import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlineDelete } from 'react-icons/ai'
import { HiOutlinePencil } from 'react-icons/hi'
import Select from 'react-select'
import Api from '../../../../Api'
import { IconPlus } from '../../../../assets'
import { Modal, ModalDelete, Navbar, Pagination } from '../../../../components'
import IconPrimaryButton from '../../../../components/Button/IconPrimaryButton'
import LoadingPrimaryButton from '../../../../components/Button/LoadingPrimaryButton'
import PrimaryButton from '../../../../components/Button/PrimaryButton'
import SecondaryButton from '../../../../components/Button/SecondaryButton'
import InputComponent from '../../../../components/Input/inputComponent'
import { selectStyle } from '../../../../utils/styles/select'

const AngkaKreditMinimum = () => {
    const limit = 10
    const [currentPage, setCurrentPage] = useState(1)
    const [refresh, setRefresh] = useState(false)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };
    const [showAdd, setShowAdd] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [nilai, setNilai] = useState('')
    const [jobLevelId, setJobLevelId] = useState('')

    const [dataJobLevel, setDataJobLevel] = useState([])

    const resetForm = () => {
        setNilai('')
        setJobLevelId('')
    }

    const getJobLevel = async () => {
        try {
            await Api.GetJobLevelDropdown(localStorage.getItem('hris-token')).then((response) => {
                const res = response.data.results.data
                const formattedData = res.map((val => ({ value: val.id, label: val.level_name })))
                setDataJobLevel(formattedData)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }

    const [data, setData] = useState([])
    const getData = async () => {
        try {
            await Api.GetAngkaKreditMinimum(localStorage.getItem('hris-token'), limit, currentPage).then((response) => {
                const res = response.data.results
                console.log(res.data)
                setData(res.data)
                setCurrentPage(res.currentPage)
                setTotalPages(res.totalPages)
                setTotalCount(res.totalCount)
            }).catch((err) => {
                console.log(err)
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Data gagal dihapus!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }

    const postData = async () => {
        setIsLoading(true)
        const data = {
            "nilai": nilai,
            "jobLevelId": jobLevelId.value
        }
        try {
            await Api.PostAngkaKreditMinimum(localStorage.getItem('hris-token'), data).then((response) => {
                console.log(response)
                setIsLoading(false)
                toast.success('Berhasil menambahkan data!')
                setShowAdd(false)
                setRefresh(true)
            }).catch((err) => {
                console.log(err)
                setIsLoading(false)
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Data gagal dihapus!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }
    const updateData = async () => {
        setIsLoading(true)
        const data = {
            "nilai": nilai,
            "jobLevelId": jobLevelId.value
        }
        try {
            await Api.UpdateAngkaKreditMinimum(localStorage.getItem('hris-token'), data, id).then((response) => {
                console.log(response)
                setIsLoading(false)
                toast.success('Edit data berhasil!')
                setShowEdit(false)
                setRefresh(true)
            }).catch((err) => {
                console.log(err)
                setIsLoading(false)
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Data gagal diedit!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }
    const deleteDataModal = (id) => {
        setShowDelete(!showDelete)
        setId(id)
        setRefresh(true)
    }

    const deleteData = async () => {
        try {
            await Api.DeleteAngkaKreditMinimum(localStorage.getItem('hris-token'), id).then((res) => {
                setShowDelete(!showDelete)
                setRefresh(true)
                toast.success('Berhasil menghapus data!')
            }).catch((err) => {
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Data gagal dihapus!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }

    const [id, setId] = useState('')

    const getDataById = (id, nilai, jobLevelId) => {
        setId(id)
        setNilai(nilai)
        setJobLevelId(jobLevelId)
        setShowEdit(true)
    }

    useEffect(() => {
        getJobLevel()
    }, [])

    useEffect(() => {
        getData()
        setRefresh(false)
    }, [refresh])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='relative'>
                <Modal
                    activeModal={showAdd}
                    title={"Tambah Angka Kredit Minimum"}
                    buttonClose={() => { setShowAdd(!showAdd); resetForm() }}
                    width={'832px'}
                    content={
                        <div className='space-y-[24px] w-[full] '>
                            <div className='space-y-3'>
                                <InputComponent type={'number'} label={'Nilai'} value={nilai} onChange={(e) => setNilai(e)} required={true} />
                                <div>
                                    <label className='w-full text-grey-thick text-[12px] font-medium mb-1 line-clamp-1'>Jenis Jabatan <span className='text-red-primer'> *</span></label>
                                    <Select
                                        value={jobLevelId}
                                        onChange={selectedOption => setJobLevelId(selectedOption)}
                                        options={dataJobLevel}
                                        placeholder={'Pilih Jenis Jabatan...'}
                                        menuPortalTarget={document.body}
                                        menuPosition="fixed"
                                        styles={selectStyle}
                                    />
                                </div>
                            </div>
                            <div className="flex justify-end gap-[10px]">
                                <SecondaryButton action={() => { setShowAdd(!showAdd); resetForm() }} text={'Batal'} />
                                {isLoading ?
                                    <LoadingPrimaryButton />
                                    : <PrimaryButton action={() => postData()} text={'Simpan'} />
                                }
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEdit}
                    title={"Edit Angka Kredit Minimum"}
                    buttonClose={() => { setShowEdit(!showEdit); resetForm() }}
                    width={'832px'}
                    content={
                        <div className='space-y-[24px] w-[full] '>
                            <div className='space-y-3'>
                                <InputComponent type={'number'} label={'Nilai'} value={nilai} onChange={(e) => setNilai(e)} required={true} />
                                <div>
                                    <label className='w-full text-grey-thick text-[12px] font-medium mb-1 line-clamp-1'>Jenis Jabatan <span className='text-red-primer'> *</span></label>
                                    <Select
                                        value={jobLevelId}
                                        onChange={selectedOption => setJobLevelId(selectedOption)}
                                        options={dataJobLevel}
                                        placeholder={'Pilih Jenis Jabatan...'}
                                        menuPortalTarget={document.body}
                                        menuPosition="fixed"
                                        styles={selectStyle}
                                    />
                                </div>
                            </div>
                            <div className="flex justify-end gap-[10px]">
                                <SecondaryButton action={() => { setShowEdit(!showEdit); resetForm() }} text={'Batal'} />
                                {isLoading ?
                                    <LoadingPrimaryButton />
                                    : <PrimaryButton action={() => updateData()} text={'Simpan'} />
                                }
                            </div>
                        </div>
                    }
                />
                < ModalDelete
                    activeModal={showDelete}
                    buttonClose={() => setShowDelete(!showDelete)}
                    submitButton={deleteData}
                />
            </div>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Pengaturan Angka Kredit Minimum'} LinkBack={'/performance-setting'} />
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='space-y-5 overflow-auto'>
                        <div className='flex justify-end'>
                            <IconPrimaryButton icon={IconPlus} action={() => setShowAdd(true)} text={"Tambah"} />
                        </div>
                        <table className='min-w-full'>
                            <thead>
                                <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                                    <th className='border py-3 px-5'>No.</th>
                                    <th className='border py-3 px-5'>Nilai</th>
                                    <th className='border py-3 px-5'>Jenis Jabatan</th>
                                    <th className='border py-3 px-5'>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.map((val, index) => (
                                    <tr key={index} className='border'>
                                        <td className='border py-3 px-5 text-grey-thick text-xs font-medium text-center'>{index + 1}</td>
                                        <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val.nilai ?? "-"}</td>
                                        <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.jobLevel?.level_name ?? "-"}</td>
                                        <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>
                                            <div className='flex justify-center items-center gap-2'>
                                                <button
                                                    onClick={() => getDataById(val.id, val.nilai, { value: val.jobLevelId, label: val.jobLevel.level_name })}
                                                    className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-blue-thick' />
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        deleteDataModal(val?.id);
                                                    }}
                                                    className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                    <AiOutlineDelete className='text-sm text-blue-thick' />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>

                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            lengthData={totalCount}
                            limitData={limit}
                            onPageChange={handlePageChange}
                            onPrevChange={handlePrevChange}
                            onNextChange={handleNextChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AngkaKreditMinimum
