import React, { useEffect, useState } from 'react'
import { Pagination } from '../../../components'
import Api from '../../../Api'
import moment from 'moment'
import imageHandle from '../../../utils/imageHandle'
import { AiOutlineDownload } from 'react-icons/ai'

const Pendidikan = () => {
    const [refresh, setRefresh] = useState(false)
    const [currentPageEducation, setCurrentPageEducation] = useState(1)
    const [totalPagesEducation, setTotalPagesEducation] = useState('')
    const [totalDataEducation, setTotalDataEducation] = useState('')
    const [limit, setLimit] = useState(10)

    const [data, setData] = useState([])

    const handlePageEducationChange = (page) => {
        setCurrentPageEducation(page);
        setRefresh(true)
    };

    const handlePrevEducationChange = () => {
        if (currentPageEducation === 1) {
            setCurrentPageEducation(1)
        } else {
            setCurrentPageEducation(currentPageEducation - 1);
        }
        setRefresh(true)
    };

    const handleNextEducationChange = () => {
        if (currentPageEducation === totalPagesEducation) {
            setCurrentPageEducation(totalPagesEducation)
        } else {
            setCurrentPageEducation(currentPageEducation + 1);
        }
        setRefresh(true)
    };
    const getApprovalEducation = async () => {
        try {
            const response = await Api.ApprovalEducation(localStorage.getItem('hris-token'), currentPageEducation, limit)
            console.log(response.data.results)
            setData(response.data.results.data)
            setTotalPagesEducation(response.data.results.totalPages)
            setTotalDataEducation(response.data.results.totalCount)
        } catch (error) {
            console.log(error)
        }
    }

    const checkboxHandler = async (id, value) => {
        console.log(value)
        try {
            const data = {
                "isApproved": value
            }
            await Api.PostApprovalEducation(localStorage.getItem('hris-token'), id, data).then((res) => {
                setRefresh(true)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getApprovalEducation()
        setRefresh(false)
    }, [refresh])
    return (
        <div>
            <div>
                <div className='overflow-auto'>
                    <table className='w-full space-y-[10px] text-dark-5 pl-3'>
                        <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                            <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Nama Pegawai</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Tingkat pendidikan</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Program Studi/Keahlian</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Institusi pendidikan</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Tahun Lulus</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[100px] max-w-[100px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Nilai Akhir</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px] justify-center'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Dokumen</h1>
                            </div>
                            <div className='flex justify-center items-center gap-[6px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Status</h1>
                            </div>
                        </div>
                        {data && data.map((item, idx) => (
                            <div key={idx} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                <div className='min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item?.user?.fullname ? item?.user?.fullname : '-'}</h1>
                                </div>
                                <div className='min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item?.level ? item?.level : '-'}</h1>
                                </div>
                                <div className='min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item?.major ? item?.major : '-'}</h1>
                                </div>
                                <div className='min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item?.institute ? item?.institute : '-'}</h1>
                                </div>
                                <div className='min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item?.end_year ? moment(item?.end_year).format('YYYY') : '-'}</h1>
                                </div>
                                <div className='min-w-[100px] max-w-[100px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item?.gap ? item?.gap : '-'}</h1>
                                </div>
                                <div className='flex items-center justify-center min-w-[150px] max-w-[150px]'>
                                    <a href={imageHandle(item?.file)} download={item?.file?.split('/').pop()} target='_blank' className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center' rel="noreferrer">
                                        <AiOutlineDownload className='text-sm text-blue-thick' />
                                    </a>
                                </div>
                                <div className='flex justify-center'>
                                    <label className="inline-flex gap-2 items-center justify-center cursor-pointer w-full">
                                        <input
                                            type="checkbox"
                                            value=""
                                            className=""
                                            checked={item.isApproved}
                                            onChange={() => checkboxHandler(item.id, !item.isApproved)}
                                        />
                                        <span className="text-dark-5 text-xs font-[500] truncate text-center">Verifikasi</span>
                                    </label>
                                </div>
                            </div>
                        ))}
                    </table>
                </div>
                <div className='px-[14px] py-[8px]'>
                    <Pagination
                        currentPage={currentPageEducation}
                        totalPages={totalPagesEducation}
                        lengthData={totalDataEducation}
                        limitData={limit}
                        onPageChange={handlePageEducationChange}
                        onPrevChange={handlePrevEducationChange}
                        onNextChange={handleNextEducationChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default Pendidikan
