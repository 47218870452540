import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { BiDownload, BiSearch } from 'react-icons/bi'
import { CgTrashEmpty } from 'react-icons/cg'
import { FaUpload } from 'react-icons/fa'
import Api from '../../../../../Api'
import IconDoc from "../../../../../assets/icon/Doc.svg"
import IconXlsx from "../../../../../assets/icon/IconXlsx.svg"
import IconPdf from "../../../../../assets/icon/Pdf.svg"
import IconPpt from "../../../../../assets/icon/Ppt.svg"
import { ModalDelete, Pagination } from '../../../../../components'
import imageHandle from '../../../../../utils/imageHandle'

const File = ({ UserId }) => {
    const [dataFile, setDataFile] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [file, setFile] = useState()
    const [showModalDelete, setShowModalDelete] = useState()
    const [idFile, setIdFile] = useState()
    const [filePath, setFilePath] = useState('')
    const [type, setType] = useState("doc")
    const [search, setSearch] = useState("")

    const postFile = async () => {
        try {
            const formData = new FormData();
            formData.append('userId', UserId);
            formData.append('file', file);
            await Api.PostFile(localStorage.getItem('hris-token'), formData).then((response) => {
                console.log(response)
                setRefresh(true)
                toast.success('Berhasil Menambahkan Data!')
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data!')
        }
    }

    const getDataFile = async () => {
        try {
            const response = await Api.GetFileProfile(localStorage.getItem('hris-token'), UserId, type, currentPage, limit, search)
            setDataFile(response.data.results.data)
            setCurrentPage(response.data.results.currentPage)
            setTotalPages(response.data.results.totalPages)
            setTotalCount(response.data.response.totalCount)
            console.log("file", response.data.results.data)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteFile = async () => {
        try {
            await Api.DeleteFile(localStorage.getItem('hris-token'), idFile).then((res) => {
                toast.success('Berbhasil Menghapus Data!')
                setShowModalDelete(!showModalDelete)
                setRefresh(true)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error(error.response.message)
        }
    }

    const handleChange = (e) => {
        const maxSize = 5 * 1024 * 1024;
        const allowedExtensions = ["doc", "docx", "ppt", "pptx", "pdf", "xls", "xlsx"];
        const file = e.target.files[0];
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (file && file.size > maxSize) {
            toast.error("file tidak boleh lebih dari 5 MB");
            setFile("");
        } else if (!allowedExtensions.includes(fileExtension)) {
            toast.error("file harus berformat doc/docx/ppt/pptx/pdf/xls/xlsx");
        } else {
            setFilePath(e.target.files[0].name);
            setFile(e.target.files[0])
        }
    };

    const deleteFileModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setIdFile(id)
        setRefresh(true)
    }

    // Implementasi fungsi debounce
    const debounce = (func, delay) => {
        let timer;
        return (...args) => {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };
    // Event handler untuk input perubahan dengan debounce
    const debouncedSetName = useCallback(debounce((value) => setSearch(value), 500), []);

    const handleInputChange = (e) => {
        debouncedSetName(e.target.value);
    };

    const [limit, setLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState(1)

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };
    useEffect(() => {
        getDataFile()
        setRefresh(false)
    }, [refresh, type, search])

    return (
        <div>
            <ModalDelete
                activeModal={showModalDelete}
                buttonClose={() => setShowModalDelete(!showModalDelete)}
                submitButton={deleteFile}
            />
            <div className="space-y-3 mb-3">
                <div className='mb-[8.5px]'>
                    <h1 className='text-[20px] font-medium'>File</h1>
                    <p className='text-xs text-dark-5'>Silakan unggah dokumen kepegawaian seperti Surat Keputusan, Sertifikat Pelatihan, dan sebagainya untuk disimpan sebagai dokumen kepegawaian yang mungkin suatu waktu dibutuhkan.</p>
                </div>
                <div className="flex items-center gap-1 w-fit">
                    <label htmlFor="upload-sign" className="cursor-pointer">
                        <div className="bg-base-outline w-[250px] border pr-2 rounded-md py-[5px] pl-[10px] text-grey-thick text-[12px] flex items-center gap-[8px]">
                            <div className="w-[54px] h-[27px] px-2.5 py-2 bg-gray-200 rounded-md justify-center items-center gap-2.5 inline-flex">
                                <div className="text-center text-sky-950 text-[9px] font-medium">Browse</div>
                            </div>
                            <input
                                type="file"
                                id="upload-sign"
                                accept=".doc,.docx,.ppt,.pptx,.pdf,.xls,.xlsx"
                                onChange={(e) => handleChange(e)}
                                className="rounded-[12px] outline-none border border-[#E3E8F1] w-[300px] px-[20px] py-[15px] hidden"
                                required
                            />
                            <h1 className="text-zinc-700 text-xs font-medium truncate">{filePath ? filePath : "Tidak ada file yang dipilih"}</h1>
                        </div>
                    </label>
                    <button onClick={postFile} className='bg-blue-primer rounded-[6px] flex items-center gap-[12px] px-[17px] py-[10px]'>
                        <FaUpload className='text-base-white' />
                        <h1 className='text-white text-sm font-[500]'>Upload File</h1>
                    </button>
                </div>
                <div className='relative'>
                    <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg' />
                    <input placeholder='Cari Nama File...' value={search} onChange={(e) => handleInputChange(e)} className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px] md:w-[300px]' />
                </div>
                {/* <div>
                <input type="file" className="hidden" onChange={(e) => handleChange(e)} />
                <button className="bg-[#0E5073] px-[17px] py-[10px] text-white text-[14px] font-medium rounded-md">Upload</button>
            </div> */}
            </div>

            <div className='flex gap-[50px] mb-2'>
                <button onClick={() => setType("doc")} className={type === "doc" ? 'text-[13px] font-medium border-b-2 border-black' : 'text-[13px] text-[#A8A8A8]'}>Doc</button>
                <button onClick={() => setType("xls")} className={type === "xls" ? 'text-[13px] font-medium border-b-2 border-black' : 'text-[13px] text-[#A8A8A8]'}>Xls</button>
                <button onClick={() => setType("pdf")} className={type === "pdf" ? 'text-[13px] font-medium border-b-2 border-black' : 'text-[13px] text-[#A8A8A8]'}>Pdf</button>
                <button onClick={() => setType("ppt")} className={type === "ppt" ? 'text-[13px] font-medium border-b-2 border-black' : 'text-[13px] text-[#A8A8A8]'}>Ppt</button>
            </div>
            <hr className='border-1 mb-[20px]' />
            <div className='flex flex-col justify-between min-h-[480px]'>
                <div className='grid grid-cols-3 gap-2'>
                    {Object.values(dataFile).map((item, idx) => (
                        <div key={idx}>
                            <div className='rounded-lg shadow-md h-[133px] p-3 bg-white'>
                                <div className='flex justify-between items-start'>
                                    <img src={type === "doc" ? IconDoc : type === "xls" ? IconXlsx : type === "pdf" ? IconPdf : type === "ppt" ? IconPpt : null}
                                        alt="Doc icon" className='w-[60px] h-[70px]'
                                    />
                                    <div className='flex flex-col gap-1'>
                                        <button onClick={() => deleteFileModal(item.id)} className='bg-base-outline p-1 rounded'>
                                            <CgTrashEmpty className='text-sm' />
                                        </button>
                                        <a href={imageHandle(item.path)} download={item.originalname} className='bg-base-outline p-1 rounded'>
                                            <BiDownload className='text-sm' />
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <h1 className='text-dark-1 text-[13px] font-medium truncate'>{item.originalname ? item.originalname : '-'}</h1>
                                    <p className='text-[12px] text-[#A8A8A8]'>{item.size ? (item.size) : '-'} Bytes</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    limitData={dataFile?.length}
                    lengthData={totalCount}
                    onPageChange={handlePageChange}
                    onPrevChange={handlePrevChange}
                    onNextChange={handleNextChange}
                />
            </div>
        </div>
    )
}

export default File