import React, { useEffect, useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { MdImportExport, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { TbFileExport } from 'react-icons/tb'
import { Link, useNavigate } from 'react-router-dom'
import Api from '../../../../Api'
import { Modal, Navbar } from '../../../../components'
import languages from '../../../../utils/languages'

const Payroll = () => {
    const [company, setCompany] = useState('')
    const [showDetailPayroll, setShowDetailPayroll] = useState()
    const [dataIncome, setDataIncome] = useState('')
    const [dataDeduction, setDataDeduction] = useState('')
    const [dataBenefit, setDataBenefit] = useState('')
    const [data, setData] = useState('')
    const [idPayroll, setIdPayroll] = useState('')

    //State Payroll
    const [employeeName, setEmployeeName] = useState('')
    const [employeeId, setEmployeeId] = useState('')
    const [employeeStatus, setEmployeeStatus] = useState('')
    const [jobGrade, setJobGrade] = useState('')
    const [basicSalary, setBasicSalary] = useState('')
    const [totalIncome, setTotalIncome] = useState('')
    const [totalDeduction, setTotalDeduction] = useState('')
    const [totalBenefit, setTotalBenefit] = useState('')
    const [totalPay, setTotalPay] = useState('')

    const navigate = useNavigate()

    const FetchUser = async () => {
        try {
            await Api.Fetch(localStorage.getItem('hris-token')).then((response) => { setCompany(response.data.results.user.companyId) })
        } catch (error) {
            console.log(error)
        }
    }

    const openDetailPayroll = async (id) => {
        setIdPayroll(id)
        setShowDetailPayroll(!showDetailPayroll)
        try {
            const response = await Api.GetPayrollById(localStorage.getItem('hris-token'), id)

            const resIncome = await Api.GetIncome(localStorage.getItem('hris-token'), idPayroll)
            const resDeduction = await Api.GetDeduction(localStorage.getItem('hris-token'), idPayroll)
            const resBenefit = await Api.GetBenefit(localStorage.getItem('hris-token'), idPayroll)

            setDataIncome(resIncome.data.incomes)
            setDataBenefit(resBenefit.data.benefits)
            setDataDeduction(resDeduction.data.deductions)

            setEmployeeName(response.data[0].user.fullname)
            setEmployeeId(response.data[0].user.user_detail.employee_id)
            setEmployeeStatus(response.data[0].user.user_detail.employe_status)
            setJobGrade(response.data[0].user.user_detail.job_grade.job_grade)
            setBasicSalary(response.data[0].basic_salary)
            setTotalIncome(response.data[0].totalIncome)
            setTotalDeduction(response.data[0].totalDeduction)
            setTotalBenefit(response.data[0].totalBenefit)
            setTotalPay(response.data[0].totalPay)
        } catch (error) {
            console.log(error)
        }
    }

    const getPayroll = async () => {
        try {
            const response = await Api.GetPayroll(localStorage.getItem('hris-token'))
            setData(response.data)
            console.log(response)
            // setIdPayroll(response.data.payroll.id)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        FetchUser()
        getPayroll()
    }, [])

    return (
        <div>
            <Modal
                activeModal={showDetailPayroll}
                title={'Detail Payroll'}
                buttonClose={() => setShowDetailPayroll(!showDetailPayroll)}
                width={'723px'}
                content={
                    <div className='space-y-[40px] w-[full]'>
                        <div className='w-[579px] relative'>
                            <div className='grid grid-cols-2 text-dark-5 gap-y-[25px] mb-[16px]'>
                                <div className="flex text-[11px] gap-[52px]">
                                    <h1 className='font-semibold'>Employee Name</h1>
                                    <h1>: {employeeName ? employeeName : '-'}</h1>
                                </div>
                                <div className="flex text-[11px] gap-[73px]">
                                    <h1 className='font-semibold'>Employee ID</h1>
                                    <h1>: {employeeId ? employeeId : '-'}</h1>
                                </div>
                                <div className="flex text-[11px] gap-[84px]">
                                    <h1 className='font-semibold'>Job Grade</h1>
                                    <h1>: {jobGrade ? jobGrade : '-'}</h1>
                                </div>
                                <div className="flex text-[11px] gap-[47px]">
                                    <h1 className='font-semibold'>Employee Status </h1>
                                    <h1>: {employeeStatus ? employeeStatus : '-'}</h1>
                                </div>
                                <div className="flex text-[11px] gap-[69px]">
                                    <h1 className='font-extrabold'>Basic Salary </h1>
                                    <h1 className='font-extrabold'>: Rp {basicSalary ? basicSalary : '-'}</h1>
                                </div>
                            </div>
                            <div className='bg-[#F8F8F8] rounded-[15px] py-[16px] px-[19px] text-dark-5 mb-[16px]'>
                                <h1 className='font-medium text-[14px] mb-[14px]'>Income</h1>
                                {Object.values(dataIncome).map((item, idx) => (
                                    <div key={idx} className="grid grid-cols-6 w-1/2 text-[11px] justify-between font-medium mb-[16px]">
                                        <h1 className='col-span-3'>{item.title}</h1>
                                        <h1 className='col-span-3'>: {item.amount}</h1>
                                    </div>
                                ))}

                                <hr className='mt-[14px] mb-[14px]' />

                                <div className='flex text-[#003049] gap-[71px] text-[11px] font-bold '>
                                    <h1>Total Incomes </h1>
                                    <h1>: Rp {totalIncome ? totalIncome : '-'}</h1>
                                </div>
                            </div>
                            <div className='bg-[#F8F8F8] rounded-[15px] py-[16px] px-[19px] text-dark-5 mb-[16px]'>
                                <h1 className='font-medium text-[14px] mb-[14px]'>Deduction</h1>

                                {Object.values(dataDeduction).map((item, idx) => (
                                    <div key={idx} className="grid grid-cols-6 w-1/2 text-[11px] mb-[16px] justify-between font-medium">
                                        <h1 className='col-span-3'>{item.title}</h1>
                                        <h1 className='col-span-3'>: {item.amount}</h1>
                                    </div>
                                ))}

                                <hr className='mt-[14px] mb-[14px]' />

                                <div className='flex text-[#003049] gap-[57px] text-[11px] font-bold '>
                                    <h1>Total Deductions </h1>
                                    <h1>: Rp {totalDeduction ? totalDeduction : '-'}</h1>
                                </div>
                            </div>
                            <div className='bg-[#F8F8F8] rounded-[15px] py-[16px] px-[19px] text-dark-5 mb-[16px]'>
                                <h1 className='font-medium text-[14px] mb-[14px]'>Benefit</h1>

                                {Object.values(dataBenefit).map((item, idx) => (
                                    <div key={idx} className="grid grid-cols-6 w-1/2 text-[11px] mb-[16px] justify-between font-medium">
                                        <h1 className='col-span-3'>{item.title}</h1>
                                        <h1 className='col-span-3'>: {item.amount}</h1>
                                    </div>
                                ))}

                                <hr className='mt-[14px] mb-[14px]' />

                                <div className='flex text-[#003049] gap-[71px] text-[11px] font-bold '>
                                    <h1>Total Benefits</h1>
                                    <h1>: Rp {totalBenefit ? totalBenefit : '-'}</h1>
                                </div>
                            </div>
                            <div className='absolute right-0 pb-[35px]'>
                                <div className="grid grid-cols-2 w-[228px] text-[11px] gap-y-[16px] justify-between font-medium ">
                                    <h1 className='text-[#003049] font-bold'>Basic Salary </h1>
                                    <h1 className='justify-self-end'>: Rp {basicSalary ? basicSalary : '-'}</h1>
                                    <h1 className='text-[#003049] font-bold'>Total Incomes</h1>
                                    <h1 className='justify-self-end'>: Rp {totalIncome ? totalIncome : '-'}</h1>
                                    <h1 className='text-[#003049] font-bold'>Total Deductions</h1>
                                    <h1 className='justify-self-end'>: Rp {totalDeduction ? totalDeduction : '-'}</h1>
                                    <h1 className='text-[#003049] font-bold'>Total Benefits</h1>
                                    <h1 className='justify-self-end'>: Rp {totalBenefit ? totalBenefit : '-'}</h1>
                                </div>

                                <hr className='mt-[12px] mb-[12px]' />

                                <div className='flex  gap-[71px] text-[14px] font-bold '>
                                    <h1 className='font-bold text-[#003049]'>Total Pay </h1>
                                    <h1 className='text-dark-5'>: Rp {totalPay ? totalPay : '-'}</h1>
                                </div>
                            </div>
                        </div>

                    </div>
                }
            />
            <div className='bg-[#F8F9FB] min-h-screen'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={languages.language === 'id' ? "Penghasilan" : "Payroll"} LinkBack={'/dashboard'} />
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div className='flex justify-between'>
                            <div>
                                <h1 className='text-[20px] font-semibold text-[#272B30]'>{languages.language === 'id' ? "Penghasilan" : "Payroll"}</h1>
                                <h2 className='text-[12px] text-dark-5'>{languages.language === 'id' ? "Daftar Penghasilan Pegawai" : "Employee Payroll List"}</h2>
                            </div>
                            <button
                                onClick={() => navigate('report', {
                                    state: { company: company }
                                })}
                                className='flex items-center rounded-lg py-[7px] px-[8px] gap-1 border border-[#8E95A2]'>
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.6932 5.82143H15.9205V5.21429C15.9205 4.89224 15.7959 4.58338 15.5743 4.35566C15.3527 4.12793 15.0521 4 14.7386 4H10.0114C9.69793 4 9.39733 4.12793 9.17569 4.35566C8.95406 4.58338 8.82955 4.89224 8.82955 5.21429V5.82143H7.05682C6.74338 5.82143 6.44278 5.94936 6.22115 6.17708C5.99951 6.40481 5.875 6.71367 5.875 7.03571V19.7857C5.875 20.1078 5.99951 20.4166 6.22115 20.6443C6.44278 20.8721 6.74338 21 7.05682 21H17.6932C18.0066 21 18.3072 20.8721 18.5289 20.6443C18.7505 20.4166 18.875 20.1078 18.875 19.7857V7.03571C18.875 6.71367 18.7505 6.40481 18.5289 6.17708C18.3072 5.94936 18.0066 5.82143 17.6932 5.82143ZM10.0114 5.21429H14.7386V7.64286H10.0114V5.21429ZM17.6932 19.7857H7.05682V7.03571H8.82955V8.85714H15.9205V7.03571H17.6932V19.7857Z" fill="#113B61" />
                                    <path d="M8.875 14.0472H14.2083V15.3805H8.875V14.0472ZM8.875 10.7139H16.875V12.0472H8.875V10.7139ZM8.875 17.3805H12.2083V18.7139H8.875V17.3805Z" fill="#113B61" />
                                </svg>
                                <h1 className='text-[14px] text-blue-thick font-medium'>Lihat Laporan Penghasilan</h1>
                            </button>
                        </div>
                        <div className="flex justify-between gap-2 mt-[31px] relative">
                            <div className='flex gap-2'>
                                <Link to={'payroll-history'} className='flex items-center rounded-lg bg-blue-primer py-[7px] px-[8px] text-white gap-1'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M13.6071 4C11.4475 4 9.37636 4.84285 7.84927 6.34314C6.32219 7.84344 5.46429 9.87827 5.46429 12H2.75L6.26952 15.4578L6.33286 15.5822L9.9881 12H7.27381C7.27381 8.56 10.1057 5.77778 13.6071 5.77778C17.1086 5.77778 19.9405 8.56 19.9405 12C19.9405 15.44 17.1086 18.2222 13.6071 18.2222C11.861 18.2222 10.2776 17.52 9.13762 16.3911L7.85286 17.6533C8.6069 18.3982 9.50358 18.9891 10.4912 19.3919C11.4788 19.7946 12.5378 20.0013 13.6071 20C15.7668 20 17.8379 19.1571 19.365 17.6568C20.8921 16.1566 21.75 14.1217 21.75 12C21.75 9.87827 20.8921 7.84344 19.365 6.34314C17.8379 4.84285 15.7668 4 13.6071 4ZM12.7024 8.44444V12.8889L16.5748 15.1467L17.2262 14.0711L14.0595 12.2222V8.44444H12.7024Z" fill="white" />
                                    </svg>
                                    <h1 className='text-[14px] font-medium'>{languages.language === 'id' ? "Riwayat Penghasilan" : "Run History"}</h1>
                                </Link>

                                <button className='bg-white border border-dark-7 rounded-[6px] flex items-center gap-[12px] px-[10px] py-[11px]'>
                                    <TbFileExport className='text-[#003049]' />
                                    <h1 className='text-[#003049] text-sm font-[500]'>{languages.language === 'id' ? "Expor" : "Export"}</h1>
                                </button>
                            </div>

                            <div className="flex gap-2">
                                <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                    <div className='relative'>
                                        <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg' />
                                        <input placeholder={languages.language === 'id' ? "Cari..." : "Search..."} className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]' />
                                    </div>
                                </div>
                                <Link to={''} className='flex items-center rounded-lg bg-red-primer py-[7px] px-[8px] text-white gap-1'>
                                    <h1 className='text-[14px] font-medium'>Lakukan Pembayaran</h1>
                                </Link>
                            </div>
                        </div>
                        <div>
                            <div className='overflow-auto scrollbar-hide'>
                                <table className='w-full space-y-[10px]'>
                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Pegawai" : "Employee"}</h1>
                                            <MdImportExport className='text-dark-5 text-xs' />
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Gaji Pokok" : "Basic Salary"}</h1>
                                            <MdImportExport className='text-dark-5 text-xs' />
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Total Penghasilan" : "Total Income"}</h1>
                                            <MdImportExport className='text-dark-5 text-xs' />
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Total Potongan" : "Total Deduction"}</h1>
                                            <MdImportExport className='text-dark-5 text-xs' />
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Total Tambahan Pengahasilan" : "Total Benefit"}</h1>
                                            <MdImportExport className='text-dark-5 text-xs' />
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Total Pembayaran</h1>
                                            <MdImportExport className='text-dark-5 text-xs' />
                                        </div>
                                        {/* <div className='flex items-center justify-center gap-[6px] w-full'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ?"Aksi":"Action"}</h1>
                                        <MdImportExport className='text-dark-5 text-xs'/>
                                    </div> */}
                                    </div>
                                    {/* {Object.values(data).map((item, idx) => (
                                    <div key={idx} className='flex items-center gap-2  px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.user.fullname}</h1>
                                            <h1 className='text-dark-7 text-[10px] font-[500] truncate'>{item.user.user_detail.employee_id} / {item.user.user_detail.job_grade? item.user.user_detail.job_grade.job_grade : '-'}</h1>
                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500] truncate'>{item? item.bank_name : '-'} - {item? item.bank_account_number : '-'}</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item? item.basic_salary : '-'}</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item? item.totalIncome : '-'}</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item? item.totalDeduction : '-'}</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item? item.totalBenefit : '-'}</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item? item.totalPay : '-'}</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            <button onClick={() => openDetailPayroll(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <AiOutlineEye  className='text-[#003049]'/>
                                            </button>
                                        </div>
                                    </div>
                                ))} */}
                                    <div className='flex items-center gap-2  px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>Alexis Carmencia</h1>
                                            <h1 className='text-dark-7 text-[10px] font-[500] truncate'>001/I</h1>
                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500] truncate'>Mandiri - 1800087658990</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>Rp. 10.000.000</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>Rp. 5.000.000</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>Rp. 500.000</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>Rp. 1.000.000</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>Rp. 15.500.000</h1>
                                        </div>
                                        {/* <div className='w-full flex items-center justify-center gap-[12px]'>
                                        <button className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <AiOutlineEye  className='text-[#003049]'/>
                                        </button>
                                    </div> */}
                                    </div>
                                </table>
                            </div>
                            <div className='flex items-center justify-between mt-[30px]'>
                                <h1 className='text-[#A098AE] text-[10px] '>Showing 1-5 from 100 data</h1>
                                <div className='flex items-center gap-[8px]'>
                                    <MdKeyboardArrowLeft className='text-[#A098AE]' />
                                    <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                        <h1 className='text-[#780000] text-[10px]'>1</h1>
                                    </div>
                                    <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] flex items-center justify-center'>
                                        <h1 className='text-white text-[10px]'>2</h1>
                                    </div>
                                    <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                        <h1 className='text-[#780000] text-[10px]'>3</h1>
                                    </div>
                                    <MdKeyboardArrowRight className='text-[#A098AE]' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Payroll