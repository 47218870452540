import React, { useState } from 'react'
import { Navbar } from '../../../../components'
import Province from './Province'
import District from './District'
import Subdistrict from './Subdistrict'

export const DataWilayah = () => {
    const [menu, setMenu] = useState('province')
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Data Wilayah'} LinkBack={'/dashboard'} />
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border w-full'>
                    <div className='border-b-2 flex flex-wrap items-center text-[16px]'>
                        <button onClick={() => setMenu("province")} className={`${menu === "province" ? "text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold" : "text-[#D1D1D1]"} px-10 py-[10px]`}>
                            Provinsi
                        </button>
                        <button onClick={() => setMenu("district")} className={`${menu === "district" ? "text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold" : "text-[#D1D1D1]"} px-10 py-[10px]`}>
                            Kabupaten/Kota
                        </button>
                        <button onClick={() => setMenu("subdistrict")} className={`${menu === "subdistrict" ? "text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold" : "text-[#D1D1D1]"} px-10 py-[10px]`}>
                            Kecamatan
                        </button>
                    </div>
                    {
                        menu === "province" ? (
                            <Province />
                        ) :
                            menu === "district" ? (
                                <District />
                            ) :
                                menu === "subdistrict" ? (
                                    <Subdistrict />
                                ) : null
                    }
                </div>
            </div>
        </div>

    )
}
