import React from 'react'
import { AiOutlineCalendar, AiOutlineEye, AiOutlineUser } from 'react-icons/ai'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { Blog1, Blog2, Blog3 } from '../../../assets'
import { HelmetReact, NavbarLandingPage } from '../../../components'

const DetailBlog2 = () => {
    return (
        <div className='scroll-smooth'>

            <HelmetReact
                title={'Maksimalkan Efisiensi SDM dengan Humansia yang unggul dengan Fitur-Fitur HRIS Terbaik'}
                description={''}
            />

            <NavbarLandingPage
                features={'#features'}
                pricing={'#pricing'}
            />

            {/* Begin : Section 1 */}
            <div className='px-[16px] lg:px-[150px] py-[75px] space-y-[25px]'>
                <div className='grid grid-cols-12 gap-[51px]'>
                    <div className='col-span-8 space-y-[18px]'>
                        <img src={Blog2} className='w-full h-[494px] object-cover rounded-xl' alt='' />
                        <h1 className='text-[#003049] font-bold text-[32px]'>Maksimalkan Efisiensi SDM dengan Humansia yang unggul dengan Fitur-Fitur HRIS Terbaik</h1>
                        <div className='flex items-center gap-[11px]'>
                            <div className='flex items-center gap-[7px]'>
                                <AiOutlineCalendar className='text-dark-5' />
                                <p className='text-dark-5 text-sm'>July, 18 2023</p>
                            </div>
                            <div className='w-[1.5px] h-[26px] bg-dark-7 rounded-full' />
                            <div className='flex items-center gap-[7px]'>
                                <AiOutlineUser className='text-dark-5' />
                                <p className='text-dark-5 text-sm'>Humanusia</p>
                            </div>
                            <div className='w-[1.5px] h-[26px] bg-dark-7 rounded-full' />
                            <div className='flex items-center gap-[7px]'>
                                <AiOutlineEye className='text-dark-5' />
                                <p className='text-dark-5 text-sm'>83</p>
                            </div>
                        </div>
                        {/* Artikel */}
                        <div className='text-dark-2 text-justify space-y-[10px]'>
                            <p>Humansia dapat membantu bisnis dalam mengoptimalkan efisiensi dan produktivitas tim SDM. Dengan fitur-fitur HRIS terbaiknya, Humansia memungkinkan perusahaan untuk mengotomatisasi tugas-tugas rutin dan mengintegrasikan proses pengelolaan sumber daya manusia menjadi satu platform yang efisien.</p>
                            <p>Berikut adalah beberapa fitur Humanusia sebagai software HR:</p>
                            <ul className='list-decimal list-inside space-y-2'>
                                <li>
                                    Manajemen Data Karyawan: Humanusia menyediakan sistem manajemen data karyawan yang komprehensif, memungkinkan Anda untuk menyimpan dan
                                    mengatur informasi karyawan dalam database terpusat. Ini termasuk detail pribadi, riwayat pekerjaan, catatan kinerja, dan banyak lagi.
                                </li>
                                <li>
                                    Manajemen Kehadiran dan Cuti: Humanusia menawarkan pelacakan kehadiran otomatis dan fitur manajemen cuti. Ini memungkinkan karyawan
                                    untuk mencatat kehadiran mereka dengan mudah dan mengirimkan permintaan cuti, sementara manajer dapat menyetujui atau menolaknya secara
                                    efisien. Ini merampingkan proses dan menghilangkan dokumen manual.
                                </li>
                                <li>
                                    Manajemen Penggajian dan Kompensasi: Humanusia menyederhanakan pemrosesan penggajian dengan mengotomatiskan perhitungan, pemotongan pajak,
                                    dan menghasilkan slip gaji yang akurat. Ini memungkinkan Anda mengelola gaji, bonus, potongan, dan tunjangan karyawan secara efektif,
                                    memastikan pembayaran tepat waktu dan akurat.
                                </li>
                                <li>
                                    Rekrutmen dan Onboarding: Humanusia membantu merampingkan proses rekrutmen dengan menyediakan alat untuk posting pekerjaan,
                                    pelacakan pelamar, dan evaluasi kandidat. Ini juga memfasilitasi orientasi yang lancar dengan memusatkan dokumentasi karyawan baru dan
                                    melakukan program orientasi digital.
                                </li>
                                <li>
                                    Manajemen Kinerja: Humanusia menyertakan fitur manajemen kinerja yang memungkinkan Anda menetapkan tujuan, melakukan tinjauan kinerja,
                                    dan memberikan umpan balik kepada karyawan. Ini membantu dalam mengidentifikasi kinerja terbaik, mengatasi kesenjangan kinerja, dan
                                    mendorong pertumbuhan dan pengembangan karyawan.
                                </li>
                                <li>
                                    Portal Swalayan: Humanusia menawarkan portal swalayan untuk karyawan dan manajer, memungkinkan mereka untuk mengakses informasi pribadi
                                    mereka, mengirimkan permintaan, melihat kebijakan perusahaan, dan berpartisipasi dalam proses terkait SDM.
                                    Ini memberdayakan karyawan dan mengurangi beban kerja administratif untuk tim SDM.
                                </li>
                                <li>
                                    Pelaporan dan Analitik: Humanusia menyediakan kemampuan pelaporan dan analitik yang kuat, memungkinkan Anda menghasilkan berbagai
                                    laporan SDM, melacak metrik utama, dan mendapatkan wawasan tentang tren tenaga kerja. Pendekatan berbasis data ini mendukung
                                    pengambilan keputusan dan perencanaan strategis.
                                </li>
                            </ul>
                            <p>Dengan fitur-fitur HRIS terbaik yang ditawarkan oleh Humansia, perusahaan dapat meningkatkan efisiensi dan kualitas manajemen sumber daya manusia mereka. Pengotomatisasian proses, integrasi data, dan analisis yang akurat memberikan dukungan bagi tim SDM untuk mengambil keputusan yang cerdas dan memastikan pertumbuhan berkelanjutan bagi perusahaan. Oleh karena itu, implementasi Humansia sebagai solusi HRIS dapat menjadi langkah penting bagi bisnis yang ingin mencapai keunggulan dalam pengelolaan sumber daya manusia.</p>
                        </div>
                    </div>
                    <div className='col-span-4 space-y-2'>
                        <div>
                            <label className='text-[#780000] font-bold text-[28px]'>Postingan Terbaru</label>
                            <hr className='border-[#780000] border-2' />
                        </div>
                        <div className='py-[10px] flex gap-[20px]'>
                            <img src={Blog1} className='w-[70px] h-[70px] rounded-xl object-cover' alt='' />
                            <div className='space-y-2'>
                                <h2 className='text-[#003049] font-bold text-sm'>Humanusia: Menciptakan Pengalaman Pengelolaan SDM yang Berfokus pada Karyawan</h2>
                                <Link to={'/detail-blog/menciptakan-pengalaman-pengelolaan-sdm-yang-berfokus-pada-karyawan'} className='flex items-center gap-[15px]'>
                                    <p className='text-[#780000] text-sm font-medium'>Baca Selanjutnya</p>
                                    <HiArrowNarrowRight className='text-[#780000]' />
                                </Link>
                            </div>
                        </div>
                        <div className='py-[10px] flex gap-[20px]'>
                            <img src={Blog3} className='w-[70px] h-[70px] rounded-xl object-cover' alt='' />
                            <div className='space-y-2'>
                                <h2 className='text-[#003049] font-bold text-sm'>Humanusia sebagai Rekomendasi Sistem Manajemen HR dengan Harga Terjangkau</h2>
                                <Link to={'/detail-blog/humanusia-sebagai-rekomendasi-sistem-manajemen-hr-dengan-harga-terjangkau'} className='flex items-center gap-[15px]'>
                                    <p className='text-[#780000] text-sm font-medium'>Baca Selanjutnya</p>
                                    <HiArrowNarrowRight className='text-[#780000]' />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailBlog2