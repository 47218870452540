import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { BsArrowRightCircle } from 'react-icons/bs'
import Api from '../../../Api'
import imageHandle from '../../../utils/imageHandle'

const Bantuan = () => {
    const [data, setData] = useState([])
    const getData = async () => {
        await Api.Helpdesk().then((response) => {
            console.log(response.data.results.data)
            setData(response.data.results.data)
        }).catch((error) => {
            console.log(error)
            toast.error(error.response.data.message ?? "Error")
        })
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <div className='h-80 overflow-auto'>
            <div className='grid grid-cols-2 gap-3'>
                {data && data.map((val, index) => (
                    <div key={index} className='flex gap-3 w-full min-h-20 border justify-between items-center p-2'>
                        <div>
                            <h1 className='text-sm'>{val?.title}</h1>
                            <p className='text-xs text-gray-500'>{val?.description}</p>
                        </div>
                        <div className='p-1'>
                            {val?.type === "FILE" ?
                                <a href={imageHandle(val?.file)} download={val?.title} target='_blank' rel="noreferrer">
                                    <BsArrowRightCircle className='text-2xl' />
                                </a>
                                :
                                <a href={val?.link} target='_blank' rel="noreferrer">
                                    <BsArrowRightCircle className='text-2xl' />
                                </a>
                            }
                        </div>
                    </div>
                ))}
            </div>
        </div >
    )
}

export default Bantuan