import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlineDelete } from 'react-icons/ai'
import { HiOutlinePencil } from 'react-icons/hi'
import Api from '../../../../Api'
import { Modal, ModalDelete, Pagination } from '../../../../components'
import PrimaryButton from '../../../../components/Button/PrimaryButton'
import SecondaryButton from '../../../../components/Button/SecondaryButton'
import InputComponent from '../../../../components/Input/inputComponent'
import SearchComponent from '../../../../components/Input/searchComponent'
import IconPrimaryButton from '../../../../components/Button/IconPrimaryButton'
import { IconPlus } from '../../../../assets'
import InputOption from '../../../../components/Input/inputOption'
import LoadingPrimaryButton from '../../../../components/Button/LoadingPrimaryButton'

const District = () => {
    const limit = 10
    const [currentPage, setCurrentPage] = useState(1)
    const [search, setSearch] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };
    const handleSearch = (e) => {
        setSearch(e)
    }

    const [data, setData] = useState([])
    const getData = async () => {
        try {
            await Api.GetDistrict(localStorage.getItem('hris-token'), limit, currentPage, search).then((response) => {
                const res = response.data.results
                setData(res.data)
                setCurrentPage(res.currentPage)
                setTotalPages(res.totalPages)
                setTotalCount(res.totalCount)
            }).catch((err) => {
                console.log(err)
                toast.error("Error")
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }

    const [showModalAdd, setShowModalAdd] = useState(false)
    const [showModalEdit, setShowModalEdit] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [id, setId] = useState('')
    const [name, setName] = useState('')

    const submitData = async () => {
        setIsLoading(true)
        const data = {
            "provinceId": idProvince,
            "name": name
        }
        try {
            await Api.CreateDistrict(localStorage.getItem('hris-token'), data).then((res) => {
                resetForm()
                setShowModalAdd(false)
                toast.success("Berhasil menambahkan data!")
                setRefresh(true)
                setIsLoading(false)
            }).catch((err) => {
                console.log(err)
                setIsLoading(false)
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Gagal menambahkan data!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }

    const getDataById = (id, name, idProvince) => {
        setId(id)
        setName(name)
        setIdProvince(idProvince)
        setShowModalEdit(true)
    }

    const updateData = async () => {
        setIsLoading(true)
        const data = {
            "provinceId": idProvince,
            "name": name
        }
        try {
            await Api.UpdateDistrict(localStorage.getItem('hris-token'), data, id).then((res) => {
                resetForm()
                toast.success("Data berhasil diubah!")
                setShowModalEdit(false)
                setRefresh(true)
                setIsLoading(false)
            }).catch((err) => {
                setIsLoading(false)
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Data gagal diubah!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }

    const deleteDataModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setId(id)
        setRefresh(true)
    }

    const deleteData = async () => {
        try {
            await Api.DeleteDistrict(localStorage.getItem('hris-token'), id).then((res) => {
                setShowModalDelete(!showModalDelete)
                setRefresh(true)
                toast.success('Berhasil menghapus data!')
            }).catch((err) => {
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Data gagal dihapus!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }

    const resetForm = () => {
        setName("")
        setIdProvince('')
    }

    const [dataProvince, setDataProvince] = useState([])
    const [idProvince, setIdProvince] = useState('')
    const getProvince = async () => {
        try {
            await Api.DropdownProvince(localStorage.getItem('hris-token')).then((response) => {
                const res = response.data.results.data
                const formattedData = res.map(res => ({ value: res.id, label: res.name }));
                setDataProvince(formattedData)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }


    useEffect(() => {
        getProvince()
    }, [])

    useEffect(() => {
        getData()
        setRefresh(false)
    }, [refresh, search])

    return (
        <div>
            <div className='relative'>
                <Modal
                    activeModal={showModalAdd}
                    title={'Tambah Kabupaten/Kota'}
                    buttonClose={() => { setShowModalAdd(false); resetForm() }}
                    width={'550px'}
                    content={
                        <div className='space-y-[25px]'>
                            <div className='w-full space-y-3'>
                                <InputOption data={dataProvince} label={'Provinsi'} value={idProvince} onChange={(e) => setIdProvince(e)} required={true} placeholder={'Pilih Provinsi'} />
                                <InputComponent onChange={(e) => setName(e)} value={name} label={"Nama Kabupaten/Kota"} placeholder={"Masukkan nama Kabupaten/Kota"} type={'text'} />
                            </div>
                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <SecondaryButton action={() => { setShowModalAdd(!showModalAdd); resetForm() }} text={'Batal'} />
                                {isLoading ?
                                    <LoadingPrimaryButton />
                                    : <PrimaryButton action={() => submitData()} text={'Simpan'} />
                                }
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showModalEdit}
                    title={'Tambah Kabupaten/Kota'}
                    buttonClose={() => { setShowModalEdit(false); resetForm() }}
                    width={'550px'}
                    content={
                        <div className='space-y-[25px]'>
                            <div className='w-full space-y-3'>
                                <InputOption data={dataProvince} label={'Provinsi'} value={idProvince} onChange={(e) => setIdProvince(e)} required={true} placeholder={'Pilih Provinsi'} />
                                <InputComponent onChange={(e) => setName(e)} value={name} label={"Nama Kabupaten/Kota"} placeholder={"Masukkan nama Kabupaten/Kota"} type={'text'} />
                            </div>
                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <SecondaryButton action={() => { setShowModalEdit(!showModalEdit); resetForm() }} text={'Batal'} />
                                {isLoading ?
                                    <LoadingPrimaryButton />
                                    : <PrimaryButton action={() => updateData()} text={'Simpan'} />
                                }
                            </div>
                        </div>
                    }
                />

                < ModalDelete
                    activeModal={showModalDelete}
                    buttonClose={() => setShowModalDelete(!showModalDelete)}
                    submitButton={deleteData}
                />
            </div>
            <div className='mt-[44px] flex items-start justify-end gap-3'>
                <SearchComponent onChange={handleSearch} width={200} placeholder={"Cari nama kabupaten/kota...."} />
                <IconPrimaryButton icon={IconPlus} action={() => setShowModalAdd(true)} text={"Tambah"} />
            </div>
            <div className='mt-2 overflow-auto'>
                <table className='w-full space-y-[10px]'>
                    <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                        <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                            <h1 className='text-dark-5 text-xs font-[600]'>No</h1>
                        </div>
                        <div className='flex items-center gap-[15px] min-w-[250px] max-w-[250px]'>
                            <h1 className='text-dark-5 text-xs font-[600]'>Nama Kabupaten/Kota</h1>
                        </div>
                        <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                            <h1 className='text-dark-5 text-xs font-[600]'>Provinsi</h1>
                        </div>
                        <div className='flex items-center justify-center gap-[15px] w-full'>
                            <h1 className='text-dark-5 text-xs font-[600]'>Aksi</h1>
                        </div>
                    </div>
                    {data && data.map((val, index) => (
                        <div key={index} className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                            <div className='min-w-[100px] max-w-[100px]'>
                                <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>{index + 1}</h1>
                            </div>
                            <div className='min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>{val.name ?? "-"}</h1>
                            </div>
                            <div className='min-w-[200px] max-w-[200px]'>
                                <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>{val.province_id ? dataProvince.find(option => option.value === val.province_id)?.label : "-"}</h1>
                            </div>
                            <div className='w-full flex items-center justify-center gap-[5px]'>
                                <button
                                    onClick={() => getDataById(val.id, val.name, val.province_id)}
                                    className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                    <HiOutlinePencil className='text-blue-thick' />
                                </button>
                                <button
                                    onClick={() => { deleteDataModal(val?.id); }}
                                    className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                    <AiOutlineDelete className='text-sm text-blue-thick' />
                                </button>
                            </div>
                        </div>
                    ))}
                </table>
            </div>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                limitData={limit}
                lengthData={totalCount}
                onPageChange={handlePageChange}
                onPrevChange={handlePrevChange}
                onNextChange={handleNextChange}
            />
        </div>
    )
}

export default District
