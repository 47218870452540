import React from 'react'
import { Alam, Eca, Faizal, Habib, HeaderAboutUs, Jafal, Kaka, LogoH, Rizieq, Syarif, Uli } from '../../assets'
import { Footer, NavbarLandingPage } from '../../components'

const Aboutus = () => {
    return (
        <div>
            <NavbarLandingPage
                features={'/'}
                solution={'/'}
                partnership={'/'}
                getStarted={'/'}
            />
            <div>
                <div className='h-[456px] flex flex-col items-center justify-center' style={{ backgroundImage: `url(${HeaderAboutUs})`, backgroundSize: 'cover' }}>
                    <h1 className='text-[48px] font-semibold text-white'>About Humanusia</h1>
                    <h1 className='text-white'>Explanation of what is meant by Humanusia</h1>
                </div>
                <div className='px-[300px] py-[75px] space-y-[32px]'>
                    <div className='bg-white rounded-md border shadow-md px-[120px] py-[74px] space-y-[32px]'>
                        <h1 className='text-dark-2 text-[26px] font-medium'>Know the meaning of the word "Humanusia"</h1>
                        <h1 className='text-dark-3 text-[18px] '>
                            The word "humanusia" is a neologism that is not yet widely used. It is a combination of the words "human" and "usia", which is the Indonesian word for "age".
                            The term is used to refer to the human condition, or the totality of human experience. It encompasses all aspects of human life, including physical,
                            emotional, mental, and spiritual.
                        </h1>
                        <h1 className='text-dark-3 text-[18px] '>
                            The term "humanusia" was first coined by the Indonesian philosopher, Soedjatmoko. He used it to describe the need for a new way of thinking about the human condition,
                            one that is based on the principles of peace, justice, and equality.
                        </h1>
                        <h1 className='text-dark-3 text-[18px] '>
                            The term "humanusia" has been used by a number of other writers and thinkers, including the American philosopher, John Dewey. Dewey believed that the human condition is constantly evolving,
                            and that we need to be open to new ideas and new ways of thinking.
                        </h1>
                        <h1 className='text-dark-3 text-[18px] '>
                            The term "humanusia" is a reminder that we are all human beings, and that we share a common humanity. It is a call to action, urging us to work together to create a better world for all.
                        </h1>
                        <h1 className='text-dark-3 text-[18px] '>
                            Here are some additional thoughts on the meaning of humanusia:
                        </h1>
                        <h1 className='text-dark-3 text-[18px] '>
                            * Humanusia is about the full range of human experience, from the physical to the spiritual.
                            <br />
                            * Humanusia is about the interconnectedness of all people, and the need for cooperation and compassion.
                            <br />
                            * Humanusia is about the potential for human beings to create a better world.
                        </h1>
                        <h1 className='text-dark-3 text-[18px] '>
                            The term humanusia is a powerful reminder of our shared humanity and our potential to create a better world. It is a call to action, urging us to work together to build a more just, peaceful, and equitable world for all.
                        </h1>
                    </div>
                </div>
                <div className='bg-gradient-to-t from-white via-[#953737] to-[#780000] px-[70px] py-[67px]'>
                    <h1 className='text-[48px] font-bold text-center text-white'>Humanusia’s Team Member</h1>
                    <h1 className='text-center text-white'>Brief introduction from our humanusia team</h1>
                    <div className='flex flex-wrap gap-[30px] mt-[100px] justify-center items-center'>
                        <div className='bg-white rounded-[20px] flex flex-col h-[250px] w-[236px]'>
                            <img src={Faizal} className='object-cover rounded-t-[20px] h-[180px]' />
                            <div className='bg-white rounded-b-[20px] h-[70px] border flex flex-col items-center justify-center'>
                                <h1 className='text-dark-2 font-bold text-[18px]'>Muh Faizal</h1>
                                <h1 className='text-dark-2 text-[12px]'>Project Leader</h1>
                            </div>
                        </div>
                        <div className='bg-white rounded-[20px] flex flex-col h-[250px] w-[236px]'>
                            <img src={Uli} className='object-cover rounded-t-[20px] h-[180px]' />
                            <div className='bg-white rounded-b-[20px] h-[70px] border flex flex-col items-center justify-center'>
                                <h1 className='text-dark-2 font-bold text-[18px]'>Uli Ahda Raihan</h1>
                                <h1 className='text-dark-2 text-[12px]'>UI UX Designer</h1>
                            </div>
                        </div>
                        <div className='bg-white rounded-[20px] flex flex-col h-[250px] w-[236px]'>
                            <img src={Eca} className='object-cover rounded-t-[20px] h-[180px]' />
                            <div className='bg-white rounded-b-[20px] h-[70px] border flex flex-col items-center justify-center'>
                                <h1 className='text-dark-2 font-bold text-[18px]'>Hersa Dwi</h1>
                                <h1 className='text-dark-2 text-[12px]'>UI UX Designer</h1>
                            </div>
                        </div>
                        <div className='bg-white rounded-[20px] flex flex-col h-[250px] w-[236px]'>
                            <img src={Alam} className='object-cover rounded-t-[20px] h-[180px]' />
                            <div className='bg-white rounded-b-[20px] h-[70px] border flex flex-col items-center justify-center'>
                                <h1 className='text-dark-2 font-bold text-[18px]'>M Fatkhul Allam</h1>
                                <h1 className='text-dark-2 text-[12px]'>Backend Web Dev</h1>
                            </div>
                        </div>
                        <div className='bg-white rounded-[20px] flex flex-col h-[250px] w-[236px]'>
                            <img src={Jafal} className='object-cover rounded-t-[20px] h-[180px]' />
                            <div className='bg-white rounded-b-[20px] h-[70px] border flex flex-col items-center justify-center'>
                                <h1 className='text-dark-2 font-bold text-[18px]'>Jahfal Rizqi</h1>
                                <h1 className='text-dark-2 text-[12px]'>Backend Web Dev</h1>
                            </div>
                        </div>
                        <div className='bg-white rounded-[20px] flex flex-col h-[250px] w-[236px]'>
                            <img src={Rizieq} className='object-cover rounded-t-[20px] h-[180px]' />
                            <div className='bg-white rounded-b-[20px] h-[70px] border flex flex-col items-center justify-center'>
                                <h1 className='text-dark-2 font-bold text-[18px]'>M Rizieq Fazlulrahman</h1>
                                <h1 className='text-dark-2 text-[12px]'>Frontend Web Dev</h1>
                            </div>
                        </div>
                        <div className='bg-white rounded-[20px] flex flex-col h-[250px] w-[236px]'>
                            <img src={Kaka} className='object-cover rounded-t-[20px] h-[180px]' />
                            <div className='bg-white rounded-b-[20px] h-[70px] border flex flex-col items-center justify-center'>
                                <h1 className='text-dark-2 font-bold text-[18px]'>Kaka Karsana</h1>
                                <h1 className='text-dark-2 text-[12px]'>Mobile Dev</h1>
                            </div>
                        </div>
                        <div className='bg-white rounded-[20px] flex flex-col h-[250px] w-[236px]'>
                            <img src={Habib} className='object-cover rounded-t-[20px] h-[180px]' />
                            <div className='bg-white rounded-b-[20px] h-[70px] border flex flex-col items-center justify-center'>
                                <h1 className='text-dark-2 font-bold text-[18px]'>Habib Ali Habsyi</h1>
                                <h1 className='text-dark-2 text-[12px]'>Devops Engineer</h1>
                            </div>
                        </div>
                        <div className='bg-white rounded-[20px] flex flex-col h-[250px] w-[236px]'>
                            <img src={Syarif} className='object-cover rounded-t-[20px] h-[180px]' />
                            <div className='bg-white rounded-b-[20px] h-[70px] border flex flex-col items-center justify-center'>
                                <h1 className='text-dark-2 font-bold text-[18px]'>Syarif Ridho</h1>
                                <h1 className='text-dark-2 text-[12px]'>Quality Assurance</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bg-[#A61C1C]'>
                    <div className='px-[16px] lg:px-[150px] py-[70px] flex flex-col items-center justify-center gap-4'>
                        <img src={LogoH} className='w-[70px]' />
                        <h1 className='text-center text-white text-[40px] font-semibold'>Get Started Now</h1>
                        <h1 className='text-dark-9 lg:w-[581px] text-center font-thin'>Hello and welcome to our Humanusia! We are delighted to be able to offer you a comprehensive and user-friendly tool for managing all aspects of your HR processes.</h1>
                        <a href='' className='bg-[#02508D] rounded-[6px] lg:w-[184px] h-[50px] px-[37px] py-[13px] text-white text-center'>Get Started</a>
                    </div>
                </div>
                {/* Begin : Footer */}
                <Footer />
                <div className='px-[16px] lg:px-[150px] py-[41px] space-y-[50px]'>
                    <h1 className='text-dark-4 text-center'>@2022 Humanusia. All rights reserved.</h1>
                </div>
                {/* End : Footer */}
            </div>
        </div>
    )
}

export default Aboutus