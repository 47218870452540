import React from 'react'

const SecondaryButton = ({ text, action }) => {
    return (
        <button
            onClick={action}
            className='text-blue-thick bg-gray-200 hover:bg-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2'
        >
            {text}
        </button>
    )
}

export default SecondaryButton
