import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { HiOutlinePencil } from 'react-icons/hi'
import { IoMdArrowDropdown } from 'react-icons/io'
import { MultiSelect } from 'react-multi-select-component'
import Api from '../../../../../Api'
import { Modal, ModalDelete } from '../../../../../components'
import InputComponent from '../../../../../components/Input/inputComponent'
import InputOption from '../../../../../components/Input/inputOption'
import languages from '../../../../../utils/languages'

const Potongan = () => {
    const [data, setData] = useState([])
    const getData = async () => {

        try {
            await Api.GetDeduction(localStorage.getItem('hris-token'))
                .then((response) => {
                    console.log(response.data)
                    setData(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }

    // Deduction
    const [showAddDeduction, setShowAddDeduction] = useState()
    const [showEditDeduction, setShowEditDeduction] = useState()
    const [showModalDeleteDeduction, setShowModalDeleteDeduction] = useState(false)
    const [idDeduction, setIdDeduction] = useState('')
    const [titleDeduction, setTitleDeduction] = useState('')
    const [amountDeduction, setAmountDeduction] = useState('')
    const [paymentTypeDeduction, setPaymentTypeDeduction] = useState('')
    const [taxDeduction, setTaxDeduction] = useState('')
    const [assignTo, setAssignTo] = useState('')
    const [selectedAssignTo, setSelectedAssignTo] = useState([])
    const [selected, setSelected] = useState([])

    const dataTax = [
        {
            value: "Taxable",
            label: "Kena Pajak"
        },
        {
            value: "Non Taxable",
            label: "Tidak Kena Pajak"
        }
    ]
    const getDeductionById = async (id) => {
        try {
            await Api.GetDeductionById(localStorage.getItem('hris-token'), id)
                .then((response) => {
                    const value = response.data[0]
                    setIdDeduction(value?.id)
                    setTitleDeduction(value?.title)
                    setAmountDeduction(value?.amount)
                    setPaymentTypeDeduction(value?.payment_type)
                    setTaxDeduction(value?.tax)
                    const assign_to = Object.keys(value.assign_to);
                    if (assign_to[0] === "ALL") {
                        setAssignTo(assign_to[0])
                    } else if (assign_to[0] === "job_category") {
                        setAssignTo(assign_to[0])
                        let options = {}
                        if (assign_to.length > 1) {
                            options = value.assign_to.job_category && value.assign_to.job_category.map(val => ({
                                "value": val.id,
                                "label": val.name
                            }))
                        } else {
                            options = [{
                                "value": value.assign_to.job_category.id,
                                "label": value.assign_to.job_category.name
                            }]
                        }
                        setSelected(options)
                    } else if (assign_to[0] === "job_position") {
                        setAssignTo(assign_to[0])
                        let options = {}
                        if (assign_to.length > 1) {
                            options = value.assign_to.job_position && value.assign_to.job_position.map(val => ({
                                "value": val.id,
                                "label": val.name
                            }))
                        } else {
                            options = [{
                                "value": value.assign_to.job_position.id,
                                "label": value.assign_to.job_position.name
                            }]
                        }
                        setSelected(options)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    // const e = error.response.data.errors.error.details
                    // for(let i=0; i<e.length; i++){
                    //     toast.error(error.response.data.errors.error.details[i].message)
                    // }
                })
        } catch (error) {
            console.log(error)
        }
    }
    // const deleteModalDeduction = async (id) => {
    //     setShowModalDeleteDeduction(!showModalDeleteDeduction)
    //     setIdDeduction(id)
    // }
    const deleteDeduction = async () => {
        try {
            await Api.DeleteDeduction(localStorage.getItem('hris-token'), idDeduction)
            setShowModalDeleteDeduction(!showModalDeleteDeduction)
            // setRefresh(true)
            getData()
            toast.success('Berhasil Menghapus Data!')
        } catch (error) {
            toast.error('Gagal Menghapus Data!')
        }
    }
    const resetFormDeduction = () => {
        setTitleDeduction(0)
        setAmountDeduction("")
        setPaymentTypeDeduction("")
        setTaxDeduction("")
        setAssignTo("")
    }
    const postDeduction = async () => {
        try {
            var dataAssigTo = {}
            if (assignTo === "ALL") {
                dataAssigTo.ALL = true
            } else if (assignTo === "job_grade") {
                dataAssigTo.job_grade = selectedAssignTo
            } else if (assignTo === "job_position") {
                dataAssigTo.job_position = selectedAssignTo
            } else if (assignTo === "job_level") {
                dataAssigTo.job_level = selectedAssignTo
            }
            const data = {
                "title": titleDeduction,
                "amount": amountDeduction,
                "payment_type": paymentTypeDeduction, // Enum ( monthly, weekly, daily, onceTime )
                "tax": taxDeduction, // Enum ( Taxable, Non Taxable )
                "assign_to": dataAssigTo
            }
            // console.log(data)
            await Api.PostDeduction(localStorage.getItem('hris-token'), data).then((response) => {
                setShowAddDeduction(!showAddDeduction)
                getData()
                resetFormDeduction()
                toast.success('Berhasil Menambahkan Data!')
            }).catch((error) => {
                // console.log(error)
                const e = error.response.data.errors.error.details
                for (let i = 0; i < e.length; i++) {
                    toast.error(error.response.data.errors.error.details[i].message)
                }
            })
        } catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }
    const editDeduction = async () => {
        try {
            let dataAssignTo = {}
            if (assignTo === "ALL") {
                dataAssignTo.ALL = true
            } else if (assignTo === "job_category") {
                dataAssignTo.job_category = selectedAssignTo
            } else if (assignTo === "job_position") {
                dataAssignTo.job_position = selectedAssignTo
            }
            const data = {
                "title": titleDeduction,
                "payment_type": paymentTypeDeduction, // Enum ( monthly, weekly, daily, onceTime )
                "amount": amountDeduction,
                "tax": taxDeduction, // Enum ( Taxable, Non Taxable )
                "assign_to": {
                    "ALL": true
                }
            }
            await Api.UpdateDeduction(localStorage.getItem('hris-token'), data, idDeduction).then((response) => {
                setShowEditDeduction(!showEditDeduction)
                getData()
                resetFormDeduction()
                toast.success('Berhasil Mengubah Data!')
            }).catch((error) => {
                console.log(error?.response?.data?.message)
                toast.error(error?.response?.data?.message)
                // const e = error.response.data.errors.error.details
                // for (let i = 0; i < e.length; i++) {
                //     toast.error(error.response.data.errors.error.details[i].message)
                // }
            })
        } catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }
    const handleEditDeduction = (id) => {
        setShowEditDeduction(true)
        getDeductionById(id)
    }
    const [jobGrade, setJobGrade] = useState([])
    const [jobLevel, setJobLevel] = useState([])
    const [jobPosition, setJobPosition] = useState([])
    const [jobCategory, setJobCategory] = useState([])


    const getSelectJobGrade = async () => {
        try {
            const res = await Api.GetJobGradeDropdown(localStorage.getItem('hris-token'))
            const data = res.data.results.data;

            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.job_grade
            }))

            setJobGrade(options)
        } catch (error) {
            console.log(error)
        }
    }
    const getSelectJobCategory = async () => {
        try {
            const res = await Api.GetJobCategoryDropdown(localStorage.getItem('hris-token'))
            const data = res.data.results.data;

            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.category_name
            }))

            setJobCategory(options)
        } catch (error) {
            console.log(error)
        }
    }
    const getSelectJobLevel = async () => {
        try {
            const res = await Api.GetJobLevelDropdown(localStorage.getItem('hris-token'))
            const data = res.data.results.data;

            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.level_name
            }))

            setJobLevel(options)
        } catch (error) {
            console.log(error)
        }
    }
    const getSelectJobPosition = async () => {
        try {
            const res = await Api.GetJobPositionDropdown(localStorage.getItem('hris-token'))
            const data = res.data.results.data;

            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.position_name
            }))

            setJobPosition(options)
        } catch (error) {
            console.log(error)
        }
    }
    const handleChange = (e) => {
        const data = []
        e && e.map(val => (
            data.push(val.value)
        ))
        setSelected(e)
        setSelectedAssignTo(data)
    }
    useEffect(() => {
        getData()
        getSelectJobGrade()
        getSelectJobLevel()
        getSelectJobPosition()
        getSelectJobCategory()
    }, [])

    return (
        <div >
            <div className='relative'>

                <Modal
                    activeModal={showAddDeduction}
                    title={languages.language === 'id' ? "Tambah Potongan" : "Add Deduction"}
                    buttonClose={() => { setShowAddDeduction(!showAddDeduction); resetFormDeduction() }}
                    width={'553px'}
                    content={
                        <div className='space-y-[25px] w-[full]'>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Nama Potongan" : "Deductions Name"} <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setTitleDeduction(e.target.value)} value={titleDeduction} type="text" className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder='Nama Potongan...' />
                            </div>

                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Jumlah" : "Amount"}<span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setAmountDeduction(e.target.value)} value={amountDeduction} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder='Nominal...' />
                                {/* <input
                                type="text"
                                value={value}
                                onChange={handleChangeRupiah}
                                placeholder="Masukkan angka..."
                                className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]'
                            /> */}
                            </div>

                            <div className='flex gap-[20px] w-full items-center'>
                                <div>
                                    <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Tipe Pembayaran" : "Payment Type"}<span className='text-red-primer'>*</span></h1>
                                    <div className="mb-[12px]">
                                        <div className='relative w-[233px]'>
                                            <select name="paymentTypeDeduction" onChange={(e) => setPaymentTypeDeduction(e.target.value)} value={paymentTypeDeduction} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                                <option selected value="">{languages.language === 'id' ? "Pilih Tipe Pembayaran" : "Select payment type"}</option>
                                                <option value="monthly">{languages.language === 'id' ? "Bulanan" : "Monthly"}</option>
                                                <option value="weekly">{languages.language === 'id' ? "Mingguan" : "Weekly"}</option>
                                                <option value="daily">{languages.language === 'id' ? "Harian" : "Daily"}</option>
                                                <option value="onceTime">{languages.language === 'id' ? "Satu Kali" : "One Time"}</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Pajak" : "Tax"}<span className='text-red-primer'>*</span></h1>
                                    <div className="mb-[12px]">
                                        <div className='relative w-[233px]'>
                                            <select name="" onChange={(e) => setTaxDeduction(e.target.value)} value={taxDeduction} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                                <option selected value="">{languages.language === 'id' ? "Pilih Pajak" : "Select Tax"}</option>
                                                <option value="Taxable">{languages.language === 'id' ? "Kena Pajak" : "Taxable"}</option>
                                                <option value="Non Taxable">{languages.language === 'id' ? "Tidak Kena Pajak" : "Non Taxable"}</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[8px]'>{languages.language === 'id' ? "Ditujukan Kepada" : "Assign To"}<span className='text-red-primer'>*</span></h1>
                                <div className='space-y-[16px]'>
                                    <div className='flex items-center gap-[14px] font-medium'>
                                        <input type="radio" checked={assignTo === "ALL"} id="allDeduction" name="radio-option" value='ALL' onChange={(e) => setAssignTo(e.target.value)} />
                                        <label htmlFor="allDeduction" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Semua Pegawai" : "All Employee"}</label>
                                    </div>
                                    {/* <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" id="searchDeduction" name="radio-option" value='' onChange={(e)=>setAssignTo(e.target.value)} />
                                    <label htmlFor="searchDeduction" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Cari Pegawai" : "Search Employee"}</label>
                                </div> */}
                                    <div className='flex items-center gap-[14px] font-medium'>
                                        <input type="radio" checked={assignTo === "job_grade"} id="jobGradeDeduction" name="radio-option" value='job_grade' onChange={(e) => setAssignTo(e.target.value)} />
                                        <label htmlFor="jobGradeDeduction" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Kelas Jabatan" : "Based on Job Grade"}</label>
                                    </div>
                                    {
                                        assignTo === "job_grade" ?
                                            <MultiSelect
                                                options={jobGrade}
                                                value={selected}
                                                onChange={(e) => handleChange(e)}
                                                labelledBy="Select"
                                            />
                                            : null
                                    }
                                    <div className='flex items-center gap-[14px] font-medium'>
                                        <input type="radio" checked={assignTo === "job_level"} id="jobLevelDeduction" name="radio-option" value='job_level' onChange={(e) => setAssignTo(e.target.value)} />
                                        <label htmlFor="jobLevelDeduction" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jenis Jabatan" : "Based on Job Level"}</label>
                                    </div>
                                    {
                                        assignTo === "job_level" ?
                                            <MultiSelect
                                                options={jobLevel}
                                                value={selected}
                                                onChange={(e) => handleChange(e)}
                                                labelledBy="Select"
                                            />
                                            : null
                                    }
                                    <div className='flex items-center gap-[14px] font-medium'>
                                        <input type="radio" checked={assignTo === "job_position"} id="jobPositionDeduction" name="radio-option" value='job_position' onChange={(e) => setAssignTo(e.target.value)} />
                                        <label htmlFor="jobPositionDeduction" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jabatan" : "Based on Job Position"}</label>
                                    </div>
                                    {
                                        assignTo === "job_position" ?
                                            <MultiSelect
                                                options={jobPosition}
                                                value={selected}
                                                onChange={(e) => handleChange(e)}
                                                labelledBy="Select"
                                            />
                                            : null
                                    }
                                </div>
                            </div>

                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => { setShowAddDeduction(!showAddDeduction); resetFormDeduction() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-[#003049] text-sm font-[500]'>{languages.language === 'id' ? "Batal" : "Cancel"}</h1>
                                    </button>
                                    <button onClick={postDeduction} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Simpan" : "Add"}</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditDeduction}
                    title={languages.language === 'id' ? "Edit Potongan" : "Edit Deduction"}
                    buttonClose={() => setShowEditDeduction(!showEditDeduction)}
                    width={'553px'}
                    content={
                        <div className='space-y-5 w-[full]'>
                            <div className='w-full'>
                                <InputComponent onChange={(e) => setTitleDeduction(e)} value={titleDeduction} type={"text"} placeholder={"Nominal..."} disabled={true} required={true} label={"Nama Potongan"} />
                            </div>
                            <div>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Tipe Pembayaran" : "Payment Type"}<span className='text-red-primer'>*</span></h1>
                                <div className="mb-[12px]">
                                    <div className='relative w-full'>
                                        <select name="paymentTypeDeduction" onChange={(e) => setPaymentTypeDeduction(e.target.value)} value={paymentTypeDeduction} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                            <option selected value="">{languages.language === 'id' ? "Pilih Tipe Pembayaran" : "Select payment type"}</option>
                                            <option value="monthly">{languages.language === 'id' ? "Bulanan" : "Monthly"}</option>
                                            <option value="weekly">{languages.language === 'id' ? "Mingguan" : "Weekly"}</option>
                                            <option value="daily">{languages.language === 'id' ? "Harian" : "Daily"}</option>
                                            <option value="onceTime">{languages.language === 'id' ? "Satu Kali" : "One Time"}</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                    </div>
                                </div>
                            </div>
                            <div className='flex gap-[20px] w-full items-center'>
                                <div className='w-full'>
                                    <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Jumlah" : "Amount"}<span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setAmountDeduction(e.target.value)} value={amountDeduction} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder='Nominal...' />
                                </div>
                            </div>
                            <div>
                                <InputOption label={"Pajak"} onChange={(e) => setTaxDeduction(e)} value={taxDeduction} data={dataTax} placeholder={"Pilih Pajak...."} required={true} disabled={true} />
                            </div>
                            {/* <div>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[8px]'>Ditujukan Untuk <span className='text-red-primer'>*</span></h1>
                                <div className='space-y-[16px]'>
                                    <div className='flex items-center gap-[14px] font-medium'>
                                        <input type="radio" checked={assignTo === "ALL"} id="allEdit" name="radio-option" value='ALL' onChange={(e) => setAssignTo(e.target.value)} />
                                        <label htmlFor="allEdit" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Semua Pegawai" : "All Employee"}</label>
                                    </div>

                                    <div className='flex items-center gap-[14px] font-medium'>
                                        <input type="radio" checked={assignTo === "job_category"} id="jenisKepegawaianEdit" name="radio-option" value='job_category' onChange={(e) => setAssignTo(e.target.value)} />
                                        <label htmlFor="jenisKepegawaianEdit" className='text-grey-thick font-medium text-xs'>Berdasarkan Jenis Kepegawaian</label>
                                    </div>
                                    {
                                        assignTo === "job_category" ?
                                            <MultiSelect
                                                options={jobCategory}
                                                value={selected}
                                                onChange={(e) => handleChange(e)}
                                                labelledBy="Select"
                                            />
                                            : null
                                    }
                                    <div className='flex items-center gap-[14px] font-medium'>
                                        <input type="radio" checked={assignTo === "job_position"} id="jabatanEdit" name="radio-option" value='job_position' onChange={(e) => setAssignTo(e.target.value)} />
                                        <label htmlFor="jabatanEdit" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jabatan" : "Based on Job Position"}</label>
                                    </div>
                                    {
                                        assignTo === "job_position" ?
                                            <MultiSelect
                                                options={jobPosition}
                                                value={selected}
                                                onChange={(e) => handleChange(e)}
                                                labelledBy="Select"
                                            />
                                            : null
                                    }
                                </div>
                            </div> */}

                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => { setShowEditDeduction(!showEditDeduction); resetFormDeduction() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-[#003049] text-sm font-[500]'>{languages.language === 'id' ? "Batal" : "Cancel"}</h1>
                                    </button>
                                    <button onClick={editDeduction} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Simpan" : "Add"}</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <ModalDelete
                    activeModal={showModalDeleteDeduction}
                    buttonClose={() => setShowModalDeleteDeduction(!showModalDeleteDeduction)}
                    submitButton={deleteDeduction}
                />
            </div>
            <div className='overflow-auto'>
                <table className='w-full space-y-[10px]'>
                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Nama</h1>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Tipe Pembayaran</h1>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Jumlah Pembayaran</h1>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Pajak</h1>
                        </div>
                        <div className='flex items-center gap-[6px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Aksi</h1>
                        </div>
                    </div>
                    {data && data.map((item, index) => (
                        <>
                            <div key={index} className='flex items-center gap-2  px-[14px] py-[8px] rounded-[3px]'>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.title}</h1>
                                </div>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.payment_type}</h1>
                                </div>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.amount}</h1>
                                </div>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.tax}</h1>
                                </div>
                                <div className='w-full flex items-start justify-start gap-[12px]'>
                                    <button
                                        onClick={() => handleEditDeduction(item.id)}
                                        className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                        <HiOutlinePencil className='text-[#003049]' />
                                    </button>
                                </div>
                            </div>
                        </>
                    ))}
                </table>
            </div>
        </div>
    )
}

export default Potongan
