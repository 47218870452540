import React, { useEffect, useState } from 'react'
import Api from '../../../Api'
import { Navbar } from '../../../components'
import Pendidikan from './Pendidikan'
import PerjalananDinas from './PerjalananDinas'
import Inovasi from './inovasi'
import ApprovalPAK from './approvalPAK'

const Verifikasi = () => {
    const [dataMenu] = useState([
        { value: 'verifikasi_perdin', label: 'Perjalanan Dinas' },
        { value: 'approval_pendidikan', label: 'Pendidikan' },
        { value: 'approval_penetapan_inovasi', label: 'Inovasi' },
        { value: 'verifikasi_pak', label: 'Pengajuan PAK' }
    ]);
    const [menu, setMenu] = useState(''); // Menu yang dipilih
    const [accessibleMenus, setAccessibleMenus] = useState([]); // Menu yang dapat diakses

    // Fungsi untuk mengecek akses menu
    const canAccessMenu = (hakAkses, menuValue) => {
        const access = hakAkses?.[menuValue];
        return (
            access === true || // Jika bernilai true (contoh: verifikasi_pak)
            access?.all || // Jika hak akses "all" bernilai true
            access?.penempatan || // Jika hak akses "penempatan" bernilai true
            access?.bawahan || // Jika hak akses "bawahan" bernilai true
            (access?.unitKerjaEselon && access?.unitKerjaEselon.length > 0) // Jika unitKerjaEselon memiliki data
        );
    };
    // Fungsi untuk memfilter menu berdasarkan hak akses dan role
    const filterMenus = (hakAkses, isAdmin) => {
        if (isAdmin) {
            // Jika admin, semua menu ditampilkan
            return dataMenu.filter(menu => menu.value !== 'verifikasi_pak')
        } else {
            // Filter menu berdasarkan hak akses
            return dataMenu.filter(menu => canAccessMenu(hakAkses, menu.value));
        }
    };

    // Fungsi untuk mengambil data dari API
    const Fetch = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'));
            const user = response.data.results.user;

            // Perbarui menu yang dapat diakses
            const filteredMenus = filterMenus(user.hak_akses, user.role === 'ADMIN');
            setAccessibleMenus(filteredMenus);

            // Set menu default ke menu pertama yang tersedia
            if (filteredMenus.length > 0) {
                setMenu(filteredMenus[0].value);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        Fetch();
    }, []);

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar="Verifikasi" LinkBack={'/dashboard'} />
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='border-b-2 flex flex-wrap items-center text-[16px]'>
                        {accessibleMenus.map(val => (
                            <button onClick={() => setMenu(val.value)} className={`${menu === val.value ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-5`}>{val.label}</button>
                        ))}
                    </div>

                    {menu === 'verifikasi_perdin' ?
                        <PerjalananDinas />
                        : menu === 'approval_pendidikan' ?
                            <Pendidikan />
                            : menu === 'approval_penetapan_inovasi' ?
                                <Inovasi />
                                : menu === 'verifikasi_pak' ?
                                    <ApprovalPAK />
                                    : null}
                </div>
            </div>
        </div>
    )
}

export default Verifikasi
