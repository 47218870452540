import { saveAs } from 'file-saver'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { IoIosSearch, IoMdArrowDropdown } from "react-icons/io"
import { TbFileExport } from "react-icons/tb"
import { useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx'
import Api from '../../../../Api'
import { Pagination } from '../../../../components'
import { handleLink } from '../../../../utils/handleLink'
import InsentifAsisten from './OutputPerhitungan/InsentifAsisten'
import Lembur from './OutputPerhitungan/Lembur'
import TransportasiKaper from './OutputPerhitungan/TransportasiKaper'
import TunjanganKinerjaAsn from './OutputPerhitungan/TunjanganKinerjaAsn'
import UangMakanAsn from './OutputPerhitungan/UangMakanAsn'

function OutputPerhitungan({ idcompany }) {
    const [company, setCompany] = useState("")
    const [type, setType] = useState("tukin-asn")
    const [month, setMonth] = useState(moment().format('M'))
    const [year, setYear] = useState(moment().format('Y'))
    const [search, setSearch] = useState("")
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [totalCount, setTotalCount] = useState(1)
    const [refresh, setRefresh] = useState(false)
    const [dataSubsidiary, setDataSubsidiary] = useState([])
    const [dataPerhitungan, setDataPerhitungan] = useState([])
    const [columnPerhitungan, setColumnPerhitungan] = useState([])

    const getRekapPerhitungan = async () => {
        try {
            await Api.GetRekapPerhitungan(localStorage.getItem('hris-token'), type, month, year, limit, page, company, search).then((response) => {
                console.log("perhitungan", response.data.results.data)
                setTotalCount(response.data.results.totalCount)
                setDataPerhitungan(response.data.results.data)
                setColumnPerhitungan(Object.keys(response.data.results.data[0]))
                setTotalPages(response.data.results.totalPages)
            })
        } catch (error) {
            console.log(error)
        }
    }
    const [userId, setUserId] = useState("")
    const FetchUser = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setUserId(response.data.results.user.id)
        } catch (error) {
            console.log(error)
        }
    }
    const handlePerhitungan = (m, y, s) => {
        if (m) {
            setMonth(m.target.value)
            setRefresh(true)
        } else if (y) {
            setYear(y.target.value)
            setRefresh(true)
        } else if (s) {
            setCompany(s.target.value)
            setRefresh(true)
        }
    }
    const getSubsidiary = async () => {
        try {
            const response = await Api.GetSubsidiaryDropdown(localStorage.getItem('hris-token'))
            setDataSubsidiary(response.data.results.data)
        } catch (error) {
            console.log(error)
        }
    }
    const handlePageChange = (val) => {
        setPage(val);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (page === 1) {
            setPage(1)
        } else {
            setPage(page - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (page === 10) {
            setPage(10)
        } else {
            setPage(page + 1);
        }
        setRefresh(true)
    };
    const getDataExportTunjanganAsn = async () => {
        try {
            await Api.GetRekapPerhitungan(localStorage.getItem('hris-token'), type, month, year, totalCount, 1, company, search).then((response) => {
                const body = response.data.results.data;
                console.log(body)
                // Define custom headers for each table
                const Headers = ["NIP", "Nama", "Pangkat/Golongan", "Kelas Jabatan", "Nilai Kinerja", "Tunjangan Kinerja", "Pot Rapel", `Pot ${moment(month).format("MMMM")}`, "Pengurangan Tunjangan", "Tunjangan Bruto", "Tunjangan Pajak", "Pagu Dibayarkan", "Nama Rekening BNI", "Nomor Rekening BNI"];

                // Create modified data arrays with custom headers
                const data = body.map(({ nip, fullname, pangkat, kelas_jabatan, nilai_kinerja, tunjangan_kinerja, pot_rapel, potongan, pengurangan_tunjangan, tunjangan_bruto, tunjangan_pajak, pagu_dibayarkan, nama_rekening, nomor_rekening }) => ({
                    "NIP": nip,
                    "Nama": fullname,
                    "Pangkat/Golongan": pangkat,
                    "Kelas Jabatan": kelas_jabatan,
                    "Nilai Kinerja": nilai_kinerja,
                    "Tunjangan Kinerja": tunjangan_kinerja,
                    "Pot Rapel": pot_rapel,
                    [`Pot ${moment(month).format("MMMM")}`]: potongan,
                    "Pengurangan Tunjangan": pengurangan_tunjangan,
                    "Tunjangan Bruto": tunjangan_bruto,
                    "Tunjangan Pajak": tunjangan_pajak,
                    "Pagu Dibayarkan": pagu_dibayarkan,
                    "Nama Rekening BNI": nama_rekening,
                    "Nomor Rekening BNI": nomor_rekening
                }));

                // Create a new worksheet for each table
                const worksheetGrade = XLSX.utils.json_to_sheet(data, { header: Headers });

                // Create a new workbook
                const workbook = XLSX.utils.book_new();

                // Add the worksheets to the workbook
                XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Output Perhitungan');
                // Generate Excel file buffer
                const excelBuffer = XLSX.write(workbook, {
                    bookType: 'xlsx',
                    type: 'array',
                });

                // Convert buffer to Blob
                const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Save the Excel file using FileSaver.js
                saveAs(excelBlob, 'Output Perhitungan.xlsx');
            })
        } catch (error) {
            console.log(error)
        }
    }
    const getDataExportUangMakanAsn = async () => {
        try {
            await Api.GetRekapPerhitungan(localStorage.getItem('hris-token'), type, month, year, totalCount, 1, company, search).then((response) => {
                const body = response.data.results.data;
                console.log(body)
                // Define custom headers for each table
                const Headers = ["NIP", "Nama", "Golongan", "Jml Tidak Masuk Rapel", "A", "C", "I", "S", "DL", "Jml. Hari Kerja", "Jml. Tidak Masuk Bulan Ini", "Jml. Kehadiran (10-11)", "Uang Makan", "Uang Makan Bruto", "Pot Pajak", "Pagu Dibayarkan", "Nama Rekening BNI", "Nomor Rekening BNI"];

                // Create modified data arrays with custom headers
                const data = body.map(({ nip, fullname, golongan, jumlah_tidak_masuk_rapel, alpha, cuti, izin, sakit, dinas_luar, jumlah_hari_kerja, jumlah_tidak_masuk, jumlah_kehadiran, uang_makan, uang_makan_bruto, potongan_pajak, pagu_dibayarkan, nama_rekening, nomor_rekening }) => ({
                    "NIP": nip,
                    "Nama": fullname,
                    "Golongan": golongan,
                    "Jml Tidak Masuk Rapel": jumlah_tidak_masuk_rapel,
                    "A": alpha,
                    "C": cuti,
                    "I": izin,
                    "S": sakit,
                    "DL": dinas_luar,
                    "Jml. Hari Kerja": jumlah_hari_kerja,
                    "Jml. Tidak Masuk Bulan Ini": jumlah_tidak_masuk,
                    "Jml. Kehadiran (10-11)": jumlah_kehadiran,
                    "Uang Makan": uang_makan,
                    "Uang Makan Bruto": uang_makan_bruto,
                    "Pot Pajak": potongan_pajak,
                    "Pagu Dibayarkan": pagu_dibayarkan,
                    "Nama Rekening BNI": nama_rekening,
                    "Nomor Rekening BNI": nomor_rekening
                }));

                // Create a new worksheet for each table
                const worksheetGrade = XLSX.utils.json_to_sheet(data, { header: Headers });

                // Create a new workbook
                const workbook = XLSX.utils.book_new();

                // Add the worksheets to the workbook
                XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Output Perhitungan');
                // Generate Excel file buffer
                const excelBuffer = XLSX.write(workbook, {
                    bookType: 'xlsx',
                    type: 'array',
                });

                // Convert buffer to Blob
                const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Save the Excel file using FileSaver.js
                saveAs(excelBlob, 'Output Perhitungan.xlsx');
            })
        } catch (error) {
            console.log(error)
        }
    }
    const getDataExportInsentifAsisten = async () => {
        try {
            await Api.GetRekapPerhitungan(localStorage.getItem('hris-token'), type, month, year, totalCount, 1, company, search).then((response) => {
                const body = response.data.results.data;
                console.log(body)
                // Define custom headers for each table
                const Headers = ["NIP", "Nama", "Jenjang Jabatan", "Kelas Jabatan", "Laporan Kinerja", "Insentif Kerja", `Pot ${moment(month).format("MMMM")}`, "Potongan Insentif", "Insentif Bruto", "Potongan Pajak", "Potongan Iuran BPJS Ketenagakerjaan", "Jumlah Potongan", "Pagu Dibayarkan", "Nama Rekening BNI", "Nomor Rekening BNI"];

                // Create modified data arrays with custom headers
                const data = body.map(({ nip, fullname, jenjang_jabatan, kelas_jabatan, insentif_kerja, potongan_insentif, insentif_bruto, potongan_pajak, potongan_iuran_bpjs, jumlah_potongan, pagu_dibayarkan, nama_rekening, nomor_rekening, laporan_kinerja }) => ({
                    "NIP": nip,
                    "Nama": fullname,
                    "Jenjang Jabatan": jenjang_jabatan,
                    "Kelas Jabatan": kelas_jabatan,
                    "Laporan Kinerja": laporan_kinerja,
                    "Insentif Kerja": insentif_kerja,
                    [`Pot ${moment(month).format("MMMM")}`]: "",
                    "Potongan Insentif": potongan_insentif,
                    "Insentif Bruto": insentif_bruto,
                    "Potongan Pajak": potongan_pajak,
                    "Potongan Iuran BPJS Ketenagakerjaan": potongan_iuran_bpjs,
                    "Jumlah Potongan": jumlah_potongan,
                    "Pagu Dibayarkan": pagu_dibayarkan,
                    "Nama Rekening BNI": nama_rekening,
                    "Nomor Rekening BNI": nomor_rekening
                }));

                // Create a new worksheet for each table
                const worksheetGrade = XLSX.utils.json_to_sheet(data, { header: Headers });

                // Create a new workbook
                const workbook = XLSX.utils.book_new();

                // Add the worksheets to the workbook
                XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Output Perhitungan');
                // Generate Excel file buffer
                const excelBuffer = XLSX.write(workbook, {
                    bookType: 'xlsx',
                    type: 'array',
                });

                // Convert buffer to Blob
                const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Save the Excel file using FileSaver.js
                saveAs(excelBlob, 'Output Perhitungan.xlsx');
            })
        } catch (error) {
            console.log(error)
        }
    }
    const getDataExportTransportasiKaper = async () => {
        try {
            await Api.GetRekapPerhitungan(localStorage.getItem('hris-token'), type, month, year, totalCount, 1, company, search).then((response) => {
                const body = response.data.results.data;
                console.log(body)
                // Define custom headers for each table
                const Headers = ["Kantor Perwakilan", "Nama", "Jml. Hari Kerja", "A", "C", "I", "S", "DL", "Jml. Tidak Masuk", "Jml. Kehadiran", "Jml. Potongan", "Pagu Dibayarkan", "Nama Rekening", "Nomor Rekening"];

                // Create modified data arrays with custom headers
                const data = body.map(({ kantor_perwakilan, fullname, jumlah_hari_kerja, alpha, cuti, izin, sakit, dinas_luar, jumlah_tidak_masuk, jumlah_kehadiran, jumlah_potongan, pagu_dibayarkan, nama_rekening, nomor_rekening }) => ({
                    "Kantor Perwakilan": kantor_perwakilan,
                    "Nama": fullname,
                    "Jml. Hari Kerja": jumlah_hari_kerja,
                    "A": alpha,
                    "C": cuti,
                    "I": izin,
                    "S": sakit,
                    "DL": dinas_luar,
                    "Jml. Tidak Masuk": jumlah_tidak_masuk,
                    "Jml. Kehadiran": jumlah_kehadiran,
                    "Jml. Potongan": jumlah_potongan,
                    "Pagu Dibayarkan": pagu_dibayarkan,
                    "Nama Rekening": nama_rekening,
                    "Nomor Rekening": nomor_rekening
                }));

                // Create a new worksheet for each table
                const worksheetGrade = XLSX.utils.json_to_sheet(data, { header: Headers });

                // Create a new workbook
                const workbook = XLSX.utils.book_new();

                // Add the worksheets to the workbook
                XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Output Perhitungan');
                // Generate Excel file buffer
                const excelBuffer = XLSX.write(workbook, {
                    bookType: 'xlsx',
                    type: 'array',
                });

                // Convert buffer to Blob
                const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Save the Excel file using FileSaver.js
                saveAs(excelBlob, 'Output Perhitungan.xlsx');
            })
        } catch (error) {
            console.log(error)
        }
    }
    const GetExportLembur = async (type) => {
        try {
            window.location.href = handleLink(`penghasilan/output-perhitungan/export-lembur?bulan=${month}&tahun=${year}&limit=${limit}&page=${page}&companyId=${company}&search=${search}&type=${type}&userId=${userId}`)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getRekapPerhitungan()
        getSubsidiary()
        setRefresh(false)
    }, [refresh, type, search])
    useEffect(() => {
        FetchUser()
    }, [])
    return (
        <div className='space-y-[20px]'>
            <div className='space-y-[4]'>
                <label className='text-grey-primer text-[20px] font-semibold'>Output Perhitungan</label>
                <p className='text-grey-medium text-xs'>Reporting Data Penghasilan Pegawai</p>
            </div>
            <div className='flex flex-wrap items-center gap-3 justify-between'>
                <div className='flex flex-wrap items-center gap-3'>
                    <div className='relative'>
                        <select onChange={(e) => setType(e.target.value)} value={type} className='text-grey-thick text-xs outline-none font-medium rounded-md w-[200px] border border-dark-7 py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                            <option selected disabled value={''}>Pilih Jenis</option>
                            <option value={'tukin-asn'} className='text-grey-medium font-normal'>Tunjangan Kinerja ASN</option>
                            <option value={'uang-makan-asn'} className='text-grey-medium font-normal'>Uang Makan ASN</option>
                            <option value={'insentif-asisten'} className='text-grey-medium font-normal'>Insentif Kerja Asisten</option>
                            <option value={'transportasi-kaper'} className='text-grey-medium font-normal'>Tunjangan Transportasi Kepala Perwakilan</option>
                            <option value={'lembur-ASN'} className='text-grey-medium font-normal'>Lembur ASN</option>
                            <option value={'lembur-ASISTEN'} className='text-grey-medium font-normal'>Lembur Asisten</option>
                        </select>
                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                    </div>
                    <div className='relative'>
                        <select onChange={(e) => handlePerhitungan(e, null, null)} value={month} className='text-grey-thick text-xs outline-none font-medium rounded-md w-[150px] border border-dark-7 py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                            <option selected disabled value={''}>Pilih Bulan</option>
                            <option value={'1'} className='text-grey-medium font-normal'>Januari</option>
                            <option value={'2'} className='text-grey-medium font-normal'>Februari</option>
                            <option value={'3'} className='text-grey-medium font-normal'>Maret</option>
                            <option value={'4'} className='text-grey-medium font-normal'>April</option>
                            <option value={'5'} className='text-grey-medium font-normal'>Mei</option>
                            <option value={'6'} className='text-grey-medium font-normal'>Juni</option>
                            <option value={'7'} className='text-grey-medium font-normal'>Juli</option>
                            <option value={'8'} className='text-grey-medium font-normal'>Agustus</option>
                            <option value={'9'} className='text-grey-medium font-normal'>September</option>
                            <option value={'10'} className='text-grey-medium font-normal'>Oktober</option>
                            <option value={'11'} className='text-grey-medium font-normal'>November</option>
                            <option value={'12'} className='text-grey-medium font-normal'>Desember</option>
                        </select>
                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                    </div>
                    <div className='relative'>
                        <select onChange={(e) => handlePerhitungan(null, e, null)} value={year} className='text-grey-thick text-xs outline-none font-medium rounded-md w-[100px] border border-dark-7 py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                            <option selected disabled value={''} className='text-grey-medium font-normal'>Pilih Tahun</option>
                            <option value={'2023'} className='text-grey-medium font-normal'>2023</option>
                            <option value={'2024'} className='text-grey-medium font-normal'>2024</option>
                            <option value={'2025'} className='text-grey-medium font-normal'>2025</option>
                            <option value={'2026'} className='text-grey-medium font-normal'>2026</option>
                            <option value={'2027'} className='text-grey-medium font-normal'>2027</option>
                            <option value={'2028'} className='text-grey-medium font-normal'>2028</option>
                            <option value={'2029'} className='text-grey-medium font-normal'>2029</option>
                            <option value={'2030'} className='text-grey-medium font-normal'>2030</option>
                        </select>
                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                    </div>
                    {dataSubsidiary?.length > 0 ?
                        <div className='relative'>
                            <select onChange={(e) => handlePerhitungan(null, null, e)} className='text-grey-thick text-[11px] outline-none font-medium rounded-md w-[227px] border border-dark-7 py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                <option value={""} selected className='text-grey-medium font-normal'>Pilih Penempatan</option>
                                {dataSubsidiary && dataSubsidiary.map((val, index) => (
                                    <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.company_name}</option>
                                ))}
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                        </div>
                        : null
                    }
                    <button onClick={() =>
                        type === "tukin-asn" ? (
                            getDataExportTunjanganAsn()
                        ) : type === "insentif-asisten" ? (
                            getDataExportInsentifAsisten()
                        ) : type === "uang-makan-asn" ? (
                            getDataExportUangMakanAsn()
                        ) : type === "transportasi-kaper" ? (
                            getDataExportTransportasiKaper()
                        ) : type === "lembur-ASN" ? (
                            GetExportLembur("ASN")
                        ) : type === "lembur-ASISTEN" ? (
                            GetExportLembur("ASISTEN")
                        ) : null
                    } className='bg-white border border-dark-7 lg:w-fit w-full rounded-[6px] flex items-center gap-[12px] py-[11px] pl-[9px] pr-[16px]'>
                        <TbFileExport className='text-grey-thick' />
                        <h1 className='text-grey-thick text-xs font-medium'>Ekspor</h1>
                    </button>
                </div>
                <div className='flex items-center gap-[14px] border border-grey-extra-thin rounded-[8px] px-[13px] py-[11px] lg:min-w-[250px] min-w-[100px]'>
                    <IoIosSearch className='text-[#8E95A2]' />
                    <input onChange={(e) => setSearch(e.target.value)} className='text-[#8E95A2] text-[10px] outline-none appearance-none' placeholder='Cari Nama Pegawai...' />
                </div>
            </div>
            <div className='overflow-auto'>
                {
                    type === "tukin-asn" ? (
                        <TunjanganKinerjaAsn data={dataPerhitungan} month={month} />
                    ) : type === "insentif-asisten" ? (
                        <InsentifAsisten data={dataPerhitungan} month={month} />
                    ) : type === "uang-makan-asn" ? (
                        <UangMakanAsn data={dataPerhitungan} month={month} />
                    ) : type === "transportasi-kaper" ? (
                        <TransportasiKaper data={dataPerhitungan} month={month} />
                    ) : type === "lembur-ASN" ? (
                        <Lembur data={dataPerhitungan} month={month} />
                    ) : type === "lembur-ASISTEN" ? (
                        <Lembur data={dataPerhitungan} month={month} />
                    ) : null
                }
            </div>
            <Pagination
                currentPage={page}
                totalPages={totalPages}
                lengthData={totalCount}
                limitData={limit}
                onPageChange={handlePageChange}
                onPrevChange={handlePrevChange}
                onNextChange={handleNextChange}
            />
        </div>
    )
}

export default OutputPerhitungan