import React from 'react'
import { AiOutlineCalendar, AiOutlineUser } from 'react-icons/ai'
import { HiArrowNarrowRight } from 'react-icons/hi'
import LazyLoad from 'react-lazy-load'
import { Link } from 'react-router-dom'
import { Blog1, Blog2, Blog3 } from '../../../assets'
import { Footer, HelmetReact, NavbarLandingPage } from '../../../components'

const MainBlog = () => {
    return (
        <div className='scroll-smooth'>

            <HelmetReact
                title={'Blog mengenai humanusia dan hris!'}
                description={''}
            />


            <NavbarLandingPage
                features={'#features'}
                pricing={'#pricing'}
            />

            {/* Begin : Section 1 */}
            <div className='px-[16px] lg:px-[150px] py-[75px] space-y-[25px]'>
                <div>
                    <h1 className='text-[#780000] font-bold text-[28px]'>Blog</h1>
                    <hr className='border-[#780000] border-2' />
                </div>
                <div className='space-y-[24px]'>
                    <div className='flex gap-[15px]'>
                        <img src={Blog1} className='min-w-[508px] h-[282px] object-cover rounded-xl' alt='' />
                        <div className='space-y-[17px]'>
                            <h2 className='text-[#003049] font-bold text-[32px]'>Humanusia: Menciptakan Pengalaman Pengelolaan SDM yang Berfokus pada Karyawan</h2>
                            <div className='flex items-center gap-[11px]'>
                                <div className='flex items-center gap-[7px]'>
                                    <AiOutlineCalendar className='text-sm text-dark-5' />
                                    <p className='text-dark-5 text-sm'>July, 18 2023</p>
                                </div>
                                <div className='w-[1.5px] h-[26px] bg-dark-7 rounded-full' />
                                <div className='flex items-center gap-[7px]'>
                                    <AiOutlineUser className='text-sm text-dark-5' />
                                    <p className='text-dark-5 text-sm'>Humanusia</p>
                                </div>
                            </div>
                            <p className='text-sm text-dark-5 line-clamp-4'>Humanusia berguna untuk pengelolaan sumber daya manusia (SDM) merupakan aspek yang penting bagi setiap organisasi. Salah satu alat yang dapat memperkuat pengelolaan SDM adalah Aplikasi Sistem Informasi Sumber Daya Manusia (HRIS). HRIS adalah platform perangkat lunak yang dirancang khusus untuk membantu perusahaan dalam mengelola berbagai aspek HR, termasuk rekrutmen, pelatihan, penggajian, manajemen kinerja, dan administrasi karyawan.</p>
                            <Link to={'/detail-blog/menciptakan-pengalaman-pengelolaan-sdm-yang-berfokus-pada-karyawan'} className='flex items-center gap-[15px]'>
                                <p className='text-[#780000] text-sm font-medium'>Baca Selanjutnya</p>
                                <HiArrowNarrowRight className='text-[#780000]' />
                            </Link>
                        </div>
                    </div>
                    <div className='flex gap-[15px]'>
                        <img src={Blog2} className='min-w-[508px] h-[282px] object-cover rounded-xl' alt='' />
                        <div className='space-y-[17px]'>
                            <h2 className='text-[#003049] font-bold text-[32px]'>Maksimalkan Efisiensi SDM dengan Humansia yang unggul dengan Fitur-Fitur HRIS Terbaik</h2>
                            <div className='flex items-center gap-[11px]'>
                                <div className='flex items-center gap-[7px]'>
                                    <AiOutlineCalendar className='text-sm text-dark-5' />
                                    <p className='text-dark-5 text-sm'>July, 18 2023</p>
                                </div>
                                <div className='w-[1.5px] h-[26px] bg-dark-7 rounded-full' />
                                <div className='flex items-center gap-[7px]'>
                                    <AiOutlineUser className='text-sm text-dark-5' />
                                    <p className='text-dark-5 text-sm'>Humanusia</p>
                                </div>
                            </div>
                            <p className='text-sm text-dark-5 line-clamp-4'>Humansia dapat membantu bisnis dalam mengoptimalkan efisiensi dan produktivitas tim SDM. Dengan fitur-fitur HRIS terbaiknya, Humansia memungkinkan perusahaan untuk mengotomatisasi tugas-tugas rutin dan mengintegrasikan proses pengelolaan sumber daya manusia menjadi satu platform yang efisien.</p>
                            <Link to={'/detail-blog/maksimalkan-efisiensi-sdm-dengan-humansia-yang-unggul-dengan-fitur-fitur-hris-terbaik'} className='flex items-center gap-[15px]'>
                                <p className='text-[#780000] text-sm font-medium'>Baca Selanjutnya</p>
                                <HiArrowNarrowRight className='text-[#780000]' />
                            </Link>
                        </div>
                    </div>
                    <div className='flex gap-[15px]'>
                        <img src={Blog3} className='min-w-[508px] h-[282px] object-cover rounded-xl' alt='' />
                        <div className='space-y-[17px]'>
                            <h2 className='text-[#003049] font-bold text-[32px]'>Humanusia sebagai Rekomendasi Sistem Manajemen HR dengan Harga Terjangkau</h2>
                            <div className='flex items-center gap-[11px]'>
                                <div className='flex items-center gap-[7px]'>
                                    <AiOutlineCalendar className='text-sm text-dark-5' />
                                    <p className='text-dark-5 text-sm'>July, 18 2023</p>
                                </div>
                                <div className='w-[1.5px] h-[26px] bg-dark-7 rounded-full' />
                                <div className='flex items-center gap-[7px]'>
                                    <AiOutlineUser className='text-sm text-dark-5' />
                                    <p className='text-dark-5 text-sm'>Humanusia</p>
                                </div>
                            </div>
                            <p className='text-sm text-dark-5 line-clamp-4'>Dalam upaya untuk membantu bisnis skala menengah meningkatkan efisiensi dan produktivitas sumber daya manusia, Humanusia hadir sebagai solusi manajemen HR yang inovatif dengan harga terjangkau. Platform HRIS (Human Resource Information System) ini menawarkan fitur-fitur terbaik yang dapat membantu perusahaan mengelola sumber daya manusia secara efisien, meningkatkan produktivitas, dan mengurangi beban administrasi.</p>
                            <Link to={'/detail-blog/maksimalkan-efisiensi-sdm-dengan-humansia-yang-unggul-dengan-fitur-fitur-hris-terbaik'} className='flex items-center gap-[15px]'>
                                <p className='text-[#780000] text-sm font-medium'>Baca Selanjutnya</p>
                                <HiArrowNarrowRight className='text-[#780000]' />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Begin : Section 2 */}
            <LazyLoad offset={400} threshold={0.95}>
                <div className='px-[16px] lg:px-[150px] py-[75px] space-y-[25px]'>
                    <div>
                        <h1 className='text-[#780000] font-bold text-[28px]'>Terbaru</h1>
                        <hr className='border-[#780000] border-2' />
                    </div>
                    <div className='grid grid-cols-12 gap-[14px]'>
                        <div className='col-span-4'>
                            <img src={Blog1} className='w-full h-[232px] object-cover rounded-xl' alt='' />
                            <div className='space-y-[17px]'>
                                <h2 className='text-[#003049] font-bold text-[32px] line-clamp-2'>Humanusia: Menciptakan Pengalaman Pengelolaan SDM yang Berfokus pada Karyawan</h2>
                                <div className='flex items-center gap-[11px]'>
                                    <div className='flex items-center gap-[7px]'>
                                        <AiOutlineCalendar className='text-sm text-dark-5' />
                                        <p className='text-dark-5 text-sm'>July, 18 2023</p>
                                    </div>
                                    <div className='w-[1.5px] h-[26px] bg-dark-7 rounded-full' />
                                    <div className='flex items-center gap-[7px]'>
                                        <AiOutlineUser className='text-sm text-dark-5' />
                                        <p className='text-dark-5 text-sm'>Humanusia</p>
                                    </div>
                                </div>
                                <p className='text-sm text-dark-5 line-clamp-2'>Humanusia berguna untuk pengelolaan sumber daya manusia (SDM) merupakan aspek yang penting bagi setiap organisasi. Salah satu alat yang dapat memperkuat pengelolaan SDM adalah Aplikasi Sistem Informasi Sumber Daya Manusia (HRIS). HRIS adalah platform perangkat lunak yang dirancang khusus untuk membantu perusahaan dalam mengelola berbagai aspek HR, termasuk rekrutmen, pelatihan, penggajian, manajemen kinerja, dan administrasi karyawan.</p>
                                <Link to={'/detail-blog/menciptakan-pengalaman-pengelolaan-sdm-yang-berfokus-pada-karyawan'} className='flex items-center gap-[15px]'>
                                    <p className='text-[#780000] text-sm font-medium'>Baca Selanjutnya</p>
                                    <HiArrowNarrowRight className='text-[#780000]' />
                                </Link>
                            </div>
                        </div>
                        <div className='col-span-4'>
                            <img src={Blog2} className='w-full h-[232px] object-cover rounded-xl' alt='' />
                            <div className='space-y-[17px]'>
                                <h2 className='text-[#003049] font-bold text-[32px] line-clamp-2'>Maksimalkan Efisiensi SDM dengan Humansia yang unggul dengan Fitur-Fitur HRIS Terbaik</h2>
                                <div className='flex items-center gap-[11px]'>
                                    <div className='flex items-center gap-[7px]'>
                                        <AiOutlineCalendar className='text-sm text-dark-5' />
                                        <p className='text-dark-5 text-sm'>July, 18 2023</p>
                                    </div>
                                    <div className='w-[1.5px] h-[26px] bg-dark-7 rounded-full' />
                                    <div className='flex items-center gap-[7px]'>
                                        <AiOutlineUser className='text-sm text-dark-5' />
                                        <p className='text-dark-5 text-sm'>Humanusia</p>
                                    </div>
                                </div>
                                <p className='text-sm text-dark-5 line-clamp-2'>Humansia dapat membantu bisnis dalam mengoptimalkan efisiensi dan produktivitas tim SDM. Dengan fitur-fitur HRIS terbaiknya, Humansia memungkinkan perusahaan untuk mengotomatisasi tugas-tugas rutin dan mengintegrasikan proses pengelolaan sumber daya manusia menjadi satu platform yang efisien.</p>
                                <Link to={'/detail-blog/maksimalkan-efisiensi-sdm-dengan-humansia-yang-unggul-dengan-fitur-fitur-hris-terbaik'} className='flex items-center gap-[15px]'>
                                    <p className='text-[#780000] text-sm font-medium'>Baca Selanjutnya</p>
                                    <HiArrowNarrowRight className='text-[#780000]' />
                                </Link>
                            </div>
                        </div>
                        <div className='col-span-4'>
                            <img src={Blog3} className='w-full h-[232px] object-cover rounded-xl' alt='' />
                            <div className='space-y-[17px]'>
                                <h2 className='text-[#003049] font-bold text-[32px] line-clamp-2'>Humanusia sebagai Rekomendasi Sistem Manajemen HR dengan Harga Terjangkau</h2>
                                <div className='flex items-center gap-[11px]'>
                                    <div className='flex items-center gap-[7px]'>
                                        <AiOutlineCalendar className='text-sm text-dark-5' />
                                        <p className='text-dark-5 text-sm'>July, 18 2023</p>
                                    </div>
                                    <div className='w-[1.5px] h-[26px] bg-dark-7 rounded-full' />
                                    <div className='flex items-center gap-[7px]'>
                                        <AiOutlineUser className='text-sm text-dark-5' />
                                        <p className='text-dark-5 text-sm'>Humanusia</p>
                                    </div>
                                </div>
                                <p className='text-sm text-dark-5 line-clamp-2'>Dalam upaya untuk membantu bisnis skala menengah meningkatkan efisiensi dan produktivitas sumber daya manusia, Humanusia hadir sebagai solusi manajemen HR yang inovatif dengan harga terjangkau. Platform HRIS (Human Resource Information System) ini menawarkan fitur-fitur terbaik yang dapat membantu perusahaan mengelola sumber daya manusia secara efisien, meningkatkan produktivitas, dan mengurangi beban administrasi.</p>
                                <Link to={'/detail-blog/humanusia-sebagai-rekomendasi-sistem-manajemen-hr-dengan-harga-terjangkau'} className='flex items-center gap-[15px]'>
                                    <p className='text-[#780000] text-sm font-medium'>Baca Selanjutnya</p>
                                    <HiArrowNarrowRight className='text-[#780000]' />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </LazyLoad>

            <LazyLoad offset={400} threshold={0.95}>
                <Footer />
            </LazyLoad>
            <div className='px-[16px] lg:px-[150px] py-[41px] space-y-[50px]'>
                <h1 className='text-dark-4 text-center'>@2022 Humanusia. All rights reserved.</h1>
            </div>

        </div>
    )
}

export default MainBlog