import React from 'react'
import { Link } from 'react-router-dom'
import { Navbar } from '../../../../components'

const PerformanceSetting = () => {
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Laporan Kinerja'} LinkBack={'/dashboard'} />
                <div className='space-y-[24px]'>
                    <h1 className="text-zinc-800 text-2xl font-semibold">Pengaturan Laporan Kinerja</h1>
                    <div className='space-y-[12px]'>
                        <Link to={'/performance-setting/perilaku-kerja'} className='bg-white px-[40px] py-[21px] rounded-md border flex items-center justify-between w-full'>
                            <h1 className="w-[550px] text-black text-xl font-medium text-start">Pengaturan Perilaku Kerja</h1>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M9.5 6L15.5 12L9.5 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Link>
                        <Link to={'/performance-setting/indikator-kuantitatif'} className='bg-white px-[40px] py-[21px] rounded-md border flex items-center justify-between w-full'>
                            <h1 className="w-[550px] text-black text-xl font-medium text-start">Pengaturan Indikator Kuantitatif</h1>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M9.5 6L15.5 12L9.5 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Link>
                        <Link to={'/performance-setting/tugas-struktural-asisten'} className='bg-white px-[40px] py-[21px] rounded-md border flex items-center justify-between w-full'>
                            <h1 className="w-[550px] text-black text-xl font-medium text-start">Pengaturan Tugas Struktural Asisten</h1>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M9.5 6L15.5 12L9.5 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Link>
                        <Link to={'/performance-setting/tugas-pokok'} className='bg-white px-[40px] py-[21px] rounded-md border flex items-center justify-between w-full'>
                            <h1 className="w-[550px] text-black text-xl font-medium text-start">Pengaturan Tugas Pokok</h1>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M9.5 6L15.5 12L9.5 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Link>
                        <Link to={'/performance-setting/tugas-tambahan'} className='bg-white px-[40px] py-[21px] rounded-md border flex items-center justify-between w-full'>
                            <h1 className="w-[550px] text-black text-xl font-medium text-start">Pengaturan Tugas Tambahan</h1>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M9.5 6L15.5 12L9.5 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Link>
                        <Link to={'/performance-setting/angka-kredit'} className='bg-white px-[40px] py-[21px] rounded-md border flex items-center justify-between w-full'>
                            <h1 className="w-[550px] text-black text-xl font-medium text-start">Pengaturan Angka Kredit Minimum</h1>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M9.5 6L15.5 12L9.5 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Link>
                        <Link to={'/performance-setting/kelompok-kerja'} className='bg-white px-[40px] py-[21px] rounded-md border flex items-center justify-between w-full'>
                            <h1 className="w-[550px] text-black text-xl font-medium text-start">Pengaturan Kelompok Kerja</h1>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M9.5 6L15.5 12L9.5 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Link>
                        <Link to={'/performance-setting/nomor-surat'} className='bg-white px-[40px] py-[21px] rounded-md border flex items-center justify-between w-full'>
                            <h1 className="w-[550px] text-black text-xl font-medium text-start">Pengaturan Nomor Surat</h1>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M9.5 6L15.5 12L9.5 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Link>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default PerformanceSetting