import React, { useState } from 'react'
import Pemasukan from './Pemasukan'
import Potongan from './Potongan'

const KomponenPenggajian = () => {
    const [menu, setMenu] = useState('Pemasukan')
    return (
        <div className='space-y-5 border rounded-xl py-[39px] px-[26px]'>
            <h1 className="text-black text-xl font-semibold">Komponen Penggajian</h1>
            <div className='border-b-2 flex flex-wrap items-center text-[16px]'>
                <button onClick={() => setMenu('Pemasukan')} className={`${menu === 'Pemasukan' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[80px]`}>Pemasukan</button>
                <button onClick={() => setMenu('Potongan')} className={`${menu === 'Potongan' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[80px]`}>Potongan</button>
            </div>
            <div className='border'>
                {menu === 'Pemasukan' ?
                    (
                        <Pemasukan />
                    ) : menu === 'Potongan' ? (
                        <Potongan />
                    ) : null}
            </div>
        </div>
    )
}

export default KomponenPenggajian
