import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { BsImage } from 'react-icons/bs'
import { MdModeEditOutline } from 'react-icons/md'
import Api from '../../../../../Api'
import { IconEdit, LogoH } from '../../../../../assets'
import IconPrimaryButton from '../../../../../components/Button/IconPrimaryButton'
import IconSecondaryButton from '../../../../../components/Button/IconSecondaryButton'
import PrimaryButton from '../../../../../components/Button/PrimaryButton'
import SecondaryButton from '../../../../../components/Button/SecondaryButton'
import imageHandle from '../../../../../utils/imageHandle'
import languages from '../../../../../utils/languages'

const General = () => {
    const [editActivated, setEditActivated] = useState(false)
    const [refresh, setRefresh] = useState(false)

    // Send Data
    const [companyID, setCompanyID] = useState('')
    const [namePath, setNamePath] = useState('')
    const [fileImage, setFileImage] = useState()
    const [imageBase64, setImageBase64] = useState()
    const [organizationName, setOrganizationName] = useState('')
    const [registrationNumber, setRegistrationNumber] = useState('')
    const [taxId, setTaxId] = useState('')
    const [phone, setPhone] = useState('')
    const [fax, setFax] = useState(0)
    const [email, setEmail] = useState('')
    const [desc, setDesc] = useState('')
    const [address, setAddress] = useState('')
    const country = 'Indonesia'
    const [province, setProvince] = useState('')
    const [city, setCity] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [notes, setNotes] = useState('')

    const handleChange = (e) => {
        const maxSize = 2 * 1024 * 1024
        const allowedExtensions = ['png', 'jpg', 'jpeg'];
        const file = e.target.files[0]
        const reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            const fileExtension = file?.name.split('.').pop().toLowerCase();
            if (file && file.size > maxSize) {
                toast.error('file tidak boleh lebih dari 2 MB')
                setFileImage(null)
            } else if (!allowedExtensions.includes(fileExtension)) {
                toast.error('file harus berformat png/jpg/jpeg')
            } else {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImageBase64(reader.result);
                };
                reader.readAsDataURL(file);
                // setNamePath(URL.createObjectURL(file));
                setNamePath(e.target.files[0].name)
                setFileImage(e.target.files[0])
            }
        }
    }
    const GetData = async () => {
        try {
            const response = await Api.GetGeneralInformation(localStorage.getItem('hris-token'))
            setCompanyID(response.data.companyId ?? "")
            setNamePath(response.data.company.image ?? "")
            setOrganizationName(response.data.company.company_name ?? "")
            setDesc(response.data.company.description ?? "")
            setRegistrationNumber(response.data.company.registration_number ?? "")
            setTaxId(response.data.company.tax_id ?? "")
            setPhone(response.data.company.phone ?? "")
            setFax(response.data.company.fax ?? "")
            setEmail(response.data.email ?? "")
            setAddress(response.data.company.address ?? "")
            // setCountry(response.data.company.country??"")
            setProvince(response.data.company.province ?? "")
            setCity(response.data.company.city ?? "")
            setPostalCode(response.data.company.postal_code ? response.data.company.postal_code.toString() : "")
            setNotes(response.data.company.notes ?? "")
        } catch (error) {
            console.log(error)
        }
    }

    const updateData = async () => {
        if (!organizationName.trim()) {
            toast.error('Nama Perusahaan Tidak Boleh Kosong!')
        } else if (!registrationNumber.trim()) {
            toast.error('Nomor Registrasi Tidak Boleh Kosong!')
        } else if (!taxId.trim()) {
            toast.error('Nomor Pajak Tidak Boleh Kosong!')
        } else if (!phone.trim()) {
            toast.error('Nomor Telepon Tidak Boleh Kosong!')
        } else if (phone.length < 10) {
            toast.error('Nomor Telepon harus memiliki minimal 10 karakter!')
        } else if (!email.trim()) {
            toast.error('Email Tidak Boleh Kosong!')
        } else if (!desc.trim()) {
            toast.error('Deskripsi Tidak Boleh Kosong!')
        } else if (!address.trim()) {
            toast.error('Alamat Tidak Boleh Kosong!')
        } else if (!province.trim()) {
            toast.error('Provinsi Tidak Boleh Kosong!')
        } else if (!city.trim()) {
            toast.error('Kota Tidak Boleh Kosong!')
        } else if (!postalCode.trim()) {
            toast.error('Kode Pos Tidak Boleh Kosong!')
        } else {
            try {
                const formData = new FormData();
                const data = {
                    company_name: organizationName,
                    description: desc,
                    registration_number: registrationNumber,
                    tax_id: taxId,
                    phone: phone,
                    email: email,
                    address: address,
                    city: city,
                    province: province,
                    postal_code: postalCode,
                    country: country,
                    notes: notes,
                }
                if (fileImage) {
                    data.image = fileImage
                }
                if (fax || fax.trim()) {
                    data.fax = fax
                }
                for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                        formData.append(key, data[key]);
                    }
                }
                // for (const pair of formData.entries()) {
                //     console.log(pair[0] + ', ' + pair[1]);
                // }
                await Api.UpdateGeneralInformation(localStorage.getItem('hris-token'), data, companyID).then((response) => {
                    toast.success('Data Berhasil Diperbarui')
                    setRefresh(true)
                    setEditActivated(!editActivated)
                }).catch((error) => {
                    console.log(error)
                    const e = error.response.data.errors.error.details
                    for (let i = 0; i < e.length; i++) {
                        toast.error(e[i].message)
                    }
                })
            } catch (error) {
                console.log(error)
                toast.error(error.message)
            }
        }
    }

    useEffect(() => {
        GetData()
        setRefresh(false)
    }, [refresh])

    return (
        <>
            <div className='flex items-center justify-between border-b py-[16px]'>
                <div className='w-full lg:w-auto'>
                    <h1 className='text-dark-3 text-[16px] font-[500]'>
                        {languages.language === 'id' ? "Informasi Umum" : "General Information"}
                    </h1>
                    <h1 className='text-[#A8A8A8] text-xs font-[500]'>
                        {languages.language === 'id' ? "Informasi Umum Instansi" : "General company information"}
                    </h1>
                </div>
                {editActivated ?
                    <IconSecondaryButton action={() => setEditActivated(!editActivated)} icon={IconEdit} text={'Edit'} />
                    : <IconPrimaryButton action={() => setEditActivated(!editActivated)} icon={IconEdit} text={'Edit'} />
                }
            </div>
            <div className='space-y-[25px]'>
                <div className='w-full'>
                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Logo Perusahaan" : "Company Logo"}</h1>
                    <label htmlFor='upload-image' className={`${editActivated ? 'cursor-pointer' : 'cursor-default'}`}>
                        <div className='w-[105px] h-[105px] border rounded-[12px] bg-[#C6C6C6] flex flex-col items-center justify-center bg-cover relative' style={{ backgroundImage: `url(${imageBase64 ? imageBase64 : namePath ? imageHandle(namePath) : LogoH})` }}>
                            <BsImage className='text-2xl text-dark-9' />
                            <div className='absolute bg-[#F9F9F9] border border-[#EBEBEB] w-[38px] h-[38px] rounded-full -bottom-3 -right-4 flex items-center justify-center'>
                                <MdModeEditOutline />
                            </div>
                        </div>
                        <input disabled={editActivated ? false : true} type='file' accept=".jpeg, .jpg, .png" id='upload-image' onChange={(e) => handleChange(e)} className='rounded-[12px] outline-none border border-[#E3E8F1] w-full px-[20px] py-[15px] hidden' required />
                    </label>
                </div>
                <div className='grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-2'>
                    <div className='w-full'>
                        <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Nama Perusahaan" : "Organization Name"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input value={organizationName} onChange={(e) => setOrganizationName(e.target.value)} disabled={editActivated ? false : true} type={'text'} className={`${editActivated ? 'bg-white' : 'bg-dark-9'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Nama Perusahaan...' />
                    </div>
                    <div className='w-full'>
                        <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Nomor Registrasi" : "Registration Number"} <span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input value={registrationNumber} onChange={(e) => setRegistrationNumber(e.target.value)} disabled={editActivated ? false : true} type={'text'} className={`${editActivated ? 'bg-white' : 'bg-dark-9'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Nomor Registrasi...' />
                    </div>
                    <div className='w-full'>
                        <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Nomor Pajak" : "Tax ID"} <span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input value={taxId} onChange={(e) => setTaxId(e.target.value)} disabled={editActivated ? false : true} type={'text'} className={`${editActivated ? 'bg-white' : 'bg-dark-9'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Nomor Pajak...' />
                    </div>
                    <div className='w-full'>
                        <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "No. Telepon" : "Phone Number"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input value={phone} onChange={(e) => setPhone(e.target.value)} disabled={editActivated ? false : true} type={'number'} className={`${editActivated ? 'bg-white' : 'bg-dark-9'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='No. Telepon...' />
                    </div>
                    <div className='w-full'>
                        <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Fax</h1>
                        <input value={fax} onChange={(e) => setFax(e.target.value)} disabled={editActivated ? false : true} type={'text'} className={`${editActivated ? 'bg-white' : 'bg-dark-9'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Fax...' />
                    </div>
                    <div className='w-full'>
                        <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Email <span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input value={email} onChange={(e) => setEmail(e.target.value)} disabled={editActivated ? false : true} type={'text'} className={`${editActivated ? 'bg-white' : 'bg-dark-9'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='humanusia@example.com' />
                    </div>
                    <div className='w-full'>
                        <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Deskripsi Perusahaan" : "Number of Employee"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <textarea value={desc} onChange={(e) => setDesc(e.target.value)} disabled={editActivated ? false : true} rows={2} className={`${editActivated ? 'bg-white' : 'bg-dark-9'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Deskripsi Perusahaan...' />
                    </div>
                    <div className='w-full'>
                        <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Alamat" : "Address"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <textarea value={address} onChange={(e) => setAddress(e.target.value)} disabled={editActivated ? false : true} rows={2} className={`${editActivated ? 'bg-white' : 'bg-dark-9'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Alamat Perusahaan...' />
                    </div>
                    <div className='w-full'>
                        <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Negara" : "Country"}</h1>
                        <input value={"Indonesia"} disabled={true} type={'text'} className={`bg-dark-9 border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='humanusia@example.com' />
                    </div>
                    <div className='w-full'>
                        <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Provinsi" : "Province"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input value={province} type={'text'} onChange={(e) => setProvince(e.target.value)} disabled={editActivated ? false : true} className={`${editActivated ? 'bg-white' : 'bg-dark-9'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder={languages.language === 'id' ? "Input Provinsi" : "Input Province"} />
                    </div>
                    <div className='w-full'>
                        <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Kabupaten/Kota" : "City"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input value={city} type={'text'} onChange={(e) => setCity(e.target.value)} disabled={editActivated ? false : true} className={`${editActivated ? 'bg-white' : 'bg-dark-9'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder={languages.language === 'id' ? "Input Kabupaten/Kota" : "Input City"} />
                    </div>
                    <div className='w-full'>
                        <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Kode Pos" : "Postal Code"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input value={postalCode} onChange={(e) => setPostalCode(e.target.value)} disabled={editActivated ? false : true} type={'number'} className={`${editActivated ? 'bg-white' : 'bg-dark-9'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Postal Code...' />
                    </div>
                    <div className='w-full md:col-span-2'>
                        <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Catatan" : "Notes"}</h1>
                        <textarea value={notes} onChange={(e) => setNotes(e.target.value)} disabled={editActivated ? false : true} type='text' rows={4} className={`${editActivated ? 'bg-white' : 'bg-dark-9'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder={languages.language === 'id' ? "Catatan..." : "Notes..."} />
                    </div>
                </div>
                {editActivated &&
                    <div className='flex items-center justify-end gap-[12px] mt-5'>
                        <SecondaryButton action={() => { setEditActivated(!editActivated) }} text={'Batal'} />
                        <PrimaryButton action={() => updateData()} text={'Simpan'} />
                    </div>
                }
            </div>
        </>
    )
}

export default General