import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { AiOutlinePlus } from 'react-icons/ai'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { IoMdArrowDropdown } from 'react-icons/io'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import Api from '../../../../../Api'
import { Modal, ModalDelete, Pagination } from '../../../../../components'

const Training = ({ refreshPage, UserId }) => {
    const [Current, setCurrent] = useState(0)
    const [Active, setActive] = useState(false)
    const [dataTraining, setDataTraining] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState()
    const [id, setId] = useState()

    //Training Modal
    const [showAddTraining, setShowAddTraining] = useState()
    const [showEditTraining, setShowEditTraining] = useState()

    //State Training

    const [idTraining, setIdTraining] = useState('')
    const [totalTraining, setTotalTraining] = useState('')
    const [formData, setFormData] = useState({
        "userId": UserId,
        "nama": "",
        "jalur": "",
        "tempat": "",
        "penyelenggara": "",
        "tahun": "",
        "nomor_sk": "",
        "tanggal_sk": "",
        "tanggal_mulai": "",
        "tanggal_selesai": "",
        "jumlah_jam": 0
    })

    const resetTrainingForm = () => {
        setFormData({
            "userId": UserId,
            "nama": "",
            "jalur": "",
            "tempat": "",
            "penyelenggara": "",
            "tahun": "",
            "nomor_sk": "",
            "tanggal_sk": "",
            "tanggal_mulai": "",
            "tanggal_selesai": "",
            "jumlah_jam": 0
        })
    }

    const openEditTraining = async (id) => {
        setIdTraining(id)
        setShowEditTraining(!showEditTraining)
        try {
            const response = await Api.GetTrainingById(localStorage.getItem('hris-token'), id)
            const data = response.data.results.data
            setFormData({
                ...formData,
                "nama": data.nama,
                "jalur": data.jalur,
                "tempat": data.tempat,
                "penyelenggara": data.penyelenggara,
                "tahun": data.tahun,
                "nomor_sk": data.nomor_sk,
                "tanggal_sk": data.tanggal_sk,
                "tanggal_mulai": data.tanggal_mulai,
                "tanggal_selesai": data.tanggal_selesai,
                "jumlah_jam": data.jumlah_jam
            })
        } catch (error) {
            console.log(error)
        }
    }

    const createTraining = async () => {
        try {
            await Api.CreateTraining(localStorage.getItem('hris-token'), formData).then((response) => {
                setRefresh(true)
                toast.success("Data Berhasil Ditambahkan!")
                resetTrainingForm()
                setShowAddTraining(!showAddTraining)
            }).catch((error) => {
                error?.response?.data?.map((val) => (
                    toast.error(val)
                ))
            })
        } catch (error) {
            toast.error('Gagal Menambahkan Data!')
        }
    }
    const [totalJam, setTotalJam] = useState(0)
    const handleTotalJamTraining = (e, type) => {
        if (type === "add") {
            const newTotal = parseInt(totalTraining) + parseInt(e);
            setTotalJam(isNaN(newTotal) ? totalTraining : newTotal);
        } else if (type === "edit") {
            console.log()
            const newData = parseInt(e) - parseInt(formData.jumlah_jam)
            // const newTotal = parseInt(totalTraining) + parseInt(newData);
            // console.log(e)
            console.log(newData)
            // console.log(newTotal)
            // setTotalJam(isNaN(newTotal) ? totalTraining : newTotal);
        } else {
            setTotalJam(totalTraining)
        }
    }
    const updateTraining = async () => {
        try {
            await Api.UpdateTraining(localStorage.getItem('hris-token'), formData, idTraining).then((response) => {
                toast.success('Berhasil Edit Data!')
                setRefresh(true)
                resetTrainingForm()
                setShowEditTraining(!showEditTraining)
            }).catch((error) => {
                error?.response?.data?.map((val) => (
                    toast.error(val)
                ))
            })
        } catch (error) {
            toast.error('Failed Update Training')
            console.log(error)
        }
    }

    const handleCollapse = (id) => {
        if (id) {
            setCurrent(id)
            setActive(!Active)
        }
    }

    const getDataTraining = async () => {
        try {
            const response = await Api.GetTrainingByUserId(localStorage.getItem('hris-token'), UserId, currentPage, limit)
            setDataTraining(response.data.results.data)
            setTotalPages(response.data.results.totalPages)
            setTotalCount(response.data.results.totalCount)
        } catch (error) {
            console.log(error)
        }
    }
    const getTotalTraining = async () => {
        try {
            const response = await Api.GetTotalTraining(localStorage.getItem('hris-token'), UserId)
            // console.log(response.data.results.jumlah_jam)
            setTotalTraining(parseInt(response.data.results.jumlah_jam))
            setTotalJam(parseInt(response.data.results.jumlah_jam))
        } catch (error) {
            console.log(error)
        }
    }
    console.log(totalJam)

    const deleteModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setId(id)
    }

    const deleteTraining = async () => {
        try {
            const response = await Api.DeleteTraining(localStorage.getItem('hris-token'), id)
            console.log(response)
            setShowModalDelete(!showModalDelete)
            setRefresh(true)
            toast.success('Berhasil Dihapus')
        } catch (error) {
            console.log(error)
            toast.error('Gagal Dihapus')
        }
    }
    const limit = 10
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState(1)

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    useEffect(() => {
        getDataTraining()
    }, [refresh, refreshPage])

    return (
        <>
            <div>
                <Modal
                    activeModal={showAddTraining}
                    title={'Tambah Pelatihan'}
                    buttonClose={() => { setShowAddTraining(!showAddTraining); resetTrainingForm() }}
                    width={'558px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Nama Pengembangan Kompetensi <span className='text-red-primer'>*</span></h1>
                                <input value={formData.nama} onChange={(e) => setFormData({ ...formData, nama: e.target.value })} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Nama Pengembangan Kompetensi...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Jalur Pengembangan Kompetensi <span className='text-red-primer'>*</span></h1>
                                <div className='relative'>
                                    <select value={formData.jalur} onChange={(e) => setFormData({ ...formData, jalur: e.target.value })} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                        <option disabled value={''}>Pilih Jalur Pengembangan Kompetensi</option>
                                        <option value={"Pelatihan Struktural Kepemimpinan"}>Pelatihan Struktural Kepemimpinan</option>
                                        <option value={"Pelatihan Manajerial"}>Pelatihan Manajerial</option>
                                        <option value={"Pelatihan Teknis"}>Pelatihan Teknis</option>
                                        <option value={"Pelatihan Fungsional"}>Pelatihan Fungsional</option>
                                        <option value={"Pelatihan Sosial Kultural"}>Pelatihan Sosial Kultural</option>
                                        <option value={"Belajar Mandiri"}>Belajar Mandiri</option>
                                        <option value={"Bimbingan di Tempat Kerja"}>Bimbingan di Tempat Kerja</option>
                                        <option value={"Bimbingan Teknis"}>Bimbingan Teknis</option>
                                        <option value={"Coaching"}>Coaching</option>
                                        <option value={"Detasering"}>Detasering</option>
                                        <option value={"e-Learning"}>e-Learning</option>
                                        <option value={"Komunitas Belajar"}>Komunitas Belajar</option>
                                        <option value={"Kursus"}>Kursus</option>
                                        <option value={"Magang/Praktek Kerja"}>Magang/Praktek Kerja</option>
                                        <option value={"Mentoring"}>Mentoring</option>
                                        <option value={"Outbond"}>Outbond</option>
                                        <option value={"Patok Banding (Benchmarking)"}>Patok Banding (Benchmarking)</option>
                                        <option value={"Pelatihan Jarak Jauh"}>Pelatihan Jarak Jauh</option>
                                        <option value={"Penataran"}>Penataran</option>
                                        <option value={"Pertukaran Pegawai"}>Pertukaran Pegawai</option>
                                        <option value={"Seminar/Konferensi/Sarasehan"}>Seminar/Konferensi/Sarasehan</option>
                                        <option value={"Sosialisasi"}>Sosialisasi</option>
                                        <option value={"Workshop/Lokakarya"}>Workshop/Lokakarya</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Tempat Pengembangan Kompetensi <span className='text-red-primer'>*</span></h1>
                                <input value={formData.tempat} onChange={(e) => setFormData({ ...formData, tempat: e.target.value })} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Tempat Pengembangan Kompetensi...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Penyelenggara Pengembangan Kompetensi <span className='text-red-primer'>*</span></h1>
                                <input value={formData.penyelenggara} onChange={(e) => setFormData({ ...formData, penyelenggara: e.target.value })} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Penyelenggara Pengembangan Kompetensi...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Tahun Mengikuti Pengembangan Kompetensi <span className='text-red-primer'>*</span></h1>
                                <input value={formData.tahun} onChange={(e) => setFormData({ ...formData, tahun: e.target.value })} type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Tahun Mengikuti Pengembangan Kompetensi...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Nomor SK/Sertifikat <span className='text-red-primer'>*</span></h1>
                                <input value={formData.nomor_sk} onChange={(e) => setFormData({ ...formData, nomor_sk: e.target.value })} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Nomor SK/Sertifikat...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Tanggal SK/Sertifikat <span className='text-red-primer'>*</span></h1>
                                <input value={formData.tanggal_sk} onChange={(e) => setFormData({ ...formData, tanggal_sk: e.target.value })} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Tanggal SK/Sertifikat...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Tanggal Mulai Pengembangan Kompetensi <span className='text-red-primer'>*</span></h1>
                                <input value={formData.tanggal_mulai} onChange={(e) => setFormData({ ...formData, tanggal_mulai: e.target.value })} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Tanggal Selesai Pengembangan Kompetensi <span className='text-red-primer'>*</span></h1>
                                <input value={formData.tanggal_selesai} onChange={(e) => setFormData({ ...formData, tanggal_selesai: e.target.value })} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Jumlah Jam Pelajaran <span className='text-red-primer'>*</span></h1>
                                <input value={formData.jumlah_jam} onChange={(e) => { setFormData({ ...formData, jumlah_jam: e.target.value }); handleTotalJamTraining(e.target.value, 'add') }} type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='0' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Jumlah Jam Pelajaran yang telah diikuti tahun ini <span className='text-red-primer'>*</span></h1>
                                <p className='bg-base-outline py-[10px] border rounded-md pl-[13px] pr-2 text-[11px] text-grey-medium w-full'>{totalJam}</p>
                            </div>
                            <div className="flex gap-[10px] items-end  justify-end">
                                <button onClick={() => { setShowAddTraining(!showAddTraining); resetTrainingForm() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={createTraining} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditTraining}
                    title={'Edit Pelatihan'}
                    buttonClose={() => { setShowEditTraining(!showEditTraining); resetTrainingForm() }}
                    width={'558px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Nama Pengembangan Kompetensi <span className='text-red-primer'>*</span></h1>
                                <input value={formData.nama} onChange={(e) => setFormData({ ...formData, nama: e.target.value })} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Nama Pengembangan Kompetensi...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Jalur Pengembangan Kompetensi <span className='text-red-primer'>*</span></h1>
                                <div className='relative'>
                                    <select value={formData.jalur} onChange={(e) => setFormData({ ...formData, jalur: e.target.value })} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                        <option disabled value={''}>Pilih Jalur Pengembangan Kompetensi</option>
                                        <option value={"Pelatihan Struktural Kepemimpinan"}>Pelatihan Struktural Kepemimpinan</option>
                                        <option value={"Pelatihan Manajerial"}>Pelatihan Manajerial</option>
                                        <option value={"Pelatihan Teknis"}>Pelatihan Teknis</option>
                                        <option value={"Pelatihan Fungsional"}>Pelatihan Fungsional</option>
                                        <option value={"Pelatihan Sosial Kultural"}>Pelatihan Sosial Kultural</option>
                                        <option value={"Belajar Mandiri"}>Belajar Mandiri</option>
                                        <option value={"Bimbingan di Tempat Kerja"}>Bimbingan di Tempat Kerja</option>
                                        <option value={"Bimbingan Teknis"}>Bimbingan Teknis</option>
                                        <option value={"Coaching"}>Coaching</option>
                                        <option value={"Detasering"}>Detasering</option>
                                        <option value={"e-Learning"}>e-Learning</option>
                                        <option value={"Komunitas Belajar"}>Komunitas Belajar</option>
                                        <option value={"Kursus"}>Kursus</option>
                                        <option value={"Magang/Praktek Kerja"}>Magang/Praktek Kerja</option>
                                        <option value={"Mentoring"}>Mentoring</option>
                                        <option value={"Outbond"}>Outbond</option>
                                        <option value={"Patok Banding (Benchmarking)"}>Patok Banding (Benchmarking)</option>
                                        <option value={"Pelatihan Jarak Jauh"}>Pelatihan Jarak Jauh</option>
                                        <option value={"Penataran"}>Penataran</option>
                                        <option value={"Pertukaran Pegawai"}>Pertukaran Pegawai</option>
                                        <option value={"Seminar/Konferensi/Sarasehan"}>Seminar/Konferensi/Sarasehan</option>
                                        <option value={"Sosialisasi"}>Sosialisasi</option>
                                        <option value={"Workshop/Lokakarya"}>Workshop/Lokakarya</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Tempat Pengembangan Kompetensi <span className='text-red-primer'>*</span></h1>
                                <input value={formData.tempat} onChange={(e) => setFormData({ ...formData, tempat: e.target.value })} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Tempat Pengembangan Kompetensi...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Penyelenggara Pengembangan Kompetensi <span className='text-red-primer'>*</span></h1>
                                <input value={formData.penyelenggara} onChange={(e) => setFormData({ ...formData, penyelenggara: e.target.value })} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Penyelenggara Pengembangan Kompetensi...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Tahun Mengikuti Pengembangan Kompetensi <span className='text-red-primer'>*</span></h1>
                                <input value={formData.tahun} onChange={(e) => setFormData({ ...formData, tahun: e.target.value })} type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Tahun Mengikuti Pengembangan Kompetensi...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Nomor SK/Sertifikat <span className='text-red-primer'>*</span></h1>
                                <input value={formData.nomor_sk} onChange={(e) => setFormData({ ...formData, nomor_sk: e.target.value })} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Nomor SK/Sertifikat...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Tanggal SK/Sertifikat <span className='text-red-primer'>*</span></h1>
                                <input value={formData.tanggal_sk} onChange={(e) => setFormData({ ...formData, tanggal_sk: e.target.value })} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Tanggal SK/Sertifikat...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Tanggal Mulai Pengembangan Kompetensi <span className='text-red-primer'>*</span></h1>
                                <input value={formData.tanggal_mulai} onChange={(e) => setFormData({ ...formData, tanggal_mulai: e.target.value })} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Tanggal Selesai Pengembangan Kompetensi <span className='text-red-primer'>*</span></h1>
                                <input value={formData.tanggal_selesai} onChange={(e) => setFormData({ ...formData, tanggal_selesai: e.target.value })} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Jumlah Jam Pelajaran <span className='text-red-primer'>*</span></h1>
                                <input value={formData.jumlah_jam} onChange={(e) => { setFormData({ ...formData, jumlah_jam: e.target.value }); handleTotalJamTraining(e.target.value, 'edit') }} type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='0' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Jumlah Jam Pelajaran yang telah diikuti tahun ini <span className='text-red-primer'>*</span></h1>
                                <p className='bg-base-outline py-[10px] border rounded-md pl-[13px] pr-2 text-[11px] text-grey-medium w-full'>{totalTraining}</p>
                            </div>
                            <div className="flex gap-[10px] items-end  justify-end">
                                <button onClick={() => { setShowEditTraining(!showEditTraining); resetTrainingForm() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={updateTraining} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <ModalDelete
                    activeModal={showModalDelete}
                    buttonClose={() => setShowModalDelete(!showModalDelete)}
                    submitButton={deleteTraining}
                />
            </div>
            <div className='px-[19px] py-[19px] space-y-[20px]'>
                <div className='flex relative'>
                    <div>
                        <h1 className='text-base-black text-[20px] font-medium'>Pelatihan</h1>
                        <h2 className='text-dark-5 text-[10px]'>Daftar Pelatihan</h2>
                    </div>
                    <div className='absolute right-0'>
                        <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                            <button onClick={() => { setShowAddTraining(true); getTotalTraining() }} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                <AiOutlinePlus className='text-xl text-white' />
                                <h1 className='text-dark-9 dark:place text-sm font-[500]'>Tambah Pelatihan</h1>
                            </button>
                        </div>
                    </div>
                </div>
                <hr className='border border-grey-extra-thin' />
                <div className='rounded shadow'>
                    <div className='px-2 py-4'>
                        <div className='overflow-auto'>
                            <table className='w-full space-y-[10px] text-dark-5'>
                                <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Nama Pengembangan Kompetensi</h1>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Jalur Pengembangan Kompetensi</h1>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Nomor SK/Sertifikat</h1>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Tanggal SK/Sertifikat</h1>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Tanggal Mulai</h1>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Tanggal Selesai</h1>
                                    </div>
                                    <div className='flex items-center justify-center gap-[6px] w-full'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Aksi</h1>
                                    </div>
                                </div>
                                {Object.values(dataTraining).map((item, idx) => (
                                    <>
                                        <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.nama ?? '-'}</h1>
                                            </div>
                                            <div className='min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.jalur ?? '-'}</h1>
                                            </div>
                                            <div className='min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.nomor_sk ?? '-'}</h1>
                                            </div>
                                            <div className='min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.tanggal_sk ?? '-'}</h1>
                                            </div>
                                            <div className='min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.tanggal_mulai ? moment(item.tanggal_mulai).format('DD/MM/YYYY') : '-'}</h1>
                                            </div>
                                            <div className='min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.tanggal_selesai ? moment(item.tanggal_selesai).format('DD/MM/YYYY') : '-'}</h1>
                                            </div>
                                            <div className='w-full flex items-center justify-center gap-[12px]'>
                                                <button onClick={() => { openEditTraining(item.id); getTotalTraining() }} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[#003049]' />
                                                </button>
                                                <button className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty onClick={() => deleteModal(item.id)} className='text-[#003049]' />
                                                </button>
                                                <button onClick={() => handleCollapse(item.id)} className='w-[29px] h-[29px] rounded-[9px] flex items-center justify-center'>
                                                    {item.id === Current && !Active ?
                                                        <MdKeyboardArrowUp className='text-[#003049]' />
                                                        :
                                                        <MdKeyboardArrowDown className='text-[#003049]' />
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                        <div className={`w-full rounded-b-[3px] bg-[#F9F9F9] transition-all ease-in-out duration-500 overflow-hidden ${item.id === Current && !Active ? 'h-fit border' : 'h-0 border-0'}`}>
                                            <div className='px-[16px] py-[19px] space-y-3'>
                                                <div className="text-gray-500 text-xs font-bold">Detail Training</div>
                                                <div className='grid grid-cols-2 gap-2'>
                                                    <div className='grid grid-cols-3 gap-2'>
                                                        <div className='text-gray-500 text-xs'>
                                                            <h1>Nama Pengembangan Kompetensi</h1>
                                                        </div>
                                                        <div className='col-span-2 text-gray-500 text-xs'>
                                                            <h1>: {item.nama ?? '-'}</h1>
                                                        </div>
                                                        <div className='text-gray-500 text-xs'>
                                                            <h1>Jalur Pengembangan Kompetensi</h1>
                                                        </div>
                                                        <div className='col-span-2 text-gray-500 text-xs'>
                                                            <h1>: {item.jalur ?? '-'}</h1>
                                                        </div>
                                                        <div className='text-gray-500 text-xs'>
                                                            <h1>Tempat Pengembangan Kompetensi</h1>
                                                        </div>
                                                        <div className='col-span-2 text-gray-500 text-xs'>
                                                            <h1>: {item.tempat ?? '-'}</h1>
                                                        </div>
                                                        <div className='text-gray-500 text-xs'>
                                                            <h1>Penyelenggara Pengembangan Kompetensi</h1>
                                                        </div>
                                                        <div className='col-span-2 text-gray-500 text-xs'>
                                                            <h1>: {item.penyelenggara ?? '-'}</h1>
                                                        </div>
                                                        <div className='text-gray-500 text-xs'>
                                                            <h1>Tahun Mengikuti Pengembangan Kompetensi</h1>
                                                        </div>
                                                        <div className='col-span-2 text-gray-500 text-xs'>
                                                            <h1>: {item.tahun ?? '-'}</h1>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-2'>
                                                        <div className='text-gray-500 text-xs'>
                                                            <h1>Nomor SK/Sertifikat</h1>
                                                        </div>
                                                        <div className='col-span-2 text-gray-500 text-xs'>
                                                            <h1>: {item.nomor_sk ?? '-'}</h1>
                                                        </div>
                                                        <div className='text-gray-500 text-xs'>
                                                            <h1>Tanggal SK/Sertifikat</h1>
                                                        </div>
                                                        <div className='col-span-2 text-gray-500 text-xs'>
                                                            <h1>: {item.tanggal_sk ? moment(item.tanggal_sk).format('DD/MM/YYYY') : '-'}</h1>
                                                        </div>
                                                        <div className='text-gray-500 text-xs'>
                                                            <h1>Tanggal Mulai</h1>
                                                        </div>
                                                        <div className='col-span-2 text-gray-500 text-xs'>
                                                            <h1>: {item.tanggal_mulai ? moment(item.tanggal_mulai).format('DD/MM/YYYY') : '-'}</h1>
                                                        </div>
                                                        <div className='text-gray-500 text-xs'>
                                                            <h1>Tanggal Selesai</h1>
                                                        </div>
                                                        <div className='col-span-2 text-gray-500 text-xs'>
                                                            <h1>: {item.tanggal_selesai ? moment(item.tanggal_selesai).format('DD/MM/YYYY') : '-'}</h1>
                                                        </div>
                                                        <div className='text-gray-500 text-xs'>
                                                            <h1>Jumlah Jam Pelajaran</h1>
                                                        </div>
                                                        <div className='col-span-2 text-gray-500 text-xs'>
                                                            <h1>: {item.jumlah_jam ?? '-'}</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}

                            </table>
                        </div>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            limitData={dataTraining?.length}
                            lengthData={totalCount}
                            onPageChange={handlePageChange}
                            onPrevChange={handlePrevChange}
                            onNextChange={handleNextChange}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Training