import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlinePlus } from 'react-icons/ai'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { MdImportExport } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import Api from '../../../../../Api'
import { Modal, ModalDelete, Navbar } from '../../../../../components'
import languages from '../../../../../utils/languages'

const OfficialWorkTravelSetting = () => {
    const navigate = useNavigate()
    const [showAddOfficialWorkTravel, setShowAdddOfficialWorkTravel] = useState(false)
    const [showEditOfficialWorkTravel, setShowEditdOfficialWorkTravel] = useState(false)
    const [data, setData] = useState('')
    const [typeName, setTypeName] = useState('')
    const [batasPengajuan, setBatasPengajuan] = useState('')
    const [uangMakanPns, setUangMakanPns] = useState("")
    const [uangMakanLembur, setUangMakanLembur] = useState("")
    const [uangLembur, setUangLembur] = useState("")
    const [idData, setIdData] = useState('')
    const [refresh, setRefresh] = useState()

    const getOfficialWorkTravelSetting = async () => {
        try {
            const response = await Api.GetWorkTravelSetting(localStorage.getItem('hris-token'))
            // console.log(response)
            setData(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const resetForm = async () => {
        setTypeName("")
        setBatasPengajuan("")
        setUangMakanPns("")
        setUangMakanLembur("")
        setUangLembur("")
    }

    const openEditOfficialWorkTravelSetting = async (id) => {
        setIdData(id)
        setShowEditdOfficialWorkTravel(!showEditOfficialWorkTravel)
        try {
            const response = await Api.GetWorkTrravelSettingById(localStorage.getItem('hris-token'), id)
            setTypeName(response.data.type_name)
            setBatasPengajuan(response.data.batas_pengajuan)
            setUangMakanPns(response.data?.uang_makan_pns)
            setUangMakanLembur(response.data?.uang_makan_lembur)
            setUangLembur(response.data?.lembur)
        } catch (error) {
            console.log(error)
        }
    }

    const editOfficialWorkTravelSetting = async () => {
        const data = {
            'type_name': typeName,
            'batas_pengajuan': batasPengajuan,
            "uang_makan_pns": uangMakanPns,
            "lembur": uangLembur,
            "uang_makan_lembur": uangMakanLembur
        }
        try {
            await Api.UpdateWorkTravelSetting(localStorage.getItem('hris-token'), data, idData).then((response) => {
                toast.success('Berhasil Edit Data!')
                setRefresh(true)
                resetForm()
                setShowEditdOfficialWorkTravel(!showEditOfficialWorkTravel)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Edit Data!')
        }
    }


    const createOfficialWorkTravelSetting = async () => {
        const data = {
            'type_name': typeName,
            'batas_pengajuan': batasPengajuan,
            "uang_makan_pns": uangMakanPns,
            "lembur": uangLembur,
            "uang_makan_lembur": uangMakanLembur
        }
        try {
            await Api.CreateWorkTravelSetting(localStorage.getItem('hris-token'), data).then((res) => {
                toast.success('Berhasil Menambahkan Data!')
                setRefresh(true)
                resetForm()
                setShowAdddOfficialWorkTravel(!showAddOfficialWorkTravel)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data!')
        }
    }

    const [showModalOfficialWorkTravelSetting, setShowModalOfficialWorkTravelSetting] = useState()

    const deleteOfficialWorkTravelSettingModal = (id) => {
        setShowModalOfficialWorkTravelSetting(!showModalOfficialWorkTravelSetting)
        setIdData(id)
    }

    const deleteOfficialWorkTravelSetting = async () => {
        try {
            await Api.DeleteWorkTravelSetting(localStorage.getItem('hris-token'), idData).then((res) => {
                setShowModalOfficialWorkTravelSetting(!showModalOfficialWorkTravelSetting)
                setRefresh(true)
                toast.success('Berhasil Menghapus Data!')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menghapus Data!')
        }
    }
    const [role, setRole] = useState('')
    const Fetch = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setRole(response.data.results.user.role)
        } catch (error) {
            navigate('/')
        }
    }
    useEffect(() => {
        Fetch()
        getOfficialWorkTravelSetting()
        setRefresh(false)
    }, [refresh])
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <ModalDelete
                activeModal={showModalOfficialWorkTravelSetting}
                buttonClose={() => setShowModalOfficialWorkTravelSetting(!showModalOfficialWorkTravelSetting)}
                submitButton={deleteOfficialWorkTravelSetting}
            />
            <Modal
                activeModal={showEditOfficialWorkTravel}
                title={languages.language === 'id' ? "Edit Tipe Perjalanan Dinas" : "Edit Official Work Travel Type"}
                buttonClose={() => { setShowEditdOfficialWorkTravel(!showEditOfficialWorkTravel); resetForm() }}
                width={'550px'}
                content={
                    <div className='space-y-[24px] w-[full]'>
                        <div>
                            <h1 className='text-[12px] text-dark-5 mb-[9px] font-medium'>{languages.language === 'id' ? "Nama Tipe Perjalanan Dinas" : "Type Name "}<span className='text-red-600'>*</span></h1>
                            <input onChange={(e) => setTypeName(e.target.value)} value={typeName} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder={languages.language === 'id' ? "Input Nama Perjalanan Dinas..." : "Input official work travel name..."} />
                        </div>
                        <div>
                            <h1 className='text-[12px] text-dark-5 mb-[9px] font-medium'>Batas Pengajuan<span className='text-red-600'>*</span></h1>
                            <div className='flex w-1/2 items-center'>
                                <input onChange={(e) => setBatasPengajuan(e.target.value)} value={batasPengajuan} type="number" className='py-[10px] border rounded-l-md pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder="Batas Pengajuan..." />
                                <h1 className='bg-gray-200 py-[10px] pl-[13px] pr-2 text-[11px] text-dark-1 rounded-r-md '>Hari</h1>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Uang Makan PNS<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <select onChange={(e) => setUangMakanPns(e.target.value)} value={uangMakanPns} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full outline-none'>
                                <option disabled selected value={''}>Pilih pembayaran</option>
                                <option value={'TIDAK DIBAYARKAN'}>Tidak Dibayarkan</option>
                                <option value={'DIBAYARKAN'}>Dibayarkan</option>
                            </select>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Uang Makan Lembur<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <select onChange={(e) => setUangMakanLembur(e.target.value)} value={uangMakanLembur} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full outline-none'>
                                <option disabled selected value={''}>Pilih pembayaran</option>
                                <option value={'TIDAK DIBAYARKAN'}>Tidak Dibayarkan</option>
                                <option value={'DIBAYARKAN'}>Dibayarkan</option>
                            </select>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Uang Lembur<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <select onChange={(e) => setUangLembur(e.target.value)} value={uangLembur} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full outline-none'>
                                <option disabled selected value={''}>Pilih pembayaran</option>
                                <option value={'TIDAK DIBAYARKAN'}>Tidak Dibayarkan</option>
                                <option value={'DIBAYARKAN'}>Dibayarkan</option>
                            </select>
                        </div>
                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => { setShowEditdOfficialWorkTravel(!showEditOfficialWorkTravel); resetForm() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>{languages.language === 'id' ? "Batal" : "Cancel"}</h1>
                                </button>
                                <button onClick={editOfficialWorkTravelSetting} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Simpan" : "Save"}</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showAddOfficialWorkTravel}
                title={languages.language === 'id' ? "Tambah Tipe Perjalanan Dinas" : "Add Official Work Travel Type"}
                buttonClose={() => { setShowAdddOfficialWorkTravel(!showAddOfficialWorkTravel); resetForm() }}
                width={'550px'}
                content={
                    <div className='space-y-[24px] w-[full]'>
                        <div>
                            <h1 className='text-[12px] text-dark-5 mb-[9px] font-medium'>{languages.language === 'id' ? "Nama Tipe Perjalanan Dinas" : "Type Name "}<span className='text-red-600'>*</span></h1>
                            <input onChange={(e) => setTypeName(e.target.value)} value={typeName} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder={languages.language === 'id' ? "Input Nama Perjalanan Dinas..." : "Input official work travel name..."} />
                        </div>
                        <div>
                            <h1 className='text-[12px] text-dark-5 mb-[9px] font-medium'>Batas Pengajuan<span className='text-red-600'>*</span></h1>
                            <div className='flex w-1/2 items-center'>
                                <input onChange={(e) => setBatasPengajuan(e.target.value)} value={batasPengajuan} type="number" className='py-[10px] border rounded-l-md pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder="Batas Pengajuan..." />
                                <h1 className='bg-gray-200 py-[10px] pl-[13px] pr-2 text-[11px] text-dark-1 rounded-r-md '>Hari</h1>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Uang Makan PNS<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <select onChange={(e) => setUangMakanPns(e.target.value)} value={uangMakanPns} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full outline-none'>
                                <option disabled selected value={''}>Pilih pembayaran</option>
                                <option value={'TIDAK DIBAYARKAN'}>Tidak Dibayarkan</option>
                                <option value={'DIBAYARKAN'}>Dibayarkan</option>
                            </select>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Uang Makan Lembur<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <select onChange={(e) => setUangMakanLembur(e.target.value)} value={uangMakanLembur} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full outline-none'>
                                <option disabled selected value={''}>Pilih pembayaran</option>
                                <option value={'TIDAK DIBAYARKAN'}>Tidak Dibayarkan</option>
                                <option value={'DIBAYARKAN'}>Dibayarkan</option>
                            </select>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Uang Lembur<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <select onChange={(e) => setUangLembur(e.target.value)} value={uangLembur} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full outline-none'>
                                <option disabled selected value={''}>Pilih pembayaran</option>
                                <option value={'TIDAK DIBAYARKAN'}>Tidak Dibayarkan</option>
                                <option value={'DIBAYARKAN'}>Dibayarkan</option>
                            </select>
                        </div>
                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => { setShowAdddOfficialWorkTravel(!showAddOfficialWorkTravel); resetForm() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>{languages.language === 'id' ? "Batal" : "Cancel"}</h1>
                                </button>
                                <button onClick={createOfficialWorkTravelSetting} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Tambah</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={languages.language === 'id' ? "Pengaturan Perjalanan Dinas" : "Official Work Travel Setting"} LinkBack={'/time-management-setting'} />
                <h1 className="text-zinc-800 text-xl font-semibold">{languages.language === 'id' ? "Perjalanan Dinas" : "Official Work Travel"}</h1>
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className="py-5 space-y-5 bg-white">
                        <div>
                            <h1 className='text-xl font-semibold text-grey-primer'>{languages.language === 'id' ? "Tipe Perjalanan Dinas" : "Type"}</h1>
                            <h2 className='text-xs text-grey-medium'>{languages.language === 'id' ? "Daftar Tipe Perjalanan Dinas" : "list of Official Work Travel type"}</h2>
                        </div>
                        <div className="flex items-center justify-end mb-[36px]">
                            {/* <button className=' gap-[10px] flex items-center py-[7px] px-[8px] text-blue-thick font-medium border rounded-lg'>
                        <CgTrashEmpty className='text-lg' />
                        <h1>{languages.language === 'id' ?"Hapus":"Delete"}</h1>
                    </button> */}
                            {/* <div className='flex gap-[12px] items-center'> */}
                            {/* <div className='relative py-[12px] pl-[40px] pr-[13px] border rounded-lg flex text-[#8E95A2] font-medium w-[300px]'>
                            <BiSearch className='absolute left-[12px] text-lg' />
                            <input type="text" placeholder={languages.language === 'id' ?"Cari...":"Search..."} className='outline-none text-[10px] w-full' />
                        </div> */}
                            {role === 'ADMIN' ?
                                <button onClick={() => setShowAdddOfficialWorkTravel(!showAddOfficialWorkTravel)} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                    <AiOutlinePlus className='text-xl text-white' />
                                    <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Tambah Tipe" : "Add New Type"}</h1>
                                </button>
                                : null
                            }
                            {/* </div> */}
                        </div>
                        <div>
                            <div className='overflow-auto scrollbar-hide'>
                                <table className='w-full space-y-[10px]'>
                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[6px] w-1/2'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Nama Tipe" : "Type Name"}</h1>
                                            <MdImportExport className='text-dark-5 text-xs' />
                                        </div>
                                        {role === 'ADMIN' ?
                                            <div className='flex items-center justify-center gap-[6px] w-1/2'>
                                                <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                                                <MdImportExport className='text-dark-5 text-xs' />
                                            </div>
                                            : null
                                        }
                                    </div>
                                    {Object.values(data).map((item, idx) => (
                                        <div key={idx} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='w-1/2'>
                                                <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.type_name ?? '-'}</h1>
                                            </div>
                                            {role === 'ADMIN' ?
                                                <div className='w-1/2 flex items-center justify-center gap-[12px]'>
                                                    <button onClick={() => openEditOfficialWorkTravelSetting(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <HiOutlinePencil className='text-[#003049]' />
                                                    </button>
                                                    <button onClick={() => deleteOfficialWorkTravelSettingModal(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <CgTrashEmpty className='text-[#003049]' />
                                                    </button>
                                                </div>
                                                : null
                                            }
                                        </div>
                                    ))}

                                </table>
                            </div>
                            {/* <Pagination
                        currentPage={currentPage} 
                        totalPages={totalPages} 
                        onPageChange={handlePageChange}
                        onPrevChange={handlePrevChange}
                        onNextChange={handleNextChange}
                    /> */}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default OfficialWorkTravelSetting