import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiFillEye, AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'
import { HiOutlinePencil } from 'react-icons/hi'
import Select from 'react-select'
import Api from '../../../../Api'
import { IconPlus } from '../../../../assets'
import { Modal, ModalDelete, Navbar, Pagination } from '../../../../components'
import IconPrimaryButton from '../../../../components/Button/IconPrimaryButton'
import LoadingPrimaryButton from '../../../../components/Button/LoadingPrimaryButton'
import PrimaryButton from '../../../../components/Button/PrimaryButton'
import SecondaryButton from '../../../../components/Button/SecondaryButton'
import InputComponent from '../../../../components/Input/inputComponent'
import { selectStyle } from '../../../../utils/styles/select'

const KelompokKerja = () => {
    const limit = 10
    const [currentPage, setCurrentPage] = useState(1)
    const [refresh, setRefresh] = useState(false)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };
    const [showAdd, setShowAdd] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [timPenilaiId, setTimPenilaiId] = useState('')

    const [dataPegawai, setDataPegawai] = useState([])

    const resetForm = () => {
        setName('')
        setTimPenilaiId('')
        setVerifikator([])
        setSelectedVerifikator([])
        setAsisten([])
        setSelectedAsisten([])
    }

    const getPegawai = async () => {
        try {
            await Api.GetDropdownEmployee(localStorage.getItem('hris-token')).then((response) => {
                const res = response.data.data
                const formattedData = res.map((val => ({ value: val.id, label: val.fullname })))
                setDataPegawai(formattedData)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }

    const [data, setData] = useState([])
    const getData = async () => {
        try {
            await Api.GetKelompokKerja(localStorage.getItem('hris-token')).then((response) => {
                const res = response.data.results
                setData(res.data)
                setCurrentPage(res.currentPage)
                setTotalPages(res.totalPages)
                setTotalCount(res.totalCount)
            }).catch((err) => {
                console.log(err)
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Error!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }

    const postData = async () => {
        setIsLoading(true)
        const data = {
            "name": name,
            "timPenilaiId": timPenilaiId?.value
        }
        try {
            await Api.PostKelompokKerja(localStorage.getItem('hris-token'), data).then((response) => {
                resetForm()
                setIsLoading(false)
                toast.success('Berhasil menambahkan data!')
                setShowAdd(false)
                setRefresh(true)
            }).catch((err) => {
                console.log(err)
                setIsLoading(false)
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Data gagal ditambahkan!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }
    const getDataById = async (id) => {
        setShowEdit(true)
        setId(id)
        try {
            await Api.GetKelompokKerjaById(localStorage.getItem('hris-token'), id).then((response) => {
                const res = response.data.results.data
                setName(res?.name)
                setTimPenilaiId({
                    "value": res?.timPenilaiId,
                    "label": res?.timPenilai?.fullname
                })
            }).catch((err) => {
                console.log(err)
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Error!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }
    const editData = async () => {
        setIsLoading(true)
        const data = {
            "name": name,
            "timPenilaiId": timPenilaiId?.value
        }
        try {
            await Api.UpdateKelompokKerja(localStorage.getItem('hris-token'), id, data).then((response) => {
                resetForm()
                setIsLoading(false)
                toast.success('Berhasil menambahkan data!')
                setShowEdit(false)
                setRefresh(true)
            }).catch((err) => {
                console.log(err)
                setIsLoading(false)
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Data gagal ditambahkan!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }
    const updateData = async () => {
        setIsLoading(true)
        const data = {
            // "nilai": nilai,
            // "jobLevelId": jobLevelId.value
        }
        try {
            await Api.UpdateAngkaKreditMinimum(localStorage.getItem('hris-token'), data, id).then((response) => {
                setIsLoading(false)
                toast.success('Edit data berhasil!')
                setShowEdit(false)
                setRefresh(true)
            }).catch((err) => {
                console.log(err)
                setIsLoading(false)
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Data gagal diedit!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }
    const deleteDataModal = (id) => {
        setShowDelete(!showDelete)
        setId(id)
        setRefresh(true)
    }

    const deleteData = async () => {
        try {
            await Api.DeleteKelompokKerja(localStorage.getItem('hris-token'), id).then((res) => {
                setShowDelete(!showDelete)
                setRefresh(true)
                toast.success('Berhasil menghapus data!')
            }).catch((err) => {
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Data gagal dihapus!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }

    const [showAddVerifikator, setShowAddVerifikator] = useState(false)
    const [showDeleteVerifikator, setShowDeleteVerifikator] = useState(false)
    const [showEditVerifikator, setShowEditVerifikator] = useState(false)
    const [idPokja, setIdPokja] = useState('')
    const [idVerifikator, setIdVerifikator] = useState('')
    const [verifikator, setVerifikator] = useState([])
    const [selectedVerifikator, setSelectedVerifikator] = useState([])

    const handleChangeVerifikator = (selectedOption) => {
        const data = []
        selectedOption && selectedOption.map(val => (
            data.push(val.value)
        ))
        setSelectedVerifikator(selectedOption)
        setVerifikator(data)
    }


    const postDataVerifikator = async () => {
        setIsLoading(true)
        const data = {
            "verifikatorId": verifikator
        }
        try {
            await Api.PostVerifikator(localStorage.getItem('hris-token'), idPokja, data).then((response) => {
                resetForm()
                setIsLoading(false)
                toast.success('Berhasil menambahkan data!')
                setShowAddVerifikator(false)
                setRefresh(true)
            }).catch((err) => {
                console.log(err)
                setIsLoading(false)
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Data gagal dihapus!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }

    const deleteDataModalVerifikator = (id, idPokja) => {
        setShowDeleteVerifikator(!showDeleteVerifikator)
        setIdVerifikator(id)
        setIdPokja(idPokja)
        setRefresh(true)
    }

    const deleteDataVerifikator = async () => {
        try {
            await Api.DeleteVerifikator(localStorage.getItem('hris-token'), idPokja, idVerifikator).then((res) => {
                setShowDeleteVerifikator(false)
                setRefresh(true)
                toast.success('Berhasil menghapus data!')
            }).catch((err) => {
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Data gagal dihapus!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }

    const [showDetailAsisten, setShowDetailAsisten] = useState(false)
    const [showDeleteAsisten, setShowDeleteAsisten] = useState(false)
    const [showAddAsisten, setShowAddAsisten] = useState(false)
    const [dataDetail, setDataDetail] = useState([])
    const [detailPokja, setDetailPokja] = useState([])
    const [detailVerifikator, setDetailVerifikator] = useState([])
    const [idAsisten, setIdAsisten] = useState('')
    const [asisten, setAsisten] = useState([])
    const [selectedAsisten, setSelectedAsisten] = useState([])

    const handleChangeAsisten = (selectedOption) => {
        const data = []
        selectedOption && selectedOption.map(val => (
            data.push(val.value)
        ))
        setSelectedAsisten(selectedOption)
        setAsisten(data)
    }
    const getAsisten = async (id, verifikator, data) => {
        setIdPokja(id)
        setDetailPokja(data)
        setDetailVerifikator(verifikator)
        setShowDetailAsisten(true)
        try {
            await Api.GetAsistenByVerifikator(localStorage.getItem('hris-token'), id, verifikator?.id).then((response) => {
                const res = response.data.results
                setDataDetail(res.data)
            }).catch((err) => {
                console.log(err)
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Data gagal ditambahkan!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }
    const postDataAsisten = async () => {
        setIsLoading(true)
        const data = {
            "pegawaiId": asisten
        }
        try {
            await Api.PostAsisten(localStorage.getItem('hris-token'), idPokja, detailVerifikator?.id, data).then((response) => {
                resetForm()
                setIsLoading(false)
                getAsisten(idPokja, detailVerifikator, detailPokja)
                toast.success('Berhasil menambahkan data!')
                setShowAddAsisten(false)
                setRefresh(true)
            }).catch((err) => {
                console.log(err)
                setIsLoading(false)
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Data gagal ditambahkan!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }
    const deleteDataModalAsisten = (idPokja, idVerifikator, id) => {
        setShowDeleteAsisten(!showDeleteAsisten)
        setIdPokja(idPokja)
        setIdVerifikator(idVerifikator)
        setIdAsisten(id)
        setRefresh(true)
    }

    const deleteDataAsisten = async () => {
        try {
            await Api.DeleteAsisten(localStorage.getItem('hris-token'), idPokja, idVerifikator, idAsisten).then((res) => {
                setShowDeleteAsisten(false)
                getAsisten(idPokja, detailVerifikator, detailPokja)
                toast.success('Berhasil menghapus data!')
            }).catch((err) => {
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Data gagal dihapus!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }
    useEffect(() => {
        getPegawai()
    }, [])

    useEffect(() => {
        getData()
        setRefresh(false)
    }, [refresh])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='relative'>
                <Modal
                    activeModal={showAdd}
                    title={"Tambah Kelompok Kerja"}
                    buttonClose={() => { setShowAdd(!showAdd); resetForm() }}
                    width={'832px'}
                    content={
                        <div className='space-y-[24px] w-[full] '>
                            <div className='space-y-3'>
                                <InputComponent type={'text'} label={'Nama Kelompok Kerja'} value={name} onChange={(e) => setName(e)} required={true} />
                                <div>
                                    <label className='w-full text-grey-thick text-[12px] font-medium mb-1 line-clamp-1'>Ketua Tim Penilai<span className='text-red-primer'> *</span></label>
                                    <Select
                                        value={timPenilaiId}
                                        onChange={selectedOption => setTimPenilaiId(selectedOption)}
                                        options={dataPegawai}
                                        placeholder={'Pilih pegawai...'}
                                        menuPortalTarget={document.body}
                                        menuPosition="fixed"
                                        styles={selectStyle}
                                    />
                                </div>
                            </div>
                            <div className="flex justify-end gap-[10px]">
                                <SecondaryButton action={() => { setShowAdd(!showAdd); resetForm() }} text={'Batal'} />
                                {isLoading ?
                                    <LoadingPrimaryButton />
                                    : <PrimaryButton action={() => postData()} text={'Simpan'} />
                                }
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEdit}
                    title={"Edit Kelompok Kerja"}
                    buttonClose={() => { setShowEdit(!showEdit); resetForm() }}
                    width={'832px'}
                    content={
                        <div className='space-y-[24px] w-[full] '>
                            <div className='space-y-3'>
                                <InputComponent type={'text'} label={'Nama Kelompok Kerja'} value={name} onChange={(e) => setName(e)} required={true} />
                                <div>
                                    <label className='w-full text-grey-thick text-[12px] font-medium mb-1 line-clamp-1'>Ketua Tim Penilai<span className='text-red-primer'> *</span></label>
                                    <Select
                                        value={timPenilaiId}
                                        onChange={selectedOption => setTimPenilaiId(selectedOption)}
                                        options={dataPegawai}
                                        placeholder={'Pilih pegawai...'}
                                        menuPortalTarget={document.body}
                                        menuPosition="fixed"
                                        styles={selectStyle}
                                    />
                                </div>
                            </div>
                            <div className="flex justify-end gap-[10px]">
                                <SecondaryButton action={() => { setShowEdit(!showEdit); resetForm() }} text={'Batal'} />
                                {isLoading ?
                                    <LoadingPrimaryButton />
                                    : <PrimaryButton action={() => editData()} text={'Simpan'} />
                                }
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showAddVerifikator}
                    title={"Tambah Verifikator"}
                    buttonClose={() => { setShowAddVerifikator(!showAddVerifikator); resetForm() }}
                    width={'832px'}
                    content={
                        <div className='space-y-[24px] w-[full] '>
                            <div className='space-y-3'>
                                <div>
                                    <label className='w-full text-grey-thick text-[12px] font-medium mb-1 line-clamp-1'>Nama Verifikator<span className='text-red-primer'> *</span></label>
                                    <Select
                                        isMulti
                                        value={selectedVerifikator}
                                        onChange={selectedOption => handleChangeVerifikator(selectedOption)}
                                        options={dataPegawai}
                                        placeholder={'Pilih pegawai...'}
                                        menuPortalTarget={document.body}
                                        menuPosition="fixed"
                                        styles={selectStyle}
                                    />
                                </div>
                            </div>
                            <div className="flex justify-end gap-[10px]">
                                <SecondaryButton action={() => { setShowAddVerifikator(!showAddVerifikator); resetForm() }} text={'Batal'} />
                                {isLoading ?
                                    <LoadingPrimaryButton />
                                    : <PrimaryButton action={() => postDataVerifikator()} text={'Simpan'} />
                                }
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showDetailAsisten}
                    title={"Detail"}
                    buttonClose={() => { setShowDetailAsisten(!showDetailAsisten); resetForm() }}
                    width={'832px'}
                    content={
                        <div className='space-y-[24px] w-[full] '>
                            <div className='space-y-3'>
                                <div className='grid grid-cols-4'>
                                    <p className='text-sm font-light '>Kelompok</p>
                                    <p className='text-sm font-light col-span-3'>: {detailPokja?.name}</p>
                                    <p className='text-sm font-light '>Ketua Tim Penilai</p>
                                    <p className='text-sm font-light col-span-3'>: {detailPokja?.timPenilai?.fullname}</p>
                                    <p className='text-sm font-light '>Verifikator</p>
                                    <p className='text-sm font-light col-span-3'>: {detailVerifikator?.user?.fullname}</p>
                                </div>
                                <div className='flex items-center gap-3'>
                                    <h1>Daftar Nama Asisten</h1>
                                    <button
                                        onClick={() => { setShowAddAsisten(true) }}
                                        className='bg-blue-thin p-2 rounded-[10px] flex items-center gap-1 w-fit'>
                                        <AiOutlinePlus className='text-blue-thick' />
                                        <span className='text-dark-3 text-[10px] font-[500] whitespace-nowrap'>Tambah Asisten</span>
                                    </button>
                                </div>
                                <div className='space-y-5 overflow-auto'>
                                    <table className='min-w-full'>
                                        <thead>
                                            <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                                                <th className='border py-3 px-5'>No</th>
                                                <th className='border py-3 px-5'>Nama Asisten</th>
                                                <th className='border py-3 px-5'>Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataDetail && dataDetail.map((val, index) => (
                                                <tr key={index} className='border'>
                                                    <td className='border py-3 px-5 text-grey-thick text-xs font-medium text-center'>
                                                        {index + 1}
                                                    </td>
                                                    <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.user?.user_detail?.gelar_depan === '-' ? "" : val?.user?.user_detail?.gelar_depan} {val?.user?.fullname} {val?.user?.user_detail?.gelar_belakang === '-' ? "" : val?.user?.user_detail?.gelar_belakang}</td>
                                                    <td className='border py-3 px-5 text-grey-thick text-xs font-medium flex justify-center'>
                                                        <button
                                                            onClick={() => {
                                                                deleteDataModalAsisten(idPokja, detailVerifikator?.id, val?.id);
                                                            }}
                                                            className='bg-blue-thin p-2 rounded-[10px] flex items-center gap-1 w-fit'>
                                                            <AiOutlineDelete className='text-sm text-blue-thick' />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="flex justify-end gap-[10px]">
                                <SecondaryButton action={() => { setShowDetailAsisten(!showDetailAsisten); resetForm() }} text={'Tutup'} />
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showAddAsisten}
                    title={"Tambah Asisten"}
                    buttonClose={() => { setShowAddAsisten(!showAddAsisten); resetForm() }}
                    width={'832px'}
                    content={
                        <div className='space-y-[24px] w-[full] '>
                            <div className='space-y-3'>
                                <div>
                                    <label className='w-full text-grey-thick text-[12px] font-medium mb-1 line-clamp-1'>Nama Asisten<span className='text-red-primer'> *</span></label>
                                    <Select
                                        isMulti
                                        value={selectedAsisten}
                                        onChange={selectedOption => handleChangeAsisten(selectedOption)}
                                        options={dataPegawai}
                                        placeholder={'Pilih pegawai...'}
                                        menuPortalTarget={document.body}
                                        menuPosition="fixed"
                                        styles={selectStyle}
                                    />
                                </div>
                            </div>
                            <div className="flex justify-end gap-[10px]">
                                <SecondaryButton action={() => { setShowAddAsisten(!showAddAsisten); resetForm() }} text={'Batal'} />
                                {isLoading ?
                                    <LoadingPrimaryButton />
                                    : <PrimaryButton action={() => postDataAsisten()} text={'Simpan'} />
                                }
                            </div>
                        </div>
                    }
                />
                < ModalDelete
                    activeModal={showDelete}
                    buttonClose={() => setShowDelete(!showDelete)}
                    submitButton={deleteData}
                />
                < ModalDelete
                    activeModal={showDeleteVerifikator}
                    buttonClose={() => setShowDeleteVerifikator(!showDeleteVerifikator)}
                    submitButton={deleteDataVerifikator}
                />
                < ModalDelete
                    activeModal={showDeleteAsisten}
                    buttonClose={() => setShowDeleteAsisten(!showDeleteAsisten)}
                    submitButton={deleteDataAsisten}
                />
            </div>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Pengaturan Angka Kredit Minimum'} LinkBack={'/performance-setting'} />
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='space-y-5 overflow-auto'>
                        <div className='flex justify-start'>
                            <IconPrimaryButton icon={IconPlus} action={() => setShowAdd(true)} text={"Tambah Kelompok Kerja"} />
                        </div>
                        <table className='min-w-full'>
                            <thead>
                                <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                                    <th className='border py-3 px-5'>No</th>
                                    <th className='border py-3 px-5'>Aksi</th>
                                    <th className='border py-3 px-5'>Kelompok</th>
                                    <th className='border py-3 px-5'>Ketua Tim Penilai</th>
                                    <th className='border py-3 px-5'>Verifikator</th>
                                    <th className='border py-3 px-5'>Asisten</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.map((val, index) => (
                                    <tr key={index} className='border'>
                                        <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>
                                            {index + 1}
                                        </td>
                                        <td className='border py-3 px-5 text-grey-thick text-xs font-medium space-y-2'>
                                            <button
                                                onClick={() => {
                                                    setIdPokja(val?.id);
                                                    setShowAddVerifikator(true);
                                                }}
                                                className='bg-blue-thin p-2 rounded-[10px] flex items-center gap-1 w-fit'>
                                                <AiOutlinePlus className='text-blue-thick' />
                                                <span className='text-dark-3 text-[10px] font-[500] whitespace-nowrap'>Tambah Verifikator</span>
                                            </button>
                                            <button
                                                onClick={() => getDataById(val.id)}
                                                className='bg-blue-thin p-2 rounded-[10px] flex items-center gap-1 w-fit'>
                                                <HiOutlinePencil className='text-blue-thick' />
                                                <span className='text-dark-3 text-[10px] font-[500] whitespace-nowrap'>Edit Kelompok Kerja</span>
                                            </button>
                                            <button
                                                onClick={() => {
                                                    deleteDataModal(val?.id);
                                                }}
                                                className='bg-blue-thin p-2 rounded-[10px] flex items-center gap-1 w-fit'>
                                                <AiOutlineDelete className='text-sm text-blue-thick' />
                                                <span className='text-dark-3 text-[10px] font-[500] whitespace-nowrap'>Hapus Kelompok Kerja</span>
                                            </button>
                                        </td>
                                        <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val.name ?? "-"}</td>
                                        <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.timPenilai?.fullname ?? "-"}</td>
                                        <td className='border py-0 px-0' colSpan={2}>
                                            <table className='min-w-full'>
                                                <tbody>
                                                    {val?.kelompokKerjaVerifikator && val?.kelompokKerjaVerifikator.map((item, i) => (
                                                        <tr key={i}>
                                                            <td className='border py-3 px-5 text-grey-thick text-xs font-medium space-y-2 flex items-center justify-between gap-1'>
                                                                {item?.user?.fullname}
                                                                <button
                                                                    onClick={() => {
                                                                        deleteDataModalVerifikator(item?.id, val?.id);
                                                                    }}
                                                                    className='bg-blue-thin p-2 rounded-[10px] flex items-center gap-1 w-fit'>
                                                                    <AiOutlineDelete className='text-sm text-blue-thick' />
                                                                </button>
                                                            </td>
                                                            <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>
                                                                <button
                                                                    onClick={() => { getAsisten(val.id, item, val) }}
                                                                    className='bg-blue-thin p-2 rounded-[10px] flex items-center gap-1 w-fit'>
                                                                    <AiFillEye className='text-blue-thick' />
                                                                    <span className='text-dark-3 text-[10px] font-[500] whitespace-nowrap'>Lihat Detail</span>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            lengthData={totalCount}
                            limitData={limit}
                            onPageChange={handlePageChange}
                            onPrevChange={handlePrevChange}
                            onNextChange={handleNextChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KelompokKerja
