import moment from 'moment'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlineDownload, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { BiDownload } from 'react-icons/bi'
import { HiPaperClip } from 'react-icons/hi'
import { IoEyeOutline } from 'react-icons/io5'
import { useLocation } from 'react-router-dom'
import Api from '../../../../../../Api'
import { Modal, Navbar } from '../../../../../../components'
import imageHandle from '../../../../../../utils/imageHandle'
import languages from '../../../../../../utils/languages'

const PenilaianLaporanPeriodik = () => {
    moment.locale('id');

    const params = useLocation()
    const idLaporan = params.state.id

    const [refresh, setRefresh] = useState(false)

    const [data, setData] = useState()
    const getDetail = async () => {
        try {
            await Api.GetLaporanTahunanById(localStorage.getItem('hris-token'), idLaporan).then((response) => {
                // console.log("ini", response)
                setData(response.data.results)
            })
        } catch (error) {
            console.log(error)
        }
    }
    const updateLaporan = async (status) => {
        try {
            const data = {
                "status": status
            }
            await Api.UpdateLaporanTahunan(localStorage.getItem('hris-token'), idLaporan, data).then((response) => {
                toast.success('Berhasil Memperbarui Data')
                setVerifikasi(true)
                setRefresh(true)
            }).catch((error) => {
                console.log(error)
                const e = error.response.data
                for (let i = 0; i < e.length; i++) {
                    toast.error(e[i])
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data')
        }
    }
    const [showDetailBuktiDukung, setShowDetailBuktiDukung] = useState(false)
    const [detailBuktiDukungBulanan, setDetailBuktiDukungBulanan] = useState([])
    const [detailBuktiDukungPeriode, setDetailBuktiDukungPeriode] = useState([])

    const handleDetailBuktiDukung = (bulanan, periode) => {
        setShowDetailBuktiDukung(true)
        setDetailBuktiDukungBulanan(bulanan)
        setDetailBuktiDukungPeriode(periode)
        console.log(periode)
    }

    const [verifikasi, setVerifikasi] = useState(false)
    const [passphrase, setPassphrase] = useState("")
    const submitPassprashe = async () => {
        try {
            const formData = new FormData();
            formData.append('passphrase', passphrase);
            await Api.PostPassphraseLaporanPeriodik(localStorage.getItem('hris-token'), idLaporan, formData).then((response) => {
                toast.success('Berhasil menandatangani dokumen!')
                setVerifikasi(false)
            }).catch((error) => {
                console.log(error.response.data.message)
                toast.error(error.response.data.message ?? 'Gagal menandatangani dokumen!')
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }

    const [passwordType, setPasswordType] = useState('password')
    const ChangePasswordType = () => {
        if (passwordType === 'password') {
            setPasswordType('text')
        } else {
            setPasswordType('password')
        }
    }

    useEffect(() => {
        getDetail()
        setRefresh(false)
    }, [refresh])
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>

            <Modal
                activeModal={showDetailBuktiDukung}
                title={"Detail Bukti Dukung"}
                buttonClose={() => setShowDetailBuktiDukung(!showDetailBuktiDukung)}
                width={'700px'}
                content={
                    <div className='overflow-auto min-h-[300px] max-h-[400px] space-y-5'>
                        {detailBuktiDukungPeriode?.length > 0 ?
                            <table className='min-w-full'>
                                <thead>
                                    <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                                        <th className='border py-3 px-5'>No.</th>
                                        <th className='border py-3 px-5'>Tanggal</th>
                                        <th className='border py-3 px-5'>Nama Bukti Dukung</th>
                                        <th className='border py-3 px-5'>Realisasi Kuantitas</th>
                                        <th className='border py-3 px-5'>Realisasi Biaya</th>
                                        <th className='border py-3 px-5'>Dokumen</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {detailBuktiDukungPeriode && detailBuktiDukungPeriode.map((val, idx) => (
                                        <tr key={idx} className='border'>
                                            <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{idx + 1}.</td>
                                            <td className='border py-3 px-5 text-grey-thick text-xs font-medium whitespace-nowrap'>{val?.tanggal}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.name}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.realisasi_kuantitas}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.realitas_target_biaya}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>
                                                <ul className='list-disc text-grey-thick text-xs font-medium'>
                                                    {val?.files && val?.files.map((file, i) => (
                                                        <li key={i} className='flex gap-2 items-center '>
                                                            <div className='bg-blue-thin rounded-md flex gap-2 items-center p-2 text-xs whitespace-nowrap'><HiPaperClip /> <span>{file?.file_name}</span></div>
                                                            <a href={imageHandle(file?.file_path)} download={file?.file_name} target='_blank' className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center' rel="noreferrer">
                                                                <AiOutlineDownload className='text-sm text-blue-thick' />
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            : null}
                        {detailBuktiDukungBulanan && detailBuktiDukungBulanan.map((bulan) => (
                            <div>
                                <div className='flex gap-3 mb-2'>
                                    <p className='text-grey-thick text-xs font-medium'>Bukti Dukung Bulan</p>
                                    <p className='text-grey-thick text-xs font-medium'>: {bulan?.bulan}</p>
                                </div>
                                <table className='min-w-full'>
                                    <thead>
                                        <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                                            <th className='border py-3 px-5'>No.</th>
                                            <th className='border py-3 px-5'>Tanggal</th>
                                            <th className='border py-3 px-5'>Nama Bukti Dukung</th>
                                            <th className='border py-3 px-5'>Realisasi Kuantitas</th>
                                            <th className='border py-3 px-5'>Realisasi Biaya</th>
                                            <th className='border py-3 px-5'>Dokumen</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bulan?.bukti && bulan?.bukti.map((val, idx) => (
                                            <tr key={idx} className='border'>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{idx + 1}.</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium whitespace-nowrap'>{val?.tanggal}</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.name}</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.realisasi_kuantitas}</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.realitas_target_biaya}</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>
                                                    <ul className='list-disc text-grey-thick text-xs font-medium'>
                                                        {val?.files && val?.files.map((file, i) => (
                                                            <li key={i} className='flex gap-2 items-center '>
                                                                <div className='bg-blue-thin rounded-md flex gap-2 items-center p-2 text-xs whitespace-nowrap'><HiPaperClip /> <span>{file?.file_name}</span></div>
                                                                <a href={imageHandle(file?.file_path)} download={file?.file_name} target='_blank' className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center' rel="noreferrer">
                                                                    <AiOutlineDownload className='text-sm text-blue-thick' />
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ))}
                    </div>
                }
            />
            <Modal
                activeModal={verifikasi}
                title={''}
                buttonClose={() => setVerifikasi(false)}
                width={'550px'}
                content={
                    <div className='space-y-6'>
                        <div className="text-center text-sky-950 text-base font-semibold">Apakah anda ingin menandatangani surat tersebut ?</div>
                        <div className='relative'>
                            <input type={passwordType} onChange={(e) => setPassphrase(e.target.value)} value={passphrase} className='text-zinc-700 text-xs font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='passpharse' />
                            <button onClick={ChangePasswordType} className='absolute right-5 top-3 text-xl text-[#A8A8A8]'>
                                {passwordType === 'text' ?
                                    <AiOutlineEye />
                                    :
                                    <AiOutlineEyeInvisible />
                                }
                            </button>
                        </div>
                        <div className='flex items-center justify-center gap-[12px] mt-5'>
                            <button onClick={() => setVerifikasi(false)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Batal" : "Cancel"}</button>
                            <button onClick={submitPassprashe} className='bg-[#0E5073] text-white text-sm rounded-[6px] py-[10px] px-[25px]'>Tanda tangan</button>
                        </div>
                    </div>
                }
            />
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Detail Laporan Kinerja Asisten'} LinkBack={'/laporan-kinerja'} />
                <div className='space-y-[24px] bg-white px-[40px] py-[40px] rounded-[12px] border'>
                    <h1 className="text-zinc-800 text-xl font-semibold">Detail Laporan Kinerja Asisten</h1>
                    <div className='grid grid-cols-2 items-center justify-center gap-[14px] w-full h-full'>

                        <div className='w-full h-full px-[34px] py-[24px] space-y-[14px] border border-gray-500 rounded-xl'>
                            <h1 className="text-zinc-700 text-sm font-medium">{languages.language === 'id' ? "Pegawai yang Dinilai" : "Employees Assessed"}</h1>
                            <div className='grid grid-cols-12'>
                                <div className='col-span-5 text-gray-500 text-xs font-normal'>
                                    <h1>Nama</h1>
                                    <h1>Nomor Induk Pegawai</h1>
                                    <h1>Jabatan</h1>
                                    <h1>Penempatan</h1>
                                </div>
                                <div className='col-span-1 text-gray-500 text-xs font-normal justify-end'>
                                    <h1>:</h1>
                                    <h1>:</h1>
                                    <h1>:</h1>
                                    <h1>:</h1>
                                </div>
                                <div className='col-span-6 text-gray-500 text-xs font-normal'>
                                    <h1 className='truncate'>{data?.pegawaiDinilai?.fullname}</h1>
                                    <h1 className='truncate'>{data?.pegawaiDinilai?.nomor_induk_pegawai}</h1>
                                    <h1 className='truncate'>{data?.pegawaiDinilai?.job_position}</h1>
                                    <h1 className='truncate'>{data?.pegawaiDinilai?.penempatan}</h1>
                                </div>
                            </div>
                            <h1 className={`capitalize ${data?.pegawaiDinilai?.approach_type === 'qualitative' ? 'text-base-green' : 'text-base-yellow'} text-xs font-[600] line-clamp-1`}>{data?.pegawaiDinilai?.approach_type}</h1>
                        </div>

                        <div className='w-full h-full px-[34px] py-[24px] space-y-[14px] border border-gray-500 rounded-xl'>
                            <h1 className="text-zinc-700 text-sm font-medium">{languages.language === 'id' ? "Pejabat Penilai Kinerja" : "Appraisal Employee"}</h1>
                            <div className='text-gray-500 text-xs font-normal'>
                                <h1 className='grid grid-cols-12'>
                                    <span className='col-span-5'>Nama</span>
                                    <span>:</span>
                                    <span className='col-span-6'>{data?.pejabatPenilai?.fullname}</span>
                                </h1>
                                <h1 className='grid grid-cols-12'>
                                    <span className='col-span-5'>Nomor Induk Pegawai</span>
                                    <span>:</span>
                                    <span className='col-span-6'>{data?.pejabatPenilai?.nomor_induk_pegawai}</span>
                                </h1>
                                <h1 className='grid grid-cols-12'>
                                    <span className='col-span-5'>Jabatan</span>
                                    <span>:</span>
                                    <span className='col-span-6'>{data?.pejabatPenilai?.job_position}</span>
                                </h1>
                                <h1 className='grid grid-cols-12'>
                                    <span className='col-span-5'>Penempatan</span>
                                    <span>:</span>
                                    <span className='col-span-6'>{data?.pejabatPenilai?.penempatan}</span>
                                </h1>
                                <h1 className='grid grid-cols-12'>
                                    <span className='col-span-5'>Catatan</span>
                                    <span>:</span>
                                    <span className='col-span-6'>{data?.laporanTahunan?.harapan}</span>
                                </h1>
                            </div>
                        </div>

                    </div>

                    <div className='border p-5 rounded-[12px]'>
                        <h1 className='text-dark-3 text-[20px] font-[600]'>Hasil Kerja</h1>
                        <div className='mt-5'>
                            <h1 className="text-zinc-800 text-sm font-medium mb-3">Tugas Utama</h1>
                            <div className='mt-5 mb-[44px] overflow-auto'>
                                <table className='min-w-full'>
                                    <thead>
                                        <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                                            <th rowSpan={2} className='border py-3 px-5'>No.</th>
                                            <th rowSpan={2} className='border py-3 px-5'>RHK Atasan yang diintervensi</th>
                                            <th rowSpan={2} className='border py-3 px-5'>Jenis RHK</th>
                                            <th rowSpan={2} className='border py-3 px-5'>Nama RHK</th>
                                            <th rowSpan={2} className='border py-3 px-5'>Target RHK</th>
                                            <th colSpan={10} className='border py-3 px-5'>Rencana Aksi</th>
                                        </tr>
                                        <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                                            <th className='border py-3 px-5 min-w-[200px]'>Nama Rencana Aksi</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Target Kualitas</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Target Kuantitas</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Target Waktu</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Target Biaya</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Bukti Dukung</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Realisasi Kuantitas</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Realisasi Biaya</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Realisasi Kualitas</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.rencanaHasilKerja && data?.rencanaHasilKerja.map((val, index) => (
                                            <tr key={index} className='border'>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{index + 1}.</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.rhk?.name ?? "-"}</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.type ?? "-"}</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.name ?? "-"}</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.target_rhk ?? "-"}</td>
                                                <td className='border py-0 px-0' colSpan={10}>
                                                    <table className='min-w-full'>
                                                        <tbody>
                                                            {val?.rencana_aksi && val?.rencana_aksi.map((v, i) => (
                                                                <tr key={i} className='border'>
                                                                    <td className='py-3 px-5 text-grey-thick text-xs font-medium min-w-[200px]'>{v?.name ?? "-"}</td>
                                                                    <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>{v?.target_kualitas ?? "-"}</td>
                                                                    <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>{v?.target_kuantitas ?? "-"}</td>
                                                                    <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>{v?.target_waktu ?? "-"}</td>
                                                                    <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>{v?.target_biaya ?? "0"}</td>
                                                                    <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>
                                                                        <div className='flex items-center justify-center gap-3'>
                                                                            <p>{v?.total_file_bukti ? v?.total_file_bukti : '0'} Dokumen</p>
                                                                            <button
                                                                                onClick={() => handleDetailBuktiDukung(v?.bukti_bulanan, v?.bukti_periode)}
                                                                                className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                                                <IoEyeOutline className='text-blue-thick' />
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                    <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>{v?.realisasi_kuantitas ?? "-"}</td>
                                                                    <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>{v?.realitas_target_biaya ?? "-"}</td>
                                                                    <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>{v?.penilaian_tahunan?.realisasi_kualitas ?? "-"}</td>

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <h1 className="text-zinc-800 text-sm font-medium mb-3">Tugas Tambahan</h1>
                            <div className='mt-5 mb-[44px] overflow-auto'>
                                <table className='min-w-full'>
                                    <thead>
                                        <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                                            <th className='border py-3 px-5'>No.</th>
                                            <th className='border py-3 px-5'>Tanggal</th>
                                            <th className='border py-3 px-5'>Uraian Kegiatan</th>
                                            <th className='border py-3 px-5'>Dasar Penugasan</th>
                                            <th className='border py-3 px-5'>Hasil</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.tugasTambahan && data.tugasTambahan.map((val, index) => (
                                            <tr className='border'>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{index + 1}.</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium max-w-[200px]'>{moment(val?.tanggal).format("D MMMM YYYY")}</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium max-w-[200px]'>{val.uraian_kegiatan}</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium max-w-[200px]'>{val?.dasar_penugasan}</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium max-w-[200px]'>{val?.hasil}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='border p-5 rounded-[12px]'>
                        <div className='mt-5 mb-[44px] overflow-auto'>
                            {data?.dataPerilakuKerja && data?.dataPerilakuKerja.map((evaluation, evaluationIndex) => (
                                <table key={evaluationIndex} className='min-w-full'>
                                    <thead>
                                        <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                                            <th className='border py-3 px-5'>No.</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Perilaku</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Umpan Balik</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Umpan Balik (Bulanan)</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Umpan Balik Rekan Kerja</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {evaluation && evaluation.settings.map((setting, settingIndex) => (
                                            <tr className='border'>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{settingIndex + 1}.</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium min-w-[200px]'>
                                                    <p className='text-grey-thick text-xs font-medium'>{setting.setting_name}</p>
                                                    <ul className='text-grey-thick text-xs font-medium list-disc ml-4'>
                                                        {setting && setting.points.map((point, pointIndex) => (
                                                            <li key={pointIndex}>{point.point_name}</li>
                                                        ))}
                                                    </ul>
                                                </td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs text-center font-medium min-w-[200px]'>
                                                    {setting.feedback ? setting.feedback : "-"}
                                                </td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium min-w-[200px]'>
                                                    {Object.entries(setting.penilaian_atasan).map(([category, count]) => (
                                                        <h1 className='text-[10px] rounded-lg px-[8px] py-1 w-fit'>
                                                            <span>{category}</span> : <span>{count}</span>
                                                        </h1>
                                                    ))}
                                                </td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium min-w-[200px]'>
                                                    {Object.entries(setting.penilaian_rekan_kerja).map(([category, count]) => (
                                                        <h1 className='text-[10px] rounded-lg px-[8px] py-1 w-fit'>
                                                            <span>{category}</span> : <span>{count}</span>
                                                        </h1>
                                                    ))}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ))}
                        </div>
                    </div>

                    {data?.files?.length > 0 &&
                        (
                            <div className='my-5 space-y-2'>
                                <h1 className="text-zinc-800 text-base font-semibold">SK Penetapan Inovasi</h1>
                                <div className='w-full grid grid-cols-2 gap-3'>
                                    <div className='space-y-2'>
                                        {data?.files?.length > 0 && (
                                            <div className='flex items-center justify-start gap-[8px] flex-wrap '>
                                                {data?.files?.map((file, index) => (
                                                    <div key={index} className='flex items-center gap-10'>
                                                        <div className='bg-[#CEDFEA] rounded-md flex gap-2 items-center p-2 text-xs'><HiPaperClip /> <span>{file?.originalname}</span></div>
                                                        <a href={imageHandle(file?.file_path)} download={file?.originalname} target='_blank' className='text-blue-primer font-semibold text-sm flex gap-2' rel="noreferrer">
                                                            <BiDownload className='text-xl' />
                                                            <p>Unduh Dokumen</p>
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {data?.filesBulanan?.length > 0 &&
                        (
                            <div className='my-5 space-y-2'>
                                {/* <h1 className="text-zinc-800 text-base font-semibold">SK Penetapan Inovasi</h1> */}
                                <div className='w-full grid grid-cols-2 gap-3'>
                                    <div className='space-y-2'>
                                        {data?.files?.length > 0 && (
                                            <div className='flex items-center justify-start gap-[8px] flex-wrap '>
                                                {data?.files?.map((file, index) => (
                                                    <div key={index} className='flex items-center gap-10'>
                                                        <div className='bg-[#CEDFEA] rounded-md flex gap-2 items-center p-2 text-xs'><HiPaperClip /> <span>{file?.originalname}</span></div>
                                                        <a href={imageHandle(file?.file_path)} download={file?.originalname} target='_blank' className='text-blue-primer font-semibold text-sm flex gap-2' rel="noreferrer">
                                                            <BiDownload className='text-xl' />
                                                            <p>Unduh Dokumen</p>
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {data?.laporanTahunan?.status === "Sudah Dinilai" ?
                        <div className='flex gap-2 justify-end'>
                            <button
                                onClick={() => {
                                    updateLaporan("Sudah Disetujui")
                                }}
                                className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-dark-9 text-sm font-[500]'>Setujui</h1>
                            </button>
                        </div>
                        : null
                    }
                </div>

            </div>
        </div>
    )
}

export default PenilaianLaporanPeriodik