import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { CgTrashEmpty } from 'react-icons/cg'
import Api from '../../../../Api'
import { ModalDelete, Navbar } from '../../../../components'
import languages from '../../../../utils/languages'

const Device = () => {
    const [refresh, setRefresh] = useState(false)
    const [data, setData] = useState('')

    const getData = async () => {
        try {
            const response = await Api.GetDevice(localStorage.getItem('hris-token'))
            console.log(response?.data?.results?.data)
            setData(response?.data?.results?.data)
        } catch (error) {
            console.log(error)
        }
    }

    const [showModalDelete, setShowModalDelete] = useState(false)
    const [id, setId] = useState('')
    const deleteModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setId(id)
        setRefresh(true)
    }

    const deleteItem = async () => {
        try {
            await Api.DeleteDevice(localStorage.getItem('hris-token'), id).then((response) => {
                setRefresh(true)
                setShowModalDelete(false)
                toast.success('Berhasil menghapus data!')
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
            toast.error('Data Gagal dihapus')
        }
    }

    useEffect(() => {
        getData()
        setRefresh(false)
    }, [refresh])
    return (
        <>
            <div className='bg-[#F8F9FB] min-h-screen'>
                <div className='relative'>
                    <ModalDelete
                        activeModal={showModalDelete}
                        buttonClose={() => setShowModalDelete(!showModalDelete)}
                        submitButton={deleteItem}
                    />
                </div>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar="Pengaturan Perangkat" LinkBack={'/dashboard'} />
                    <div className='bg-white border shadow-sm rounded-[12px] px-[22px] py-[36px] space-y-5'>
                        <div className='flex flex-wrap items-center justify-between gap-3'>
                            <div className='w-full lg:w-auto'>
                                <h1 className='text-dark-3 text-[16px] font-[500]'>Pengaturan Perangkat</h1>
                                <h1 className='text-[#A8A8A8] text-xs font-[500]'>Daftar Perangkat</h1>
                            </div>
                        </div>
                        <div className='overflow-auto'>
                            <table className='w-full space-y-[10px]'>
                                <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center gap-[15px] min-w-[50px] max-w-[50px]'>
                                        <h1 className='text-dark-5 text-xs font-[600]'>No</h1>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[200px] max-w-[250px]'>
                                        <h1 className='text-dark-5 text-xs font-[600]'>Nama</h1>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[250px] max-w-[250px]'>
                                        <h1 className='text-dark-5 text-xs font-[600]'>Email</h1>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-dark-5 text-xs font-[600]'>Platform</h1>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-dark-5 text-xs font-[600]'>Brand</h1>
                                    </div>
                                    <div className='flex items-center justify-end gap-[15px] w-full'>
                                        <h1 className='text-dark-5 text-xs font-[600]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                                    </div>
                                </div>
                                {data.length === 0 ?
                                    <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[50px] max-w-[50px]'>-</div>
                                        <div className='min-w-[200px] max-w-[250px]'>-</div>
                                        <div className='min-w-[250px] max-w-[250px]'>-</div>
                                        <div className='min-w-[150px] max-w-[150px]'>-</div>
                                        <div className='min-w-[150px] max-w-[150px]'>-</div>
                                        <div className='w-full flex items-center justify-end gap-[5px]'>-</div>

                                    </div>
                                    :
                                    Object.values(data).map((val, index) => {
                                        return (
                                            <div key={index} className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                                <div className='min-w-[50px] max-w-[50px]'>
                                                    <h1 className='text-xs line-clamp-1'>{index + 1}</h1>
                                                </div>
                                                <div className='min-w-[200px] max-w-[250px]'>
                                                    <h1 className='text-xs'>{val?.user?.fullname ?? "-"}</h1>
                                                </div>
                                                <div className='min-w-[250px] max-w-[250px]'>
                                                    <h1 className='text-xs'>{val?.user?.email ?? "-"}</h1>
                                                </div>
                                                <div className='min-w-[150px] max-w-[150px]'>
                                                    <h1 className='text-xs line-clamp-1'>{val?.platform}</h1>
                                                </div>
                                                <div className='min-w-[150px] max-w-[150px]'>
                                                    <h1 className='text-xs line-clamp-1'>{val?.brand}</h1>
                                                </div>
                                                <div className='w-full flex items-center justify-end'>
                                                    <button onClick={() => deleteModal(val.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <CgTrashEmpty className='text-[#003049]' />
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Device