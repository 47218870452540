import React, { useEffect, useState } from 'react'
import { Modal, ModalDelete, Navbar, Pagination } from '../../../../../../components'
import Api from '../../../../../../Api'
import moment from 'moment'
import { HiOutlinePencil } from 'react-icons/hi'
import { CgTrashEmpty } from 'react-icons/cg'
import toast from 'react-hot-toast'
import InputComponent from '../../../../../../components/Input/inputComponent'
import { AiOutlineDownload, AiOutlinePlus } from 'react-icons/ai'
import imageHandle from '../../../../../../utils/imageHandle'

const PengajuanInovasi = () => {
    const [refresh, setRefresh] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalData, setTotalData] = useState('')
    const [limit, setLimit] = useState(10)

    const [data, setData] = useState([])

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };
    const getInovasi = async () => {
        try {
            const response = await Api.GetInovasi(localStorage.getItem('hris-token'), limit, currentPage)
            console.log(response.data.results.data)
            setData(response.data.results.data)
            setTotalPages(response.data.results.totalPages)
            setTotalData(response.data.results.totalCount)
        } catch (error) {
            console.log(error)
        }
    }

    const [showModalAdd, setShowModalAdd] = useState(false)
    const [showModalEdit, setShowModalEdit] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState(false)

    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [nomorSK, setNomorSK] = useState('')
    const [description, setDescription] = useState('')
    const [file, setFile] = useState('')
    const [namePath, setNamePath] = useState('')

    const resetForm = () => {
        setName('')
        setDescription('')
        setFile('')
        setName('')
        setNomorSK('')
    }
    const handleChangeFile = (e) => {
        const maxSize = 10 * 1024 * 1024
        const allowedExtensions = ['pdf'];
        const file = e?.target?.files[0]
        const fileExtension = file?.name?.split('.').pop().toLowerCase();
        if (file && file.size > maxSize) {
            toast.error('File tidak boleh lebih dari 10 MB!')
            setFile(null)
        } else if (!allowedExtensions.includes(fileExtension)) {
            toast.error('File harus berformat pdf!')
        } else {
            setNamePath(e.target.files[0].name)
            setFile(e.target.files[0])
        }
    }

    const postData = async () => {
        try {
            if (name === '' || name === null) {
                toast.error("Nama Inovasi wajib diisi!")
            } else if (nomorSK === '' || nomorSK === null) {
                toast.error("Nomor SK wajib diisi!")
            } else if (file === '' || file === null) {
                toast.error("Dokumen wajib diisi!")
            } else {
                const data = {
                    name: name,
                    description: description,
                    nomor_sk: nomorSK,
                    sk_penetapan_inovasi: file
                }
                const formData = new FormData();
                for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                        formData.append(key, data[key]);
                    }
                }
                for (const pair of formData.entries()) {
                    console.log(pair[0] + ', ' + pair[1]);
                }
                await Api.PostInovasi(localStorage.getItem('hris-token'), formData).then((res) => {
                    setRefresh(true)
                    resetForm()
                    setShowModalAdd(false)
                    toast.success('Berhasil menambahkan data!')
                }).catch((error) => {
                    console.log(error)
                    if (error?.response?.data?.errors) {
                        toast.error(error?.response?.data?.errors)
                    } else if (error?.response?.data?.message) {
                        toast.error(error?.response?.data?.message)
                    } else {
                        toast.error("Gagal menambahkan data!")
                    }
                })

            }
        } catch (error) {
            console.log(error)
        }
    }

    const deleteDataModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setId(id)
    }

    const deleteData = async () => {
        try {
            await Api.DeleteInovasi(localStorage.getItem('hris-token'), id).then((res) => {
                setShowModalDelete(!showModalDelete)
                setRefresh(true)
                toast.success('Berhasil menghapus data!')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal menghapus data!')
        }
    }

    useEffect(() => {
        getInovasi()
        setRefresh(false)
    }, [refresh])
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='relative'>
                <Modal
                    activeModal={showModalAdd}
                    title={'Tambah Inovasi'}
                    buttonClose={() => { setShowModalAdd(!showModalAdd); resetForm() }}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className='space-y-2'>
                                <InputComponent onChange={(e) => setName(e)} value={name} label={'Nama Inovasi'} placeholder={'Masukkan nama inovasi...'} required={true} />
                            </div>
                            <div className='space-y-2'>
                                <InputComponent onChange={(e) => setNomorSK(e)} value={nomorSK} label={'Nomor SK Inovasi'} placeholder={'Masukkan nomor SK Inovasi...'} required={true} />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-grey-thick mb-[9px]'>Deskripsi</h1>
                                <textarea onChange={(e) => setDescription(e.target.value)} value={description} cols={4} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-thick w-full' placeholder='Masukkan Deskripsi...' />
                            </div>
                            <div>
                                <h1 className="text-neutral-500 text-[11px] font-medium mb-1">Dokumen SK Penetapan Inovasi <span className='text-red-900'>*</span></h1>
                                <label htmlFor='upload-file' className='cursor-pointer'>
                                    <div className='border shadow-sm rounded-lg relative text-center justify-center px-[70px] py-[11px] w-full'>
                                        <div className='flex items-center justify-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                <path d="M5.95829 18.3327C4.56496 18.3327 3.37635 17.853 2.39246 16.8935C1.40857 15.928 0.916626 14.7516 0.916626 13.3643C0.916626 12.1727 1.27413 11.1093 1.98913 10.1743C2.71024 9.23935 3.65135 8.64352 4.81246 8.38685C5.19746 6.98129 5.96135 5.84463 7.10413 4.97685C8.25301 4.10296 9.55163 3.66602 11 3.66602C12.7905 3.66602 14.3061 4.28935 15.5466 5.53602C16.7933 6.77657 17.4166 8.29213 17.4166 10.0827C18.4738 10.2049 19.3477 10.6632 20.0383 11.4577C20.735 12.2399 21.0833 13.1566 21.0833 14.2077C21.0833 15.3566 20.683 16.3313 19.8825 17.1318C19.0819 17.9324 18.1072 18.3327 16.9583 18.3327H11.9166C11.4155 18.3327 10.9847 18.1524 10.6241 17.7918C10.2636 17.4374 10.0833 17.0066 10.0833 16.4993V11.7785L8.61663 13.1993L7.33329 11.916L11 8.24935L14.6666 11.916L13.3833 13.1993L11.9166 11.7785V16.4993H16.9583C17.6 16.4993 18.1408 16.2763 18.5808 15.8302C19.0269 15.3902 19.25 14.8493 19.25 14.2077C19.25 13.566 19.0269 13.0252 18.5808 12.5852C18.1408 12.1391 17.6 11.916 16.9583 11.916H15.5833V10.0827C15.5833 8.81768 15.1372 7.73602 14.245 6.83768C13.3527 5.94546 12.2711 5.49935 11 5.49935C9.73496 5.49935 8.65329 5.94546 7.75496 6.83768C6.86274 7.73602 6.41663 8.81768 6.41663 10.0827H5.95829C5.07218 10.0827 4.31746 10.3974 3.69413 11.0268C3.06468 11.6502 2.74996 12.4049 2.74996 13.291C2.74996 14.1771 3.06468 14.941 3.69413 15.5827C4.31746 16.1938 5.07218 16.4993 5.95829 16.4993H8.24996V18.3327" fill="#6B7280" />
                                            </svg>
                                        </div>
                                        {namePath ? (
                                            <h1 className='text-gray-500 text-[12px] text-center'>{namePath}</h1>
                                        ) : (
                                            <>
                                                <h1 className='text-red-primer text-[12px] font-medium text-center'>Pilih File</h1>
                                                <div className="text-gray-500 text-[8px] text-center">pdf maks 2 MB</div>
                                            </>
                                        )}
                                    </div>
                                    <input type='file' id='upload-file' accept=".pdf" onChange={(e) => handleChangeFile(e)} className='rounded-[12px] outline-none border border-[#E3E8F1] w-foll px-[20px] py-[15px] hidden' required />
                                </label>
                            </div>
                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => { setShowModalAdd(!showModalAdd); resetForm() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={postData} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Tambah</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <ModalDelete
                    activeModal={showModalDelete}
                    buttonClose={() => setShowModalDelete(!showModalDelete)}
                    submitButton={deleteData}
                />
            </div>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Pengajuan Inovasi'} LinkBack={'/laporan-kinerja'} />
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>

                    <div className='flex lg:flex-row items-center justify-start gap-3'>
                        <button onClick={() => setShowModalAdd(true)} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                            <AiOutlinePlus className='text-xl text-white' />
                            <h1 className='text-dark-9 text-sm font-[500]'>Tambah Inovasi</h1>
                        </button>
                    </div>
                    <div className='overflow-auto'>
                        <table className='w-full space-y-[10px] text-dark-5 pl-3'>
                            <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500]'>Nama Inovasi</h1>
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500]'>No SK Inovasi</h1>
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500]'>Deskripsi</h1>
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px] justify-center'>
                                    <h1 className='text-dark-5 text-[11px] font-[500]'>Status</h1>
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px] justify-center'>
                                    <h1 className='text-dark-5 text-[11px] font-[500]'>Konversi Angka Kredit</h1>
                                </div>
                                <div className='flex items-center justify-center gap-[6px] w-full'>
                                    <h1 className='text-dark-5 text-[11px] font-[500]'>Aksi</h1>
                                </div>
                            </div>
                            {data && data.map((item, idx) => (
                                <div key={idx} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='min-w-[250px] max-w-[250px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item?.name ? item?.name : '-'}</h1>
                                    </div>
                                    <div className='min-w-[250px] max-w-[250px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item?.nomor_sk ? item?.nomor_sk : '-'}</h1>
                                    </div>
                                    <div className='min-w-[250px] max-w-[250px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item?.description ? item?.description : '-'}</h1>
                                    </div>
                                    <div className='flex items-center justify-center min-w-[150px] max-w-[150px]'>
                                        <h1 className={`text-[11px] font-[500] truncate text-center p-2 rounded w-fit ${item?.isApproved === true ? 'text-[#7EFFB2]' : 'text-red-thick'}`}>{item?.isApproved === true ? "Sudah diverifikasi" : "Belum diverifikasi"}</h1>
                                    </div>
                                    <div className='flex items-center justify-center min-w-[150px] max-w-[150px]'>
                                        <h1 className={`text-[11px] font-[500] truncate text-center p-2 rounded w-fit ${item?.isClaimed === true ? 'text-[#7EFFB2]' : 'text-red-thick'}`}>{item?.isClaimed === true ? "Sudah dikonversi" : "Belum dikonversi"}</h1>
                                    </div>
                                    <div className='flex justify-center items-center w-full gap-3'>
                                        <a href={imageHandle(item?.file)} download={item?.file?.split('/').pop()} target='_blank' className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center' rel="noreferrer">
                                            <AiOutlineDownload className='text-sm text-blue-thick' />
                                        </a>
                                        <button
                                            onClick={() => deleteDataModal(item?.id)}
                                            className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                            <CgTrashEmpty className='text-[#003049]' />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </table>
                    </div>
                    <div className='px-[14px] py-[8px]'>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            lengthData={totalData}
                            limitData={limit}
                            onPageChange={handlePageChange}
                            onPrevChange={handlePrevChange}
                            onNextChange={handleNextChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PengajuanInovasi
