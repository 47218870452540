import React from 'react'
import { Logo, Logo2, LogoOmbudsman } from '../assets'

const ExportPAKKum = () => {
    return (
        <div className='font-export text-sm'>
            <div className='text-center w-full'>
                <img src={LogoOmbudsman} className='h-16 w-auto mx-auto mb-5' />
                <p className='font-bold'>PENETAPAN ANGKA KREDIT KUMULATIF</p>
                <p className='font-bold'>TAHUN 2024</p>
                <p className='font-normal my-3'>NOMOR: B/181/KP.08.04/II/2024</p>
                <p className='font-bold mb-3 text-end'>Periode: Januari s.d. Desember</p>
            </div>
            <div className='flex justify-center w-full'>
                <table className='table-auto border-collapse border border-black mx-3 w-full' id="table">
                    <thead>
                        <tr>
                            <td colSpan={4} className='font-bold border border-black text-center'>ASISTEN YANG DINILAI</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='border border-black p-1 text-center'>1</td>
                            <td className='border border-black p-1'>Nama</td>
                            <td className='border border-black p-1 text-center'>:</td>
                            <td className='border border-black p-1'>Aat Sugihartati, SH.</td>
                        </tr>
                        <tr>
                            <td className='border border-black p-1 text-center'>2</td>
                            <td className='border border-black p-1'>NIO</td>
                            <td className='border border-black p-1 text-center'>:</td>
                            <td className='border border-black p-1'>197806182012114001</td>
                        </tr>
                        <tr>
                            <td className='border border-black p-1 text-center'>3</td>
                            <td className='border border-black p-1'>Pangkat</td>
                            <td className='border border-black p-1 text-center'>:</td>
                            <td className='border border-black p-1'>Asisten Muda I</td>
                        </tr>
                        <tr>
                            <td className='border border-black p-1 text-center'>4</td>
                            <td className='border border-black p-1'>Jenjang Jabatan</td>
                            <td className='border border-black p-1 text-center'>:</td>
                            <td className='border border-black p-1'>Asisten Muda</td>
                        </tr>
                        <tr>
                            <td className='border border-black p-1 text-center'>5</td>
                            <td className='border border-black p-1'>Unit kerja</td>
                            <td className='border border-black p-1 text-center'>:</td>
                            <td className='border border-black p-1'>OMBUDSMAN RI PUSAT</td>
                        </tr>
                    </tbody>
                </table>
            </div >
            <p className='font-bold text-center mt-5'>KONVERSI ANGKA KREDIT</p>
            <div className='flex justify-center w-full'>
                <table className='table-auto border-collapse border border-black mx-3 w-full' id="table">
                    <thead>
                        <tr>
                            <td colSpan={4} className='border border-black text-center'>Hasil Penilai Kinerja</td>
                            <td rowSpan={2} className='border border-black text-center'>Angka kredit minimal yang harus dicapai setiap tahun</td>
                            <td rowSpan={2} className='border border-black text-center'>Angka kredit yang didapat</td>
                        </tr>
                        <tr>
                            <td className='border border-black p-1 text-center'>TAHUN</td>
                            <td className='border border-black p-1 text-center'>ANGKA</td>
                            <td className='border border-black p-1 text-center'>SEBUTAN</td>
                            <td className='border border-black p-1 text-center'>PROSENTASE</td>
                        </tr>
                        <tr>
                            <td className='border border-black p-1 text-center bg-gray-300'>1</td>
                            <td className='border border-black p-1 text-center bg-gray-300'>2</td>
                            <td className='border border-black p-1 text-center bg-gray-300'>3</td>
                            <td className='border border-black p-1 text-center bg-gray-300'>4</td>
                            <td className='border border-black p-1 text-center bg-gray-300'>5</td>
                            <td className='border border-black p-1 text-center bg-gray-300'>6</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='border border-black p-1 text-center'>AK Kumulatif 2023</td>
                            <td className='border border-black p-1 text-center'>94,36</td>
                            <td className='border border-black p-1 text-center'>Baik</td>
                            <td className='border border-black p-1 text-center'>125%</td>
                            <td className='border border-black p-1 text-center'>25,00</td>
                            <td className='border border-black p-1 text-center'>31,25</td>
                        </tr>
                        <tr>
                            <td className='border border-black p-1 text-center'>Ak 2024</td>
                            <td className='border border-black p-1 text-center'>94,36</td>
                            <td className='border border-black p-1 text-center'>Baik</td>
                            <td className='border border-black p-1 text-center'>125%</td>
                            <td className='border border-black p-1 text-center'>25,00</td>
                            <td className='border border-black p-1 text-center'>31,25</td>
                        </tr>
                        <tr>
                            <td colSpan={5} className='border border-black p-2'>Angka Kredit Pendidikan (S2 / S3)</td>
                            <td className='border border-black p-2 text-center'>50</td>
                        </tr>
                        <tr>
                            <td colSpan={5} className='font-bold border border-black p-2'>Total Angka Kredit yang diperoleh</td>
                            <td className='font-bold border border-black p-2 text-center'>81,25</td>
                        </tr>
                        <tr>
                            <td colSpan={6} className='border border-black p-2'>
                                Rekomendasi:<br />
                                Dapat dipertimbangkan untuk naik pangkat
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='flex justify-end mt-5'>
                <div className=''>
                    <p>Ditetapkan di Jakarta</p>
                    <p>Pada tanggal 01 Februari 2024</p>
                    <p>Ketua Tim Penilai</p>
                    <p className='h-24 grid place-content-center'>#</p>
                    <p>Jemsly Hutabarat</p>
                </div>
            </div>
            <div className='flex justify-start mt-5'>
                <div className=''>
                    <p>Tembusan disampaikan kepada :</p>
                    <ul className='list-decimal list-inside px-1'>
                        <li>Pejabat Penilai</li>
                        <li>Asisten yang bersangkutan</li>
                    </ul>
                </div>
            </div>
        </div >
    )
}

export default ExportPAKKum
