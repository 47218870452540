import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { IoMdArrowDropdown } from 'react-icons/io'
import { RxCross2 } from 'react-icons/rx'
import { useNavigate } from 'react-router-dom'
import Api from '../../../../Api'
import { IconDownload } from '../../../../assets'
import IconPrimaryButton from '../../../../components/Button/IconPrimaryButton'
import PrimaryButton from '../../../../components/Button/PrimaryButton'
import imageHandle from '../../../../utils/imageHandle'
import languages from '../../../../utils/languages'

function Pegawai() {
    const navigate = useNavigate()
    const [namepath, setNamePath] = useState('')
    const [file, setFile] = useState('')
    const [typeTemplate, setTypeTemplate] = useState('')

    const handleChangeFile = (e) => {
        const maxSize = 10 * 1024 * 1024
        const allowedExtensions = ['xlsx', 'xls'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize) {
            toast.error('file tidak boleh lebih dari 10 MB')
            setFile(null)
        } else if (!allowedExtensions.includes(fileExtension)) {
            toast.error('file harus berformat excel')
        } else {
            setNamePath(e.target.files[0].name)
            setFile(e.target.files[0])
        }
    }

    const ImportData = async () => {
        if (file !== "" && typeTemplate !== "") {
            const data = {
                file: file
            }
            try {
                setIsLoading(true)
                if (typeTemplate === 'Job Management') {
                    await Api.UploadJobManagement(localStorage.getItem('hris-token'), data).then((response) => {
                        toast.success('Import Data Job Berhasil')
                        navigate('/job-management')
                        setIsLoading(false)
                    }).catch((e) => {
                        console.log(e.response.data.message)
                        toast.error(e.response.data.message)
                        setFile("")
                        setIsLoading(false)
                    })
                } else if (typeTemplate === "Employee Data") {
                    await Api.UploadEmployee(localStorage.getItem('hris-token'), data).then((response) => {
                        toast.success('Import Data Pegawai Berhasil')
                        navigate('/employee')
                        setIsLoading(false)
                    }).catch((e) => {
                        console.log(e.response.data.message)
                        toast.error(e.response.data.message)
                        setFile("")
                        setIsLoading(false)
                    })
                } else if (typeTemplate === "Penempatan") {
                    await Api.UploadPenempatan(localStorage.getItem('hris-token'), data).then((response) => {
                        toast.success('Import Data Penempatan')
                        navigate('/subsidiary')
                        setIsLoading(false)
                    }).catch((e) => {
                        console.log(e.response.data.message)
                        toast.error(e.response.data.message)
                        setFile("")
                        setIsLoading(false)
                    })
                }
            } catch (error) {
                console.log(error)
            }
        } else {
            toast.error("Pilih jenis template & File wajib diisi!")
        }
    }

    const [template, setTemplate] = useState()
    const getTemplateJob = async () => {
        try {
            const response = await Api.GetTemplateJob(localStorage.getItem('hris-token'))
            setTemplate(response.data.results.path)
        } catch (error) {
            console.log(error)
        }
    }
    const getTemplateEmployee = async () => {
        try {
            const response = await Api.GetTemplateEmployee(localStorage.getItem('hris-token'))
            setTemplate(response.data.results.path)
        } catch (error) {
            console.log(error)
        }
    }
    const getTemplatePenempatan = async () => {
        try {
            const response = await Api.GetTemplatePenempatan(localStorage.getItem('hris-token'))
            setTemplate(response.data.results.path)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (typeTemplate === "Job Management") {
            getTemplateJob()
        } else if (typeTemplate === "Penempatan") {
            getTemplatePenempatan()
        } else if (typeTemplate === "Employee Data") {
            getTemplateEmployee()
        }
    }, [typeTemplate])

    const [isLoading, setIsLoading] = useState(false)
    if (isLoading) {
        return (
            <div className='bg-[#F8F9FB] min-h-screen content-center w-full text-center pt-52 flex-row justify-center items-center'>
                <div className="text-center space-y-2">
                    <div role="status">
                        <svg aria-hidden="true" className="inline w-16 h-16 text-gray-200 animate-spin dark:text-red-thick fill-blue-medium" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                    <p>Memuat Data...</p>
                </div>
            </div>
        )
    }
    return (
        <>
            <div className='space-y-[32px] bg-white px-[46px] py-[40px] rounded-[12px] border'>
                <div className='space-y-1'>
                    <h1 className='text-[#272B30] font-[600] text-[20px]'>{languages.language === 'id' ? "Import Data Pegawai" : "Import Job and Employee Data"}</h1>
                    <h1 className='text-dark-5 text-[14px]'>{languages.language === 'id' ? "Import file untuk memperbarui data pegawai" : "Import a file to update external user data"}</h1>
                </div>
                <div className='space-y-1'>
                    <h1 className='text-[#272B30] text-[16px] font-[600]'>{languages.language === 'id' ? "Langkah-langkah" : "Step :"}</h1>
                    <h1 className='text-[#464E5F] text-[14px]'>{languages.language === 'id' ? "Ikuti langkah-langkah berikut untuk melakukan import yang benar" : "Follow these steps to perform the correct import"}</h1>
                    <ol className='list-decimal text-[#464E5F] text-[14px] list-outside ml-3'>
                        <li>
                            {languages.language === 'id' ? "Pada bagian download template, pilih jenis template sesuai urutan pengisian yang benar, Pastikan Anda mengikuti urutan import dokumen yang benar, yaitu:" : "In the download template section, select the template type according to the correct filling order, Make sure you follow the correct document import sequence, namely:"}
                        </li>
                    </ol>
                    <ul className='list-disc text-[#464E5F] text-[14px] list-inside ml-3'>
                        <li>
                            Import Penempatan
                        </li>
                        <li>
                            {languages.language === 'id' ? "Import Manajemen Job" : "Import Job Management"}
                        </li>
                        <li>
                            {languages.language === 'id' ? "Import Data Pegawai" : "Import Employee Data"}
                        </li>
                    </ul>
                    <h1 className='text-[#464E5F] text-[14px]'>{languages.language === 'id' ? "Kemudian pilih" : "then click"} <span className='font-bold'>{languages.language === 'id' ? "Download Template" : "'Download Template'"}</span></h1>
                    <ol className='list-decimal text-[#464E5F] text-[14px] list-outside ml-3' start={2}>
                        <li>
                            {languages.language === 'id' ? "Isikan data sesuai template yang tersedia" : "Fill in data according to the available template"}
                        </li>
                        <li>
                            {languages.language === 'id' ? "pastikan data yang diisi sudah benar" : "Make sure the data  you fill is correct"}
                        </li>
                        <li>
                            {languages.language === 'id' ? "setelah data sudah diisi, Pilih File untuk di upload, kemudian pilih" : "After all the data is filled in,Choose File to upload, then click "} <span className='font-bold'>{languages.language === 'id' ? "Upload File" : "‘Upload File’"}</span>
                        </li>
                    </ol>
                </div>
                <div className='space-y-1'>
                    <h1 className='text-[#272B30] text-[16px] font-[600]'>{languages.language === 'id' ? "Download Template" : "Download Template :"}</h1>
                    <div className='flex items-center gap-[8px]'>
                        <div className='relative'>
                            <select onChange={(e) => setTypeTemplate(e.target.value)} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                <option selected value='' className='text-grey-medium font-normal '>{languages.language === 'id' ? "Pilih Jenis Template" : "Select template type"}</option>
                                <option className='text-grey-primer' value="Penempatan">Penempatan</option>
                                <option className='text-grey-primer' value="Job Management">{languages.language === 'id' ? "Manajemen Job" : "Job Management"}</option>
                                <option className='text-grey-primer' value="Employee Data">{languages.language === 'id' ? "Data Pegawai" : "Employee Data"}</option>
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                        </div>
                        {typeTemplate !== "" ?
                            <a href={imageHandle(template)} download={'template'}>
                                <IconPrimaryButton icon={IconDownload} text={'Download Template'} />
                            </a>
                            : null
                        }
                    </div>
                </div>
                <div className='space-y-1'>
                    <h1 className='text-[#272B30] text-[16px] font-[600]'>{languages.language === 'id' ? "Upload File" : "Upload File"} <span className='text-[#780000] ml-[4px]'>*</span></h1>
                    <h1 className='text-[#C1121F] text-[8px]'>*{languages.language === 'id' ? "Upload File maks 500kb" : "Upload File max 10 MB"}</h1>
                    <div className='flex items-center gap-[13px]'>
                        <label htmlFor='upload-file' className='cursor-pointer w-full'>
                            <div className='border shadow-sm rounded-lg relative flex flex-col text-center items-center px-[70px] py-[11px] w-1/2'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                    <path d="M5.95829 18.3327C4.56496 18.3327 3.37635 17.853 2.39246 16.8935C1.40857 15.928 0.916626 14.7516 0.916626 13.3643C0.916626 12.1727 1.27413 11.1093 1.98913 10.1743C2.71024 9.23935 3.65135 8.64352 4.81246 8.38685C5.19746 6.98129 5.96135 5.84463 7.10413 4.97685C8.25301 4.10296 9.55163 3.66602 11 3.66602C12.7905 3.66602 14.3061 4.28935 15.5466 5.53602C16.7933 6.77657 17.4166 8.29213 17.4166 10.0827C18.4738 10.2049 19.3477 10.6632 20.0383 11.4577C20.735 12.2399 21.0833 13.1566 21.0833 14.2077C21.0833 15.3566 20.683 16.3313 19.8825 17.1318C19.0819 17.9324 18.1072 18.3327 16.9583 18.3327H11.9166C11.4155 18.3327 10.9847 18.1524 10.6241 17.7918C10.2636 17.4374 10.0833 17.0066 10.0833 16.4993V11.7785L8.61663 13.1993L7.33329 11.916L11 8.24935L14.6666 11.916L13.3833 13.1993L11.9166 11.7785V16.4993H16.9583C17.6 16.4993 18.1408 16.2763 18.5808 15.8302C19.0269 15.3902 19.25 14.8493 19.25 14.2077C19.25 13.566 19.0269 13.0252 18.5808 12.5852C18.1408 12.1391 17.6 11.916 16.9583 11.916H15.5833V10.0827C15.5833 8.81768 15.1372 7.73602 14.245 6.83768C13.3527 5.94546 12.2711 5.49935 11 5.49935C9.73496 5.49935 8.65329 5.94546 7.75496 6.83768C6.86274 7.73602 6.41663 8.81768 6.41663 10.0827H5.95829C5.07218 10.0827 4.31746 10.3974 3.69413 11.0268C3.06468 11.6502 2.74996 12.4049 2.74996 13.291C2.74996 14.1771 3.06468 14.941 3.69413 15.5827C4.31746 16.1938 5.07218 16.4993 5.95829 16.4993H8.24996V18.3327" fill="#6B7280" />
                                </svg>
                                <h1 className='text-[#8E95A2] text-[12px] font-medium'>{languages.language === 'id' ? "Pilih file" : "Choose File"}</h1>
                            </div>
                            <input type='file' id='upload-file' accept=".xlsx, .xls" onChange={(e) => handleChangeFile(e)} className='rounded-[12px] outline-none border border-[#E3E8F1] w-foll px-[20px] py-[15px] hidden' required />
                        </label>
                        {/* <button className='flex bg-blue-primer items-center justify-center px-[8px] py-[7px] gap-[4px] rounded-lg text-white font-medium text-[14px]'>
                            <BiCloudUpload className='text-xl' />
                            <h1>{languages.language === 'id' ? "Upload File" : "Upload File"}</h1>
                        </button> */}
                    </div>
                    {(namepath && file) &&
                        <div className='py-[6px] px-[8px] bg-base-outline rounded flex gap-[8px] justify-between w-fit'>
                            <h1 className='text-[10px] text-dark-5 truncate'>{namepath}</h1>
                            <button onClick={() => setFile('')}> <RxCross2 className='text-sm' /></button>
                        </div>
                    }
                </div>

                <PrimaryButton text={'Upload File'} action={() => ImportData()} />
            </div>
        </>
    )
}

export default Pegawai