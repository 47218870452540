import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { AiOutlineEye } from 'react-icons/ai'
import { MdImportExport, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { useLocation } from 'react-router-dom'
import Api from '../../../../../Api'
import { LogoH } from '../../../../../assets'
import { Modal, Navbar } from '../../../../../components'

const DetailSubsidiary = () => {

    const [tabs, setTabs] = useState('Job Management')
    const [showModal, setShowModal] = useState(false)
    const [totalCount, setTotalCount] = useState('')

    const params = useLocation()

    const getStatistic = async () => {
        try {
            const resTotalEmployee = await Api.GetTotalEmployee(localStorage.getItem('hris-token'), params.state.subsidiaryId)
            setTotalCount(resTotalEmployee.data.totalCount)
            console.log(resTotalEmployee)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getStatistic()
    })

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <Modal
                activeModal={showModal}
                title={'Detail Attendance'}
                buttonClose={() => setShowModal(!showModal)}
                width={'715px'}
                content={
                    <div>
                        <div className='bg-[#F8F8F8] rounded-[15px] p-[22px]'>
                            <h1 className='text-dark-4 text-[13px] font-semibold'>Attendance Information</h1>
                            <div className='grid grid-cols-12 gap-5 px-4 py-4'>
                                <div className='flex flex-col gap-3 col-span-3'>
                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Employee Nmae</h1>
                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Job Title</h1>
                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Company Location</h1>
                                </div>
                                <div className='flex flex-col gap-3 col-span-1'>
                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                </div>
                                <div className='flex flex-col gap-3 col-span-3'>
                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>Muhammad Faizal</h1>
                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>Front End</h1>
                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>Arca</h1>
                                </div>
                                <div className='flex flex-col gap-3 col-span-2'>
                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Duration</h1>
                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Wokrshift</h1>
                                </div>
                                <div className='flex flex-col gap-3 col-span-1'>
                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                </div>

                                <div className='flex flex-col gap-3 col-span-2'>
                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>8 Hours</h1>
                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>General</h1>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-12 mt-[30px]'>
                            <div className='col-span-6 border-r pr-[40px] py-[20px] space-y-2'>
                                <div className='flex items-center gap-[40px]'>
                                    <h1 className='text-dark-4 text-sm font-semibold'>Clock In</h1>
                                    <h1 className='text-dark-4 text-sm font-semibold'>08:00</h1>
                                </div>
                                <div className='flex items-center gap-[18px]'>
                                    <img src={LogoH} alt='Logo Humanusia' className='w-[77px] h-[77px] bg-[#F8F8F8] border rounded-[10px]' />
                                    <img src={LogoH} alt='Logo Humanusia' className='w-full h-[77px] bg-[#F8F8F8] border rounded-[10px] object-contain' />
                                </div>
                            </div>
                            <div className='col-span-6 pl-[40px] py-[20px] space-y-2'>
                                <div className='flex items-center gap-[40px]'>
                                    <h1 className='text-dark-4 text-sm font-semibold'>Clock Out</h1>
                                    <h1 className='text-dark-4 text-sm font-semibold'>17:00</h1>
                                </div>
                                <div className='flex items-center gap-[18px]'>
                                    <img src={LogoH} alt='Logo Humanusia' className='w-[77px] h-[77px] bg-[#F8F8F8] border rounded-[10px]' />
                                    <img src={LogoH} alt='Logo Humanusia' className='w-full h-[77px] bg-[#F8F8F8] border rounded-[10px] object-contain' />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={'Detail Subsidiary'} LinkBack={'/subsidiary'} />
                    <div className='space-y-8'>
                        <div className='shadow-sm border-b-2 py-[10px]'>
                            <h1 className='text-dark-3 text-[20px] font-semibold'>PT Humanusia.id</h1>
                        </div>
                        <div className='grid grid-cols-12 gap-8'>
                            <div className='col-span-3 bg-white border shadow-sm rounded-[12px] px-[22px] py-[26px] space-y-2'>
                                <h1 className='text-dark-5 text-[13px] font-semibold uppercase'>Total Employees</h1>
                                <h1 className='text-[#C1121F] text-[24px] font-bold uppercase'>{totalCount ?? '0'}</h1>
                            </div>
                            <div className='col-span-3 bg-white border shadow-sm rounded-[12px] px-[22px] py-[26px] space-y-2'>
                                <h1 className='text-dark-5 text-[13px] font-semibold uppercase'>Applied Employees</h1>
                                <h1 className='text-[#C1121F] text-[24px] font-bold uppercase'>200</h1>
                            </div>
                            <div className='col-span-3 bg-white border shadow-sm rounded-[12px] px-[22px] py-[26px] space-y-2'>
                                <h1 className='text-dark-5 text-[13px] font-semibold uppercase'>New Employees</h1>
                                <h1 className='text-[#C1121F] text-[24px] font-bold uppercase'>70</h1>
                            </div>
                            <div className='col-span-3 bg-white border shadow-sm rounded-[12px] px-[22px] py-[26px] space-y-2'>
                                <h1 className='text-dark-5 text-[13px] font-semibold uppercase'>Resigned Employees</h1>
                                <h1 className='text-[#C1121F] text-[24px] font-bold uppercase'>20</h1>
                            </div>
                        </div>
                        <div className='space-y-[24px] bg-white border px-[16px] py-[30px] rounded-[12px]'>
                            <div className='border-b-2 flex items-center '>
                                <button onClick={() => setTabs('Job Management')} className={`${tabs === 'Job Management' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2' : 'text-[#D1D1D1]'} px-[70px] py-[10px]`}>Job Management</button>
                                <button onClick={() => setTabs('Organization')} className={`${tabs === 'Organization' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2' : 'text-[#D1D1D1]'} px-[70px] py-[10px]`}>Organization Management</button>
                                <button onClick={() => setTabs('Attendance')} className={`${tabs === 'Attendance' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2' : 'text-[#D1D1D1]'} px-[70px] py-[10px]`}>Attendance</button>
                            </div>
                            <div className='bg-white border shadow-sm rounded-[12px] px-[22px] py-[36px]'>
                                <div className='flex flex-col lg:flex-row items-center justify-between mb-[36px]'>
                                    <div className='w-full lg:w-auto'>
                                        <h1 className='text-dark-3 text-[16px] font-[500]'>
                                            {
                                                tabs === 'Job Management' ?
                                                    'Job Management'
                                                    : tabs === 'Attendance' ?
                                                        'Attendance Record'
                                                        : tabs === 'Organization' ?
                                                            'Structure Organization'
                                                            : null
                                            }
                                        </h1>
                                        <h1 className='text-[#A8A8A8] text-xs font-[500]'>
                                            {
                                                tabs === 'Job Management' ?
                                                    'list of job Management '
                                                    : tabs === 'Attendance' ?
                                                        'Record of Attendance Employee'
                                                        : tabs === 'Organization' ?
                                                            'Organizational structure of the company'
                                                            : null
                                            }
                                        </h1>
                                    </div>
                                </div>
                                {tabs === 'Job Management' ?
                                    <div>
                                        <div className='overflow-auto scrollbar-hide'>
                                            <table className='w-full space-y-[10px]'>
                                                <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                                    <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                                        <h1 className='text-dark-5 text-xs font-[500]'>Job Grade</h1>
                                                        <MdImportExport className='text-dark-5 text-xs' />
                                                    </div>
                                                    <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                                        <h1 className='text-dark-5 text-xs font-[500]'>Job Level</h1>
                                                        <MdImportExport className='text-dark-5 text-xs' />
                                                    </div>
                                                    <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                                        <h1 className='text-dark-5 text-xs font-[500]'>Job Position</h1>
                                                        <MdImportExport className='text-dark-5 text-xs' />
                                                    </div>
                                                </div>
                                                <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                                    <div className='min-w-[300px] max-w-[300px]'>
                                                        <h1 className='text-dark-5 text-xs font-[500] truncate'>Muhammad Faizal</h1>
                                                    </div>
                                                    <div className='min-w-[300px] max-w-[300px]'>
                                                        <h1 className='text-dark-5 text-xs font-[500] truncate'>Test</h1>
                                                    </div>
                                                    <div className='min-w-[300px] max-w-[300px]'>
                                                        <h1 className='text-dark-5 text-xs font-[500] truncate'>Overtime</h1>
                                                    </div>
                                                </div>
                                            </table>
                                        </div>
                                        <div className='flex items-center justify-between mt-[30px]'>
                                            <h1 className='text-[#A098AE] text-[10px] '>Showing 1-5 from 100 data</h1>
                                            <div className='flex items-center gap-[8px]'>
                                                <MdKeyboardArrowLeft className='text-[#A098AE]' />
                                                <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                                    <h1 className='text-[#780000] text-[10px]'>1</h1>
                                                </div>
                                                <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] flex items-center justify-center'>
                                                    <h1 className='text-white text-[10px]'>2</h1>
                                                </div>
                                                <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                                    <h1 className='text-[#780000] text-[10px]'>3</h1>
                                                </div>
                                                <MdKeyboardArrowRight className='text-[#A098AE]' />
                                            </div>
                                        </div>
                                    </div>
                                    : tabs === 'Organization' ?
                                        <div>
                                            Halaman Organization
                                        </div>
                                        : tabs === 'Attendance' ?
                                            <div>
                                                <div className='overflow-auto scrollbar-hide'>
                                                    <table className='w-full space-y-[10px]'>
                                                        <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                                            <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                                                <h1 className='text-dark-5 text-xs font-[500]'>Employee Name</h1>
                                                                <MdImportExport className='text-dark-5 text-xs' />
                                                            </div>
                                                            <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                                                <h1 className='text-dark-5 text-xs font-[500]'>Date</h1>
                                                                <MdImportExport className='text-dark-5 text-xs' />
                                                            </div>
                                                            <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                                                <h1 className='text-dark-5 text-xs font-[500]'>Clock In</h1>
                                                                <MdImportExport className='text-dark-5 text-xs' />
                                                            </div>
                                                            <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                                                <h1 className='text-dark-5 text-xs font-[500]'>Location Clock In</h1>
                                                                <MdImportExport className='text-dark-5 text-xs' />
                                                            </div>
                                                            <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                                                <h1 className='text-dark-5 text-xs font-[500]'>Clock Out</h1>
                                                                <MdImportExport className='text-dark-5 text-xs' />
                                                            </div>
                                                            <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                                                <h1 className='text-dark-5 text-xs font-[500]'>Location Clock Out</h1>
                                                                <MdImportExport className='text-dark-5 text-xs' />
                                                            </div>
                                                            <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                                                <h1 className='text-dark-5 text-xs font-[500]'>Duration</h1>
                                                            </div>
                                                            <div className='flex items-center gap-[15px] w-full justify-center'>
                                                                <h1 className='text-dark-5 text-xs font-[500]'>Action</h1>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                                            <div className='min-w-[300px] max-w-[300px]'>
                                                                <h1 className='text-dark-5 text-xs font-[500] truncate'>Muhammad Faizal</h1>
                                                            </div>
                                                            <div className='min-w-[150px] max-w-[150px]'>
                                                                <h1 className='text-dark-5 text-xs font-[500] truncate'>{moment().format('DD/MM/YYYY')}</h1>
                                                            </div>
                                                            <div className='min-w-[150px] max-w-[150px]'>
                                                                <h1 className='text-dark-5 text-xs font-[500] truncate'>08:00</h1>
                                                            </div>
                                                            <div className='min-w-[200px] max-w-[200px]'>
                                                                <a href='http://www.devapp.humanusia.id' target={'_blank'} rel="noreferrer" className='text-dark-5 text-xs font-[500] truncate hover:text-[#B70100] underline underline-offset-2'>See Location</a>
                                                            </div>
                                                            <div className='min-w-[150px] max-w-[150px]'>
                                                                <h1 className='text-dark-5 text-xs font-[500] truncate'>17:00</h1>
                                                            </div>
                                                            <div className='min-w-[200px] max-w-[200px]'>
                                                                <a href='http://www.devapp.humanusia.id' target={'_blank'} rel="noreferrer" className='text-dark-5 text-xs font-[500] truncate hover:text-[#B70100] underline underline-offset-2'>See Location</a>
                                                            </div>
                                                            <div className='min-w-[100px] max-w-[100px]'>
                                                                <h1 className='text-dark-5 text-xs font-[500] truncate'>8 Hours</h1>
                                                            </div>
                                                            <div className='w-full flex items-center justify-center'>
                                                                <button onClick={() => setShowModal(!showModal)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                                    <AiOutlineEye className='text-[#003049]' />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </table>
                                                </div>
                                                <div className='flex items-center justify-between mt-[30px]'>
                                                    <h1 className='text-[#A098AE] text-[10px] '>Showing 1-5 from 100 data</h1>
                                                    <div className='flex items-center gap-[8px]'>
                                                        <MdKeyboardArrowLeft className='text-[#A098AE]' />
                                                        <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                                            <h1 className='text-[#780000] text-[10px]'>1</h1>
                                                        </div>
                                                        <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] flex items-center justify-center'>
                                                            <h1 className='text-white text-[10px]'>2</h1>
                                                        </div>
                                                        <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                                            <h1 className='text-[#780000] text-[10px]'>3</h1>
                                                        </div>
                                                        <MdKeyboardArrowRight className='text-[#A098AE]' />
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailSubsidiary