import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlineDownload, AiOutlinePlus } from 'react-icons/ai'
import { BiLink } from 'react-icons/bi'
import { CgTrashEmpty } from 'react-icons/cg'
import { IoMdArrowDropdown } from 'react-icons/io'
import Api from '../../../../Api'
import { Modal, ModalDelete, Navbar } from '../../../../components'
import imageHandle from '../../../../utils/imageHandle'
import languages from '../../../../utils/languages'

const Helpdesk = () => {
    const [refresh, setRefresh] = useState(false)
    const [showModalAdd, setShowModalAdd] = useState(false)
    const [data, setData] = useState('')

    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [type, setType] = useState("")
    const [link, setLink] = useState("")
    const [file, setFile] = useState("")
    const [namePath, setNamePath] = useState('')
    const [id, setId] = useState('')

    const handleChangeFile = (e) => {
        const maxSize = 2 * 1024 * 1024
        const allowedExtensions = ['pdf'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize) {
            toast.error('gambar tidak boleh lebih dari 2 MB')
            setFile(null)
        } else if (!allowedExtensions.includes(fileExtension)) {
            toast.error('file harus berformat pdf')
        } else {
            setNamePath(e.target.files[0].name)
            setFile(e.target.files[0])
        }
    }

    const resetForm = () => {
        setTitle("")
        setDescription("")
        setType("")
        setLink("")
        setFile("")
    }
    const getData = async () => {
        try {
            const response = await Api.Helpdesk(localStorage.getItem('hris-token'))
            console.log(response?.data?.results?.data)
            setData(response?.data?.results?.data)
        } catch (error) {
            console.log(error)
        }
    }

    const postData = async () => {
        try {
            const data = {
                title: title,
                description: description,
                type: type,
                link: link,
                file: file
            }
            const formData = new FormData();
            formData.append('title', data.title);
            formData.append('description', data.description);
            formData.append('type', data.type);
            if (type === "FILE") {
                // Handling file
                if (data.file) {
                    formData.append('file', data.file);
                }
            } else {
                formData.append('link', data.link);
            }

            for (const pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }
            await Api.PostHelpdesk(localStorage.getItem('hris-token'), formData).then((response) => {
                setRefresh(true)
                resetForm()
                setShowModalAdd(false)
                toast.success("Berhasil menambahkan data!")
            }).catch((error) => {
                console.log(error)
                if (error?.response?.data?.errors?.error?.details) {
                    const e = error.response.data.errors.error.details
                    for (let i = 0; i < e.length; i++) {
                        toast.error(e[i].message)
                    }
                } else if (error?.response?.data?.errors) {
                    toast.error(error.response.data.errors)
                } else {
                    toast.error("Gagal Mengubah Data!")
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    const [showModalDelete, setShowModalDelete] = useState(false)

    const deleteModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setId(id)
        setRefresh(true)
    }

    const deleteFile = async () => {
        try {
            await Api.DeleteHelpdesk(localStorage.getItem('hris-token'), id).then((response) => {
                setRefresh(true)
                setShowModalDelete(false)
                toast.success('Berhasil menghapus data!')
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
            toast.error('Data Gagal dihapus')
        }
    }

    useEffect(() => {
        getData()
        setRefresh(false)
    }, [refresh])
    return (
        <>
            <div className='bg-[#F8F9FB] min-h-screen'>
                <div className='relative'>
                    <Modal
                        activeModal={showModalAdd}
                        title={'Tambah'}
                        buttonClose={() => {
                            setShowModalAdd(!showModalAdd);
                            resetForm()
                        }}
                        width={'550px'}
                        content={
                            <div className='space-y-3'>
                                <div>
                                    <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Judul <span className='text-red-primer'>*</span></h1>
                                    <input type="text" onChange={(e) => setTitle(e.target.value)} value={title} className='py-[10px] px-[16px] border rounded-md text-gray-500 text-xs w-full' placeholder='Masukkan judul...' />
                                </div>
                                <div>
                                    <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Deskripsi <span className='text-red-primer'>*</span></h1>
                                    <textarea rows={3} type="text" onChange={(e) => setDescription(e.target.value)} value={description} className='py-[10px] px-[16px] border rounded-md text-gray-500 text-xs w-full' placeholder='Tuliskan deskripsi...' />
                                </div>
                                <div>
                                    <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Tipe <span className='text-red-primer'>*</span></h1>
                                    <div className='relative'>
                                        <select onChange={(e) => setType(e.target.value)} value={type} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                            <option selected disabled value={''}>Pilih Tipe...</option>
                                            <option value="FILE">FILE</option>
                                            <option value="LINK">LINK</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-2 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                                {type === "LINK" ?
                                    <div>
                                        <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Link <span className='text-red-primer'>*</span></h1>
                                        <input type="text" onChange={(e) => setLink(e.target.value)} value={link} className='py-[10px] px-[16px] border rounded-md text-gray-500 text-xs w-full' placeholder='Masukkan link...' />
                                    </div>
                                    : type === "FILE" ?
                                        <div>
                                            <h1 className="text-neutral-500 text-[11px] font-medium mb-1">File <span className='text-red-primer'>*</span></h1>
                                            <label htmlFor='upload-file' className='cursor-pointer'>
                                                <div className='border shadow-sm rounded-lg relative text-center justify-center px-[70px] py-[11px] w-full'>
                                                    <div className='flex items-center justify-center'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                            <path d="M5.95829 18.3327C4.56496 18.3327 3.37635 17.853 2.39246 16.8935C1.40857 15.928 0.916626 14.7516 0.916626 13.3643C0.916626 12.1727 1.27413 11.1093 1.98913 10.1743C2.71024 9.23935 3.65135 8.64352 4.81246 8.38685C5.19746 6.98129 5.96135 5.84463 7.10413 4.97685C8.25301 4.10296 9.55163 3.66602 11 3.66602C12.7905 3.66602 14.3061 4.28935 15.5466 5.53602C16.7933 6.77657 17.4166 8.29213 17.4166 10.0827C18.4738 10.2049 19.3477 10.6632 20.0383 11.4577C20.735 12.2399 21.0833 13.1566 21.0833 14.2077C21.0833 15.3566 20.683 16.3313 19.8825 17.1318C19.0819 17.9324 18.1072 18.3327 16.9583 18.3327H11.9166C11.4155 18.3327 10.9847 18.1524 10.6241 17.7918C10.2636 17.4374 10.0833 17.0066 10.0833 16.4993V11.7785L8.61663 13.1993L7.33329 11.916L11 8.24935L14.6666 11.916L13.3833 13.1993L11.9166 11.7785V16.4993H16.9583C17.6 16.4993 18.1408 16.2763 18.5808 15.8302C19.0269 15.3902 19.25 14.8493 19.25 14.2077C19.25 13.566 19.0269 13.0252 18.5808 12.5852C18.1408 12.1391 17.6 11.916 16.9583 11.916H15.5833V10.0827C15.5833 8.81768 15.1372 7.73602 14.245 6.83768C13.3527 5.94546 12.2711 5.49935 11 5.49935C9.73496 5.49935 8.65329 5.94546 7.75496 6.83768C6.86274 7.73602 6.41663 8.81768 6.41663 10.0827H5.95829C5.07218 10.0827 4.31746 10.3974 3.69413 11.0268C3.06468 11.6502 2.74996 12.4049 2.74996 13.291C2.74996 14.1771 3.06468 14.941 3.69413 15.5827C4.31746 16.1938 5.07218 16.4993 5.95829 16.4993H8.24996V18.3327" fill="#6B7280" />
                                                        </svg>
                                                    </div>
                                                    {namePath ? (
                                                        <h1 className='text-gray-500 text-[12px] text-center'>{namePath}</h1>
                                                    ) : (
                                                        <>
                                                            <h1 className='text-red-primer text-[12px] font-medium text-center'>{languages.language === 'id' ? "Pilih File" : "Choose File"}</h1>
                                                            <div className="text-gray-500 text-[8px] text-center">pdf maks 2 MB</div>
                                                        </>
                                                    )}
                                                </div>
                                                <input type='file' id='upload-file' accept=".pdf" onChange={(e) => handleChangeFile(e)} className='rounded-[12px] outline-none border border-[#E3E8F1] w-foll px-[20px] py-[15px] hidden' required />
                                            </label>
                                        </div>
                                        : null
                                }
                                <div className='relative pb-[37px]'>
                                    <div className="flex gap-[10px] absolute right-0">
                                        <button onClick={() => { setShowModalAdd(!showModalAdd) }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                            <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                                        </button>
                                        <button
                                            onClick={postData}
                                            className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                            <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    />
                    <ModalDelete
                        activeModal={showModalDelete}
                        buttonClose={() => setShowModalDelete(!showModalDelete)}
                        submitButton={deleteFile}
                    />
                </div>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar="Pengaturan Bantuan" LinkBack={'/dashboard'} />
                    <div className='bg-white border shadow-sm rounded-[12px] px-[22px] py-[36px] space-y-5'>
                        <div className='flex flex-wrap items-center justify-between gap-3'>
                            <div className='w-full lg:w-auto'>
                                <h1 className='text-dark-3 text-[16px] font-[500]'>Pengaturan Bantuan</h1>
                                <h1 className='text-[#A8A8A8] text-xs font-[500]'>Daftar Bantuan</h1>
                            </div>
                            <button onClick={() => setShowModalAdd(true)} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                <AiOutlinePlus className='text-xl text-white' />
                                <h1 className='text-dark-9 text-sm font-[500]'>Tambah</h1>
                            </button>
                        </div>
                        <div className='overflow-auto'>
                            <table className='w-full space-y-[10px]'>
                                <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center gap-[15px] min-w-[50px] max-w-[50px]'>
                                        <h1 className='text-dark-5 text-xs font-[600]'>No</h1>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-dark-5 text-xs font-[600]'>Judul</h1>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[250px] max-w-[250px]'>
                                        <h1 className='text-dark-5 text-xs font-[600]'>Deskripsi</h1>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                        <h1 className='text-dark-5 text-xs font-[600]'>Tipe</h1>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-dark-5 text-xs font-[600]'>Link</h1>
                                    </div>
                                    <div className='flex items-center justify-center gap-[15px] w-full'>
                                        <h1 className='text-dark-5 text-xs font-[600]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                                    </div>
                                </div>
                                {data.length === 0 ?
                                    <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[50px] max-w-[50px]'>-</div>
                                        <div className='min-w-[200px] max-w-[200px]'>-</div>
                                        <div className='min-w-[250px] max-w-[250px]'>-</div>
                                        <div className='min-w-[100px] max-w-[100px]'>-</div>
                                        <div className='min-w-[200px] max-w-[200px]'>-</div>
                                        <div className='w-full flex items-center justify-center gap-[5px]'>-</div>

                                    </div>
                                    :
                                    Object.values(data).map((val, index) => {
                                        return (
                                            <div key={index} className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                                <div className='min-w-[50px] max-w-[50px]'>
                                                    <h1 className='text-xs line-clamp-1'>{index + 1}</h1>
                                                </div>
                                                <div className='min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-xs'>{val?.title}</h1>
                                                </div>
                                                <div className='min-w-[250px] max-w-[250px]'>
                                                    <h1 className='text-xs'>{val?.description}</h1>
                                                </div>
                                                <div className='min-w-[100px] max-w-[100px]'>
                                                    <h1 className='text-xs line-clamp-1'>{val?.type}</h1>
                                                </div>
                                                <div className='min-w-[200px] max-w-[200px]'>
                                                    <a href={val?.link} target='_blank' className='text-xs line-clamp-1' rel="noreferrer">{val?.link}</a>
                                                </div>
                                                <div className='w-full flex items-center justify-center gap-[5px]'>
                                                    {val?.type === "FILE" ?
                                                        <a href={imageHandle(val.file)} download={val?.file?.split(/(\\|\/)/g).pop()} target='_blank' className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center' rel="noreferrer">
                                                            <AiOutlineDownload className='text-sm text-blue-thick' />
                                                        </a>
                                                        : <a href={val.link} target='_blank' disabled className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center' rel="noreferrer">
                                                            <BiLink className='text-sm text-blue-thick' />
                                                        </a>
                                                    }
                                                    <button onClick={() => deleteModal(val.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <CgTrashEmpty className='text-[#003049]' />
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Helpdesk