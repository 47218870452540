import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { AiOutlineEye } from 'react-icons/ai'
import { BsImage } from 'react-icons/bs'
import { IoMdArrowDropdown } from 'react-icons/io'
import { MdModeEditOutline } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import Api from '../../../../../Api'
import { LogoH } from '../../../../../assets'
import { Navbar } from '../../../../../components'
import PrimaryButton from '../../../../../components/Button/PrimaryButton'
import SecondaryButton from '../../../../../components/Button/SecondaryButton'
import imageHandle from '../../../../../utils/imageHandle'
import languages from '../../../../../utils/languages'

const EditSubsidiary = () => {
    const [typePassword, setTypePassword] = useState('password')
    const params = useLocation()
    const [namePath, setNamePath] = useState('')
    const [fileImage, setFileImage] = useState()
    const [imageBase64, setImageBase64] = useState()
    const [organizationName, setOrganizationName] = useState('')
    const [registrationNumber, setRegistrationNumber] = useState('')
    const [taxId, setTaxId] = useState('')
    const [phone, setPhone] = useState('')
    const [fax, setFax] = useState(0)
    const [desc, setDesc] = useState('')
    const [address, setAddress] = useState('')
    const country = 'Indonesia'
    const [province, setProvince] = useState('')
    const [city, setCity] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [notes, setNotes] = useState('')
    const [status, setStatus] = useState('')
    const [userName, setUserName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [timeZone, setTimeZone] = useState('')

    const navigate = useNavigate()

    const handleChange = (e) => {
        const maxSize = 2 * 1024 * 1024
        const allowedExtensions = ['png', 'jpg', 'jpeg'];
        const file = e.target.files[0]
        const reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            const fileExtension = file?.name.split('.').pop().toLowerCase();
            if (file && file.size > maxSize) {
                toast.error('file tidak boleh lebih dari 2 MB')
                setFileImage(null)
            } else if (!allowedExtensions.includes(fileExtension)) {
                toast.error('file harus berformat png/jpg/jpeg')
            } else {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImageBase64(reader.result);
                };
                reader.readAsDataURL(file);
                // setNamePath(URL.createObjectURL(file));
                setNamePath(e.target.files[0].name)
                setFileImage(e.target.files[0])
            }
        }
    }

    const getSubsidiaryById = async () => {
        try {
            const response = await Api.GetSubsidiaryById(localStorage.getItem('hris-token'), params.state.subsidiaryId)
            const res = response.data.company
            setNamePath(res.image ?? "")
            setOrganizationName(res.company_name ?? "")
            setDesc(res.description ?? "")
            setRegistrationNumber(res.registration_number ?? "")
            setTaxId(res.tax_id ?? "")
            setPhone(res.phone ?? "")
            setFax(res.fax ?? "")
            setEmail(res.email ?? "")
            setAddress(res.address ?? "")
            // setCountry(res.country??"")
            setProvince(res.province ?? "")
            setCity(res.city ?? "")
            setPostalCode(res.postal_code ? res.postal_code.toString() : "")
            setNotes(res.description ?? "")
            setUserName(res.user[0].fullname ?? "")
            setStatus(res.user[0].isVerified)
            setTimeZone(res.timezone ?? "")
        } catch (error) {
            console.log(error)
        }
    }

    const updateSubsidiary = async () => {
        if (!organizationName.trim()) {
            toast.error('Nama Perusahaan Tidak Boleh Kosong!')
        } else if (!registrationNumber.trim()) {
            toast.error('Nomor Registrasi Tidak Boleh Kosong!')
        } else if (!taxId.trim()) {
            toast.error('Nomor Pajak Tidak Boleh Kosong!')
        } else if (!phone.trim()) {
            toast.error('Nomor Telepon Tidak Boleh Kosong!')
        } else if (phone.length < 10) {
            toast.error('Nomor Telepon harus memiliki minimal 10 karakter!')
        } else if (!email.trim()) {
            toast.error('Email Tidak Boleh Kosong!')
        } else if (!desc.trim()) {
            toast.error('Deskripsi Tidak Boleh Kosong!')
        } else if (!address.trim()) {
            toast.error('Alamat Tidak Boleh Kosong!')
        } else if (!province.trim()) {
            toast.error('Provinsi Tidak Boleh Kosong!')
        } else if (!city.trim()) {
            toast.error('Kota Tidak Boleh Kosong!')
        } else if (!postalCode.trim()) {
            toast.error('Kode Pos Tidak Boleh Kosong!')
        } else if (!timeZone.trim()) {
            toast.error('Zona Waktu Tidak Boleh Kosong!')
        } else {
            try {
                const data = {
                    subsidiary_name: organizationName,
                    description: desc,
                    registration_number: registrationNumber,
                    tax_id: taxId,
                    phone_number: phone,
                    address: address,
                    city: city,
                    province: province,
                    postal_code: postalCode,
                    country: country,
                    user_name: userName,
                    email: email,
                    status: true,
                    status_company: true,
                    timezone: timeZone
                }
                if (fileImage) {
                    data.image = fileImage
                }
                if (fax || fax !== "") {
                    data.fax = fax
                }
                if (password || password !== "") {
                    data.password = password
                }
                await Api.UpdateSubsidiary(localStorage.getItem('hris-token'), data, params.state.subsidiaryId).then((res) => {
                    // console.log(response)  
                    toast.success('Berhasil mengubah data!')
                    navigate('/subsidiary')
                }).catch((e) => {
                    toast.error(e?.response?.data?.errors);
                })
            } catch (error) {
                console.log(error)
                toast.error("Data gagal diubah!")
            }
        }
    }

    const handleTypePassword = () => {
        if (typePassword === 'password') {
            setTypePassword('text')
        } else {
            setTypePassword('password')
        }
    }

    useEffect(() => {
        getSubsidiaryById()
    }, [])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={languages.language === 'id' ? "Edit Penempatan" : "Edit Subsidiary"} LinkBack={'/subsidiary'} />
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div className='w-full lg:w-auto'>
                            <h1 className='text-dark-3 text-[16px] font-[500]'>
                                {languages.language === 'id' ? "Edit Penempatan" : "Edit Subsidiary Data"}
                            </h1>
                            {/* <h1 className='text-[#A8A8A8] text-xs font-[500]'>
                                Input the data of your subsidiary
                            </h1> */}
                        </div>
                        <div className='space-y-[25px]'>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Logo Perusahaan" : "Company Logo"}</h1>
                                <label htmlFor='upload-image' className={`cursor-pointer`}>
                                    <div className='w-[105px] h-[105px] border rounded-[12px] bg-[#C6C6C6] flex flex-col items-center justify-center bg-cover relative' style={{ backgroundImage: `url(${imageBase64 ? imageBase64 : namePath ? imageHandle(namePath) : LogoH})` }}>
                                        <BsImage className='text-2xl text-[rgb(237,237,237)]' />
                                        <div className='absolute bg-[#F9F9F9] border border-[#EBEBEB] w-[38px] h-[38px] rounded-full -bottom-3 -right-4 flex items-center justify-center'>
                                            <MdModeEditOutline />
                                        </div>
                                    </div>
                                    <input type='file' accept=".jpeg, .jpg, .png" id='upload-image' onChange={(e) => handleChange(e)} className='rounded-[12px] outline-none border border-[#E3E8F1] w-full px-[20px] py-[15px] hidden' required />
                                </label>
                            </div>
                            <div className='grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-2'>
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Nama Perusahaan" : "Organization Name"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <input value={organizationName} onChange={(e) => setOrganizationName(e.target.value)} type={'text'} className={`bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Nama Perusahaan...' />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Nomor Registrasi" : "Registration Number"} <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <input value={registrationNumber} onChange={(e) => setRegistrationNumber(e.target.value)} type={'text'} className={`bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Nomor Registrasi...' />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Nomor Pajak" : "Tax ID"} <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <input value={taxId} onChange={(e) => setTaxId(e.target.value)} type={'text'} className={`bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Nomor Pajak...' />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "No. Telepon" : "Phone Number"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <input value={phone} onChange={(e) => setPhone(e.target.value)} type={'number'} className={`bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='No. Telepon...' />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Fax</h1>
                                    <input value={fax} onChange={(e) => setFax(e.target.value)} type={'text'} className={`bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Fax...' />
                                </div>
                                <div></div>
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Deskripsi Perusahaan" : "Number of Employee"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <textarea value={desc} onChange={(e) => setDesc(e.target.value)} rows={2} className={`bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Deskripsi Perusahaan...' />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Alamat" : "Address"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <textarea value={address} onChange={(e) => setAddress(e.target.value)} rows={2} className={`bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Alamat Perusahaan...' />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Negara" : "Country"}</h1>
                                    <input value={"Indonesia"} disabled={true} type={'text'} className={`bg-dark-9 border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='humanusia@example.com' />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Provinsi" : "Province"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <input value={province} type={'text'} onChange={(e) => setProvince(e.target.value)} className={`bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder={languages.language === 'id' ? "Input Provinsi" : "Input Province"} />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Kabupaten/Kota" : "City"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <input value={city} type={'text'} onChange={(e) => setCity(e.target.value)} className={`bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder={languages.language === 'id' ? "Input Kabupaten/Kota" : "Input City"} />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Kode Pos" : "Postal Code"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <input value={postalCode} onChange={(e) => setPostalCode(e.target.value)} type={'number'} className={`bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Postal Code...' />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Zona waktu<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <div className='relative'>
                                        <select value={timeZone} onChange={(e) => setTimeZone(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                            <option selected disabled value={''}>Pilih Zona Waktu</option>
                                            <option value={'Asia/Jakarta'}>WIB</option>
                                            <option value={'Asia/Makassar'}>WITA</option>
                                            <option value={'Asia/Jayapura'}>WIT</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                                    </div>
                                </div>
                                <div className='w-full md:col-span-2'>
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Catatan" : "Notes"}</h1>
                                    <textarea value={notes} onChange={(e) => setNotes(e.target.value)} type='text' rows={4} className={`bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder={languages.language === 'id' ? "Catatan..." : "Notes..."} />
                                </div>
                            </div>

                            <div className='border-b-2 w-full' />
                            <div className='flex items-center gap-2'>
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Nama Pengguna" : "Username"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <input value={userName} type={'text'} onChange={(e) => setUserName(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder={languages.language === 'id' ? "Nama Pengguna..." : "Input Username"} />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Status" : "Status"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <div className='relative'>
                                        <select value={status} onChange={(e) => setStatus(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                            <option selected disabled value={''}>{languages.language === 'id' ? "Pilih Status" : "Select Status"}</option>
                                            <option value={true}>{languages.language === 'id' ? "Aktif" : "Enable"}</option>
                                            <option value={false}>{languages.language === 'id' ? "Tidak Aktif" : "Disable"}</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center gap-[30px]'>
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Email" : "Email"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <input type={'text'} value={email} onChange={(e) => setEmail(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Humanusia@example.com...' />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Kata Sandi" : "Password"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <div className='relative'>
                                        <input type={typePassword} onChange={(e) => setPassword(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder={languages.language === 'id' ? "Input Kata Sandi" : "Input Password"} />
                                        <button onClick={handleTypePassword} className='absolute top-[10px] right-5 '>
                                            <AiOutlineEye className='text-[#A8A8A8] text-xl' />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <SecondaryButton action={() => navigate('/subsidiary')} text={'Batal'} />
                                <PrimaryButton action={() => updateSubsidiary()} text={"Simpan"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditSubsidiary