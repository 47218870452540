import React, { useEffect } from 'react'
import { useState } from 'react'
import Api from '../../../Api'
import moment from 'moment'
import { Pagination } from '../../../components'
import { AiOutlineDownload } from 'react-icons/ai'
import imageHandle from '../../../utils/imageHandle'

const Inovasi = () => {
    const [refresh, setRefresh] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalData, setTotalData] = useState('')
    const [limit, setLimit] = useState(10)

    const [data, setData] = useState([])

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };
    const getApproval = async () => {
        try {
            const response = await Api.ApprovalInovasi(localStorage.getItem('hris-token'), currentPage, limit)
            console.log(response.data.results)
            setData(response.data.results.data)
            setTotalPages(response.data.results.totalPages)
            setTotalData(response.data.results.totalCount)
        } catch (error) {
            console.log(error)
        }
    }

    const checkboxHandler = async (id, value) => {
        try {
            const data = {
                "isApproved": value
            }
            await Api.PostApprovalInovasi(localStorage.getItem('hris-token'), id, data).then((res) => {
                setRefresh(true)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getApproval()
        setRefresh(false)
    }, [refresh])
    return (
        <div>
            <div className='overflow-auto'>
                <table className='w-full space-y-[10px] text-dark-5 pl-3'>
                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                        <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Nama Pegawai</h1>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Nama Inovasi</h1>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Nomor SK Inovasi</h1>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Deskripsi</h1>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px] justify-center'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Status</h1>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px] justify-center'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Konversi Angka Kredit</h1>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px] justify-center'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Dokumen</h1>
                        </div>
                        <div className='flex justify-center items-center gap-[6px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Status</h1>
                        </div>
                    </div>
                    {data && data.map((item, idx) => (
                        <div key={idx} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                            <div className='min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item?.user?.fullname ? item?.user?.fullname : '-'}</h1>
                            </div>
                            <div className='min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item?.name ? item?.name : '-'}</h1>
                            </div>
                            <div className='min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item?.nomor_sk ? item?.nomor_sk : '-'}</h1>
                            </div>
                            <div className='min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item?.description ? item?.description : '-'}</h1>
                            </div>
                            <div className='flex items-center justify-center min-w-[150px] max-w-[150px]'>
                                <h1 className={`text-[11px] font-[500] truncate text-center p-2 rounded w-fit ${item?.isApproved === true ? 'text-[#7EFFB2]' : 'text-red-thick'}`}>{item?.isApproved === true ? "Sudah diverifikasi" : "Belum diverifikasi"}</h1>
                            </div>
                            <div className='flex items-center justify-center min-w-[150px] max-w-[150px]'>
                                <h1 className={`text-[11px] font-[500] truncate text-center p-2 rounded w-fit ${item?.isClaimed === true ? 'text-[#7EFFB2]' : 'text-red-thick'}`}>{item?.isClaimed === true ? "Sudah dikonversi" : "Belum dikonversi"}</h1>
                            </div>
                            <div className='flex items-center justify-center min-w-[150px] max-w-[150px]'>
                                <a href={imageHandle(item?.file)} download={item?.file?.split('/').pop()} target='_blank' className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center' rel="noreferrer">
                                    <AiOutlineDownload className='text-sm text-blue-thick' />
                                </a>
                            </div>
                            <div className='flex justify-center'>
                                <label className="inline-flex gap-2 items-center justify-center cursor-pointer w-full">
                                    <input
                                        type="checkbox"
                                        value=""
                                        className=""
                                        checked={item.isApproved}
                                        onChange={() => checkboxHandler(item.id, !item.isApproved)}
                                    />
                                    <span className="text-dark-5 text-xs font-[500] truncate text-center">Verifikasi</span>
                                </label>
                            </div>
                        </div>
                    ))}
                </table>
            </div>
            <div className='px-[14px] py-[8px]'>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    lengthData={totalData}
                    limitData={limit}
                    onPageChange={handlePageChange}
                    onPrevChange={handlePrevChange}
                    onNextChange={handleNextChange}
                />
            </div>
        </div>
    )
}

export default Inovasi
