import React, { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { BiPlus, BiSearch } from 'react-icons/bi'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { IoMdArrowDropdown } from 'react-icons/io'
import { MdImportExport } from 'react-icons/md'
import Api from '../../../../../Api'
import { Modal, ModalDelete, Pagination } from '../../../../../components'
import languages from '../../../../../utils/languages'
import IconPrimaryButton from '../../../../../components/Button/IconPrimaryButton'
import { IconPlus } from '../../../../../assets'
import SecondaryButton from '../../../../../components/Button/SecondaryButton'
import PrimaryButton from '../../../../../components/Button/PrimaryButton'

function Jabatan() {
    const [showModalAddJobPosition, setShowModalAddJobPosition] = useState(false)
    const [showModalEditJobPosition, setShowModalEditJobPosition] = useState(false)
    const [showModalDeleteJobPosition, setShowModalDeleteJobPosition] = useState(false)

    // Pagination
    const limit = 10
    const [refresh, setRefresh] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const resetForm = () => {
        setJobPositionName("")
        setIdJobPositionComp("")
        setJobGradeDropdown("")
        // setJobGradeDropdownName("")
        // setJobCategoryDropdownName("")
        setJobCategory("")
        setSubsidiary("")
        setSuperiorId("")
        setUnitKerjaSelected("")
        setUnitKerjaALSelected("")
        setUnitKerjaEselonSelected("")
    }

    // Job Position

    const [dataJobPosition, setDataJobPosition] = useState('')
    const [dataJobPositionDropdown, setDataJobPositionDropdown] = useState([])
    const [jobPositionName, setJobPositionName] = useState('')
    const [idJobPosition, setIdJobPosition] = useState('')
    const [idJobPositionComp, setIdJobPositionComp] = useState('')
    const [superiorId, setSuperiorId] = useState('')
    const [unitKerjaSelected, setUnitKerjaSelected] = useState('')
    const [unitKerjaALSelected, setUnitKerjaALSelected] = useState('')
    const [unitKerjaEselonSelected, setUnitKerjaEselonSelected] = useState('')
    const [jobCategory, setJobCategory] = useState('')

    const OpenEditJobPosition = async (id) => {
        setIdJobPosition(id)
        setShowModalEditJobPosition(!showModalEditJobPosition)
        setJobPositionName('')
        setJobGradeDropdown('')
        setSuperiorId('')
        setUnitKerjaSelected('')
        setUnitKerjaALSelected('')
        setUnitKerjaEselonSelected('')
        setSubsidiary('')
        try {
            const response = await Api.GetJobPositionByID(localStorage.getItem('hris-token'), id)
            setJobPositionName(response.data.jobPosition.position_name ?? "")
            setIdJobPositionComp(response.data.jobPosition.jobId ?? "")
            setJobGradeDropdown(response.data.jobPosition.job_grade.id ?? "")
            // setJobGradeDropdownName(response.data.jobPosition.job_grade.job_grade ?? "")
            // setJobCategoryDropdownName(response.data.jobPosition.job_category.category_name ?? "")
            setJobCategory(response.data.jobPosition.job_category.id ?? "")
            setSubsidiary(response.data.jobPosition.companyId ?? "")
            setSuperiorId(response.data.jobPosition.superiorId ?? "")
            setUnitKerjaSelected(response.data.jobPosition.UnitKerja.id ?? "")
            setUnitKerjaALSelected(response.data.jobPosition.UnitKerjaAtasanLangsung.id ?? "")
            setUnitKerjaEselonSelected(response.data.jobPosition.UnitKerjaInduk.id ?? "")
        } catch (error) {
            console.log(error)
        }
    }

    const getJobPosition = async () => {
        try {
            const resJobPosition = await Api.GetJobPosition(localStorage.getItem('hris-token'), currentPage, limit, name)
            setDataJobPosition(resJobPosition.data.data)
            setCurrentPage(resJobPosition.data.currentPage)
            setTotalPages(resJobPosition.data.totalPages)
            setTotalCount(resJobPosition.data.totalCount)
        } catch (error) {
            console.log(error)
        }
    }

    const getJobPositionDropdown = async () => {
        try {
            const response = await Api.GetJobPositionDropdown(localStorage.getItem('hris-token'), unitKerjaSelected)
            setDataJobPositionDropdown(response.data.results.data)
        } catch (error) {
            console.log(error)
        }
    }

    const postJobPosition = async () => {
        if (!jobPositionName.trim()) {
            toast.error('Nama Jabatan Tidak Boleh Kosong!')
        } else if (!idJobPositionComp.trim()) {
            toast.error('Id Jabatan Tidak Boleh Kosong!')
        } else if (!subsidiary.trim()) {
            toast.error('Penempatan Tidak Boleh Kosong!')
        } else if (!unitKerjaSelected.trim()) {
            toast.error('Unit Kerja Tidak Boleh Kosong!')
        } else if (!jobGradeDropdown.trim()) {
            toast.error('Kelas Jabatan Tidak Boleh Kosong!')
        } else if (!jobCategory.trim()) {
            toast.error('Jenis Kepegawaian Tidak Boleh Kosong!')
        } else {
            try {
                const data = {
                    position_name: jobPositionName,
                    jobId: idJobPositionComp,
                    job_grade: jobGradeDropdown,
                    job_category: jobCategory,
                    unit_kerja: unitKerjaSelected,
                    companyId: subsidiary
                }
                if (unitKerjaALSelected !== '') {
                    data.unit_kerja_atasan_langsung = unitKerjaALSelected;
                }
                if (unitKerjaEselonSelected !== '') {
                    data.unit_kerja_induk = unitKerjaEselonSelected;
                }
                if (superiorId !== '') {
                    data.superiorId = superiorId;
                }
                await Api.PostJobPosition(localStorage.getItem('hris-token'), data)
                    .then((response) => {
                        setShowModalAddJobPosition(!showModalAddJobPosition)
                        setRefresh(true)
                        toast.success('Berhasil Menambahakn Data!')
                        resetForm()
                    }).catch((error) => {
                        console.log(error)
                        const e = error.response.data.errors.error.details
                        for (let i = 0; i < e.length; i++) {
                            toast.error(error.response.data.errors.error.details[i].message)
                        }
                    })
            } catch (error) {
                console.log(error)
                toast.error('Gagal Menambahkan Data!')
            }
        }
    }

    const editJobPosition = async () => {
        if (!jobPositionName.trim()) {
            toast.error('Nama Jabatan Tidak Boleh Kosong!')
        } else if (!idJobPositionComp.trim()) {
            toast.error('Id Jabatan Tidak Boleh Kosong!')
        } else if (!subsidiary.trim()) {
            toast.error('Penempatan Tidak Boleh Kosong!')
        } else if (!unitKerjaSelected.trim()) {
            toast.error('Unit Kerja Tidak Boleh Kosong!')
        } else if (!jobGradeDropdown.trim()) {
            toast.error('Kelas Jabatan Tidak Boleh Kosong!')
        } else if (!jobCategory.trim()) {
            toast.error('Jenis Kepegawaian Tidak Boleh Kosong!')
        } else {
            try {
                const data = {
                    position_name: jobPositionName,
                    jobId: idJobPositionComp,
                    job_grade: jobGradeDropdown,
                    job_category: jobCategory,
                    unit_kerja: unitKerjaSelected,
                    companyId: subsidiary
                }
                if (unitKerjaALSelected !== '') {
                    data.unit_kerja_atasan_langsung = unitKerjaALSelected;
                }
                if (unitKerjaEselonSelected !== '') {
                    data.unit_kerja_induk = unitKerjaEselonSelected;
                }
                if (superiorId !== '') {
                    data.superiorId = superiorId;
                }
                await Api.UpdateJobPosition(localStorage.getItem('hris-token'), data, idJobPosition)
                    .then((response) => {
                        setShowModalEditJobPosition(!showModalEditJobPosition)
                        setRefresh(true)
                        toast.success('Berhasil Menambahkan Data!')
                        resetForm()
                    })
                    .catch((error) => {
                        console.log(error)
                        const e = error.response.data.errors.error.details
                        for (let i = 0; i < e.length; i++) {
                            toast.error(error.response.data.errors.error.details[i].message)
                        }
                    })
            } catch (error) {
                toast.error('Gagal Menambahkan Data!')
                console.log(error)
            }
        }
    }

    const deleteJobPositionModal = async (id) => {
        setShowModalDeleteJobPosition(!showModalDeleteJobPosition)
        setIdJobPosition(id)
    }

    const deleteJobPosition = async (id) => {
        try {
            await Api.DeleteJobPosition(localStorage.getItem('hris-token'), idJobPosition).then((res) => {
                setShowModalDeleteJobPosition(!showModalDeleteJobPosition)
                setRefresh(true)
                toast.success('Successfully deleted data!!!')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            toast.error('Failed to delete data!!!')
        }
    }

    const [dataJobGrade, setDataJobGrade] = useState([])
    const [jobGradeDropdown, setJobGradeDropdown] = useState('')

    const getJobGrade = async () => {
        try {
            const response = await Api.GetJobGradeDropdown(localStorage.getItem('hris-token'))
            setDataJobGrade(response.data.results.data)
        } catch (error) {
            console.log(error)
        }
    }
    const [dataUnitKerja, setDataUnitKerja] = useState([])

    const getUnitKerja = async () => {
        try {
            const response = await Api.GetUnitKerjaDropdown(localStorage.getItem('hris-token'), subsidiary)
            setDataUnitKerja(response.data.results)
        } catch (error) {
            console.log(error)
        }
    }

    const [dataJobCategory, setDataJobCategory] = useState([])

    const getJobCategory = async () => {
        try {
            const response = await Api.GetJobCategoryDropdown(localStorage.getItem('hris-token'))
            setDataJobCategory(response.data.results.data)
        } catch (error) {
            console.log(error)
        }
    }

    const [dataSubsidiary, setDataSubsidiary] = useState([])
    const [subsidiary, setSubsidiary] = useState('')

    const getSubsidiary = async () => {
        try {
            const response = await Api.GetSubsidiaryDropdown(localStorage.getItem('hris-token'))
            setDataSubsidiary(response.data.results.data)
        } catch (error) {
            console.log(error)
        }
    }

    const [name, setName] = useState("")

    // Implementasi fungsi debounce
    const debounce = (func, delay) => {
        let timer;
        return (...args) => {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    // Event handler untuk input perubahan dengan debounce
    const debouncedSetName = useCallback(debounce((value) => setName(value), 500), []);

    const handleInputChange = (e) => {
        debouncedSetName(e.target.value);
    };

    const [role, setRole] = useState('')
    const Fetch = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setRole(response.data.results.user.role)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getJobPosition()
        Fetch()
        setRefresh(false)
    }, [refresh, name])

    useEffect(() => {
        getJobGrade()
        getUnitKerja()
        getJobCategory()
        getJobPositionDropdown()
        getSubsidiary()
    }, [subsidiary, unitKerjaSelected])
    return (
        <>
            <div className='relative'>
                <Modal
                    activeModal={showModalAddJobPosition}
                    title={languages.language === 'id' ? "Tambah Jabatan" : "Add Jabatan"}
                    buttonClose={() => { setShowModalAddJobPosition(!showModalAddJobPosition); resetForm() }}
                    width={'550px'}
                    content={
                        <div className='space-y-[25px]'>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Nama Jabatan" : "Job Position Name"}<span className='text-red-primer ml-[4px]'>*</span></h1>
                                <input onChange={(e) => setJobPositionName(e.target.value)} value={jobPositionName} type={'text'} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full' placeholder={languages.language === 'id' ? "Input Nama Jabatan" : "Job Position Name..."} />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Id Jabatan" : "Job Position ID"}<span className='text-red-primer ml-[4px]'>*</span></h1>
                                <input onChange={(e) => setIdJobPositionComp(e.target.value)} value={idJobPositionComp} type={'number'} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full' placeholder='Id Jabatan...' />
                            </div>

                            <div className='w-full'>
                                <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Kantor Cabang/Penempatan<span className='text-red-primer'>*</span></h2>
                                <div className='relative'>
                                    <select onChange={(e) => setSubsidiary(e.target.value)} value={subsidiary} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected disabled value={''}>Pilih Kantor Cabang/Penempatan...</option>
                                        {Object.values(dataSubsidiary).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.company_name}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-2 text-[#2E2E2E ] text-xl' />
                                </div>
                            </div>

                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Unit Kerja<span className='text-red-primer ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select onChange={(e) => setUnitKerjaSelected(e.target.value)} value={unitKerjaSelected} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>Pilih Unit Kerja...</option>
                                        {Object.values(dataUnitKerja).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.name}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Unit Kerja Atasan Langsung</h1>
                                <div className='relative'>
                                    <select onChange={(e) => setUnitKerjaALSelected(e.target.value)} value={unitKerjaALSelected} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>Pilih Unit Kerja...</option>
                                        {Object.values(dataUnitKerja).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.name}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Unit Kerja Eselon II / Keasistenan Utama/ Perwakilan</h1>
                                <div className='relative'>
                                    <select onChange={(e) => setUnitKerjaEselonSelected(e.target.value)} value={unitKerjaEselonSelected} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>Pilih Unit Kerja...</option>
                                        {Object.values(dataUnitKerja).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.name}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Kelas Jabatan<span className='text-red-primer ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select onChange={(e) => setJobGradeDropdown(e.target.value)} value={jobGradeDropdown} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected disabled value={''}>{languages.language === 'id' ? "Pilih Kelas Jabatan..." : "Select Job Grade"}</option>
                                        {Object.values(dataJobGrade).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.job_grade}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Jenis Kepegawaian<span className='text-red-primer ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select onChange={(e) => setJobCategory(e.target.value)} value={jobCategory} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>{languages.language === 'id' ? "Pilih Jenis Kepegawaian..." : "Select Job Category..."}</option>
                                        {Object.values(dataJobCategory).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.category_name}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Atasan Langsung</h1>
                                <div className='relative'>
                                    <select onChange={(e) => setSuperiorId(e.target.value)} value={superiorId} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>{languages.language === 'id' ? "Pilih Atasan Langsung" : "Select Superior"}</option>
                                        {Object.values(dataJobPositionDropdown).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.position_name}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                                </div>
                            </div>
                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <SecondaryButton action={() => { setShowModalAddJobPosition(!showModalAddJobPosition); resetForm() }} text={'Batal'} />
                                <PrimaryButton action={() => postJobPosition()} text={'Simpan'} />
                            </div>
                        </div>
                    }
                />
            </div>
            <div className='relative'>
                <Modal
                    activeModal={showModalEditJobPosition}
                    title={'Edit Jabatan'}
                    buttonClose={() => { setShowModalEditJobPosition(!showModalEditJobPosition); resetForm() }}
                    width={'550px'}
                    content={
                        <div className='space-y-[25px]'>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Nama Jabatan" : "Job Position Name"}<span className='text-red-primer ml-[4px]'>*</span></h1>
                                <input onChange={(e) => setJobPositionName(e.target.value)} value={jobPositionName} type={'text'} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full' placeholder={languages.language === 'id' ? "Input Nama Jabatan" : "Job Position Name..."} />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Id Jabatan" : "Job Position ID"}<span className='text-red-primer ml-[4px]'>*</span></h1>
                                <input onChange={(e) => setIdJobPositionComp(e.target.value)} value={idJobPositionComp} type={'number'} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full' placeholder='Id Jabatan...' />
                            </div>

                            <div className='w-full'>
                                <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Kantor Cabang/Penempatan<span className='text-red-primer'>*</span></h2>
                                <div className='relative'>
                                    <select onChange={(e) => setSubsidiary(e.target.value)} value={subsidiary} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected disabled value={''}>Pilih Kantor Cabang/Penempatan...</option>
                                        {Object.values(dataSubsidiary).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.company_name}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-2 text-[#2E2E2E ] text-xl' />
                                </div>
                            </div>

                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Unit Kerja<span className='text-red-primer ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select onChange={(e) => setUnitKerjaSelected(e.target.value)} value={unitKerjaSelected} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>Pilih Unit Kerja...</option>
                                        {Object.values(dataUnitKerja).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.name}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Unit Kerja Atasan Langsung</h1>
                                <div className='relative'>
                                    <select onChange={(e) => setUnitKerjaALSelected(e.target.value)} value={unitKerjaALSelected} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>Pilih Unit Kerja...</option>
                                        {Object.values(dataUnitKerja).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.name}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Unit Kerja Eselon II / Keasistenan Utama/ Perwakilan</h1>
                                <div className='relative'>
                                    <select onChange={(e) => setUnitKerjaEselonSelected(e.target.value)} value={unitKerjaEselonSelected} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>Pilih Unit Kerja...</option>
                                        {Object.values(dataUnitKerja).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.name}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Kelas Jabatan<span className='text-red-primer ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select onChange={(e) => setJobGradeDropdown(e.target.value)} value={jobGradeDropdown} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected disabled value={''}>{languages.language === 'id' ? "Pilih Kelas Jabatan..." : "Select Job Grade"}</option>
                                        {Object.values(dataJobGrade).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.job_grade}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Jenis Kepegawaian<span className='text-red-primer ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select onChange={(e) => setJobCategory(e.target.value)} value={jobCategory} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>{languages.language === 'id' ? "Pilih Jenis Kepegawaian..." : "Select Job Category..."}</option>
                                        {Object.values(dataJobCategory).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.category_name}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Atasan Langsung</h1>
                                <div className='relative'>
                                    <select onChange={(e) => setSuperiorId(e.target.value)} value={superiorId} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>{languages.language === 'id' ? "Pilih Atasan Langsung" : "Select Superior"}</option>
                                        {Object.values(dataJobPositionDropdown).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.position_name}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                                </div>
                            </div>
                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <SecondaryButton action={() => { setShowModalEditJobPosition(!showModalEditJobPosition); resetForm() }} text={'Batal'} />
                                <PrimaryButton action={() => editJobPosition()} text={'Simpan'} />
                            </div>
                        </div>
                    }
                />
            </div>
            <div className='relative'>
                <ModalDelete
                    activeModal={showModalDeleteJobPosition}
                    buttonClose={() => setShowModalDeleteJobPosition(!showModalDeleteJobPosition)}
                    submitButton={deleteJobPosition}
                />
            </div>
            <div className='bg-white border shadow-sm rounded-[12px] px-[22px] py-[36px]'>
                <div className='flex flex-wrap justify-between items-center gap-2 mb-[36px]'>
                    <div className='w-full lg:w-auto'>
                        <h1 className='text-dark-3 text-[16px] font-bold'>Jabatan</h1>
                        <h1 className='text-grey-thick text-xs font-medium'>Daftar Jabatan</h1>
                    </div>
                    <div className='flex flex-wrap gap-2'>
                        <div className='relative'>
                            <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg' />
                            <input onChange={(e) => handleInputChange(e)} placeholder='Cari ...' className='text-[#A8A8A8] text-[12px] font-[500] pl-12 border rounded-md py-2 w-full lg:w-[200px]' />
                        </div>
                        {role === 'ADMIN' ?
                            <IconPrimaryButton icon={IconPlus} action={() => setShowModalAddJobPosition(!showModalAddJobPosition)} text={'Tambah Jabatan'} />
                            : null
                        }
                    </div>
                </div>
                <div>

                    <div className='overflow-auto'>
                        <table className='w-full space-y-[10px]'>
                            <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[15px] min-w-[600px] max-w-[600px]'>
                                    <h1 className='text-grey-thick text-xs font-[500]'>{languages.language === 'id' ? "Nama Jabatan" : "Position Name"}</h1>
                                    <MdImportExport className='text-grey-thick text-xs' />
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                    <h1 className='text-grey-thick text-xs font-[500]'>Kantor Cabang/Penempatan</h1>
                                </div>
                                {role === 'ADMIN' ?
                                    <div className='flex items-center justify-center gap-[15px] w-full'>
                                        <h1 className='text-grey-thick text-xs font-[500]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                                    </div>
                                    : null
                                }
                            </div>
                            {dataJobPosition.length === 0 ?
                                <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='min-w-[600px] max-w-[600px]'>
                                        <h1 className='text-grey-thick text-xs font-[500] truncate'>-</h1>
                                    </div>
                                    <div className='min-w-[300px] max-w-[300px]'>
                                        <h1 className='text-grey-thick text-xs font-[500] truncate'>-</h1>
                                    </div>
                                    {role === 'ADMIN' ?
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            <h1 className='text-grey-thick text-xs font-[500] truncate'>-</h1>
                                        </div>
                                        : null
                                    }
                                </div>
                                :
                                Object.values(dataJobPosition).map((data, index) => {
                                    return (

                                        <div key={index} className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='min-w-[600px] max-w-[600px]'>
                                                <h1 className='text-grey-thick text-xs font-[500] truncate'>{data.position_name}</h1>
                                            </div>
                                            <div className='min-w-[300px] max-w-[300px]'>
                                                <h1 className='text-grey-thick text-xs font-[500] truncate'>{data?.company?.company_name}</h1>
                                            </div>
                                            {role === 'ADMIN' ?
                                                <div className='w-full flex items-center justify-center gap-[12px]'>
                                                    <button onClick={() => OpenEditJobPosition(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <HiOutlinePencil className='text-[#003049]' />
                                                    </button>
                                                    <button onClick={() => deleteJobPositionModal(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <CgTrashEmpty className='text-[#003049]' />
                                                    </button>
                                                </div>
                                                : null
                                            }
                                        </div>
                                    )
                                })}
                        </table>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        lengthData={totalCount}
                        limitData={dataJobPosition?.length}
                        onPageChange={handlePageChange}
                        onPrevChange={handlePrevChange}
                        onNextChange={handleNextChange}
                    />
                </div>
            </div>
        </>
    )
}

export default Jabatan