import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { BiDownload } from 'react-icons/bi'
import { HiPaperClip } from 'react-icons/hi'
import { useLocation } from 'react-router-dom'
import Api from '../../../../../../../Api'
import { Navbar } from '../../../../../../../components'
import imageHandle from '../../../../../../../utils/imageHandle'
import languages from '../../../../../../../utils/languages'
import LKPKT from './lkpkt'

const DetailLaporanSayaBulanan = () => {
    moment.locale('id');
    const params = useLocation()
    const id = params?.state?.id

    const [refresh, setRefresh] = useState(false)

    const [data, setData] = useState([])

    const [dataPKT, setDataPKT] = useState([])
    const [dataKinerja, setDataKinerja] = useState([])

    const getLKBawahanById = async () => {
        try {
            await Api.GetDetailLaporan(localStorage.getItem('hris-token'), id).then((response) => {
                // console.log(response.data.results)
                const res = response?.data?.results
                setData(res)
                setDataPKT(res.prestasiKerjaTertentu)
                setDataKinerja(res.kinerja)
            })
        } catch (error) {
            console.log(error)
        }
    }


    const [dataLM, setDataLM] = useState([])
    const getLM = async () => {
        try {
            await Api.GetLM(localStorage.getItem('hris-token'), id).then((response) => {
                console.log("LM", response.data.results.data)
                setDataLM(response.data.results.data)
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getLKBawahanById()
        getLM()
        setRefresh(false)
    }, [refresh])
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={languages.language === 'id' ? "Detail Penilaian" : "Detail Evaluation"} LinkBack={'/laporan-kinerja'} />
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='space-y-2'>
                        <div className='grid grid-cols-6'>
                            <p className='text-dark-3 text-sm font-semibold'>Nama Pegawai</p>
                            <p className='col-span-5 text-dark-3 text-sm font-semibold'>: {data?.laporan?.fullname}</p>
                        </div>
                        <div className='grid grid-cols-6'>
                            <p className='text-dark-3 text-sm font-semibold'>Bulan</p>
                            <p className='col-span-5 text-dark-3 text-sm font-semibold'>: {moment(data?.laporan?.start_date).format('MMMM YYYY')}</p>
                        </div>
                        <div className='grid grid-cols-6'>
                            <p className='text-dark-3 text-sm font-semibold'>Status</p>
                            <p className='col-span-5 text-dark-3 text-sm font-semibold'>: {data?.laporan?.status !== "approved" ? data?.laporan?.status : "Disetujui"}</p>
                        </div>
                        {/* {dataLaporan.data && dataLaporan.data.map((val, index) => (
                                <p key={index} className={`text-[#C1121F] font-semibold py-[10px] px-[10px]`}>{val.bulan}</p>
                            ))} */}
                    </div>
                    <div className='border p-5 rounded-[12px]'>
                        <h1 className='text-dark-3 text-[20px] font-[600] text-center'>Laporan Hasil Kerja</h1>
                        {/* <div className='mt-5'>
                            <h1 className="text-zinc-800 text-sm font-medium mb-3">Tugas Utama</h1>
                            <div className='mt-5 mb-[44px] overflow-auto'>
                                <table className='min-w-full'>
                                    <thead>
                                        <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                                            <th rowSpan={2} className='border py-3 px-5'>No.</th>
                                            <th rowSpan={2} className='border py-3 px-5'>RHK Atasan yang diintervensi</th>
                                            <th rowSpan={2} className='border py-3 px-5'>Jenis RHK</th>
                                            <th rowSpan={2} className='border py-3 px-5'>Nama RHK</th>
                                            <th rowSpan={2} className='border py-3 px-5'>Target RHK</th>
                                            <th colSpan={9} className='border py-3 px-5'>Rencana Aksi</th>
                                        </tr>
                                        <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                                            <th className='border py-3 px-5 min-w-[200px]'>Nama Rencana Aksi</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Target Kualitas</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Target Kuantitas</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Target Waktu</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Target Biaya</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Bukti Dukung</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Realisasi Kuantitas</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Realisasi Biaya</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Umpan Balik</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.rencanaHasilKerja && data?.rencanaHasilKerja.map((val, index) => (
                                            <tr key={index} className='border'>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{index + 1}.</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.rhk_atasan ?? "-"}</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.type}</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.rhk}</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.target_rhk}</td>
                                                <td className='border py-0 px-0' colSpan={9}>
                                                    <table className='min-w-full'>
                                                        <tbody>
                                                            {val?.rencana_aksi && val?.rencana_aksi.map((v, i) => (
                                                                <tr key={i} className='border'>
                                                                    <td className='py-3 px-5 text-grey-thick text-xs font-medium min-w-[200px]'>{v?.name}</td>
                                                                    <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>{v?.target_kualitas}</td>
                                                                    <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>{v?.target_kuantitas}</td>
                                                                    <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>{v?.target_waktu}</td>
                                                                    <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>{v?.target_biaya}</td>
                                                                    <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>
                                                                        <div className='flex items-center justify-center gap-3'>
                                                                            <p>{v?.bukti_dukung.length ? v?.bukti_dukung.length : '0'} Dokumen</p>
                                                                            <button
                                                                                onClick={() => handleDetailBuktiDukung(v?.bukti_dukung)}
                                                                                className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                                                <IoEyeOutline className='text-blue-thick' />
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                    <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>{v?.realisasi_kuantitas}</td>
                                                                    <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>{v?.realitas_target_biaya}</td>
                                                                    <td className='border-l py-3 px-5 text-grey-thick text-xs font-medium min-w-[200px]'>
                                                                        <ul className='space-y-2'>
                                                                            {v.feedback && v.feedback.map((item, i) => (
                                                                                <li>
                                                                                    <p className='text-xs'>{i + 1}. {item.penilai}</p>
                                                                                    <h1 className={`${item?.feedback === 'Sesuai Ekspektasi' ? 'text-white bg-[#51A7F5]' : item?.feedback === 'Dibawah Ekspektasi' ? 'text-white bg-[#EA5A54]' : item?.feedback === 'Diatas Ekspektasi' ? 'text-white bg-[#AED96C]' : ''} font-medium text-xs rounded-lg text-center p-2 w-fit`}>{item?.feedback === null ? "-" : item?.feedback}</h1>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div> */}
                        <div>
                            <LKPKT dataPKT={dataPKT} dataKinerja={dataKinerja} />
                        </div>
                        <div className='mt-5'>
                            <h1 className="text-zinc-800 text-sm font-medium mb-3">Tugas Tambahan</h1>
                            <div className='mt-5 mb-[44px] overflow-auto'>
                                <table className='min-w-full'>
                                    <thead>
                                        <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                                            <th className='border py-3 px-5'>No.</th>
                                            <th className='border py-3 px-5'>Tanggal</th>
                                            <th className='border py-3 px-5'>Uraian Kegiatan</th>
                                            <th className='border py-3 px-5'>Dasar Penugasan</th>
                                            <th className='border py-3 px-5'>Hasil</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.tugasTambahan && data.tugasTambahan.map((val, index) => (
                                            <tr className='border'>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{index + 1}.</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium max-w-[200px]'>{moment(val?.tanggal).format("D MMMM YYYY")}</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium max-w-[200px]'>{val.uraian_kegiatan}</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium max-w-[200px]'>{val?.dasar_penugasan}</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium max-w-[200px]'>{val?.hasil}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='border p-5 rounded-[12px]'>
                        <h1 className='text-dark-3 text-[20px] font-[600] text-center'>Perilaku Kerja</h1>
                        <div className='mt-5 mb-[44px] overflow-auto'>
                            {data.penilaianPerilakuKerja && data.penilaianPerilakuKerja.map((evaluation, evaluationIndex) => (
                                <table key={evaluationIndex} className='min-w-full'>
                                    <thead>
                                        <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                                            <th className='border py-3 px-5'>No.</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Perilaku</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Umpan Balik</th>
                                            <th className='border py-3 px-5 min-w-[200px]'>Umpan Balik Rekan Kerja</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {evaluation && evaluation.settings.map((setting, settingIndex) => (
                                            <tr className='border'>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{settingIndex + 1}.</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium min-w-[200px]'>
                                                    <p className='text-grey-thick text-xs font-medium'>{setting.setting_name}</p>
                                                    <ul className='text-grey-thick text-xs font-medium list-disc ml-4'>
                                                        {setting && setting.points.map((point, pointIndex) => (
                                                            <li key={pointIndex}>{point.point_name}</li>
                                                        ))}
                                                    </ul>
                                                </td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium min-w-[200px]'>
                                                    <ul className='space-y-2'>
                                                        <li>
                                                            <p className='text-[10px]'>1. {data?.laporan?.namaPenilai1}</p>
                                                            <h1 className={`${setting?.feedback === 'Sesuai Ekspektasi' ? 'text-white bg-[#51A7F5]' : setting?.feedback === 'Dibawah Ekspektasi' ? 'text-white bg-[#EA5A54]' : setting?.feedback === 'Diatas Ekspektasi' ? 'text-white bg-[#AED96C]' : ''} font-medium text-xs rounded-lg text-center p-2 w-fit`}>{setting.feedback === null ? "-" : setting.feedback}</h1>
                                                        </li>
                                                        {
                                                            data?.laporan?.penilai2Id ?
                                                                <li>
                                                                    <p className='text-[10px]'>2. {data?.laporan?.namaPenilai2}</p>
                                                                    <h1 className={`${data.penilaianPerilakuKerja2[evaluationIndex]?.settings[settingIndex]?.feedback === 'Sesuai Ekspektasi' ? 'text-white bg-[#51A7F5]' : data.penilaianPerilakuKerja2[evaluationIndex]?.settings[settingIndex]?.feedback === 'Dibawah Ekspektasi' ? 'text-white bg-[#EA5A54]' : data.penilaianPerilakuKerja2[evaluationIndex]?.settings[settingIndex]?.feedback === 'Diatas Ekspektasi' ? 'text-white bg-[#AED96C]' : ''} font-medium text-xs rounded-lg text-center p-2 w-fit`}>{data.penilaianPerilakuKerja2[evaluationIndex]?.settings[settingIndex]?.feedback === null ? "-" : data.penilaianPerilakuKerja2[evaluationIndex]?.settings[settingIndex]?.feedback}</h1>
                                                                </li>
                                                                : null
                                                        }
                                                    </ul>
                                                </td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium min-w-[200px]'>
                                                    {Object.entries(setting.feedback_rekan_kerja).map(([category, count]) => (
                                                        <h1 className='text-[10px] rounded-lg px-[8px] py-1 w-fit'>
                                                            <span>{category}</span> : <span>{count}</span>
                                                        </h1>
                                                    ))}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ))}
                        </div>
                    </div>

                    {dataLM ?
                        <div className='p-5 rounded-[12px] border '>
                            <h1 className="text-zinc-800 text-xl font-semibold">Indikator Kuantitatif Kinerja</h1>
                            <div className='p-5 rounded-[12px] border space-y-3 mt-5'>
                                <div className='grid grid-cols-2'>
                                    <div className='flex items-center'>
                                        <p className='text-sm'>Jumlah Laporan Masyarakat yang ditangani</p>
                                    </div>
                                    <div className='flex gap-1'>
                                        <p className='text-sm'>:</p>
                                        <input
                                            disabled
                                            type="number"
                                            value={dataLM?.jumlah_LM_yang_ditutup}
                                            className="bg-base-outline h-[25px] px-2 mx-2 text-sm border rounded-sm w-full"
                                            placeholder=''
                                        />
                                    </div>
                                </div>
                                <div className='grid grid-cols-2'>
                                    <div className='flex items-center'>
                                        <p className='text-sm'>Jumlah Laporan Masyarakat yang ditutup</p>
                                    </div>
                                    <div className='flex gap-1'>
                                        <p className='text-sm'>:</p>
                                        <input
                                            disabled
                                            type="number"
                                            value={dataLM?.jumlah_LM_yang_ditangani}
                                            className="bg-base-outline h-[25px] px-2 mx-2 text-sm border rounded-sm w-full"
                                            placeholder=''
                                        />
                                    </div>
                                </div>
                                <div className='grid grid-cols-2'>
                                    <div className='flex items-center'>
                                        <p className='text-sm'>Jumlah Inisiatif Atas Prakarsa Sendiri yang ditutup</p>
                                    </div>
                                    <div className='flex gap-1'>
                                        <p className='text-sm'>:</p>
                                        <input
                                            disabled
                                            type="number"
                                            value={dataLM?.jumlah_IN_yang_ditutup}
                                            className="bg-base-outline h-[25px] px-2 mx-2 text-sm border rounded-sm w-full"
                                            placeholder=''
                                        />
                                    </div>
                                </div>
                                <div className='grid grid-cols-2'>
                                    <div className='flex items-center'>
                                        <p className='text-sm'>Jumlah Sistematik Reviu yang ditutup</p>
                                    </div>
                                    <div className='flex gap-1'>
                                        <p className='text-sm'>:</p>
                                        <input
                                            disabled
                                            type="number"
                                            value={dataLM?.jumlah_SR_yang_ditutup}
                                            className="bg-base-outline h-[25px] px-2 mx-2 text-sm border rounded-sm w-full"
                                            placeholder=''
                                        />
                                    </div>
                                </div>
                                <div className='grid grid-cols-2'>
                                    <div className='flex items-center'>
                                        <p className='text-sm'>Jumlah Rapid Assessment yang ditutup</p>
                                    </div>
                                    <div className='flex gap-1'>
                                        <p className='text-sm'>:</p>
                                        <input
                                            disabled
                                            type="number"
                                            value={dataLM?.jumlah_RA_yang_ditutup}
                                            className="bg-base-outline h-[25px] px-2 mx-2 text-sm border rounded-sm w-full"
                                            placeholder=''
                                        />
                                    </div>
                                </div>
                                <div className='grid grid-cols-2'>
                                    <div className='flex items-center'>
                                        <p className='text-sm'>Valuasi Laporan yang ditutup</p>
                                    </div>
                                    <div className='flex gap-1'>
                                        <p className='text-sm'>:</p>
                                        <input
                                            disabled
                                            type="number"
                                            value={dataLM?.valuasi_laporan_yang_ditutup}
                                            className="bg-base-outline h-[25px] px-2 mx-2 text-sm border rounded-sm w-full"
                                            placeholder=''
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                    }

                    <div className='my-5 space-y-2'>
                        <h1 className="text-zinc-800 text-base font-semibold">SK Penetapan Inovasi</h1>
                        <div className='space-y-2'>
                            {data?.files?.length > 0 ? data.files.map((file, idx) => (
                                <div key={idx} className='flex items-center gap-10'>
                                    <div className='bg-[#CEDFEA] rounded-md flex gap-2 items-center p-2 text-xs'><HiPaperClip /> <span>{file?.originalname}</span></div>
                                    <a href={imageHandle(file?.file_path)} download={file?.file_name} target='_blank' className='text-blue-primer font-semibold text-sm flex gap-2'>
                                        <BiDownload className='text-xl' />
                                        <p>Unduh Dokumen</p>
                                    </a>
                                </div>
                            )) :
                                <div className='flex items-center gap-10'>
                                    <div className='bg-[#CEDFEA] rounded-md flex gap-2 items-center p-2 text-xs'>Tidak Ada SK yg diunggah</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='my-5 space-y-2'>
                        <div className='space-y-[5px]'>
                            <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Catatan ({data?.laporan?.namaPenilai1 ?? "-"})<span className='text-red-primer'>*</span></h1>
                            <textarea disabled value={data?.laporan?.harapanPenilai1 ?? "-"} cols={5} className='bg-base-outline text-zinc-700 text-xs font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' />
                        </div>
                        {data?.laporan?.penilai2Id ?
                            <div className='space-y-[5px]'>
                                <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Catatan ({data?.laporan?.namaPenilai2 ?? "-"})<span className='text-red-primer'>*</span></h1>
                                <textarea disabled value={data?.laporan?.harapanPenilai2 ?? "-"} cols={5} className='bg-base-outline text-zinc-700 text-xs font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' />
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default DetailLaporanSayaBulanan