import request from "./utils/request";

class Api {

    static urlAPI() {
        return process.env.REACT_APP_BACKEND_URL
        // return "https://staging.api.humanusia.id/api/v1/"
        // return "http://localhost:5000/api/v1/"
    }

    // Begin :: Auth
    static Login(email, password, captchaToken) {
        let path = 'auth/login';
        // let path = 'auth/login-captcha';
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data: {
                email,
                password,
                captchaToken
            },
        })
    }

    static ResetPassword(userId, token, data) {
        let path = `auth/reset-password/${userId}/${token}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
        })
    }

    static ForgotPassword(email) {
        let path = 'auth/forgot-password';
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data: {
                email
            },
        })
    }

    static Register(data) {
        let path = 'register';
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
        })
    }

    static Fetch(token) {
        let path = `auth/fetch`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostHelpdesk(token, data) {
        let path = `helpdesk`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
        })
    }
    static Helpdesk() {
        let path = `helpdesk`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET'
        })
    }

    static DeleteHelpdesk(token, id) {
        let path = `helpdesk/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static GetDevice(token, page, limit, search) {
        let path = `user-device?page=${page}&limit=${limit}&search=${search}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static DeleteDevice(token, id) {
        let path = `user-device/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetConfiguration(token) {
        let path = `configuration`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static UpdateConfiguration(token, id, data) {
        let path = `configuration/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static GetProvince(token, limit, page, name) {
        let path = `regional/provinces?limit=${limit}&page=${page}&search=${name}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static DropdownProvince(token, search) {
        let path = `dropdown/province`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static CreateProvince(token, data) {
        let path = `regional/provinces`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static UpdateProvince(token, data, id) {
        let path = `regional/provinces/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static DeleteProvince(token, id) {
        let path = `regional/provinces/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static GetDistrict(token, limit, page, name) {
        let path = `regional/regencies?limit=${limit}&page=${page}&search=${name}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static DropdownDistrict(token, provinceId) {
        let path = `dropdown/regency?provinceId=${provinceId ?? ""}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static CreateDistrict(token, data) {
        let path = `regional/regencies`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static UpdateDistrict(token, data, id) {
        let path = `regional/regencies/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static DeleteDistrict(token, id) {
        let path = `regional/regencies/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static GetSubdistrict(token, limit, page, name) {
        let path = `regional/districts?limit=${limit}&page=${page}&search=${name}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static CreateSubdistrict(token, data) {
        let path = `regional/districts`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static UpdateSubdistrict(token, data, id) {
        let path = `regional/districts/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static DeleteSubdistrict(token, id) {
        let path = `regional/districts/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static DropdownSubdistrict(token, regencyId) {
        let path = `dropdown/district?regencyId=${regencyId ?? ""}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    // static GetCountry(token) {
    //     let path = `api/regions/Indonesia/province`;
    //     return request(`${this.urlAPI()}${path}`, {
    //         method: 'GET',
    //         headers: {
    //             'Authorization': `Bearer ${token}`
    //         }
    //     })
    // }

    // Import

    static UploadJobManagement(token, data) {
        let path = `import/import-job-management`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
        })
    }
    static UploadEmployee(token, data) {
        let path = `import/import-employee`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
        })
    }
    static UploadKehadiran(token, data) {
        let path = `import/import-absensi`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
        })
    }
    static UploadCuti(token, data) {
        let path = `import/import-cuti`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
        })
    }
    static UploadJumlahCuti(token, data) {
        let path = `import/import-jumlah-cuti`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
        })
    }
    static UploadPenempatan(token, data) {
        let path = `import/import-penempatan`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
        })
    }

    // Dashboard
    static GetNews(token, title, page, limit) {
        let path = `news/get?title=${title}&page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetNewsDetail(token, id) {
        let path = `news/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static CreateNews(token, data) {
        let path = `news/create`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static UpdateNews(token, data, id) {
        let path = `news/update/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static DeleteNews(token, id) {
        let path = `news/delete/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static PostForum(token, data) {
        let path = `forum/create`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetForum(token) {
        let path = `forum/get`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetStatistict(token) {
        let path = `statistict/dashboard`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static GetStatistictRekapAbsen(token) {
        let path = `statistict/rekap-absensi`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static GetStatistictJenisKepegawaian(token) {
        let path = `statistict/jenis-kepegawaian`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static GetWidgetKinerjaSaya(token) {
        let path = `dashboard/widget-kinerja-saya`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetBirthdateByMonth(token) {
        let path = `birthday-user/get-birtday-user`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetNewEmployee(token) {
        let path = `dashboard/get-count`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetDropdownEmployee(token, company) {
        var path
        !company || company === "" || company === undefined ? (
            path = `report-to/dropdown-employee`
        ) : (
            path = `report-to/dropdown-employee?companyId=${company}`
        )
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    // Job Grade
    static GetJobGrade(token, page, limit, name) {
        let path = `job-grade/get?page=${page}&limit=${limit}&search=${name}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetJobGradeDropdown(token) {
        let path = `job-grade/dropdown`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetJobGradeByID(token, id) {
        let path = `job-grade/get-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static PostJobGrade(token, data) {
        let path = `job-grade/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static UpdateJobGrade(token, data, id) {
        let path = `job-grade/update-job-grade/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static DeleteJobGrade(token, id) {
        let path = `job-grade/delete-job-grade/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    // Job level
    static GetJobLevel(token, page, limit, name) {
        let path = `job-level/get?page=${page}&limit=${limit}&search=${name}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetJobLevelDropdown(token) {
        let path = `job-level/dropdown`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetJobLevelByID(token, id) {
        let path = `job-level/get-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static PostJobLevel(token, data) {
        let path = `job-level/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static UpdateJobLevel(token, data, id) {
        let path = `job-level/update-job-level/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static DeleteJobLevel(token, id) {
        let path = `job-level/delete-job-level/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static DeletePangkat(token, id) {
        let path = `job-level/pangkat/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }



    // Job level
    static GetJobSuperior(token) {
        let path = `job-position/get-superior`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    // Attendance

    static GetLaporanPresensi(token, userId, bulan, tahun) {
        let path = `attendence/laporan?userId=${userId}&bulan=${bulan}&tahun=${tahun}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetMyAttendance(token, startDate, endDate, page, limit) {
        let path = `attendence/get?start_date=${startDate}&end_date=${endDate}&page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetRekapAbsenAttandance(token, month, year, limit, page, unitKerja, search) {
        let path = `attendence/rekap-absen?bulan=${month}&tahun=${year}&page=${page}&limit=${limit}&search=${search}&unitKerjaEselon=${unitKerja}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetKehadiran(token, data) {
        let path = `dashboard/rekap-kehadiran`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static GetCuti(token, page, limit, strat_date, end_date, name, isAsc) {
        let path = `dashboard/absensi/cuti?page=${page}&limit=${limit}&start_date=${strat_date}&end_date=${end_date}&search=${name}&isAsc=${isAsc}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static GetIzin(token, page, limit, strat_date, end_date, name, isAsc) {
        let path = `dashboard/absensi/izin?page=${page}&limit=${limit}&start_date=${strat_date}&end_date=${end_date}&search=${name}&isAsc=${isAsc}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static GetPerjalananDinas(token, page, limit, strat_date, end_date, name, isAsc) {
        let path = `dashboard/absensi/perjalanan-dinas?page=${page}&limit=${limit}&start_date=${strat_date}&end_date=${end_date}&search=${name}&isAsc=${isAsc}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static GetPelatihan(token, page, limit, strat_date, end_date, name, isAsc) {
        let path = `dashboard/pelatihan?page=${page}&limit=${limit}&start_date=${strat_date}&end_date=${end_date}&search=${name}&isAsc=${isAsc}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static GetPenghargaanDashboard(token, page, limit, strat_date, end_date, name, isAsc) {
        let path = `dashboard/penghargaan?page=${page}&limit=${limit}&start_date=${strat_date}&end_date=${end_date}&search=${name}&isAsc=${isAsc}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static GetTugasBelajarDashboard(token, page, limit, strat_date, end_date, name, isAsc) {
        let path = `dashboard/tugas-belajar?page=${page}&limit=${limit}&start_date=${strat_date}&end_date=${end_date}&search=${name}&isAsc=${isAsc}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetMyAttendanceById(token, id) {
        let path = `attendence/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static GetMyAttendanceRecord(token) {
        let path = `attendence/get`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static GetSyncAttendance(token, date) {
        let path = `attendence/sync?tanggal=${date}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static PostPresensiManual(token, data) {
        let path = `attendence/presensi-manual`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
        })
    }
    static GetPresensiManual(token, page, limit, startDate, endDate) {
        let path = `attendence/presensi-manual?page=${page}&limit=${limit}&start_date=${startDate}&end_date=${endDate}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static GetPresensiManualById(token, id) {
        let path = `attendence/presensi-manual/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static DeletePresensiManual(token, id) {
        let path = `attendence/presensi-manual/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static PostPassphrasePresensiManual(token, id, data) {
        let path = `attendence/presensi-manual/${id}/passphrase`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostMyAttendanceCheckIn(token, data) {
        let path = `attendence/checkin`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static PostMyAttendanceCheckOut(token, data) {
        let path = `attendence/checkout`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static PostCheckinLembur(token, id, data) {
        let path = `overtime/${id}/checkin`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static PostCheckoutLembur(token, id, data) {
        let path = `overtime/${id}/checkout`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static PostMyAttendance(token, data) {
        let path = `attendence/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetAttendanceRecord(token, startDate, endDate, fullname, limit, page, company, unitKerjaEselon, isAsc, jobCategoryId) {
        let path = `attendencerecord/get?start_date=${startDate}&end_date=${endDate}&fullname=${fullname}&limit=${limit}&page=${page}&companyId=${company}&unitKerjaEselon=${unitKerjaEselon}&isAsc=${isAsc}&jobCategoryId=${jobCategoryId}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetAttendanceRecordById(token, id) {
        let path = `attendencerecord/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetJobPosition(token, page, limit, name) {
        let path = `job-position/get?page=${page}&limit=${limit}&search=${name}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetJobPositionDropdown(token, unitKerja) {
        var path
        !unitKerja || unitKerja === "" || unitKerja === undefined ? (
            path = `job-position/dropdown`
        ) : (
            path = `job-position/dropdown?unitKerjaId=${unitKerja}`
        )
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetJobPositionByID(token, id) {
        let path = `job-position/get-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static PostJobPosition(token, data) {
        let path = `job-position/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static UpdateJobPosition(token, data, id) {
        let path = `job-position/update-job-position/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }


    // Leave

    static getLeave(token, startDate, endDate, page, limit) {
        let path = `leave/get?start_date=${startDate}&end_date=${endDate}&page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static getDurasiCuti(token, startDate, endDate, userId) {
        let path
        userId ?
            path = `leave/durasi-cuti?start_date=${startDate}&end_date=${endDate}&userId=${userId}`
            : path = `leave/durasi-cuti?start_date=${startDate}&end_date=${endDate}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static getLeaveByAdmin(token, startDate, endDate, fullname, page, limit, company, unitKerja) {
        let path = `leave/get-leave-admin?start_date=${startDate}&end_date=${endDate}&fullname=${fullname}&page=${page}&limit=${limit}&companyId=${company}&unitKerjaId=${unitKerja}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static DeleteJobPosition(token, id) {
        let path = `job-position/delete-job-position/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static getPembatalanCutiById(token, id) {
        let path = `leave/pembatalan/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static getLeaveById(token, id) {
        let path = `leave/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static getExportLeave(token, id) {
        let path = `leave/${id}/export`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static getDetailApprovalLeave(token, id) {
        let path = `leave/${id}/list-approval`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static DeleteLeave(token, id) {
        let path = `leave/delete-leave/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static CancelLeave(token, id, data) {
        // let path = `leave/cancel-leave/${id}`;
        let path = `leave/${id}/pembatalan`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static PostLeave(token, data) {
        let path = `leave/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static UpdateLeave(token, data, id) {
        let path = `leave/update-leave/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static PostPassphraseCuti(token, id, data) {
        let path = `leave/${id}/passphrase`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetRekapCuti(token, page, limit, tahun, search) {
        let path = `leavetype/penampung-cuti?page=${page}&limit=${limit}&tahun=${tahun}&search=${search}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static GetRekapCutiById(token, page, limit, tahun) {
        let path = `leavetype/penampung-cuti-user?page=${page}&limit=${limit}&tahun=${tahun}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }


    // LeaveType

    static getLeaveType(token, page, limit) {
        let path = `leavetype/get?page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static getLeaveTypeById(token, id) {
        let path = `leavetype/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static getSisaCuti(token, id) {
        let path = `leavetype/${id}/sisa-cuti`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static getSisaCutiUserId(token, id, userId) {
        let path = `leavetype/${id}/sisa-cuti?userId=${userId}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static DeleteLeaveType(token, id) {
        let path = `leavetype/delete-leave-setting/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static PostLeaveType(token, data) {
        let path = `leavetype/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }



    // CAlendar
    static GetCalendar(token) {
        let path = `calendar`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    // Event
    static GetEvent(token) {
        let path = `event/get`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static GetEventById(token, id) {
        let path = `event/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static PostEvent(token, data) {
        let path = `event/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static DeleteEvent(token, id) {
        let path = `event/delete-event/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static UpdateEvent(token, data, id) {
        let path = `event/update-event/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    // My Todo
    static GetTodo(token) {
        let path = `mytodo/get`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static GetTodoById(token, id) {
        let path = `mytodo/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static PostTodo(token, data) {
        let path = `mytodo/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static DeleteTodo(token, id) {
        let path = `mytodo/delete-mytodo/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static UpdateTodo(token, data, id) {
        let path = `mytodo/update-mytodo/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }



    //Schedule

    static GetSchedule(token, date, name, page, limit) {
        let path = `schedule/get?dateof_filling=${date}&fullname=${name}&page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static GetScheduleById(token, id) {
        let path = `schedule/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static DeleteSchedule(token, id) {
        let path = `schedule/delete-schedule/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static PostSchedule(token, data) {
        let path = `schedule/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static UpdateSchedule(token, data, id) {
        let path = `schedule/update-schedule/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }



    // Pengaturan Persetujuan
    static GetApprovalSetting(token, page, limit, search, jobGrade, jobLevel, jobPosition, jobCategory, company, unitKerja) {
        let path = `pengaturan-persetujuan?page=${page}&limit=${limit}&search=${search}&jobGradeId=${jobGrade}&jobLevelId=${jobLevel}&jobPositionId=${jobPosition}&jobCategoryId=${jobCategory}&companyId=${company}&unitKerjaId=${unitKerja}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static GetApprovalSettingById(token, id) {
        let path = `pengaturan-persetujuan/get-by-userid/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static UpdateApprovalSetting(token, menu, data) {
        let path = `pengaturan-persetujuan/${menu}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    // Hak Akses
    static GetHakAkses(token, page, limit, name) {
        let path = `hak-akses?page=${page}&limit=${limit}&search=${name}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static GetHakAksesById(token, id) {
        let path = `hak-akses/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static UpdateHakAkses(token, id, data) {
        let path = `hak-akses/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    // User Management 
    static GetUserManagement(token, page, limit, name) {
        let path = `user-manage/get?page=${page}&pageSize=${limit}&fullname=${name}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetUserManagementById(token, id) {
        let path = `user-manage/get-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static CreateUserManagement(token, data) {
        let path = `user-manage/create`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            data,
        })
    }

    static UpdateUserManagement(token, id, data) {
        let path = `user-manage/update/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            data,
        })
    }

    static DeleteUserManagement(token, id) {
        let path = `user-manage/delete/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    // Package

    static GetAllPackage(token) {
        let path = `package/get`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    // Employee Status
    static GetEmployeeStatus(token) {
        let path = `employee-status/get`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetEmployeeStatusById(token, id) {
        let path = `employee-status/get-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static CreateEmployeeStatus(token, data) {
        let path = `employee-status/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static UpdateEmployeeStatus(token, data, id) {
        let path = `employee-status/update-employee-status/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static DeleteEmployeeStatus(token, id) {
        let path = `employee-status/delete-employee-status/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static UpdateLeaveType(token, data, id) {
        let path = `leavetype/update/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    // Work Shift
    static GetWorkShift(token) {
        let path = `work-shift/get`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetWorkShiftById(token, id) {
        let path = `work-shift/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static CreateWorkShift(token, data) {
        let path = `work-shift/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static UpdateWorkShift(token, data, id) {
        let path = `work-shift/update-work-shift/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static DeleteWorkShift(token, id) {
        let path = `work-shift/delete-work-shift/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    // Cost And Profit
    static GetCost(token, page, limit) {
        let path = `cost/get?page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetCostById(token, id) {
        let path = `cost/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static CreateCost(token, data) {
        let path = `cost/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static UpdateCost(token, data, id) {
        let path = `cost/update-cost/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static DeleteCost(token, id) {
        let path = `cost/delete-cost/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetProfit(token, page, limit) {
        let path = `profit/get?page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetProfitById(token, id) {
        let path = `profit/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static CreateProfit(token, data) {
        let path = `profit/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static UpdateProfit(token, data, id) {
        let path = `profit/update-profit/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static DeleteProfit(token, id) {
        let path = `profit/delete-profit/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    // Get Country
    static GetCountry() {
        let path = `api/countries`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET'
        })
    }

    // General Information
    static GetGeneralInformation(token) {
        let path = `company/get-company-id`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static UpdateGeneralInformation(token, data, id) {
        let path = `company/update-company/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
        })
    }

    // Permission
    static GetPermission(token, startDate, endDate, page, limit) {
        let path = `permission/get?start_date=${startDate}&end_date=${endDate}&page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static getDurasiIzin(token, startDate, endDate, userId) {
        let path = `permission/durasi-izin?start_date=${startDate}&end_date=${endDate}&userId=${userId}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static GetPermissionByAdmin(token, startDate, endDate, fullname, limit, page, company, unitKerja) {
        let path = `permission/get-permission-admin?start_date=${startDate}&end_date=${endDate}&fullname=${fullname}&page=${page}&limit=${limit}&companyId=${company}&unitKerjaId=${unitKerja}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static GetPermissionById(token, id) {
        let path = `permission/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static GetExportPermission(token, id) {
        let path = `permission/${id}/export`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static getDetailApprovalPermission(token, id) {
        let path = `permission/${id}/list-approval`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static PostPermission(token, data) {
        let path = `permission/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static UpdatePermission(token, data, id) {
        let path = `permission/update-permission/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static DeletePermission(token, id) {
        let path = `permission/delete-permission/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static PostPassphrasePermission(token, id, data) {
        let path = `permission/${id}/passphrase`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Overtime
    static GetOvertime(token, startDate, endDate, page, limit) {
        let path = `overtime/get?start_date=${startDate}&end_date=${endDate}&page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static GetOvertimeByAdmin(token, startDate, endDate, fullname, page, limit, company, unitKerja) {
        let path = `overtime/get-overtime-admin?start_date=${startDate}&end_date=${endDate}&fullname=${fullname}&page=${page}&limit=${limit}&companyId=${company}&unitKerjaId=${unitKerja}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static GetEditOvertimeById(token, id) {
        let path = `overtime/edit/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static GetOvertimeById(token, id) {
        let path = `overtime/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static getDetailApprovalOvertime(token, id) {
        let path = `overtime/${id}/list-approval`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static PostOvertime(token, data) {
        let path = `overtime/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static UpdateOvertime(token, data, id) {
        let path = `overtime/update-overtime/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static DeleteOvertime(token, id) {
        let path = `overtime/delete-overtime/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static GetJobDetail(token, id) {
        let path = `profile/get-job/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static GetJobLogin(token) {
        let path = `profile/get-job-login`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static UpdateJobDetail(token, data, id) {
        let path = `profile/post-job/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    //MyProfile-Report to
    static GetAllReportTo(token) {
        let path = `report-to/get-all`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static GetReportToById(token, id) {
        let path = `report-to/get-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static GetReportToSupervisorByLogin(token) {
        let path = `report-to/get`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })

    }

    static GetReportToSupervisorByParam(token, id) {
        let path = `report-to/get-report-to/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })

    }

    static GetReportToSupervisorById(token, id) {
        let path = `report-to/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })

    }

    static GetReportMethod(token) {
        let path = `report-method/get`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static GetReportMethodByParam(token, id) {
        let path = `report-method/get-report/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static PostReportToSupervisor(token, data, id) {
        let path = `report-to/post-supervisor/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static UpdateReportToSupervisor(token, data, id) {
        let path = `report-to/update-report-to/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static DeleteReportToSupervisor(token, id) {
        let path = `report-to/delete-report-to/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    // PTKP
    static GetPtkpStatus(token) {
        let path = `payroll/get-ptkpstatus`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    // Payroll
    static GetPayroll(token) {
        let path = `payroll/get-payroll`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static GetPayrollById(token, id) {
        let path = `payroll/get-payroll-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }


    // Payroll - Myprofile

    static GetPayrollMyProfileByLogin(token) {
        let path = `profile/get-myprofile-payroll`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static GetPayrollMyProfileByParam(token, id) {
        let path = `profile/get-myprofile-payroll/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })

    }

    static PostPayrollMyProfile(token, data, id) {
        let path = `profile/post-myprofile-payroll/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    // Income
    static GetIncome(token) {
        let path = `payroll/get-income`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static GetIncomeById(token, id) {
        let path = `payroll/get-income-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static PostIncome(token, data) {
        let path = `payroll/post-income`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static UpdateIncome(token, data, id) {
        let path = `payroll/update-income/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static DeleteIncome(token, id) {
        let path = `payroll/delete-income/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    // Deduction
    static GetDeduction(token) {
        let path = `payroll/get-deduction`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static GetDeductionById(token, id) {
        let path = `payroll/get-deduction-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static PostDeduction(token, data) {
        let path = `payroll/post-deduction`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static UpdateDeduction(token, data, id) {
        let path = `payroll/update-deduction/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static DeleteDeduction(token, id) {
        let path = `payroll/delete-deduction/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    // Benefit
    static GetBenefit(token) {
        let path = `payroll/get-benefit`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static GetBenefitById(token, id) {
        let path = `payroll/get-benefit-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static PostBenefit(token, data) {
        let path = `payroll/post-benefit`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static UpdateBenefit(token, data, id) {
        let path = `payroll/update-benefit/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static DeleteBenefit(token, id) {
        let path = `payroll/delete-benefit/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }


    // JKK
    static GetJKK(token) {
        let path = `payroll/get-jkk-bpjs`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static GetJKKById(token, id) {
        let path = `payroll/get-jkk-bpjs-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static PostJKK(token, data) {
        let path = `payroll/post-jkk-bpjs`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static UpdateJKK(token, data, id) {
        let path = `payroll/update-jkk-bpjs/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static DeleteJKK(token, id) {
        let path = `payroll/delete-jkk-bpjs/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }


    // Termination Reason
    static GetTermination(token) {
        let path = `termination-reason/get`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetTerminationById(token, id) {
        let path = `termination-reason/get-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static CreateTermination(token, data) {
        let path = `termination-reason/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static UpdateTermination(token, data, id) {
        let path = `termination-reason/update-termination/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static DeleteTermination(token, id) {
        let path = `termination-reason/delete-termination/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    // Get Employee

    static getAllEmployee(token) {
        let path = `employe/get-new`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static GetTemplateJob(token) {
        let path = `import/template-job-management`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static GetTemplateEmployee(token) {
        let path = `import/template-employee`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static GetTemplatePenempatan(token) {
        let path = `import/template-penempatan`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static GetTemplateKehadiran(token) {
        let path = `import/template-absensi`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static GetTemplateSisaCuti(token) {
        let path = `import/template-cuti`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static GetTemplateJumlahCuti(token) {
        let path = `import/template-jumlah-cuti`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static GetAllEmployeeById(token, id) {
        let path = `employe/Get-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    // get employee by job position
    static GetEmployeeDropdown(token, id) {
        let path = `report-to/dropdown?jobPosition=${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    // Employee
    static GetEmployee(token, page, limit, name, penempatan, unitKerja, kelasJabatan, jenisJabatan, jenisKepegawaian, jabatan) {
        let path = `employe/get-new?limit=${limit}&page=${page}&fullname=${name}&companyId=${penempatan}&jobGrade=${kelasJabatan}&jobLevel=${jenisJabatan}&jobPosition=${jabatan}&jobCategory=${jenisKepegawaian}&unitKerjaEselon=${unitKerja}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static GetEmployeeById(token, id) {
        let path = `employe/get-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static CreateEmployee(token, data) {
        let path = `employe/post-new`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static CreateEmployee2(token, data) {
        let path = `employe/post-new-2`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static UpdateEmployee(token, data, id) {
        let path = `employe/update-employee-new/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static DataWFH(token, data) {
        let path = `employe/toggle-wfh`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static DeleteEmployee(token, id) {
        let path = `employe/delete-employe-new/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    // Employee Transfer
    static GetEmployeeTransfer(token) {
        let path = `employee-transfer/get`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static CreateEmployeeTransfer(token, data) {
        let path = `employee-transfer/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // get data employee for report to
    static GetEmployeeReportTo(token) {
        let path = `report-to/dropdown`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    static GetEmployeeReportToUserId(token, userId) {
        let path = `report-to/dropdown?userId=${userId}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }
    // get data employee for report to
    static GetDropdownPejabatPenilai(token) {
        let path = `rencana-hasil-kerja/dropdown-penilai-kinerja`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    // Finance Reimburstment Setting
    static GetFinanceReimbursmentSetting(token, page, limit) {
        let path = `finance/get-rb-setting?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetFinanceReimbursmentSettingById(token, id) {
        let path = `finance/get-rb-setting-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static CreateFinanceReimbursmentSetting(token, data) {
        let path = `finance/post-rb-setting`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateFinanceReimbursmentSetting(token, data, id) {
        let path = `finance/update-rb-setting/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteFinanceReimbursmentSetting(token, id) {
        let path = `finance/delete-rb-setting/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Finance Reimburstment
    static GetFinanceReimbursment(token, name, page, limit) {
        let path = `finance/get-reimburshment?search=${name}&page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetFinanceReimbursmentById(token, id) {
        let path = `finance/get-reimburshment-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static CreateFinanceReimbursment(token, data) {
        let path = `finance/post-reimburshment`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateFinanceReimbursment(token, data, id) {
        let path = `finance/update-reimburshment/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteFinanceReimbursment(token, id) {
        let path = `finance/delete-reimburshment/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Finance Cash Advance Setting
    static GetFinanceCashAdvanceSetting(token, page, limit) {
        let path = `finance/get-ca-setting?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetFinanceCashAdvanceSettingById(token, id) {
        let path = `finance/get-ca-setting-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static CreateFinanceCashAdvanceSetting(token, data) {
        let path = `finance/post-ca-setting`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateFinanceCashAdvanceSetting(token, data, id) {
        let path = `finance/update-ca-setting/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteFinanceCashAdvanceSetting(token, id) {
        let path = `finance/delete-ca-setting/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Finance Cash Advance
    static GetFinanceCashAdvance(token, name, page, limit) {
        let path = `finance/get-cashadvance?search=${name}&page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetFinanceCashAdvanceById(token, id) {
        let path = `finance/get-cashadvance-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static CreateFinanceCashAdvance(token, data) {
        let path = `finance/post-cashadvance`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateFinanceCashAdvance(token, data, id) {
        let path = `finance/update-cashadvance/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteFinanceCashAdvance(token, id) {
        let path = `finance/delete-cashadvance/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Finance Loan Setting
    static GetFinanceLoanSetting(token, page, limit) {
        let path = `finance/get-loan-setting?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetFinanceLoanSettingById(token, id) {
        let path = `finance/get-loan-setting-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static CreateFinanceLoanSetting(token, data) {
        let path = `finance/post-loan-setting`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateFinanceLoanSetting(token, data, id) {
        let path = `finance/update-loan-setting/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteFinanceLoanSetting(token, id) {
        let path = `finance/delete-loan-setting/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Finance Loan
    static GetFinanceLoan(token, name, page, limit) {
        let path = `finance/get-loan?search=${name}&page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetFinanceLoanById(token, id) {
        let path = `finance/get-loan-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static CreateFinanceLoan(token, data) {
        let path = `finance/post-loan`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateFinanceLoan(token, data, id) {
        let path = `finance/update-loan/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteFinanceLoan(token, id) {
        let path = `finance/delete-loan/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Finance Loan Installment
    static GetLoanInstallment(token) {
        let path = `finance/get-loan-installment`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetLoanInstallmentById(token, id) {
        let path = `finance/get-loan-installment-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static CreateLoanInstallment(token, data) {
        let path = `finance/post-loan-installment`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateLoanInstallment(token, data, id) {
        let path = `finance/update-loan-installment/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteLoanInstallment(token, id) {
        let path = `finance/delete-loan-installment/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Recruitment

    static GetRecruitment(token, title, page, limit) {
        let path = `recruitment/get?title=${title}&page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetRecruitmentById(token, id) {
        let path = `recruitment/get-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static CreateRecruitment(token, data) {
        let path = `recruitment/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateRecruitment(token, data, id) {
        let path = `recruitment/update-recruitment/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteRecruitment(token, id) {
        let path = `recruitment/delete-recruitment/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Recruitment Entry Application

    static GetRecruitmentApplicant(token) {
        let path = `entry-aplicant/get`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetRecruitmentApplicantById(token, id) {
        let path = `entry-aplicant/get/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static CreateRecruitmentApplicant(token, data) {
        let path = `entry-aplicant/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Recruitment Stage
    static GetRecruitmentStage(token) {
        let path = `recruitment-stage/get`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetRecruitmentStageById(token, id) {
        let path = `recruitment-stage/get/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostRecruitmentStage(token, data) {
        let path = `recruitment-stage/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostEndRecruitment(token, data, id) {
        let path = `recruitment-stage/edit-status-recruitment-stage-applicant/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateRecruitmentStage(token, data, id) {
        let path = `recruitment-stage/edit-recruitment-stage/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Document

    static GetDocument(token, title) {
        let path = `document/get?title=${title}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetDocumentById(token, id) {
        let path = `document/get/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetDocumentResponden(token, id) {
        let path = `document/get-document-answer-by-documentId/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetDocumentRespondenDetail(token, id, documentId) {
        let path = `document/get-document-answer-by-id/${id}/${documentId}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }



    static CreateDocument(token, data) {
        let path = `document/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static CreateDocumentAnswer(token, data) {
        let path = `document/post-documentAnswer`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateDocument(token, data, id) {
        let path = `document/update-document/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteDocument(token, id) {
        let path = `document/delete-document/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // My Profile
    static GetPersonalDetail(token) {
        let path = `profile/get`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetPersonalDetailById(token, id) {
        let path = `profile/get-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostPersonalDetail(token, data, id) {
        let path = `profile/post/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static ChangePassword(token, data) {
        let path = `profile/change-password`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static ChangePasswordByAdmin(token, data) {
        let path = `user-manage/change-user-password`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Inbox

    static GetInbox(token, query, page, limit, isApproval, inboxType) {
        let path = `inbox/get-all?search=${query}&page=${page}&limit=${limit}&isApproval=${isApproval}&inboxType=${inboxType}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetInboxCounter(token) {
        let path = `/inbox/counter`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetInboxSuperior(token) {
        let path = `inbox/get-superior`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostInboxApprovallReimbursment(token, data, id) {
        let path = `approval/post-approval-reimburshment/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static PostInboxApprovallLoan(token, data, id) {
        let path = `approval/post-approval-loan/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static PostInboxApprovallCashAdvance(token, data, id) {
        let path = `approval/post-approval-cashadvance/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static PostInboxApprovallLeave(token, data, id) {
        let path = `approval/post-approval-leave/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static PostInboxApprovallPermission(token, data, id) {
        let path = `approval/post-approval-permission/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static PostInboxApprovallOvertime(token, data, id) {
        let path = `approval/post-approval-overtime/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static PostInboxApprovallWorkTravel(token, data, id) {
        let path = `approval/post-approval-workTravel/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static PostInboxApprovallPresensiManual(token, data, id) {
        let path = `approval/presensi-manual/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static PostInboxApprovallPembatalanCuti(token, data, id) {
        let path = `approval/pembatalan-cuti/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static DeleteInbox(token, data) {
        let path = `inbox/delete`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static UpdateStatusReadInbox(token, data) {
        let path = `inbox/readStatus`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }


    // Emergency Contact
    static GetEmergencyContactByLogin(token, page, limit) {
        let path = `profile/get-emergency-contact-login?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetEmergencyContact(token) {
        let path = `profile/get-emergency-contact-login`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetEmergencyContactByParam(token, param) {
        let path = `profile/get-emergency-contact/${param}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetEmergencyContactById(token, id) {
        let path = `profile/get-emergency-contact-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostEmergencyContact(token, data, id) {
        let path = `profile/post-emergency-contact/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteEmergencyContact(token, id) {
        let path = `profile/delete-emergency-contact/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateEmergencyContact(token, data, id, paramUser) {
        let path = `profile/update-emergency-contact/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Dependents

    static GetDependentsByLogin(token, page, limit) {
        let path = `profile/get-dependent-login?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetDependentsByParams(token, params, page, limit) {
        let path = `profile/get-dependent/${params}?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetDependentsById(token, id) {
        let path = `profile/get-dependent-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostDependents(token, data, id) {
        let path = `profile/post-dependent/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteDependents(token, id) {
        let path = `profile/delete-dependent/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateDependents(token, data, id) {
        let path = `profile/update-dependent/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Immigration

    static GetImmigrationByLogin(token, page, limit) {
        let path = `profile/get-immigration-login?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetImmigrationById(token, id) {
        let path = `profile/get-immigration-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetImmigrationByParam(token, param, page, limit) {
        let path = `profile/get-immigration/${param}?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostImmigration(token, data, id) {
        let path = `profile/post-immigration/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteImmigration(token, id) {
        let path = `profile/delete-immigration/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateImmigration(token, data, id) {
        let path = `profile/update-immigration/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Job
    static GetJobDetailByLogin(token, id) {
        let path = `profile/get-myprofile-job/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostJobDetail(token, data, id) {
        let path = `profile/get-myprofile-job/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }


    // Qualification

    // Work Experience
    static GetWorkExperienceByLogin(token, page, limit) {
        let path = `profile/get-workexperience-login?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkExperienceById(token, id) {
        let path = `profile/get-workexperience-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkExperienceByParam(token, id, page, limit) {
        let path = `profile/get-workexperience/${id}?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostWorkExperience(token, data, id) {
        let path = `profile/post-workexperience/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteWorkExperience(token, id) {
        let path = `profile/delete-workexperience/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateWorkExperience(token, data, id) {
        let path = `profile/update-workexperience/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Work Education
    static PostApprovalEducation(token, id, data) {
        let path = `approval/work-education/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static ApprovalEducation(token, page, limit) {
        let path = `approval/work-education?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static SetPendidikanTerakhir(token, id) {
        let path = `profile/set-pendidikan-tertinggi/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static ClaimPendidikan(token, workEducationId, laporanPeriodeId) {
        let path = `profile/claim-pendidikan/${workEducationId}?laporanPeriodeId=${laporanPeriodeId}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetWorkEducation(token, page, limit) {
        let path = `profile/get-workeducation-login?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkEducationById(token, id) {
        let path = `profile/get-workeducation-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkEducationByParam(token, id, page, limit) {
        let path = `profile/get-workeducation/${id}?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostWorkEducation(token, data, id) {
        let path = `profile/post-workeducation/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    static DeleteWorkEducation(token, id) {
        let path = `profile/delete-workeducation/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteFileIjazah(token, id) {
        let path = `profile/delete-file-workeducation/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateWorkEducation(token, data, id) {
        let path = `profile/update-workeducation/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Work Skill
    static GetWorkSkill(token, page, limit) {
        let path = `profile/get-workskill-login?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkSkillById(token, id) {
        let path = `profile/get-workskill-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkSkillByParam(token, id, page, limit) {
        let path = `profile/get-workskill/${id}?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostWorkSkill(token, data, id) {
        let path = `profile/post-workskill/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteWorkSkill(token, id) {
        let path = `profile/delete-workskill/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateWorkSkill(token, data, id) {
        let path = `profile/update-workskill/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Work Language
    static GetWorkLanguage(token, page, limit) {
        let path = `profile/get-worklanguage-login?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkLanguageById(token, id) {
        let path = `profile/get-worklanguage-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkLanguageByParam(token, id, page, limit) {
        let path = `profile/get-worklanguage/${id}?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostWorkLanguage(token, data, id) {
        let path = `profile/post-worklanguage/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteWorkLanguage(token, id) {
        let path = `profile/delete-worklanguage/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateWorkLanguage(token, data, id) {
        let path = `profile/update-worklanguage/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Work License
    static GetWorkLicense(token, page, limit) {
        let path = `profile/get-worklicense-login?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkLicenseById(token, id) {
        let path = `profile/get-worklicense-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkLicenseByParam(token, id, page, limit) {
        let path = `profile/get-worklicense/${id}?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostWorkLicense(token, data, id) {
        let path = `profile/post-worklicense/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteWorkLicense(token, id) {
        let path = `profile/delete-worklicense/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateWorkLicense(token, data, id) {
        let path = `profile/update-worklicense/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // File
    static GetFileProfile(token, userId, type, page, limit, search) {
        let path = `profile/file?userId=${userId}&type=${type}&page=${page}&limit=${limit}&search=${search}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetFileByLoginByParam(token, id) {
        let path = `profile/get-file/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteFile(token, id) {
        let path = `profile/file/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostFile(token, data) {
        let path = `profile/file`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Geo Location
    static PostGeoLocation(token, data) {
        let path = `geo-location/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetGeoLocation(token, limit, page, search) {
        let path = `geo-location/get?limit=${limit}&page=${page}&search=${search}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetGeoLocationById(token, id) {
        let path = `geo-location/get-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetGeoLocationByUser(token) {
        let path = `geo-location/get-by-user`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static UpdateGeoLocation(token, data, id) {
        let path = `geo-location/update/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteGeoLocation(token, id) {
        let path = `geo-location/delete/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Set Time
    static GetSetTime(token) {
        let path = `set-time/get-set-time`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static CreateSetTime(token, data) {
        let path = `set-time/create-set-time`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Hari Libur
    static PostHariLibur(token, data) {
        let path = `calendar/hari-libur`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static UpdateHariLibur(token, data, id) {
        let path = `calendar/hari-libur/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetHariLibur(token, page, limit) {
        let path = `calendar/hari-libur?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetHariLiburById(token, id) {
        let path = `calendar/hari-libur/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteHariLibur(token, id) {
        let path = `calendar/hari-libur/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Set Time Normal
    static PostSetTimeNormal(token, data) {
        let path = `set-time/normal`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static UpdateSetTimeNormal(token, data, id) {
        let path = `set-time/normal/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetSetTimeNormal(token, page, limit) {
        let path = `set-time/normal?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetSetTimeNormalId(token, id) {
        let path = `set-time/normal/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteSetTimeNormal(token, id) {
        let path = `set-time/normal/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    // Set Time Periode Khusus
    static PostPeriodeKhusus(token, data) {
        let path = `set-time/khusus/periode`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static UpdatePeriodeKhusus(token, data, id) {
        let path = `set-time/khusus/periode/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetPeriode(token) {
        let path = `set-time/khusus/periode`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetPeriodeById(token, id) {
        let path = `set-time/khusus/periode/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeletePeriode(token, id) {
        let path = `set-time/khusus/periode/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    // Set Time Khusus
    static PostSetTimeKhusus(token, data) {
        let path = `set-time/khusus`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static UpdateSetTimeKhusus(token, data, id) {
        let path = `set-time/khusus/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetSetTimeKhusus(token, periodeId) {
        let path = `set-time/khusus?setTimePeriodeId=${periodeId}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetSetTimeKhususById(token, id) {
        let path = `set-time/khusus/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteSetTimeKhusus(token, id) {
        let path = `set-time/khusus/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Subsidiary
    static GetSubsidiary(token, page, limit) {
        let path = `subsidiary/get?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetSubsidiaryDropdown(token) {
        let path = `subsidiary/dropdown`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetSubsidiaryById(token, id) {
        let path = `subsidiary/get/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateSubsidiary(token, data, id) {
        let path = `subsidiary/update-subsidiary/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    static PostSubsidiary(token, data) {
        let path = `subsidiary/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    static DeleteSubsidiary(token, id) {
        let path = `subsidiary/delete-subsidiary/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetTotalEmployee(token, param) {
        let path = `subsidiary/get-user-total/${param}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Training
    static CreateTraining(token, data) {
        let path = `pelatihan`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateTraining(token, data, id) {
        let path = `pelatihan/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteTraining(token, id) {
        let path = `pelatihan/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetTotalTraining(token, userId) {
        let path = `pelatihan/jumlah-jam?userId=${userId}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetTrainingByUserId(token, userId, page, limit) {
        let path = `pelatihan?userId=${userId}&page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetTrainingById(token, id) {
        let path = `pelatihan/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetTrainingByLogin(token) {
        let path = `training/get-by-login`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Penghargaan
    static GetPenghargaan(token, userId, page, limit) {
        let path = `penghargaan?userId=${userId}&page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetPenghargaanById(token, id) {
        let path = `penghargaan/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static CreatePengahargaan(token, data) {
        let path = `penghargaan`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static EditPengahargaan(token, data, id) {
        let path = `penghargaan/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeletePengahargaan(token, id) {
        let path = `penghargaan/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Tugas Belajar
    static GetTugasBelajar(token, userId, page, limit) {
        let path = `tugas-belajar?userId=${userId}&page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetTugasBelajarById(token, id) {
        let path = `tugas-belajar/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static CreateTugasBelajar(token, data) {
        let path = `tugas-belajar`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static EditTugasBelajar(token, data, id) {
        let path = `tugas-belajar/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteTugasBelajar(token, id) {
        let path = `tugas-belajar/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Official Work Travel Setting

    static CreateWorkTravelSetting(token, data) {
        let path = `work-travel/create`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateWorkTravelSetting(token, data, id) {
        let path = `work-travel/update/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkTrravelSettingById(token, id) {
        let path = `work-travel/get-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetWorkTravelSetting(token) {
        let path = `work-travel/get`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteWorkTravelSetting(token, id) {
        let path = `work-travel/delete/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Unit Kerja
    static CreateUnitKerja(token, data) {
        let path = `unit-kerja`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateUnitKerja(token, data, id) {
        let path = `unit-kerja/update/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetUnitKerjaById(token, id) {
        let path = `unit-kerja/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetUnitKerja(token, page, limit, name) {
        let path = `unit-kerja?page=${page}&limit=${limit}&search=${name}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetUnitKerjaDropdown(token, company) {
        var path
        !company || company === "" || company === undefined ? (
            path = `pengaturan-pengajuan/dropdown-unit-kerja`
        ) : (
            path = `pengaturan-pengajuan/dropdown-unit-kerja?companyId=${company}`
        )
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetUnitKerjaUnique(token) {
        let path = `dropdown/unit-kerja`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetUnitKerjaEselon(token) {
        let path = `dropdown/unit-kerja-eselon`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }



    static DeleteUnitKerja(token, id) {
        let path = `unit-kerja/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Job Category
    static CreateJobCategory(token, data) {
        let path = `job-category/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateJobCategory(token, data, id) {
        let path = `job-category/update-job-category/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetJobCategoryById(token, id) {
        let path = `job-category/get/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetJobCategory(token, page, limit, name) {
        let path = `job-category/get?page=${page}&limit=${limit}&search=${name}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetJobCategoryDropdown(token) {
        let path = `job-category/dropdown`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteJobCategory(token, id) {
        let path = `job-category/delete-job-category/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteGolongan(token, id) {
        let path = `job-category/golongan/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Polling 
    static CreatePolling(token, data) {
        let path = `job-category/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdatePolling(token, data, id) {
        let path = `job-category/update-job-category/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetPollingById(token, id) {
        let path = `polling/get/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetActivePolling(token) {
        let path = `polling/get-active`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetPolling(token, title) {
        let path = `polling/get?titleQuery=${title}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetClosePolling(token) {
        let path = `polling/get-close`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeletePolling(token, id) {
        let path = `job-category/delete-job-category/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Punishment
    static CreatePunishment(token, data) {
        let path = `punishment/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdatePunishment(token, data, id) {
        let path = `punishment/update/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetPunishmentById(token, id) {
        let path = `punishment/get/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetPunishment(token) {
        let path = `punishment/get`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeletePunishment(token, id) {
        let path = `punishment/delete/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Official Work Travel Time Management
    static CreateWorkTravel(token, data) {
        let path = `work-travels/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateWorkTravel(token, data, id) {
        let path = `work-travels/update/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkTravelById(token, id) {
        let path = `work-travels/get-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetWorkTravelByAdmin(token, startDate, endDate, fullname, page, limit, company, unitKerja) {
        let path = `work-travels/get-admin?page=${page}&limit=${limit}&start_date=${startDate}&end_date=${endDate}&companyId=${company}&unitKerjaEselon=${unitKerja}&fullname=${fullname}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetWorkTravel(token, startDate, endDate, page, limit) {
        let path = `work-travels/get?start_date=${startDate}&end_date=${endDate}&page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static getDetailApprovalWorkTravel(token, id) {
        let path = `work-travels/${id}/list-approval`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
    }

    static DeleteWorkTravel(token, id) {
        let path = `work-travels/delete/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static WorkTravelVerification(token, id, data) {
        let path = `work-travels/verifikasi/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Announcement
    static CreateAnnouncement(token, data) {
        let path = `announcment/create`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateAnnouncement(token, data, id) {
        let path = `announcment/update/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetAnnouncementById(token, id) {
        let path = `announcment/get-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteAnnouncement(token, id) {
        let path = `announcment/delete/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    //Performance
    static GetAngkaKreditMinimum(token, limit, page) {
        let path = `angka-kredit-minimum?limit=${limit}&page=${page}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostAngkaKreditMinimum(token, data) {
        let path = `angka-kredit-minimum`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static UpdateAngkaKreditMinimum(token, data, id) {
        let path = `angka-kredit-minimum/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteAngkaKreditMinimum(token, id) {
        let path = `angka-kredit-minimum/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GenerateId(token) {
        let path = `performance-setting/generate-id`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetPerformanceSetting(token) {
        let path = `performance-setting`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostPerformanceSetting(token, data) {
        let path = `performance-setting`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeletePerformanceSetting(token, id) {
        let path = `performance-setting/point/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static GetIndikatorKinerja(token, page, limit) {
        let path = `indikator-kuantitatif-kinerja?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetIndikatorKinerjaById(token, id) {
        let path = `indikator-kuantitatif-kinerja/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostIndikator(token, data) {
        let path = `indikator-kuantitatif-kinerja`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static UpdateIndikator(token, data, id) {
        let path = `indikator-kuantitatif-kinerja/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteIndikator(token, id) {
        let path = `indikator-kuantitatif-kinerja/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetIndikatorKinerjaByLaporan(token, id) {
        let path = `laporan/${id}/indikator`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostTugasStruktural(token, data) {
        let path = `tugas-struktural`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static UpdateTugasStruktural(token, data, id) {
        let path = `tugas-struktural/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetTugasStruktural(token, page, limit, search) {
        let path = `tugas-struktural?page=${page}&limit=${limit}&search=${search}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetTugasStrukturalById(token, id) {
        let path = `tugas-struktural/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DropdownTugasStruktural(token, search) {
        let path = `dropdown/tugas-struktural?search=${search}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteTugasStruktural(token, id) {
        let path = `tugas-struktural/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostTugasPokok(token, data) {
        let path = `tugas-pokok`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static UpdateTugasPokok(token, data, id) {
        let path = `tugas-pokok/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetTugasPokok(token, page, limit, search) {
        let path = `tugas-pokok?page=${page}&limit=${limit}&search=${search}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetTugasPokokById(token, id) {
        let path = `tugas-pokok/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DropdownTugasPokokByKode(token, kode) {
        let path = `dropdown/tugas-pokok/kode?search=${kode}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DropdownTugasPokokByName(token, name, kode) {
        let path = `dropdown/tugas-pokok/name?search=${name}&kode=${kode}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteTugasPokok(token, id) {
        let path = `tugas-pokok/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteTugasPokokDetail(token, id) {
        let path = `tugas-pokok/detail/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostSetTugasTambahan(token, data) {
        let path = `tugas-tambahan-asisten`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetSetTugasTambahan(token, page, limit, search) {
        let path = `tugas-tambahan-asisten?page=${page}&limit=${limit}&search=${search}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetSetTugasTambahanById(token, id) {
        let path = `tugas-tambahan-asisten/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static UpdateSetTugasTambahan(token, data, id) {
        let path = `tugas-tambahan-asisten/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteSetTugasTambahan(token, id) {
        let path = `tugas-tambahan-asisten/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostNomorSurat(token, data) {
        let path = `nomor-pak`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetNomorSurat(token, page, limit) {
        let path = `nomor-pak?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetNomorSuratById(token, id) {
        let path = `nomor-pak/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static UpdateNomorSurat(token, id, data) {
        let path = `nomor-pak/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteNomorSurat(token, id) {
        let path = `nomor-pak/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DropdownTugasTambahan(token) {
        let path = `dropdown/tugas-tambahan-asisten`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostPassphrase(token, id, data) {
        let path = `sasaran-kinerja-pegawai/${id}/passphrase`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
    }
    static PostPassphraseLaporanBulanan(token, id, data) {
        let path = `laporan/${id}/passphrase`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
    }
    static PostPassphraseLaporanPeriodik(token, id, data) {
        let path = `/laporan/tahunan/${id}/passphrase`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
    }
    static GetMatrix(token, id) {
        let path = `sasaran-kinerja-pegawai/${id}/matriks`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetSKP(token, page, limit) {
        let path = `sasaran-kinerja-pegawai?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DropdownSKP(token) {
        let path = `dropdown/sasaran-kinerja-pegawai`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetSKPById(token, id) {
        let path = `sasaran-kinerja-pegawai/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetDetailSKP(token, id) {
        let path = `sasaran-kinerja-pegawai/${id}/detail`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostSKP(token, data) {
        let path = `sasaran-kinerja-pegawai`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static UpdateSKP(token, data, id) {
        let path = `sasaran-kinerja-pegawai/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteSKP(token, id) {
        let path = `sasaran-kinerja-pegawai/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteRHK(token, id) {
        let path = `rencana-hasil-kerja/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostRHK(token, data) {
        let path = `rencana-hasil-kerja`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static UpdateRHK(token, data, id) {
        let path = `rencana-hasil-kerja/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetRHKById(token, id) {
        let path = `rencana-hasil-kerja/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetRHK(token) {
        let path = `rencana-hasil-kerja`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetDetailRHK(token, id) {
        let path = `sasaran-kinerja-pegawai/${id}/detail`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetRHKbyIdSKP(token, id) {
        let path = `rencana-hasil-kerja?skpId=${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostRencanaAksi(token, data, rhkId) {
        let path = `rencana-hasil-kerja/${rhkId}/rencana-aksi`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static UpdateRencanaAksi(token, data, rhkId, rencanaAksiId) {
        let path = `rencana-hasil-kerja/${rhkId}/rencana-aksi/${rencanaAksiId}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteRA(token, rhkId, rencanaAksiId) {
        let path = `rencana-hasil-kerja/${rhkId}/rencana-aksi/${rencanaAksiId}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostLaporanTahunan(token, data) {
        let path = `laporan/tahunan`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
    }
    static DeleteLaporanTahunan(token, id) {
        let path = `laporan/tahunan/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostLaporanBulanan(token, data) {
        let path = `laporan`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteLaporanBulanan(token, id) {
        let path = `laporan/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetLaporanMaladministrasi(token, id) {
        let path = `laporan/laporan-maladministrasi?laporanId=${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostLM(token, id, data) {
        let path = `laporan/${id}/indikator`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetLaporanByIdSkp(token, limit, page, id) {
        let path = `laporan?limit=${limit}&page=${page}&skpId=${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetExportLaporanBulanan(token, id) {
        let path = `/laporan/${id}/export`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetExportLaporanTahunan(token, id) {
        let path = `/laporan/tahunan/${id}/export`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetLaporanRhkByIdSkp(token, id) {
        let path = `laporan/rhk?skpId=${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostBuktiDukung(token, data) {
        let path = `laporan/tambah-bukti-dukung`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
    }
    static PostBuktiDukungPeriode(token, data) {
        let path = `laporan/periode/tambah-bukti-dukung`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
    }
    static DeleteFileBuktiDukung(token, id) {
        let path = `laporan/file/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetKinerjaById(token, id) {
        let path = `laporan/kinerja/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostKinerja(token, data) {
        let path = `laporan/kinerja`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
    }
    static UpdateKinerja(token, data, id) {
        let path = `laporan/kinerja/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
    }
    static DeleteKinerja(token, id) {
        let path = `laporan/kinerja/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteFileKinerja(token, id) {
        let path = `laporan/kinerja-file/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static ApprovalInovasi(token, page, limit) {
        let path = `approval/penetapan-inovasi?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostApprovalInovasi(token, id, data) {
        let path = `approval/penetapan-inovasi/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static ClaimInovasi(token, id, laporanPeriodeId) {
        let path = `penetapan-inovasi/${id}/claim?laporanPeriodeId=${laporanPeriodeId}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetInovasi(token, limit, page) {
        let path = `penetapan-inovasi?limit=${limit}&page=${page}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostInovasi(token, data) {
        let path = `penetapan-inovasi`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
    }
    static DeleteInovasi(token, id) {
        let path = `penetapan-inovasi/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostPKT(token, data) {
        let path = `laporan/prestasi-kerja-tertentu`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static UpdatePKT(token, data, id) {
        let path = `laporan/prestasi-kerja-tertentu/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetPKT(token, id) {
        let path = `laporan/prestasi-kerja-tertentu/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetPKTById(token, id) {
        let path = `laporan/prestasi-kerja-tertentu/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeletePKT(token, id) {
        let path = `laporan/prestasi-kerja-tertentu/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostTugasTambahan(token, data) {
        let path = `laporan/tugas-tambahan`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static UpdateTugasTambahan(token, data, id) {
        let path = `laporan/tugas-tambahan/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteTugasTambahan(token, id) {
        let path = `laporan/tugas-tambahan/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetLM(token, id) {
        let path = `laporan/laporan-masyarakat?laporanId=${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DropdownRHK(token, skpId) {
        let path = `rencana-hasil-kerja/dropdown-rhk?skpId=${skpId}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DropdownIntervenedEmployee(token) {
        let path = `rencana-hasil-kerja/dropdown-pegawai-intervensi`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DropdownLaporanKinerja(token, skpId) {
        let path = `dropdown/laporan-periode?sasaranKinerjaPegawaiId=${skpId}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    // Laporan Kinerja Saya
    static GetLaporanKinerjaSaya(token, id) {
        let path = `laporan/kinerja-saya?skpId=${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    // Laporan Kinerja Bawahan
    static GetLaporanKinerjaBawahan(token, periode, month, tahun, bawahanId) {
        let path = ""
        if (periode === "bulan") {
            path = `laporan/kinerja-bawahan?bulan=${month}&tahun=${tahun}&bawahanId=${bawahanId}`
        } else {
            path = `laporan/kinerja-bawahan?tahun=${tahun}&bawahanId=${bawahanId}`
        }
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static UpdateLaporan(token, id, data) {
        let path = `laporan/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetLaporanTahunan(token, id) {
        let path = `laporan/tahunan/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetDetailLaporan(token, id) {
        let path = `laporan/${id}/detail`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetLaporanById(token, id) {
        let path = `laporan/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetLaporanTahunanById(token, id) {
        let path = `laporan/tahunan/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static UpdateLaporanTahunan(token, id, data) {
        let path = `laporan/tahunan/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
    }
    static GetPenilaianPerilakuKerja(token, userId, penilaiId, month) {
        let path = `penilaian-perilaku-kerja?userId=${userId}&penilaiId=${penilaiId}&bulan=${month}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostPenilaianKinerjaBulanan(token, data, id) {
        let path = `laporan/kinerja/${id}/penilaian-bulanan`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostPenilaianPerilakuKerja(token, data, userId, penilaiId, month) {
        let path = `penilaian-perilaku-kerja?userId=${userId}&penilaiId=${penilaiId}&bulan=${month}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostPenilaianRhkBulanan(token, data, rencanaAksiId) {
        let path = `rencana-hasil-kerja/rencana-aksi/${rencanaAksiId}/penilaian-bulanan`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostPenilaianRhkTahunan(token, data, rencanaAksiId) {
        let path = `rencana-hasil-kerja/rencana-aksi/${rencanaAksiId}/penilaian-tahunan`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostPenilaianPerilakuKerjaTahunan(token, data) {
        let path = `penilaian-perilaku-kerja/tahunan`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetRekapAbsen(token, month, year, limit, page, company, search) {
        let path = `penghasilan/rekap-absen?bulan=${month}&tahun=${year}&limit=${limit}&page=${page}&companyId=${company}&search=${search}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetRekapPotongan(token, month, year, limit, page, company, refresh) {
        let path = `penghasilan/rekap-potongan?bulan=${month}&tahun=${year}&limit=${limit}&page=${page}&companyId=${company}&search=${refresh}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetRekapPenghasilan(token, month, year, limit, page, company, search, jenisKepegawaian, unitKerja) {
        let path = `penghasilan/rekap-penghasilan?bulan=${month}&tahun=${year}&limit=${limit}&page=${page}&companyId=${company}&search=${search}&jobCategoryId=${jenisKepegawaian}&unitKerjaEselon=${unitKerja}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static ExportLembur(token, month, year, user) {
        let path = `overtime/export?bulan=${month}&tahun=${year}&userId=${user}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetRekapPerhitungan(token, type, month, year, limit, page, company, search) {
        let path
        const kepegawaian = type.split("-")
        type === "lembur-ASN" || type === "lembur-ASISTEN" ? (
            path = `penghasilan/output-perhitungan/${kepegawaian[0]}?bulan=${month}&tahun=${year}&limit=${limit}&page=${page}&companyId=${company}&search=${search}&type=${kepegawaian[1]}`
        ) : (
            path = `penghasilan/output-perhitungan/${type}?bulan=${month}&tahun=${year}&limit=${limit}&page=${page}&companyId=${company}&search=${search}`
        )
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetExportLembur(token, month, year, limit, page, company, search, type) {
        const path = `penghasilan/output-perhitungan/export-lembur?bulan=${month}&tahun=${year}&limit=${limit}&page=${page}&companyId=${company}&search=${search}&type=${type}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetHierarchy(token) {
        let path = `job-position/hierarchy`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static getPayrollHistory(token, userId, year) {
        let path = `profile/payroll/${userId}/riwayat-penghasilan?tahun=${year}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static getPayrollByLogin(token) {
        let path = `profile/mypayroll`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static getMyPayrollByAdmin(token, id) {
        let path = `profile/payroll/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static editMyPayroll(token, data, id) {
        let path = `profile/payroll/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static getJKK(token) {
        let path = `payroll/get-jkk-bpjs`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostKelompokKerja(token, data) {
        let path = `kelompok-kerja`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static UpdateKelompokKerja(token, id, data) {
        let path = `kelompok-kerja/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteKelompokKerja(token, id) {
        let path = `kelompok-kerja/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetKelompokKerja(token) {
        let path = `kelompok-kerja`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetKelompokKerjaById(token, id) {
        let path = `kelompok-kerja/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostVerifikator(token, id, data) {
        let path = `kelompok-kerja/${id}/verifikator`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteVerifikator(token, id, verifikatorId) {
        let path = `kelompok-kerja/${id}/verifikator/${verifikatorId}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetAsistenByVerifikator(token, id, verifikatorId) {
        let path = `kelompok-kerja/${id}/verifikator/${verifikatorId}/pegawai`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostAsisten(token, id, verifikatorId, data) {
        let path = `kelompok-kerja/${id}/verifikator/${verifikatorId}/pegawai`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteAsisten(token, id, verifikatorId, pegawaiId) {
        let path = `kelompok-kerja/${id}/verifikator/${verifikatorId}/pegawai/${pegawaiId}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetPengajuanPak(token) {
        let path = `pengajuan-pak`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetPengajuanPakById(token, id) {
        let path = `pengajuan-pak/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostPengajuanPak(token, data) {
        let path = `pengajuan-pak`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static UpdatePengajuanPak(token, data, id) {
        let path = `pengajuan-pak/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeletePengajuanPak(token, id) {
        let path = `pengajuan-pak/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetApprovalPengajuanPak(token, currentPage, limit) {
        let path = `approval/pengajuan-pak?limit=${10}&page=${1}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetNilaiCapaianSKA(token, laporanPeriodeId) {
        let path = `laporan/nilai-capaian-ska/${laporanPeriodeId}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostApprovalPAK(token, id, data) {
        let path = `approval/pengajuan-pak/${id}/verifikasi-administrasi`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static PostVerifikasiBerkas(token, id, data) {
        let path = `approval/pengajuan-pak/${id}/verifikasi-berkas`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    } static PostPassphraseVerifikasiBerkas(token, id, data) {
        let path = `pengajuan-pak/${id}/passphrase`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
}
export default Api;