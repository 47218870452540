import React, { useState } from 'react'
import { Navbar } from '../../../../../components'
import languages from '../../../../../utils/languages'
import Jabatan from './Jabatan'
import JenisJabatan from './JenisJabatan'
import JenisKepegawaian from './JenisKepegawaian'
import KelasJabatan from './KelasJabatan'
import UnitKerja from './UnitKerja'

const JobManagement = () => {
    const [menu, setMenu] = useState('Kelas Jabatan')
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Manajemen Job'} LinkBack={'/dashboard'} />
                <div>
                    <h1 className="text-zinc-800 text-xl font-semibold">{languages.language === 'id' ? "Manajemen Job" : "Job Management"}</h1>
                    <h2 className="text-gray-500 text-sm font-normal">{languages.language === 'id' ? "Daftar Manajemen Job" : "list of job management"}</h2>
                </div>
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border w-full'>
                    <div className='border-b-2 flex flex-wrap items-center text-[16px]'>
                        <button onClick={() => setMenu('Kelas Jabatan')} className={`${menu === 'Kelas Jabatan' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px]`}>Kelas Jabatan</button>
                        <button onClick={() => setMenu('Jenis Jabatan')} className={`${menu === 'Jenis Jabatan' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px]`}>Jenis Jabatan</button>
                        <button onClick={() => setMenu('Jenis Kepegawaian')} className={`${menu === 'Jenis Kepegawaian' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px]`}>Jenis Kepegawaian</button>
                        <button onClick={() => setMenu('Unit Kerja')} className={`${menu === 'Unit Kerja' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px]`}>Unit Kerja</button>
                        <button onClick={() => setMenu('Jabatan')} className={`${menu === 'Jabatan' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px]`}>Jabatan</button>
                    </div>

                    {menu === 'Kelas Jabatan' ? (
                        <KelasJabatan />
                    ) : menu === 'Jenis Jabatan' ? (
                        <JenisJabatan />
                    ) : menu === 'Jenis Kepegawaian' ? (
                        <JenisKepegawaian />
                    ) : menu === 'Unit Kerja' ? (
                        <UnitKerja />
                    ) : menu === 'Jabatan' ? (
                        <Jabatan />
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default JobManagement