import React, { useEffect, useState } from 'react';
import { Navbar } from '../../../../components';
import InputComponent from '../../../../components/Input/inputComponent';
import PrimaryButton from '../../../../components/Button/PrimaryButton'
import Api from '../../../../Api';
import toast from 'react-hot-toast';
import LoadingPrimaryButton from '../../../../components/Button/LoadingPrimaryButton';

const System = () => {
    const [id, setId] = useState("")
    const [androidVersion, setAndroidVersion] = useState("")
    const [iosVersion, setIosVersion] = useState("")
    const [isWebPresenceDisabled, setIsWebPresenceDisabled] = useState("")
    const [isMustCheckin, setIsMustCheckin] = useState("")
    const [isMultipleDevice, setIsMultipleDevice] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const updateConfiguration = async () => {
        setIsLoading(true)
        const data = {
            "androidVersion": androidVersion,
            "iosVersion": iosVersion,
            "isWebPresenceDisabled": isWebPresenceDisabled,
            "isMustCheckin": isMustCheckin,
            "isMultipleDevice": isMultipleDevice
        }
        try {
            await Api.UpdateConfiguration(localStorage.getItem('hris-token'), id, data).then((res) => {
                toast.success("Behasil memperbarui data!")
                setIsLoading(false)
            }).catch((err) => {
                console.log(err)
                toast.error(err?.response?.data?.errors)
                setIsLoading(false)
            })
        } catch (error) {
            console.log(error)
        }
    }
    const getData = async () => {
        try {
            await Api.GetConfiguration(localStorage.getItem('hris-token')).then((res) => {
                const data = res.data.results.data
                setId(data.id)
                setAndroidVersion(data.androidVersion)
                setIosVersion(data.iosVersion)
                setIsWebPresenceDisabled(data.isWebPresenceDisabled)
                setIsMustCheckin(data.isMustCheckin)
                setIsMultipleDevice(data.isMultipleDevice)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Pengaturan Sistem'} LinkBack={'/dashboard'} />
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='space-y-[24px] rounded-[12px]'>
                        <div className='grid grid-cols-3 items-center gap-2 mb-20'>
                            <div className=''>
                                <p className='tex-xs font-medium'>Versi Android</p>
                            </div>
                            <div className='col-span-2'>
                                <InputComponent type={"text"} placeholder={"Versi Android"} value={androidVersion} onChange={(e) => setAndroidVersion(e)} />
                            </div>
                            <div className=''>
                                <p className='tex-xs font-medium'>Versi IOS</p>
                            </div>
                            <div className='col-span-2'>
                                <InputComponent type={"text"} placeholder={"Versi IOS"} value={iosVersion} onChange={(e) => setIosVersion(e)} />
                            </div>
                            <div className=''>
                                <p className='tex-xs font-medium'>Presensi Website</p>
                            </div>
                            <div className='col-span-2'>
                                <label className="inline-flex gap-2 items-center justify-start cursor-pointer w-full">
                                    <input
                                        type="checkbox"
                                        value=""
                                        className="sr-only peer"
                                        checked={isWebPresenceDisabled}
                                        onChange={() => setIsWebPresenceDisabled(!isWebPresenceDisabled)}
                                    />
                                    <div className={`relative w-11 h-6 bg-gray-500 rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600`}></div>
                                    <span className="text-dark-5 text-xs font-[500] truncate text-center"> {!isWebPresenceDisabled ? "Tidak Aktif" : "Aktif"}</span>
                                </label>
                            </div>
                            <div className=''>
                                <p className='tex-xs font-medium'>Presensi Masuk</p>
                            </div>
                            <div className='col-span-2'>
                                <label className="inline-flex gap-2 items-center justify-start cursor-pointer w-full">
                                    <input
                                        type="checkbox"
                                        value=""
                                        className="sr-only peer"
                                        checked={isMustCheckin}
                                        onChange={() => setIsMustCheckin(!isMustCheckin)}
                                    />
                                    <div className={`relative w-11 h-6 bg-gray-500 rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600`}></div>
                                    <span className="text-dark-5 text-xs font-[500] truncate text-center"> {!isMustCheckin ? "Tidak Wajib" : "Wajib"}</span>
                                </label>
                            </div>
                            <div className=''>
                                <p className='tex-xs font-medium'>Izin Perangkat</p>
                            </div>
                            <div className='col-span-2'>
                                <label className="inline-flex gap-2 items-center justify-start cursor-pointer w-full">
                                    <input
                                        type="checkbox"
                                        value=""
                                        className="sr-only peer"
                                        checked={isMultipleDevice}
                                        onChange={() => setIsMultipleDevice(!isMultipleDevice)}
                                    />
                                    <div className={`relative w-11 h-6 bg-gray-500 rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600`}></div>
                                    <span className="text-dark-5 text-xs font-[500] truncate text-center"> {!isMustCheckin ? "Satu perangkat" : "Banyak Perangkat"}</span>
                                </label>
                            </div>
                        </div>
                        <div className='flex justify-end'>
                            {isLoading ?
                                <LoadingPrimaryButton />
                                : <PrimaryButton action={updateConfiguration} text={"Simpan"} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default System