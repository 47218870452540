import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { BsArrowLeftShort } from 'react-icons/bs'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Api from '../../../Api'
import { Landing1, Logo2 } from '../../../assets'

const ResetPassword = () => {
    const navigate = useNavigate()
    const [password, setPassword] = useState('')
    const [confirmPassword, setconfirmPassword] = useState('')
    const [passwordType, setPasswordType] = useState('password')
    const { userId, token } = useParams()

    const resetPassword = async () => {
        if (password === confirmPassword) {
            const data = {
                "new_password": password,
                "confirm_password": confirmPassword
            }
            try {
                await Api.ResetPassword(userId, token, data).then((response) => {
                    toast.success("Kata Sandi Berhasil Diubah!")
                    navigate("/")
                }).catch((e) => {
                    // console.log(e.response.data)
                    toast.error(e.response.data.message)
                })
            } catch (error) {
                console.log(error)
                toast.error("Invalid email and/or password!!!")
            }
        } else {
            toast.error("Periksa Kembali Kata Sandi")
        }

    }


    const ChangePasswordType = () => {
        if (passwordType === 'password') {
            setPasswordType('text')
        } else {
            setPasswordType('password')
        }
    }
    return (
        <div>
            <div className='lg:grid lg:grid-cols-12'>
                <div className='lg:col-span-7 bg-[#ECEEF6] '>
                    <div className='flex flex-col justify-between h-[100%]'>
                        <div className='px-[85px] mt-[51px]'>
                            <img src={Logo2} className='w-[203px]' alt='Humanusia Logo' />
                        </div>
                        <div className=''>
                            <img src={Landing1} className='' alt='LandingImage' />
                        </div>
                    </div>
                </div>
                <div className='lg:col-span-5 bg-white px-5 lg:px-[82px] py-[170px]'>
                    <h1 className='text-[30px] text-[#2F3044] font-medium'>Atur Ulang Kata Sandi</h1>
                    <h2 className='text-[#A8A8A8] text-sm'>Masukkan kata sandi baru</h2>
                    <div className='mt-3'>
                        <h1 className='text-[13px] text-dark-3 font-medium mb-2'>Kata Sandi Baru</h1>
                        <div className='relative'>
                            <input onChange={(e) => setPassword(e.target.value)} type={passwordType} className="bg-white border border-[#780000] text-[#A8A8A8] text-[12px] rounded-lg block w-full py-[13px] px-[20px] mb-2" placeholder='Kata Sandi Baru...' />
                            <button onClick={ChangePasswordType} className='absolute right-5 top-3 text-xl text-[#A8A8A8]'>
                                {passwordType === 'text' ?
                                    <AiOutlineEye />
                                    :
                                    <AiOutlineEyeInvisible />
                                }
                            </button>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <h1 className='text-[13px] text-dark-3 font-medium mb-2'>Konfirmasi Kata Sandi</h1>
                        <div className='relative'>
                            <input onChange={(e) => setconfirmPassword(e.target.value)} type={passwordType} className="bg-white border border-[#780000] text-[#A8A8A8] text-[12px] rounded-lg block w-full py-[13px] px-[20px] mb-2" placeholder='Konfirmasi Kata Sandi...' />
                            <button onClick={ChangePasswordType} className='absolute right-5 top-3 text-xl text-[#A8A8A8]'>
                                {passwordType === 'text' ?
                                    <AiOutlineEye />
                                    :
                                    <AiOutlineEyeInvisible />
                                }
                            </button>
                        </div>
                    </div>
                    <button onClick={resetPassword} className='py-[10px] px-[195px] text-white rounded-md bg-[#0E5073] text-[12px] w-full font-semibold'>Kirim</button>

                    <div className='flex justify-center gap-1 mt-3'>
                        <Link to='/' className='flex gap-3'>
                            <BsArrowLeftShort className='text-dark-5 text-2xl' />
                            <h1 className='text-[14px] text-dark-5 underline'>Kembali Ke Halaman Login</h1>
                        </Link>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ResetPassword