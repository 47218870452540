import moment from 'moment'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlineDownload, AiOutlinePlus } from 'react-icons/ai'
import { BiSearch } from 'react-icons/bi'
import { BsFilter } from 'react-icons/bs'
import { CgTrashEmpty } from 'react-icons/cg'
import { IoMdArrowDropdown } from 'react-icons/io'
import { MdImportExport, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import Api from '../../../Api'
import { Modal, ModalDelete, Pagination } from '../../../components'
import imageHandle from '../../../utils/imageHandle'
import languages from '../../../utils/languages'
import SearchComponent from '../../../components/Input/searchComponent'

const PerjalananDinas = () => {
    const navigate = useNavigate()
    const [showDetailApproval, setShowDetailApproval] = useState('')
    const [dataWorkTravel, setDataWorkTravel] = useState('')
    const [refresh, setRefresh] = useState(false)

    // openIndex
    const [Current, setCurrent] = useState(0)
    const [Active, setActive] = useState(false)
    const handleCollapse = (id) => {
        if (id) {
            setCurrent(id)
            setActive(!Active)
        }
    }

    const [limit, setLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')
    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const admin = localStorage.getItem('hris-role') === 'ADMIN' || localStorage.getItem('hris-role') === 'SUBSIDIARY'

    const [showFilter, setShowFilter] = useState(false)
    const [penempatan, setPenempatan] = useState("")
    const [unitKerja, setUnitKerja] = useState("")
    const [startDateFilter, setStartDateFilter] = useState("")
    const [endDateFilter, setEndDateFilter] = useState("")
    const [fullnameFilter, setFullnameFilter] = useState("")

    const getDataWorkTravel = async () => {
        try {
            const response = await Api.GetWorkTravelByAdmin(localStorage.getItem('hris-token'), startDateFilter, endDateFilter, fullnameFilter, currentPage, limit, penempatan, unitKerja)
            setDataWorkTravel(response.data.workTravels)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            setTotalCount(response.data.totalCount)
        } catch (error) {
            console.log(error)
        }
    }

    const handleResetFilter = () => {
        setStartDateFilter("")
        setEndDateFilter("")
        setFullnameFilter("")
        setPenempatan("")
        setUnitKerja("")
        setShowFilter(!showFilter)
        // window.location.reload()
    }

    const [dataPenempatan, setDataPenempatan] = useState([])
    const [dataUnitKerja, setDataUnitKerja] = useState([])
    const [userId, setUserId] = useState("")
    const GetData = async () => {
        try {
            const res = await Api.GetSubsidiaryDropdown(localStorage.getItem('hris-token'))
            const res2 = await Api.GetUnitKerjaEselon(localStorage.getItem('hris-token'))
            const fetch = await Api.Fetch(localStorage.getItem('hris-token'))
            setDataPenempatan(res.data.results.data)
            setDataUnitKerja(res2.data.results.data)
            setUserId(fetch?.data?.results?.user?.id)
        } catch (error) {
            console.log(error)
        }
    }
    const checkboxHandler = async (id, value) => {
        console.log(value)
        try {
            const data = {
                "isVerified": value
            }
            await Api.WorkTravelVerification(localStorage.getItem('hris-token'), id, data).then((res) => {
                setRefresh(true)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
        }
    }


    const [showModalDelete, setShowModalDelete] = useState(false)
    const [id, setId] = useState('')

    const deleteModal = (v) => {
        setShowModalDelete(!showModalDelete)
        setId(v)
        setRefresh(true)
    }
    const deleteWorkTravel = async () => {
        try {
            await Api.DeleteWorkTravel(localStorage.getItem('hris-token'), id).then((res) => {
                setRefresh(true)
                setShowModalDelete(false)
                toast.success('Berhasil menghapus data!')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal menghapus data!')
        }
    }

    const handleSearch = (e) => {
        setFullnameFilter(e)
    }

    useEffect(() => {
        GetData()
    }, [])
    useEffect(() => {
        getDataWorkTravel()
        setRefresh(false)
    }, [startDateFilter, endDateFilter, fullnameFilter, limit, currentPage, penempatan, unitKerja, refresh])

    return (
        <div>
            <div className='relative'>
                <Modal
                    activeModal={showFilter}
                    buttonClose={() => setShowFilter(!showFilter)}
                    width={'550px'}
                    title={
                        <div className='flex gap-[27px] items-center'>
                            <BsFilter className='text-dark-1 text-2xl' />
                            <h1 className='text-dark-1 text-[20px] font-semibold'>Filter</h1>
                        </div>
                    }
                    content={
                        <div className='space-y-[20px]'>
                            <div className='grid grid-cols-2 gap-3'>
                                <div className='relative'>
                                    <select onChange={(e) => setPenempatan(e.target.value)} value={penempatan} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                        <option value={""} selected className='text-grey-medium font-normal'>Pilih Penempatan</option>
                                        {dataPenempatan && dataPenempatan.map((val, index) => (
                                            <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.company_name}</option>
                                        ))}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                                </div>
                                <div className='relative'>
                                    <select onChange={(e) => setUnitKerja(e.target.value)} value={unitKerja} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                        <option value={""} selected className='text-grey-medium font-normal'>Pilih Unit Kerja</option>
                                        {dataUnitKerja && dataUnitKerja.map((val, index) => (
                                            <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.name}</option>
                                        ))}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                                </div>
                                <div className='col-span-2'>
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Tanggal</h1>
                                    <div className='flex gap-3 items-center'>
                                        <input onChange={(e) => setStartDateFilter(e.target.value)} value={startDateFilter} type="date" className='w-full lg:w-[121px] p-[10px] rounded-md border border-gray-300 text-[#003049] text-[12px]' />
                                        <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>-</h1>
                                        <input onChange={(e) => setEndDateFilter(e.target.value)} value={endDateFilter} type="date" className='w-full lg:w-[121px] p-[10px] rounded-md border border-gray-300 text-[#003049] text-[12px]' />
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center justify-between'>
                                <button onClick={handleResetFilter} className='py-[10px] px-[15px] text-[#0E5073] text-[14px] font-medium flex justify-center rounded-md items-center border'>Reset Filter</button>
                                <button onClick={() => setShowFilter(!showFilter)} className='py-[10px] px-[15px] bg-[#0E5073] text-white text-[14px] font-medium flex justify-center rounded-md items-center'>Filter</button>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showDetailApproval}
                    title={'Detail Persetujuan'}
                    buttonClose={() => setShowDetailApproval(!showDetailApproval)}
                    width={'507px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className='overflow-auto scrollbar-hide'>
                                <table className='w-full space-y-[10px]'>
                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[6px] min-w-[300px] max-w-[300px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Nama Atasan</h1>
                                            <MdImportExport className='text-dark-5 text-xs' />
                                        </div>
                                        <div className='flex items-center justify-center gap-[15px] w-full'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Status</h1>
                                        </div>
                                    </div>
                                </table>
                            </div>
                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowDetailApproval(!showDetailApproval)} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Tutup" : "Close"}</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                < ModalDelete
                    activeModal={showModalDelete}
                    buttonClose={() => setShowModalDelete(!showModalDelete)}
                    submitButton={deleteWorkTravel}
                />
            </div>
            <div className="flex flex-wrap items-center gap-2 my-5">
                <div className='relative'>
                    <select onChange={(e) => setPenempatan(e.target.value)} value={penempatan} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border border-grey-extra-thin py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                        <option value={""} selected className='text-grey-medium font-normal'>Seluruh Penempatan</option>
                        {dataPenempatan && dataPenempatan.map((val, index) => (
                            <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.company_name}</option>
                        ))}
                    </select>
                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                </div>
                <div className='relative'>
                    <select onChange={(e) => setUnitKerja(e.target.value)} value={unitKerja} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border border-grey-extra-thin py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                        <option value={""} selected className='text-grey-medium font-normal'>Seluruh Unit Kerja</option>
                        {dataUnitKerja && dataUnitKerja.map((val, index) => (
                            <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.name}</option>
                        ))}
                    </select>
                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                </div>
                <div className='flex gap-1 items-center'>
                    <input onChange={(e) => setStartDateFilter(e.target.value)} value={startDateFilter} type="date" className='w-full lg:w-[121px] p-[10px] rounded-md border border-grey-extra-thin text-[#003049] text-[12px]' />
                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>-</h1>
                    <input onChange={(e) => setEndDateFilter(e.target.value)} value={endDateFilter} type="date" className='w-full lg:w-[121px] p-[10px] rounded-md border border-grey-extra-thin text-[#003049] text-[12px]' />
                </div>
                <div className='relative'>
                    <select onChange={(e) => setLimit(e.target.value)} value={limit} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-20 border border-grey-extra-thin py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                        <option value={""} className='text-grey-medium font-normal'>Limit</option>
                        <option value={10} className='text-grey-medium font-normal '>10</option>
                        <option value={25} className='text-grey-medium font-normal '>25</option>
                        <option value={50} className='text-grey-medium font-normal '>50</option>
                        <option value={100} className='text-grey-medium font-normal '>100</option>
                    </select>
                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                </div>
                <SearchComponent onChange={handleSearch} width={200} placeholder={"Cari nama pegawai...."} />

            </div>
            <div>
                <div className='overflow-auto'>
                    <table className='w-full space-y-[10px]'>
                        <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                            <div className='flex items-center justify-start gap-[6px] min-w-[50px] max-w-[50px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>No.</h1>
                            </div>
                            <div className='flex items-center justify-start gap-[6px] min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Nama Pegawai" : "Employee Name"}</h1>
                            </div>
                            <div className='flex items-center justify-start gap-[6px] min-w-[250px] max-w-[250px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Jabatan" : "Job Position"}</h1>
                            </div>
                            <div className='flex items-center justify-start gap-[6px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Jenis Perjalanan" : "Name of Trip"}</h1>
                            </div>
                            <div className='flex items-center justify-start gap-[6px] min-w-[100px] max-w-[100px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Durasi" : "Duration"}</h1>
                            </div>
                            <div className='flex items-center justify-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Status Dokumen</h1>
                            </div>
                            <div className='flex items-center justify-center gap-[6px] w-full'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                            </div>
                        </div>
                        {Object.values(dataWorkTravel).map((item, idx) => (
                            <div key={idx}>
                                <div className='flex items-center gap-2  px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='min-w-[50px] max-w-[50px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500] truncate text-start'>{idx + 1}</h1>
                                    </div>
                                    <div className='min-w-[250px] max-w-[250px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500] truncate text-start'>{item.user?.fullname ?? '-'}</h1>
                                    </div>
                                    <div className='min-w-[250px] max-w-[250px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500] truncate text-start'>{item.user?.user_detail?.job_position?.position_name ?? '-'}</h1>
                                    </div>
                                    <div className='min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500] truncate text-start'>{item.office_work_travel.type_name ?? '-'}</h1>
                                    </div>
                                    <div className='min-w-[100px] max-w-[100px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500] truncate text-start'>{item.duration ?? '-'} Hari</h1>
                                    </div>
                                    <div className='min-w-[150px] max-w-[150px] flex justify-center'>
                                        {userId !== item?.userId ?
                                            <label className="inline-flex gap-2 items-center justify-center cursor-pointer w-full">
                                                <input
                                                    type="checkbox"
                                                    value=""
                                                    className=""
                                                    checked={item.isVerified}
                                                    onChange={() => checkboxHandler(item.id, !item.isVerified)}
                                                />
                                                <span className="text-dark-5 text-xs font-[500] truncate text-center">Verifikasi</span>
                                            </label>
                                            :
                                            <h1 className={`text-[11px] font-[500] truncate text-center p-2 rounded w-fit ${item.isVerified === true ? 'text-dark-5 bg-[#7EFFB2]' : 'text-white bg-red-thick'}`}>{item.isVerified === true ? "Sudah diverifikasi" : "Belum diverifikasi"}</h1>
                                        }
                                    </div>
                                    <div className='w-full flex items-center gap-[12px]'>
                                        <a href={imageHandle(item.file)} download={item?.nomor_surat} target='_blank' className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center' rel="noreferrer">
                                            <AiOutlineDownload className='text-sm text-blue-thick' />
                                        </a>
                                        <button onClick={() => handleCollapse(item.id)} className='w-[29px] h-[29px] flex items-center justify-center'>
                                            {item.id === Current && !Active ?
                                                <MdKeyboardArrowUp className='text-[#003049]' />
                                                :
                                                <MdKeyboardArrowDown className='text-[#003049]' />
                                            }
                                        </button>
                                    </div>

                                </div>
                                <div className={`w-full rounded-b-[3px] bg-[#F9F9F9] transition-all ease-in-out duration-500 overflow-hidden ${item.id === Current && !Active ? 'h-fit border' : 'h-0 border-0'}`}>
                                    <div className='p-[16px] space-y-3'>
                                        <h1 className="text-gray-500 text-sm font-semibold ">{languages.language === 'id' ? "Detail Perjalanan Dinas" : "Detail Official Work Travel"}</h1>
                                        <div className='space-y-3'>
                                            <div className='flex w-full items-start gap-10'>
                                                <div className='w-fit space-y-1'>
                                                    <div className='grid grid-cols-12 '>
                                                        <h1 className='col-span-4 text-gray-500 text-xs'>{languages.language === 'id' ? "Tanggal" : "Date"}</h1>
                                                        <h1 className='col-span-8 text-gray-500 text-xs'>: {item.end_date && item.end_date ? `${moment(item.start_date).format("DD-MM-YYYY")} s/d ${moment(item.end_date).format("DD-MM-YYYY")}` : '-'}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-12 '>
                                                        <h1 className='col-span-4 text-gray-500 text-xs'>{languages.language === 'id' ? "Nama" : "Name"}</h1>
                                                        <h1 className='col-span-8 text-gray-500 text-xs'>: {item.user?.fullname}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-12 '>
                                                        <h1 className='col-span-4 text-gray-500 text-xs'>{languages.language === 'id' ? "Jabatan" : "Job Position"}</h1>
                                                        <h1 className='col-span-8 text-gray-500 text-xs'>: {item.user?.user_detail.job_position.position_name}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-12 '>
                                                        <h1 className='col-span-4 text-gray-500 text-xs'>{languages.language === 'id' ? "Jenis Perjalanan Dinas" : "Name of Trip"}</h1>
                                                        <h1 className='col-span-8 text-gray-500 text-xs'>: {item.office_work_travel.type_name ?? '-'}</h1>
                                                    </div>
                                                </div>
                                                <div className='w-fit space-y-1'>
                                                    <div className='grid grid-cols-12 '>
                                                        <h1 className='col-span-4 text-gray-500 text-xs'>{languages.language === 'id' ? "Durasi" : "Duration"}</h1>
                                                        <h1 className='col-span-8 text-gray-500 text-xs'>: {item.duration ?? "-"} Hari</h1>
                                                    </div>
                                                    <div className='grid grid-cols-12 '>
                                                        <h1 className='col-span-4 text-gray-500 text-xs'>{languages.language === 'id' ? "Lokasi" : "Location"}</h1>
                                                        <h1 className='col-span-8 text-gray-500 text-xs'>: {item.location ? `${item.location}, ` : ''}{item.kecamatan ? `${item.kecamatan}, ` : ''}{item.kabupaten ? `${item.kabupaten}, ` : ''}{item.provinsi ? `${item.provinsi}` : ''}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-12 '>
                                                        <h1 className='col-span-4 text-gray-500 text-xs'>{languages.language === 'id' ? "Lampiran" : "Attachment"}</h1>
                                                        <div className='col-span-8 text-gray-500 text-xs flex gap-1 items-start'>
                                                            <h1 className=' text-gray-500 text-xs'>:</h1>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                                <path d="M5 12.8711V7.87109C5 6.54501 5.52678 5.27324 6.46447 4.33556C7.40215 3.39788 8.67392 2.87109 10 2.87109C11.3261 2.87109 12.5979 3.39788 13.5355 4.33556C14.4732 5.27324 15 6.54501 15 7.87109V14.5378C15 15.4218 14.6488 16.2697 14.0237 16.8948C13.3986 17.5199 12.5507 17.8711 11.6667 17.8711C10.7826 17.8711 9.93477 17.5199 9.30964 16.8948C8.68452 16.2697 8.33333 15.4218 8.33333 14.5378V7.87109C8.33333 7.42907 8.50893 7.00514 8.82149 6.69258C9.13405 6.38002 9.55797 6.20443 10 6.20443C10.442 6.20443 10.866 6.38002 11.1785 6.69258C11.4911 7.00514 11.6667 7.42907 11.6667 7.87109V14.5378" stroke="#015995" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                            <a href={imageHandle(item.file)} target='_blank' rel="noreferrer" className="text-cyan-900 text-xs font-medium">{languages.language === 'id' ? "Unduh Surat Tugas" : "Download Surat Tugas"}</a>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-12 '>
                                                        <h1 className='col-span-4 text-gray-500 text-xs'>{languages.language === 'id' ? "Tujuan" : "Purpose"}</h1>
                                                        <h1 className='col-span-8 text-gray-500 text-xs'>: {item.purpose ?? '-'}</h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </table>
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    limitData={10}
                    lengthData={totalCount}
                    onPageChange={handlePageChange}
                    onPrevChange={handlePrevChange}
                    onNextChange={handleNextChange}
                />
            </div>
        </div>
    )
}

export default PerjalananDinas