import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { toast } from 'react-hot-toast'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { BsFileText } from 'react-icons/bs'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { IoLocationOutline } from 'react-icons/io5'
import Api from '../../../../Api'
import { Modal, ModalDelete, Navbar } from '../../../../components'


const FiturCalendar = () => {
    const [showAddEvent, setShowAddEvent] = useState()
    const [showEditEvent, setShowEditEvent] = useState()
    const [showEditTodo, setShowEditTodo] = useState()
    const [data, setData] = useState('')
    const [dataTodo, setDataTodo] = useState('')
    const [index, setIndex] = useState(0)
    const [refresh, setRefresh] = useState(false)


    //Post Event
    const [tittleEvent, setTittleEvent] = useState('')
    const [startDateEvent, setStartDateEvent] = useState('')
    const [endDateEvent, setEndDateEvent] = useState('')
    const [startTimeEvent, setStartTimeEvent] = useState('')
    const [endTimeEvent, setEndTimeEvent] = useState('')
    const [locationEvent, setLocationEvent] = useState('')
    const [descEvent, setDescEvent] = useState('')

    //Post My Todo
    const [tittleTodo, setTittleTodo] = useState('')
    const [startDateTodo, setStartDateTodo] = useState('')
    const [endDateTodo, setEndDateTodo] = useState('')
    const [startTimeTodo, setStartTimeTodo] = useState('')
    const [endTimeTodo, setEndTimeTodo] = useState('')
    const [locationTodo, setLocationTodo] = useState('')
    const [descTodo, setDescTodo] = useState('')

    //Update Todo and Event
    const [idEvent, setIdEvent] = useState()
    const [idTodo, setIdTodo] = useState()

    // Modal Delete
    const [showModalDeleteEvent, setShowModalDeleteEvent] = useState()
    const [showModalDeleteTodo, setShowModalDeleteTodo] = useState()

    const openEditTodo = async (id) => {
        setShowEditTodo(!showEditTodo)
        setIdTodo(id)
        try {
            const response = await Api.GetTodoById(localStorage.getItem('hris-token'), id)
            setTittleTodo(response.data[0].tittle)
            setStartDateTodo(response.data[0].start_date)
            setEndDateTodo(response.data[0].end_date)
            setStartTimeTodo(response.data[0].start_time)
            setEndTimeTodo(response.data[0].end_time)
            setLocationTodo(response.data[0].location)
            setDescTodo(response.data[0].description)
        } catch (error) {
            console.log(error)
        }
    }

    const editTodo = async () => {
        try {
            const data = {
                tittle: tittleTodo,
                start_date: startDateTodo,
                end_date: endDateTodo,
                start_time: startTimeTodo,
                end_time: endTimeTodo,
                location: locationTodo,
                description: descTodo
            }
            await Api.UpdateTodo(localStorage.getItem('hris-token'), data, idTodo).then((res) => {
                resetFormTodo()
                setRefresh(true)
                setShowEditTodo(!showEditTodo)
                toast.success('Success edit My Todo!')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    const openEditEvent = async (id) => {
        setShowEditEvent(!showEditEvent)
        setIdEvent(id)
        try {
            const response = await Api.GetEventById(localStorage.getItem('hris-token'), id)
            setTittleEvent(response.data[0].tittle)
            setStartDateEvent(response.data[0].start_date)
            setEndDateEvent(response.data[0].end_date)
            setStartTimeEvent(response.data[0].start_time)
            setEndTimeEvent(response.data[0].end_time)
            setLocationEvent(response.data[0].location)
            setDescEvent(response.data[0].description)
        } catch (error) {
            console.log(error)
        }
    }

    const editEvent = async () => {
        try {
            const data = {
                tittle: tittleEvent,
                start_date: startDateEvent,
                end_date: endDateEvent,
                start_time: startTimeEvent,
                end_time: endTimeEvent,
                location: locationEvent,
                description: descEvent
            }
            await Api.UpdateEvent(localStorage.getItem('hris-token'), data, idEvent).then((res) => {
                resetFormEvent()
                setShowEditEvent(!showEditEvent)
                setRefresh(true)
                toast.success('Success edit event!')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    const resetFormEvent = () => {
        setTittleEvent('')
        setStartDateEvent('')
        setEndDateEvent('')
        setStartTimeEvent('')
        setEndTimeEvent('')
        setLocationEvent('')
        setDescEvent('')
    }

    const resetFormTodo = () => {
        setTittleTodo('')
        setStartDateTodo('')
        setEndDateTodo('')
        setStartTimeTodo('')
        setEndTimeTodo('')
        setLocationTodo('')
        setDescTodo('')
    }

    const postEvent = async () => {
        try {
            const data = {
                tittle: tittleEvent,
                start_date: startDateEvent,
                end_date: endDateEvent,
                start_time: startTimeEvent,
                end_time: endTimeEvent,
                location: locationEvent,
                description: descEvent
            }
            await Api.PostEvent(localStorage.getItem('hris-token'), data).then((res) => {
                resetFormEvent()
                setShowAddEvent(!showAddEvent)
                setRefresh(true)
                toast.success('Success Add Event!')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    const postMyTodo = async () => {
        try {
            const data = {
                tittle: tittleTodo,
                start_date: startDateTodo,
                end_date: endDateTodo,
                start_time: startTimeTodo,
                end_time: endTimeTodo,
                location: locationTodo,
                description: descTodo
            }
            const response = await Api.PostTodo(localStorage.getItem('hris-token'), data)
            resetFormTodo()
            setRefresh(true)
            setShowAddEvent(!showAddEvent)
            console.log(response)
            toast.success('Success Add My Todo!')
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    const deleteEvent = async () => {
        try {
            await Api.DeleteEvent(localStorage.getItem('hris-token'), idEvent).then((res) => {
                setShowModalDeleteEvent(!showModalDeleteEvent)
                setRefresh(true)
                toast.success('Success Delete Event!')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete Event!')
        }
    }

    const deleteTodo = async () => {
        try {
            await Api.DeleteTodo(localStorage.getItem('hris-token'), idTodo).then((res) => {
                setShowModalDeleteTodo(!showModalDeleteTodo)
                setRefresh(true)
                toast.success('Success Delete My Todo!')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete My Todo!')
        }
    }

    const deleteEventModal = (id) => {
        setShowModalDeleteEvent(!showModalDeleteEvent)
        setIdEvent(id)
    }

    const deleteTodoModal = (id) => {
        setShowModalDeleteTodo(!showModalDeleteTodo)
        setIdTodo(id)
    }

    const localizer = momentLocalizer(moment);
    const [dataCalendar, setDataCalendar] = useState([])

    const getDataCalendar = async () => {
        try {
            const response = await Api.GetCalendar(localStorage.getItem('hris-token'))
            console.log(response)
            setDataCalendar(response.data.results.data)
        } catch (error) {
            console.log(error)
        }
    }

    const Messages = {
        today: 'Hari Ini',
        previous: 'Sebelumnya',
        next: 'Selanjutnya',
        month: 'Bulan',
        week: 'Minggu',
        day: 'Hari',
        agenda: 'Agenda',
        date: 'Tanggal',
        time: 'Waktu',
        event: 'Acara',
        allDay: 'Sepanjang Hari',
        // Dan lain-lain, tergantung pada string yang ingin Anda terjemahkan
    };

    useEffect(() => {
        getDataCalendar()
        // getDataEvent()
        // getDataTodo()
        setRefresh(false)
    }, [refresh])

    return (
        <div>
            <ModalDelete
                activeModal={showModalDeleteEvent}
                buttonClose={() => setShowModalDeleteEvent(!showModalDeleteEvent)}
                submitButton={deleteEvent}
            />

            <ModalDelete
                activeModal={showModalDeleteTodo}
                buttonClose={() => setShowModalDeleteTodo(!showModalDeleteTodo)}
                submitButton={deleteTodo}
            />

            <Modal
                activeModal={showAddEvent}
                title={'Add Event'}
                buttonClose={() => setShowAddEvent(!showAddEvent)}
                width={'406px'}
                content={
                    <div className='space-y-[16px] w-full'>
                        <div className='flex bg-[#9F0518] text-[11px] gap-[30px] w-[full] py-[8px] pl-[21px] rounded-md'>
                            <button onClick={() => setIndex(0)} className={index === 0 ? 'font-semibold text-white' : 'font-medium text-dark-5'}>Event</button>
                            <button onClick={() => setIndex(1)} className={index === 1 ? 'font-semibold text-white' : 'font-medium text-dark-5'}>My To Do</button>
                        </div>

                        <div className='space-y-[14px]'>
                            {index === 0 ? (
                                <>
                                    <div>
                                        <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>Title <span className='text-[#C1121F]'>*</span></h1>
                                        <input onChange={(e) => setTittleEvent(e.target.value)} value={tittleEvent} type="text" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-full' placeholder='Input event title.....' />
                                    </div>
                                    <div className='flex gap-[6px]'>
                                        <div>
                                            <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>Start date</h1>
                                            <input onChange={(e) => setStartDateEvent(e.target.value)} value={startDateEvent} type="date" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-[157px]' />
                                        </div>
                                        <div>
                                            <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>End Date </h1>
                                            <input onChange={(e) => setEndDateEvent(e.target.value)} value={endDateEvent} type="date" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-[157px]' />
                                        </div>
                                    </div>
                                    <div className='flex gap-[6px]'>
                                        <div>
                                            <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>Start Time</h1>
                                            <input onChange={(e) => setStartTimeEvent(e.target.value)} value={startTimeEvent} type="time" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-[157px]' />
                                        </div>
                                        <div>
                                            <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>End Time </h1>
                                            <input onChange={(e) => setEndTimeEvent(e.target.value)} value={endTimeEvent} type="time" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-[157px]' />
                                        </div>
                                    </div>
                                    <div>
                                        <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>Location</h1>
                                        <input onChange={(e) => setLocationEvent(e.target.value)} value={locationEvent} type="text" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-full' placeholder='Input location event...' />
                                    </div>
                                    <div>
                                        <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>Description</h1>
                                        <textarea onChange={(e) => setDescEvent(e.target.value)} value={descEvent} rows={3} className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-full resize-none' placeholder='type details activity...' />
                                    </div>
                                    <div className='relative pb-[37px]'>
                                        <div className="flex gap-[6px] absolute right-0">
                                            <button onClick={() => setShowAddEvent(!showAddEvent)} className=' py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px] bg-[#ECECEC]'>
                                                <h1 className='text-[#0E5073] text-sm font-[500]'>Cancel</h1>
                                            </button>
                                            <button onClick={postEvent} className=' py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px] border bg-[#0E5073]'>
                                                <h1 className='text-[white] text-sm font-[500]'>Add</h1>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div>
                                        <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>Title <span className='text-[#C1121F]'>*</span></h1>
                                        <input onChange={(e) => setTittleTodo(e.target.value)} value={tittleTodo} type="text" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-full' placeholder='Input todo title.....' />
                                    </div>
                                    <div className='flex gap-[6px]'>
                                        <div>
                                            <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>Start date</h1>
                                            <input onChange={(e) => setStartDateTodo(e.target.value)} value={startDateTodo} type="date" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-[157px]' />
                                        </div>
                                        <div>
                                            <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>End Date </h1>
                                            <input onChange={(e) => setEndDateTodo(e.target.value)} value={endDateTodo} type="date" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-[157px]' />
                                        </div>
                                    </div>
                                    <div className='flex gap-[6px]'>
                                        <div>
                                            <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>Start Time</h1>
                                            <input onChange={(e) => setStartTimeTodo(e.target.value)} value={startTimeTodo} type="time" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-[157px]' />
                                        </div>
                                        <div>
                                            <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>End Time </h1>
                                            <input onChange={(e) => setEndTimeTodo(e.target.value)} value={endTimeTodo} type="time" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-[157px]' />
                                        </div>
                                    </div>
                                    <div>
                                        <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>Location</h1>
                                        <input onChange={(e) => setLocationTodo(e.target.value)} value={locationTodo} type="text" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-full' placeholder='Input location todo...' />
                                    </div>
                                    <div>
                                        <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>Description</h1>
                                        <textarea onChange={(e) => setDescTodo(e.target.value)} value={descTodo} rows={3} className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-full resize-none' placeholder='type details activity...' />
                                    </div>

                                    <div className='relative pb-[37px]'>
                                        <div className="flex gap-[6px] absolute right-0">
                                            <button onClick={() => setShowAddEvent(!showAddEvent)} className=' py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px] bg-[#ECECEC]'>
                                                <h1 className='text-[#0E5073] text-sm font-[500]'>Cancel</h1>
                                            </button>
                                            <button onClick={postMyTodo} className=' py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px] border bg-[#0E5073]'>
                                                <h1 className='text-[white] text-sm font-[500]'>Add</h1>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showEditEvent}
                title={'Edit Event'}
                buttonClose={() => setShowEditEvent(!showEditEvent)}
                width={'406px'}
                content={
                    <div className='space-y-[16px] w-full'>
                        <div className='space-y-[14px]'>
                            <div>
                                <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>Title <span className='text-[#C1121F]'>*</span></h1>
                                <input value={tittleEvent} onChange={(e) => setTittleEvent(e.target.value)} type="text" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-full' placeholder='Input event title.....' />
                            </div>
                            <div className='flex gap-[6px]'>
                                <div>
                                    <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>Start date</h1>
                                    <input value={startDateEvent} onChange={(e) => setStartDateEvent(e.target.value)} type="date" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-[157px]' />
                                </div>
                                <div>
                                    <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>End Date </h1>
                                    <input value={endDateEvent} onChange={(e) => setEndDateEvent(e.target.value)} type="date" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-[157px]' />
                                </div>
                            </div>
                            <div className='flex gap-[6px]'>
                                <div>
                                    <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>Start Time</h1>
                                    <input value={startTimeEvent} onChange={(e) => setStartTimeEvent(e.target.value)} type="time" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-[157px]' />
                                </div>
                                <div>
                                    <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>End Time </h1>
                                    <input value={endTimeEvent} onChange={(e) => setEndTimeEvent(e.target.value)} type="time" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-[157px]' />
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>Location</h1>
                                <input value={locationEvent} onChange={(e) => setLocationEvent(e.target.value)} type="text" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-full' placeholder='Input location event...' />
                            </div>
                            <div>
                                <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>Description</h1>
                                <textarea value={descEvent} onChange={(e) => setDescEvent(e.target.value)} rows={3} className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-full resize-none' placeholder='type details activity...' />
                            </div>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[6px] absolute right-0">
                                <button onClick={() => setShowEditEvent(!showEditEvent)} className=' py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px] bg-[#ECECEC]'>
                                    <h1 className='text-[#0E5073] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={editEvent} className=' py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px] border bg-[#0E5073]'>
                                    <h1 className='text-[white] text-sm font-[500]'>Save</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showEditTodo}
                title={'Edit My Todo'}
                buttonClose={() => setShowEditTodo(!showEditTodo)}
                width={'406px'}
                content={
                    <div className='space-y-[16px] w-full'>
                        <div className='space-y-[14px]'>
                            <div>
                                <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>Title <span className='text-[#C1121F]'>*</span></h1>
                                <input value={tittleTodo} onChange={(e) => setTittleTodo(e.target.value)} type="text" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-full' placeholder='Input edit title...' />
                            </div>
                            <div className='flex gap-[6px]'>
                                <div>
                                    <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>Start date</h1>
                                    <input value={startDateTodo} onChange={(e) => setStartDateTodo(e.target.value)} type="date" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-[157px]' />
                                </div>
                                <div>
                                    <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>End Date </h1>
                                    <input value={endDateTodo} onChange={(e) => setEndDateTodo(e.target.value)} type="date" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-[157px]' />
                                </div>
                            </div>
                            <div className='flex gap-[6px]'>
                                <div>
                                    <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>Start Time</h1>
                                    <input value={startTimeTodo} onChange={(e) => setStartTimeTodo(e.target.value)} type="time" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-[157px]' />
                                </div>
                                <div>
                                    <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>End Time </h1>
                                    <input value={endTimeTodo} onChange={(e) => setEndTimeTodo(e.target.value)} type="time" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-[157px]' />
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>Location</h1>
                                <input value={locationTodo} onChange={(e) => setLocationTodo(e.target.value)} type="text" className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-full' placeholder='Input location todo...' />
                            </div>
                            <div>
                                <h1 className='text-[10px] text-dark-5 mb-[4px] font-medium'>Description</h1>
                                <textarea value={descTodo} onChange={(e) => setDescTodo(e.target.value)} rows={3} className='py-[10px] px-[10px] text-[11px] text-[#A8A8A8] border rounded-md w-full resize-none' placeholder='type details activity...' />
                            </div>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[6px] absolute right-0">
                                <button onClick={() => setShowEditTodo(!showEditTodo)} className=' py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px] bg-[#ECECEC]'>
                                    <h1 className='text-[#0E5073] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={editTodo} className=' py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px] border bg-[#0E5073]'>
                                    <h1 className='text-[white] text-sm font-[500]'>Save</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <div className='bg-[#F8F9FB] min-h-screen'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={'Kalender'} LinkBack={'/dashboard'} />
                    <div className='space-y-[24px] bg-white pl-[28px] py-[40px] rounded-[12px] border w-full h-full flex gap-[24px]'>
                        <div className='space-y-[24px] w-full'>
                            {/* <div>
                            <h1 className='text-[20px]'>Kalender</h1>
                            <h2 className='text-[10px] text-dark-5'>List of  Calendar</h2>
                        </div> */}

                            {/* <div className='flex gap-[6px] items-center'>

                                <button onClick={() => setShowAddEvent(!showAddEvent)} className='bg-[#0E5073] py-2  pl-4 pr-2 rounded-[10px] flex items-center gap-[16px] text-white'>
                                    Add Event
                                    <AiOutlinePlus className='text-xl text-white' />
                                </button>
                            </div> */}
                            <div className='w-full'>
                                {/* <CalendarTable
                                    refreshPage={refresh} /> */}
                                <Calendar
                                    localizer={localizer}
                                    events={dataCalendar}
                                    startAccessor="start"
                                    endAccessor="end"
                                    style={{ height: 500 }}
                                    messages={Messages}
                                />

                            </div>
                        </div>

                        <div className='pl-[18px] pr-[19px] h-[610px] min-w-fit overflow-y-auto scrollbar-hide'>
                            {data.length > 0 &&
                                <h1 className='text-dark-1 font-semibold text-[18px] mb-[19px]'>Event</h1>
                            }
                            {Object.values(data).map((item, idx) => (
                                <div key={idx} className='mb-[23px]'>
                                    <h1 className='text-dark-3 font-medium text-[12px] mb-[13px]'>{item.start_date ? moment(item.start_date).format('dddd, DD MMMM YYYY') : '-'}</h1>
                                    <div className='bg-[#C1121F1A] rounded-xl text-dark-4 pl-[19.13px] pr-[35px] w-[244px] text-[11px] pt-[26px] pb-[16px] relative'>
                                        <div className='space-y-[10px]'>
                                            <div className="flex gap-[18.2px] items-center">
                                                <BsFileText className='text-2xl' />
                                                <h1>{item.tittle ? item.tittle : '-'}</h1>
                                            </div>
                                            <div className="flex gap-[18.2px] items-center">
                                                <AiOutlineClockCircle className='text-2xl' />
                                                <h1>{item.start_time} - {item.end_time}</h1>
                                            </div>
                                            <div className="flex gap-[18.2px] items-center">
                                                <IoLocationOutline className='text-2xl' />
                                                <h1>{item.location ? item.location : '-'}</h1>
                                            </div>

                                            <div className='flex gap-[5px] absolute right-0 bottom-[-6px]'>
                                                <button onClick={() => openEditEvent(item.id)} className='w-[26px] h-[26px] rounded-full  bg-[#003049] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-white text-lg' />
                                                </button>
                                                <button onClick={() => deleteEventModal(item.id)} className='w-[26px] h-[26px] rounded-full  bg-[#003049] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-white text-lg' />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className='mb-[23px]'>
                                {dataTodo.length > 0 &&
                                    <h1 className='text-dark-1 font-semibold text-[18px] mb-[19px]'>My Todo</h1>
                                }
                                {Object.values(dataTodo).map((item, idx) => (
                                    <>
                                        <h1 className='text-dark-3 font-medium text-[12px] mb-[13px]'>{item.start_date ? moment(item.start_date).format('dddd, DD MMMM YYYY') : '-'}</h1>
                                        <div className='bg-[#219EBC1A] rounded-xl text-dark-4 pl-[19.13px] pr-[35px] w-[244px] text-[11px] pt-[26px] pb-[16px] relative'>
                                            <div className='space-y-[10px]'>
                                                <div className="flex gap-[18.2px] items-center">
                                                    <BsFileText className='text-2xl' />
                                                    <h1>{item.tittle ? item.tittle : '-'}</h1>
                                                </div>
                                                <div className="flex gap-[18.2px] items-center">
                                                    <AiOutlineClockCircle className='text-2xl' />
                                                    <h1>{item.start_time} - {item.end_time}</h1>
                                                </div>
                                                <div className="flex gap-[18.2px] items-center">
                                                    <IoLocationOutline className='text-2xl' />
                                                    <h1>{item.location ? item.location : '-'}</h1>
                                                </div>

                                                <div className='flex gap-[5px] absolute right-0 bottom-[-6px]'>
                                                    <button onClick={() => openEditTodo(item.id)} className='w-[26px] h-[26px] rounded-full  bg-[#003049] flex items-center justify-center'>
                                                        <HiOutlinePencil className='text-white text-lg' />
                                                    </button>
                                                    <button onClick={() => deleteTodoModal(item.id)} className='w-[26px] h-[26px] rounded-full  bg-[#003049] flex items-center justify-center'>
                                                        <CgTrashEmpty className='text-white text-lg' />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FiturCalendar