import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { BiDownload } from 'react-icons/bi'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiPaperClip } from 'react-icons/hi'
import { IoMdArrowDropdown } from 'react-icons/io'
import { useLocation } from 'react-router-dom'
import Api from '../../../../Api'
import { HeaderProfile, LogoH } from '../../../../assets'
import { Modal, ModalDelete, Navbar } from '../../../../components'
import InputOption from '../../../../components/Input/inputOption'
import imageHandle from '../../../../utils/imageHandle'
import Dependents from './components/Dependents'
import EmergencyContact from './components/EmergencyContact'
import File from './components/File'
import Imigration from './components/Imigration'
import JobDetails from './components/JobDetails'
import Payroll from './components/Payroll'
import Penghargaan from './components/Penghargaan'
import PersonalDetail from './components/PersonalDetail'
import Qualifications from './components/Qualifications'
import ReportTo from './components/ReportTo'
import Training from './components/Training'
import TugasBelajar from './components/TugasBelajar'

const MyProfile = () => {
    const [index, setIndex] = useState(0)

    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOptionId, setSelectedOptionId] = useState('');

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            borderRadius: '6px',
            border: '1px solid #A8A8A8',
            fontSize: '12px',
            paddingLeft: '2px',
            width: '100%',
            color: '#A8A8A8',
        }),
    };

    //Emergency Contact Modal
    const [showAddEmergencyContact, setShowAddEmergencyContact] = useState()
    const [showEditEmergencyContact, setShowEditEmergencyContact] = useState()
    //Dependents Modal
    const [showAddDependents, setShowAddDependents] = useState()
    const [showEditDependents, setShowEditDependents] = useState()
    //Imigration Modal
    const [showAddImigration, setShowAddImigration] = useState()
    const [showEditImigration, setShowEditImigration] = useState()
    //Report-to Modal
    const [showAddSupervisor, setShowAddSupervisor] = useState()
    const [showEditSupervisor, setShowEditSupervisor] = useState()
    const [showAddSubordinate, setShowAddSubordinate] = useState()
    const [showEditSubordinate, setShowEditSubordinate] = useState()
    //Qualification Modal
    const [showAddWorkExperience, setShowAddWorkExperience] = useState()
    const [showEditWorkExperience, setShowEditWorkExperience] = useState()
    const [showAddEducation, setShowAddEducation] = useState()
    const [showEditEducation, setShowEditEducation] = useState()
    const [showModalDeleteIjazah, setShowModalDeleteIjazah] = useState(false)
    const [showAddSkills, setShowAddSkills] = useState()
    const [showEditSkills, setShowEditSkills] = useState()
    const [showAddLanguages, setShowAddLanguages] = useState()
    const [showEditLanguages, setShowEditLanguages] = useState()
    const [showAddLicense, setShowAddLicense] = useState()
    const [showEditLicense, setShowEditLicense] = useState()

    const params = useLocation()
    const [userId, setUserId] = useState('')

    const Fetch = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setUserId(response.data.results.user.id)
        } catch (error) {
            console.log(error)
        }
    }

    // State Personal Detail
    const [namePersonalDetail, setNamePersonalDetail] = useState('')
    const [imagePersonalDetail, setImagePersonalDetail] = useState('')
    const [jobPositionPersonalDetail, setJobPositionPersonalDetail] = useState('')

    const getDataPersonalDetail = async () => {
        try {
            if (!params.state) {
                const response = await Api.GetPersonalDetail(localStorage.getItem('hris-token'))
                setNamePersonalDetail(response.data[0].fullname)
                setImagePersonalDetail(response.data[0].user_detail.image)
                setJobPositionPersonalDetail(response.data[0].user_detail.job_position.position_name)
                console.log(response.data, 'gerPerDet')

            } else {
                const response = await Api.GetPersonalDetailById(localStorage.getItem('hris-token'), params.state.employeeId)
                setNamePersonalDetail(response.data[0].fullname)
                setImagePersonalDetail(response.data[0].user_detail.image)
                setJobPositionPersonalDetail(response.data[0].user_detail.job_position.position_name)
            }
        } catch (error) {
            console.log(error)
        }
    }

    // Emergency Contact
    const [nameEmergencyContact, setNameEmergencyContact] = useState()
    const [telephoneEmergencyContact, setTelephoneEmergencyContact] = useState()
    const [mobileEmergencyContact, setMobileEmergencyContact] = useState()
    const [addresEmergencyContact, setAddressEmergencyContact] = useState()
    const [relationshipEmergencyContact, setRelationshipEmergencyContact] = useState()
    const [idEmergencyContact, setIdEmergencyContact] = useState()

    const resetFormEmergencyContact = () => {
        setNameEmergencyContact('')
        setTelephoneEmergencyContact('')
        setMobileEmergencyContact('')
        setAddressEmergencyContact('')
        setRelationshipEmergencyContact('')
        setIdEmergencyContact('')
    }

    const editEmergencyContact = async () => {
        try {
            const data = {
                name: nameEmergencyContact,
                // telephone: telephoneEmergencyContact,
                mobile: mobileEmergencyContact,
                address: addresEmergencyContact,
                relationship: relationshipEmergencyContact
            }
            await Api.UpdateEmergencyContact(localStorage.getItem('hris-token'), data, idEmergencyContact).then((res) => {
                resetFormEmergencyContact()
                setRefresh(true)
                toast.success('Success Edit Emergency Contact')
                setShowEditEmergencyContact(!showEditEmergencyContact)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')

        }
    }

    const openEditEmergencyContact = async (id) => {
        setShowEditEmergencyContact(!showEditEmergencyContact)
        setIdEmergencyContact(id)
        try {
            const response = await Api.GetEmergencyContactById(localStorage.getItem('hris-token'), id)
            setNameEmergencyContact(response.data.name)
            setTelephoneEmergencyContact(response.data.telephone)
            setMobileEmergencyContact(response.data.mobile)
            setRelationshipEmergencyContact(response.data.relationship)
            setAddressEmergencyContact(response.data.address)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const postEmergencyContact = async () => {
        try {
            const data = {
                name: nameEmergencyContact,
                // telephone: telephoneEmergencyContact,
                mobile: mobileEmergencyContact,
                address: addresEmergencyContact,
                relationship: relationshipEmergencyContact
            }
            if (!params.state) {
                const response = await Api.PostEmergencyContact(localStorage.getItem('hris-token'), data, userId)
                console.log(response)
                resetFormEmergencyContact()
                setRefresh(true)
                toast.success('Success Create Emergency Contact')
                setShowAddEmergencyContact(!showAddEmergencyContact)
            } else {
                const response = await Api.PostEmergencyContact(localStorage.getItem('hris-token'), data, params.state.employeeId)
                console.log(response)
                resetFormEmergencyContact()
                setRefresh(true)
                toast.success('Success Create Emergency Contact')
                setShowAddEmergencyContact(!showAddEmergencyContact)
            }
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    // Dependents
    const [nameDependents, setNameDependents] = useState()
    const [relationshipDependents, setRelationshipDependents] = useState()
    const [birthDateDependents, setBirthDateDependents] = useState()
    const [idDependents, setIdDependents] = useState()

    const resetFormDependents = () => {
        setNameDependents('')
        setRelationshipDependents('')
        setBirthDateDependents('')
        setIdDependents('')
    }

    const postDependents = async () => {
        try {
            const data = {
                name: nameDependents,
                relationship: relationshipDependents,
                birthday: birthDateDependents
            }
            if (!params.state) {
                await Api.PostDependents(localStorage.getItem('hris-token'), data, userId).then((res) => {
                    resetFormDependents()
                    setRefresh(true)
                    toast.success('Success Create Dependents')
                    setShowAddDependents(!showAddDependents)
                }).catch((err) => {
                    console.log(err)
                })
            } else {
                await Api.PostDependents(localStorage.getItem('hris-token'), data, params.state.employeeId).then((res) => {
                    resetFormDependents()
                    setRefresh(true)
                    toast.success('Success Create Dependents')
                    setShowAddDependents(!showAddDependents)
                }).catch((err) => {
                    console.log(err)
                })
            }
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    const openEditDependents = async (id) => {
        setShowEditDependents(!showEditDependents)
        setIdDependents(id)
        try {
            const response = await Api.GetDependentsById(localStorage.getItem('hris-token'), id)
            setNameDependents(response.data[0].name)
            setRelationshipDependents(response.data[0].relationship)
            setBirthDateDependents(response.data[0].birthday)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const editDependents = async () => {
        try {
            const data = {
                name: nameDependents,
                relationship: relationshipDependents,
                birthday: birthDateDependents
            }
            await Api.UpdateDependents(localStorage.getItem('hris-token'), data, idDependents).then((res) => {
                resetFormDependents()
                setRefresh(true)
                toast.success('Success Edit Dependents')
                setShowEditDependents(!showEditDependents)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Error Edit Dependents')
        }
    }



    // Imigration
    const [documentType, setDocumentType] = useState()
    const [number, setNumber] = useState()
    const [issueDateImmigration, setIssueDateImmigration] = useState()
    const [expiryDateImmigration, setExpiryDateImmigration] = useState()
    const [eligibleStatus, setEligibleStatus] = useState()
    const [issuedby, setIssuedby] = useState()
    const [eligileIssueDate, setEligileIssueDate] = useState()
    const [comment, setComment] = useState()
    const [idImmigration, setIdImmigration] = useState()

    const resetFormImigration = () => {
        setDocumentType('')
        setNumber('')
        setIssueDateImmigration('')
        setExpiryDateImmigration('')
        setEligibleStatus('')
        setIssuedby('')
        setEligileIssueDate('')
        setComment('')
        setIdImmigration('')
    }

    const postImmigration = async () => {
        try {
            const data = {
                documentType: documentType,
                number: number,
                issueDate: issueDateImmigration,
                expiryDate: expiryDateImmigration,
                issuedby: issuedby,
                note: comment,
            }
            if (!params.state) {
                await Api.PostImmigration(localStorage.getItem('hris-token'), data, userId).then((res) => {
                    resetFormImigration()
                    setRefresh(true)
                    toast.success('Success Create Immigration')
                    setShowAddImigration(!showAddImigration)
                }).catch((err) => {
                    console.log(err)
                })
            } else {
                await Api.PostImmigration(localStorage.getItem('hris-token'), data, params.state.employeeId).then((res) => {
                    resetFormImigration()
                    setRefresh(true)
                    toast.success('Success Create Immigration')
                    setShowAddImigration(!showAddImigration)
                }).catch((err) => {
                    console.log(err)
                })
            }
        } catch (error) {
            console.log(error)
            toast.error('Failed Create Immigration')
        }
    }

    const openEditImirgration = async (id) => {
        setShowEditImigration(!showEditImigration)
        setIdImmigration(id)
        try {
            const response = await Api.GetImmigrationById(localStorage.getItem('hris-token'), id)
            setDocumentType(response.data[0].documentType)
            setNumber(response.data[0].number)
            setIssueDateImmigration(response.data[0].issueDate)
            setExpiryDateImmigration(response.data[0].expiryDate)
            setEligibleStatus(response.data[0].eligibleStatus)
            setIssuedby(response.data[0].issuedby)
            setEligileIssueDate(response.data[0].eligileIssueDate)
            setComment(response.data[0].note)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const editImmigration = async () => {
        try {
            const data = {
                documentType: documentType,
                number: number,
                issueDate: issueDateImmigration,
                expiryDate: expiryDateImmigration,
                issuedby: issuedby,
                note: comment,
            }
            await Api.UpdateImmigration(localStorage.getItem('hris-token'), data, idImmigration).then((res) => {
                resetFormImigration()
                setRefresh(true)
                toast.success('Success Edit Immigration')
                setShowEditImigration(!showEditImigration)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Success Edit Immigration')
        }
    }

    // Report To 
    const [nameSupervisor, setNameSupervisor] = useState('')
    const [reportingMethodSupervisor, setReportingMethodSupervisor] = useState('')
    const [nameSubordinate, setNameSubordinate] = useState('')
    const [reportingMethodSubordinate, setReportingMethodSubordinate] = useState('')
    const [idReportToSupervisor, setIdReportSupervisor] = useState()
    const [refresh, setRefresh] = useState(false)

    const resetFormReportTo = () => {
        setNameSupervisor('')
        setReportingMethodSupervisor('')
    }

    const openEditReportToSupervisor = async (id) => {
        setShowEditSupervisor(!showEditSupervisor)
        setIdReportSupervisor(id)
        try {
            const response = await Api.GetReportToSupervisorById(localStorage.getItem('hris-token'), id)
            setNameSupervisor(response.data.name)
            setReportingMethodSupervisor(response.data.report_method.name)
        } catch (error) {
            console.log(error)
        }
    }

    const editSupervisor = async () => {
        try {
            const data = {
                name: nameSupervisor,
                report_method: reportingMethodSupervisor
            }
            await Api.UpdateReportToSupervisor(localStorage.getItem('hris-token'), data, idReportToSupervisor).then((res) => {
                resetFormReportTo()
                setRefresh(true)
                toast.success('Success Edit')
                setShowEditSupervisor(!showEditSupervisor)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Failed Edit')
        }
    }

    // const getReportMethod = async () => {
    //     try {
    //         if (!params.state) {
    //             const response = await Api.GetReportMethod(localStorage.getItem('hris-token'))
    //             setDataReportMethod(response.data)
    //         } else {
    //             const response = await Api.GetReportMethodByParam(localStorage.getItem('hris-token'), params.state.employeeId)
    //             setDataReportMethod(response.data)
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // Qualification
    const [companyNameWorkExperience, setCompanyNameWorkExperience] = useState('')
    const [jobTittleeWorkExperience, setJobTittleeWorkExperience] = useState('')
    const [startDateWorkExperience, setStartDateWorkExperience] = useState('')
    const [endDateWorkExperience, setEndDateWorkExperience] = useState('')
    const [commentWorkExperience, setCommentWorkExperience] = useState('')

    const [nameSkill, setNameSkill] = useState('')
    const [yearExperienceSkill, setYearExperienceSkill] = useState('')
    const [commentSkill, setCommentSkill] = useState('')

    const [educationLevel, setEducationLevel] = useState('')
    const [educationLevel2, setEducationLevel2] = useState('')
    const [institute, setInstitute] = useState('')
    const [major, setMajor] = useState('')
    const [startYear, setStartYear] = useState('')
    const [endYear, setEndYear] = useState('')
    const [gpa, setGpa] = useState('')
    const [file, setFile] = useState('')
    const [dataFile, setDataFile] = useState('')
    const [namePath, setNamePath] = useState('')
    const [rumpunPendidikan, setRumpunPendidikan] = useState('')
    const [dataRumpunPendidikan, setDataRumpunPendidikan] = useState([
        {
            label: "Agama (Keyakinan tentang Ketuhanan serta teks suci agama)",
            value: "Agama (Keyakinan tentang Ketuhanan serta teks suci agama)"
        },
        {
            label: "Humaniora (Nilai kemanusiaan dan pemikiran manusia)",
            value: "Humaniora (Nilai kemanusiaan dan pemikiran manusia)"
        },
        {
            label: "Sosial (Hubungan antar manusia dan fenomena masyarakat)",
            value: "Sosial (Hubungan antar manusia dan fenomena masyarakat)"
        },
        {
            label: "Alam (Alam semesta)",
            value: "Alam (Alam semesta)"
        },
        {
            label: "Formal (Sistem formal teoritis)",
            value: "Formal (Sistem formal teoritis)"
        },
        {
            label: "Terapan (Aplikasi ilmu bagi kehidupan manusia)",
            value: "Terapan (Aplikasi ilmu bagi kehidupan manusia)"
        }
    ])
    const [dataTingkatPendidikan, setDataTingkatPendidikan] = useState([
        {
            "value": "S1",
            "label": "S1"
        },
        {
            "value": "S2",
            "label": "S2"
        },
        {
            "value": "S3",
            "label": "S3"
        },
        {
            "value": "Lainnya...",
            "label": "Lainnya..."
        },
    ])

    const [languages, setLanguages] = useState('')
    const [fluency, setFluency] = useState('')
    const [competency, setCompetency] = useState('')
    const [commentLanguages, setCommentLanguages] = useState('')

    const [licenseType, setLicenseType] = useState('')
    const [licenseNumber, setLicenseNumber] = useState('')
    const [issuedDate, setIssuedDate] = useState('')
    const [expiryDate, setExpiryDate] = useState('')

    const [idWorkExperience, setIdWorkExperience] = useState('')
    const [idWorkEducation, setIdWorkEducation] = useState('')
    const [idWorkSkill, setIdWorkSkill] = useState('')
    const [idWorkLisence, setIdWorkLisence] = useState('')
    const [idWWorkLanguage, setIdWorkLanguage] = useState('')

    const resetFormQualification = () => {
        setCompanyNameWorkExperience('')
        setJobTittleeWorkExperience('')
        setStartDateWorkExperience('')
        setEndDateWorkExperience('')
        setCommentWorkExperience('')
        setNameSkill('')
        setYearExperienceSkill('')
        setCommentSkill('')
        setEducationLevel('')
        setInstitute('')
        setMajor('')
        setStartYear('')
        setEndYear('')
        setGpa('')
        setFile('')
        setDataFile('')
        setNamePath('')
        setRumpunPendidikan('')
        setLanguages('')
        setFluency('')
        setCompetency('')
        setCommentLanguages('')
        setLicenseType('')
        setLicenseNumber('')
        setIssuedDate('')
        setExpiryDate('')
        setIdWorkExperience('')
        setIdWorkEducation('')
        setIdWorkSkill('')
        setIdWorkLanguage('')
    }

    const postWorkExperience = async () => {
        try {
            const data = {
                companyName: companyNameWorkExperience,
                jobTitle: jobTittleeWorkExperience,
                comment: commentWorkExperience,
                startDate: startDateWorkExperience,
                endDate: endDateWorkExperience
            }
            if (!params.state) {
                const response = await Api.PostWorkExperience(localStorage.getItem('hris-token'), data, userId)
                console.log(response)
                resetFormQualification()
                setRefresh(true)
                toast.success('Berhasil menambahkan data!')
                setShowAddWorkExperience(!showAddWorkExperience)
            } else {
                const response = await Api.PostWorkExperience(localStorage.getItem('hris-token'), data, params.state.employeeId)
                console.log(response)
                resetFormQualification()
                setRefresh(true)
                toast.success('Berhasil menambahkan data!')
                setShowAddWorkExperience(!showAddWorkExperience)
            }
        } catch (error) {
            console.log(error)
            if (error?.response?.data?.errors) {
                toast.error(error?.response?.data?.errors)
            } else if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message)
            } else {
                toast.error("Gagal menambahkan data!")
            }
        }
    }

    const editWorkExperience = async () => {
        try {
            const data = {
                companyName: companyNameWorkExperience,
                jobTitle: jobTittleeWorkExperience,
                comment: commentWorkExperience,
                startDate: startDateWorkExperience,
                endDate: endDateWorkExperience
            }
            await Api.UpdateWorkExperience(localStorage.getItem('hris-token'), data, idWorkExperience).then((res) => {
                resetFormQualification()
                setRefresh(true)
                toast.success('Berhasil mengubah data!')
                setShowEditWorkExperience(!showEditWorkExperience)
            }).catch((error) => {
                console.log(error)
                console.log(error)
                if (error?.response?.data?.errors) {
                    toast.error(error?.response?.data?.errors)
                } else if (error?.response?.data?.message) {
                    toast.error(error?.response?.data?.message)
                } else {
                    toast.error("Gagal mengubah data!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal mengubah data!')
        }
    }

    const openEditWorkExperience = async (id) => {
        setShowEditWorkExperience(!showEditWorkExperience)
        setIdWorkExperience(id)
        try {
            const response = await Api.GetWorkExperienceById(localStorage.getItem('hris-token'), id)
            setCompanyNameWorkExperience(response.data[0].companyName)
            setJobTittleeWorkExperience(response.data[0].jobTitle)
            setStartDateWorkExperience(response.data[0].startDate)
            setEndDateWorkExperience(response.data[0].endDate)
            setCommentWorkExperience(response.data[0].comment)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const postWorkSkill = async () => {
        try {
            const data = {
                skill: nameSkill,
                yearsOfExperience: yearExperienceSkill,
                comment: commentSkill
            }

            if (!params.state) {

                await Api.PostWorkSkill(localStorage.getItem('hris-token'), data, userId).then((res) => {
                    resetFormQualification()
                    setRefresh(true)
                    toast.success('Berhasil menambahkan data!')
                    setShowAddSkills(!showAddSkills)
                }).catch((error) => {
                    console.log(error)
                    if (error?.response?.data?.errors) {
                        toast.error(error?.response?.data?.errors)
                    } else if (error?.response?.data?.message) {
                        toast.error(error?.response?.data?.message)
                    } else {
                        toast.error("Gagal menambahkan data!")
                    }
                })
            } else {

                await Api.PostWorkSkill(localStorage.getItem('hris-token'), data, params.state.employeeId).then((res) => {
                    resetFormQualification()
                    setRefresh(true)
                    toast.success('Berhasil menambahkan data!')
                    setShowAddSkills(!showAddSkills)
                }).catch((error) => {
                    console.log(error)
                    if (error?.response?.data?.errors) {
                        toast.error(error?.response?.data?.errors)
                    } else if (error?.response?.data?.message) {
                        toast.error(error?.response?.data?.message)
                    } else {
                        toast.error("Gagal menambahkan data!")
                    }
                })
            }
        } catch (error) {
            console.log(error)
            toast.error('Failed Create!')
        }
    }

    const editWorkSkill = async () => {
        try {
            const data = {
                skill: nameSkill,
                yearsOfExperience: yearExperienceSkill,
                comment: commentSkill
            }

            await Api.UpdateWorkSkill(localStorage.getItem('hris-token'), data, idWorkSkill).then((res) => {
                resetFormQualification()
                setRefresh(true)
                setShowEditSkills(!showEditSkills)
                toast.success('Berhasil menambahkan data!')
            }).catch((error) => {
                console.log(error)
                if (error?.response?.data?.errors) {
                    toast.error(error?.response?.data?.errors)
                } else if (error?.response?.data?.message) {
                    toast.error(error?.response?.data?.message)
                } else {
                    toast.error("Gagal mengubah data!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal mengubah data!')
        }
    }

    const openEditSkill = async (id) => {
        setShowEditSkills(!showEditSkills)
        setIdWorkSkill(id)
        try {
            const response = await Api.GetWorkSkillById(localStorage.getItem('hris-token'), id)
            setNameSkill(response.data[0].skill)
            setYearExperienceSkill(response.data[0].yearsOfExperience)
            setCommentSkill(response.data[0].comment)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const postWorkEducation = async () => {
        try {
            if (educationLevel === '' || educationLevel === null) {
                toast.error("Tingkat pendidikan wajib diisi!")
            } else if (institute === '' || institute === null) {
                toast.error("Institusi Pendidikan wajib diisi!")
            } else if (major === '' || major === null) {
                toast.error("Program Studi/Keahlian wajib diisi!")
            } else if (rumpunPendidikan === '' || rumpunPendidikan === null) {
                toast.error("Rumpun Pendidikan wajib diisi!")
            } else if (startYear === '' || startYear === null) {
                toast.error("Tahun Mulai wajib diisi!")
            } else if (endYear === '' || endYear === null) {
                toast.error("Tahun Akhir wajib diisi!")
            } else if (gpa === '' || gpa === null) {
                toast.error("Nilai Akhir wajib diisi!")
            } else if (file === '' || file === null) {
                toast.error("Ijazah wajib diisi!")
            } else {
                const data = {
                    level: educationLevel === "Lainnya..." ? educationLevel2 : educationLevel,
                    institute: institute,
                    major: major,
                    start_year: startYear,
                    end_year: endYear,
                    gap: gpa,
                    rumpun_pendidikan: rumpunPendidikan,
                    file: file
                }
                const formData = new FormData();
                for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                        formData.append(key, data[key]);
                    }
                }
                // for (const pair of formData.entries()) {
                //     console.log(pair[0] + ', ' + pair[1]);
                // }
                if (!params.state) {
                    await Api.PostWorkEducation(localStorage.getItem('hris-token'), formData, userId).then((res) => {
                        resetFormQualification()
                        setRefresh(true)
                        toast.success('Berhasil menambahkan data!')
                        setShowAddEducation(!showAddEducation)
                    }).catch((error) => {
                        console.log(error)
                        if (error?.response?.data?.errors) {
                            toast.error(error?.response?.data?.errors)
                        } else if (error?.response?.data?.message) {
                            toast.error(error?.response?.data?.message)
                        } else {
                            toast.error("Gagal menambahkan data!")
                        }
                    })
                } else {
                    await Api.PostWorkEducation(localStorage.getItem('hris-token'), formData, params.state.employeeId).then((res) => {
                        resetFormQualification()
                        setRefresh(true)
                        toast.success('Berhasil menambahkan data!')
                        setShowAddEducation(!showAddEducation)
                    }).catch((error) => {
                        console.log(error)
                        if (error?.response?.data?.errors) {
                            toast.error(error?.response?.data?.errors)
                        } else if (error?.response?.data?.message) {
                            toast.error(error?.response?.data?.message)
                        } else {
                            toast.error("Gagal menambahkan data!")
                        }
                    })
                }
            }
        } catch (error) {
            console.log(error)
            toast.error('Gagal menambahkan data!')
        }
    }

    const editWorkEducation = async () => {
        try {
            const data = {
                level: educationLevel === "Lainnya..." ? educationLevel2 : educationLevel,
                institute: institute,
                major: major,
                start_year: startYear,
                end_year: endYear,
                gap: gpa,
                rumpun_pendidikan: rumpunPendidikan,
            }
            const formData = new FormData();
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }
            if (file !== '') {
                formData.append('file', file);
            }
            // for (const pair of formData.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }
            await Api.UpdateWorkEducation(localStorage.getItem('hris-token'), formData, idWorkEducation).then((res) => {
                resetFormQualification()
                setRefresh(true)
                setShowEditEducation(!showEditEducation)
                toast.success('Berhasil mengubah data!')
            }).catch((error) => {
                console.log(error)
                if (error?.response?.data?.errors) {
                    toast.error(error?.response?.data?.errors)
                } else if (error?.response?.data?.message) {
                    toast.error(error?.response?.data?.message)
                } else {
                    toast.error("Gagal mengubah data!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal mengubah data!')
        }
    }
    const openEditWorkEducation = async (id) => {
        setShowEditEducation(true)
        setIdWorkEducation(id)
        try {
            const response = await Api.GetWorkEducationById(localStorage.getItem('hris-token'), id)
            setEducationLevel(response.data[0].level)
            setInstitute(response.data[0].institute)
            setMajor(response.data[0].major)
            setStartYear(moment(response.data[0].startDate).format('YYYY-MM-DD'))
            setEndYear(moment(response.data[0].endDate).format('YYYY-MM-DD'))
            setGpa(response.data[0].gap)
            setRumpunPendidikan(response.data[0].rumpun_pendidikan)
            setDataFile(response.data[0].files)
            if (dataTingkatPendidikan.find(item => item.value === response.data[0].level)) {
                setEducationLevel(response.data[0].level)
            } else {
                setEducationLevel("Lainnya...")
                setEducationLevel2(response.data[0].level)
            }
        } catch (error) {
            console.log(error)
        }
    }
    console.log(educationLevel, educationLevel2)
    const [idFile, setIdFile] = useState('')
    const deleteModalFileIjazah = (id) => {
        setShowModalDeleteIjazah(!showModalDeleteIjazah)
        setIdFile(id)
    }

    const deleteFileIjazah = async () => {
        try {
            await Api.DeleteFileIjazah(localStorage.getItem('hris-token'), idFile).then((res) => {
                setDataFile((prevFileList) =>
                    prevFileList.filter((file) => file.id !== idFile)
                );
                setShowModalDeleteIjazah(false)
                toast.success('Berhasil Meghapus Data!')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menghapus Data!')
        }
    }
    const postWorkLanguage = async () => {
        try {
            const data = {
                language: languages,
                fluency: fluency,
                competency: competency,
                comment: commentLanguages
            }
            if (!params.state) {
                await Api.PostWorkLanguage(localStorage.getItem('hris-token'), data, userId).then((res) => {
                    resetFormQualification()
                    setRefresh(true)
                    toast.success('Berhasil menambahkan data!')
                    setShowAddLanguages(!showAddLanguages)
                }).catch((error) => {
                    console.log(error)
                    if (error?.response?.data?.errors) {
                        toast.error(error?.response?.data?.errors)
                    } else if (error?.response?.data?.message) {
                        toast.error(error?.response?.data?.message)
                    } else {
                        toast.error("Gagal menambahkan data!")
                    }
                })
            } else {
                await Api.PostWorkLanguage(localStorage.getItem('hris-token'), data, params.state.employeeId).then((res) => {
                    resetFormQualification()
                    setRefresh(true)
                    toast.success('Berhasil menambahkan data!')
                    setShowAddLanguages(!showAddLanguages)
                }).catch((error) => {
                    console.log(error)
                    if (error?.response?.data?.errors) {
                        toast.error(error?.response?.data?.errors)
                    } else if (error?.response?.data?.message) {
                        toast.error(error?.response?.data?.message)
                    } else {
                        toast.error("Gagal menambahkan data!")
                    }
                })
            }
        } catch (error) {
            console.log(error)
            toast.error('Gagal menambahkan data!')
        }
    }

    const editWorkLanguage = async () => {
        try {
            const data = {
                language: languages,
                fluency: fluency,
                competency: competency,
                comment: commentLanguages
            }
            await Api.UpdateWorkLanguage(localStorage.getItem('hris-token'), data, idWWorkLanguage).then((res) => {
                resetFormQualification()
                setRefresh(true)
                setShowEditLanguages(!showEditLanguages)
                toast.success('Berhasil mengubah data!')
            }).catch((error) => {
                console.log(error)
                if (error?.response?.data?.errors) {
                    toast.error(error?.response?.data?.errors)
                } else if (error?.response?.data?.message) {
                    toast.error(error?.response?.data?.message)
                } else {
                    toast.error("Gagal mengubah data!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error("Gagal mengubah data!")
        }
    }
    const openEditWorkLanguage = async (id) => {
        setShowEditLanguages(!showEditLanguages)
        setIdWorkLanguage(id)
        try {
            const response = await Api.GetWorkLanguageById(localStorage.getItem('hris-token'), id)
            setLanguages(response.data[0].language)
            setFluency(response.data[0].fluency)
            setCompetency(response.data[0].competency)
            setCommentLanguages(response.data[0].language)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const openEditWorkLicense = async (id) => {
        setShowEditLicense(!showEditLicense)
        setIdWorkLisence(id)
        try {
            const response = await Api.GetWorkLicenseById(localStorage.getItem('hris-token'), id)
            setLicenseType(response.data[0].licenseType)
            setLicenseNumber(response.data[0].licenseNumber)
            setIssuedDate(response.data[0].issuedDate)
            setExpiryDate(response.data[0].expiryDate)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const postWorkLicense = async () => {
        try {
            const data = {
                licenseType: licenseType,
                licenseNumber: licenseNumber,
                issuedDate: issuedDate,
                expiryDate: expiryDate
            }
            if (!params.state) {
                await Api.PostWorkLicense(localStorage.getItem('hris-token'), data, userId).then((res) => {
                    resetFormQualification()
                    setRefresh(true)
                    toast.success('Berhasil menambahkan data!')
                    setShowAddLicense(!showAddLicense)
                }).catch((error) => {
                    console.log(error)
                    if (error?.response?.data?.errors) {
                        toast.error(error?.response?.data?.errors)
                    } else if (error?.response?.data?.message) {
                        toast.error(error?.response?.data?.message)
                    } else {
                        toast.error("Gagal menambahkan data!")
                    }
                })
            } else {
                await Api.PostWorkLicense(localStorage.getItem('hris-token'), data, params.state.employeeId).then((res) => {
                    resetFormQualification()
                    setRefresh(true)
                    toast.success('Berhasil menambahkan data!')
                    setShowAddLicense(!showAddLicense)
                }).catch((error) => {
                    console.log(error)
                    if (error?.response?.data?.errors) {
                        toast.error(error?.response?.data?.errors)
                    } else if (error?.response?.data?.message) {
                        toast.error(error?.response?.data?.message)
                    } else {
                        toast.error("Gagal menambahkan data!")
                    }
                })
            }
        } catch (error) {
            console.log(error)
            toast.error('Failed Create!')
        }
    }

    const editWorkLisence = async () => {
        try {
            const data = {
                licenseType: licenseType,
                licenseNumber: licenseNumber,
                issuedDate: issuedDate,
                expiryDate: expiryDate
            }
            await Api.UpdateWorkLicense(localStorage.getItem('hris-token'), data, idWorkLisence).then((res) => {
                resetFormQualification()
                setRefresh(true)
                setShowEditLicense(!showEditLicense)
                toast.success('Berhasil mengubah data!')
            }).catch((error) => {
                console.log(error)
                if (error?.response?.data?.errors) {
                    toast.error(error?.response?.data?.errors)
                } else if (error?.response?.data?.message) {
                    toast.error(error?.response?.data?.message)
                } else {
                    toast.error("Gagal mengubah data!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal mengubah data!')
        }
    }

    const [viewImage, setViewImage] = useState()

    const handleViewImage = (e) => {
        const maxSize = 2 * 1024 * 1024
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize) {
            toast.error('Gambar tidak boleh lebih dari 2 MB !')
        } else if (!allowedExtensions.includes(fileExtension)) {
            toast.error('File harus bertipe jpg, jpeg, atau png !')
        } else {
            setViewImage(URL.createObjectURL(e.target.files[0]))
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                updateProfilePersonalDetail(reader.result)
            };
        }
    }

    const updateProfilePersonalDetail = async (imageUploaded) => {
        try {
            const data = {
                image: imageUploaded
            }
            if (!params.state) {
                console.log(data)
                await Api.PostPersonalDetail(localStorage.getItem('hris-token'), data, userId).then((res) => {
                    toast.success('Success Update Gambar')
                    setRefresh(true)
                    console.log(res)
                }).catch((err) => {
                    console.log(err)
                })
            } else {
                console.log(data)
                await Api.PostPersonalDetail(localStorage.getItem('hris-token'), data, params.state.employeeId).then((res) => {
                    toast.success('Success Update Gambar')
                    setRefresh(true)
                    console.log(res)
                }).catch((err) => {
                    console.log(err)
                })
            }
        } catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }

    // const getAllEmployee = async () => {
    //     try {
    //         await Api.getAllEmployee(localStorage.getItem('hris-token')).then((res)=>{
    //         const res = response.data.data

    //         const options = res && res.map(val => ({
    //             "label": val.fullname,
    //             "value": val.id,
    //         }))

    //         setSelectedEmployee(options)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const handleSelectChange = selectedOption => {
        setSelectedOption(selectedOption);
        const selectedValue = selectedOption.value;
        setSelectedOptionId(selectedValue)
    };

    const postReportToSupervisor = async () => {
        try {
            const data = {
                reportToEmployeeId: selectedOptionId,
                report_method: reportingMethodSupervisor
            }
            console.log(data)
            if (!params.state) {
                await Api.PostReportToSupervisor(localStorage.getItem('hris-token'), data, userId).then((res) => {
                    resetFormReportTo()
                    toast.success('Success Create')
                    setRefresh(true)
                    setShowAddSupervisor(!showAddSupervisor)
                }).catch((err) => {
                    console.log(err)
                })
            } else {
                await Api.PostReportToSupervisor(localStorage.getItem('hris-token'), data, params.state.employeeId).then((res) => {
                    resetFormReportTo()
                    setRefresh(true)
                    toast.error('Failed Create')
                    setShowAddSupervisor(!showAddSupervisor)
                }).catch((err) => {
                    console.log(err)
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleChangeFile = (e) => {
        const maxSize = 10 * 1024 * 1024
        const allowedExtensions = ['pdf'];
        const file = e?.target?.files[0]
        const fileExtension = file?.name?.split('.').pop().toLowerCase();
        if (file && file.size > maxSize) {
            toast.error('File tidak boleh lebih dari 10 MB!')
            setFile(null)
        } else if (!allowedExtensions.includes(fileExtension)) {
            toast.error('File harus berformat pdf!')
        } else {
            setNamePath(e.target.files[0].name)
            setFile(e.target.files[0])
        }
    }

    useEffect(() => {
        Fetch()
        getDataPersonalDetail()
        // getReportMethod()
        setRefresh(false)
    }, [refresh])

    return (
        <div>
            {/* Modal */}
            <div>
                <Modal
                    activeModal={showAddDependents}
                    title={'Tambah Tanggungan'}
                    buttonClose={() => setShowAddDependents(!showAddDependents)}
                    width={'558px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className="flex  gap-[14px]">
                                <div className='w-full'>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama</h1>
                                    <input onChange={(e) => setNameDependents(e.target.value)} value={nameDependents} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Input Nama...' />
                                </div>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Hubungan</h2>
                                    <div className='relative'>
                                        <select onChange={(e) => setRelationshipDependents(e.target.value)} value={relationshipDependents} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                            <option selected disabled={''}>Pilih Hubungan</option>
                                            <option value={'Anak'}>Anak</option>
                                            <option value={'Lainnya'}>Lainnya</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-[14px]">
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Tanggal Lahir</h2>
                                    <input onChange={(e) => setBirthDateDependents(e.target.value)} value={birthDateDependents} type="date" className='border-dark-7 pr-2 rounded-md py-[10px] pl-[21.74px] text-dark-5 w-full text-[12px] mb-[20.5px] border' />
                                </div>
                            </div>
                            <div className="flex gap-[10px] items-end  justify-end">
                                <button onClick={() => setShowAddDependents(!showAddDependents)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={postDependents} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditDependents}
                    title={'Edit Tanggungan'}
                    buttonClose={() => setShowEditDependents(!showEditDependents)}
                    width={'558px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className="flex  gap-[14px]">
                                <div className='w-full'>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama</h1>
                                    <input value={nameDependents} onChange={(e) => setNameDependents(e.target.value)} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Dependents name...' />
                                </div>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Hubungan</h2>
                                    <div className='relative'>
                                        <select value={relationshipDependents} onChange={(e) => setRelationshipDependents(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                            <option selected value={''}>Pilih Hubungan</option>
                                            <option value={'Anak'}>Anak</option>
                                            <option value={'Lainnya'}>Lainnya</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Tanggal Lahir</h2>
                                <input onChange={(e) => setBirthDateDependents(e.target.value)} value={moment(birthDateDependents).format('YYYY-MM-DD')} type="date" className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-dark-5 w-full text-[12px] mb-[20.5px]' />
                            </div>
                            <div className="flex gap-[10px] items-end  justify-end ">
                                <button onClick={() => setShowEditDependents(!showEditDependents)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={editDependents} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    }
                />

                <Modal
                    activeModal={showAddEmergencyContact}
                    title={'Tambah Kontak Darurat'}
                    buttonClose={() => setShowAddEmergencyContact(!showAddEmergencyContact)}
                    width={'558px'}
                    content={
                        <div className='space-y-[20px]'>
                            <div className="flex gap-[14px]">
                                <div className='w-full mb-[20px]'>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Kontak Keluarga <span className='text-red-primer'>*</span></h1>
                                    <div className='relative w-full'>
                                        <select onChange={(e) => setRelationshipEmergencyContact(e.target.value)} value={relationshipEmergencyContact} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                            <option selected disabled value={''}>Pilih Kontak Keluarga...</option>
                                            <option value={'Ayah'}>Ayah</option>
                                            <option value={'Ibu'}>Ibu</option>
                                            <option value={'Suami'}>Suami</option>
                                            <option value={'Istri'}>Istri</option>
                                            <option value={'Anak'}>Anak</option>
                                            <option value={'Saudara'}>Saudara</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama</h1>
                                    <input onChange={(e) => setNameEmergencyContact(e.target.value)} value={nameEmergencyContact} type="text" className='py-[10px] border w-full rounded-md pl-[13px] pr-2 text-[11px] text-dark-5' placeholder='Input Nama...' />
                                </div>
                            </div>
                            <div className="flex gap-[14px]">
                                <div className='w-full'>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>No. Handphone</h1>
                                    <input onChange={(e) => setMobileEmergencyContact(e.target.value)} value={mobileEmergencyContact} type="number" className='py-[10px] border w-full rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5' placeholder='Input No. Handphone...' />
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5'>Alamat</h1>
                                <textarea rows={2} onChange={(e) => setAddressEmergencyContact(e.target.value)} value={addresEmergencyContact} className='py-[10px] border w-full rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 resize-none' placeholder='Input Alamat...' />
                            </div>

                            <div className="flex gap-[10px] justify-end ">
                                <button onClick={() => setShowAddEmergencyContact(!showAddEmergencyContact)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={postEmergencyContact} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditEmergencyContact}
                    title={'Edit Kontak Darurat'}
                    buttonClose={() => setShowEditEmergencyContact(!showEditEmergencyContact)}
                    width={'558px'}
                    content={
                        <div className='space-y-[20px]'>
                            <div className="flex gap-[14px]">
                                <div className='w-full mb-[20px]'>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Kontak Keluarga <span className='text-red-primer'>*</span></h1>
                                    <div className='relative w-full'>
                                        <select onChange={(e) => setRelationshipEmergencyContact(e.target.value)} value={relationshipEmergencyContact} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                            <option selected disabled value={''}>Pilih Kontak Keluarga...</option>
                                            <option value={'Ayah'}>Ayah</option>
                                            <option value={'Ibu'}>Ibu</option>
                                            <option value={'Suami'}>Suami</option>
                                            <option value={'Istri'}>Istri</option>
                                            <option value={'Anak'}>Anak</option>
                                            <option value={'Saudara'}>Saudara</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama</h1>
                                    <input onChange={(e) => setNameEmergencyContact(e.target.value)} value={nameEmergencyContact} type="text" className='py-[10px] border w-full rounded-md pl-[13px] pr-2 text-[11px] text-dark-5' placeholder='Input Nama...' />
                                </div>
                            </div>
                            <div className="flex gap-[14px]">
                                <div className='w-full'>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>No. Handphone</h1>
                                    <input onChange={(e) => setMobileEmergencyContact(e.target.value)} value={mobileEmergencyContact} type="number" className='py-[10px] border w-full rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5' placeholder='Input No. Handphone...' />
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5'>Alamat</h1>
                                <textarea rows={2} onChange={(e) => setAddressEmergencyContact(e.target.value)} value={addresEmergencyContact} className='py-[10px] border w-full rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 resize-none' placeholder='Input Alamat...' />
                            </div>

                            <div className="flex gap-[10px] justify-end ">
                                <button onClick={() => setShowEditEmergencyContact(!showEditEmergencyContact)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={editEmergencyContact} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showAddImigration}
                    title={'Add Migration'}
                    buttonClose={() => setShowAddImigration(!showAddImigration)}
                    width={'558px'}
                    content={
                        <div className='space-y-[24px] w-[full]'>
                            <div className='flex gap-[44px]'>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] mb-[12px]'>Document</h2>
                                    <div className='relative'>
                                        <select onChange={(e) => setDocumentType(e.target.value)} value={documentType} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                            <option selected value={''}>Select Document</option>
                                            <option value={'Visa'}>Visa</option>
                                            <option value={'Passport'}>Passport</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] mb-[12px]'>Number</h2>
                                    <input onChange={(e) => setNumber(e.target.value)} value={number} type="number" className='py-[10px] pl-[13px] pr-2 rounded-md text-[11px] text-[#A8A8A8] w-full border' placeholder='Document Number...' />
                                </div>
                            </div>
                            <div className='flex gap-[44px] w-full'>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] mb-[12px]'>Issued Date</h2>
                                    <input onChange={(e) => setIssueDateImmigration(e.target.value)} value={issueDateImmigration} type="date" className=' pr-2 rounded-md py-[10px] pl-[21.74px] text-dark-5 w-full text-[12px] mb-[20.5px] border ' placeholder='Full Name' />
                                </div>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] mb-[12px]'>Expiry Date</h2>
                                    <input onChange={(e) => setExpiryDateImmigration(e.target.value)} value={expiryDateImmigration} type="date" className=' pr-2 rounded-md py-[10px] pl-[21.74px] text-dark-5 w-full text-[12px] mb-[20.5px] border ' placeholder='Full Name' />
                                </div>
                            </div>
                            <div className='flex gap-[44px] w-full'>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] mb-[12px]'>Issued by</h2>
                                    <div className='relative'>
                                        <select onChange={(e) => setIssuedby(e.target.value)} value={issuedby} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                            <option selected value={''}>Select Country</option>
                                            <option value={'Indonesia'}>Indonesia</option>
                                            <option value={'Inggris'}>Inggris</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                            </div>
                            <div className='w-full'>
                                <h2 className='text-dark-5 text-[12px] mb-[12px]'>Note</h2>
                                <textarea onChange={(e) => setComment(e.target.value)} type="date" value={comment} className=' pr-2 rounded-md py-[10px] pl-[21.74px] text-dark-5 w-full text-[12px] mb-[20.5px] border appearance-none resize-none' rows={4} />
                            </div>
                            <div className="flex gap-[10px] items-end justify-end">
                                <button onClick={() => setShowAddImigration(!showAddImigration)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-blue-primer text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={postImmigration} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Save</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditImigration}
                    title={'Edit Migration'}
                    buttonClose={() => setShowEditImigration(!showEditImigration)}
                    width={'558px'}
                    content={
                        <div className='space-y-[24px] w-[full]'>
                            <div className='flex gap-[44px]'>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] mb-[12px]'>Document</h2>
                                    <div className='relative'>
                                        <select value={documentType} onChange={(e) => setDocumentType(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                            <option selected value={''}>Select Document</option>
                                            <option value={'Visa'}>Visa</option>
                                            <option value={'Passport'}>Passport</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] mb-[12px]'>Number</h2>
                                    <input value={number} onChange={(e) => setNumber(e.target.value)} type="number" className='py-[10px] pl-[13px] pr-2 rounded-md text-[11px] text-[#A8A8A8] w-full border' placeholder='Document Number...' />
                                </div>
                            </div>
                            <div className='flex gap-[44px]'>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] mb-[12px]'>Issued Date</h2>
                                    <input value={moment(issueDateImmigration).format('YYYY-MM-DD')} onChange={(e) => setIssueDateImmigration(e.target.value)} type="date" className=' pr-2 rounded-md py-[10px] pl-[21.74px] text-dark-5 w-full text-[12px] mb-[20.5px] border ' placeholder='Full Name' />
                                </div>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] mb-[12px]'>Expiry Date</h2>
                                    <input value={moment(expiryDateImmigration).format('YYYY-MM-DD')} onChange={(e) => setExpiryDateImmigration(e.target.value)} type="date" className=' pr-2 rounded-md py-[10px] pl-[21.74px] text-dark-5 w-full text-[12px] mb-[20.5px] border ' placeholder='Full Name' />
                                </div>
                            </div>
                            <div className='flex gap-[44px]'>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] mb-[12px]'>Issued by</h2>
                                    <div className='relative'>
                                        <select value={issuedby} onChange={(e) => setIssuedby(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                            <option selected value={''}>Select Country</option>
                                            <option value={'Indonesia'}>Indonesia</option>
                                            <option value={'English'}>English</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                            </div>
                            <div className='w-full'>
                                <h2 className='text-dark-5 text-[12px] mb-[12px]'>Note</h2>
                                <textarea value={comment} onChange={(e) => setComment(e.target.value)} className=' pr-2 rounded-md py-[10px] pl-[21.74px] text-dark-5 w-full text-[12px] mb-[20.5px] border appearance-none resize-none' rows={4} />
                            </div>
                            <div className="flex gap-[10px] items-end justify-end">
                                <button onClick={() => setShowEditImigration(!setShowEditImigration)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-blue-primer text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={editImmigration} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Save</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showAddSupervisor}
                    title={'Tambah Atasan'}
                    buttonClose={() => setShowAddSupervisor(!showAddSupervisor)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            {/* <div className="flex  gap-[14px]">
                                <div className='w-full'>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama <span className='text-red-primer'> *</span></h1>
                                    <div className='relative'>
                                        <select onChange={(e) => setNameSupervisor(e.target.value)} value={nameSupervisor} type="text" className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[13px] py-[10px] w-full appearance-none'>
                                            <option>Pilih Nama</option>
                                            {Object.values(selectedEmployee).map((data, index) => {
                                                console.log(data)
                                                return (
                                                    <option key={index} value={data.value}>{data.label}</option>
                                                    )
                                                })}
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                            </div> */}

                            <h1 className='text-[12px] text-dark-5'>Nama <span className='text-red-primer'> *</span></h1>
                            {/* <Select
                                className="basic-single"
                                classNamePrefix="Pilih Nama"
                                isDisabled={isDisabled}
                                isLoading={isLoading}
                                isClearable={isClearable}
                                isRtl={isRtl}
                                isSearchable={isSearchable}
                                value={selectedOption}
                                onChange={handleSelectChange}
                                name="atasan"
                                options={selectedEmployee}
                                placeholder="Pilih Atasan"
                                styles={customStyles}
                            /> */}
                            <div className="flex gap-[14px]">
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Metode Report-To <span className='text-red-primer'> *</span></h2>
                                    <div className='relative'>
                                        <select onChange={(e) => setReportingMethodSupervisor(e.target.value)} value={reportingMethodSupervisor} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[13px] py-[10px] w-full appearance-none'>
                                            <option selected value={''}>Metode Report-To</option>
                                            <option value={'Direct'}>Direct</option>
                                            <option value={'In-Direct'}>In-Direct</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                            </div>
                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowAddSupervisor(!showAddSupervisor)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Cancel</h1>
                                    </button>
                                    <button onClick={postReportToSupervisor} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Add</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditSupervisor}
                    title={'Edit Atasan'}
                    buttonClose={() => setShowEditSupervisor(!showEditSupervisor)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className="flex  gap-[14px]">
                                <div className='w-full'>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama <span className='text-red-primer'> *</span></h1>
                                    <input type="text" onChange={(e) => setNameSupervisor(e.target.value)} value={nameSupervisor} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Pilih Nama' />
                                </div>
                                {/* <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Structure ID <span className='text-red-primer'>*</span><h1/>
                                <input type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]'/>
                            </div> */}

                            </div>
                            <div className="flex gap-[14px]">
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Metode Report-To <span className='text-red-primer'> *</span></h2>
                                    <div className='relative'>
                                        <select onChange={(e) => setReportingMethodSupervisor(e.target.value)} value={reportingMethodSupervisor} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[13px] py-[10px] w-full appearance-none'>
                                            <option selected value={''}>Metode Report-To</option>
                                            <option value={'Direct'}>Direct</option>
                                            <option value={'In-Direct'}>In-Direct</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                            </div>
                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowEditSupervisor(!showEditSupervisor)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Cancel</h1>
                                    </button>
                                    <button onClick={editSupervisor} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Submit</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showAddSubordinate}
                    title={'Tambah Bawahan'}
                    buttonClose={() => setShowAddSubordinate(!showAddSubordinate)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className="flex  gap-[14px]">
                                <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama  <span className='text-red-primer'> *</span></h1>
                                    <input onChange={(e) => setNameSubordinate(e.target.value)} value={nameSubordinate} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' placeholder='Pilih Nama' />
                                </div>
                                {/* <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Struktur ID  <span className='text-red-primer'> *</span></h1>
                                    <input type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' />
                                </div> */}
                            </div>
                            <div className="flex gap-[14px]">
                                <div>
                                    <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Metode Report-To <span className='text-red-primer'> *</span></h2>
                                    <div className='relative'>
                                        <select onChange={(e) => setReportingMethodSubordinate(e.target.value)} value={reportingMethodSubordinate} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[13px] py-[10px] w-[252px] appearance-none'>
                                            <option selected value={''}>Metode Report-To</option>
                                            <option value={'Direct'}>Direct</option>
                                            <option value={'In-Direct'}>In-Direct</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                            </div>
                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowAddSubordinate(!showAddSubordinate)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Cancel</h1>
                                    </button>
                                    <button className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Submit</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditSubordinate}
                    title={'Edit Bawahan'}
                    buttonClose={() => setShowEditSubordinate(!showEditSubordinate)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className="flex  gap-[14px]">
                                <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama <span className='text-red-primer'> *</span></h1>
                                    <input type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' placeholder='Pilih Nama' />
                                </div>
                                {/* <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Structure ID <span className='text-red-primer'>*</span><h1/>
                                    <input type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' />
                                </div> */}

                            </div>
                            <div className="flex gap-[14px]">
                                <div>
                                    <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Metode Report-To <span className='text-red-primer'> *</span></h2>
                                    <div className='relative'>
                                        <select value={''} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[252px] appearance-none'>
                                            <option selected value={''}>Metode Report-To</option>
                                            <option value={'Direct'}>Direct</option>
                                            <option value={'In-Direct'}>In-Direct</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                            </div>
                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowEditSubordinate(!showEditSubordinate)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Cancel</h1>
                                    </button>
                                    <button className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Submit</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />

                <Modal
                    activeModal={showAddEducation}
                    title={'Tambah Pendidikan'}
                    buttonClose={() => { setShowAddEducation(!showAddEducation); resetFormQualification() }}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className='space-y-2'>
                                <InputOption data={dataTingkatPendidikan} onChange={(e) => setEducationLevel(e)} value={educationLevel} label={'Tingkat Pendidikan'} placeholder={'Masukkan Tingkat Pendidikan...'} required={true} />
                                {
                                    educationLevel === "Lainnya..." ?
                                        <input onChange={(e) => setEducationLevel2(e.target.value)} value={educationLevel2} type="text" className='text-zinc-700 text-xs font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Masukkan Tingkat Pendidikan...' />
                                        : null
                                }
                            </div>
                            <div>
                                <h1 className='text-[12px] text-grey-thick mb-[9px]'>Institusi Pendidikan</h1>
                                <input onChange={(e) => setInstitute(e.target.value)} value={institute} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-thick w-full' placeholder='Masukkan Institusi Pendidikan...' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-grey-thick mb-[9px]'>Program Studi/Keahlian</h1>
                                <input onChange={(e) => setMajor(e.target.value)} value={major} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-thick w-full' placeholder='Masukkan Program Studi/Keahlian...' />
                            </div>
                            <div>
                                <InputOption data={dataRumpunPendidikan} label={'Rumpun Pendidikan'} value={rumpunPendidikan} onChange={(e) => setRumpunPendidikan(e)} required={true} placeholder={'Pilih Rumpun Pendidikan'} />
                            </div>
                            <div className="flex gap-[25px]">
                                <div className='w-full'>
                                    <h1 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tahun Mulai <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setStartYear(e.target.value)} value={startYear} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-thick w-full' />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tahun Akhir <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setEndYear(e.target.value)} value={endYear} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-thick w-full' />
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-grey-thick mb-[9px]'>Nilai Akhir <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setGpa(e.target.value)} value={gpa} type="number" min={0} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-thick w-1/2' placeholder='Masukkan Nilai Akhir' />
                            </div>
                            <div>
                                <h1 className="text-neutral-500 text-[11px] font-medium mb-1">Ijazah <span className='text-red-900'>*</span></h1>
                                <label htmlFor='upload-file' className='cursor-pointer'>
                                    <div className='border shadow-sm rounded-lg relative text-center justify-center px-[70px] py-[11px] w-full'>
                                        <div className='flex items-center justify-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                <path d="M5.95829 18.3327C4.56496 18.3327 3.37635 17.853 2.39246 16.8935C1.40857 15.928 0.916626 14.7516 0.916626 13.3643C0.916626 12.1727 1.27413 11.1093 1.98913 10.1743C2.71024 9.23935 3.65135 8.64352 4.81246 8.38685C5.19746 6.98129 5.96135 5.84463 7.10413 4.97685C8.25301 4.10296 9.55163 3.66602 11 3.66602C12.7905 3.66602 14.3061 4.28935 15.5466 5.53602C16.7933 6.77657 17.4166 8.29213 17.4166 10.0827C18.4738 10.2049 19.3477 10.6632 20.0383 11.4577C20.735 12.2399 21.0833 13.1566 21.0833 14.2077C21.0833 15.3566 20.683 16.3313 19.8825 17.1318C19.0819 17.9324 18.1072 18.3327 16.9583 18.3327H11.9166C11.4155 18.3327 10.9847 18.1524 10.6241 17.7918C10.2636 17.4374 10.0833 17.0066 10.0833 16.4993V11.7785L8.61663 13.1993L7.33329 11.916L11 8.24935L14.6666 11.916L13.3833 13.1993L11.9166 11.7785V16.4993H16.9583C17.6 16.4993 18.1408 16.2763 18.5808 15.8302C19.0269 15.3902 19.25 14.8493 19.25 14.2077C19.25 13.566 19.0269 13.0252 18.5808 12.5852C18.1408 12.1391 17.6 11.916 16.9583 11.916H15.5833V10.0827C15.5833 8.81768 15.1372 7.73602 14.245 6.83768C13.3527 5.94546 12.2711 5.49935 11 5.49935C9.73496 5.49935 8.65329 5.94546 7.75496 6.83768C6.86274 7.73602 6.41663 8.81768 6.41663 10.0827H5.95829C5.07218 10.0827 4.31746 10.3974 3.69413 11.0268C3.06468 11.6502 2.74996 12.4049 2.74996 13.291C2.74996 14.1771 3.06468 14.941 3.69413 15.5827C4.31746 16.1938 5.07218 16.4993 5.95829 16.4993H8.24996V18.3327" fill="#6B7280" />
                                            </svg>
                                        </div>
                                        {namePath ? (
                                            <h1 className='text-gray-500 text-[12px] text-center'>{namePath}</h1>
                                        ) : (
                                            <>
                                                <h1 className='text-red-primer text-[12px] font-medium text-center'>Pilih File</h1>
                                                <div className="text-gray-500 text-[8px] text-center">pdf maks 2 MB</div>
                                            </>
                                        )}
                                    </div>
                                    <input type='file' id='upload-file' accept=".pdf" onChange={(e) => handleChangeFile(e)} className='rounded-[12px] outline-none border border-[#E3E8F1] w-foll px-[20px] py-[15px] hidden' required />
                                </label>
                            </div>
                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => { setShowAddEducation(!showAddEducation); resetFormQualification() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={postWorkEducation} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Tambah</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditEducation}
                    title={'Edit Pendidikan'}
                    buttonClose={() => { setShowEditEducation(!showEditEducation); resetFormQualification() }}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className='space-y-2'>
                                <InputOption data={dataTingkatPendidikan} onChange={(e) => setEducationLevel(e)} value={educationLevel} label={'Tingkat Pendidikan'} placeholder={'Masukkan Tingkat Pendidikan...'} required={true} />
                                {
                                    educationLevel === "Lainnya..." ?
                                        <input onChange={(e) => setEducationLevel2(e.target.value)} value={educationLevel2} type="text" className='text-zinc-700 text-xs font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Masukkan Tingkat Pendidikan...' />
                                        : null
                                }
                            </div>
                            <div>
                                <h1 className='text-[12px] text-grey-thick mb-[9px]'>Institusi Pendidikan</h1>
                                <input onChange={(e) => setInstitute(e.target.value)} value={institute} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-thick w-full' placeholder='Masukkan Institusi Pendidikan...' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-grey-thick mb-[9px]'>Program Studi/Keahlian</h1>
                                <input onChange={(e) => setMajor(e.target.value)} value={major} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-thick w-full' placeholder='Masukkan Program Studi/Keahlian...' />
                            </div>
                            <div>
                                <InputOption data={dataRumpunPendidikan} label={'Rumpun Pendidikan'} value={rumpunPendidikan} onChange={(e) => setRumpunPendidikan(e)} required={true} placeholder={'Pilih Rumpun Pendidikan'} />
                            </div>
                            <div className="flex gap-[25px]">
                                <div className='w-full'>
                                    <h1 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tahun Mulai <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setStartYear(e.target.value)} value={startYear} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-thick w-full' />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tahun Akhir <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setEndYear(e.target.value)} value={endYear} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-thick w-full' />
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-grey-thick mb-[9px]'>Nilai Akhir <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setGpa(e.target.value)} value={gpa} type="number" min={0} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-thick w-1/2' placeholder='Masukkan Nilai Akhir' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-grey-thick mb-[9px]'>Nilai Akhir <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setGpa(e.target.value)} value={gpa} type="number" min={0} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-thick w-1/2' placeholder='Masukkan Nilai Akhir' />
                            </div>
                            {dataFile?.length >= 1 ? (
                                <div className='flex items-center gap-3'>
                                    <div className='bg-[#CEDFEA] rounded-md flex gap-2 items-center p-2 text-xs'><HiPaperClip /> <span>{dataFile[0].originalname}</span></div>
                                    <a href={imageHandle(dataFile[0].path)} download={dataFile[0].originalname} target='_blank' className='text-blue-primer font-semibold text-sm flex gap-2' rel="noreferrer">
                                        <BiDownload className='text-xl' />
                                    </a>
                                    <button
                                        onClick={() => deleteModalFileIjazah(dataFile[0].id)}
                                    >
                                        <CgTrashEmpty className='text-[#003049]' />
                                    </button>
                                </div>
                            ) :
                                <div>
                                    <h1 className="text-neutral-500 text-[11px] font-medium mb-1">Ijazah <span className='text-red-900'>*</span></h1>
                                    <label htmlFor='upload-file' className='cursor-pointer'>
                                        <div className='border shadow-sm rounded-lg relative text-center justify-center px-[70px] py-[11px] w-full'>
                                            <div className='flex items-center justify-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                    <path d="M5.95829 18.3327C4.56496 18.3327 3.37635 17.853 2.39246 16.8935C1.40857 15.928 0.916626 14.7516 0.916626 13.3643C0.916626 12.1727 1.27413 11.1093 1.98913 10.1743C2.71024 9.23935 3.65135 8.64352 4.81246 8.38685C5.19746 6.98129 5.96135 5.84463 7.10413 4.97685C8.25301 4.10296 9.55163 3.66602 11 3.66602C12.7905 3.66602 14.3061 4.28935 15.5466 5.53602C16.7933 6.77657 17.4166 8.29213 17.4166 10.0827C18.4738 10.2049 19.3477 10.6632 20.0383 11.4577C20.735 12.2399 21.0833 13.1566 21.0833 14.2077C21.0833 15.3566 20.683 16.3313 19.8825 17.1318C19.0819 17.9324 18.1072 18.3327 16.9583 18.3327H11.9166C11.4155 18.3327 10.9847 18.1524 10.6241 17.7918C10.2636 17.4374 10.0833 17.0066 10.0833 16.4993V11.7785L8.61663 13.1993L7.33329 11.916L11 8.24935L14.6666 11.916L13.3833 13.1993L11.9166 11.7785V16.4993H16.9583C17.6 16.4993 18.1408 16.2763 18.5808 15.8302C19.0269 15.3902 19.25 14.8493 19.25 14.2077C19.25 13.566 19.0269 13.0252 18.5808 12.5852C18.1408 12.1391 17.6 11.916 16.9583 11.916H15.5833V10.0827C15.5833 8.81768 15.1372 7.73602 14.245 6.83768C13.3527 5.94546 12.2711 5.49935 11 5.49935C9.73496 5.49935 8.65329 5.94546 7.75496 6.83768C6.86274 7.73602 6.41663 8.81768 6.41663 10.0827H5.95829C5.07218 10.0827 4.31746 10.3974 3.69413 11.0268C3.06468 11.6502 2.74996 12.4049 2.74996 13.291C2.74996 14.1771 3.06468 14.941 3.69413 15.5827C4.31746 16.1938 5.07218 16.4993 5.95829 16.4993H8.24996V18.3327" fill="#6B7280" />
                                                </svg>
                                            </div>
                                            {namePath ? (
                                                <h1 className='text-gray-500 text-[12px] text-center'>{namePath}</h1>
                                            ) : (
                                                <>
                                                    <h1 className='text-red-primer text-[12px] font-medium text-center'>Pilih File</h1>
                                                    <div className="text-gray-500 text-[8px] text-center">pdf maks 2 MB</div>
                                                </>
                                            )}
                                        </div>
                                        <input type='file' id='upload-file' accept=".pdf" onChange={(e) => handleChangeFile(e)} className='rounded-[12px] outline-none border border-[#E3E8F1] w-foll px-[20px] py-[15px] hidden' required />
                                    </label>
                                </div>
                            }
                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => { setShowEditEducation(!showEditEducation); resetFormQualification() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={editWorkEducation} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <ModalDelete
                    activeModal={showModalDeleteIjazah}
                    buttonClose={() => setShowModalDeleteIjazah(!showModalDeleteIjazah)}
                    submitButton={deleteFileIjazah}
                />
                <Modal
                    activeModal={showAddWorkExperience}
                    title={'Tambah Pengalaman Kerja'}
                    buttonClose={() => setShowAddWorkExperience(!showAddWorkExperience)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama Perusahaan <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setCompanyNameWorkExperience(e.target.value)} value={companyNameWorkExperience} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Nama Perusahaan...' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Jabatan*</h1>
                                <input onChange={(e) => setJobTittleeWorkExperience(e.target.value)} value={jobTittleeWorkExperience} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Jabatan...' />
                            </div>
                            <div className="flex gap-[25px]">
                                <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tanggal Mulai <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setStartDateWorkExperience(e.target.value)} value={startDateWorkExperience} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' />
                                </div>
                                <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tanggal Selesai <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setEndDateWorkExperience(e.target.value)} type="date" value={endDateWorkExperience} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' />
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Deskripsi</h1>
                                <textarea onChange={(e) => setCommentWorkExperience(e.target.value)} value={commentWorkExperience} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full resize-none' rows={3} placeholder='Deskripsi...' />
                            </div>

                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowAddWorkExperience(!showAddWorkExperience)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={postWorkExperience} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Tambah</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditWorkExperience}
                    title={'Edit Pengalaman Kerja'}
                    buttonClose={() => setShowEditWorkExperience(!showEditWorkExperience)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama Perusahaan <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setCompanyNameWorkExperience(e.target.value)} value={companyNameWorkExperience} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Nama Perusahaan...' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Jabatan <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setJobTittleeWorkExperience(e.target.value)} value={jobTittleeWorkExperience} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Jabatan...' />
                            </div>
                            <div className="flex gap-[25px]">
                                <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tanggal Mulai <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setStartDateWorkExperience(e.target.value)} value={moment(startDateWorkExperience).format('YYYY-MM-DD')} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' />
                                </div>
                                <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tanggal Selesai <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setEndDateWorkExperience(e.target.value)} value={moment(endDateWorkExperience).format('YYYY-MM-DD')} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' />
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Deskripsi</h1>
                                <textarea onChange={(e) => setCommentWorkExperience(e.target.value)} value={commentWorkExperience} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full resize-none' rows={3} placeholder='Deskripsi...' />
                            </div>

                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowEditWorkExperience(!showEditWorkExperience)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={editWorkExperience} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showAddSkills}
                    title={'Tambah Skill'}
                    buttonClose={() => setShowAddSkills(!showAddSkills)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama Skill <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setNameSkill(e.target.value)} value={nameSkill} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Nama Skill...' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tahun Pengalaman</h1>
                                <input onChange={(e) => setYearExperienceSkill(e.target.value)} value={yearExperienceSkill} type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Tahun Pengalaman...' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Deskripsi</h1>
                                <textarea onChange={(e) => setCommentSkill(e.target.value)} value={commentSkill} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full resize-none' rows={3} placeholder='Deskripsi...' />
                            </div>

                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowAddSkills(!showAddSkills)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={postWorkSkill} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Tambah</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditSkills}
                    title={'Edit Skill'}
                    buttonClose={() => setShowEditSkills(!showEditSkills)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama Skill <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setNameSkill(e.target.value)} value={nameSkill} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Nama Skill...' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tahun Pengalaman</h1>
                                <input onChange={(e) => setYearExperienceSkill(e.target.value)} value={yearExperienceSkill} type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Tahun Pengalaman...' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Deskripsi</h1>
                                <textarea onChange={(e) => setCommentSkill(e.target.value)} value={commentSkill} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full resize-none' rows={3} placeholder='Deskripsi...' />
                            </div>

                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowEditSkills(!showEditSkills)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={editWorkSkill} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showAddLanguages}
                    title={'Tambah Bahasa'}
                    buttonClose={() => setShowAddLanguages(!showAddLanguages)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Bahasa <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setLanguages(e.target.value)} value={languages} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Bahasa...' />
                            </div>
                            <div>
                                <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Kelancaran *</h2>
                                <div className='relative'>
                                    <select onChange={(e) => setFluency(e.target.value)} value={fluency} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[536px] appearance-none'>
                                        <option selected value={''}>Pilih Kelancaran...</option>
                                        <option value="Speaking">Berbicara </option>
                                        <option value="Writing">Menulis </option>
                                        <option value="Reading">Membaca  </option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                </div>
                            </div>
                            <div>
                                <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Kompetensi *</h2>
                                <div className='relative'>
                                    <select onChange={(e) => setCompetency(e.target.value)} value={competency} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[536px] appearance-none'>
                                        <option selected value={''}>Pilih Kompetensi...</option>
                                        <option value="Mother Tongue">Bahasa Ibu</option>
                                        <option value="Good">Baik</option>
                                        <option value="Basic">Dasar</option>
                                        <option value="Poor">Kurang</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Deskripsi</h1>
                                <textarea onChange={(e) => setCommentLanguages(e.target.value)} value={commentLanguages} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full resize-none' rows={3} placeholder='Deskripsi...' />
                            </div>

                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowAddLanguages(!showAddLanguages)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={postWorkLanguage} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Tambah</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditLanguages}
                    title={'Edit Bahasa'}
                    buttonClose={() => setShowEditLanguages(!showEditLanguages)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Bahasa <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setLanguages(e.target.value)} value={languages} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Bahasa...' />
                            </div>
                            <div>
                                <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Kelancaran *</h2>
                                <div className='relative'>
                                    <select onChange={(e) => setEndYear(e.target.value)} value={fluency} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[536px] appearance-none'>
                                        <option selected value={''}>Pilih Kelancaran...</option>
                                        <option value="Speaking">Berbicara </option>
                                        <option value="Writing">Menulis </option>
                                        <option value="Reading">Membaca  </option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                </div>
                            </div>
                            <div>
                                <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Kompetensi *</h2>
                                <div className='relative'>
                                    <select onChange={(e) => setCompetency(e.target.value)} value={competency} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[536px] appearance-none'>
                                        <option selected value={''}>Pilih Kompetensi...</option>
                                        <option value="Mother Tongue">Bahasa Ibu</option>
                                        <option value="Good">Baik</option>
                                        <option value="Basic">Dasar</option>
                                        <option value="Poor">Kurang</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Deskripsi</h1>
                                <textarea onChange={(e) => setComment(e.target.value)} value={commentLanguages} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full resize-none' rows={3} />
                            </div>

                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowEditLanguages(!showEditLanguages)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]' placeholder='Deskripsi...'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={editWorkLanguage} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showAddLicense}
                    title={'Tambah Lisensi'}
                    buttonClose={() => setShowAddLicense(!showAddLicense)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tipe Lisensi <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setLicenseType(e.target.value)} value={licenseType} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Tipe Lisensi...' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nomor Lisensi <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setLicenseNumber(e.target.value)} value={licenseNumber} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Nomor Lisensi...' />
                            </div>
                            <div className="flex gap-[32px]">
                                <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tanggal Terbit <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setIssuedDate(e.target.value)} value={issuedDate} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' />
                                </div>
                                <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tanggal Kadaluarsa <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setExpiryDate(e.target.value)} value={expiryDate} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' />
                                </div>
                            </div>

                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowAddLicense(!showAddLicense)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={postWorkLicense} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Tambah</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditLicense}
                    title={'Edit Lisensi'}
                    buttonClose={() => setShowEditLicense(!showEditLicense)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tipe Lisensi <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setLicenseType(e.target.value)} value={licenseType} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Tipe Lisensi...' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nomor Lisensi <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setLicenseNumber(e.target.value)} value={licenseNumber} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Nomor Lisensi...' />
                            </div>
                            <div className="flex gap-[32px]">
                                <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tanggal Terbit <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setIssuedDate(e.target.value)} value={moment(issuedDate).format('YYYY-MM-DD')} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' />
                                </div>
                                <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tanggal Kadaluarsa <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setExpiryDate(e.target.value)} value={moment(expiryDate).format('YYYY-MM-DD')} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' />
                                </div>
                            </div>

                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowEditLicense(!showEditLicense)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={editWorkLisence} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
            </div>
            <div className='bg-[#F8F9FB] min-h-screen'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={'Profile'} LinkBack={'/dashboard'} />
                    <div className='space-y-[24px] bg-[#F8F9FB] px-[28px] py-[40px] rounded-[12px] border'>
                        <div className='flex flex-col relative'>
                            <img src={HeaderProfile} className='h-[91px] w-full rounded-t-xl' alt='' />
                            <div className='pl-[185px] h-[91px] w-full rounded-b-xl bg-white'>
                                <h1 className='text-[20px] font-semibold text-dark-3'>{namePersonalDetail}</h1>
                                <p className='text-[11px] text-[#A8A8A8] font-semibold'>{jobPositionPersonalDetail ? jobPositionPersonalDetail : 'ADMIN'}</p>
                            </div>
                            <label htmlFor='upload-image'>
                                <div className='w-[95px] h-[95px] rounded-full border-4 border-white absolute left-[75px] top-[55px] bottom-[36px] bg-cover hover:bg-slate-500' style={{
                                    backgroundImage: `url(${imagePersonalDetail ? imageHandle(imagePersonalDetail) : LogoH})`,
                                    backgroundSize: 'cover', // Menambahkan properti backgroundSize untuk memastikan gambar penuh
                                    backgroundPosition: 'center', // Menambahkan properti backgroundPosition untuk mengatur posisi gambar
                                    width: '95px',
                                    height: '95px',
                                    borderRadius: '50%',
                                }}>
                                    {/* <img className='w-[95px] h-[95px] rounded-full border-4 border-white absolute left-[75px] top-[55px] bottom-[36px] object-cover hover:bg-transparent' src={imagePersonalDetail? imageHandle(imagePersonalDetail) : imageHandle(viewImage)} alt="Profile" /> */}
                                    <input type='file' id='upload-image' onChange={(e) => handleViewImage(e)} className='hidden' />
                                </div>
                            </label>
                        </div>
                        <div className="flex relative gap-10">
                            <div className='w-[267px] h-[762px] py-[18px]  shadow rounded-l-xl border-r-6 relative pl-[18px] bg-white'>

                                <button onClick={() => setIndex(0)} className={index === 0 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 0 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Informasi Pribadi
                                    </h1>
                                </button>

                                <button onClick={() => setIndex(1)} className={index === 1 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 1 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Kontak Darurat
                                    </h1>

                                </button>

                                <button onClick={() => setIndex(2)} className={index === 2 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 2 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Tanggungan
                                    </h1>
                                </button>

                                {/* <button onClick={() => setIndex(3)} className={index === 3 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 3 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Imigrasi
                                    </h1>
                                </button> */}

                                <button onClick={() => setIndex(4)} className={index === 4 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 4 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Pekerjaan
                                    </h1>
                                </button>

                                <button onClick={() => setIndex(5)} className={index === 5 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 5 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Penghasilan
                                    </h1>
                                </button>

                                {/* <button onClick={() => setIndex(6)} className={index === 6 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 6 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Unit Kerja Atasan Langsung
                                    </h1>
                                </button> */}

                                <button onClick={() => setIndex(7)} className={index === 7 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 7 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Kualifikasi
                                    </h1>
                                </button>

                                <button onClick={() => setIndex(8)} className={index === 8 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 8 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        File
                                    </h1>
                                </button>

                                <button onClick={() => setIndex(9)} className={index === 9 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 9 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Pelatihan
                                    </h1>
                                </button>
                                <button onClick={() => setIndex(10)} className={index === 10 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 10 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Penghargaan
                                    </h1>
                                </button>
                                <button onClick={() => setIndex(11)} className={index === 11 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 11 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Tugas Belajar
                                    </h1>
                                </button>
                            </div>

                            <div className="ml-auto w-full p-5 space-y-5 bg-white rounded-r-xl h-[762px] overflow-y-auto border shadow-md scrollbar-default">
                                {index === 0 ? (
                                    <PersonalDetail />
                                ) : index === 1 ? (
                                    <EmergencyContact
                                        modalAddContact={() => setShowAddEmergencyContact(!showEditEmergencyContact)}
                                        modalEditContact={openEditEmergencyContact}
                                        refreshPage={refresh} />

                                ) : index === 2 ? (
                                    <Dependents
                                        ModalAddDepents={() => setShowAddDependents(!showAddDependents)}
                                        ModalEditDepents={openEditDependents}
                                        refreshPage={refresh} />
                                ) : index === 3 ? (
                                    <Imigration
                                        modalAddImigration={() => setShowAddImigration(!showAddImigration)}
                                        modalEditImigration={openEditImirgration}
                                        refreshPage={refresh} />
                                ) : index === 4 ? (
                                    <JobDetails
                                    />
                                ) : index === 5 ? (
                                    <Payroll />
                                ) : index === 6 ? (
                                    <ReportTo
                                        modalAddSubordinate={() => setShowAddSubordinate(!showAddSubordinate)}
                                        modalEditSubordinate={() => setShowEditSubordinate(!showEditSubordinate)}
                                        modalAddSupervisor={() => setShowAddSupervisor(!showAddSupervisor)}
                                        modalEditSupervisor={openEditReportToSupervisor}
                                        refreshPage={refresh} />
                                ) : index === 7 ? (
                                    <Qualifications
                                        modalAddWorkExperience={() => setShowAddWorkExperience(!showAddWorkExperience)}
                                        modalEditWorkExperience={openEditWorkExperience}
                                        modalAddEducation={() => setShowAddEducation(!showAddEducation)}
                                        modalEditEducation={openEditWorkEducation}
                                        modalAddSkills={() => setShowAddSkills(!showAddSkills)}
                                        modalEditSkills={openEditSkill}
                                        modalAddLanguages={() => setShowAddLanguages(!showAddLanguages)}
                                        modalEditLanguages={openEditWorkLanguage}
                                        modalAddLicense={() => setShowAddLicense(!showAddLicense)}
                                        modalEditLicense={openEditWorkLicense}
                                        refreshPage={refresh}
                                    />
                                ) : index === 8 ? (
                                    <File
                                        UserId={userId}
                                    />
                                ) : index === 9 ? (
                                    <Training
                                        refreshPage={refresh}
                                        UserId={userId}
                                    />
                                ) : index === 10 ? (
                                    <Penghargaan
                                        refreshPage={refresh}
                                        UserId={userId}
                                    />
                                ) : index === 11 ? (
                                    <TugasBelajar
                                        refreshPage={refresh}
                                        UserId={userId}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyProfile