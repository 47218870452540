import { saveAs } from 'file-saver'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { BiFilter } from 'react-icons/bi'
import { BsFilter } from 'react-icons/bs'
import { IoIosSearch, IoMdArrowDropdown } from "react-icons/io"
import { TbArrowsSort, TbFileExport } from "react-icons/tb"
import * as XLSX from 'xlsx'
import Api from '../../../../Api'
import { Modal, Pagination } from '../../../../components'

function RekapPenghasilan({ idcompany }) {
    const [showFilter, setShowFilter] = useState(false)
    const [company, setCompany] = useState("")
    const [month, setMonth] = useState(moment().format('M'))
    const [year, setYear] = useState(moment().format('Y'))
    const [search, setSearch] = useState("")
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [totalCount, setTotalCount] = useState(1)
    const [refresh, setRefresh] = useState(false)
    const [dataPenghasilan, setDataPenghasilan] = useState([])
    const [columnPenghasilan, setColumnPenghasilan] = useState([])

    const getRekapPenghasilan = async () => {
        console.log(month, year, limit, page, company, search, jenisKepegawaian, unitKerja)
        try {
            await Api.GetRekapPenghasilan(localStorage.getItem('hris-token'), month, year, limit, page, company, search, jenisKepegawaian, unitKerja).then((response) => {
                console.log(response.data.data)
                setTotalCount(response.data.totalCount)
                setDataPenghasilan(response.data.data)
                setColumnPenghasilan(Object.keys(response.data.data[0]))
                setTotalPages(response.data.totalPages)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const handlePenghasilan = (m, y, s) => {
        if (m) {
            setMonth(m.target.value)
            setRefresh(true)
        } else if (y) {
            setYear(y.target.value)
            setRefresh(true)
        } else if (s) {
            setCompany(s.target.value)
            setRefresh(true)
        }
    }
    const handlePageChange = (val) => {
        setPage(val);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (page === 1) {
            setPage(1)
        } else {
            setPage(page - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (page === 10) {
            setPage(10)
        } else {
            setPage(page + 1);
        }
        setRefresh(true)
    };
    const formatRupiah = (number) => {
        // Menggunakan regex untuk menambahkan separator titik setiap 3 digit
        return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    const getDataExport = async () => {
        try {
            await Api.GetRekapPenghasilan(localStorage.getItem('hris-token'), month, year, totalCount, 1, company, search, jenisKepegawaian, unitKerja).then((response) => {
                const body = response.data.data;

                // Define custom headers for each table
                const Headers = [];
                Object.keys(response.data.data[0]).map((val) => (
                    val === "userId" ? null : Headers.push(val)
                ))

                // Create modified data arrays with custom headers
                const data = body.map((row, rowIndex) => {
                    const rowData = {};
                    Headers.forEach((header, index) => {
                        rowData[header] = row[header];
                    });
                    return rowData;
                });

                // Create a new worksheet for each table
                const worksheetGrade = XLSX.utils.json_to_sheet(data, { header: Headers });

                // Create a new workbook
                const workbook = XLSX.utils.book_new();

                // Add the worksheets to the workbook
                XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Rekap Absensi');
                // Generate Excel file buffer
                const excelBuffer = XLSX.write(workbook, {
                    bookType: 'xlsx',
                    type: 'array',
                });

                // Convert buffer to Blob
                const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Save the Excel file using FileSaver.js
                saveAs(excelBlob, 'Rekap Absensi.xlsx');
            })
        } catch (error) {
            console.log(error)
        }
    }

    const [dataPenempatan, setDataPenempatan] = useState([])
    const [dataUnitKerja, setDataUnitKerja] = useState([])
    const [dataKelasJabatan, setDataKelasJabatan] = useState([])
    const [dataJenisJabatan, setDataJenisJabatan] = useState([])
    const [dataJenisKepegawaian, setDataJenisKepegawaian] = useState([])
    const [dataJabatan, setDataJabatan] = useState([])
    const GetData = async () => {
        try {
            const res = await Api.GetSubsidiaryDropdown(localStorage.getItem('hris-token'))
            const res2 = await Api.GetUnitKerjaEselon(localStorage.getItem('hris-token'))
            const res3 = await Api.GetJobGradeDropdown(localStorage.getItem('hris-token'))
            const res4 = await Api.GetJobLevelDropdown(localStorage.getItem('hris-token'))
            const res5 = await Api.GetJobCategoryDropdown(localStorage.getItem('hris-token'))
            const res6 = await Api.GetJobPositionDropdown(localStorage.getItem('hris-token'), unitKerja)
            setDataPenempatan(res.data.results.data)
            setDataUnitKerja(res2.data.results.data)
            setDataKelasJabatan(res3.data.results.data)
            setDataJenisJabatan(res4.data.results.data)
            setDataJenisKepegawaian(res5.data.results.data)
            setDataJabatan(res6.data.results.data)
        } catch (error) {
            console.log(error)
        }
    }

    const [unitKerja, setUnitKerja] = useState("")
    const [jenisKepegawaian, setJenisKepegawaian] = useState("")

    const handleResetFilter = () => {
        setCompany("")
        setUnitKerja("")
        setJenisKepegawaian("")
        setShowFilter(!showFilter)
        setRefresh(true)
    }

    const filter = () => {
        setRefresh(true)
        setShowFilter(false)
    }

    const calculateActiveFilters = () => {
        let count = 0;
        if (company) count++;
        if (unitKerja) count++;
        if (jenisKepegawaian) count++;
        return count;
    };

    useEffect(() => {
        getRekapPenghasilan()
        GetData()
        setRefresh(false)
    }, [refresh, search])
    return (
        <>
            <div className='relative'>
                <Modal
                    activeModal={showFilter}
                    buttonClose={() => setShowFilter(!showFilter)}
                    width={'550px'}
                    title={
                        <div className='flex gap-[27px] items-center'>
                            <BsFilter className='text-dark-1 text-2xl' />
                            <h1 className='text-dark-1 text-[20px] font-semibold'>Filter</h1>
                        </div>
                    }
                    content={
                        <div className='space-y-[20px]'>
                            <div className='grid grid-cols-2 gap-3'>
                                <div className='relative'>
                                    <select onChange={(e) => setCompany(e.target.value)} value={company} className='text-grey-thick text-[11px] outline-none font-medium rounded-md w-full border border-dark-7 py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                        <option value={""} selected className='text-grey-medium font-normal'>Pilih Penempatan</option>
                                        {dataPenempatan && dataPenempatan.map((val, index) => (
                                            <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.company_name}</option>
                                        ))}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                                </div>
                                <div className='relative'>
                                    <select onChange={(e) => setJenisKepegawaian(e.target.value)} value={jenisKepegawaian} className='text-grey-thick text-[11px] outline-none font-medium rounded-md w-full border border-dark-7 py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                        <option value={""} selected className='text-grey-medium font-normal'>Pilih Jenis Kepegawaian</option>
                                        {dataJenisKepegawaian && dataJenisKepegawaian.map((val, index) => (
                                            <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.category_name}</option>
                                        ))}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                                </div>
                                <div className='relative col-span-2'>
                                    <select onChange={(e) => setUnitKerja(e.target.value)} value={unitKerja} className='text-grey-thick text-[11px] outline-none font-medium rounded-md w-full border border-dark-7 py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                        <option value={""} selected className='text-grey-medium font-normal'>Pilih Unit Kerja Eselon II/ Keasistenan Utama/ Perwakilan</option>
                                        {dataUnitKerja && dataUnitKerja.map((val, index) => (
                                            <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.name}</option>
                                        ))}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                                </div>
                            </div>
                            <div className='flex items-center justify-between'>
                                <button onClick={handleResetFilter} className='py-[10px] px-[15px] text-[#0E5073] text-[14px] font-medium flex justify-center rounded-md items-center border'>Reset Filter</button>
                                <button onClick={() => filter()} className='py-[10px] px-[15px] bg-[#0E5073] text-white text-[14px] font-medium flex justify-center rounded-md items-center'>Filter</button>
                            </div>
                        </div>
                    }
                />
            </div>
            <div className='space-y-[20px]'>
                <div className='space-y-[4]'>
                    <label className='text-grey-primer text-[20px] font-semibold'>Rekap Penghasilan</label>
                    <p className='text-grey-medium text-xs'>Reporting Data Penghasilan Pegawai</p>
                </div>
                <div className='flex flex-wrap items-center gap-3 justify-between'>
                    <div className='flex flex-wrap items-center gap-3'>
                        <div className='relative'>
                            <select onChange={(e) => handlePenghasilan(e, null, null)} value={month} className='text-grey-thick text-xs outline-none font-medium rounded-md w-[150px] border border-dark-7 py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                <option selected disabled value={''}>Pilih Bulan</option>
                                <option value={'1'} className='text-grey-medium font-normal'>Januari</option>
                                <option value={'2'} className='text-grey-medium font-normal'>Februari</option>
                                <option value={'3'} className='text-grey-medium font-normal'>Maret</option>
                                <option value={'4'} className='text-grey-medium font-normal'>April</option>
                                <option value={'5'} className='text-grey-medium font-normal'>Mei</option>
                                <option value={'6'} className='text-grey-medium font-normal'>Juni</option>
                                <option value={'7'} className='text-grey-medium font-normal'>Juli</option>
                                <option value={'8'} className='text-grey-medium font-normal'>Agustus</option>
                                <option value={'9'} className='text-grey-medium font-normal'>September</option>
                                <option value={'10'} className='text-grey-medium font-normal'>Oktober</option>
                                <option value={'11'} className='text-grey-medium font-normal'>November</option>
                                <option value={'12'} className='text-grey-medium font-normal'>Desember</option>
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                        </div>
                        <div className='relative'>
                            <select onChange={(e) => handlePenghasilan(null, e, null)} value={year} className='text-grey-thick text-xs outline-none font-medium rounded-md w-[100px] border border-dark-7 py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                <option selected disabled value={''} className='text-grey-medium font-normal'>Pilih Tahun</option>
                                <option value={'2023'} className='text-grey-medium font-normal'>2023</option>
                                <option value={'2024'} className='text-grey-medium font-normal'>2024</option>
                                <option value={'2025'} className='text-grey-medium font-normal'>2025</option>
                                <option value={'2026'} className='text-grey-medium font-normal'>2026</option>
                                <option value={'2027'} className='text-grey-medium font-normal'>2027</option>
                                <option value={'2028'} className='text-grey-medium font-normal'>2028</option>
                                <option value={'2029'} className='text-grey-medium font-normal'>2029</option>
                                <option value={'2030'} className='text-grey-medium font-normal'>2030</option>
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                        </div>
                        <button onClick={() => setShowFilter(!showFilter)} className='relative bg-white border border-dark-7 rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                            <BiFilter className='text-dark-5 text-2xl' />
                            Filter
                            {calculateActiveFilters() > 0 ?
                                <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2">{calculateActiveFilters()}</div>
                                : null
                            }
                        </button>
                        <button onClick={() => getDataExport()} className='bg-white border border-dark-7 lg:w-fit w-full rounded-[6px] flex items-center gap-[12px] py-[11px] pl-[9px] pr-[16px]'>
                            <TbFileExport className='text-grey-thick' />
                            <h1 className='text-grey-thick text-xs font-medium'>Ekspor</h1>
                        </button>
                    </div>
                    <div className='flex items-center gap-[14px] border border-grey-extra-thin rounded-[8px] px-[13px] py-[11px] lg:min-w-[250px] min-w-[100px]'>
                        <IoIosSearch className='text-[#8E95A2]' />
                        <input onChange={(e) => setSearch(e.target.value)} className='text-[#8E95A2] text-[10px] outline-none appearance-none' placeholder='Cari Nama Pegawai...' />
                    </div>
                </div>
                <div className='overflow-auto'>
                    <table className='w-full space-y-[10px]'>
                        <div className='bg-[#EBF7FF] rounded-[3px] border border-[#F1F1F1] p-[9px] flex items-center gap-[20px]'>
                            {columnPenghasilan && columnPenghasilan.map((val, index) => (
                                <div key={index} className={`flex items-center gap-[11px] min-w-[180px] ${val !== "userId" ? 'min-w-[180px]' : "min-w-[50px]"}`}>
                                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>{val !== "userId" ? val.replace(/_/g, ' ') : "No"}</h1>
                                    <TbArrowsSort className='text-grey-medium text-[8px]' />
                                </div>))}
                        </div>
                        {dataPenghasilan && dataPenghasilan.map((row, indexRow) => (
                            <div key={indexRow} className='space-y-[10px]'>
                                <div className='bg-white rounded-[3px] p-[9px] flex items-center gap-[20px]'>
                                    {Object.values(row).map((valCol, indexCol) => (
                                        indexCol === 0 ?
                                            <div key={indexCol} className='flex items-center gap-[11px] min-w-[50px] max-w-[180px]'>
                                                <h1 className='text-grey-medium text-[12px] font-medium line-clamp-1'>{indexRow + 1}</h1>
                                            </div>
                                            : <div key={indexCol} className='flex items-center gap-[11px] min-w-[180px] max-w-[180px]'>
                                                <h1 className='text-grey-medium text-[12px] font-medium line-clamp-1'>
                                                    {valCol ? (
                                                        Number.isInteger(valCol) ? `Rp. ${formatRupiah(valCol)}` : valCol
                                                    ) : "-"
                                                    }
                                                </h1>
                                            </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </table>
                </div>
                <Pagination
                    currentPage={page}
                    totalPages={totalPages}
                    lengthData={totalCount}
                    limitData={limit}
                    onPageChange={handlePageChange}
                    onPrevChange={handlePrevChange}
                    onNextChange={handleNextChange}
                />
            </div>
        </>
    )
}

export default RekapPenghasilan