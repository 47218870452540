import React from 'react'
import { AiOutlineCalendar, AiOutlineEye, AiOutlineUser } from 'react-icons/ai'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { Blog1, Blog2, Blog3, Tablet } from '../../../assets'
import { HelmetReact, NavbarLandingPage } from '../../../components'

const DetailBlog1 = () => {
    return (
        <div className='scroll-smooth'>

            <HelmetReact
                title={'Humanusia: Menciptakan Pengalaman Pengelolaan SDM yang Berfokus pada Karyawan'}
                description={''}
            />

            <NavbarLandingPage
                features={'#features'}
                pricing={'#pricing'}
            />

            {/* Begin : Section 1 */}
            <div className='px-[16px] lg:px-[150px] py-[75px] space-y-[25px]'>
                <div className='grid grid-cols-12 gap-[51px]'>
                    <div className='col-span-8 space-y-[18px]'>
                        <img src={Blog1} className='w-full h-[494px] object-cover rounded-xl' alt='' />
                        <h1 className='text-[#003049] font-bold text-[32px]'>Humanusia: Menciptakan Pengalaman Pengelolaan SDM yang Berfokus pada Karyawan</h1>
                        <div className='flex items-center gap-[11px]'>
                            <div className='flex items-center gap-[7px]'>
                                <AiOutlineCalendar className='text-dark-5' />
                                <p className='text-dark-5 text-sm'>July, 18 2023</p>
                            </div>
                            <div className='w-[1.5px] h-[26px] bg-dark-7 rounded-full' />
                            <div className='flex items-center gap-[7px]'>
                                <AiOutlineUser className='text-dark-5' />
                                <p className='text-dark-5 text-sm'>Humanusia</p>
                            </div>
                            <div className='w-[1.5px] h-[26px] bg-dark-7 rounded-full' />
                            <div className='flex items-center gap-[7px]'>
                                <AiOutlineEye className='text-dark-5' />
                                <p className='text-dark-5 text-sm'>104</p>
                            </div>
                        </div>
                        {/* Artikel */}
                        <div className='text-dark-2 text-justify space-y-[10px]'>
                            <p>Pengelolaan Sumber Daya Manusia (SDM) yang efektif dan berfokus pada karyawan merupakan faktor kunci dalam kesuksesan perusahaan. Dalam upaya untuk mencapai tujuan tersebut, perusahaan sering kali mencari solusi yang dapat meningkatkan pengalaman karyawan dan mengoptimalkan proses pengelolaan SDM. Salah satu solusi yang semakin populer adalah Humanusia, sebuah sistem pengelolaan SDM yang berfokus pada pengalaman karyawan.</p>
                            <p>Humanusia menawarkan pendekatan yang inovatif dan manusia sentris dalam pengelolaan SDM. Sistem ini bertujuan untuk menciptakan lingkungan kerja yang lebih baik dengan memperhatikan kebutuhan dan harapan individu karyawan. Humanusia mengakui bahwa karyawan adalah aset berharga perusahaan dan memberikan perhatian khusus pada kepentingan dan kepuasan mereka.</p>
                            <div className='w-full flex items-center justify-center'>
                                <img src={Tablet} alt='' />
                            </div>
                            <p>Salah satu aspek utama dari Humanusia adalah penggunaan teknologi modern, terutama aplikasi berbasis cloud, yang memungkinkan akses mudah dan cepat untuk karyawan di berbagai tingkatan organisasi. Dengan antarmuka yang ramah pengguna, karyawan dapat mengelola berbagai hal seperti pengajuan cuti, melihat jadwal kerja, mengakses informasi pribadi, dan berpartisipasi dalam pelatihan online. Dengan demikian, Humanusia menciptakan pengalaman pengelolaan SDM yang intuitif dan memudahkan karyawan dalam menjalankan tugas-tugas mereka.</p>
                            <p>Humanusia juga memiliki kemampuan analitik yang kuat, yang memungkinkan tim HR untuk mengakses data penting dan menganalisis tren yang berkaitan dengan karyawan dan kinerja organisasi. Dengan menggunakan data yang akurat dan terperinci, perusahaan dapat membuat keputusan yang lebih baik dalam hal rekrutmen, pengembangan karir, dan manajemen kinerja.</p>
                        </div>
                    </div>
                    <div className='col-span-4 space-y-2'>
                        <div>
                            <label className='text-[#780000] font-bold text-[28px]'>Postingan Terbaru</label>
                            <hr className='border-[#780000] border-2' />
                        </div>
                        <div className='py-[10px] flex gap-[20px]'>
                            <img src={Blog2} className='w-[70px] h-[70px] rounded-xl object-cover' alt='' />
                            <div className='space-y-2'>
                                <h2 className='text-[#003049] font-bold text-sm'>Maksimalkan Efisiensi SDM dengan Humansia yang unggul dengan Fitur-Fitur HRIS Terbaik</h2>
                                <Link to={'/detail-blog/maksimalkan-efisiensi-sdm-dengan-humansia-yang-unggul-dengan-fitur-fitur-hris-terbaik'} className='flex items-center gap-[15px]'>
                                    <p className='text-[#780000] text-sm font-medium'>Baca Selanjutnya</p>
                                    <HiArrowNarrowRight className='text-[#780000]' />
                                </Link>
                            </div>
                        </div>
                        <div className='py-[10px] flex gap-[20px]'>
                            <img src={Blog3} className='w-[70px] h-[70px] rounded-xl object-cover' alt='' />
                            <div className='space-y-2'>
                                <h2 className='text-[#003049] font-bold text-sm'>Humanusia sebagai Rekomendasi Sistem Manajemen HR dengan Harga Terjangkau</h2>
                                <Link to={'/detail-blog/humanusia-sebagai-rekomendasi-sistem-manajemen-hr-dengan-harga-terjangkau'} className='flex items-center gap-[15px]'>
                                    <p className='text-[#780000] text-sm font-medium'>Baca Selanjutnya</p>
                                    <HiArrowNarrowRight className='text-[#780000]' />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailBlog1