import moment from 'moment'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlinePlus } from 'react-icons/ai'
import { BiSearch } from 'react-icons/bi'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { IoMdArrowDropdown } from 'react-icons/io'
import Api from '../../../../Api'
import { LogoH } from '../../../../assets'
import { Modal, Navbar, TinyMce } from '../../../../components'
import imageHandle from '../../../../utils/imageHandle'
import languages from '../../../../utils/languages'

const HistoryAnnouncement = () => {
    const [modalAddAnnouncement, setModalAddAnnouncement] = useState()
    const [modalWhoCanSeeAnnouncement, setModalWhoCanSeeAnnouncement] = useState()
    const [announcement, setAnnouncement] = useState()
    const [selectedOption, setSelectedOption] = useState('everyone');
    const [refresh, setRefresh] = useState('')
    const [dataEmployee, setDataEmployee] = useState('')
    const [dataAnnouncement, setDataAnnouncement] = useState('')
    const [triggerOption, setTriggerOption] = useState({})


    //Create Polling
    const [jobGrade, setJobGrade] = useState([])
    const [jobLevel, setJobLevel] = useState([])
    const [jobCategory, setJobCategory] = useState([])
    const [jobPosition, setJobPosition] = useState([])
    const [employeeName, setEmployeeName] = useState([])
    const [idEmployee, setIdEmployee] = useState([])
    const [idJobGrade, setIdJobGrade] = useState([])
    const [idJobLevel, setIdJobLevel] = useState([])
    const [idJobCategory, setIdJobCategory] = useState([])
    const [idJobPosition, setIdJobPosition] = useState([])
    const [searchTerm, setSearchTerm] = useState('')

    //Get data Job
    const [dataJobGrade, setDataJobGrade] = useState('')
    const [dataJobLevel, setDataJobLevel] = useState('')
    const [dataJobCategory, setDataJobCategory] = useState('')
    const [dataJobPosition, setDataJobPosition] = useState('')

    const getContentTinyMce = (data) => {
        setAnnouncement(data);
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handlePopUpOption = (itemId) => {
        setTriggerOption((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId],
        }));
    };


    const getJobGrade = async () => {
        try {
            const response = await Api.GetJobGrade(localStorage.getItem('hris-token'), 1, 100)
            setDataJobGrade(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }
    const getJobLevel = async () => {
        try {
            const response = await Api.GetJobLevel(localStorage.getItem('hris-token'), 1, 100)
            console.log(response, 'jobLevel')
            setDataJobLevel(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }
    const getJobPosition = async () => {
        try {
            const resJobPosition = await Api.GetJobPosition(localStorage.getItem('hris-token'), 1, 100)
            setDataJobPosition(resJobPosition.data.data)
        } catch (error) {
            console.log(error)
        }
    }
    const getJobCategory = async () => {
        try {
            const response = await Api.GetJobCategory(localStorage.getItem('hris-token'))
            console.log(response, 'jobcategory')
            setDataJobCategory(response.data.jobCategory)
        } catch (error) {
            console.log(error)
        }
    }

    const GetEmployee = async () => {
        try {
            const response = await Api.GetEmployee(localStorage.getItem('hris-token'), '', '', '', '', '', '', '', '', '')
            console.log(response, 'employee')
            setDataEmployee(response.data.data.filter(item => item.role === 'USER'))
        } catch (error) {
            console.log(error)
        }
    }

    const getAnnouncement = async () => {
        try {
            const response = await Api.GetAnnouncement(localStorage.getItem('hris-token'))
            console.log(response, 'announcement')
            setDataAnnouncement(response.data.announcements)
        } catch (error) {
            console.log(error)
        }
    }

    const createAnnouncement = async () => {
        try {
            if (selectedOption === 'everyone') {
                const data = {
                    assigned_to_users: ['all'],
                    description: announcement
                }
                const response = await Api.CreateAnnouncement(localStorage.getItem('hris-token'), data)

            } else if (selectedOption === 'based on position') {
                const data = {
                    assigned_to_users: jobCategory,
                    description: announcement
                }
                const response = await Api.CreateAnnouncement(localStorage.getItem('hris-token'), data)

            } else {
                const data = {
                    assigned_to_users: idEmployee,
                    description: announcement
                }
                console.log(data)
                const response = await Api.CreateAnnouncement(localStorage.getItem('hris-token'), data)

            }
            setRefresh(true)
            setModalAddAnnouncement(!modalAddAnnouncement)
            toast.success('Success Create Announcement!')

        } catch (error) {
            console.log(error)
        }
    }

    const deleteAnnouncement = async (id) => {
        try {
            const response = await Api.DeleteAnnouncement(localStorage.getItem('hris-token'), id)
            toast.success('Success Delete Announcement')
            setRefresh(true)
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectChangeJobGrade = (id) => {
        const selectedOption = dataJobGrade.find(
            (data) => data.id === id
        );
        if (selectedOption && !idJobGrade.includes(id)) {
            setIdJobGrade([...idJobGrade, selectedOption.id]);
            setJobGrade([...jobGrade, selectedOption.job_grade]);
        }
    };

    const handleRemoveClickedNameJobGrade = (name) => {

        const index = jobGrade.indexOf(name);

        if (index !== -1) {
            const newIdJobGrade = [...idJobGrade];
            const newJobGrade = [...jobGrade];

            newIdJobGrade.splice(index, 1);
            newJobGrade.splice(index, 1);

            setIdJobGrade(newIdJobGrade);
            setJobGrade(newJobGrade);
        }
    };

    const handleSelectChangeJobLevel = (id) => {
        const selectedOption = dataJobLevel.find(
            (data) => data.id === id
        );
        if (selectedOption && !idJobLevel.includes(id)) {
            setIdJobLevel([...idJobLevel, selectedOption.id]);
            setJobLevel([...jobLevel, selectedOption.level_name]);
        }
    };

    const handleRemoveClickedNameJobLevel = (name) => {

        const index = jobLevel.indexOf(name);

        if (index !== -1) {
            const newIdJobLevel = [...idJobLevel];
            const newJobLevel = [...jobLevel];

            newIdJobLevel.splice(index, 1);
            newJobLevel.splice(index, 1);

            setIdJobLevel(newIdJobLevel);
            setJobLevel(newJobLevel);
        }
    };

    const handleSelectChangeJobCategory = (id) => {
        const selectedOption = dataJobCategory.find(
            (data) => data.id === id
        );
        if (selectedOption && !idJobCategory.includes(id)) {
            setIdJobCategory([...idJobCategory, selectedOption.id]);
            setJobCategory([...jobCategory, selectedOption.category_name]);
        }
    };

    const handleRemoveClickedNameJobCategory = (name) => {

        const index = jobCategory.indexOf(name);

        if (index !== -1) {
            const newIdJobCategory = [...idJobCategory];
            const newJobCategory = [...jobCategory];

            newIdJobCategory.splice(index, 1);
            newJobCategory.splice(index, 1);

            setIdJobCategory(newIdJobCategory);
            setJobCategory(newJobCategory);
        }
    };

    const handleSelectChangeJobPosition = (id) => {
        const selectedOption = dataJobPosition.find(
            (data) => data.id === id
        );
        if (selectedOption && !idJobPosition.includes(id)) {
            setIdJobPosition([...idJobPosition, selectedOption.id]);
            setJobPosition([...jobPosition, selectedOption.position_name]);
        }
    };

    const handleRemoveClickedNameJobPosition = (name) => {

        const index = jobPosition.indexOf(name);

        if (index !== -1) {
            const newIdJobPosition = [...idJobPosition];
            const newJobPosition = [...jobPosition];

            newIdJobPosition.splice(index, 1);
            newJobPosition.splice(index, 1);

            setIdJobPosition(newIdJobPosition);
            setJobPosition(newJobPosition);
        }
    };

    const handleSearchChangeEmployee = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleRemoveEmployee = (name) => {
        const index = employeeName.indexOf(name);
        if (index !== -1) {
            const newIdEmployee = [...idEmployee];
            const newEmployeeName = [...employeeName];

            newIdEmployee.splice(index, 1);
            newEmployeeName.splice(index, 1);

            setIdEmployee(newIdEmployee);
            setEmployeeName(newEmployeeName);
        }
    };

    const handleNameClickEmployee = (id) => {
        const selectedOption = dataEmployee.find(
            (data) => data.id === id
        );
        if (selectedOption && !idEmployee.includes(id)) {
            setIdEmployee([...idEmployee, selectedOption.id]);
            setEmployeeName([...employeeName, selectedOption.fullname]);
        }
    };

    useEffect(() => {
        getAnnouncement()
        setRefresh(false)
    }, [refresh])

    useEffect(() => {
        getJobGrade()
        getJobLevel()
        getJobPosition()
        getJobCategory()
        GetEmployee()
    }, [modalWhoCanSeeAnnouncement])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <Modal
                activeModal={modalAddAnnouncement}
                title={languages.language === 'id' ? "Buat Pengumuman" : "Create Announcement"}
                buttonClose={() => setModalAddAnnouncement(!modalAddAnnouncement)}
                width={'850px'}
                content={
                    <div className='space-y-6'>
                        <div>
                            <div className='flex items-center gap-[10px] mb-[14px]'>
                                <img className="w-[27px] h-[27px] rounded-full" src="https://via.placeholder.com/27x27" />
                                <h1 className="text-black text-xs font-normal">HR Admin </h1>
                            </div>
                            <button onClick={() => setModalWhoCanSeeAnnouncement(!modalWhoCanSeeAnnouncement)} className='py-[6px] px-[12px] rounded-[10px] border text-zinc-700 text-[11px] font-medium flex items-center gap-[10px] bg-grey-extra-thin'>
                                <h1 className="text-zinc-700 text-[11px] font-medium font-['Be Vietnam Pro'] tracking-tight">{languages.language === 'id' ? "Siapa yang dapat melihat postingan ini?" : "Who can see this post?"}</h1>
                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
                                    <path d="M7.68926 5.80142L3.59863 2.27369C3.44609 2.14263 3.22266 2.2608 3.22266 2.47242V9.52789C3.22266 9.73951 3.44609 9.85767 3.59863 9.72662L7.68926 6.19888C7.80635 6.09791 7.80635 5.9024 7.68926 5.80142Z" fill="#40444C" />
                                </svg>
                            </button>
                        </div>
                        <TinyMce
                            getContentTinyMce={getContentTinyMce}
                            setDataTinyMce={announcement}
                        />
                        <div className='flex items-center justify-center gap-[12px] mt-5 w-full'>
                            <button onClick={createAnnouncement} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Post" : "Submit"}</button>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={modalWhoCanSeeAnnouncement}
                title={'Post Viewer'}
                buttonClose={() => setModalWhoCanSeeAnnouncement(!modalWhoCanSeeAnnouncement)}
                width={'600px'}
                content={
                    <div className='space-y-6'>
                        <div>
                            <h1 className="text-zinc-700 text-base font-medium">{languages.language === 'id' ? "Siapa yang dapat melihat postingan ini?" : "Who can see this post?"}</h1>
                            <h1 className="text-zinc-600 text-sm font-normal">{languages.language === 'id' ? "Postingan Anda akan ditampilkan di beranda dan riwayat postingan. Audiens default disetel ke Semua Orang, namun Anda dapat mengubah audiens untuk postingan spesifik ini." : "your post will be shown in homepage and post history. The default audience is set to Everyone, but you can change the audience for these specific posts."}</h1>
                        </div>

                        <div className='space-y-[16px]'>
                            <div className={`w-full ${selectedOption === 'everyone' ? 'bg-red-thin' : 'bg-white'} p-[6px] rounded-lg`}>
                                <div className='flex items-center justify-between'>
                                    <div className='flex items-center gap-[12px]'>
                                        <div className="w-[60px] h-[60px] bg-white rounded-full flex items-center justify-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="35" viewBox="0 0 34 35" fill="none">
                                                <path d="M16.9997 3.36328C24.8239 3.36328 31.1663 9.7057 31.1663 17.5299C31.1663 25.3542 24.8239 31.6966 16.9997 31.6966C9.17543 31.6966 2.83301 25.3542 2.83301 17.5299C2.83301 9.7057 9.17543 3.36328 16.9997 3.36328ZM22.672 18.7738C22.1833 18.03 21.8305 17.4945 20.4875 17.707C17.9517 18.1094 17.6698 18.5528 17.5493 19.2838L17.5153 19.5048L17.4799 19.74C17.3425 20.7075 17.3468 21.073 17.7916 21.5391C19.5837 23.4204 20.6575 24.7762 20.9833 25.5695C21.142 25.9563 21.55 27.1279 21.2695 28.2867C22.9891 27.6018 24.5197 26.5151 25.7334 25.1176C25.8893 24.5878 26.0026 23.9276 26.0026 23.1286V22.9799C26.0026 21.6737 26.0026 21.0773 25.0789 20.5489C24.7821 20.3805 24.4771 20.2269 24.1652 20.0884C23.6453 19.8519 23.301 19.6974 22.8363 19.0174C22.7803 18.9371 22.7255 18.8559 22.672 18.7738ZM16.9997 5.96003C13.7173 5.96003 10.7522 7.32853 8.64701 9.52436C8.89776 9.69862 9.11592 9.9437 9.26609 10.2809C9.55509 10.9283 9.55509 11.5955 9.55509 12.1863C9.55509 12.6509 9.55509 13.0929 9.70384 13.4117C9.90784 13.848 10.789 14.035 11.5668 14.1965C11.8458 14.256 12.132 14.3155 12.3927 14.3878C13.1095 14.5861 13.6648 15.2307 14.1083 15.7478C14.2924 15.9617 14.5658 16.2776 14.7033 16.3569C14.7741 16.3059 15.0022 16.058 15.1141 15.6514C15.2019 15.3398 15.1764 15.0649 15.0503 14.9148C14.257 13.9798 14.3009 12.1806 14.546 11.5162C14.9313 10.4693 16.1355 10.5472 17.0167 10.6039C17.3453 10.6251 17.6542 10.6464 17.8865 10.6166C18.7677 10.5061 19.0397 9.16453 19.2309 8.90245C19.6446 8.33578 20.9111 7.48153 21.6959 6.95453C20.2176 6.29719 18.6175 5.95834 16.9997 5.96003Z" fill="#C1121F" />
                                            </svg>
                                        </div>
                                        <div>
                                            <h1 className="text-zinc-700 text-[15px] font-medium">{languages.language === 'id' ? "Semua Orang" : "Everyone"}</h1>
                                            <h1 className="text-zinc-600 text-sm font-normal">{languages.language === 'id' ? "Semua pegawai di perusahaan bisa melihat postingan ini" : "All Employee in this company can see this post"}</h1>
                                        </div>
                                    </div>
                                    <div>
                                        <label className="flex items-center gap-2">
                                            <input
                                                type="radio"
                                                value="everyone"
                                                checked={selectedOption === "everyone"}
                                                onChange={handleOptionChange}
                                                className="form-radio h-4 w-4 text-zinc-700"
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className={`w-full ${selectedOption === 'based on position' ? 'bg-red-thin' : 'bg-white'} bg-red-thin p-[6px] rounded-lg`}>
                                <div className='flex items-center justify-between'>
                                    <div className='flex items-center gap-[12px]'>
                                        <div className="w-[60px] h-[60px] bg-white rounded-full flex items-center justify-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="35" viewBox="0 0 34 35" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2706 8.26941V10.2811L7.87077 10.4766C7.04632 10.5429 6.26791 10.8838 5.66002 11.4447C5.05213 12.0056 4.64986 12.7541 4.51752 13.5706C4.45944 13.9361 4.40419 14.3016 4.35602 14.6685C4.34456 14.7572 4.36156 14.8473 4.40459 14.9257C4.44762 15.0041 4.51445 15.0668 4.59544 15.1048L4.70452 15.1558C12.3956 18.7967 21.6054 18.7967 29.295 15.1558L29.4041 15.1048C29.4848 15.0666 29.5514 15.0038 29.5941 14.9254C29.6369 14.847 29.6537 14.757 29.6421 14.6685C29.5941 14.3018 29.5407 13.9357 29.482 13.5706C29.3497 12.7541 28.9474 12.0056 28.3395 11.4447C27.7316 10.8838 26.9532 10.5429 26.1288 10.4766L23.7289 10.2825V8.27083C23.7291 7.67704 23.5161 7.10291 23.1287 6.65288C22.7413 6.20285 22.2053 5.9068 21.6181 5.81858L19.8898 5.55933C17.9738 5.27189 16.0257 5.27189 14.1098 5.55933L12.3814 5.81858C11.7945 5.90676 11.2586 6.20261 10.8713 6.65234C10.4839 7.10208 10.2708 7.67586 10.2706 8.26941ZM19.5739 7.66024C17.8673 7.40429 16.1322 7.40429 14.4257 7.66024L12.6974 7.9195C12.6135 7.93204 12.5369 7.97426 12.4816 8.03847C12.4262 8.10268 12.3957 8.18462 12.3956 8.26941V10.1323C15.4625 9.95664 18.537 9.95664 21.6039 10.1323V8.26941C21.6039 8.18462 21.5734 8.10268 21.518 8.03847C21.4626 7.97426 21.386 7.93204 21.3022 7.9195L19.5739 7.66024Z" fill="#C1121F" />
                                                <path d="M29.9168 17.6293C29.914 17.5835 29.9001 17.539 29.8763 17.4998C29.8525 17.4605 29.8196 17.4277 29.7803 17.404C29.741 17.3803 29.6965 17.3665 29.6507 17.3638C29.6049 17.3611 29.5591 17.3695 29.5173 17.3884C21.625 20.8833 12.3742 20.8833 4.48196 17.3884C4.44015 17.3695 4.39437 17.3611 4.34856 17.3638C4.30275 17.3665 4.25828 17.3803 4.21898 17.404C4.17968 17.4277 4.14673 17.4605 4.12295 17.4998C4.09916 17.539 4.08527 17.5835 4.08246 17.6293C3.93768 20.3415 4.08348 23.0613 4.51738 25.7425C4.64971 26.559 5.05199 27.3075 5.65988 27.8684C6.26777 28.4293 7.04617 28.7702 7.87063 28.8365L10.5226 29.0518C14.8335 29.3989 19.1643 29.3989 23.4766 29.0518L26.1286 28.8379C26.9533 28.7715 27.7318 28.4304 28.3398 27.8693C28.9477 27.3081 29.3498 26.5592 29.4819 25.7425C29.9154 23.0579 30.0627 20.3379 29.9168 17.6307V17.6293Z" fill="#C1121F" />
                                            </svg>
                                        </div>
                                        <div>
                                            <h1 className="text-zinc-700 text-[15px] font-medium">{languages.language === 'id' ? "Berdasarkan Jabatan" : "Based on Position"}</h1>
                                            <h1 className="text-zinc-600 text-sm font-normal">{languages.language === 'id' ? "Semua pegawai di perusahaan bisa melihat postingan ini" : "All Employee in this company can see this post"}</h1>
                                        </div>
                                    </div>
                                    <div>
                                        <label className="flex items-center gap-2">
                                            <input
                                                type="radio"
                                                value="based on position"
                                                checked={selectedOption === "based on position"}
                                                onChange={handleOptionChange}
                                                className="form-radio h-4 w-4 text-zinc-700"
                                            />
                                        </label>
                                    </div>
                                </div>
                                {selectedOption === 'based on position' && (
                                    <div className='mt-[19px] ml-[70px] space-y-[15px]'>
                                        <div>
                                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Job Grade <span className='text-[#780000]'>*</span></h1>
                                            <div className='relative w-full mb-2'>
                                                <select onChange={(e) => handleSelectChangeJobGrade(e.target.value)} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                                    <option selected value={''}>{languages.language === 'id' ? "Pilih Job Grade..." : "Select Job Grade..."}</option>
                                                    {Object.values(dataJobGrade).map((data, index) => {
                                                        return (
                                                            <option key={index} value={data.id}>{data.job_grade}</option>
                                                        )
                                                    })}
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                            </div>
                                            {jobGrade.length > 0 && (
                                                <div className='flex items-center justify-start gap-[8px] flex-wrap '>
                                                    {jobGrade.map((name, index) => (
                                                        <div key={index} className='flex gap-[8px] items-center rounded-lg'>
                                                            <div className='rounded-md py-[4px] px-[10px] text-dark-3 text-xs flex gap-[8px] items-center border border-dark-3'>
                                                                <h1>{name}</h1>
                                                                <button onClick={() => handleRemoveClickedNameJobGrade(name)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                        <path d="M2.90333 2.90431C2.93236 2.87521 2.96685 2.85212 3.00481 2.83636C3.04278 2.82061 3.08348 2.8125 3.12458 2.8125C3.16569 2.8125 3.20639 2.82061 3.24435 2.83636C3.28232 2.85212 3.3168 2.87521 3.34583 2.90431L4.99958 4.55868L6.65333 2.90431C6.68239 2.87525 6.71688 2.85221 6.75484 2.83648C6.7928 2.82076 6.83349 2.81266 6.87458 2.81266C6.91567 2.81266 6.95636 2.82076 6.99432 2.83648C7.03228 2.85221 7.06678 2.87525 7.09583 2.90431C7.12489 2.93336 7.14793 2.96786 7.16366 3.00582C7.17938 3.04378 7.18748 3.08447 7.18748 3.12556C7.18748 3.16665 7.17938 3.20734 7.16366 3.2453C7.14793 3.28326 7.12489 3.31775 7.09583 3.34681L5.44146 5.00056L7.09583 6.65431C7.12489 6.68336 7.14793 6.71786 7.16366 6.75582C7.17938 6.79378 7.18748 6.83447 7.18748 6.87556C7.18748 6.91665 7.17938 6.95734 7.16366 6.9953C7.14793 7.03326 7.12489 7.06775 7.09583 7.09681C7.06678 7.12586 7.03228 7.14891 6.99432 7.16464C6.95636 7.18036 6.91567 7.18845 6.87458 7.18845C6.83349 7.18845 6.7928 7.18036 6.75484 7.16464C6.71688 7.14891 6.68239 7.12586 6.65333 7.09681L4.99958 5.44243L3.34583 7.09681C3.31678 7.12586 3.28228 7.14891 3.24432 7.16464C3.20636 7.18036 3.16567 7.18845 3.12458 7.18845C3.08349 7.18845 3.0428 7.18036 3.00484 7.16464C2.96688 7.14891 2.93239 7.12586 2.90333 7.09681C2.87428 7.06775 2.85123 7.03326 2.8355 6.9953C2.81978 6.95734 2.81169 6.91665 2.81169 6.87556C2.81169 6.83447 2.81978 6.79378 2.8355 6.75582C2.85123 6.71786 2.87428 6.68336 2.90333 6.65431L4.55771 5.00056L2.90333 3.34681C2.87423 3.31778 2.85114 3.2833 2.83539 3.24533C2.81963 3.20736 2.81152 3.16666 2.81152 3.12556C2.81152 3.08445 2.81963 3.04375 2.83539 3.00579C2.85114 2.96782 2.87423 2.93334 2.90333 2.90431Z" fill="#C1121F" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Job Level<span className='text-[#780000]'>*</span></h1>
                                            <div className='relative w-full mb-2'>
                                                <select onChange={(e) => handleSelectChangeJobLevel(e.target.value)} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                                    <option selected value={''}>{languages.language === 'id' ? "Pilih Job Level..." : "Select Job Level..."}</option>
                                                    {Object.values(dataJobLevel).map((data, index) => {
                                                        return (
                                                            <option key={index} value={data.id}>{data.level_name}</option>
                                                        )
                                                    })}
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                            </div>
                                            {jobLevel.length > 0 && (
                                                <div className='flex items-center justify-start gap-[8px] flex-wrap '>
                                                    {jobLevel.map((name, index) => (
                                                        <div key={index} className='flex gap-[8px] items-center rounded-lg'>
                                                            <div className='rounded-md py-[4px] px-[10px] text-dark-3 text-xs flex gap-[8px] items-center border border-dark-3'>
                                                                <h1>{name}</h1>
                                                                <button onClick={() => handleRemoveClickedNameJobLevel(name)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                        <path d="M2.90333 2.90431C2.93236 2.87521 2.96685 2.85212 3.00481 2.83636C3.04278 2.82061 3.08348 2.8125 3.12458 2.8125C3.16569 2.8125 3.20639 2.82061 3.24435 2.83636C3.28232 2.85212 3.3168 2.87521 3.34583 2.90431L4.99958 4.55868L6.65333 2.90431C6.68239 2.87525 6.71688 2.85221 6.75484 2.83648C6.7928 2.82076 6.83349 2.81266 6.87458 2.81266C6.91567 2.81266 6.95636 2.82076 6.99432 2.83648C7.03228 2.85221 7.06678 2.87525 7.09583 2.90431C7.12489 2.93336 7.14793 2.96786 7.16366 3.00582C7.17938 3.04378 7.18748 3.08447 7.18748 3.12556C7.18748 3.16665 7.17938 3.20734 7.16366 3.2453C7.14793 3.28326 7.12489 3.31775 7.09583 3.34681L5.44146 5.00056L7.09583 6.65431C7.12489 6.68336 7.14793 6.71786 7.16366 6.75582C7.17938 6.79378 7.18748 6.83447 7.18748 6.87556C7.18748 6.91665 7.17938 6.95734 7.16366 6.9953C7.14793 7.03326 7.12489 7.06775 7.09583 7.09681C7.06678 7.12586 7.03228 7.14891 6.99432 7.16464C6.95636 7.18036 6.91567 7.18845 6.87458 7.18845C6.83349 7.18845 6.7928 7.18036 6.75484 7.16464C6.71688 7.14891 6.68239 7.12586 6.65333 7.09681L4.99958 5.44243L3.34583 7.09681C3.31678 7.12586 3.28228 7.14891 3.24432 7.16464C3.20636 7.18036 3.16567 7.18845 3.12458 7.18845C3.08349 7.18845 3.0428 7.18036 3.00484 7.16464C2.96688 7.14891 2.93239 7.12586 2.90333 7.09681C2.87428 7.06775 2.85123 7.03326 2.8355 6.9953C2.81978 6.95734 2.81169 6.91665 2.81169 6.87556C2.81169 6.83447 2.81978 6.79378 2.8355 6.75582C2.85123 6.71786 2.87428 6.68336 2.90333 6.65431L4.55771 5.00056L2.90333 3.34681C2.87423 3.31778 2.85114 3.2833 2.83539 3.24533C2.81963 3.20736 2.81152 3.16666 2.81152 3.12556C2.81152 3.08445 2.81963 3.04375 2.83539 3.00579C2.85114 2.96782 2.87423 2.93334 2.90333 2.90431Z" fill="#C1121F" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Job Category<span className='text-[#780000]'>*</span></h1>
                                            <div className='relative w-full mb-2'>
                                                <select onChange={(e) => handleSelectChangeJobCategory(e.target.value)} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                                    <option selected value={''}>{languages.language === 'id' ? "Pilih Job Kategori..." : "Select Job Category..."}</option>
                                                    {Object.values(dataJobCategory).map((data, index) => {
                                                        return (
                                                            <option key={index} value={data.id}>{data.category_name}</option>
                                                        )
                                                    })}
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                            </div>
                                            {jobCategory.length > 0 && (
                                                <div className='flex items-center justify-start gap-[8px] flex-wrap '>
                                                    {jobCategory.map((name, index) => (
                                                        <div key={index} className='flex gap-[8px] items-center rounded-lg'>
                                                            <div className='rounded-md py-[4px] px-[10px] text-dark-3 text-xs flex gap-[8px] items-center border border-dark-3'>
                                                                <h1>{name}</h1>
                                                                <button onClick={() => handleRemoveClickedNameJobCategory(name)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                        <path d="M2.90333 2.90431C2.93236 2.87521 2.96685 2.85212 3.00481 2.83636C3.04278 2.82061 3.08348 2.8125 3.12458 2.8125C3.16569 2.8125 3.20639 2.82061 3.24435 2.83636C3.28232 2.85212 3.3168 2.87521 3.34583 2.90431L4.99958 4.55868L6.65333 2.90431C6.68239 2.87525 6.71688 2.85221 6.75484 2.83648C6.7928 2.82076 6.83349 2.81266 6.87458 2.81266C6.91567 2.81266 6.95636 2.82076 6.99432 2.83648C7.03228 2.85221 7.06678 2.87525 7.09583 2.90431C7.12489 2.93336 7.14793 2.96786 7.16366 3.00582C7.17938 3.04378 7.18748 3.08447 7.18748 3.12556C7.18748 3.16665 7.17938 3.20734 7.16366 3.2453C7.14793 3.28326 7.12489 3.31775 7.09583 3.34681L5.44146 5.00056L7.09583 6.65431C7.12489 6.68336 7.14793 6.71786 7.16366 6.75582C7.17938 6.79378 7.18748 6.83447 7.18748 6.87556C7.18748 6.91665 7.17938 6.95734 7.16366 6.9953C7.14793 7.03326 7.12489 7.06775 7.09583 7.09681C7.06678 7.12586 7.03228 7.14891 6.99432 7.16464C6.95636 7.18036 6.91567 7.18845 6.87458 7.18845C6.83349 7.18845 6.7928 7.18036 6.75484 7.16464C6.71688 7.14891 6.68239 7.12586 6.65333 7.09681L4.99958 5.44243L3.34583 7.09681C3.31678 7.12586 3.28228 7.14891 3.24432 7.16464C3.20636 7.18036 3.16567 7.18845 3.12458 7.18845C3.08349 7.18845 3.0428 7.18036 3.00484 7.16464C2.96688 7.14891 2.93239 7.12586 2.90333 7.09681C2.87428 7.06775 2.85123 7.03326 2.8355 6.9953C2.81978 6.95734 2.81169 6.91665 2.81169 6.87556C2.81169 6.83447 2.81978 6.79378 2.8355 6.75582C2.85123 6.71786 2.87428 6.68336 2.90333 6.65431L4.55771 5.00056L2.90333 3.34681C2.87423 3.31778 2.85114 3.2833 2.83539 3.24533C2.81963 3.20736 2.81152 3.16666 2.81152 3.12556C2.81152 3.08445 2.81963 3.04375 2.83539 3.00579C2.85114 2.96782 2.87423 2.93334 2.90333 2.90431Z" fill="#C1121F" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Job Position<span className='text-[#780000]'>*</span></h1>
                                            <div className='relative w-full mb-2'>
                                                <select onChange={(e) => handleSelectChangeJobPosition(e.target.value)} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                                    <option selected value={''}>{languages.language === 'id' ? "Pilih Job Posistion..." : "Select Job Position..."}</option>
                                                    {Object.values(dataJobPosition).map((data, index) => {
                                                        return (
                                                            <option key={index} value={data.id}>{data.position_name}</option>
                                                        )
                                                    })}
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                            </div>
                                            {jobPosition.length > 0 && (
                                                <div className='flex items-center justify-start gap-[8px] flex-wrap '>
                                                    {jobPosition.map((name, index) => (
                                                        <div key={index} className='flex gap-[8px] items-center rounded-lg'>
                                                            <div className='rounded-md py-[4px] px-[10px] text-dark-3 text-xs flex gap-[8px] items-center border border-dark-3'>
                                                                <h1>{name}</h1>
                                                                <button onClick={() => handleRemoveClickedNameJobPosition(name)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                        <path d="M2.90333 2.90431C2.93236 2.87521 2.96685 2.85212 3.00481 2.83636C3.04278 2.82061 3.08348 2.8125 3.12458 2.8125C3.16569 2.8125 3.20639 2.82061 3.24435 2.83636C3.28232 2.85212 3.3168 2.87521 3.34583 2.90431L4.99958 4.55868L6.65333 2.90431C6.68239 2.87525 6.71688 2.85221 6.75484 2.83648C6.7928 2.82076 6.83349 2.81266 6.87458 2.81266C6.91567 2.81266 6.95636 2.82076 6.99432 2.83648C7.03228 2.85221 7.06678 2.87525 7.09583 2.90431C7.12489 2.93336 7.14793 2.96786 7.16366 3.00582C7.17938 3.04378 7.18748 3.08447 7.18748 3.12556C7.18748 3.16665 7.17938 3.20734 7.16366 3.2453C7.14793 3.28326 7.12489 3.31775 7.09583 3.34681L5.44146 5.00056L7.09583 6.65431C7.12489 6.68336 7.14793 6.71786 7.16366 6.75582C7.17938 6.79378 7.18748 6.83447 7.18748 6.87556C7.18748 6.91665 7.17938 6.95734 7.16366 6.9953C7.14793 7.03326 7.12489 7.06775 7.09583 7.09681C7.06678 7.12586 7.03228 7.14891 6.99432 7.16464C6.95636 7.18036 6.91567 7.18845 6.87458 7.18845C6.83349 7.18845 6.7928 7.18036 6.75484 7.16464C6.71688 7.14891 6.68239 7.12586 6.65333 7.09681L4.99958 5.44243L3.34583 7.09681C3.31678 7.12586 3.28228 7.14891 3.24432 7.16464C3.20636 7.18036 3.16567 7.18845 3.12458 7.18845C3.08349 7.18845 3.0428 7.18036 3.00484 7.16464C2.96688 7.14891 2.93239 7.12586 2.90333 7.09681C2.87428 7.06775 2.85123 7.03326 2.8355 6.9953C2.81978 6.95734 2.81169 6.91665 2.81169 6.87556C2.81169 6.83447 2.81978 6.79378 2.8355 6.75582C2.85123 6.71786 2.87428 6.68336 2.90333 6.65431L4.55771 5.00056L2.90333 3.34681C2.87423 3.31778 2.85114 3.2833 2.83539 3.24533C2.81963 3.20736 2.81152 3.16666 2.81152 3.12556C2.81152 3.08445 2.81963 3.04375 2.83539 3.00579C2.85114 2.96782 2.87423 2.93334 2.90333 2.90431Z" fill="#C1121F" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                )}
                            </div>
                            <div className={`w-full ${selectedOption === 'employee' ? 'bg-red-thin' : 'bg-white'} bg-red-thin p-[6px] rounded-lg`}>
                                <div className='flex items-center justify-between'>
                                    <div className='flex items-center gap-[12px]'>
                                        <div className="w-[60px] h-[60px] bg-white rounded-full flex items-center justify-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="35" viewBox="0 0 34 35" fill="none">
                                                <path d="M15.9234 15.8233C19.2929 15.8233 22.0245 13.0918 22.0245 9.7222C22.0245 6.35265 19.2929 3.62109 15.9234 3.62109C12.5538 3.62109 9.82227 6.35265 9.82227 9.7222C9.82227 13.0918 12.5538 15.8233 15.9234 15.8233Z" fill="#C1121F" />
                                                <path d="M19.833 26.9766H26.4441V28.2988H19.833V26.9766Z" fill="#C1121F" />
                                                <path d="M14.1667 28.8648V31.6981C14.1667 31.9486 14.2662 32.1888 14.4434 32.366C14.6205 32.5431 14.8607 32.6426 15.1112 32.6426H31.1667C31.4172 32.6426 31.6574 32.5431 31.8346 32.366C32.0117 32.1888 32.1112 31.9486 32.1112 31.6981V22.2537C32.1112 22.0032 32.0117 21.763 31.8346 21.5859C31.6574 21.4088 31.4172 21.3092 31.1667 21.3092H24.5556V19.9209C24.5556 19.6704 24.4561 19.4302 24.279 19.2531C24.1019 19.076 23.8617 18.9765 23.6112 18.9765C23.3607 18.9765 23.1205 19.076 22.9434 19.2531C22.7662 19.4302 22.6667 19.6704 22.6667 19.9209V21.3092H20.7778V17.9281C19.1729 17.666 17.5496 17.5334 15.9234 17.5315C12.3372 17.5162 8.79109 18.2867 5.53451 19.7887C4.99852 20.0416 4.54634 20.443 4.23162 20.9452C3.91691 21.4474 3.75286 22.0294 3.75895 22.622V28.8648H14.1667ZM30.2223 30.7537H16.0556V23.1981H22.6667V23.5948C22.6667 23.8453 22.7662 24.0855 22.9434 24.2626C23.1205 24.4397 23.3607 24.5392 23.6112 24.5392C23.8617 24.5392 24.1019 24.4397 24.279 24.2626C24.4561 24.0855 24.5556 23.8453 24.5556 23.5948V23.1981H30.2223V30.7537Z" fill="#C1121F" />
                                            </svg>
                                        </div>
                                        <div>
                                            <h1 className="text-zinc-700 text-[15px] font-medium">{languages.language === 'id' ? "Kustom Karyawan" : "Custome Employees"}Everyone</h1>
                                            <h1 className="text-zinc-600 text-sm font-normal">{languages.language === 'id' ? "Semua Karyawan yang dipilih dapat melihat postingan" : "All Selected Employee in this company can see this post"}</h1>
                                        </div>
                                    </div>
                                    <div>
                                        <label className="flex items-center gap-2">
                                            <input
                                                type="radio"
                                                value="employee"
                                                checked={selectedOption === "employee"}
                                                onChange={handleOptionChange}
                                                className="form-radio h-4 w-4 text-zinc-700"
                                            />
                                        </label>
                                    </div>
                                </div>

                                {selectedOption === 'employee' && (
                                    <div className='mt-[19px] ml-[70px]'>
                                        <div>
                                            <div className='relative py-[12px] pl-[12px] pr-[13px] border rounded-lg flex text-[#8E95A2] font-medium w-full bg-white mb-3'>
                                                <BiSearch className='absolute right-[12px] text-lg' />
                                                <input value={searchTerm} onChange={handleSearchChangeEmployee} type="text" placeholder={languages.language === 'id' ? "Cari Berdasarkan Nama Pegawai..." : "Search Employee name..."} className='outline-none text-[12px] w-full' />
                                            </div>

                                            {searchTerm.trim() !== '' && (
                                                <div className={`bg-white rounded-md mb-3 shadow overflow-auto min-h-fit max-h-[120px]`}>
                                                    {dataEmployee.map((item, index) => (
                                                        <h1 key={index} className='flex text-[12px] font-medium items-center p-[10px]' onClick={() => handleNameClickEmployee(item.id)}>
                                                            {item.fullname}
                                                        </h1>
                                                    ))}
                                                </div>
                                            )}

                                            {employeeName.length > 0 && (
                                                <div className='flex items-center justify-start gap-[8px] flex-wrap '>
                                                    {employeeName.map((name, index) => (
                                                        <div key={index} className='flex gap-[8px] items-center rounded-lg'>
                                                            <div className='rounded-md py-[4px] px-[10px] text-dark-3 text-xs flex gap-[8px] items-center border border-dark-3'>
                                                                <h1>{name}</h1>
                                                                <button onClick={() => handleRemoveEmployee(name)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                        <path d="M2.90333 2.90431C2.93236 2.87521 2.96685 2.85212 3.00481 2.83636C3.04278 2.82061 3.08348 2.8125 3.12458 2.8125C3.16569 2.8125 3.20639 2.82061 3.24435 2.83636C3.28232 2.85212 3.3168 2.87521 3.34583 2.90431L4.99958 4.55868L6.65333 2.90431C6.68239 2.87525 6.71688 2.85221 6.75484 2.83648C6.7928 2.82076 6.83349 2.81266 6.87458 2.81266C6.91567 2.81266 6.95636 2.82076 6.99432 2.83648C7.03228 2.85221 7.06678 2.87525 7.09583 2.90431C7.12489 2.93336 7.14793 2.96786 7.16366 3.00582C7.17938 3.04378 7.18748 3.08447 7.18748 3.12556C7.18748 3.16665 7.17938 3.20734 7.16366 3.2453C7.14793 3.28326 7.12489 3.31775 7.09583 3.34681L5.44146 5.00056L7.09583 6.65431C7.12489 6.68336 7.14793 6.71786 7.16366 6.75582C7.17938 6.79378 7.18748 6.83447 7.18748 6.87556C7.18748 6.91665 7.17938 6.95734 7.16366 6.9953C7.14793 7.03326 7.12489 7.06775 7.09583 7.09681C7.06678 7.12586 7.03228 7.14891 6.99432 7.16464C6.95636 7.18036 6.91567 7.18845 6.87458 7.18845C6.83349 7.18845 6.7928 7.18036 6.75484 7.16464C6.71688 7.14891 6.68239 7.12586 6.65333 7.09681L4.99958 5.44243L3.34583 7.09681C3.31678 7.12586 3.28228 7.14891 3.24432 7.16464C3.20636 7.18036 3.16567 7.18845 3.12458 7.18845C3.08349 7.18845 3.0428 7.18036 3.00484 7.16464C2.96688 7.14891 2.93239 7.12586 2.90333 7.09681C2.87428 7.06775 2.85123 7.03326 2.8355 6.9953C2.81978 6.95734 2.81169 6.91665 2.81169 6.87556C2.81169 6.83447 2.81978 6.79378 2.8355 6.75582C2.85123 6.71786 2.87428 6.68336 2.90333 6.65431L4.55771 5.00056L2.90333 3.34681C2.87423 3.31778 2.85114 3.2833 2.83539 3.24533C2.81963 3.20736 2.81152 3.16666 2.81152 3.12556C2.81152 3.08445 2.81963 3.04375 2.83539 3.00579C2.85114 2.96782 2.87423 2.93334 2.90333 2.90431Z" fill="#C1121F" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                )}
                            </div>
                        </div>

                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={() => setModalWhoCanSeeAnnouncement(!modalWhoCanSeeAnnouncement)} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-full py-[10px] px-[25px]'>{languages.language === 'id' ? "Terapkan" : "Apply"}</button>
                        </div>
                    </div>
                }
            />

            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={languages.language === 'id' ? "Riwayat Pengumuman" : "History Announcement"} LinkBack={'/dashboard'} />
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div className='flex items-center justify-between'>
                            <div>
                                <input type="date" className='py-[13px] px-[10px] text-[12px] border rounded-md font-medium text-[#8E95A2] bg-grey-scale' />
                            </div>
                            <div className='flex items-center gap-[8px]'>
                                <div className='relative'>
                                    <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg' />
                                    <input placeholder={languages.language === 'id' ? "Cari Nama Pengumuman..." : "Search Announcement Name..."} className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]' />
                                </div>
                                <button onClick={() => setModalAddAnnouncement(!modalAddAnnouncement)} className='flex gap-[4px] items-center px-[8px] py-[7px] text-[14px] font-medium bg-blue-thick rounded-lg border text-white'>
                                    <AiOutlinePlus className='text-lg' />
                                    <h1>{languages.language === 'id' ? "Pengumuman Baru" : "New Announcement"}</h1>
                                </button>
                            </div>
                        </div>

                        <div className='space-y-[12px]'>
                            {Object.values(dataAnnouncement).map((item, idx) => (
                                <div className='py-[28px] px-[36px] border rounded-xl shadow'>
                                    <div className='flex items-center justify-between mb-[16px]'>
                                        <div className='flex items-center gap-[17px]'>
                                            <img className="w-[38.24px] h-[41.13px] rounded-full shadow" src={item.user.user_detail.image ? imageHandle(item.user.user_detail.image) : LogoH} />
                                            <div>
                                                <h1 className="text-zinc-800 text-base font-semibold">{item.user.fullname ?? '-'}</h1>
                                                <h1 className="text-gray-400 text-[11px] font-light">{moment(item.createdAt).format('DD-MM-YYYY')} ; {moment(item.createdAt).format('HH:MM')}</h1>
                                            </div>
                                        </div>
                                        <div onClick={() => handlePopUpOption(item.id)} className='relative'>
                                            <BsThreeDotsVertical />
                                            {triggerOption[item.id] &&
                                                <>
                                                    <div className='border rounded-lg shadow-lg px-[17px] py-[14px] absolute right-0 w-[192px] bg-white text-dark-3 text-[10px] font-medium'>
                                                        <button onClick={() => deleteAnnouncement(item.id)} className='w-full text-start mb-[12px] hover:bg-slate-300'>{languages.language === 'id' ? "Hapus" : "Delete"}</button>
                                                        <button className='w-full text-start'>{languages.language === 'id' ? "Salin Tautan" : "Copy Link"}</button>
                                                    </div>
                                                </>}
                                        </div>
                                    </div>
                                    <div className="text-zinc-700 text-sm font-normal" dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HistoryAnnouncement