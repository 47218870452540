import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlineSearch } from 'react-icons/ai'
import { BiFilter } from 'react-icons/bi'
import { BsFilter } from 'react-icons/bs'
import { HiOutlinePencil } from 'react-icons/hi'
import { IoMdArrowDropdown } from 'react-icons/io'
import Select from 'react-select'
import Api from '../../../../Api'
import { Modal, Navbar, Pagination } from '../../../../components'
import languages from '../../../../utils/languages'

const Pelaporan = () => {
    const [refresh, setRefresh] = useState(false)
    const [showModalEdit, setShowModalEdit] = useState(false)
    const [showModalEditMassal, setShowModalEditMassal] = useState(false)
    const [data, setData] = useState('')
    const limit = 10
    const [menu, setMenu] = useState('cuti')

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')

    const [userId, setUserId] = useState("")
    const [isOnlyAdminCuti, setIsOnlyAdminCuti] = useState()
    const [isOnlyAdminIzin, setIsOnlyAdminIzin] = useState()
    const [isOnlyAdminLaporan, setIsOnlyAdminLaporan] = useState()

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const resetForm = () => {
        setIdEmployee("")
        setIdEmployeeBerwenang("")
        setVerificator("")
        setVerificatorId("")
        setIdEmployeeIzin("")
        setPenilai1Id("")
        setPersetujuan("")
        setPenilai2Id("")
        setIsOnlyAdminCuti("")
        setIsOnlyAdminIzin("")
        setIsOnlyAdminLaporan("")
    }
    const getData = async () => {
        try {
            const response = await Api.GetApprovalSetting(localStorage.getItem('hris-token'), currentPage, limit, name, jobGrade, jobLevel, jobPosition, jobCategory, company, unitKerja)
            // console.log(response.data.results.data, 'persetujuan')
            setData(response.data.results.data)
            setCurrentPage(response.data.results.currentPage)
            setTotalPages(response.data.results.totalPages)
            setTotalCount(response.data.results.totalCount)
        } catch (error) {
            console.log(error)
        }
    }

    const updateData = async (massal) => {
        let form = {}
        if (menu === 'cuti') {
            form = {
                "userId": [],
                "atasanId": idEmployee.value,
                "pejabatBerwenangId": idEmployeeBerwenang.value,
                "verifikatorId": "",
                "isOnlyAdmin": isOnlyAdminCuti
            }
            if (massal) {
                form.userId = selectedRows
            } else {
                form.userId.push(userId)
            }

            if (verificator === true) {
                form["verifikatorId"] = verificatorId?.value
            }
        } else if (menu === 'izin') {
            form = {
                "userId": [],
                "atasanId": idEmployeeIzin.value,
                "isOnlyAdmin": isOnlyAdminIzin
            }
            if (massal) {
                form.userId = selectedRows
            } else {
                form.userId.push(userId)
            }
        } else if (menu === 'laporan-kinerja') {
            form = {
                "userId": [],
                "atasan1Id": penilai1Id.value,
                "atasan2Id": "",
                "isOnlyAdmin": isOnlyAdminLaporan
            }
            if (massal) {
                form.userId = selectedRows
            } else {
                form.userId.push(userId)
            }

            if (persetujuan === true) {
                form["atasan2Id"] = penilai2Id.value
            }
        }
        console.log(form)
        try {
            await Api.UpdateApprovalSetting(localStorage.getItem('hris-token'), menu, form).then((response) => {
                setRefresh(true)
                setMenu('cuti')
                resetForm()
                setShowModalEdit(false)
                setShowModalEditMassal(false)
                toast.success("Data Berhasil Diubah")
            }).catch((error) => {
                console.log(error)
                if (error?.response?.data?.errors?.error?.details) {
                    const e = error.response.data.errors.error.details
                    for (let i = 0; i < e.length; i++) {
                        toast.error(e[i].message)
                    }
                } else if (error?.response?.data?.errors) {
                    toast.error(error.response.data.errors)
                } else {
                    toast.error("Gagal Mengubah Data!")
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    // Cuti
    const [idEmployee, setIdEmployee] = useState()
    const [idEmployeeBerwenang, setIdEmployeeBerwenang] = useState([])
    const [verificator, setVerificator] = useState(false)
    const [verificatorId, setVerificatorId] = useState('')

    const [dataPegawai, setDataPegawai] = useState([])
    const getDataPegawai = async () => {
        try {
            const response = await Api.GetDropdownEmployee(localStorage.getItem('hris-token'))
            const data = response.data.data
            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.fullname
            }))
            // console.log(data)
            setDataPegawai(options)
        } catch (error) {
            console.log(error)
        }
    }
    const [dataPegawaiPenempatan, setDataPegawaiPenempatan] = useState([])
    const getDataPegawaiByCompany = async (id) => {
        try {
            const response = await Api.GetDropdownEmployee(localStorage.getItem('hris-token'), id)
            const data = response.data.data
            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.fullname
            }))

            setDataPegawaiPenempatan(options)
        } catch (error) {
            console.log(error)
        }
    }

    // izin
    const [idEmployeeIzin, setIdEmployeeIzin] = useState()

    // Laporan
    const [persetujuan, setPersetujuan] = useState(false)
    const [penilai1Id, setPenilai1Id] = useState("")
    const [penilai2Id, setPenilai2Id] = useState("")

    // Filter
    const [showFilter, setShowFilter] = useState()
    const [totalCount, setTotalCount] = useState('')
    const [name, setName] = useState('')
    const [jobGrade, setJobGrade] = useState('')
    const [jobLevel, setJobLevel] = useState('')
    const [jobPosition, setJobPosition] = useState('')
    const [jobCategory, setJobCategory] = useState('')
    const [unitKerja, setUnitKerja] = useState('')
    const [company, setCompany] = useState('')

    const [dataPenempatan, setDataPenempatan] = useState([])
    const [dataUnitKerja, setDataUnitKerja] = useState([])
    const [dataKelasJabatan, setDataKelasJabatan] = useState([])
    const [dataJenisJabatan, setDataJenisJabatan] = useState([])
    const [dataJenisKepegawaian, setDataJenisKepegawaian] = useState([])
    const [dataJabatan, setDataJabatan] = useState([])
    const GetData = async () => {
        try {
            const res = await Api.GetSubsidiaryDropdown(localStorage.getItem('hris-token'))
            const res2 = await Api.GetUnitKerjaDropdown(localStorage.getItem('hris-token'), company)
            const res3 = await Api.GetJobGradeDropdown(localStorage.getItem('hris-token'))
            const res4 = await Api.GetJobLevelDropdown(localStorage.getItem('hris-token'))
            const res5 = await Api.GetJobCategoryDropdown(localStorage.getItem('hris-token'))
            const res6 = await Api.GetJobPositionDropdown(localStorage.getItem('hris-token'), unitKerja)
            setDataPenempatan(res.data.results.data)
            setDataUnitKerja(res2.data.results)
            setDataKelasJabatan(res3.data.results.data)
            setDataJenisJabatan(res4.data.results.data)
            setDataJenisKepegawaian(res5.data.results.data)
            setDataJabatan(res6.data.results.data)
            // console.log(response, 'employeeo')
        } catch (error) {
            console.log(error)
        }
    }

    // Handle Filter
    const handleFilter = () => {
        setShowFilter(!showFilter)
        setRefresh(true)
    }
    const handleResetFilter = () => {
        setJobGrade("")
        setJobLevel("")
        setJobPosition("")
        setJobCategory("")
        setUnitKerja("")
        setCompany("")
        setRefresh(true)
        setShowFilter(false)
    }

    // Detail
    const getDetail = async (user) => {
        setShowModalEdit(true)
        setUserId(user)
        try {
            await Api.GetApprovalSettingById(localStorage.getItem('hris-token'), user).then((res) => {
                console.log(res.data.results.data)
                const data = res.data.results.data.pengaturan_persetujuan
                // cuti
                setIdEmployee({
                    "value": data?.cuti?.atasanId ?? "",
                    "label": data?.cuti?.atasanName ?? ""
                })
                setIdEmployeeBerwenang({
                    "value": data?.cuti?.pejabatBerwenangId ?? "",
                    "label": data?.cuti?.pejabatBerwenangName ?? ""
                })
                setVerificator(data?.cuti?.verifikatorId ? true : false)
                setVerificatorId({
                    "value": data?.cuti?.verifikatorId ?? "",
                    "label": data?.cuti?.verifikatorName ?? ""
                })

                //izin
                setIdEmployeeIzin({
                    "value": data?.izin?.atasanId ?? "",
                    "label": data?.izin?.atasanName ?? ""
                })

                // laporan
                setPenilai1Id({
                    "value": data?.laporanKinerja?.atasan1Id ?? "",
                    "label": data?.laporanKinerja?.atasan1Name ?? ""
                })
                setPersetujuan(data?.laporanKinerja?.atasan2Id ? true : false)
                setPenilai2Id({
                    "value": data?.laporanKinerja?.atasan2Id ?? "",
                    "label": data?.laporanKinerja?.atasan2Name ?? ""
                })

                setIsOnlyAdminCuti(data?.cuti?.isOnlyAdmin)
                setIsOnlyAdminIzin(data?.izin?.isOnlyAdmin)
                setIsOnlyAdminLaporan(data?.laporanKinerja?.isOnlyAdmin)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const [selectedRows, setSelectedRows] = useState([]);
    const handleSelectRow = (id) => {
        if (selectedRows.includes(id)) {
            setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
        } else {
            setSelectedRows([...selectedRows, id]);
        }
    };

    const isSelected = (id) => selectedRows.includes(id);
    const handleSelectAll = () => {
        if (selectedRows.length === data.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(data.map(item => item.id));
        }
    };

    const calculateActiveFilters = () => {
        let count = 0;
        if (jobGrade) count++;
        if (jobLevel) count++;
        if (jobPosition) count++;
        if (jobCategory) count++;
        if (company) count++;
        if (unitKerja) count++;
        return count;
    };
    useEffect(() => {
        getData()
        setRefresh(false)
    }, [refresh, name])

    useEffect(() => {
        getDataPegawai()
        getDataPegawaiByCompany()
    }, [])

    useEffect(() => {
        GetData()
    }, [company, unitKerja])
    return (
        <>
            <div>
                <Modal
                    activeModal={showModalEdit}
                    title={'Edit Persetujuan'}
                    buttonClose={() => {
                        setShowModalEdit(!showModalEdit);
                        setMenu('cuti')
                        resetForm()
                    }}
                    width={'550px'}
                    content={
                        <div className='space-y-[25px]'>
                            <div className='space-y-[24px]'>
                                <div className='border-b-2 flex justify-start items-center text-[16px]'>
                                    <button onClick={() => setMenu('cuti')} className={`${menu === 'cuti' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px]`}>Cuti</button>
                                    <button onClick={() => setMenu('izin')} className={`${menu === 'izin' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px]`}>Izin</button>
                                    <button onClick={() => setMenu('laporan-kinerja')} className={`${menu === 'laporan-kinerja' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px]`}>Laporan Kinerja</button>
                                </div>

                                {menu === 'cuti' ? (
                                    <>

                                        <div className='flex gap-[19px]'>
                                            <div className='w-full'>
                                                <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Atasan Langsung <span className='text-[#780000]'>*</span></h1>
                                                {localStorage.getItem('hris-role') === 'ADMIN' ?
                                                    <Select
                                                        placeholder="Pilih ..."
                                                        value={idEmployee}
                                                        onChange={selectedOption => setIdEmployee(selectedOption)}
                                                        options={dataPegawai}
                                                    />
                                                    : <Select
                                                        placeholder="Pilih ..."
                                                        value={idEmployee}
                                                        onChange={selectedOption => setIdEmployee(selectedOption)}
                                                        options={dataPegawaiPenempatan}
                                                    />
                                                }
                                            </div>
                                            <div className='w-full'>
                                                <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Pejabat Yang Berwenang <span className='text-[#780000]'>*</span></h1>

                                                {localStorage.getItem('hris-role') === 'ADMIN' ?
                                                    <Select
                                                        placeholder="Pilih ..."
                                                        value={idEmployeeBerwenang}
                                                        onChange={selectedOption => setIdEmployeeBerwenang(selectedOption)}
                                                        options={dataPegawai}
                                                    />
                                                    : <Select
                                                        placeholder="Pilih ..."
                                                        value={idEmployeeBerwenang}
                                                        onChange={selectedOption => setIdEmployeeBerwenang(selectedOption)}
                                                        options={dataPegawaiPenempatan}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className=''>
                                            <label className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    className="form-checkbox h-5 w-5 text-blue-600"
                                                    value={!verificator}
                                                    checked={verificator}
                                                    onChange={(e) => setVerificator(e.target.checked)}
                                                />
                                                <span className="text-[12px] text-dark-5 font-medium">Tambahkan Verifikator</span>
                                            </label>
                                        </div>
                                        {verificator === true ?
                                            <div className='w-full'>
                                                <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Verifikator</h1>
                                                {localStorage.getItem('hris-role') === 'ADMIN' ?
                                                    <Select
                                                        placeholder="Pilih ..."
                                                        value={verificatorId}
                                                        onChange={selectedOption => setVerificatorId(selectedOption)}
                                                        options={dataPegawai}
                                                    />
                                                    : <Select
                                                        placeholder="Pilih ..."
                                                        value={verificatorId}
                                                        onChange={selectedOption => setVerificatorId(selectedOption)}
                                                        options={dataPegawaiPenempatan}
                                                    />
                                                }
                                            </div>
                                            : null
                                        }

                                        <hr className='mb-[21px]' />
                                        <div className='space-y-3'>
                                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Hak akses edit <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                            <div className="space-y-2">
                                                <div className="flex items-center">
                                                    <input
                                                        checked={isOnlyAdminCuti === true}
                                                        onChange={(e) => setIsOnlyAdminCuti(e.target.value === "true")}
                                                        id="default-radio-1"
                                                        type="radio"
                                                        value="true"
                                                        className="w-4 h-4 bg-gray-100 border-gray-300"
                                                    />
                                                    <label
                                                        htmlFor="default-radio-1"
                                                        className="ml-[21.13px] text-grey-thick text-[12px] font-medium"
                                                    >
                                                        Hanya Admin
                                                    </label>
                                                </div>
                                                <div className="flex items-center">
                                                    <input
                                                        checked={isOnlyAdminCuti === false}
                                                        onChange={(e) => setIsOnlyAdminCuti(e.target.value === "true")}
                                                        id="default-radio-2"
                                                        type="radio"
                                                        value="false"
                                                        className="w-4 h-4 bg-gray-100 border-gray-300"
                                                    />
                                                    <label
                                                        htmlFor="default-radio-2"
                                                        className="ml-[21.13px] text-grey-thick text-[12px] font-medium"
                                                    >
                                                        Admin dan Pegawai
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : menu === 'izin' ? (
                                    <>
                                        <div>
                                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>{languages.language === 'id' ? "Penandatanganan" : "Approval"} <span className='text-[#780000]'>*</span></h1>
                                            {localStorage.getItem('hris-role') === 'ADMIN' ?
                                                <Select
                                                    placeholder="Pilih ..."
                                                    value={idEmployeeIzin}
                                                    onChange={selectedOption => setIdEmployeeIzin(selectedOption)}
                                                    options={dataPegawai}
                                                />
                                                : <Select
                                                    placeholder="Pilih ..."
                                                    value={idEmployeeIzin}
                                                    onChange={selectedOption => setIdEmployeeIzin(selectedOption)}
                                                    options={dataPegawaiPenempatan}
                                                />
                                            }
                                        </div>

                                        <hr className='mb-[21px]' />
                                        <div className='space-y-3'>
                                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Hak akses edit <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                            <div className="space-y-2">
                                                <div className="flex items-center">
                                                    <input
                                                        checked={isOnlyAdminIzin === true}
                                                        onChange={(e) => setIsOnlyAdminIzin(e.target.value === "true")}
                                                        id="default-radio-3"
                                                        type="radio"
                                                        value="true"
                                                        className="w-4 h-4 bg-gray-100 border-gray-300"
                                                    />
                                                    <label
                                                        htmlFor="default-radio-3"
                                                        className="ml-[21.13px] text-grey-thick text-[12px] font-medium"
                                                    >
                                                        Hanya Admin
                                                    </label>
                                                </div>
                                                <div className="flex items-center">
                                                    <input
                                                        checked={isOnlyAdminIzin === false}
                                                        onChange={(e) => setIsOnlyAdminIzin(e.target.value === "true")}
                                                        id="default-radio-4"
                                                        type="radio"
                                                        value="false"
                                                        className="w-4 h-4 
                                                        bg-gray-100 border-gray-300"
                                                    />
                                                    <label
                                                        htmlFor="default-radio-4"
                                                        className="ml-[21.13px] text-grey-thick text-[12px] font-medium"
                                                    >
                                                        Admin dan Pegawai
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : menu === 'laporan-kinerja' ? (
                                    <>
                                        <div className='w-full space-y-3'>
                                            <div className='w-full space-y-1'>
                                                <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Pejabat Penilai Kinerja <span className='text-red-primer'>*</span></h1>
                                                <Select
                                                    placeholder="Pilih ..."
                                                    value={penilai1Id}
                                                    onChange={selectedOption => setPenilai1Id(selectedOption)}
                                                    options={dataPegawai}
                                                />
                                            </div>
                                            <div className=''>
                                                <label className="flex items-center space-x-2">
                                                    <input
                                                        type="checkbox"
                                                        className="form-checkbox h-5 w-5 text-blue-600"
                                                        value={!persetujuan}
                                                        checked={persetujuan}
                                                        onChange={(e) => { setPersetujuan(e.target.checked); setPenilai2Id(null) }}
                                                    />
                                                    <span className="text-[12px] text-dark-5 font-medium">Tambahkan Persetujuan</span>
                                                </label>
                                            </div>
                                            {persetujuan ?
                                                <div className='w-full space-y-1'>
                                                    <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Persetujuan <span className='text-red-primer'>*</span></h1>
                                                    <Select
                                                        placeholder="Pilih ..."
                                                        value={penilai2Id}
                                                        onChange={selectedOption => setPenilai2Id(selectedOption)}
                                                        options={dataPegawai}
                                                    />
                                                </div>
                                                : null
                                            }
                                        </div>

                                        <hr className='mb-[21px]' />
                                        <div className='space-y-3'>
                                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Hak akses edit <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                            <div className="space-y-2">
                                                <div className="flex items-center">
                                                    <input
                                                        checked={isOnlyAdminLaporan === true}
                                                        onChange={(e) => setIsOnlyAdminLaporan(e.target.value === "true")}
                                                        id="default-radio-5"
                                                        type="radio"
                                                        value="true"
                                                        className="w-4 h-4 bg-gray-100 border-gray-300"
                                                    />
                                                    <label
                                                        htmlFor="default-radio-5"
                                                        className="ml-[21.13px] text-grey-thick text-[12px] font-medium"
                                                    >
                                                        Hanya Admin
                                                    </label>
                                                </div>
                                                <div className="flex items-center">
                                                    <input
                                                        checked={isOnlyAdminLaporan === false}
                                                        onChange={(e) => setIsOnlyAdminLaporan(e.target.value === "true")}
                                                        id="default-radio-6"
                                                        type="radio"
                                                        value="false"
                                                        className="w-4 h-4 bg-gray-100 border-gray-300"
                                                    />
                                                    <label
                                                        htmlFor="default-radio-6"
                                                        className="ml-[21.13px] text-grey-thick text-[12px] font-medium"
                                                    >
                                                        Admin dan Pegawai
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <button onClick={() => {
                                    setShowModalEdit(!showModalEdit);
                                    setMenu('cuti')
                                    resetForm()
                                }} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Batal" : "Cancel"}</button>
                                <button onClick={() => updateData(false)} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Simpan" : "Submit"}</button>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showModalEditMassal}
                    title={'Edit Persetujuan Massal'}
                    buttonClose={() => {
                        setShowModalEditMassal(!showModalEditMassal);
                        setMenu('cuti');
                        resetForm()
                    }}
                    width={'550px'}
                    content={
                        <div className='space-y-[25px]'>
                            <div className='space-y-[24px]'>
                                <div className='border-b-2 flex justify-start items-center text-[16px]'>
                                    <button onClick={() => setMenu('cuti')} className={`${menu === 'cuti' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px]`}>Cuti</button>
                                    <button onClick={() => setMenu('izin')} className={`${menu === 'izin' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px]`}>Izin</button>
                                    <button onClick={() => setMenu('laporan-kinerja')} className={`${menu === 'laporan-kinerja' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px]`}>Laporan Kinerja</button>
                                </div>

                                {menu === 'cuti' ? (
                                    <>

                                        <div className='flex gap-[19px]'>
                                            <div className='w-full'>
                                                <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Atasan Langsung <span className='text-[#780000]'>*</span></h1>
                                                {localStorage.getItem('hris-role') === 'ADMIN' ?
                                                    <Select
                                                        placeholder="Pilih ..."
                                                        value={idEmployee}
                                                        onChange={selectedOption => setIdEmployee(selectedOption)}
                                                        options={dataPegawai}
                                                    />
                                                    : <Select
                                                        placeholder="Pilih ..."
                                                        value={idEmployee}
                                                        onChange={selectedOption => setIdEmployee(selectedOption)}
                                                        options={dataPegawaiPenempatan}
                                                    />
                                                }
                                            </div>
                                            <div className='w-full'>
                                                <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Pejabat Yang Berwenang <span className='text-[#780000]'>*</span></h1>

                                                {localStorage.getItem('hris-role') === 'ADMIN' ?
                                                    <Select
                                                        placeholder="Pilih ..."
                                                        value={idEmployeeBerwenang}
                                                        onChange={selectedOption => setIdEmployeeBerwenang(selectedOption)}
                                                        options={dataPegawai}
                                                    />
                                                    : <Select
                                                        placeholder="Pilih ..."
                                                        value={idEmployeeBerwenang}
                                                        onChange={selectedOption => setIdEmployeeBerwenang(selectedOption)}
                                                        options={dataPegawaiPenempatan}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className=''>
                                            <label className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    className="form-checkbox h-5 w-5 text-blue-600"
                                                    value={!verificator}
                                                    checked={verificator}
                                                    onChange={(e) => setVerificator(e.target.checked)}
                                                />
                                                <span className="text-[12px] text-dark-5 font-medium">Tambahkan Verifikator</span>
                                            </label>
                                        </div>
                                        {verificator === true ?
                                            <div className='w-full'>
                                                <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Verifikator</h1>
                                                {localStorage.getItem('hris-role') === 'ADMIN' ?
                                                    <Select
                                                        placeholder="Pilih ..."
                                                        value={verificatorId}
                                                        onChange={selectedOption => setVerificatorId(selectedOption)}
                                                        options={dataPegawai}
                                                    />
                                                    : <Select
                                                        placeholder="Pilih ..."
                                                        value={verificatorId}
                                                        onChange={selectedOption => setVerificatorId(selectedOption)}
                                                        options={dataPegawaiPenempatan}
                                                    />
                                                }
                                            </div>
                                            : null
                                        }

                                        <hr className='mb-[21px]' />
                                        <div className='space-y-3'>
                                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Hak akses edit <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                            <div className="space-y-2">
                                                <div className="flex items-center">
                                                    <input
                                                        checked={isOnlyAdminCuti === true}
                                                        onChange={(e) => setIsOnlyAdminCuti(e.target.value === "true")}
                                                        id="default-radio-7"
                                                        type="radio"
                                                        value="true"
                                                        className="w-4 h-4 bg-gray-100 border-gray-300"
                                                    />
                                                    <label
                                                        htmlFor="default-radio-7"
                                                        className="ml-[21.13px] text-grey-thick text-[12px] font-medium"
                                                    >
                                                        Hanya Admin
                                                    </label>
                                                </div>
                                                <div className="flex items-center">
                                                    <input
                                                        checked={isOnlyAdminCuti === false}
                                                        onChange={(e) => setIsOnlyAdminCuti(e.target.value === "true")}
                                                        id="default-radio-8"
                                                        type="radio"
                                                        value="false"
                                                        className="w-4 h-4 bg-gray-100 border-gray-300"
                                                    />
                                                    <label
                                                        htmlFor="default-radio-8"
                                                        className="ml-[21.13px] text-grey-thick text-[12px] font-medium"
                                                    >
                                                        Admin dan Pegawai
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : menu === 'izin' ? (
                                    <>
                                        <div>
                                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>{languages.language === 'id' ? "Penandatanganan" : "Approval"} <span className='text-[#780000]'>*</span></h1>
                                            {localStorage.getItem('hris-role') === 'ADMIN' ?
                                                <Select
                                                    placeholder="Pilih ..."
                                                    value={idEmployeeIzin}
                                                    onChange={selectedOption => setIdEmployeeIzin(selectedOption)}
                                                    options={dataPegawai}
                                                />
                                                : <Select
                                                    placeholder="Pilih ..."
                                                    value={idEmployeeIzin}
                                                    onChange={selectedOption => setIdEmployeeIzin(selectedOption)}
                                                    options={dataPegawaiPenempatan}
                                                />
                                            }
                                        </div>

                                        <hr className='mb-[21px]' />
                                        <div className='space-y-3'>
                                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Hak akses edit <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                            <div className="space-y-2">
                                                <div className="flex items-center">
                                                    <input
                                                        checked={isOnlyAdminIzin === true}
                                                        onChange={(e) => setIsOnlyAdminIzin(e.target.value === "true")}
                                                        id="default-radio-9"
                                                        type="radio"
                                                        value="true"
                                                        className="w-4 h-4 bg-gray-100 border-gray-300"
                                                    />
                                                    <label
                                                        htmlFor="default-radio-9"
                                                        className="ml-[21.13px] text-grey-thick text-[12px] font-medium"
                                                    >
                                                        Hanya Admin
                                                    </label>
                                                </div>
                                                <div className="flex items-center">
                                                    <input
                                                        checked={isOnlyAdminIzin === false}
                                                        onChange={(e) => setIsOnlyAdminIzin(e.target.value === "true")}
                                                        id="default-radio-10"
                                                        type="radio"
                                                        value="false"
                                                        className="w-4 h-4 bg-gray-100 border-gray-300"
                                                    />
                                                    <label
                                                        htmlFor="default-radio-10"
                                                        className="ml-[21.13px] text-grey-thick text-[12px] font-medium"
                                                    >
                                                        Admin dan Pegawai
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : menu === 'laporan-kinerja' ? (
                                    <>
                                        <div className='w-full space-y-3'>
                                            <div className='w-full space-y-1'>
                                                <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Pejabat Penilai Kinerja <span className='text-red-primer'>*</span></h1>
                                                <Select
                                                    placeholder="Pilih ..."
                                                    value={penilai1Id}
                                                    onChange={selectedOption => setPenilai1Id(selectedOption)}
                                                    options={dataPegawai}
                                                />
                                            </div>
                                            <div className=''>
                                                <label className="flex items-center space-x-2">
                                                    <input
                                                        type="checkbox"
                                                        className="form-checkbox h-5 w-5 text-blue-600"
                                                        value={!persetujuan}
                                                        checked={persetujuan}
                                                        onChange={(e) => { setPersetujuan(e.target.checked); setPenilai2Id(null) }}
                                                    />
                                                    <span className="text-[12px] text-dark-5 font-medium">Tambahkan Persetujuan</span>
                                                </label>
                                            </div>
                                            {persetujuan ?
                                                <div className='w-full space-y-1'>
                                                    <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Persetujuan <span className='text-red-primer'>*</span></h1>
                                                    <Select
                                                        placeholder="Pilih ..."
                                                        value={penilai2Id}
                                                        onChange={selectedOption => setPenilai2Id(selectedOption)}
                                                        options={dataPegawai}
                                                    />
                                                </div>
                                                : null
                                            }
                                        </div>

                                        <hr className='mb-[21px]' />
                                        <div className='space-y-3'>
                                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Hak akses edit <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                            <div className="space-y-2">
                                                <div className="flex items-center">
                                                    <input
                                                        checked={isOnlyAdminLaporan === true}
                                                        onChange={(e) => setIsOnlyAdminLaporan(e.target.value === "true")}
                                                        id="default-radio-11"
                                                        type="radio"
                                                        value="true"
                                                        className="w-4 h-4 bg-gray-100 border-gray-300"
                                                    />
                                                    <label
                                                        htmlFor="default-radio-11"
                                                        className="ml-[21.13px] text-grey-thick text-[12px] font-medium"
                                                    >
                                                        Hanya Admin
                                                    </label>
                                                </div>
                                                <div className="flex items-center">
                                                    <input
                                                        checked={isOnlyAdminLaporan === false}
                                                        onChange={(e) => setIsOnlyAdminLaporan(e.target.value === "true")}
                                                        id="default-radio-12"
                                                        type="radio"
                                                        value="false"
                                                        className="w-4 h-4 bg-gray-100 border-gray-300"
                                                    />
                                                    <label
                                                        htmlFor="default-radio-12"
                                                        className="ml-[21.13px] text-grey-thick text-[12px] font-medium"
                                                    >
                                                        Admin dan Pegawai
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <button onClick={() => {
                                    setShowModalEditMassal(!showModalEditMassal);
                                    setMenu('cuti'); resetForm()
                                    resetForm()
                                }} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Batal" : "Cancel"}</button>
                                <button onClick={() => updateData(true)} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Simpan" : "Submit"}</button>
                            </div>
                        </div>
                    }
                />

                <Modal
                    activeModal={showFilter}
                    buttonClose={() => setShowFilter(!showFilter)}
                    width={'550px'}
                    title={
                        <div className='flex gap-[27px] items-center'>
                            <BsFilter className='text-dark-1 text-2xl' />
                            <h1 className='text-dark-1 text-[20px] font-semibold'>Filter</h1>
                        </div>
                    }
                    content={
                        <div className='space-y-[20px]'>
                            <div className='grid grid-cols-2 gap-3'>
                                <div className='relative'>
                                    <select onChange={(e) => setCompany(e.target.value)} value={company} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                        <option value={""} selected className='text-grey-medium font-normal'>Pilih Penempatan</option>
                                        {dataPenempatan && dataPenempatan.map((val, index) => (
                                            <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.company_name}</option>
                                        ))}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                                </div>
                                <div className='relative'>
                                    <select onChange={(e) => setUnitKerja(e.target.value)} value={unitKerja} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                        <option value={""} selected className='text-grey-medium font-normal'>Pilih Unit Kerja</option>
                                        {dataUnitKerja && dataUnitKerja.map((val, index) => (
                                            <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.name}</option>
                                        ))}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                                </div>
                                <div className='relative'>
                                    <select onChange={(e) => setJobGrade(e.target.value)} value={jobGrade} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                        <option value={""} selected className='text-grey-medium font-normal'>Pilih Kelas Jabatan</option>
                                        {dataKelasJabatan && dataKelasJabatan.map((val, index) => (
                                            <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.job_grade}</option>
                                        ))}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                                </div>
                                <div className='relative'>
                                    <select onChange={(e) => setJobLevel(e.target.value)} value={jobLevel} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                        <option value={""} selected className='text-grey-medium font-normal'>Pilih Jenis Jabatan</option>
                                        {dataJenisJabatan && dataJenisJabatan.map((val, index) => (
                                            <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.level_name}</option>
                                        ))}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                                </div>
                                <div className='relative'>
                                    <select onChange={(e) => setJobCategory(e.target.value)} value={jobCategory} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                        <option value={""} selected className='text-grey-medium font-normal'>Pilih Jenis Kepegawaian</option>
                                        {dataJenisKepegawaian && dataJenisKepegawaian.map((val, index) => (
                                            <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.category_name}</option>
                                        ))}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                                </div>
                                <div className='relative'>
                                    <select onChange={(e) => setJobPosition(e.target.value)} value={jobPosition} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                        <option value={""} selected className='text-grey-medium font-normal'>Pilih Jabatan</option>
                                        {dataJabatan && dataJabatan.map((val, index) => (
                                            <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.position_name}</option>
                                        ))}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                                </div>
                            </div>
                            <div className='flex items-center justify-between'>
                                <button onClick={handleResetFilter} className='py-[10px] px-[15px] text-[#0E5073] text-[14px] font-medium flex justify-center rounded-md items-center border'>Reset Filter</button>
                                <button onClick={handleFilter} className='py-[10px] px-[15px] bg-[#0E5073] text-white text-[14px] font-medium flex justify-center rounded-md items-center'>Filter</button>
                            </div>
                        </div>
                    }
                />
            </div>
            <div className='w-full space-y-[24px] overflow-hidde'>
                <Navbar SubNavbar={true} NameSubNavbar="Pengaturan Persetujuan" LinkBack={'/dashboard'} />
                <div className='bg-white border shadow-sm rounded-[12px] px-[22px] py-[36px] space-y-5'>
                    <div className='flex flex-col lg:flex-row items-center justify-between'>
                        <div className='w-full lg:w-auto'>
                            <h1 className='text-dark-3 text-[16px] font-[500]'>Pengaturan Persetujuan</h1>
                            <h1 className='text-[#A8A8A8] text-xs font-[500]'>Daftar Persetujuan</h1>
                        </div>
                    </div>
                    <div className='flex gap-2 sm:justify-start md:justify-between flex-col lg:flex-row lg:items-center'>
                        <div className='flex items-center gap-1 mt-3 lg:mt-0 w-fit lg:w-auto'>
                            <button onClick={() => setShowFilter(!showFilter)} className='relative bg-white border border-dark-7 rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                <BiFilter className='text-dark-5 text-2xl' />
                                Filter
                                {calculateActiveFilters() > 0 ?
                                    <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2">{calculateActiveFilters()}</div>
                                    : null
                                }
                            </button>
                            {selectedRows.length > 0 ?
                                <button onClick={() => setShowModalEditMassal(!showModalEditMassal)} className='bg-white border lg:w-fit w-full rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                    <HiOutlinePencil className='text-[#0E5073]' />
                                    <h1 className='text-[#0E5073] text-sm font-[500]'>Edit</h1>
                                </button>
                                : null
                            }
                        </div>
                        <div className='relative w-fit'>
                            <AiOutlineSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg' />
                            <input onChange={(e) => setName(e.target.value)} placeholder='Cari Nama Pegawai...' className='text-[#A8A8A8] text-sm font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]' />
                        </div>
                        {/* <button onClick={ () => setShowModalAdd(!showModalAdd)} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                <AiOutlinePlus className='text-xl text-white'/>
                                <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ?"Tambah Pengguna":"Add User"}</h1>
                            </button> */}
                    </div>
                    <div className='overflow-auto'>
                        <table className='w-full space-y-[10px]'>
                            <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                    <input
                                        type="checkbox"
                                        checked={selectedRows.length === data.length}
                                        onChange={handleSelectAll}
                                        className="form-checkbox"
                                    />
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[50px] max-w-[50px]'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>No</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>{languages.language === 'id' ? "Nama Pegawai" : "Employee Name"}</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>Persetujuan Cuti</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>Persetujuan Izin</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>Persetujuan Laporan Kinerja</h1>
                                </div>
                                <div className='flex items-center justify-center gap-[15px] w-full'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                                </div>
                            </div>
                            {data.length === 0 ?
                                <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='min-w-[50px] max-w-[50px]'>
                                        <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>-</h1>
                                    </div>
                                    <div className='min-w-[50px] max-w-[50px]'>
                                        <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>-</h1>
                                    </div>
                                    <div className='min-w-[250px] max-w-[250px]'>
                                        <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>-</h1>
                                    </div>
                                    <div className='min-w-[100px] max-w-[100px]'>
                                        <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>-</h1>
                                    </div>
                                    <div className='min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>-</h1>
                                    </div>
                                    <div className='min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>-</h1>
                                    </div>
                                    <div className='w-full flex items-center justify-center gap-[5px]'>
                                        <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>-</h1>
                                    </div>
                                </div>
                                :
                                Object.values(data).map((data, index) => {
                                    return (
                                        <div key={index} className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='min-w-[100px] max-w-[100px]'>
                                                <input
                                                    type="checkbox"
                                                    checked={isSelected(data.id)}
                                                    onChange={() => handleSelectRow(data.id)}
                                                    className="form-checkbox"
                                                />
                                            </div>
                                            <div className='min-w-[50px] max-w-[50px]'>
                                                <h1 className='text-xs line-clamp-1'>{index + 1}</h1>
                                            </div>
                                            <div className='min-w-[250px] max-w-[250px]'>
                                                <h1 className='text-xs line-clamp-1'>{data?.fullname}</h1>
                                            </div>
                                            <div className='min-w-[250px] max-w-[250px]'>
                                                {data?.pengaturan_persetujuan?.cuti ?
                                                    <ul className='space-y-2'>
                                                        <li>
                                                            <p className='text-xs'>1. {data?.pengaturan_persetujuan?.cuti?.atasanName}</p>
                                                        </li>
                                                        <li>
                                                            <p className='text-xs'>2. {data?.pengaturan_persetujuan?.cuti?.pejabatBerwenangName}</p>
                                                        </li>
                                                        {data?.pengaturan_persetujuan?.cuti?.verifikatorId ?
                                                            <li>
                                                                <p className='text-xs'>3. {data?.pengaturan_persetujuan?.cuti?.verifikatorName}</p>
                                                            </li>
                                                            : null
                                                        }

                                                    </ul>
                                                    : <p>-</p>
                                                }
                                            </div>
                                            <div className='min-w-[250px] max-w-[250px]'>
                                                {data?.pengaturan_persetujuan?.izin ?
                                                    <ul className='space-y-2'>
                                                        <li>
                                                            <p className='text-xs'>{data?.pengaturan_persetujuan?.izin?.atasanName}</p>
                                                        </li>
                                                    </ul>
                                                    : <p>-</p>
                                                }
                                            </div>
                                            <div className='min-w-[250px] max-w-[250px]'>
                                                {data?.pengaturan_persetujuan?.laporanKinerja ?
                                                    <ul className='space-y-2'>
                                                        <li>
                                                            <p className='text-xs'>1. {data?.pengaturan_persetujuan?.laporanKinerja?.atasan1Name}</p>
                                                        </li>
                                                        {data?.pengaturan_persetujuan?.laporanKinerja?.atasan2Name ?
                                                            <li>
                                                                <p className='text-xs'>2. {data?.pengaturan_persetujuan?.laporanKinerja?.atasan2Name}</p>
                                                            </li>
                                                            : null
                                                        }
                                                    </ul>
                                                    : <p>-</p>
                                                }
                                            </div>
                                            <div className='w-full flex items-center justify-center gap-[5px]'>
                                                <button
                                                    onClick={() => {
                                                        // getDataById(data.id)
                                                        getDetail(data?.id)
                                                    }}
                                                    className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[#003049]' />
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </table>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        lengthData={totalCount}
                        limitData={limit}
                        onPageChange={handlePageChange}
                        onPrevChange={handlePrevChange}
                        onNextChange={handleNextChange}
                    />
                </div>
            </div>
        </>
    )
}

export default Pelaporan