import React, { useEffect, useState } from 'react'
import { Modal, ModalDelete, Navbar, Pagination } from '../../../../components'
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'
import Api from '../../../../Api'
import { HiOutlinePencil } from 'react-icons/hi'
import InputComponent from "../../../../components/Input/inputComponent";
import Select from 'react-select'
import { selectStyle } from '../../../../utils/styles/select'
import toast from 'react-hot-toast'
import SearchComponent from '../../../../components/Input/searchComponent'
import { CgTrashEmpty } from 'react-icons/cg'

const TugasStrukturalAsisten = () => {
    const [showAdd, setShowAdd] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [data, setData] = useState([])
    const limit = 10

    const [search, setSearch] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState(1)
    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const getTugasStruktural = async () => {
        try {
            await Api.GetTugasStruktural(localStorage.getItem('hris-token'), currentPage, limit, search).then((response) => {
                setData(response.data.results.data)
                setCurrentPage(response.data.results.currentPage)
                setTotalPages(response.data.results.totalPages)
                setTotalCount(response.data.results.totalCount)
            })
        } catch (error) {

        }
    }
    const getDataById = async (data) => {
        setShowEdit(true)
        setId(data)
        try {
            await Api.GetTugasStrukturalById(localStorage.getItem('hris-token'), data).then((response) => {
                console.log(response.data.results.data)
                const data = response.data.results.data
                setSelected({
                    "value": data.unitKerja,
                    "label": data.unitKerja
                })
                for (let i = 0; i < data.name.length; i++) {
                    setName(prevName => [...prevName, { id: i, value: data.name[i] }]);
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const [name, setName] = useState([])
    const [dataUnitKerja, setDataUnitKerja] = useState()
    const [selected, setSelected] = useState([])
    const getUnitKerja = async () => {
        try {
            await Api.GetUnitKerjaUnique(localStorage.getItem('hris-token')).then((response) => {
                const data = response.data.results.data
                const options = data && data.map(val => ({
                    "value": val.name,
                    "label": val.name
                }))
                setDataUnitKerja(options)
            })
        } catch (error) {

        }
    }

    const handleCahnge = (selectedOption) => {
        setSelected(selectedOption)
    }

    const resetForm = () => {
        setName([])
        setSelected("")
    }

    const PostData = async () => {
        try {
            let data = {
                "unitKerja": selected.value,
                "name": []
            }
            for (const i in name) {
                data["name"].push(name[i].value);
            }
            await Api.PostTugasStruktural(localStorage.getItem('hris-token'), data).then((response) => {
                setRefresh(true)
                resetForm()
                toast.success("Berhasil Menambahkan Data!")
                setShowAdd(false)
            }).catch((e) => {
                console.log(e)
                toast.error(e?.response?.data?.message)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const UpdateData = async () => {
        try {

            let data = {
                "unitKerja": selected.value,
                "name": []
            }
            for (const i in name) {
                data["name"].push(name[i].value);
            }
            await Api.UpdateTugasStruktural(localStorage.getItem('hris-token'), data, id).then((response) => {
                setRefresh(true)
                resetForm()
                toast.success("Berhasil Mengubah Data!")
                setShowEdit(false)
            }).catch((e) => {
                console.log(e)
                toast.error(e?.response?.data?.message)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const [id, setId] = useState("")
    const [showModalDelete, setShowModalDelete] = useState(false)

    const deleteModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setId(id)
        setRefresh(true)
    }

    const deleteTugas = async () => {
        try {
            await Api.DeleteTugasStruktural(localStorage.getItem('hris-token'), id).then((response) => {
                setRefresh(true)
                setShowModalDelete(false)
                toast.success('Hapus Data Berhasil!')
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
            toast.error('Data Gagal dihapus!')
        }
    }
    const [Current, setCurrent] = useState(0)
    const [Active, setActive] = useState(false)
    const handleCollapse = (id) => {
        if (id) {
            setCurrent(id)
            setActive(!Active)
        }
    }

    const handleSearch = (e) => {
        setSearch(e)
    }

    const handleAddRepeter = () => {
        const newId = name?.length + 1;
        setName([...name, { id: newId, value: '' }]);
    };

    const handleRemoveRepeter = (nameId) => {
        const updatedRepeter = name.filter((name) => name.id !== nameId);
        setName(updatedRepeter);
    };

    const handleChangeRepeter = (itemId, selectedValue) => {
        const updatedRepeter = name.map((item) =>
            item.id === itemId ? { ...item, value: selectedValue } : item
        );
        setName(updatedRepeter);
    };

    useEffect(() => {
        getUnitKerja()
    }, [])
    useEffect(() => {
        getTugasStruktural()
        setRefresh(false)
    }, [refresh, search])
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='relative'>
                <Modal
                    activeModal={showAdd}
                    title={"Tambah Tugas Struktural"}
                    buttonClose={() => { setShowAdd(false); resetForm() }}
                    width={'832px'}
                    content={
                        <div className='space-y-[24px] w-[full]'>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Unit Kerja <span className='text-[#780000]'>*</span></h1>
                                <Select
                                    value={selected}
                                    styles={selectStyle}
                                    onChange={data => handleCahnge(data)}
                                    options={dataUnitKerja}
                                    placeholder="Pilih unit kerja"
                                />
                            </div>
                            <div className='space-y-3'>
                                {name.map((val, index) => (
                                    <div key={index} className='flex justify-between items-end gap-3'>
                                        <div className='w-full'>
                                            <InputComponent
                                                disabled={false}
                                                label={"Tugas Jabatan"}
                                                value={val.value}
                                                onChange={(e) => handleChangeRepeter(val.id, e)}
                                                required={true}
                                                type={"text"}
                                                placeholder={"Masukkan nama tugas jabatan...."}
                                            />
                                        </div>
                                        <button onClick={() => handleRemoveRepeter(val.id)} className='px-[12px] py-[9px] bg-[#FFE1E1] rounded-[6px] flex items-center justify-center'>
                                            <CgTrashEmpty className='text-[#780000] text-xl' />
                                        </button>
                                    </div>
                                ))}
                                <button onClick={handleAddRepeter} className='bg-blue-thin text-blue-thick py-2 px-4 rounded-[10px] flex items-center gap-2'>
                                    <AiOutlinePlus className='text-lg' />
                                    <h1 className='text-sm font-[500]'>Tambah Tugas Jabatan</h1>
                                </button>
                            </div>
                            <div className="flex justify-end gap-[10px]">
                                <button onClick={() => { setShowAdd(false); resetForm() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={PostData} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEdit}
                    title={"Edit Tugas Struktural"}
                    buttonClose={() => { setShowEdit(false); resetForm() }}
                    width={'832px'}
                    content={
                        <div className='space-y-[24px] w-[full]'>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Unit Kerja <span className='text-[#780000]'>*</span></h1>
                                <Select
                                    value={selected}
                                    styles={selectStyle}
                                    onChange={data => handleCahnge(data)}
                                    options={dataUnitKerja}
                                    placeholder="Pilih unit kerja"
                                />
                            </div>
                            <div className='space-y-3'>
                                {name.map((val, index) => (
                                    <div key={index} className='flex justify-between items-end gap-3'>
                                        <div className='w-full'>
                                            <InputComponent
                                                disabled={false}
                                                label={"Tugas Jabatan"}
                                                value={val.value}
                                                onChange={(e) => handleChangeRepeter(val.id, e)}
                                                required={true}
                                                type={"text"}
                                                placeholder={"Masukkan nama tugas jabatan...."}
                                            />
                                        </div>
                                        <button onClick={() => handleRemoveRepeter(val.id)} className='px-[12px] py-[9px] bg-[#FFE1E1] rounded-[6px] flex items-center justify-center'>
                                            <CgTrashEmpty className='text-[#780000] text-xl' />
                                        </button>
                                    </div>
                                ))}
                                <button onClick={handleAddRepeter} className='bg-blue-thin text-blue-thick py-2 px-4 rounded-[10px] flex items-center gap-2'>
                                    <AiOutlinePlus className='text-lg' />
                                    <h1 className='text-sm font-[500]'>Tambah Tugas Jabatan</h1>
                                </button>
                            </div>
                            <div className="flex justify-end gap-[10px]">
                                <button onClick={() => { setShowEdit(false); resetForm() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={UpdateData} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <ModalDelete
                    activeModal={showModalDelete}
                    buttonClose={() => setShowModalDelete(!showModalDelete)}
                    submitButton={deleteTugas}
                />
            </div>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Pengaturan Tugas Struktural Asisten'} LinkBack={'/performance-setting'} />
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='space-y-5 overflow-auto'>
                        <div className='flex justify-end gap-2'>
                            <SearchComponent onChange={handleSearch} width={200} textSize={12} placeholder={"Cari Tugas Jabatan..."} />
                            <button onClick={() => setShowAdd(true)} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                <AiOutlinePlus className='text-xl text-white' />
                                <h1 className='text-dark-9 text-sm font-[500]'>Tambah Tugas Struktural</h1>
                            </button>
                        </div>
                        <table className='min-w-full'>
                            <thead>
                                <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                                    <th className='border py-3 px-5'>No.</th>
                                    <th className='border py-3 px-5'>Unit Kerja</th>
                                    <th className='border py-3 px-5'>Tugas Jabatan</th>
                                    <th className='border py-3 px-5'>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.map((val, index) => (
                                    <tr key={index} className='border'>
                                        <td className='border py-3 px-5 text-grey-thick text-xs font-medium text-center'>{index + 1}</td>
                                        <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.unitKerja}</td>
                                        <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>
                                            <ul className='space-y-2 list-decimal text-[10px]'>
                                                {
                                                    val?.name.slice(0, val.id === Current && !Active ? val?.name.length : 3).map((v, i) => (
                                                        <li>{v}</li>
                                                    ))
                                                }
                                                {val?.name.length > 3 ? (
                                                    val.id === Current && !Active ? (
                                                        <button
                                                            onClick={() => handleCollapse(val.id)}
                                                            className="text-blue-600 hover:text-blue-800 underline"
                                                        >Lebih sedikit...</button>
                                                    ) : (
                                                        <button
                                                            onClick={() => handleCollapse(val.id)}
                                                            className="text-blue-600 hover:text-blue-800 underline"
                                                        >Lebih Banyak...</button>
                                                    )
                                                ) : null
                                                }
                                            </ul>
                                        </td>
                                        <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>
                                            <div className='flex justify-center items-center gap-2'>
                                                <button
                                                    onClick={() => getDataById(val.id)}
                                                    className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-blue-thick' />
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        deleteModal(val?.id);
                                                    }}
                                                    className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                    <AiOutlineDelete className='text-sm text-blue-thick' />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            lengthData={totalCount}
                            limitData={limit}
                            onPageChange={handlePageChange}
                            onPrevChange={handlePrevChange}
                            onNextChange={handleNextChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TugasStrukturalAsisten