import React, { useEffect, useState } from 'react'
import { DCardEmploye, Modal } from '../../../components'
import { IconDCardDashboard, IconMarker, LogoH } from '../../../assets'
import { BiRefresh } from 'react-icons/bi'
import { AiFillClockCircle } from 'react-icons/ai'
import moment, { tz } from 'moment'
import Api from '../../../Api'
import toast from 'react-hot-toast'
import { Circle, MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import L from "leaflet"
import languages from '../../../utils/languages'

const CardPegawai = ({ isWFH }) => {
    const tz = moment.tz.guess(true);
    const [refresh, setRefresh] = useState(false)
    const today = moment().format('YYYY-MM-DD');
    const [sync, setSync] = useState(false)
    const [dateSync, setDateSync] = useState(moment().format("YYYY-MM-DD"))
    const getSyncAttendence = async () => {
        setSync(true)
        try {
            await Api.GetSyncAttendance(localStorage.getItem('hris-token'), dateSync).then((res) => {
                setSync(false)
                toast.success("Sinkron Data Berhasil!")
                getDataPresensi()
            }).catch((err) => {
                setSync(false)
                toast.error(err?.response?.data?.message)
                console.log(err)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const [geodata, setGeoData] = useState([])
    const getGeoData = async () => {
        try {
            const response = await Api.GetGeoLocationByUser(localStorage.getItem("hris-token"))
            setGeoData(response.data.results.data)
        } catch (error) {
            console.log(error)
        }
    }
    const [dataPresensi, setDataPresensi] = useState([])
    const getDataPresensi = async () => {
        try {
            const response = await Api.GetMyAttendanceRecord(localStorage.getItem('hris-token'))
            setDataPresensi(response.data.attendances[0] ? response.data.attendances[0] : [])
        } catch (error) {
            console.log(error)
        }
    }


    const [lat, setLat] = useState()
    const [long, setLong] = useState()

    // Fungsi isInRadius
    function isInRadius(lat1, lon1, lat2, lon2, radiusInMeters) {
        // Konversi derajat ke radian
        const toRadians = degree => degree * (Math.PI / 180);

        // Menghitung jarak Haversine
        const haversine = (a, b) => {
            const dLat = toRadians(b.lat - a.lat);
            const dLon = toRadians(b.lon - a.lon);
            const hav =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(toRadians(a.lat)) *
                Math.cos(toRadians(b.lat)) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2);
            const c = 2 * Math.atan2(Math.sqrt(hav), Math.sqrt(1 - hav));
            const radius = 6371000; // Radius bumi dalam meter
            return radius * c;
        };

        // Titik koordinat lokasi 1
        const point1 = { lat: lat1, lon: lon1 };

        // Titik koordinat lokasi 2
        const point2 = { lat: lat2, lon: lon2 };

        // Menghitung jarak antara kedua titik dalam meter
        const distance = haversine(point1, point2);

        // Memeriksa apakah jarak kurang dari atau sama dengan radius yang ditentukan
        return distance <= radiusInMeters;
    }

    const [modalCheckIn, setModalCheckIn] = useState(false)
    const [modalCheckOut, setModalCheckOut] = useState(false)

    const CheckInDataAttendance = async () => {
        setModalCheckIn(false)
        let resultRadius = false
        if (isWFH) {
            resultRadius = true
        } else {
            geodata.forEach((data) => {
                const geocodeArray = JSON.parse(data.geocode);
                const georadius = data.georadius;
                const result = isInRadius(geocodeArray[0], geocodeArray[1], lat, long, georadius);

                if (result) {
                    resultRadius = true;
                    return;
                }
            });
        }
        if (!resultRadius) {
            toast.error('Anda diluar radius!')
        } else {
            try {
                const data = {
                    longitude_checkin: JSON.stringify(long),
                    latitude_checkin: JSON.stringify(lat),
                    isWFH: isWFH,
                    timezone: tz,
                }
                await Api.PostMyAttendanceCheckIn(localStorage.getItem('hris-token'), data).then((res) => {
                    toast.success('Presensi Masuk Berhasil')
                    setRefresh(true)
                }).catch((err) => {
                    console.log(err)
                    toast.error(err?.response?.data?.message)
                })
            } catch (error) {
                console.log(error)
                toast.error("Presensi Masuk Gagal")
                toast.error(error.response.data.message)
            }
        }
    }

    const CheckOutDataAttendance = async () => {
        setModalCheckOut(false)
        let resultRadius = false
        if (isWFH) {
            resultRadius = true
        } else {
            geodata.forEach((data) => {
                const geocodeArray = JSON.parse(data.geocode);
                const georadius = data.georadius;
                const result = isInRadius(geocodeArray[0], geocodeArray[1], lat, long, georadius);

                if (result) {
                    resultRadius = true;
                    return;
                }
            });
        }
        if (!resultRadius) {
            toast.error('Anda diluar radius!')
        } else {
            try {
                const data = {
                    longitude_checkout: JSON.stringify(long),
                    latitude_checkout: JSON.stringify(lat),
                    isWFH: isWFH,
                    timezone: tz,
                }
                await Api.PostMyAttendanceCheckOut(localStorage.getItem('hris-token'), data).then((res) => {
                    toast.success('Presensi Keluar Berhasil')
                    setRefresh(true)
                }).catch((err) => {
                    console.log(err)
                    toast.error(err?.response?.data?.message)
                })
            } catch (error) {
                console.log(error)
                toast.error('Presensi Keluar Gagal')
                toast.error(error.response.data.message)
            }
        }
    }


    const [position, setPosition] = useState(null)

    const requestLocation = (type) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLat(latitude)
                    setLong(longitude)
                    setPosition([latitude, longitude])
                    if (type === "checkIn") {
                        setModalCheckIn(!modalCheckIn)
                    } else if (type === "checkOut") {
                        setModalCheckOut(!modalCheckOut)
                    }
                },
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        toast.error('Akses lokasi diperlukan untuk melanjutkan. Silakan izinkan akses lokasi.');
                    } else {
                        toast.error(error.message);
                    }
                }
            );

        } else {
            toast.error('Geolocation tidak didukung pada browser ini.');
        }
    }

    const iconOmbudsman = L.icon({
        iconUrl: LogoH,
        iconSize: [24, 32],
    });
    const iconMarker = L.icon({
        iconUrl: IconMarker,
        iconSize: [24, 32],
    });


    const [getStatistict, setGetStatistict] = useState('')
    const [isWebPresenceDisabled, setIsWebPresenceDisabled] = useState("")
    const getData = async () => {
        try {
            const resStat = await Api.GetStatistict(localStorage.getItem('hris-token'))
            const res = await Api.GetConfiguration(localStorage.getItem('hris-token'))
            const data = res.data.results.data
            setIsWebPresenceDisabled(data.isWebPresenceDisabled)
            setGetStatistict(resStat.data)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getData()
        getDataPresensi()
        getGeoData()
        setRefresh(false)
    }, [refresh])
    return (
        <>
            <div className='relative'>
                <Modal
                    activeModal={modalCheckIn}
                    title={'Presensi Masuk'}
                    buttonClose={() => setModalCheckIn(false)}
                    width={'450px'}
                    content={
                        <div className='space-y-6'>
                            <div>
                                {position ? (
                                    <MapContainer center={position} zoom={18} style={{ height: "150px", width: "100%" }}>
                                        <TileLayer
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                                        />
                                        <Marker position={position}
                                            icon={iconMarker}>
                                        </Marker>
                                        {Object.values(geodata).map((data, index) => {
                                            const geocodeString = data.geocode;
                                            const geocodeArray = JSON.parse(geocodeString);
                                            return (
                                                <div key={index}>
                                                    <Marker
                                                        position={
                                                            data.length === 0
                                                                ? [-4.289663763983576, 122.42769997219564]
                                                                : geocodeArray
                                                        }
                                                        icon={iconOmbudsman}
                                                    >
                                                        <Popup>{data.geoname}</Popup>
                                                    </Marker>
                                                    <Circle
                                                        center={
                                                            data.length === 0
                                                                ? [-4.289663763983576, 122.42769997219564]
                                                                : geocodeArray
                                                        }
                                                        radius={data.georadius}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </MapContainer>
                                ) : (
                                    <div className="text-center space-y-2">
                                        <div role="status">
                                            <svg aria-hidden="true" className="inline w-16 h-16 text-gray-200 animate-spin dark:text-red-thick fill-blue-medium" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                            </svg>
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <p>Memuat Lokasi...</p>
                                    </div>
                                )}
                            </div>
                            <div className="text-center text-sky-950 text-base font-semibold">“Sekarang Pukul {moment().format('HH:mm')}, Apakah anda yakin untuk <span className='font-bold italic'>Presensi Masuk</span>”</div>

                            <div className="text-center text-neutral-500 text-[13px]">Tindakan ini tidak dapat dibatalkan</div>


                            <div className='flex items-center justify-center gap-[12px] mt-5'>
                                <button onClick={() => setModalCheckIn(false)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Batal" : "Cancel"}</button>
                                <button onClick={CheckInDataAttendance} className='bg-[#0E5073] text-white text-sm rounded-[6px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Ya, Saya Yakin" : "Yes, Im Sure"}</button>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={modalCheckOut}
                    title={'Presensi Keluar'}
                    buttonClose={() => setModalCheckOut(false)}
                    width={'450px'}
                    content={
                        <div className='space-y-6'>
                            <div>
                                {position ? (
                                    <MapContainer center={position} zoom={18} style={{ height: "150px", width: "100%" }}>
                                        <TileLayer
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                                        />

                                        <Marker position={position}
                                            icon={iconMarker}>
                                        </Marker>
                                        {Object.values(geodata).map((data, index) => {
                                            const geocodeString = data.geocode;
                                            const geocodeArray = JSON.parse(geocodeString);
                                            return (
                                                <div key={index}>
                                                    <Marker
                                                        position={
                                                            data.length === 0
                                                                ? [-4.289663763983576, 122.42769997219564]
                                                                : geocodeArray
                                                        }
                                                        icon={iconOmbudsman}
                                                    >
                                                        <Popup>{data.geoname}</Popup>
                                                    </Marker>
                                                    <Circle
                                                        center={
                                                            data.length === 0
                                                                ? [-4.289663763983576, 122.42769997219564]
                                                                : geocodeArray
                                                        }
                                                        radius={data.georadius}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </MapContainer>
                                ) : (
                                    <div className="text-center space-y-2">
                                        <div role="status">
                                            <svg aria-hidden="true" className="inline w-16 h-16 text-gray-200 animate-spin dark:text-red-thick fill-blue-medium" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                            </svg>
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <p>Memuat Lokasi...</p>
                                    </div>
                                )}
                            </div>
                            <div className="text-center text-sky-950 text-base font-semibold">“Sekarang Pukul {moment().format('HH:mm')}, Apakah anda yakin untuk <span className='font-bold italic'>Presensi Keluar</span>”</div>

                            <div className="text-center text-neutral-500 text-[13px]">Tindakan ini tidak dapat dibatalkan</div>


                            <div className='flex items-center justify-center gap-[12px] mt-5'>
                                <button onClick={() => setModalCheckOut(false)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Batal" : "Cancel"}</button>
                                <button onClick={CheckOutDataAttendance} className='bg-[#0E5073] text-white text-sm rounded-[6px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Ya, Saya Yakin" : "Yes, Im Sure"}</button>
                            </div>
                        </div>
                    }
                />
            </div>
            <div div className='flex flex-wrap items-center gap-3 '>
                {isWebPresenceDisabled ?
                    <>
                        <div className='lg:w-[230px] md:w-[200px] w-full border p-5 bg-white rounded-[10px] shadow-sm space-y-[10px]'>
                            {sync ?
                                <div className='w-full space-y-2'>
                                    <div className='text-start'>
                                        <p className='font-semibold'>Presensi Masuk</p>
                                    </div>
                                    <div className='content-center w-full text-center flex-row justify-center items-center'>
                                        <svg aria-hidden="true" className="inline w-10 h-10 text-gray-200 animate-spin dark:text-red-thick fill-blue-medium" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                    </div>
                                </div>
                                :
                                <button onClick={() => requestLocation("checkIn")} className='w-full space-y-5'>
                                    <div className='text-start'>
                                        <p className='font-semibold'>Presensi Masuk</p>

                                    </div>
                                    <div className='flex items-center gap-3 w-full'>
                                        {/* {dataPresensi.date === today && dataPresensi.time_checkin !== null ?
                        <p className='text-xs text-green-700'> ✓ Sudah Presensi</p>
                        : <p className='text-xs text-red-700'>✕ Belum ada presensi masuk</p>
                    } */}
                                        <div className='w-1/2 flex gap-2'>
                                            <AiFillClockCircle className='text-base-yellow w-[20px] h-[20px]' />
                                            {dataPresensi.date === today && dataPresensi.time_checkin ?
                                                <p className='text-sm'>{moment(dataPresensi.time_checkin).format("HH:mm")}</p>
                                                : <p>--:--</p>
                                            }
                                        </div>
                                    </div>
                                </button>
                            }
                        </div>
                        <div className='lg:w-[230px] md:w-[200px] w-full border p-5 bg-white rounded-[10px] shadow-sm space-y-[10px]'>
                            {sync ?
                                <div className='w-full space-y-2'>
                                    <div className='text-start'>
                                        <p className='font-semibold'>Presensi Keluar</p>
                                    </div>
                                    <div className='content-center w-full text-center flex-row justify-center items-center'>
                                        <svg aria-hidden="true" className="inline w-10 h-10 text-gray-200 animate-spin dark:text-red-thick fill-blue-medium" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                    </div>
                                </div>
                                : <button onClick={() => requestLocation("checkOut")} className='w-full space-y-5'>
                                    <div className='text-start'>
                                        <p className='font-semibold'>Presensi Keluar</p>

                                    </div>
                                    <div className='flex items-center gap-3 w-full'>
                                        {/* {dataPresensi.date === today && dataPresensi.time_checkout ?
                        <p className='text-xs text-green-700'> ✓ Sudah Presensi</p>
                        : <p className='text-xs text-red-700'>✕ Belum ada presensi keluar</p>
                    } */}
                                        <div className='w-1/2 flex gap-2'>
                                            <AiFillClockCircle className='text-base-yellow w-[20px] h-[20px]' />
                                            {dataPresensi.date === today && dataPresensi.time_checkout ?
                                                <p className='text-sm'>{moment(dataPresensi.time_checkout).format("HH:mm")}</p>
                                                : <p>--:--</p>
                                            }
                                        </div>
                                    </div>
                                </button>
                            }
                        </div>
                    </>
                    : null
                }
                <div className='lg:w-[230px] md:w-fit w-full border p-5 bg-white rounded-[10px] shadow-sm space-y-[10px]'>
                    <div className='w-full space-y-2'>
                        <p className='font-semibold text-sm'>Sinkron Data Fingerprint</p>
                        <div className='flex gap-2 text-start'>
                            <input onChange={(e) => setDateSync(e.target.value)} value={dateSync} type="date" className='p-[10px] rounded-md border border-gray-300 text-[#003049] text-[12px]' />
                            <button onClick={() => getSyncAttendence()} className='px-[10px] rounded-md border border-gray-300'>
                                <BiRefresh className='text-red-thick text-2xl' />
                            </button>
                        </div>
                    </div>
                </div>
                <div className='lg:w-[230px] md:w-[200px] w-full'>
                    <DCardEmploye
                        CardName={"Sisa Hak Cuti"}
                        Value={getStatistict.sisa_cuti ? getStatistict.sisa_cuti : 0}
                        Img={IconDCardDashboard}
                    />
                </div>
                <div className='lg:w-[230px] md:w-[200px] w-full'>
                    <DCardEmploye
                        CardName={"Total Jam Pelatihan"}
                        Value={getStatistict.total_pelatihan ? getStatistict.total_pelatihan : 0}
                        Img={IconDCardDashboard}
                    />
                </div>
            </div>
        </>
    )
}

export default CardPegawai
