import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { BiPlus } from 'react-icons/bi'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { IoMdArrowDropdown } from 'react-icons/io'
import { MdImportExport } from 'react-icons/md'
import Api from '../../../../../Api'
import { Modal, ModalDelete, Navbar, Pagination } from '../../../../../components'

const CostProfit = () => {

    const [showModalAddProfit, setShowModalAddProfit] = useState(false)
    const [showModalAddCost, setShowModalAddCost] = useState(false)
    const [showModalEditProfit, setShowModalEditProfit] = useState(false)
    const [showModalEditCost, setShowModalEditCost] = useState(false)
    const [showModalDeleteProfit, setShowModalDeleteProfit] = useState(false)
    const [showModalDeleteCost, setShowModalDeleteCost] = useState(false)
    const [refresh, setRefresh] = useState(false)

    // Get Job Pisition
    const [dataJobPosition, setDataJobPosition] = useState('')
    const GetJobPosition = async () => {
        try {
            const response = await Api.GetJobPosition(localStorage.getItem('hris-token'), 1, 100)
            setDataJobPosition(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    // Pagination Cost
    const limit = 5
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    // Pagination Profit
    const limitProfit = 5
    const [currentPageProfit, setCurrentPageProfit] = useState(1)
    const [totalPagesProfit, setTotalPagesProfit] = useState('')

    const handlePageChangeProfit = (page) => {
        setCurrentPageProfit(page);
        setRefresh(true)
    };

    const handlePrevChangeProfit = () => {
        if (currentPageProfit === 1) {
            setCurrentPageProfit(1)
        } else {
            setCurrentPageProfit(currentPageProfit - 1);
        }
        setRefresh(true)
    };

    const handleNextChangeProfit = () => {
        if (currentPageProfit === totalPagesProfit) {
            setCurrentPageProfit(totalPagesProfit)
        } else {
            setCurrentPageProfit(currentPageProfit + 1);
        }
        setRefresh(true)
    };

    // Cost Data
    const [costID, setCostID] = useState('')
    const [costData, setCostData] = useState('')
    const [jobPositionCost, setJobPositionCost] = useState('')

    const GetCost = async () => {
        try {
            const response = await Api.GetCost(localStorage.getItem('hris-token'), currentPage, limit)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            setCostData(response.data.costs)
            console.log(response, 'getCost')
        } catch (error) {
            console.log(error)
        }
    }

    const PostCost = async () => {
        try {
            const data = {
                job_position: jobPositionCost
            }
            await Api.CreateCost(localStorage.getItem('hris-token'), data).then((res) => {
                setJobPositionCost('')
                setRefresh(true)
                setShowModalAddCost(!showModalAddCost)
                toast.success('Success Create')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Cost already created!')
        }
    }

    const CostModalOpen = async (id) => {
        setShowModalEditCost(!showModalEditCost)
        setCostID(id)
        try {
            const response = await Api.GetCostById(localStorage.getItem('hris-token'), id)
            setJobPositionCost(response.data.job_position.position_name)
        } catch (error) {
            console.log(error)
        }
    }

    const UpdateCost = async () => {
        try {
            const data = {
                job_position: jobPositionCost
            }
            await Api.UpdateCost(localStorage.getItem('hris-token'), data, costID).then((res) => {
                setJobPositionCost('')
                setRefresh(true)
                toast.success('Success Update')
                setShowModalEditCost(!showModalEditCost)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Cost already created!')
        }
    }

    const DeleteCostModal = async (id) => {
        setShowModalDeleteCost(!showModalDeleteCost)
        setCostID(id)
    }

    const DeleteCost = async (id) => {
        try {
            await Api.DeleteCost(localStorage.getItem('hris-token'), costID).then((res) => {
                setRefresh(true)
                toast.success('Success Delete')
                setShowModalDeleteCost(!showModalDeleteCost)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete')
        }
    }

    // Profit Data
    const [profitID, setProfitID] = useState('')
    const [profitData, setProfitData] = useState('')
    const [jobPositionProfit, setJobPositionProfit] = useState('')

    const GetProfit = async () => {
        try {
            const response = await Api.GetProfit(localStorage.getItem('hris-token'), currentPageProfit, limitProfit)
            setProfitData(response.data.profit)
            setCurrentPageProfit(response.data.currentPage)
            setTotalPagesProfit(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }

    const PostProfit = async () => {
        try {
            const data = {
                jobPosition: jobPositionProfit
            }
            await Api.CreateProfit(localStorage.getItem('hris-token'), data).then((res) => {
                setJobPositionProfit('')
                setRefresh(true)
                toast.success('Success Create')
                setShowModalAddProfit(!showModalAddProfit)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Profit already created!')
        }
    }

    const ProfitModalOpen = async (id) => {
        setShowModalEditProfit(!showModalEditProfit)
        setProfitID(id)
        try {
            const response = await Api.GetProfitById(localStorage.getItem('hris-token'), id)
            setJobPositionProfit(response.data.jobPosition.position_name)
        } catch (error) {
            console.log(error)
        }
    }

    const UpdateProfit = async () => {
        try {
            const data = {
                jobPosition: jobPositionProfit
            }
            await Api.UpdateProfit(localStorage.getItem('hris-token'), data, profitID).then((res) => {
                setJobPositionProfit('')
                setRefresh(true)
                toast.success('Success Update')
                setShowModalEditProfit(!showModalEditProfit)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Profit already created!')
        }
    }

    const DeleteProfitModal = async (id) => {
        setShowModalDeleteProfit(!showModalDeleteProfit)
        setProfitID(id)
    }

    const DeleteProfit = async (id) => {
        try {
            await Api.DeleteProfit(localStorage.getItem('hris-token'), profitID).then((res) => {
                setRefresh(true)
                toast.success('Success Delete')
                setShowModalDeleteProfit(!showModalDeleteProfit)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete')
        }
    }

    useEffect(() => {
        GetJobPosition()
        GetCost()
        GetProfit()
        setRefresh(false)
    }, [refresh])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <Modal
                activeModal={showModalAddProfit}
                title={'Profit Centre Unit Additions'}
                buttonClose={() => setShowModalAddProfit(!showModalAddProfit)}
                width={'550px'}
                content={
                    <div className='space-y-[25px]'>
                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Profit Employee<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select onChange={(e) => setJobPositionProfit(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option selected value={''}>Select Job Position</option>
                                    {Object.values(dataJobPosition).map((data, index) => {
                                        return (
                                            <option key={index} value={data.id}>{data.position_name}</option>
                                        )
                                    })}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                            </div>
                        </div>
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={() => setShowModalAddProfit(!showModalAddProfit)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={PostProfit} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showModalAddCost}
                title={'Cost Centre Unit Additions'}
                buttonClose={() => setShowModalAddCost(!showModalAddCost)}
                width={'550px'}
                content={
                    <div className='space-y-[25px]'>
                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Cost Employee<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select onChange={(e) => setJobPositionCost(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option disabled selected value={''}>Select Job Position</option>
                                    {Object.values(dataJobPosition).map((data, index) => {
                                        return (
                                            <option key={index} value={data.id}>{data.position_name}</option>
                                        )
                                    })}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                            </div>
                        </div>
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={() => setShowModalAddCost(!showModalAddCost)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={PostCost} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showModalEditCost}
                title={'Edit Cost Centre Unit Additions'}
                buttonClose={() => setShowModalEditCost(!showModalEditCost)}
                width={'550px'}
                content={
                    <div className='space-y-[25px]'>
                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Cost Employee<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select value={jobPositionCost} onChange={(e) => setJobPositionCost(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option selected value={''}>Select Job Position</option>
                                    {Object.values(dataJobPosition).map((data, index) => {
                                        return (
                                            <option key={index} value={data.id}>{data.position_name}</option>
                                        )
                                    })}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                            </div>
                        </div>
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={() => setShowModalEditCost(!showModalEditCost)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={UpdateCost} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showModalEditProfit}
                title={'Edit Profit Centre Unit Additions'}
                buttonClose={() => setShowModalEditProfit(!showModalEditProfit)}
                width={'550px'}
                content={
                    <div className='space-y-[25px]'>
                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Profit Employee<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select value={jobPositionProfit} onChange={(e) => setJobPositionProfit(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option selected value={''}>Select Job Position</option>
                                    {Object.values(dataJobPosition).map((data, index) => {
                                        return (
                                            <option key={index} value={data.id}>{data.position_name}</option>
                                        )
                                    })}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                            </div>
                        </div>
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={() => setShowModalEditProfit(!showModalEditProfit)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={UpdateProfit} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                        </div>
                    </div>
                }
            />
            <ModalDelete
                activeModal={showModalDeleteCost}
                buttonClose={() => setShowModalDeleteCost(!showModalDeleteCost)}
                submitButton={DeleteCost}
            />
            <ModalDelete
                activeModal={showModalDeleteProfit}
                buttonClose={() => setShowModalDeleteProfit(!showModalDeleteProfit)}
                submitButton={DeleteProfit}
            />
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Cost and Profit'} LinkBack={'/dashboard'} />
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='flex items-center justify-between'>
                        <div className='w-full lg:w-auto'>
                            <h1 className='text-dark-3 text-[16px] font-[500]'>
                                Profit Center
                            </h1>
                            <h1 className='text-[#A8A8A8] text-xs font-[500]'>
                                list of Profit employee
                            </h1>
                        </div>
                        <button onClick={() => setShowModalAddProfit(!showModalAddProfit)} className={`bg-[#0E5073] w-[150px] justify-center text-white text-sm rounded-[6px] p-[10px] flex items-center gap-[11px]`}>
                            <BiPlus className='text-white' />
                            Add Profit
                        </button>
                    </div>
                    <div className='overflow-auto scrollbar-hide'>
                        <table className='w-full space-y-[10px]'>
                            <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[15px] w-full'>
                                    <h1 className='text-dark-5 text-xs font-[500]'>Profit</h1>
                                    <MdImportExport className='text-dark-5 text-xs' />
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-xs font-[500]'>Action</h1>
                                </div>
                            </div>
                            {profitData.length === 0 ?
                                <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='w-full'>
                                        <h1 className='text-dark-5 text-xs font-[500] truncate'>-</h1>
                                    </div>
                                    <div className='min-w-[200px] max-w-[200px] flex items-center gap-[12px]'>
                                        <h1 className='text-dark-5 text-xs font-[500] truncate'>-</h1>
                                    </div>
                                </div>
                                :
                                Object.values(profitData).map((data, index) => {
                                    return (
                                        <div key={index} className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='w-full'>
                                                <h1 className='text-dark-5 text-xs font-[500] truncate'>{data.jobPosition.position_name}</h1>
                                            </div>
                                            <div className='min-w-[200px] max-w-[200px] flex items-center gap-[12px]'>
                                                <button onClick={() => ProfitModalOpen(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[#003049]' />
                                                </button>
                                                <button className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty onClick={() => DeleteProfitModal(data.id)} className='text-[#003049]' />
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </table>
                    </div>
                    <Pagination
                        currentPage={currentPageProfit}
                        totalPages={totalPagesProfit}
                        onPageChange={handlePageChangeProfit}
                        onPrevChange={handlePrevChangeProfit}
                        onNextChange={handleNextChangeProfit}
                    />
                </div>
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='flex items-center justify-between'>
                        <div className='w-full lg:w-auto'>
                            <h1 className='text-dark-3 text-[16px] font-[500]'>
                                Cost Center
                            </h1>
                            <h1 className='text-[#A8A8A8] text-xs font-[500]'>
                                list of Cost employee
                            </h1>
                        </div>
                        <button onClick={() => setShowModalAddCost(!showModalAddCost)} className={`bg-[#0E5073] text-white text-sm  w-[150px] justify-center rounded-[6px] p-[10px] flex items-center gap-[11px]`}>
                            <BiPlus className='text-white' />
                            Add Cost
                        </button>
                    </div>
                    <div className='overflow-auto scrollbar-hide'>
                        <table className='w-full space-y-[10px]'>
                            <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[15px] w-full'>
                                    <h1 className='text-dark-5 text-xs font-[500]'>Cost</h1>
                                    <MdImportExport className='text-dark-5 text-xs' />
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-xs font-[500]'>Action</h1>
                                </div>
                            </div>
                            {costData.length === 0 ?
                                <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='w-full'>
                                        <h1 className='text-dark-5 text-xs font-[500] truncate'>-</h1>
                                    </div>
                                    <div className='min-w-[200px] max-w-[200px] flex items-center gap-[12px]'>
                                        <h1 className='text-dark-5 text-xs font-[500] truncate'>-</h1>
                                    </div>
                                </div>
                                :
                                Object.values(costData).map((data, index) => {
                                    return (
                                        <div key={index} className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='w-full'>
                                                <h1 className='text-dark-5 text-xs font-[500] truncate'>{data.job_position.position_name}</h1>
                                            </div>
                                            <div className='min-w-[200px] max-w-[200px] flex items-center gap-[12px]'>
                                                <button onClick={() => CostModalOpen(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[#003049]' />
                                                </button>
                                                <button className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty onClick={() => DeleteCostModal(data.id)} className='text-[#003049]' />
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </table>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        onPrevChange={handlePrevChange}
                        onNextChange={handleNextChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default CostProfit