import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiFillEye, AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'
import { HiOutlinePencil } from 'react-icons/hi'
import Select from 'react-select'
import Api from '../../../../Api'
import { IconPlus } from '../../../../assets'
import { Modal, ModalDelete, Navbar, Pagination } from '../../../../components'
import IconPrimaryButton from '../../../../components/Button/IconPrimaryButton'
import LoadingPrimaryButton from '../../../../components/Button/LoadingPrimaryButton'
import PrimaryButton from '../../../../components/Button/PrimaryButton'
import SecondaryButton from '../../../../components/Button/SecondaryButton'
import InputComponent from '../../../../components/Input/inputComponent'
import { selectStyle } from '../../../../utils/styles/select'
import moment from 'moment'

const NomorSurat = () => {
    const limit = 10
    const [currentPage, setCurrentPage] = useState(1)
    const [refresh, setRefresh] = useState(false)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };
    const [showAdd, setShowAdd] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [id, setId] = useState('')
    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [timPenilaiId, setTimPenilaiId] = useState('')

    const [dataPegawai, setDataPegawai] = useState([])

    const resetForm = () => {
        setStart("")
        setEnd("")
        setStartDate("")
        setEndDate("")
    }

    const getPegawai = async () => {
        try {
            await Api.GetDropdownEmployee(localStorage.getItem('hris-token')).then((response) => {
                const res = response.data.data
                const formattedData = res.map((val => ({ value: val.id, label: val.fullname })))
                setDataPegawai(formattedData)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }

    const [data, setData] = useState([])
    const getData = async () => {
        try {
            await Api.GetNomorSurat(localStorage.getItem('hris-token'), currentPage, limit).then((response) => {
                const res = response.data.results
                setData(res.data)
                setCurrentPage(res.currentPage)
                setTotalPages(res.totalPages)
                setTotalCount(res.totalCount)
            }).catch((err) => {
                console.log(err)
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Error!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }

    const postData = async () => {
        setIsLoading(true)
        const data = {
            "start": start,
            "end": end,
            "start_date": startDate,
            "end_date": endDate
        }
        try {
            await Api.PostNomorSurat(localStorage.getItem('hris-token'), data).then((response) => {
                resetForm()
                setIsLoading(false)
                toast.success('Berhasil menambahkan data!')
                setShowAdd(false)
                setRefresh(true)
            }).catch((err) => {
                console.log(err)
                setIsLoading(false)
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Data gagal ditambahkan!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }
    const getDataById = async (id) => {
        setShowEdit(true)
        setId(id)
        try {
            await Api.GetNomorSuratById(localStorage.getItem('hris-token'), id).then((response) => {
                const res = response.data.results.data
                setStart(res?.start)
                setEnd(res?.end)
                setStartDate(res?.start_date)
                setEndDate(res?.end_date)
            }).catch((err) => {
                console.log(err)
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Error!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }
    const editData = async () => {
        setIsLoading(true)
        const data = {
            "start": start,
            "end": end,
            "start_date": startDate,
            "end_date": endDate
        }
        try {
            await Api.UpdateNomorSurat(localStorage.getItem('hris-token'), id, data).then((response) => {
                resetForm()
                setIsLoading(false)
                toast.success('Berhasil mengubah data!')
                setShowEdit(false)
                setRefresh(true)
            }).catch((err) => {
                console.log(err)
                setIsLoading(false)
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Data gagal diubah!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }
    const deleteDataModal = (id) => {
        setShowDelete(!showDelete)
        setId(id)
        setRefresh(true)
    }

    const deleteData = async () => {
        try {
            await Api.DeleteNomorSurat(localStorage.getItem('hris-token'), id).then((res) => {
                setShowDelete(!showDelete)
                setRefresh(true)
                toast.success('Berhasil menghapus data!')
            }).catch((err) => {
                if (err.response.data.errors) {
                    toast.error(err.response.data.errors)
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message)
                } else {
                    toast.error("Data gagal dihapus!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }
    useEffect(() => {
        getPegawai()
    }, [])

    useEffect(() => {
        getData()
        setRefresh(false)
    }, [refresh])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='relative'>
                <Modal
                    activeModal={showAdd}
                    title={"Tambah Nomor Surat"}
                    buttonClose={() => { setShowAdd(!showAdd); resetForm() }}
                    width={'832px'}
                    content={
                        <div className='space-y-[24px] w-[full] '>
                            <div className='space-y-3'>
                                <div>
                                    <h1 className='w-full text-grey-thick text-[12px] font-medium mb-1 line-clamp-1'>Rentang Nomor Surat <span className='text-red-primer'> *</span></h1>
                                    <div className='flex items-center gap-3'>
                                        <input
                                            type={'number'}
                                            value={start}
                                            placeholder={"0"}
                                            min={0}
                                            onChange={(e) => setStart(e.target.value)}
                                            className='w-full border pr-2 rounded-md py-[10px] pl-[21.74px] text-grey-thick text-[12px]'
                                        />
                                        <p>-</p>
                                        <input
                                            type={'number'}
                                            value={end}
                                            placeholder={"0"}
                                            min={0}
                                            onChange={(e) => setEnd(e.target.value)}
                                            className='w-full border pr-2 rounded-md py-[10px] pl-[21.74px] text-grey-thick text-[12px]'
                                        />
                                    </div>
                                </div>
                                <div>
                                    <h1 className='w-full text-grey-thick text-[12px] font-medium mb-1 line-clamp-1'>Rentang Tanggal Berlaku <span className='text-red-primer'> *</span></h1>
                                    <div className='flex items-center gap-3'>
                                        <input
                                            type={'date'}
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                            className='w-full border pr-2 rounded-md py-[10px] pl-[21.74px] text-grey-thick text-[12px]'
                                        />
                                        <p>-</p>
                                        <input
                                            type={'date'}
                                            value={endDate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                            className='w-full border pr-2 rounded-md py-[10px] pl-[21.74px] text-grey-thick text-[12px]'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-end gap-[10px]">
                                <SecondaryButton action={() => { setShowAdd(!showAdd); resetForm() }} text={'Batal'} />
                                {isLoading ?
                                    <LoadingPrimaryButton />
                                    : <PrimaryButton action={() => postData()} text={'Simpan'} />
                                }
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEdit}
                    title={"Edit Nomor Surat"}
                    buttonClose={() => { setShowEdit(!showEdit); resetForm() }}
                    width={'832px'}
                    content={
                        <div className='space-y-[24px] w-[full] '>
                            <div className='space-y-3'>
                                <div>
                                    <h1 className='w-full text-grey-thick text-[12px] font-medium mb-1 line-clamp-1'>Rentang Nomor Surat <span className='text-red-primer'> *</span></h1>
                                    <div className='flex items-center gap-3'>
                                        <input
                                            type={'number'}
                                            value={start}
                                            placeholder={"0"}
                                            min={0}
                                            onChange={(e) => setStart(e.target.value)}
                                            className='w-full border pr-2 rounded-md py-[10px] pl-[21.74px] text-grey-thick text-[12px]'
                                        />
                                        <p>-</p>
                                        <input
                                            type={'number'}
                                            value={end}
                                            placeholder={"0"}
                                            min={0}
                                            onChange={(e) => setEnd(e.target.value)}
                                            className='w-full border pr-2 rounded-md py-[10px] pl-[21.74px] text-grey-thick text-[12px]'
                                        />
                                    </div>
                                </div>
                                <div>
                                    <h1 className='w-full text-grey-thick text-[12px] font-medium mb-1 line-clamp-1'>Rentang Tanggal Berlaku <span className='text-red-primer'> *</span></h1>
                                    <div className='flex items-center gap-3'>
                                        <input
                                            type={'date'}
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                            className='w-full border pr-2 rounded-md py-[10px] pl-[21.74px] text-grey-thick text-[12px]'
                                        />
                                        <p>-</p>
                                        <input
                                            type={'date'}
                                            value={endDate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                            className='w-full border pr-2 rounded-md py-[10px] pl-[21.74px] text-grey-thick text-[12px]'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-end gap-[10px]">
                                <SecondaryButton action={() => { setShowEdit(!showEdit); resetForm() }} text={'Batal'} />
                                {isLoading ?
                                    <LoadingPrimaryButton />
                                    : <PrimaryButton action={() => editData()} text={'Simpan'} />
                                }
                            </div>
                        </div>
                    }
                />
                < ModalDelete
                    activeModal={showDelete}
                    buttonClose={() => setShowDelete(!showDelete)}
                    submitButton={deleteData}
                />
            </div>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Pengaturan Nomor Surat'} LinkBack={'/performance-setting'} />
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='space-y-5 overflow-auto'>
                        <div className='flex justify-start'>
                            <IconPrimaryButton icon={IconPlus} action={() => setShowAdd(true)} text={"Tambah Nomor Surat"} />
                        </div>
                        <table className='min-w-full'>
                            <thead>
                                <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                                    <th className='border py-3 px-5'>No</th>
                                    <th className='border py-3 px-5'>Rentang Nomor Surat</th>
                                    <th className='border py-3 px-5'>Rentang Tanggal</th>
                                    <th className='border py-3 px-5'>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.map((val, index) => (
                                    <tr key={index} className='border'>
                                        <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>
                                            {index + 1}
                                        </td>
                                        <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.start} - {val?.end}</td>
                                        <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{moment(val?.start_date).format("D MMMM YYYY")} - {moment(val?.end_date).format("D MMMM YYYY")}</td>
                                        <td className=' py-3 px-5 text-grey-thick text-xs font-medium flex gap-3'>
                                            <button
                                                onClick={() => getDataById(val.id)}
                                                className='bg-blue-thin p-2 rounded-[10px] flex items-center gap-1 w-fit'>
                                                <HiOutlinePencil className='text-blue-thick' />
                                            </button>
                                            <button
                                                onClick={() => {
                                                    deleteDataModal(val?.id);
                                                }}
                                                className='bg-blue-thin p-2 rounded-[10px] flex items-center gap-1 w-fit'>
                                                <AiOutlineDelete className='text-sm text-blue-thick' />
                                            </button>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            lengthData={totalCount}
                            limitData={limit}
                            onPageChange={handlePageChange}
                            onPrevChange={handlePrevChange}
                            onNextChange={handleNextChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NomorSurat
