import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'
import { HiOutlinePencil } from 'react-icons/hi'
import Api from '../../../../Api'
import { Modal, ModalDelete, Navbar, Pagination } from '../../../../components'
import InputComponent from "../../../../components/Input/inputComponent"
import SearchComponent from '../../../../components/Input/searchComponent'

const TugasTambahan = () => {
    const [showAdd, setShowAdd] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [data, setData] = useState([])
    const [name, setName] = useState([])
    const limit = 10

    const [search, setSearch] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState(1)
    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const getTugasTambahan = async () => {
        try {
            await Api.GetSetTugasTambahan(localStorage.getItem('hris-token'), currentPage, limit, search).then((response) => {
                setData(response.data.results.data)
                setCurrentPage(response.data.results.currentPage)
                setTotalPages(response.data.results.totalPages)
                setTotalCount(response.data.results.totalCount)
            })
        } catch (error) {

        }
    }
    const getDataById = async (data) => {
        setShowEdit(true)
        setId(data)
        try {
            await Api.GetSetTugasTambahanById(localStorage.getItem('hris-token'), data).then((response) => {
                console.log(response.data.results.data)
                const data = response.data.results.data
                setName(data?.name ?? "")
            })
        } catch (error) {

        }
    }

    const [tugas, setTugas] = useState([])
    const [dataJobLevel, setDataJobLevel] = useState([])
    const [selected, setSelected] = useState("")
    const getJobLevel = async () => {
        try {
            await Api.GetJobLevelDropdown(localStorage.getItem('hris-token')).then((response) => {
                const data = response.data.results.data
                const options = data && data.map(val => ({
                    "value": val.id,
                    "label": val.level_name
                }))
                setDataJobLevel(options)
            })
        } catch (error) {

        }
    }

    const handleCahnge = (selectedOption) => {
        setSelected(selectedOption)
    }

    const resetForm = () => {
        setName("")
    }

    const PostData = async () => {
        try {
            const data = {
                "name": name
            }
            await Api.PostSetTugasTambahan(localStorage.getItem('hris-token'), data).then((response) => {
                setRefresh(true)
                resetForm()
                toast.success("Berhasil Menambahkan Data!")
                setShowAdd(false)
            }).catch((e) => {
                console.log(e)
                toast.error(e?.response?.data?.message)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const UpdateData = async () => {
        try {
            const data = {
                "name": name
            }
            console.log(data)
            await Api.UpdateSetTugasTambahan(localStorage.getItem('hris-token'), data, id).then((response) => {
                setRefresh(true)
                resetForm()
                toast.success("Berhasil Mengubah Data!")
                setShowEdit(false)
            }).catch((e) => {
                console.log(e)
                toast.error(e?.response?.data?.message)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const [id, setId] = useState("")
    const [showModalDelete, setShowModalDelete] = useState(false)

    const deleteModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setId(id)
        setRefresh(true)
    }

    const deleteData = async () => {
        try {
            await Api.DeleteSetTugasTambahan(localStorage.getItem('hris-token'), id).then((response) => {
                setRefresh(true)
                setShowModalDelete(false)
                toast.success('Hapus Data Berhasil!')
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
            toast.error('Data Gagal dihapus!')
        }
    }
    const [Current, setCurrent] = useState(0)
    const [Active, setActive] = useState(false)
    const handleCollapse = (id) => {
        if (id) {
            setCurrent(id)
            setActive(!Active)
        }
    }

    const handleSearch = (e) => {
        setSearch(e)
    }

    const handleAddRepeter = () => {
        setTugas([...tugas, { kode: '', name: '' }]);
    };

    const handleRemoveRepeter = (index) => {
        const updatedRepeter = [...tugas];  // Create a shallow copy of tugas
        updatedRepeter.splice(index, 1);    // Remove the item at the given index
        setTugas(updatedRepeter);
    };

    const handleChangeRepeter = (index, selectedKode, selectedValue) => {
        const updatedRepeter = tugas.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    kode: selectedKode !== undefined && selectedKode !== null ? (selectedKode !== item.kode ? selectedKode : item.kode) : item.kode,
                    name: selectedValue !== undefined && selectedValue !== null ? (selectedValue !== item.name ? selectedValue : item.name) : item.name
                };
            }
            return item; // Kembalikan item yang tidak diubah untuk indeks lainnya
        });

        setTugas(updatedRepeter);
    };



    const [idDetail, setIdDetail] = useState("")
    const [indexDetail, setIndexDetail] = useState("")
    const [showModalDeleteDetail, setShowModalDeleteDetail] = useState(false)

    const deleteModalDetail = (index, id) => {
        setShowModalDeleteDetail(true)
        setIdDetail(id)
        setIndexDetail(index)
        setRefresh(true)
    }

    const deleteTugasTambahanDetail = async () => {
        if (idDetail) {
            try {
                console.log(idDetail)
                await Api.DeleteTugasTambahanDetail(localStorage.getItem('hris-token'), idDetail).then((response) => {
                    setRefresh(true)
                    handleRemoveRepeter(indexDetail)
                    setShowModalDeleteDetail(false)
                    toast.success('Hapus Data Berhasil!')
                }).catch((error) => {
                    console.log(error)
                })
            } catch (error) {
                console.log(error)
                toast.error('Data Gagal dihapus!')
            }
        } else {
            handleRemoveRepeter(indexDetail)
            setShowModalDeleteDetail(false)
        }
    }

    useEffect(() => {
        getJobLevel()
    }, [])
    useEffect(() => {
        getTugasTambahan()
        setRefresh(false)
    }, [refresh, search])
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='relative'>
                <Modal
                    activeModal={showAdd}
                    title={"Tambah Tugas Tambahan"}
                    buttonClose={() => { setShowAdd(false); resetForm() }}
                    width={'832px'}
                    content={
                        <div className='space-y-[24px] w-[full]'>
                            <div>
                                <InputComponent
                                    disabled={false}
                                    label={"Nama Tugas"}
                                    value={name}
                                    onChange={(e) => setName(e)}
                                    required={true}
                                    type={"text"}
                                    placeholder={"Masukkan nama tugas Tambahan...."}
                                />
                            </div>
                            <div className="flex justify-end gap-[10px]">
                                <button onClick={() => { setShowAdd(false); resetForm() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={PostData} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEdit}
                    title={"Edit Tugas Tambahan"}
                    buttonClose={() => { setShowEdit(false); resetForm() }}
                    width={'832px'}
                    content={
                        <div className='space-y-[24px] w-[full]'>
                            <div>
                                <InputComponent
                                    disabled={false}
                                    label={"Nama Tugas"}
                                    value={name}
                                    onChange={(e) => setName(e)}
                                    required={true}
                                    type={"text"}
                                    placeholder={"Masukkan nama tugas Tambahan...."}
                                />
                            </div>
                            <div className="flex justify-end gap-[10px]">
                                <button onClick={() => { setShowEdit(false); resetForm() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={UpdateData} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <ModalDelete
                    activeModal={showModalDeleteDetail}
                    buttonClose={() => setShowModalDeleteDetail(!showModalDeleteDetail)}
                    submitButton={deleteTugasTambahanDetail}
                />
                <ModalDelete
                    activeModal={showModalDelete}
                    buttonClose={() => setShowModalDelete(!showModalDelete)}
                    submitButton={deleteData}
                />
            </div>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Pengaturan Tugas Tambahan Asisten'} LinkBack={'/performance-setting'} />
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='space-y-5 overflow-auto'>
                        <div className='flex justify-end gap-2'>
                            <SearchComponent onChange={handleSearch} width={200} textSize={12} placeholder={"Cari Tugas Tambahan..."} />
                            <button onClick={() => setShowAdd(true)} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                <AiOutlinePlus className='text-xl text-white' />
                                <h1 className='text-dark-9 text-sm font-[500]'>Tambah Tugas Tambahan</h1>
                            </button>
                        </div>
                        <table className='min-w-full'>
                            <thead>
                                <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                                    <th className='border py-3 px-5'>No.</th>
                                    <th className='border py-3 px-5'>Tugas Tambahan</th>
                                    <th className='border py-3 px-5'>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.map((val, index) => (
                                    <tr key={index} className='border'>
                                        <td className='border py-3 px-5 text-grey-thick text-xs font-medium text-center'>{index + 1}</td>
                                        <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.name ?? ""}</td>
                                        <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>
                                            <div className='flex justify-center items-center gap-2'>
                                                <button
                                                    onClick={() => getDataById(val.id)}
                                                    className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-blue-thick' />
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        deleteModal(val?.id);
                                                    }}
                                                    className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                    <AiOutlineDelete className='text-sm text-blue-thick' />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            lengthData={totalCount}
                            limitData={limit}
                            onPageChange={handlePageChange}
                            onPrevChange={handlePrevChange}
                            onNextChange={handleNextChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TugasTambahan