import React, { useEffect, useState } from 'react'
import { IconAdmin, IconConfiguration, IconConfigurationSelected, IconDashboard, IconDocument, IconEmployee, IconFinance, IconLogout, IconNotification, IconPayroll, IconPerformance, IconPerformanceSelected, IconRecruitment, IconTimeManagement, LogoH } from '../../assets'
import { RiArrowRightSFill } from 'react-icons/ri';
import { MdMenuOpen } from 'react-icons/md'
import { BsArrowLeftShort } from 'react-icons/bs'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaEnvelope } from 'react-icons/fa';
import Api from '../../Api';
import imageHandle from '../../utils/imageHandle';
import languages from '../../utils/languages';

const Navbar = ({ SubNavbar, NameSubNavbar, LinkBack }) => {

    const navigate = useNavigate()
    const location = useLocation()
    const [mobileMenu, setMobileMenu] = useState(false)
    const [admin, setAdmin] = useState(false)
    const [job, setJob] = useState(false)
    const [organization, setOrganization] = useState(false)
    const [employee, setEmployee] = useState(false)
    const [configuration, setConfiguration] = useState(false)
    const [timeManagement, setTimeManagement] = useState(false)
    const [attendance, setAttendance] = useState(false)
    const [user, setUser] = useState('')
    const [role, setRole] = useState('')
    const [isAtasan, setIsAtasan] = useState(false)
    const [jabatan, setJabatan] = useState('')
    const [display, setDisplay] = useState(true)
    const [inbox, setInbox] = useState('')
    const [image, setImage] = useState('')
    const [userID, setUserID] = useState('')
    const [hakAkses, setHakAkses] = useState([])


    const Fetch = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setUser(response.data.results.user)
            setUserID(response.data.results.user.id)
            setRole(response.data.results.user.role)
            setIsAtasan(response.data.results.user.isAtasan)
            setJabatan(response.data?.results?.user?.user_detail?.job_position?.position_name)
            setImage(response.data?.results?.user?.user_detail?.image)
            setHakAkses(response.data.results.user.hak_akses)
        } catch (error) {
            console.log(error)
        }
    }

    // const getDataCompany = async() => {
    //     try {
    //         const response = await Api.GetGeneralInformation(localStorage.getItem('hris-token'))
    //         setImage(response.data.company.image)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const [limit, setLimit] = useState(10)
    const [query, setQuery] = useState('')
    const [page, setPage] = useState(1)
    const GetInbox = async () => {
        try {
            const response = await Api.GetInboxCounter(localStorage.getItem('hris-token'))
            setInbox(response.data.results)
        } catch (error) {
            console.log(error)
        }
    }

    const Logout = async () => {
        localStorage.removeItem('hris-token')
        localStorage.removeItem('hris-role')
        navigate('/')
    }

    function canAccessMenu(hakAkses) {
        // Daftar properti dan objek yang perlu dicek
        const menus = [
            hakAkses?.verifikasi_perdin,
            hakAkses?.approval_pendidikan,
            hakAkses?.approval_penetapan_inovasi,
            { verifikasi_pak: hakAkses?.verifikasi_pak }
        ];

        // Cek apakah setidaknya ada satu hak akses yang memenuhi kriteria
        return menus.some(menu =>
            // Validasi objek sebelum pengecekan properti
            typeof menu === 'object' && menu !== null &&
            (
                menu.verifikasi_pak ||
                menu.all ||
                menu.penempatan ||
                menu.bawahan ||
                (Array.isArray(menu.unitKerjaEselon) && menu.unitKerjaEselon.length > 0)
            )
        );
    }
    useEffect(() => {
        Fetch()
        GetInbox()
    }, [])

    return (
        <div>
            <div className={`bg-white border shadow-sm rounded-[6px] py-[16px] px-[25px] mt-[30px] w-full flex items-center justify-between`}>
                <div className={`${SubNavbar ? 'hidden' : 'block'} space-y-[5px]`}>
                    <h1 className='text-[#C1121F] text-sm font-semibold'>Halo, {user.fullname}</h1>
                    <h1 className='text-[#AAAAAA] text-[10px] font-[500]'>{role === 'ADMIN' || role === 'SUBSIDIARY' ? role : jabatan}</h1>
                </div>
                <div className={`${SubNavbar ? 'block' : 'hidden'} flex items-center gap-[16px]`}>
                    <Link to={LinkBack}>
                        <BsArrowLeftShort className='text-[#DC1717] text-2xl' />
                    </Link>
                    <h1 className='text-[#DC1717] text-sm font-[500]'>{NameSubNavbar}</h1>
                </div>
                <div className='hidden lg:flex items-end gap-[20px]'>
                    <div className='flex flex-col justify-center items-center'>
                        <Link to={'/inbox'} className={`${SubNavbar ? '' : 'border'} w-[41px] h-[41px] rounded-full flex items-center justify-center relative`}>
                            <FaEnvelope className={`${inbox.length > 0 ? 'text-[#DC1717] animate-pulse' : 'text-red-primer animate-none'} text-2xl relative`} />
                        </Link>
                        <p className='text-xs text-[#AAAAAA] text-[10px] font-[500]'>Pesan Masuk ({inbox})</p>
                    </div>
                    {/* <button className={`${SubNavbar ? '' : 'border'} w-[41px] h-[41px] rounded-full flex items-center justify-center`}>
                        <img src={IconNotification} alt='Humanusia Notification'/>
                    </button> */}
                    {role === 'ADMIN' || role === 'SUBSIDIARY' ?
                        <div className='flex flex-col justify-center items-center'>
                            <button onClick={() => navigate('/general-information')} className={` border w-[41px] h-[41px] rounded-full overflow-hidden`}>
                                <img src={!image ? LogoH : imageHandle(image)} alt="profile" className="object-cover rounded-full w-[41px] h-[41px]" />
                            </button>
                            <p className='text-xs text-[#AAAAAA] text-[10px] font-[500]'>Profil</p>
                        </div>
                        :
                        <div className='flex flex-col justify-center items-center'>
                            <button onClick={() => navigate('/profile', { state: { employeeId: userID } })} className={` border w-[41px] h-[41px] rounded-full overflow-hidden`}>
                                <img src={image ? imageHandle(image) : LogoH} alt="profile" className="object-cover rounded-full w-[41px] h-[41px]" />
                            </button>
                            <p className='text-xs text-[#AAAAAA] text-[10px] font-[500]'>Profil</p>
                        </div>
                    }
                </div>
                <button onClick={() => setMobileMenu(!mobileMenu)} className='flex lg:hidden border w-[41px] h-[41px] rounded-full items-center justify-center'>
                    <MdMenuOpen className='text-2xl text-[#C1121F]' />
                </button>
            </div>
            <div className={`${mobileMenu ? 'h-[730px] border' : 'h-0 border-0'} lg:hidden mt-2 bg-white rounded-[6px] w-full shadow-sm transition-all duration-1000 ease-in-out overflow-hidden`}>
                <div className='my-[16px] mx-[25px]'>
                    <div className='border-b-2 pt-[20px] pb-[30px]'>
                        <div className='flex items-center justify-center gap-[20px]'>
                            <div className='flex flex-col justify-center items-center'>
                                <Link to={'/inbox'} className={`${SubNavbar ? '' : 'border'} w-[41px] h-[41px] rounded-full flex items-center justify-center relative`}>
                                    <FaEnvelope className={`${inbox.length > 0 ? 'text-[#DC1717] animate-pulse' : 'text-red-primer animate-none'} text-2xl relative`} />
                                </Link>
                                <p className='text-xs text-[#AAAAAA] text-[10px] font-[500]'>Pesan Masuk ({inbox})</p>
                            </div>
                            {role === 'ADMIN' || role === 'SUBSIDIARY' ?
                                <div className='flex flex-col justify-center items-center'>
                                    <button onClick={() => navigate('/general-information')} className={` border w-[41px] h-[41px] rounded-full overflow-hidden`}>
                                        <img src={!image ? LogoH : imageHandle(image)} alt="profile" className="object-cover rounded-full w-[41px] h-[41px]" />
                                    </button>
                                    <p className='text-xs text-[#AAAAAA] text-[10px] font-[500]'>Profil</p>
                                </div>
                                :
                                <div className='flex flex-col justify-center items-center'>
                                    <button onClick={() => navigate('/profile', { state: { employeeId: userID } })} className={` border w-[41px] h-[41px] rounded-full overflow-hidden`}>
                                        <img src={!image ? LogoH : imageHandle(image)} alt="profile" className="object-cover rounded-full w-[41px] h-[41px]" />
                                    </button>
                                    <p className='text-xs text-[#AAAAAA] text-[10px] font-[500]'>Profil</p>
                                </div>
                            }
                        </div>
                        {/* <h1 className='text-sm text-[#A8A8A8] hover:text-[#780000] font-[500] mt-6 text-center'>{user.fullname}</h1> */}
                    </div>
                    {role === 'ADMIN' || role === 'SUBSIDIARY' ?
                        <div className={`my-[40px] h-[410px] overflow-auto scrollbar-hide flex flex-col ${display ? 'items-start' : 'items-center'}`}>
                            <Link to={'/dashboard'} className='flex items-center gap-[16px] mb-[40px]'>
                                <img src={IconDashboard} alt='Humanusia Icon' />
                                {display &&
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500]'>Beranda</h1>
                                }
                            </Link>

                            <button onClick={() => { setAdmin(!admin); setJob(false); setConfiguration(false); setOrganization(false); setDisplay(true); setEmployee(false); setTimeManagement(false) }} className={`flex items-center ${display ? 'justify-between w-full' : 'w-fit'} gap-[16px] text-sm ${admin ? 'text-[#780000]' : 'text-black'} hover:text-[#780000] font-[500] pb-[40px]`}>
                                <div className='flex items-center gap-[16px]'>
                                    <img src={IconAdmin} alt='Humanusia Icon' />
                                    {display &&
                                        <h1>Admin</h1>
                                    }
                                </div>
                                {display &&
                                    <RiArrowRightSFill className='text-xl' />
                                }
                            </button>
                            <div className={`${admin ? 'block min-h-[250px]' : 'hidden'} pl-[40px] transition-all ease-in-out duration-500 overflow-auto pb-5 w-full [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300`}>
                                <Link to={'/import-data'}>
                                    <h1 className={`${location.pathname === '/import-data' ? 'text-[#780000]' : 'text-black'} text-sm text-black hover:text-[#780000] font-[500]`}>Impor Data</h1>
                                </Link>
                                <Link to={'/user-management'}>
                                    <h1 className={`${location.pathname === '/user-management' ? 'text-[#780000]' : 'text-black'} text-sm text-black hover:text-[#780000] font-[500] mt-5`}>{languages.language === 'id' ? "Manajemen Pengguna" : "User Management"}</h1>
                                </Link>
                                <Link to={'/job-management'}>
                                    <h1 className={`${location.pathname === '/job-management' ? 'text-[#780000]' : 'text-black'} text-sm text-black hover:text-[#780000] font-[500] mt-5`}>{languages.language === 'id' ? "Manajemen Jabatan" : "Job Management"}</h1>
                                </Link>

                                <Link to={'/general-information'}>
                                    <h1 className={`${location.pathname === '/general-information' ? 'text-[#780000]' : 'text-black'} text-sm text-black hover:text-[#780000] font-[500] mt-5`}>{languages.language === 'id' ? "Informasi Umum" : "General Information"}</h1>
                                </Link>
                                <Link to={'/general-information/structure'}>
                                    <h1 className={`${location.pathname === '/general-information/structure' ? 'text-[#780000]' : 'text-black'} text-sm text-black hover:text-[#780000] font-[500] mt-5`}>Organisasi</h1>
                                </Link>
                                {
                                    role !== 'SUBSIDIARY' ?
                                        <Link to={'/subsidiary'}>
                                            <h1 className={`${location.pathname === '/subsidiary' ? 'text-[#780000]' : 'text-black'} text-sm text-black hover:text-[#780000] font-[500] mt-5`}>{languages.language === 'id' ? "Penempatan" : "Subsidiary"}</h1>

                                        </Link>
                                        : null
                                }

                            </div>

                            <button onClick={() => { setConfiguration(!configuration); setEmployee(false); setDisplay(true); setAdmin(false); setTimeManagement(false) }} className={`flex items-center ${display ? 'justify-between w-full' : 'w-fit'} gap-[16px] text-sm ${employee ? 'text-[#780000]' : 'text-black'} hover:text-[#780000] font-[500] pb-[40px]`}>
                                <div className='flex items-center gap-[16px]'>
                                    <img src={configuration ? IconConfigurationSelected : IconConfiguration} alt='Humanusia Icon' />
                                    {display &&
                                        <h1>Pengaturan</h1>
                                    }
                                </div>
                                {display &&
                                    <RiArrowRightSFill className='text-xl' />
                                }
                            </button>
                            <div className={`${configuration ? 'block min-h-[200px]' : 'hidden'} pl-[40px] transition-all ease-in-out duration-500 overflow-auto pb-5 w-full [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300`}>
                                <Link to={'/time-management-setting'}>
                                    <h1 className={`${location.pathname === '/time-management-setting' ? 'text-[#780000]' : 'text-black'} text-sm text-black hover:text-[#780000] font-[500]`}>{languages.language === 'id' ? "Pengaturan Manajemen Waktu" : "Time Management Setting"}</h1>
                                </Link>
                                {
                                    role === 'ADMIN' ?
                                        <>
                                            <Link to={'/payroll-setting'}>
                                                <h1 className={`${location.pathname === '/payroll-setting' ? 'text-[#780000]' : 'text-black'} text-sm text-black hover:text-[#780000] font-[500] mt-5`}>{languages.language === 'id' ? "Pengaturan Penghasilan" : "Payroll Setting"}</h1>

                                            </Link>
                                            <Link to={'/performance-setting'}>
                                                <h1 className={`${location.pathname === '/performance-setting' ? 'text-[#780000]' : 'text-black'} text-sm text-black hover:text-[#780000] font-[500] mt-5`}>{languages.language === 'id' ? "Pengaturan Laporan Kinerja" : "Performance Setting"}</h1>
                                            </Link>
                                            <Link to={'/approval-setting'}>
                                                <h1 className={`${location.pathname === '/approval-setting' ? 'text-[#780000]' : 'text-black'} text-sm text-black hover:text-[#780000] font-[500] mt-5`}>Pengaturan Persetujuan</h1>
                                            </Link>
                                            <Link to={'/hak-akses'}>
                                                <h1 className={`${location.pathname === '/hak-akses' ? 'text-[#780000]' : 'text-black'} text-sm text-black hover:text-[#780000] font-[500] mt-5`}>Hak Akses</h1>
                                            </Link>
                                            <Link to={'/punishment'}>
                                                <h1 className={`${location.pathname === '/punishment' ? 'text-[#780000]' : 'text-black'} text-sm text-black hover:text-[#780000] font-[500] mt-5`}>{languages.language === 'id' ? "Hukuman" : "Punishment"}</h1>
                                            </Link>
                                            <Link to={'/helpdesk'}>
                                                <h1 className={`${location.pathname === '/helpdesk' ? 'text-[#780000]' : 'text-black'} text-sm text-black hover:text-[#780000] font-[500] mt-5`}>{languages.language === 'id' ? "Bantuan" : "Helpdesk"}</h1>
                                            </Link>
                                            <Link to={'/device'}>
                                                <h1 className={`${location.pathname === '/device' ? 'text-[#780000]' : 'text-black'} text-sm text-black hover:text-[#780000] font-[500] mt-5`}>{languages.language === 'id' ? "Perangkat" : "Device"}</h1>
                                            </Link>
                                            <Link to={'/system'}>
                                                <h1 className={`${location.pathname === '/system' ? 'text-[#780000]' : 'text-black'} text-sm text-black hover:text-[#780000] font-[500] mt-5`}>{languages.language === 'id' ? "Sistem" : "System"}</h1>
                                            </Link>
                                            <Link to={'/data-wilayah'}>
                                                <h1 className={`${location.pathname === '/data-wilayah' ? 'text-[#780000]' : 'text-black'} text-sm text-black hover:text-[#780000] font-[500] mt-5`}>Data Wilayah</h1>
                                            </Link>
                                        </>
                                        : null
                                }

                            </div>

                            <Link to={'/employee'} className='flex items-center gap-[16px] mb-[40px]'>
                                <img src={IconEmployee} alt='Humanusia Icon' />
                                {display &&
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500]'>Pegawai</h1>
                                }
                            </Link>

                            <button onClick={() => { setTimeManagement(!timeManagement); setConfiguration(false); setAttendance(false); setDisplay(true); setAdmin(false); setEmployee(false) }} className={`flex items-center ${display ? 'justify-between w-full' : 'w-fit'} text-sm ${timeManagement ? 'text-[#780000]' : 'text-black'} hover:text-[#780000] font-[500] gap-[16px] pb-[40px]`}>
                                <div className='flex items-center gap-[16px]'>
                                    <img src={IconTimeManagement} alt='Humanusia Icon' />
                                    {display &&
                                        <h1>Mnj. Waktu</h1>
                                    }
                                </div>
                                {display &&
                                    <RiArrowRightSFill className='text-black text-xl' />
                                }
                            </button>
                            <div className={`${timeManagement ? 'block min-h-[180px]' : 'hidden'} pl-[40px] transition-all ease-in-out duration-500 overflow-auto pb-5 w-full [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300`}>
                                <Link to={'/time-management/attendance'}>
                                    <h1 className={`${location.pathname === '/time-management/attendance' ? 'text-[#780000]' : 'text-black'} text-sm text-black hover:text-[#780000] font-[500]`}>{languages.language === 'id' ? "Kehadiran" : "Attendance"}</h1>

                                </Link>
                                <Link to={'/time-management/time-off'}>
                                    <h1 className={`${location.pathname === '/time-management/time-off' ? 'text-[#780000]' : 'text-black'} text-sm text-black hover:text-[#780000] font-[500] mt-5`}>Cuti, Izin, Lembur</h1>

                                </Link>
                                <Link to={'/time-management/adm-perjalanan-dinas'}>
                                    <h1 className={`${location.pathname === '/time-management/official-work-travel' ? 'text-[#780000]' : 'text-black'} text-sm text-black hover:text-[#780000] font-[500] mt-5`}>{languages.language === 'id' ? "Perjalanan Dinas" : "Official Work Travel"}</h1>
                                </Link>
                                <Link to={'/time-management/calendar'}>
                                    <h1 className={`${location.pathname === '/payroll-setting' ? 'text-[#780000]' : 'text-black'} text-sm text-black hover:text-[#780000] font-[500] mt-5`}>{languages.language === 'id' ? "Kalender" : "Calendar"}</h1>
                                </Link>
                            </div>
                            <Link to={'/payroll/report'} className='flex items-center gap-[16px] mb-[40px]'>
                                <img src={IconPayroll} alt='Humanusia Icon' />
                                {display &&
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500]'>Penghasilan</h1>
                                }
                            </Link>
                            <Link to={'/verifikasi'} className='flex items-center gap-[16px] mb-[40px]'>
                                <img src={IconDocument} alt='Humanusia Icon' />
                                {display &&
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500]'>Verifikasi</h1>
                                }
                            </Link>
                        </div>
                        :
                        <div className={`my-[40px] h-[410px] overflow-auto scrollbar-hide flex flex-col ${display ? 'items-start' : 'items-center'}`}>
                            <Link to={'/dashboard'} className='flex items-center gap-[16px] mb-[40px]'>
                                <img src={IconDashboard} alt='Humanusia Icon' />
                                {display &&
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500]'>Beranda</h1>
                                }
                            </Link>

                            {/* <Link to={'/document'} className='flex items-center gap-[16px] mb-[40px]'>
                                <img src={IconDocument} alt='Humanusia Icon'/>
                                {display &&
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500]'>Document</h1>
                                }
                            </Link> */}

                            <button onClick={() => { setTimeManagement(!timeManagement); setAttendance(false); setDisplay(true); setAdmin(false); setEmployee(false) }} className={`flex items-center ${display ? 'justify-between w-full' : 'w-fit'} text-sm ${timeManagement ? 'text-[#780000]' : 'text-black'} hover:text-[#780000] font-[500] gap-[16px]`}>
                                <div className='flex items-center gap-[16px]'>
                                    <img src={IconTimeManagement} alt='Humanusia Icon' />
                                    {display &&
                                        <h1>Manajemen Waktu</h1>
                                    }
                                </div>
                                {display &&
                                    <RiArrowRightSFill className='text-black text-xl' />
                                }
                            </button>
                            <div className={`${timeManagement ? 'h-[160px]' : 'h-0'} transition-all duration-1000 ease-in-out w-full mb-[40px] pl-[39px] overflow-auto w-full [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300`}>
                                <Link to={'/time-management/rekap-absen'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Rekap Absen</h1>
                                </Link>
                                <Link to={'/time-management/my-attendance'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Kehadiran Saya</h1>
                                </Link>
                                <Link to={'/time-management/time-off'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Cuti, Izin, Lembur</h1>
                                </Link>
                                <Link to={'/time-management/official-work-travel'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Perjalanan Dinas</h1>
                                </Link>
                                <Link to={'/time-management/calendar'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Kalender</h1>
                                </Link>
                            </div>

                            <Link to={'/laporan-kinerja'} className='flex items-center gap-[16px] mb-[40px]'>
                                <img src={IconPerformance} alt='Humanusia Icon' />
                                {display &&
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500]'>Laporan Kinerja</h1>
                                }
                            </Link>
                            {canAccessMenu(hakAkses) ?
                                <Link to={'/verifikasi'} className='flex items-center gap-[16px] mb-[40px]'>
                                    <img src={IconDocument} alt='Humanusia Icon' />
                                    {display &&
                                        <h1 className='text-sm text-black hover:text-[#780000] font-[500]'>Verifikasi</h1>
                                    }
                                </Link>
                                : null
                            }
                        </div>
                    }

                    <div className={`border-t-2 w-full pt-[40px] flex ${display ? 'justify-start' : 'justify-center'}`}>
                        <button onClick={Logout} className='flex items-center gap-[16px]'>
                            <img src={IconLogout} alt='Humanusia Icon' />
                            {display &&
                                <h1 className='text-sm text-[#780000] font-[500]'>Keluar</h1>
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Navbar