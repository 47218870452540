import moment from 'moment';
import React from 'react';

const PembatalanCuti = ({ UserData, Detail, Type, Notes, buttonHide, id, onClickDecline, onClickApprove }) => {
    return (
        <div>
            <div className='bg-[#F8F8F8] rounded-[15px] px-[19px] py-[31px] w-full text-dark-5 text-[11px] font-semibold space-y-[21px]'>

                <div className='border-b-2'>
                    <h1 className='text-dark-2 text-sm font-[500]'>Detail Cuti</h1>
                </div>
                <div className='grid lg:grid-cols-6 md:grid-cols-4 grid-cols-2'>
                    <p>Nama</p>
                    <p className='lg:col-span-5 md:col-span-3'>: {UserData?.fullname ? UserData?.fullname : '-'}</p>
                </div>
                <div className='grid lg:grid-cols-6 md:grid-cols-4 grid-cols-2'>
                    <p>Jenis Cuti</p>
                    <p className='lg:col-span-5 md:col-span-3'>: {Type ? Type : '-'}</p>
                </div>
                <div className='grid lg:grid-cols-6 md:grid-cols-4 grid-cols-2'>
                    <p>Tanggal Pembatalan Cuti</p>
                    <p className='lg:col-span-5 md:col-span-3'>:
                        {Detail && Detail.map((val, index) => (
                            <span> {moment(val.leaveDetail.date).format('DD/MM/YYYY')} {index + 1 === Detail.length ? "" : ","}</span>
                        ))
                        }
                    </p>
                </div>
                <div className='grid lg:grid-cols-6 md:grid-cols-4 grid-cols-2'>
                    <p>Alasan Pembatalan Cuti</p>
                    <p className='lg:col-span-5 md:col-span-3'>: {Notes ? Notes : '-'}</p>
                </div>
            </div>
            {
                buttonHide?.status !== "approved" && buttonHide.status !== "decline" ?
                    buttonHide?.user?.id !== UserData.id ?
                        (
                            <div className='flex items-center justify-end gap-[20px] mt-10'>
                                <button onClick={() => {
                                    onClickDecline();
                                }} className='bg-[#C1121F] text-dark-9 text-[14px] font-[500] rounded-[6px] py-[10px] px-[25px]'>
                                    Ditolak
                                </button>
                                <button onClick={() => {
                                    onClickApprove();
                                }} className='bg-[#04BB00] text-dark-9 text-[14px] font-[500] rounded-[6px] py-[10px] px-[25px]'>
                                    Setujui
                                </button>
                            </div>
                        ) : null
                    // : null
                    : null
            }
        </div>

    )
}

export default PembatalanCuti