import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { AiOutlinePlus } from 'react-icons/ai'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { MdImportExport } from 'react-icons/md'
import { useLocation } from 'react-router-dom'
import Api from '../../../../../Api'
import { ModalDelete, Pagination } from '../../../../../components'

const Dependents = ({ ModalAddDepents, ModalEditDepents, refreshPage }) => {
    const [data, setData] = useState('')
    const [refresh, setRefresh] = useState(false)
    const params = useLocation()
    const [userId, setUserId] = useState()
    const [modalDelete, setModalDelete] = useState()
    const [idDependents, setIdDependents] = useState()

    const limit = 5
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const Fetch = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setUserId(response.data.results.data.id)
        } catch (error) {
            console.log(error)
        }
    }

    const getData = async () => {
        try {
            if (!params.state) {
                const response = await Api.GetDependentsByLogin(localStorage.getItem('hris-token'), currentPage, limit)
                console.log(response, 'dependets')
                setData(response.data.data)
                setCurrentPage(response.data.pagination.page)
                setTotalPages(response.data.pagination.totalPages)
            } else {
                const response = await Api.GetDependentsByParams(localStorage.getItem('hris-token'), params.state.employeeId, currentPage, limit)
                setData(response.data.data)
                setCurrentPage(response.data.pagination.page)
                setTotalPages(response.data.pagination.totalPages)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const deleteDependentsModal = (id) => {
        setModalDelete(!modalDelete)
        setIdDependents(id)
        setRefresh(true)
    }
    const deleteDependents = async (id) => {
        try {
            await Api.DeleteDependents(localStorage.getItem('hris-token'), idDependents).then((res) => {
                setModalDelete(!modalDelete)
                setRefresh(true)
                toast.success('Success Delete')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete')
        }
    }

    useEffect(() => {
        Fetch()
        getData()
        setRefresh(false)
    }, [refresh, refreshPage])

    return (
        <div>
            <ModalDelete
                activeModal={modalDelete}
                buttonClose={() => setModalDelete(!modalDelete)}
                submitButton={deleteDependents}
            />
            <div className='shadow rounded-xl px-[19px] py-[19px]'>
                <div className='flex relative'>
                    <div>
                        <h1 className='text-base-black text-[20px] font-medium'>Tanggungan</h1>
                        <h2 className='text-dark-5 text-[10px] mb-[35px]'>Daftar Tanggungan</h2>
                    </div>
                    <div className='absolute right-0'>
                        <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                            <button onClick={ModalAddDepents} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                <AiOutlinePlus className='text-xl text-white' />
                                <h1 className='text-dark-9 text-sm font-[500]'>Tambah</h1>
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='overflow-auto scrollbar-hide'>
                        <table className='w-full space-y-[10px] text-dark-5'>
                            <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[6px] min-w-[220px] max-w-[220px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500]'>Nama</h1>
                                    <MdImportExport className='text-dark-5 text-xs' />
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500]'>Hubungan</h1>
                                    <MdImportExport className='text-dark-5 text-xs' />
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500]'>Tanggal Lahir</h1>
                                    <MdImportExport className='text-dark-5 text-xs' />
                                </div>

                                <div className='flex items-center justify-center gap-[6px] w-full'>
                                    <h1 className='text-dark-5 text-[11px] font-[500]'>Aksi</h1>
                                    <MdImportExport className='text-dark-5 text-xs' />
                                </div>
                            </div>
                            {Object.values(data).map((item, idx) => (
                                <div key={idx} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='min-w-[220px] max-w-[220px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.name ? item.name : '-'}</h1>
                                    </div>
                                    <div className='min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.relationship ? item.relationship : '-'}</h1>
                                    </div>
                                    <div className='min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.birthday ? moment(item.birthday).format('L') : '-'}</h1>
                                    </div>
                                    <div className='w-full flex items-center justify-center gap-[12px]'>
                                        <button onClick={() => deleteDependentsModal(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <CgTrashEmpty className='text-[#003049]' />
                                        </button>
                                        <button onClick={() => ModalEditDepents(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <HiOutlinePencil className='text-[#003049]' />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </table>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        onPrevChange={handlePrevChange}
                        onNextChange={handleNextChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default Dependents