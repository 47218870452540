import moment from 'moment'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlinePlus } from 'react-icons/ai'
import { BiSearch } from 'react-icons/bi'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { MultiSelect } from 'react-multi-select-component'
import Api from '../../../../../Api'
import { Modal, ModalDelete, Navbar, Pagination } from '../../../../../components'
import InputComponent from '../../../../../components/Input/inputComponent'
import languages from '../../../../../utils/languages'

function PengaturanHariLibur() {
  moment.locale('id');

  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalEdit, setShowModalEdit] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const handleInputChange = (inputName, inputValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [inputName]: inputValue,
    }));
  };
  const [selectedPenempatan, setSelectedPenempatan] = useState([])
  const handleChangePenempatan = (e) => {
    const data = []
    e && e.map(val => (
      data.push(val.value)
    ))
    handleInputChange("companyId", data)
    setSelectedPenempatan(e)
    // setSelectedAssignTo(data)
  }

  const [formData, setFormData] = useState({
    title: "",
    date: "",
    companyId: ""
  });

  // console.log(formData)

  const resetFormData = () => {
    setFormData({
      title: "",
      date: "",
      companyId: ""
    });
    setSelectedAssignTo("")
    setAssignTo("")
    setSelectedPenempatan([])
  };


  const postHariLibur = async () => {
    try {
      const data = formData;
      var dataAssigTo = {}
      if (assignTo === "ALL") {
        dataAssigTo.ALL = true
      } else if (assignTo === "job_grade") {
        dataAssigTo.job_grade = selectedAssignTo
      } else if (assignTo === "job_position") {
        dataAssigTo.job_position = selectedAssignTo
      } else if (assignTo === "job_level") {
        dataAssigTo.job_level = selectedAssignTo
      } else if (assignTo === "job_category") {
        dataAssigTo.job_category = selectedAssignTo
      }
      data.ditujukan_untuk = dataAssigTo
      // console.log(data)
      await Api.PostHariLibur(localStorage.getItem('hris-token'), data).then((response) => {
        // console.log(response)
        resetFormData()
        setRefresh(true)
        toast.success('Sukses Menambahkan Data')
        setShowModalAdd(false)
      }).catch((error) => {
        console.log(error)
        toast.error('Gagal Menambahkan Data')
      })
    } catch (error) {
      console.log(error)
      toast.error('Gagal Menambahkan Data')
    }
  };

  const updateHariLibur = async () => {
    try {
      const data = formData;
      var dataAssigTo = {}
      if (assignTo === "ALL") {
        dataAssigTo.ALL = true
      } else if (assignTo === "job_grade") {
        dataAssigTo.job_grade = selectedAssignTo
      } else if (assignTo === "job_position") {
        dataAssigTo.job_position = selectedAssignTo
      } else if (assignTo === "job_level") {
        dataAssigTo.job_level = selectedAssignTo
      } else if (assignTo === "job_category") {
        dataAssigTo.job_category = selectedAssignTo
      }
      if (assignTo !== "" || selectedAssignTo.length !== 0) {
        data.ditujukan_untuk = dataAssigTo
      }
      // console.log(data)
      await Api.UpdateHariLibur(localStorage.getItem('hris-token'), data, id).then((response) => {
        // console.log(response)
        resetFormData()
        setRefresh(true)
        toast.success('Sukses Edit Data')
        setShowModalEdit(false)
      }).catch((error) => {
        console.log(error)
        toast.error(error.response.data.errors)
        // toast.error('Gagal Menambahkan Data')
      })
    } catch (error) {
      console.log(error)
      toast.error('Gagal Menambahkan Data')
    }
  };

  const [data, setData] = useState([])
  const getDataHariLibur = async () => {
    try {
      const response = await Api.GetHariLibur(localStorage.getItem('hris-token'), currentPage, limit)
      // console.log(response.data.results.data)
      setData(response.data.results.data)
      setCurrentPage(response.data.results.currentPage)
      setTotalPages(response.data.results.totalPages)
      setTotalCount(response.data.results.totalCount)
    } catch (error) {
      console.log(error)
    }
  }
  const getDataHariLiburById = async (id) => {
    setShowModalEdit(true)
    try {
      const response = await Api.GetHariLiburById(localStorage.getItem('hris-token'), id)
      setId(id)
      const res = response.data.results.data
      const company = res.companies && res.companies.map(val => (val.id))
      setFormData({
        title: res.title,
        date: res.date,
        companyId: company
      });
      setAssignTo(res.ditujukan_untuk)
      const options = res.companies && res.companies.map(val => ({
        "value": val.id,
        "label": val.company_name
      }))
      const assign_to = Object.keys(response.data.results.data.ditujukan_untuk);
      if (assign_to[0] === "ALL") {
        setAssignTo(assign_to[0])
      } else if (assign_to[0] === "job_category") {
        setAssignTo(assign_to[0])
        // console.log(response.data.results.data.ditujukan)
        const options = response.data.results.data.ditujukan && response.data.results.data.ditujukan.map(val => ({
          "value": val.id,
          "label": val.name
        }))
        setSelected(options)
      } else if (assign_to[0] === "job_position") {
        setAssignTo(assign_to[0])
        // console.log(response.data.results.data.ditujukan)
        const options = response.data.results.data.ditujukan && response.data.results.data.ditujukan.map(val => ({
          "value": val.id,
          "label": val.name
        }))
        setSelected(options)
      }
      setSelectedPenempatan(options)
    } catch (error) {
      console.log(error)
    }
  }

  const [showModalDelete, setShowModalDelete] = useState(false)
  const [id, setId] = useState('')

  const deleteModal = (id) => {
    setShowModalDelete(!showModalDelete)
    setId(id)
    setRefresh(true)
  }

  const deleteHariLibur = async () => {
    try {
      await Api.DeleteHariLibur(localStorage.getItem('hris-token'), id).then((response) => {
        setRefresh(true)
        setShowModalDelete(!showModalDelete)
        toast.success('Hapus Data Berhasil')
      }).catch((error) => {
        console.log(error)
      })
    } catch (error) {
      console.log(error)
      toast.error('Data Gagal dihapus')
    }
  }


  const [assignTo, setAssignTo] = useState('')
  const [selectedAssignTo, setSelectedAssignTo] = useState([])
  const [selected, setSelected] = useState([])
  const [jobCategory, setJobCategory] = useState([])
  const [jobPosition, setJobPosition] = useState([])

  const getSelectJobCategory = async () => {
    try {
      const res = await Api.GetJobCategoryDropdown(localStorage.getItem('hris-token'))
      const data = res.data.results.data;

      const options = data && data.map(val => ({
        "value": val.id,
        "label": val.category_name
      }))

      setJobCategory(options)
    } catch (error) {
      console.log(error)
    }
  }
  const getSelectJobPosition = async () => {
    try {
      const res = await Api.GetJobPositionDropdown(localStorage.getItem('hris-token'))
      const data = res.data.results.data;

      const options = data && data.map(val => ({
        "value": val.id,
        "label": val.position_name
      }))

      setJobPosition(options)
    } catch (error) {
      console.log(error)
    }
  }
  const handleChange = (e) => {
    const data = []
    e && e.map(val => (
      data.push(val.value)
    ))
    setSelected(e)
    setSelectedAssignTo(data)
  }

  const [dataPenempatan, setDataPenempatan] = useState([])
  const getSubsidiary = async () => {
    try {
      const response = await Api.GetSubsidiaryDropdown(localStorage.getItem('hris-token'))
      // console.log("subsidiary", response.data.results.data)
      const data = response.data.results.data
      const options = data && data.map(val => ({
        "value": val.id,
        "label": val.company_name
      }))
      setDataPenempatan(options)
    } catch (error) {
      console.log(error)
    }
  }
  const limit = 10

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState('')
  const [totalCount, setTotalCount] = useState(0)

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setRefresh(true)
  };

  const handlePrevChange = () => {
    if (currentPage === 1) {
      setCurrentPage(1)
    } else {
      setCurrentPage(currentPage - 1);
    }
    setRefresh(true)
  };

  const handleNextChange = () => {
    if (currentPage === totalPages) {
      setCurrentPage(totalPages)
    } else {
      setCurrentPage(currentPage + 1);
    }
    setRefresh(true)
  };
  useEffect(() => {
    getDataHariLibur()
    setRefresh(false)
  }, [refresh])

  useEffect(() => {
    getSelectJobCategory()
    getSelectJobPosition()
    getSubsidiary()
  }, [])
  return (
    <div className='bg-[#F8F9FB] min-h-screen'>
      <div className='relative'>
        <Modal
          activeModal={showModalAdd}
          title={"Tambah Hari Libur"}
          buttonClose={() => { setShowModalAdd(!showModalAdd); resetFormData() }}
          width={'550px'}
          content={
            <div>

              <div className="grid grid-cols-2 gap-x-5 gap-y-2 pb-[37px]">
                <div className="col-span-2">
                  <InputComponent
                    disabled={false}
                    label={"Nama Hari Libur"}
                    value={formData.title}
                    onChange={(e) => handleInputChange("title", e)}
                    required={true}
                    type={"text"}
                  />
                </div>
                <div className="col-span-2">
                  <InputComponent
                    disabled={false}
                    label={"Tanggal Hari Libur"}
                    value={formData.date}
                    onChange={(e) => handleInputChange("date", e)}
                    required={true}
                    type={"date"}
                  />
                </div>
                <div className="col-span-2">
                  <h2 className="text-grey-thick text-[12px] font-medium mb-[9px]">
                    Penempatan <span className="text-red-primer">*</span>
                  </h2>
                  {/* <div className="relative">
                    <select
                      value={formData.companyId}
                      onChange={(e) => handleInputChange("companyId", e.target.value)}
                      className="bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none"
                    >
                      <option selected disabled value={""}>
                        Pilih Penempatan
                      </option>
                      {dataPenempatan && dataPenempatan.map((val, index) => (
                        <option key={index} value={val.id}>{val.company_name}</option>
                      ))}
                    </select>
                    <IoMdArrowDropdown className="absolute top-[10px] right-3 text-[#2E2E2E ] text-xl" />
                  </div> */}
                  <MultiSelect
                    className="basic-single"
                    classNamePrefix="select"
                    value={selectedPenempatan}
                    options={dataPenempatan}
                    onChange={(e) => handleChangePenempatan(e)}
                  />
                </div>
                <div>
                  <h1 className='text-grey-thick text-[11px] font-medium mb-[8px]'>Ditujukan Untuk <span className='text-red-primer'>*</span></h1>
                  <div className='space-y-[16px]'>
                    <div className='flex items-center gap-[14px] font-medium'>
                      <input type="radio" checked={assignTo === "ALL"} id="all" name="radio-option" value='ALL' onChange={(e) => setAssignTo(e.target.value)} />
                      <label htmlFor="all" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Semua Pegawai" : "All Employee"}</label>
                    </div>
                    {/* <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "ALL"} id="search" name="radio-option" value='' onChange={(e)=>setAssignTo(e.target.value)} />
                                    <label htmlFor="search" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Cari Pegawai" : "Search Employee"}</label>
                                </div> */}
                    <div className='flex items-center gap-[14px] font-medium'>
                      <input type="radio" checked={assignTo === "job_category"} id="jenisKepegawaian" name="radio-option" value='job_category' onChange={(e) => setAssignTo(e.target.value)} />
                      <label htmlFor="jenisKepegawaian" className='text-grey-thick font-medium text-xs'>Berdasarkan Jenis Kepegawaian</label>
                    </div>
                    {
                      assignTo === "job_category" ?
                        <MultiSelect
                          options={jobCategory}
                          value={selected}
                          onChange={(e) => handleChange(e)}
                          labelledBy="Select"
                        />
                        : null
                    }
                    <div className='flex items-center gap-[14px] font-medium'>
                      <input type="radio" checked={assignTo === "job_position"} id="jabatan" name="radio-option" value='job_position' onChange={(e) => setAssignTo(e.target.value)} />
                      <label htmlFor="jabatan" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jabatan" : "Based on Job Position"}</label>
                    </div>
                    {
                      assignTo === "job_position" ?
                        <MultiSelect
                          options={jobPosition}
                          value={selected}
                          onChange={(e) => handleChange(e)}
                          labelledBy="Select"
                        />
                        : null
                    }
                  </div>
                </div>
              </div>

              <div className='relative pb-[37px]'>
                <div className="flex gap-[10px] absolute right-0">
                  <button onClick={() => { setShowModalAdd(!showModalAdd); resetFormData() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                    <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                  </button>
                  <button onClick={postHariLibur} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                    <h1 className='text-dark-9 text-sm font-[500]'>Tambah</h1>
                  </button>
                </div>
              </div>
            </div>
          }
        />
        <Modal
          activeModal={showModalEdit}
          title={"Tambah Hari Libur"}
          buttonClose={() => { setShowModalEdit(!showModalEdit); resetFormData() }}
          width={'550px'}
          content={
            <div>

              <div className="grid grid-cols-2 gap-x-5 gap-y-2 pb-[37px]">
                <div className="col-span-2">
                  <InputComponent
                    disabled={false}
                    label={"Jam Normal"}
                    value={formData.title}
                    onChange={(e) => handleInputChange("title", e)}
                    required={true}
                    type={"text"}
                  />
                </div>
                <div className="col-span-2">
                  <InputComponent
                    disabled={false}
                    label={"Jam Normal"}
                    value={formData.date}
                    onChange={(e) => handleInputChange("date", e)}
                    required={true}
                    type={"date"}
                  />
                </div>
                <div className="col-span-2">
                  <h2 className="text-grey-thick text-[12px] font-medium mb-[9px]">
                    Penempatan <span className="text-red-primer">*</span>
                  </h2>
                  {/* <div className="relative">
                    <select
                      value={formData.companyId}
                      onChange={(e) => handleInputChange("companyId", e.target.value)}
                      className="bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none"
                    >
                      <option selected disabled value={""}>
                        Pilih Penempatan
                      </option>
                      {dataPenempatan && dataPenempatan.map((val, index) => (
                        <option key={index} value={val.id}>{val.company_name}</option>
                      ))}
                    </select>
                    <IoMdArrowDropdown className="absolute top-[10px] right-3 text-[#2E2E2E ] text-xl" />
                  </div> */}
                  <MultiSelect
                    className="basic-single"
                    classNamePrefix="select"
                    value={selectedPenempatan}
                    options={dataPenempatan}
                    onChange={(e) => handleChangePenempatan(e)}
                  />
                </div>
                <div>
                  <h1 className='text-grey-thick text-[11px] font-medium mb-[8px]'>Ditujukan Untuk <span className='text-red-primer'>*</span></h1>
                  <div className='space-y-[16px]'>
                    <div className='flex items-center gap-[14px] font-medium'>
                      <input type="radio" checked={assignTo === "ALL"} id="allEdit" name="radio-option" value='ALL' onChange={(e) => setAssignTo(e.target.value)} />
                      <label htmlFor="allEdit" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Semua Pegawai" : "All Employee"}</label>
                    </div>
                    {/* <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" id="search" name="radio-option" value='' onChange={(e)=>setAssignTo(e.target.value)} />
                                    <label htmlFor="search" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Cari Pegawai" : "Search Employee"}</label>
                                </div> */}
                    <div className='flex items-center gap-[14px] font-medium'>
                      <input type="radio" checked={assignTo === "job_category"} id="jenisKepegawaianEdit" name="radio-option" value='job_category' onChange={(e) => setAssignTo(e.target.value)} />
                      <label htmlFor="jenisKepegawaianEdit" className='text-grey-thick font-medium text-xs'>Berdasarkan Jenis Kepegawaian</label>
                    </div>
                    {
                      assignTo === "job_category" ?
                        <MultiSelect
                          options={jobCategory}
                          value={selected}
                          onChange={(e) => handleChange(e)}
                          labelledBy="Select"
                        />
                        : null
                    }
                    <div className='flex items-center gap-[14px] font-medium'>
                      <input type="radio" checked={assignTo === "job_position"} id="jabatanEdit" name="radio-option" value='job_position' onChange={(e) => setAssignTo(e.target.value)} />
                      <label htmlFor="jabatanEdit" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jabatan" : "Based on Job Position"}</label>
                    </div>
                    {
                      assignTo === "job_position" ?
                        <MultiSelect
                          options={jobPosition}
                          value={selected}
                          onChange={(e) => handleChange(e)}
                          labelledBy="Select"
                        />
                        : null
                    }
                  </div>
                </div>
              </div>

              <div className='relative pb-[37px]'>
                <div className="flex gap-[10px] absolute right-0">
                  <button onClick={() => { setShowModalEdit(!showModalEdit); resetFormData() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                    <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                  </button>
                  <button onClick={updateHariLibur} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                  </button>
                </div>
              </div>
            </div>
          }
        />

        <ModalDelete
          activeModal={showModalDelete}
          buttonClose={() => setShowModalDelete(!showModalDelete)}
          submitButton={deleteHariLibur}
        />
      </div>
      <div className='w-full space-y-[24px] overflow-hidden'>
        <Navbar SubNavbar={true} NameSubNavbar={"Pengaturan Hari Libur"} LinkBack={'/time-management-setting'} />
        <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
          <div className="py-5 space-y-5 bg-white">
            <div>
              <h1 className='text-xl font-semibold text-grey-primer'>Pengaturan Hari Libur</h1>
              <h2 className='text-xs text-grey-medium'>Daftar Hari Libur</h2>
            </div>
            <div className="flex flex-wrap items-center justify-between gap-[12px] mb-[36px]">
              {/* <button className=' gap-[10px] flex items-center py-[7px] px-[8px] text-blue-thick font-medium border rounded-lg'>
                <CgTrashEmpty className='text-lg' />
                <h1>{languages.language === 'id' ? "Hapus" : "Delete"}</h1>
              </button> */}
              <button onClick={() => setShowModalAdd(true)} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                <AiOutlinePlus className='text-xl text-white' />
                <h1 className='text-dark-9 text-sm font-[500]'>Tambah Hari Libur</h1>
              </button>
              <div className='relative py-[12px] pl-[40px] pr-[13px] border rounded-lg flex text-[#8E95A2] font-medium w-[300px]'>
                <BiSearch className='absolute left-[12px] text-lg' />
                <input type="text" placeholder="Cari..." className='outline-none text-[10px] w-full' />
              </div>
            </div>
            <div>
              <div className='overflow-auto'>
                <table className='w-full space-y-[10px]'>
                  <div className='flex justify-between items-start gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                    <div className='flex items-start gap-[6px] w-[100px]'>
                      <h1 className='text-dark-5 text-[11px] font-[500]'>No</h1>
                    </div>
                    <div className='flex items-start gap-[6px] w-full'>
                      <h1 className='text-dark-5 text-[11px] font-[500]'>Nama Hari Libur</h1>
                    </div>
                    <div className='flex items-start gap-[6px] w-full'>
                      <h1 className='text-dark-5 text-[11px] font-[500]'>Tanggal</h1>
                    </div>
                    {/* <div className='flex items-start gap-[6px] w-full'>
                      <h1 className='text-dark-5 text-[11px] font-[500]'>Penempatan</h1>
                    </div> */}

                    <div className='flex items-start justify-center gap-[6px] w-[200px]'>
                      <h1 className='text-dark-5 text-[11px] font-[500]'>Aksi</h1>
                    </div>
                  </div>
                  {data.map((val, index) => (
                    <div key={index} className='flex justify-between items-start gap-2 px-[14px] py-[8px] rounded-[3px]'>
                      <div className='w-[100px]'>
                        <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{index + 1}.</h1>
                      </div>
                      <div className='w-full'>
                        <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{val.title}</h1>
                      </div>
                      <div className='w-full'>
                        <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{moment(val?.date).format("DD MMMM YYYY")}</h1>
                      </div>
                      {/* <div className='w-full flex flex-wrap'>
                        {val?.companies?.length === dataPenempatan?.length ?
                          <h1 className='text-dark-5 text-[11px] font-[500] truncate'>Seluruh Penempatan</h1>
                          : val?.companies && val?.companies.map((comp, idx) => (
                            <h1 key={idx} className='text-dark-5 text-[11px] font-[500] truncate'>{comp.company_name}{val?.companies?.length - (idx + 1) !== 0 ? "," : ""}</h1>
                          ))
                        }
                      </div> */}
                      <div className='flex items-start justify-center gap-[12px] w-[200px]'>
                        <button onClick={() => getDataHariLiburById(val.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                          <HiOutlinePencil className='text-[#003049]' />
                        </button>
                        <button onClick={() => deleteModal(val.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                          <CgTrashEmpty className='text-[#003049]' />
                        </button>
                      </div>
                    </div>
                  ))}

                </table>
              </div>
              <Pagination
                limitData={data?.length}
                lengthData={totalCount}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                onPrevChange={handlePrevChange}
                onNextChange={handleNextChange}
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default PengaturanHariLibur