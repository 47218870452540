import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { CgTrashEmpty } from 'react-icons/cg';
import Api from '../../../../Api';
import languages from '../../../../utils/languages';
import { Navbar } from '../../../../components';

const Kinerja = () => {
    const [evaluationNames, setEvaluationNames] = useState([]);
    const [evaluationNameId, setEvaluationNameId] = useState('');
    const [settingId, setSettingId] = useState('');
    const [pointId, setPointId] = useState('');

    useEffect(() => {
        if (evaluationNames.length === 0) {
            generateId('evaluation')
        }
        getPerformanceSetting()
    }, []);

    const getPerformanceSetting = async () => {
        try {
            await Api.GetPerformanceSetting(localStorage.getItem('hris-token')).then((response) => {
                const data = response.data.results
                setEvaluationNames(data)
            })
        } catch (error) {
            console.log(error)
        }
    }
    const generateId = async (param) => {
        try {
            if (param === 'evaluation') {
                let id = []
                for (let i = 0; i < 3; i++) {
                    const response = await Api.GenerateId(localStorage.getItem('hris-token'));
                    id.push(response.data.results.id);
                }
                setEvaluationNameId(id[0]);
                setSettingId(id[1]);
                setPointId(id[2]);
            } else if (param === 'setting') {
                let id = []
                for (let i = 0; i < 2; i++) {
                    const response = await Api.GenerateId(localStorage.getItem('hris-token'));
                    id.push(response.data.results.id);
                }
                setSettingId(id[0]);
                setPointId(id[1]);
            } else if (param === 'point') {
                const response = await Api.GenerateId(localStorage.getItem('hris-token'));
                setPointId(response.data.results.id);
            }
        } catch (error) {
            console.log(error)
        }
    }
    const AddEvaluationName = () => {
        generateId('evaluation')
        const newEvaluationNames = [
            ...evaluationNames,
            { id: evaluationNameId, evaluation_name: '', settings: [{ id: settingId, setting_name: '', points: [{ id: pointId, point_name: '' }] }] },
        ];
        setEvaluationNames(newEvaluationNames);
    };

    const addSettings = (evaluationNameIndex) => {
        generateId('setting')
        const newEvaluationNames = [...evaluationNames];
        newEvaluationNames[evaluationNameIndex].settings.push({
            id: settingId,
            setting_name: '',
            points: [{ id: pointId, point_name: '' }],
        });
        setEvaluationNames(newEvaluationNames);
    };

    const addPoints = (evaluationNameIndex, pointsIndex) => {
        generateId('point')
        const newEvaluationNames = [...evaluationNames];
        newEvaluationNames[evaluationNameIndex].settings[pointsIndex].points.push({ id: pointId, point_name: '' });
        setEvaluationNames(newEvaluationNames);
    };

    const deleteEvaluationNames = async (evaluationNamesIndex, id) => {
        try {
            await Api.DeletePerformanceSetting(localStorage.getItem('hris-token'), id)
                .then((response) => {
                    const newEvaluationNames = [...evaluationNames];
                    newEvaluationNames.splice(evaluationNamesIndex, 1);
                    setEvaluationNames(newEvaluationNames);
                    toast.success('Sukses Menghapus Data')
                }).catch((error) => {
                    console.log(error)
                    toast.error('Gagal Menghapus Data')
                })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menghapus Data')

        };
    }

    const deleteSettings = async (evaluationNamesIndex, settingsIndex, id) => {
        try {
            await Api.DeletePerformanceSetting(localStorage.getItem('hris-token'), id)
                .then((response) => {
                    const newEvaluationNames = [...evaluationNames];
                    newEvaluationNames[evaluationNamesIndex].settings.splice(settingsIndex, 1);
                    setEvaluationNames(newEvaluationNames);
                    toast.success('Sukses Menghapus Data')
                }).catch((error) => {
                    console.log(error)
                    toast.error('Gagal Menghapus Data')
                })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menghapus Data')
        }
    };

    const deletePoint = async (evaluationNamesIndex, settingsIndex, subPointsIndex, id) => {
        try {
            await Api.DeletePerformanceSetting(localStorage.getItem('hris-token'), id)
                .then((response) => {
                    const newEvaleationNames = [...evaluationNames];
                    newEvaleationNames[evaluationNamesIndex].settings[settingsIndex].points.splice(
                        subPointsIndex,
                        1
                    );
                    setEvaluationNames(newEvaleationNames);
                    toast.success('Sukses Menghapus Data')
                }).catch((error) => {
                    console.log(error)
                    toast.error('Gagal Menghapus Data')
                })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menghapus Data')
        }
    };

    const simpanData = async () => {
        try {
            const data = {
                "entries": evaluationNames
            }
            // console.log(data)
            await Api.PostPerformanceSetting(localStorage.getItem('hris-token'), data)
                .then((response) => {
                    // console.log(response)
                    toast.success('Sukses Menambahkan Data')
                }).catch((error) => {
                    console.log(error)
                    toast.error('Gagal Menambahkan Data')
                })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data')
        }
    };

    return (

        <div className='bg-[#F8F9FB] min-h-screen'>
        <div className='w-full space-y-[24px] overflow-hidden'>
            <Navbar SubNavbar={true} NameSubNavbar={'Pengaturan Perilaku Kerja'} LinkBack={'/performance-setting'} />
            <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                <div className='space-y-[24px] rounded-[12px]'>
                    {/* Render komponen JenisPenilaian dan PoinPenilaian */}
                    {evaluationNames && evaluationNames.map((evaluationName, evaluationNamesIndex) => (
                        <div key={evaluationNamesIndex} className='rounded-xl border shadow p-[24px]'>
                            <div className='mb-[14px]'>
                                <h1 className="text-zinc-700 text-sm font-semibold mb-[12px]">{languages.language === 'id' ? "Penilaian Kinerja " : "Performance Evaluation 1"}</h1>
                                <div className='flex items-center gap-[12px]'>
                                    <input type="text"
                                        value={evaluationName.evaluation_name}
                                        onChange={(e) => {
                                            const newEvaluationNames = [...evaluationNames];
                                            newEvaluationNames[evaluationNamesIndex].evaluation_name = e.target.value;
                                            setEvaluationNames(newEvaluationNames);
                                        }} className='text-gray-500 text-xs font-normal px-[18px] py-[10px] border rounded-md w-full' />
                                    <button onClick={() => deleteEvaluationNames(evaluationNamesIndex, evaluationName.id)} className='p-[12px] bg-[#FFE1E1] rounded-[9px] flex items-center justify-center'>
                                        <CgTrashEmpty className='text-[#780000]' />
                                    </button>
                                </div>
                            </div>

                            {/* Performance Setting Name Repeater */}
                            {evaluationName.settings.map((setting, settingIndex) => (
                                <div key={settingIndex} className='border shadow rounded-xl px-[27px] py-[20px] mb-[24px]'>
                                    <h1 className="text-zinc-700 text-sm font-semibold">{languages.language === 'id' ? "Nama Penilaian Kinerja" : "Performance Setting Name "}{settingIndex + 1}</h1>
                                    <h1 className="text-gray-400 text-xs mb-[14px]">{languages.language === 'id' ? "Ini akan ditampilkan sebagai nama penilaian kinerja karyawan" : "This will be displayed as the name of the employee's performance appraisal from the title"}</h1>
                                    <div className='flex items-center gap-[12px] mb-[19px]'>
                                        <input type="text" value={setting.setting_name}
                                            onChange={(e) => {
                                                const newEvaluationNames = [...evaluationNames];
                                                newEvaluationNames[evaluationNamesIndex].settings[
                                                    settingIndex
                                                ].setting_name = e.target.value;
                                                setEvaluationNames(newEvaluationNames);
                                            }} className='text-gray-500 text-xs font-normal px-[18px] py-[10px] border rounded-md w-full' />
                                        <button onClick={() => deleteSettings(evaluationNamesIndex, settingIndex, setting.id)} className='p-[12px] bg-[#FFE1E1] rounded-[9px] flex items-center justify-center'>
                                            <CgTrashEmpty className='text-[#4b2d2d]' />
                                        </button>
                                    </div>
                                    <div>
                                        <h1 className="text-zinc-700 text-sm font-semibold">{languages.language === 'id' ? "Poin Penilaian Kinerja" : "Performance Setting Point"}</h1>
                                        <h1 className="text-gray-400 text-xs mb-[12px]">{languages.language === 'id' ? "Ini akan ditampilkan sebagai poin penilaian kinerja karyawan dari penilaian kinerja" : "This will be displayed as the point of the employee's performance appraisal from the name setting"}</h1>
                                        {/* Performance Setting Point Repeater */}
                                        {setting.points.map((point, pointIndex) => (
                                            <div key={pointIndex} className='flex items-center gap-[12px] mb-[19px]'>
                                                <input type="text"
                                                    value={point.point_name}
                                                    onChange={(e) => {
                                                        const newEvaluationNames = [...evaluationNames];
                                                        newEvaluationNames[evaluationNamesIndex].settings[
                                                            settingIndex
                                                        ].points[pointIndex].point_name = e.target.value;
                                                        setEvaluationNames(newEvaluationNames);
                                                    }}
                                                    className='text-gray-500 text-xs font-normal px-[18px] py-[10px] border rounded-md w-full' />
                                                <button onClick={() =>
                                                    deletePoint(evaluationNamesIndex, settingIndex, pointIndex, point.id)
                                                } className='p-[12px] bg-[#FFE1E1] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-[#4b2d2d]' />
                                                </button>
                                            </div>
                                        ))}
                                        <button onClick={() => addPoints(evaluationNamesIndex, settingIndex)} className='flex items-center text-zinc-800 text-xs gap-[10px]'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                <path d="M12 2.14844C6.49 2.14844 2 6.63844 2 12.1484C2 17.6584 6.49 22.1484 12 22.1484C17.51 22.1484 22 17.6584 22 12.1484C22 6.63844 17.51 2.14844 12 2.14844ZM16 12.8984H12.75V16.1484C12.75 16.5584 12.41 16.8984 12 16.8984C11.59 16.8984 11.25 16.5584 11.25 16.1484V12.8984H8C7.59 12.8984 7.25 12.5584 7.25 12.1484C7.25 11.7384 7.59 11.3984 8 11.3984H11.25V8.14844C11.25 7.73844 11.59 7.39844 12 7.39844C12.41 7.39844 12.75 7.73844 12.75 8.14844V11.3984H16C16.41 11.3984 16.75 11.7384 16.75 12.1484C16.75 12.5584 16.41 12.8984 16 12.8984Z" fill="#113B61" />
                                            </svg>
                                            <h1 className="text-zinc-800 text-xs font-normal">{languages.language === 'id' ? "Tambah Poin" : "Add point"}</h1>
                                        </button>
                                    </div>


                                </div>
                            ))}

                            <button onClick={() => addSettings(evaluationNamesIndex)} className='flex items-center text-zinc-800 text-xs gap-[10px]'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                    <path d="M12 2.14844C6.49 2.14844 2 6.63844 2 12.1484C2 17.6584 6.49 22.1484 12 22.1484C17.51 22.1484 22 17.6584 22 12.1484C22 6.63844 17.51 2.14844 12 2.14844ZM16 12.8984H12.75V16.1484C12.75 16.5584 12.41 16.8984 12 16.8984C11.59 16.8984 11.25 16.5584 11.25 16.1484V12.8984H8C7.59 12.8984 7.25 12.5584 7.25 12.1484C7.25 11.7384 7.59 11.3984 8 11.3984H11.25V8.14844C11.25 7.73844 11.59 7.39844 12 7.39844C12.41 7.39844 12.75 7.73844 12.75 8.14844V11.3984H16C16.41 11.3984 16.75 11.7384 16.75 12.1484C16.75 12.5584 16.41 12.8984 16 12.8984Z" fill="#113B61" />
                                </svg>
                                <h1 className="text-zinc-800 text-xs font-normal">{languages.language === 'id' ? "Tambah Nama Penilaian Kinerja" : "Add Performance Setting Name"}</h1>
                            </button>
                        </div>
                    ))}


                    <div className='flex items-center justify-end '>
                        <button onClick={AddEvaluationName} className='flex items-center text-zinc-800 text-xs gap-[10px] rounded-xl border border-red-thick py-[6px] px-[10px]'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16 12.75H12.75V16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16V12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H11.25V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z" fill="#7E0000" />
                            </svg>
                            <h1 className="text-red-thick text-xs font-normal">{languages.language === 'id' ? "Tambah Penilaian Kinerja" : "Add Performance Evaluation"}</h1>
                        </button>
                    </div>
                    <div className='flex items-center justify-end '>
                        <button onClick={simpanData} className='flex items-center bg-blue-primer text-white text-sm gap-[10px] rounded-lg py-[6px] px-[10px]'>Simpan</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Kinerja