import moment from 'moment'
import 'moment/locale/id'
import React, { useEffect, useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { MdImportExport } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import Api from '../../../../Api'
import { Pagination } from '../../../../components'
import languages from '../../../../utils/languages'

const SasaranKinerjaBawahan = () => {
    moment.locale('id');

    const navigate = useNavigate()
    const [dataSKP, setDataSKP] = useState([])

    const getSKP = async () => {
        try {
            await Api.GetSKP(localStorage.getItem('hris-token'), page, limit).then((response) => {
                setDataSKP(response.data.dataSkp)
                setTotalPages(response.data.totalPages)
                setTotalCount(response.data.totalCount)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const limit = 10
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState()
    const [totalCount, setTotalCount] = useState()
    const [refresh, setRefresh] = useState(false)

    const handlePageChange = (val) => {
        setPage(val);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (page === 1) {
            setPage(1)
        } else {
            setPage(page - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (page === 10) {
            setPage(10)
        } else {
            setPage(page + 1);
        }
        setRefresh(true)
    };

    const [idUser, setIdUser] = useState('')
    const fetchUser = async () => {
        try {
            await Api.Fetch(localStorage.getItem('hris-token')).then((response) => {
                setIdUser(response.data.results.user.id)
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getSKP()
        fetchUser()
        setRefresh(false)
    }, [refresh])
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] p-5 overflow-hidden'>
                {/* <h1 className="text-zinc-800 text-xl font-semibold">Performance</h1> */}
                <div className='w-full lg:w-auto mb-[20px]'>
                    <h1 className='text-dark-3 text-[20px] font-[600]'>{languages.language === 'id' ? "Laporan Kinerja Asisten" : "Employee Performance"}</h1>
                    <h1 className='text-[#A8A8A8] text-xs font-[400]'>{languages.language === 'id' ? "Daftar Laporan Kinerja Asisten" : "List performance of  employee in the company"}</h1>
                </div>
                <div className='flex lg:flex-row items-center justify-end  '>
                    {/* <div>
                        <button className=' gap-[10px] flex items-center py-[7px] px-[8px] text-blue-thick font-medium border rounded-lg'>
                            <BiExport className='text-lg' />
                            <h1>{languages.language === 'id' ? "expor" : "Export"}</h1>
                        </button>
                    </div> */}
                    <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                        <div className='relative py-[12px] pl-[40px] pr-[13px] border rounded-lg flex text-[#8E95A2] font-medium w-[300px]'>
                            <BiSearch className='absolute left-[12px] text-lg' />
                            <input type="text" placeholder='Cari...' className='outline-none text-[10px] w-full' />
                        </div>
                    </div>
                </div>
                <div className='mt-[44px] overflow-auto scrollbar-hide'>
                    <table className='w-full space-y-[10px]'>
                        <div className='flex items-center gap-7 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                            <div className='flex items-center gap-[15px] min-w-[220px] max-w-[220px]'>
                                <h1 className='text-dark-5 text-xs font-[600]'>{languages.language === 'id' ? "Pegawai" : "Employee"}</h1>
                                <MdImportExport className='text-dark-5 text-xs' />
                            </div>
                            <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-dark-5 text-xs font-[600]'>{languages.language === 'id' ? "Pendekatan" : "Approach"}</h1>
                                <MdImportExport className='text-dark-5 text-xs' />
                            </div>
                            <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-dark-5 text-xs font-[600]'>{languages.language === 'id' ? "Penempatan" : "Subsidiary"}</h1>
                                <MdImportExport className='text-dark-5 text-xs' />
                            </div>
                            {/* <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-dark-5 text-xs font-[600]'>{languages.language === 'id' ? "Status Pegawai" : "Employee status"}</h1>
                                        <MdImportExport className='text-dark-5 text-xs' />
                                    </div> */}
                            <div className='flex items-center justify-center w-full'>
                                <h1 className='text-dark-5 text-xs font-[600]'>Status</h1>
                                <MdImportExport className='text-dark-5 text-xs' />
                            </div>
                            <div className='flex items-center justify-center gap-[15px] w-full'>
                                <h1 className='text-dark-5 text-xs font-[600]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                            </div>
                        </div>
                        {
                            dataSKP && dataSKP.map((val, index) => {
                                if (val.userId !== idUser) {
                                    return (
                                        <div key={index} className='flex items-center gap-7 px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='min-w-[220px] max-w-[220px]'>
                                                <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>{val?.user?.fullname}</h1>
                                                <div className='px-[8px] py-[2px] bg-red-thick text-white text-[8px] flex items-center justify-center rounded-lg'>Period {moment(val?.start_periode).format("D MMMM YYYY")} - {moment(val?.end_periode).format("D MMMM YYYY")}</div>
                                            </div>
                                            <div className='min-w-[150px] max-w-[150px]'>
                                                <h1 className={`capitalize text-xs font-[600] line-clamp-1`}>{val?.approach_type}</h1>
                                            </div>
                                            <div className='min-w-[150px] max-w-[150px]'>
                                                <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>{val?.user?.company?.company_name}</h1>
                                            </div>
                                            {/* <div className='min-w-[150px] max-w-[150px]'>
                                                    <h1 className='text-blue-primer text-xs font-[600] line-clamp-1'>Full Time Employee</h1>
                                                </div> */}
                                            <div className='flex items-center justify-start w-full'>
                                                <div className={`py-[12px] px-[12px] text-grey-thick text-xs font-semibold rounded-lg capitalize ${val?.status === 'Ditolak' ? 'bg-[#FF9F9F]' : val?.status === 'draft' ? 'bg-[#CBEAFF]' : val?.status === "Sedang Ditinjau" ? 'bg-[#FFED86]' : val?.status === 'Disetujui' ? 'bg-[#7EFFB2]' : val?.status === 'Penilaian' ? 'bg-[#FFDDCB]' : val?.status === 'Selesai' ? 'bg-[#CFCBFF]' : null}`}>{val?.status}</div>
                                            </div>
                                            <div className='flex items-center justify-center w-full gap-[12px]'>
                                                <button onClick={() => navigate('/laporan-kinerja/detail-skp', {
                                                    state: { id: val?.id }
                                                })} className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                        <path d="M15.2331 8.11744C15.5681 8.55558 15.5681 9.14706 15.2331 9.58448C14.178 10.9618 11.6468 13.7976 8.69149 13.7976C5.73621 13.7976 3.20493 10.9618 2.14987 9.58448C1.9869 9.3747 1.89844 9.11661 1.89844 8.85096C1.89844 8.58531 1.9869 8.32723 2.14987 8.11744C3.20493 6.74015 5.73621 3.9043 8.69149 3.9043C11.6468 3.9043 14.178 6.74015 15.2331 8.11744V8.11744Z" stroke="#003049" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M8.69129 10.9705C9.86213 10.9705 10.8113 10.0213 10.8113 8.85047C10.8113 7.67963 9.86213 6.73047 8.69129 6.73047C7.52045 6.73047 6.57129 7.67963 6.57129 8.85047C6.57129 10.0213 7.52045 10.9705 8.69129 10.9705Z" stroke="#003049" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </table>
                </div>

                <Pagination
                    currentPage={page}
                    totalPages={totalPages}
                    lengthData={totalCount}
                    limitData={limit}
                    onPageChange={handlePageChange}
                    onPrevChange={handlePrevChange}
                    onNextChange={handleNextChange}
                />
            </div>
        </div>
    )
}

export default SasaranKinerjaBawahan