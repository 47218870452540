import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import Api from '../../../../Api'
import { Pagination } from '../../../../components'
import imageHandle from '../../../../utils/imageHandle'
import languages from '../../../../utils/languages'

const PerjalananDinas = ({ startDate, endDate, exportData, name, isAsc, limitData }) => {
  const [data, setData] = useState([])

  const [refresh, setRefresh] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState('')
  const [totalCount, setTotalCount] = useState('')

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setRefresh(true)
  };

  const handlePrevChange = () => {
    if (currentPage === 1) {
      setCurrentPage(1)
    } else {
      setCurrentPage(currentPage - 1);
    }
    setRefresh(true)
  };

  const handleNextChange = () => {
    if (currentPage === totalPages) {
      setCurrentPage(totalPages)
    } else {
      setCurrentPage(currentPage + 1);
    }
    setRefresh(true)
  };
  const getDataPerjalananDinas = async () => {
    try {
      const response = await Api.GetPerjalananDinas(localStorage.getItem('hris-token'), currentPage, limitData, startDate, endDate, name, isAsc)
      setData(response.data.results.workTravels)
      setCurrentPage(response.data.results.currentPage)
      setTotalPages(response.data.results.totalPages)
      setTotalCount(response.data.results.totalCount)
      exportData(response.data.results.totalCount)
    } catch (error) {
      console.log(error)
    }
  }
  // openIndex
  const [Current, setCurrent] = useState(0)
  const [Active, setActive] = useState(false)
  const handleCollapse = (id) => {
    if (id) {
      setCurrent(id)
      setActive(!Active)
    }
  }
  useEffect(() => {
    getDataPerjalananDinas()
    setRefresh(false)
  }, [refresh, startDate, endDate, name, isAsc, limitData])
  return (
    <>
      <div>
        <div className='overflow-auto'>
          <table className='w-full space-y-[10px]'>
            <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
              <div className='flex items-center justify-start gap-[6px] min-w-[50px] max-w-[50px]'>
                <h1 className='text-dark-5 text-[11px] font-[500]'>No.</h1>
              </div>
              <div className='flex items-center justify-start gap-[6px] min-w-[250px] max-w-[250px]'>
                <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Nama Pegawai" : "Employee Name"}</h1>
              </div>
              <div className='flex items-center justify-start gap-[6px] min-w-[250px] max-w-[250px]'>
                <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Jabatan" : "Job Position"}</h1>
              </div>
              <div className='flex items-center justify-start gap-[6px] min-w-[200px] max-w-[200px]'>
                <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Jenis Perjalanan" : "Name of Trip"}</h1>
              </div>
              <div className='flex items-center justify-start gap-[6px] min-w-[100px] max-w-[100px]'>
                <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Durasi" : "Duration"}</h1>
              </div>
              <div className='flex items-center justify-start gap-[6px] w-full'>
                <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
              </div>
            </div>
            {data && data.map((item, index) => (
              <div key={index}>
                <div className='flex items-center gap-2  px-[14px] py-[8px] rounded-[3px]'>
                  <div className='min-w-[50px] max-w-[50px]'>
                    <h1 className='text-dark-5 text-[11px] font-[500] truncate text-start'>{index + 1}</h1>
                  </div>
                  <div className='min-w-[250px] max-w-[250px]'>
                    <h1 className='text-dark-5 text-[11px] font-[500] truncate text-start'>{item.user?.fullname ?? '-'}</h1>
                  </div>
                  <div className='min-w-[250px] max-w-[250px]'>
                    <h1 className='text-dark-5 text-[11px] font-[500] truncate text-start'>{item.user?.user_detail?.job_position?.position_name ?? '-'}</h1>
                  </div>
                  <div className='min-w-[200px] max-w-[200px]'>
                    <h1 className='text-dark-5 text-[11px] font-[500] truncate text-start'>{item.office_work_travel.type_name ?? '-'}</h1>
                  </div>
                  <div className='min-w-[100px] max-w-[100px]'>
                    <h1 className='text-dark-5 text-[11px] font-[500] truncate text-start'>{item.duration ?? '-'} Hari</h1>
                  </div>
                  <div className='w-full flex items-center gap-[12px]'>
                    <button onClick={() => handleCollapse(item.id)} className='w-[29px] h-[29px] flex items-center justify-center'>
                      {item.id === Current && !Active ?
                        <MdKeyboardArrowUp className='text-[#003049]' />
                        :
                        <MdKeyboardArrowDown className='text-[#003049]' />
                      }
                    </button>
                  </div>

                </div>
                <div className={`w-full rounded-b-[3px] bg-[#F9F9F9] transition-all ease-in-out duration-500 overflow-hidden ${item.id === Current && !Active ? 'h-fit border' : 'h-0 border-0'}`}>
                  <div className='p-[16px] space-y-3'>
                    <h1 className="text-gray-500 text-sm font-semibold ">{languages.language === 'id' ? "Detail Perjalanan Dinas" : "Detail Official Work Travel"}</h1>
                    <div className='space-y-3'>
                      <div className='flex w-full items-start gap-10'>
                        <div className='w-fit space-y-1'>
                          <div className='grid grid-cols-12 '>
                            <h1 className='col-span-4 text-gray-500 text-xs'>{languages.language === 'id' ? "Tanggal" : "Date"}</h1>
                            <h1 className='col-span-8 text-gray-500 text-xs'>: {item.end_date && item.end_date ? `${moment(item.start_date).format("DD-MM-YYYY")} s/d ${moment(item.end_date).format("DD-MM-YYYY")}` : '-'}</h1>
                          </div>
                          <div className='grid grid-cols-12 '>
                            <h1 className='col-span-4 text-gray-500 text-xs'>{languages.language === 'id' ? "Nama" : "Name"}</h1>
                            <h1 className='col-span-8 text-gray-500 text-xs'>: {item.user?.fullname}</h1>
                          </div>
                          <div className='grid grid-cols-12 '>
                            <h1 className='col-span-4 text-gray-500 text-xs'>{languages.language === 'id' ? "Jabatan" : "Job Position"}</h1>
                            <h1 className='col-span-8 text-gray-500 text-xs'>: {item.user?.user_detail.job_position.position_name}</h1>
                          </div>
                          <div className='grid grid-cols-12 '>
                            <h1 className='col-span-4 text-gray-500 text-xs'>{languages.language === 'id' ? "Jenis Perjalanan Dinas" : "Name of Trip"}</h1>
                            <h1 className='col-span-8 text-gray-500 text-xs'>: {item.office_work_travel.type_name ?? '-'}</h1>
                          </div>
                        </div>
                        <div className='w-fit space-y-1'>
                          <div className='grid grid-cols-12 '>
                            <h1 className='col-span-4 text-gray-500 text-xs'>{languages.language === 'id' ? "Durasi" : "Duration"}</h1>
                            <h1 className='col-span-8 text-gray-500 text-xs'>: {item.duration ?? "-"} Hari</h1>
                          </div>
                          <div className='grid grid-cols-12 '>
                            <h1 className='col-span-4 text-gray-500 text-xs'>{languages.language === 'id' ? "Lokasi" : "Location"}</h1>
                            <h1 className='col-span-8 text-gray-500 text-xs'>: {item.location ?? '-'}</h1>
                          </div>
                          <div className='grid grid-cols-12 '>
                            <h1 className='col-span-4 text-gray-500 text-xs'>{languages.language === 'id' ? "Lampiran" : "Attachment"}</h1>
                            <div className='col-span-8 text-gray-500 text-xs flex gap-1 items-start'>
                              <h1 className=' text-gray-500 text-xs'>:</h1>
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                <path d="M5 12.8711V7.87109C5 6.54501 5.52678 5.27324 6.46447 4.33556C7.40215 3.39788 8.67392 2.87109 10 2.87109C11.3261 2.87109 12.5979 3.39788 13.5355 4.33556C14.4732 5.27324 15 6.54501 15 7.87109V14.5378C15 15.4218 14.6488 16.2697 14.0237 16.8948C13.3986 17.5199 12.5507 17.8711 11.6667 17.8711C10.7826 17.8711 9.93477 17.5199 9.30964 16.8948C8.68452 16.2697 8.33333 15.4218 8.33333 14.5378V7.87109C8.33333 7.42907 8.50893 7.00514 8.82149 6.69258C9.13405 6.38002 9.55797 6.20443 10 6.20443C10.442 6.20443 10.866 6.38002 11.1785 6.69258C11.4911 7.00514 11.6667 7.42907 11.6667 7.87109V14.5378" stroke="#015995" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <a href={imageHandle(item.file)} target='_blank' className="text-cyan-900 text-xs font-medium" rel="noreferrer">{languages.language === 'id' ? "Unduh Surat Tugas" : "Download Surat Tugas"}</a>
                            </div>
                          </div>
                          <div className='grid grid-cols-12 '>
                            <h1 className='col-span-4 text-gray-500 text-xs'>{languages.language === 'id' ? "Tujuan" : "Purpose"}</h1>
                            <h1 className='col-span-8 text-gray-500 text-xs'>: {item.purpose ?? '-'}</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </table>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          limitData={limitData}
          lengthData={totalCount}
          onPageChange={handlePageChange}
          onPrevChange={handlePrevChange}
          onNextChange={handleNextChange}
        />
      </div>
    </>
  )
}

export default PerjalananDinas