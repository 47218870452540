import { saveAs } from 'file-saver'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { AiFillCheckCircle, AiFillClockCircle, AiOutlineEye, AiOutlineEyeInvisible, AiOutlinePlus } from 'react-icons/ai'
import { BiExport, BiFilter, BiSearch } from 'react-icons/bi'
import { BsFilter } from 'react-icons/bs'
import { CgTrashEmpty } from 'react-icons/cg'
import { FaTimesCircle } from 'react-icons/fa'
import { HiOutlinePencil } from 'react-icons/hi'
import { IoMdArrowDropdown } from 'react-icons/io'
import { MdImportExport } from 'react-icons/md'
import { TbFileExport } from 'react-icons/tb'
import Select from 'react-select'
import * as XLSX from 'xlsx'
import Api from '../../../../Api'
import { Modal, ModalDelete, Pagination } from '../../../../components'
import InputComponent from '../../../../components/Input/inputComponent'
import languages from '../../../../utils/languages'
import { IconLocFingerprint } from '../../../../assets'
import imageHandle from '../../../../utils/imageHandle'
import SecondaryButton from '../../../../components/Button/SecondaryButton'
import PrimaryButton from '../../../../components/Button/PrimaryButton'
import LoadingPrimaryButton from '../../../../components/Button/LoadingPrimaryButton'

const Permission = () => {
    const [showDetailPermission, setShowDetailPermission] = useState()
    const [showAddPermission, setShowAddPermission] = useState()
    const [showEditPermission, setShowEditPermission] = useState()
    const [showModalDelete, setShowModalDelete] = useState()
    const [showDetailApproval, setShowDetailApproval] = useState(false)

    const [data, setData] = useState('')
    const [dataById, setDataById] = useState('')
    const [permissionId, setPermissionId] = useState('')

    // Post Permission
    const [fullName, setFullname] = useState('')
    const [permissionReason, setPermissionReason] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [numOfDays, setNumOfDays] = useState(0)
    const [alamat, setAlamat] = useState('')
    const [user, setUser] = useState('')

    const [userName, setUserName] = useState('')
    const [userId, setUserId] = useState('')
    const [refresh, setRefresh] = useState(false)

    const limit = 10
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState(1)

    const [isLoading, setIsLoading] = useState(false)

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };


    const FetchUser = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setUserName(response.data.results.user.fullname)
            setUserId(response.data.results.user.id)
            getDataPegawaiByCompany(response?.data?.results?.user?.companyId)
        } catch (error) {
            console.log(error)
        }
    }

    const [dataPegawai, setDataPegawai] = useState([])
    const getDataPegawai = async () => {
        try {
            const response = await Api.GetDropdownEmployee(localStorage.getItem('hris-token'))
            const data = response.data.data
            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.fullname
            }))

            setDataPegawai(options)
        } catch (error) {
            console.log(error)
        }
    }
    const [dataPegawaiPenempatan, setDataPegawaiPenempatan] = useState([])
    const getDataPegawaiByCompany = async (id) => {
        try {
            const response = await Api.GetDropdownEmployee(localStorage.getItem('hris-token'), id)
            const data = response.data.data
            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.fullname
            }))

            setDataPegawaiPenempatan(options)
        } catch (error) {
            console.log(error)
        }
    }

    // const getEmployeeById = async () => {
    //     try {
    //         const response = await Api.GetAllEmployeeById(localStorage.getItem('hris-token'), user)
    //         // console.log(response, 'getEmployeeById')
    //         setFullname(response.data[0].fullname)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const deletePermissionModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setPermissionId(id)
        setRefresh(true)
    }

    const deletePermission = async () => {
        try {
            await Api.DeletePermission(localStorage.getItem('hris-token'), permissionId).then((res) => {
                setShowModalDelete(!showModalDelete)
                setRefresh(true)
                toast.success('Berhasil Menghapus Data!')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menghapus Data!')

        }
    }

    const resetForm = () => {
        setUser('')
        setIdEmployee([])
        setPermissionReason('')
        setStartDate('')
        setEndDate('')
        setNumOfDays('')
        setAlamat('')
    }

    const postPermission = async () => {
        setIsLoading(true)
        try {
            const data = {
                fullname: localStorage.getItem('hris-role') === 'ADMIN' || localStorage.getItem('hris-role') === 'SUBSIDIARY' ? user.label : userName,
                userId: localStorage.getItem('hris-role') === 'ADMIN' || localStorage.getItem('hris-role') === 'SUBSIDIARY' ? user : userId,
                // job_position: 'jobPosition',
                permission_reason: permissionReason,
                start_date: startDate,
                end_date: endDate,
                duration: numOfDays,
                // dateof_filling: dateOfFilling,
                // submission_time: submissionTime,
                // num_ofdays: numOfDays,
                // start_time: startTime,
                // end_time: endTime,
                // backto_work: backToWork,
                // backto_work_time: backToWorkTime,
                // teks_izin: teksIzin,
                alamat_selama_izin: alamat,
                reportToId: idEmployee?.value
            }
            // console.log(data)
            await Api.PostPermission(localStorage.getItem('hris-token'), data).then((res) => {
                setIsLoading(false)
                resetForm()
                setShowAddPermission(!showAddPermission)
                toast.success('Berhasil Menambahkan Data!')
                setRefresh(true)
            }).catch((error) => {
                setIsLoading(false)
                console.log(error)
                if (error?.response?.data?.errors) {
                    toast.error(error.response.data.errors)
                } else if (error?.response?.data?.message) {
                    toast.error(error.response.data.message)
                } else {
                    toast.error("Gagal Menambahkan Data!")
                }
            })

        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data!')
        }
    }

    const admin = localStorage.getItem('hris-role') === 'ADMIN' || localStorage.getItem('hris-role') === 'SUBSIDIARY'

    const [showFilter, setShowFilter] = useState(false)
    const [penempatan, setPenempatan] = useState("")
    const [unitKerja, setUnitKerja] = useState("")
    const [startDateFilter, setStartDateFilter] = useState("")
    const [endDateFilter, setEndDateFilter] = useState("")
    const [fullnameFilter, setFullnameFilter] = useState("")

    const getPermission = async () => {
        try {
            if (admin) {
                const response = await Api.GetPermissionByAdmin(localStorage.getItem('hris-token'), startDateFilter, endDateFilter, fullnameFilter, limit, currentPage, penempatan, unitKerja)
                setData(response.data.permissions)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
                setTotalCount(response.data.totalCount)
                // console.log(response)
                // setRefresh(true)
            } else {
                const response = await Api.GetPermission(localStorage.getItem('hris-token'), startDateFilter, endDateFilter, currentPage, limit)
                setData(response.data.permissions)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
                setTotalCount(response.data.totalCount)
                // console.log(response)
                // setRefresh(true)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const exportIzin = async () => {
        // Sample data array
        const response = await Api.GetPermissionByAdmin(localStorage.getItem('hris-token'), startDateFilter, endDateFilter, fullnameFilter, totalCount, 1, penempatan, unitKerja)
        const body = response.data.permissions;
        // console.log(body)

        // Define custom headers for each table
        const Headers = ['Nama Pegawai', 'Jabatan', 'Tanggal Pengisian', 'Tanggal Izin', 'Durasi', 'Alasan', 'Alamat Selama Izin'];

        // Create modified data arrays with custom headers
        const data = body.map(({ user, dateof_filling, start_date, end_date, duration, permission_reason, alamat_selama_izin }) => ({
            'Nama Pegawai': user ? user.fullname : '-',
            'Jabatan': user?.user_detail ? user?.user_detail?.job_position?.position_name : '',
            'Tanggal Pengisian': moment(dateof_filling).format('DD MMMM YYYY'),
            'Tanggal Izin': start_date && end_date ? `${moment(start_date).format('DD MMMM YYYY')} s/d ${moment(end_date).format('DD MMMM YYYY')}` : '-',
            'Durasi': duration ? duration : '-',
            'Alasan': permission_reason ? permission_reason : '-',
            'Alamat Selama Izin': alamat_selama_izin ? alamat_selama_izin : '-'
        }));

        // Create a new worksheet for each table
        const worksheetGrade = XLSX.utils.json_to_sheet(data, { header: Headers });

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Add the worksheets to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Rekap Cuti');
        // Generate Excel file buffer
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        // Convert buffer to Blob
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Save the Excel file using FileSaver.js
        saveAs(excelBlob, 'Rekap Cuti.xlsx');
    };

    const [dataPenempatan, setDataPenempatan] = useState([])
    const [dataUnitKerja, setDataUnitKerja] = useState([])
    const GetData = async () => {
        try {
            const res = await Api.GetSubsidiaryDropdown(localStorage.getItem('hris-token'))
            const res2 = await Api.GetUnitKerjaDropdown(localStorage.getItem('hris-token'), penempatan)
            setDataPenempatan(res.data.results.data)
            setDataUnitKerja(res2.data.results)
            // console.log(response, 'employeeo')
        } catch (error) {
            console.log(error)
        }
    }

    const handleResetFilter = () => {
        setStartDate("")
        setEndDate("")
        setFullname("")
        setPenempatan("")
        setUnitKerja("")
        setShowFilter(!showFilter)
        setRefresh(true)
        // window.location.reload()
    }

    const [dataApproval, setDataApproval] = useState([])
    const openDetailApproval = async (id) => {
        setShowDetailApproval(!showDetailApproval)
        try {
            const response = await Api.getDetailApprovalPermission(localStorage.getItem('hris-token'), id)
            setDataApproval(response.data.approval)
        } catch (error) {
            console.log(error)
        }
    }

    const openDetailPermission = async (id) => {
        setShowDetailPermission(!showDetailPermission)
        try {
            const response = await Api.GetPermissionById(localStorage.getItem('hris-token'), id)
            setDataById(response.data.permission)
            // setDataJobPosition(response.data.user.buat[0].job_position.position_name)
            // console.log(response)
        } catch (error) {
            console.log(error)
        }
    }


    const UpdatePermissionModalOpen = async (id) => {
        setShowEditPermission(!showEditPermission)
        setPermissionId(id)
        try {
            const response = await Api.GetPermissionById(localStorage.getItem('hris-token'), id)
            const data = response.data.permission
            // console.log(data)
            setUser(data.user.id ?? "")
            setFullname(data.fullname ?? "")
            setPermissionReason(data.permission_reason ?? "")
            setNumOfDays(data.duration ?? "")
            setStartDate(data.start_date ?? "")
            setEndDate(data.end_date ?? "")
            setAlamat(data.alamat_selama_izin ?? "")
            var idEmp = []
            var nameEmp = []
            data.reportTo.forEach((val, i) => {
                idEmp.push(val.id);
                nameEmp.push(val.fullname);
            });
            setIdEmployee(idEmp)
        } catch (error) {
            console.log(error)
        }
    }

    const UpdatePermission = async () => {
        try {
            const data = {
                fullname: localStorage.getItem('hris-role') === 'ADMIN' || localStorage.getItem('hris-role') === 'SUBSIDIARY' ? fullName : userName,
                userId: user,
                // job_position: 'jobPosition',
                permission_reason: permissionReason,
                start_date: startDate,
                end_date: endDate,
                duration: numOfDays,
                // dateof_filling: dateOfFilling,
                // submission_time: submissionTime,
                // num_ofdays: numOfDays,
                // start_time: startTime,
                // end_time: endTime,
                // backto_work: backToWork,
                // backto_work_time: backToWorkTime,
                // teks_izin: teksIzin,
                alamat_selama_izin: alamat
            }
            // console.log(data)
            await Api.UpdatePermission(localStorage.getItem('hris-token'), data, permissionId).then((res) => {
                setShowEditPermission(!showEditPermission)
                toast.success('Berhasil Edit Data!')
                resetForm()
                setRefresh(true)
            }).catch((error) => {
                console.log(error)
                if (error?.response?.data?.errors) {
                    toast.error(error.response.data.errors)
                } else if (error?.response?.data?.message) {
                    toast.error(error.response.data.message)
                } else {
                    toast.error("Gagal edit Data!")
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Edit Data!')

        }
    }

    // Repeater Inputan
    const [idEmployee, setIdEmployee] = useState([])

    const calculateDuration = async () => {
        if (startDate && endDate) {
            try {
                await Api.getDurasiIzin(localStorage.getItem('hris-token'),
                    startDate,
                    endDate,
                    localStorage.getItem('hris-role') === 'ADMIN' || localStorage.getItem('hris-role') === 'SUBSIDIARY' ? user : userId)
                    .then((response) => {
                        // console.log(response?.data?.results?.durasi_izin)
                        setNumOfDays(response?.data?.results?.durasi_izin);
                    }).catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        }
    };

    const [isOnlyAdmin, setIsOnlyAdmin] = useState(false)

    const getDetail = async () => {
        try {
            await Api.Fetch(localStorage.getItem('hris-token')).then(async (response) => {
                await Api.GetApprovalSettingById(localStorage.getItem('hris-token'), localStorage.getItem('hris-role') === 'ADMIN' ? user : response.data.results.user.id).then((res) => {
                    // console.log("Hak akses", res?.data?.results?.data?.pengaturan_persetujuan)
                    const data = res?.data?.results?.data?.pengaturan_persetujuan
                    setIsOnlyAdmin(data.izin?.isOnlyAdmin)
                    //izin
                    setIdEmployee({
                        "value": data?.izin?.atasanId ?? "",
                        "label": data?.izin?.atasanName ?? ""
                    })
                }).catch((err) => {
                    console.log(err)
                })
            })
        } catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = (id) => {
        setPermissionId(id)
        setVerifikasi(true)
    }

    const [verifikasi, setVerifikasi] = useState(false)
    const [passphrase, setPassphrase] = useState("")
    const submitPassprashe = async () => {
        try {
            const data = {
                "passphrase": passphrase
            }
            await Api.PostPassphrasePermission(localStorage.getItem('hris-token'), permissionId, data).then((response) => {
                toast.success('Berhasil menandatangani dokumen!')
                setVerifikasi(false)
                // navigate('/laporan-kinerja/detail-saya', { state: { id: skpId } })
            }).catch((error) => {
                console.log(error.response.data.message)
                setVerifikasi(false)
                toast.error(error.response.data.message ?? 'Gagal menandatangani dokumen!')
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }

    const [passwordType, setPasswordType] = useState('password')
    const ChangePasswordType = () => {
        if (passwordType === 'password') {
            setPasswordType('text')
        } else {
            setPasswordType('password')
        }
    }


    const calculateActiveFilters = () => {
        let count = 0;
        if (penempatan) count++;
        if (unitKerja) count++;
        if (startDateFilter) count++;
        if (endDateFilter) count++;
        return count;
    };

    useEffect(() => {
        calculateDuration();
    }, [startDate, endDate]);
    useEffect(() => {
        getDetail()
        // getEmployeeById()
    }, [user])

    useEffect(() => {
        GetData()
        getPermission()
        setRefresh(false)
    }, [fullnameFilter, limit, currentPage, refresh])

    useEffect(() => {
        getDataPegawai()
        FetchUser()
        setRefresh(false)
    }, [refresh, limit, user])
    return (
        <div>
            <Modal
                activeModal={showFilter}
                buttonClose={() => setShowFilter(!showFilter)}
                width={'550px'}
                title={
                    <div className='flex gap-[27px] items-center'>
                        <BsFilter className='text-dark-1 text-2xl' />
                        <h1 className='text-dark-1 text-[20px] font-semibold'>Filter</h1>
                    </div>
                }
                content={
                    <div className='space-y-[20px]'>
                        <div className='grid grid-cols-2 gap-3'>
                            <div className='relative'>
                                <select onChange={(e) => setPenempatan(e.target.value)} value={penempatan} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                    <option value={""} selected className='text-grey-medium font-normal'>Pilih Penempatan</option>
                                    {dataPenempatan && dataPenempatan.map((val, index) => (
                                        <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.company_name}</option>
                                    ))}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                            </div>
                            <div className='relative'>
                                <select onChange={(e) => setUnitKerja(e.target.value)} value={unitKerja} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                    <option value={""} selected className='text-grey-medium font-normal'>Pilih Unit Kerja</option>
                                    {dataUnitKerja && dataUnitKerja.map((val, index) => (
                                        <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.name}</option>
                                    ))}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                            </div>
                            <div className='col-span-2'>
                                <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Tanggal</h1>
                                <div className='flex gap-3 items-center'>
                                    <input onChange={(e) => setStartDateFilter(e.target.value)} value={startDateFilter} type="date" className='w-full lg:w-[121px] p-[10px] rounded-md border border-gray-300 text-[#003049] text-[12px]' />
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>-</h1>
                                    <input onChange={(e) => setEndDateFilter(e.target.value)} value={endDateFilter} type="date" className='w-full lg:w-[121px] p-[10px] rounded-md border border-gray-300 text-[#003049] text-[12px]' />
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center justify-between'>
                            <button onClick={handleResetFilter} className='py-[10px] px-[15px] text-[#0E5073] text-[14px] font-medium flex justify-center rounded-md items-center border'>Reset Filter</button>
                            <button onClick={() => { setShowFilter(!showFilter); setRefresh(true) }} className='py-[10px] px-[15px] bg-[#0E5073] text-white text-[14px] font-medium flex justify-center rounded-md items-center'>Filter</button>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showDetailApproval}
                title={'Detail Persetujuan'}
                buttonClose={() => setShowDetailApproval(!showDetailApproval)}
                width={'507px'}
                content={
                    <div className='space-y-[20px] w-[full]'>
                        <div className='overflow-auto scrollbar-hide'>
                            <table className='w-full space-y-[10px]'>
                                <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center gap-[6px] min-w-[300px] max-w-[300px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Nama Atasan</h1>
                                        <MdImportExport className='text-dark-5 text-xs' />
                                    </div>
                                    <div className='flex items-center justify-center gap-[15px] w-full'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Status</h1>
                                    </div>
                                </div>
                                {dataApproval && dataApproval.map((val, index) => (
                                    <div key={index} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[300px] max-w-[300px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{val.atasan}</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            <div className='rounded-[9px] flex flex-col items-center justify-center'>
                                                {val.status === 'pending' ?
                                                    <AiFillClockCircle className='text-base-yellow w-[29px] h-[29px]' />
                                                    : val.status === 'approved' ?
                                                        <AiFillCheckCircle className='text-[#00AB9A] w-[29px] h-[29px]' />
                                                        :
                                                        <FaTimesCircle className='text-[#C1121F] w-[29px] h-[29px]' />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </table>
                        </div>
                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowDetailApproval(!showDetailApproval)} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Tutup" : "Close"}</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showDetailPermission}
                title={languages.language === 'id' ? "Detail Izin" : "Detail Permission"}
                buttonClose={() => setShowDetailPermission(!showDetailPermission)}
                width={'832px'}
                content={
                    <div className='space-y-[40px] w-[full]'>
                        <div className='bg-[#F8F8F8] rounded-[15px] px-[19px] py-[31px] w-[773px]'>
                            <div className='bg-[#F8F8F8] rounded-[15px] px-[19px] py-[31px] w-[773px] text-dark-5 text-xs'>
                                <div className="flex mb-[21px]">
                                    <h1 className='w-1/4 font-semibold'>{languages.language === 'id' ? "Nama Pegawai" : "Employee Name"}</h1>
                                    <h1 className='w-3/4 font-medium uppercase'>: {dataById.fullname ? dataById.fullname : '-'}</h1>
                                </div>
                                <div className="flex mb-[21px]">
                                    <h1 className='w-1/4 font-semibold'>{languages.language === 'id' ? "Jabatan" : "Job Position"}</h1>
                                    <h1 className='w-3/4 font-medium uppercase'>: {dataById.user ? dataById.user?.user_detail?.job_position?.position_name : '-'}</h1>
                                </div>
                                <div className="flex mb-[21px]">
                                    <h1 className='w-1/4 font-semibold'>{languages.language === 'id' ? "Alasan Izin" : "Permission Reason"}</h1>
                                    <h1 className='w-3/4 font-medium uppercase'>: {dataById.permission_reason ? dataById.permission_reason : '-'}</h1>
                                </div>
                                <div className="flex mb-[21px]">
                                    <h1 className='w-1/4 font-semibold'>{languages.language === 'id' ? "Tanggal Mulai" : "Start Date"}</h1>
                                    <h1 className='w-3/4 font-medium uppercase'>: {dataById.start_date ? moment(dataById.start_date).format('DD-MM-YYYY') : '-'}</h1>
                                </div>
                                <div className="flex mb-[21px]">
                                    <h1 className='w-1/4 font-semibold'>{languages.language === 'id' ? "Tanggal Selesai" : "End Date"}</h1>
                                    <h1 className='w-3/4 font-medium uppercase'>: {dataById.end_date ? moment(dataById.end_date).format('DD-MM-YYYY') : '-'}</h1>
                                </div>
                                <div className="flex mb-[21px]">
                                    <h1 className='w-1/4 font-semibold'>Alamat Selama Izin</h1>
                                    <h1 className='w-3/4 font-medium'>: {dataById.alamat_selama_izin}</h1>
                                </div>
                                <div className="flex mb-[21px]">
                                    <h1 className='w-1/4 font-semibold'>{languages.language === 'id' ? "Durasi Izin" : "Duration"}</h1>
                                    <h1 className='w-3/4 font-medium'>: {dataById.duration ? dataById.duration : '-'} Hari</h1>
                                </div>
                            </div>

                            {/* <h1 className='text-dark-3 mt-[21px] mb-[11px] font-semibold'>{languages.language === 'id' ?"Kembali Bekerja":"Back to Work"}</h1> */}

                            {/* <div className='w-[260px]'>
                        <div className="grid grid-cols-2 text-[11px] text-dark-5 gap-y-[20px]">
                            <h1 className='font-medium'>{languages.language === 'id' ?"Tanggal":"Date"}</h1>
                            <h1>: {dataById.backto_work ? moment(dataById.backto_work).format('DD-MM-YYYY') : '-'}</h1>
                            <h1 className='font-medium'>{languages.language === 'id' ?"Waktu":"Time"}</h1>
                            <h1>: {dataById.backto_work_time ? dataById.backto_work_time : '-'}</h1>
                        </div>
                        </div> */}
                        </div>
                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowDetailPermission(!showDetailPermission)} className=' bg-[#0E5073] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[white] text-sm font-[500]'>{languages.language === 'id' ? "Tutup" : "Close"}</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showAddPermission}
                title={languages.language === 'id' ? "Tambah Izin" : "Add Permission"}
                buttonClose={() => { setShowAddPermission(!showAddPermission); resetForm() }}
                width={'553px'}
                content={
                    <div className='space-y-[24px] w-[full]'>
                        {localStorage.getItem('hris-role') === 'ADMIN' || localStorage.getItem('hris-role') === 'SUBSIDIARY' ?
                            (
                                <div>
                                    <h2 className='text-dark-5 text-[12px] font-medium mb-[5px]'>{languages.language === 'id' ? "Nama Pegawai" : "Employee Name"} <span className='text-[#780000]'>*</span></h2>
                                    {/* <div className='relative'>
                                        <select onChange={(e) => setUser(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[473px] appearance-none'>
                                            <option selected disabled value={''}>{languages.language === 'id' ? "Pilih Pegawai" : "Select Employee..."}</option>
                                            {dataEmployeeAll && dataEmployeeAll.map((item) => {
                                                if (item.role === 'USER') {
                                                    return (
                                                        <option value={item.id}>{item.fullname}</option>
                                                    )
                                                } else {
                                                    return ''
                                                }
                                            })}
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div> */}
                                    {localStorage.getItem('hris-role') === 'ADMIN' ?
                                        <Select
                                            value={dataPegawai.find(option => option.value === user)}
                                            options={dataPegawai}
                                            onChange={(e) => {
                                                setUser(e.value)
                                            }}
                                        />
                                        : <Select
                                            value={dataPegawaiPenempatan.find(option => option.value === user)}
                                            options={dataPegawaiPenempatan}
                                            onChange={(e) => {
                                                setUser(e.value)
                                            }}
                                        />
                                    }
                                </div>
                            ) :
                            (
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Nama Pegawai" : "Employee Name"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <div className='relative'>
                                        <p className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-full appearance-none'>{userName}</p>
                                    </div>
                                </div>
                            )}


                        {/* <div>
                        <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>{languages.language === 'id' ?"Jabatan":"Job Position"}</h1>
                        <input value={jobPosition? jobPosition : 'default'} type='text' className='bg-[#F2F2F2] border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder='Job Position' readOnly/>
                    </div> */}

                        <div>
                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>{languages.language === 'id' ? "Alasan Izin" : "Permission Reason"} <span className='text-[#780000]'>*</span></h1>
                            <input onChange={(e) => setPermissionReason(e.target.value)} value={permissionReason} type='text' className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[12px]  h-[35px] w-full text-[#A8A8A8]' placeholder={languages.language === 'id' ? "Alasan Izin" : "Permission reason..."} />
                        </div>

                        <div className="flex gap-[19px]">
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>{languages.language === 'id' ? "Tanggal Mulai" : "Start Date"}  <span className='text-[#780000]'>*</span></h1>
                                <input onChange={(e) => setStartDate(e.target.value)} value={startDate} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[227px]' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>{languages.language === 'id' ? "Tanggal Selesai" : "End Date"} <span className='text-[#780000]'>*</span></h1>
                                <input onChange={(e) => setEndDate(e.target.value)} value={endDate} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[227px]' />
                            </div>
                        </div>

                        <div>
                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>{languages.language === 'id' ? "Durasi Izin" : "Number of days"}</h1>
                            <p className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[227px]'>{numOfDays}</p>
                        </div>

                        {/* <div>
                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Teks Izin <span className='text-[#780000]'>*</span></h1>
                            <textarea onChange={(e) => setTeksIzin(e.target.value)} value={teksIzin} rows={3} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[12px] text-dark-5 w-full resize-none' placeholder="Teks Izin..."></textarea>
                        </div> */}
                        <div>
                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Alamat Selama Izin <span className='text-[#780000]'>*</span></h1>
                            <textarea onChange={(e) => setAlamat(e.target.value)} value={alamat} rows={3} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[12px] text-dark-5 w-full resize-none' placeholder="Alamat selama izin..."></textarea>
                        </div>

                        <div>
                            {isOnlyAdmin === false ?
                                <>
                                    <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>{languages.language === 'id' ? "Penandatangan" : "Approval"} <span className='text-[#780000]'>*</span></h1>
                                    {localStorage.getItem('hris-role') === 'ADMIN' ?
                                        <Select
                                            value={idEmployee}
                                            onChange={selectedOption => setIdEmployee(selectedOption)}
                                            options={dataPegawai}
                                        />
                                        : <Select
                                            value={idEmployee}
                                            onChange={selectedOption => setIdEmployee(selectedOption)}
                                            options={dataPegawaiPenempatan}
                                        />
                                    }
                                </>
                                : <InputComponent
                                    disabled={true}
                                    label={"Penendatangan"}
                                    value={idEmployee?.label}
                                    required={true}
                                    type={"text"}
                                />
                            }
                        </div>


                        <div className='relative pb-[37px] pt-[40px]'>
                            <div className="flex justify-end gap-[10px]">
                                <SecondaryButton action={() => { setShowAddPermission(!showAddPermission); resetForm() }} text={'Batal'} />
                                {isLoading ?
                                    <LoadingPrimaryButton />
                                    : <PrimaryButton action={() => postPermission()} text={'Simpan'} />
                                }
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showEditPermission}
                title={'Edit Izin'}
                buttonClose={() => { setShowEditPermission(!showEditPermission); resetForm() }}
                width={'553px'}
                content={
                    <div className='space-y-[24px] w-[full]'>
                        <div>
                            <h2 className='text-dark-5 text-[12px] font-medium mb-[5px]'>{languages.language === 'id' ? "Nama Pegawai" : "Employee Name"} <span className='text-[#780000]'>*</span></h2>
                            <p className='bg-[#F2F2F2] border rounded-[6px] text-[#A8A8A8] text-[12px] px-[8px] py-[10px] w-full appearance-none'>{fullName}</p>
                        </div>

                        {/* <div>
                        <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>{languages.language === 'id' ?"Jabatan":"Job Position"}</h1>
                        <input value={jobPosition? jobPosition : 'default'} type='text' className='bg-[#F2F2F2] border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder='Job Position' readOnly/>
                    </div> */}

                        <div>
                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>{languages.language === 'id' ? "Alasan Izin" : "Permission Reason"} <span className='text-[#780000]'>*</span></h1>
                            <input type='text' value={permissionReason} onChange={(e => setPermissionReason(e.target.value))} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[12px]  h-[35px] w-full text-[#A8A8A8]' placeholder={languages.language === 'id' ? "Alasan Izin" : "Permission reason..."} />
                        </div>

                        <div className="flex gap-[19px]">
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>{languages.language === 'id' ? "Tanggal Mulai" : "Start Date"}  <span className='text-[#780000]'>*</span></h1>
                                <input onChange={(e) => setStartDate(e.target.value)} value={startDate} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[227px]' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>{languages.language === 'id' ? "Tanggal Selesai" : "End Date"} <span className='text-[#780000]'>*</span></h1>
                                <input onChange={(e) => setEndDate(e.target.value)} value={endDate} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[227px]' />
                            </div>
                        </div>

                        <div>
                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>{languages.language === 'id' ? "Durasi Izin" : "Number of days"}</h1>
                            <input onChange={(e) => setNumOfDays(e.target.value)} value={numOfDays} type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[227px]' />
                        </div>

                        {/* <div>
                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Teks Izin <span className='text-[#780000]'>*</span></h1>
                            <textarea onChange={(e) => setTeksIzin(e.target.value)} value={teksIzin} rows={3} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[12px] text-dark-5 w-full resize-none' placeholder="Teks Izin..."></textarea>
                        </div> */}
                        <div>
                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Alamat Selama Izin <span className='text-[#780000]'>*</span></h1>
                            <textarea onChange={(e) => setAlamat(e.target.value)} value={alamat} rows={3} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[12px] text-dark-5 w-full resize-none' placeholder="Alamat selama izin..."></textarea>
                        </div>

                        {/* <div>
                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>{languages.language === 'id' ? "Penandatanganan" : "Approval"} <span className='text-[#780000]'>*</span></h1>

                            <div className='relative py-[12px] pl-[12px] pr-[13px] border rounded-lg flex text-[#8E95A2] font-medium w-full mb-3'>
                                <BiSearch className='absolute right-[12px] text-lg' />
                                <input value={searchTerm} onChange={handleSearchChange} type="text" placeholder={languages.language === 'id' ? "Cari..." : "Search Employee name..."} className='outline-none text-[12px] w-full' />
                            </div>

                            {searchTerm.trim() !== '' && (
                                <div className={`bg-white rounded-md mb-3 shadow overflow-auto min-h-fit max-h-[120px]`}>
                                    {dataEmployeeAll.map((item, index) => {
                                        if (item.role === 'USER') {
                                            return (
                                                <h1 key={index} className='flex text-[12px] font-medium items-center p-[10px]' onClick={() => handleNameClick(item.id)}>
                                                    {item.fullname}
                                                </h1>
                                            )
                                        }
                                    })}
                                </div>
                            )}

                            {employeeName.length > 0 && (
                                <div className='flex items-center justify-start gap-[8px] flex-wrap '>
                                    {employeeName.map((name, index) => (
                                        <div key={index} className='flex gap-[8px] items-center rounded-lg'>
                                            <div className='bg-[#8E95A2] rounded-md py-[4px] px-[10px] text-white text-xs flex gap-[8px] items-center'>
                                                <h1>{name}</h1>
                                                <button onClick={() => handleRemoveClickedEmployee(name)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                                        <path d="M7.82246 7.50043L10.3308 4.99793C10.4406 4.88809 10.5024 4.73911 10.5024 4.58377C10.5024 4.42842 10.4406 4.27944 10.3308 4.1696C10.221 4.05976 10.072 3.99805 9.91663 3.99805C9.76129 3.99805 9.61231 4.05976 9.50247 4.1696L6.99996 6.67793L4.49746 4.1696C4.38762 4.05976 4.23864 3.99805 4.0833 3.99805C3.92796 3.99805 3.77897 4.05976 3.66913 4.1696C3.55929 4.27944 3.49758 4.42842 3.49758 4.58377C3.49758 4.73911 3.55929 4.88809 3.66913 4.99793L6.17746 7.50043L3.66913 10.0029C3.61446 10.0572 3.57106 10.1217 3.54144 10.1928C3.51183 10.2638 3.49658 10.3401 3.49658 10.4171C3.49658 10.4941 3.51183 10.5704 3.54144 10.6414C3.57106 10.7125 3.61446 10.777 3.66913 10.8313C3.72336 10.8859 3.78788 10.9293 3.85896 10.959C3.93005 10.9886 4.00629 11.0038 4.0833 11.0038C4.1603 11.0038 4.23655 10.9886 4.30763 10.959C4.37872 10.9293 4.44324 10.8859 4.49746 10.8313L6.99996 8.32293L9.50247 10.8313C9.55669 10.8859 9.62121 10.9293 9.6923 10.959C9.76338 10.9886 9.83962 11.0038 9.91663 11.0038C9.99364 11.0038 10.0699 10.9886 10.141 10.959C10.2121 10.9293 10.2766 10.8859 10.3308 10.8313C10.3855 10.777 10.4289 10.7125 10.4585 10.6414C10.4881 10.5704 10.5033 10.4941 10.5033 10.4171C10.5033 10.3401 10.4881 10.2638 10.4585 10.1928C10.4289 10.1217 10.3855 10.0572 10.3308 10.0029L7.82246 7.50043Z" fill="white" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div> */}


                        <div className='relative pb-[37px] pt-[40px]'>
                            <div className="flex justify-end gap-[10px]">
                                <SecondaryButton action={() => { setShowEditPermission(!showEditPermission); resetForm() }} text={'Batal'} />
                                {isLoading ?
                                    <LoadingPrimaryButton />
                                    : <PrimaryButton action={() => UpdatePermission()} text={'Simpan'} />
                                }
                            </div>
                        </div>
                    </div>
                }
            />

            <ModalDelete
                activeModal={showModalDelete}
                buttonClose={() => setShowModalDelete(!showModalDelete)}
                submitButton={deletePermission}
            />
            <Modal
                activeModal={verifikasi}
                title={''}
                buttonClose={() => setVerifikasi(false)}
                width={'550px'}
                content={
                    <div className='space-y-6'>
                        <div className="text-center text-sky-950 text-base font-semibold">Apakah anda ingin menandatangani surat tersebut ?</div>
                        <div className='relative'>
                            <input type={passwordType} onChange={(e) => setPassphrase(e.target.value)} value={passphrase} className='text-zinc-700 text-xs font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='passpharse' />
                            <button onClick={ChangePasswordType} className='absolute right-5 top-3 text-xl text-[#A8A8A8]'>
                                {passwordType === 'text' ?
                                    <AiOutlineEye />
                                    :
                                    <AiOutlineEyeInvisible />
                                }
                            </button>
                        </div>
                        <div className='flex items-center justify-center gap-[12px] mt-5'>
                            <button onClick={() => setVerifikasi(false)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Batal</button>
                            <button onClick={submitPassprashe} className='bg-[#0E5073] text-white text-sm rounded-[6px] py-[10px] px-[25px]'>Tanda tangan</button>
                        </div>
                    </div>
                }
            />
            <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>

                <div>
                    <h1 className='text-[20px]'>{languages.language === 'id' ? "Izin" : "Permission"}</h1>
                    <h2 className='text-[10px] text-dark-5'>{languages.language === 'id' ? "Daftar Izin Pegawai" : "List of  Permission Employee"}</h2>
                </div>
                {admin ?
                    <div className="flex flex-wrap justify-between gap-2 mt-[31px]">
                        <div className='flex flex-col lg:flex-row w-full lg:w-auto lg:mt-0 items-start lg:items-center gap-[10px]'>
                            <button onClick={() => setShowFilter(!showFilter)} className='relative bg-white border border-dark-7 rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                <BiFilter className='text-dark-5 text-2xl' />
                                Filter
                                {calculateActiveFilters() > 0 ?
                                    <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2">{calculateActiveFilters()}</div>
                                    : null
                                }
                            </button>
                            <button onClick={() => exportIzin()} className='bg-white border border-dark-7 rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                <TbFileExport className='text-[#003049]' />
                                <h1 className='text-[#003049] text-sm font-[500]'>Ekspor</h1>
                            </button>
                        </div>
                        <div className='flex flex-col lg:flex-row w-full lg:w-auto lg:mt-0 items-start lg:items-center gap-[10px]'>
                            <div className='relative'>
                                <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg' />
                                <input onChange={(e) => setFullnameFilter(e.target.value)} placeholder='Cari Nama Pegawai...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]' />
                            </div>
                            <button onClick={() => setShowAddPermission(!showAddPermission)} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                <AiOutlinePlus className='text-xl text-white' />
                                <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Tambah Izin" : "Add Permission"}</h1>
                            </button>
                        </div>
                    </div>
                    : <div className="flex flex-wrap justify-between gap-2 items-center mt-[31px]">
                        <div className="flex flex-wrap items-center gap-2">
                            <input onChange={(e) => setStartDateFilter(e.target.value)} value={startDateFilter} type="date" className='w-full lg:w-[121px] p-[10px] rounded-md border border-gray-300 text-[#003049] text-[12px]' />
                            <p>-</p>
                            <input onChange={(e) => setEndDateFilter(e.target.value)} value={endDateFilter} type="date" className='w-full lg:w-[121px] p-[10px] rounded-md border border-gray-300 text-[#003049] text-[12px]' />
                        </div>
                        <div className='flex flex-col lg:flex-row w-full lg:w-auto lg:mt-0 items-start lg:items-center gap-[10px]'>
                            <button onClick={() => setShowAddPermission(!showAddPermission)} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                <AiOutlinePlus className='text-xl text-white' />
                                <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Tambah Izin" : "Add Permission"}</h1>
                            </button>
                        </div>
                    </div>
                }

                <div>
                    <div className='overflow-auto'>
                        <table className='w-full space-y-[10px]'>
                            <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Nama Pegawai" : "Employee Name"}</h1>
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Jabatan" : "Job Position"}</h1>
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Tanggal Izin" : "Date of Filling"}</h1>
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Alasan" : "Reason"}</h1>
                                </div>
                                <div className='flex items-center justify-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] text-center'>{languages.language === 'id' ? "Persetujuan" : "Approved Leader"}</h1>
                                </div>
                                <div className='flex items-center justify-center gap-[6px] w-full'>
                                    <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                                </div>
                            </div>
                            {Object.values(data).map((item, idx) => (
                                <div key={idx} className='flex items-center gap-2  px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.fullname}</h1>
                                    </div>
                                    <div className='min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{!item.user?.user_detail ? '-' : item.user?.user_detail.job_position.position_name}</h1>
                                    </div>
                                    <div className='min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{moment(item.start_date).format('DD-MM-YYYY')} s/d {moment(item.end_date).format('DD-MM-YYYY')}</h1>
                                    </div>
                                    <div className='min-w-[120px] max-w-[120px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.permission_reason}</h1>
                                    </div>
                                    <div className='w-full flex items-center justify-center gap-[12px] min-w-[120px] max-w-[120px]'>
                                        <button onClick={() => openDetailApproval(item.id)} className='rounded-[9px] flex flex-col items-center justify-center'>
                                            {item.status === 'pending' ?
                                                <AiFillClockCircle className='text-base-yellow w-[29px] h-[29px]' />
                                                : item.status === 'approved' ?
                                                    <AiFillCheckCircle className='text-[#00AB9A] w-[29px] h-[29px]' />
                                                    :
                                                    <FaTimesCircle className='text-[#C1121F] w-[29px] h-[29px]' />
                                            }
                                        </button>
                                    </div>
                                    <div className='w-full flex items-center justify-start gap-[12px]'>
                                        <button className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <AiOutlineEye onClick={() => openDetailPermission(item.id)} className='text-[#003049]' />
                                        </button>
                                        {item.status === 'approved' ?
                                            null
                                            :
                                            <button onClick={() => UpdatePermissionModalOpen(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <HiOutlinePencil className='text-[#003049]' />
                                            </button>
                                        }
                                        {/* {item.status === 'approved' ?
                                            null
                                            : */}
                                        <button onClick={() => deletePermissionModal(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <CgTrashEmpty className='text-[#003049]' />
                                        </button>
                                        {/* } */}

                                        {item.status === 'approved' ?
                                            <a
                                                href={imageHandle(item?.signatureFile?.path)}
                                                target='_blank'
                                                rel="noreferrer"
                                                className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <BiExport className='text-[#003049]' />
                                            </a>
                                            : null
                                        }

                                        {item.status === 'approved' && !admin ?
                                            <button
                                                onClick={() => handleSubmit(item.id)}
                                                className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center px-2'>
                                                <img src={IconLocFingerprint} alt='' />
                                            </button>
                                            : null
                                        }
                                    </div>
                                </div>
                            ))}
                        </table>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        limitData={data?.length}
                        lengthData={totalCount}
                        onPageChange={handlePageChange}
                        onPrevChange={handlePrevChange}
                        onNextChange={handleNextChange}
                    />
                </div>

            </div>
        </div>
    )
}

export default Permission