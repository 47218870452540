import React, { useEffect, useState } from 'react'
import { Modal, ModalDelete, Navbar, Pagination } from '../../../../components'
import { BiSearch } from 'react-icons/bi'
import { AiOutlineEye, AiOutlinePlus } from 'react-icons/ai'
import { MdImportExport } from 'react-icons/md'
import { HiOutlinePencil } from 'react-icons/hi'
import { CgTrashEmpty } from 'react-icons/cg'
import { IoMdArrowDropdown } from 'react-icons/io'
import Api from '../../../../Api'
import moment from 'moment'
import { debounce } from 'lodash'
import { toast } from 'react-hot-toast'

const Schedule = () => {

    const [showDetailScheduleByDate, setShowDetailScheduleByDate] = useState()
    const [showDetailScheduleByDay, setShowDetailScheduleByDay] = useState()
    const [showModalDelete, setShowModalDelete] = useState()
    const [showAddSchedule, setShowAddSchedule] = useState()
    const [showEditSchedule, setShowEditSchedule] = useState()

    const [dataSchedule, setDataSchedule] = useState('')
    const [dataScheduleById, setDataScheduleById] = useState('')
    const [dataEmployee, setDataEmployee] = useState('')

    const [openByDate, setOpenByDate] = useState(false)
    const [openByDay, setOpenByDay] = useState(false)

    const [name, setName] = useState('')
    const [scheduleType, setScheduleType] = useState('')
    const [scheduleTime, setScheduleTime] = useState('')
    const [selectDate, setSelectDate] = useState('')
    const [homeSchedule, setHomeSchedule] = useState('')
    const [workSchedule, setWorkSchedule] = useState('')
    const [idSchedule, setIdSchedule] = useState('')
    const [index, setIndex] = useState()

    const [refresh, setRefresh] = useState(false)

    //Repeater
    const [selectedDaysHomeSchedule, setSelectedDaysHomeSchedule] = useState([]);
    const [selectedDaysWorkSchedule, setSelectedDaysWorkSchedule] = useState([]);

    const [limit, setLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [nameSearch, setNameSearch] = useState('')
    const [dateSearch, setDateSearch] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const [userName, setUserName] = useState('')
    const [userId, setUserId] = useState('')

    const FetchUser = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setUserName(response.data.results.data.fullname)
            setUserId(response.data.results.data.id)
        } catch (error) {
            console.log(error)
        }
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const handleDayClickWorkSchedule = (day) => {
        if (selectedDaysWorkSchedule.includes(day)) {
            setSelectedDaysWorkSchedule(selectedDaysWorkSchedule.filter((selectedDay) => selectedDay !== day));
        } else {
            setSelectedDaysWorkSchedule([...selectedDaysWorkSchedule, day]);
        }
    };

    const handleRemoveDayWorkSchedule = (day) => {
        setSelectedDaysWorkSchedule(selectedDaysWorkSchedule.filter((selectedDay) => selectedDay !== day));
    };

    const handleDayClickHomeSchedule = (day) => {
        if (selectedDaysHomeSchedule.includes(day)) {
            setSelectedDaysHomeSchedule(selectedDaysHomeSchedule.filter((selectedDay) => selectedDay !== day));
        } else {
            setSelectedDaysHomeSchedule([...selectedDaysHomeSchedule, day]);
        }
    };

    const handleRemoveDayHomeSchedule = (day) => {
        setSelectedDaysHomeSchedule(selectedDaysHomeSchedule.filter((selectedDay) => selectedDay !== day));
    };

    const getEmployee = async () => {
        try {
            const response = await Api.GetEmployee(localStorage.getItem('hris-token'), '', 1, 100, '', '', '', '', '', '')
            setDataEmployee(response.data.data)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const openEditSchedule = async (id) => {
        setShowEditSchedule(!showEditSchedule)
        setIdSchedule(id)
        try {
            if (scheduleTime === 'By Day') {
                const response = await Api.GetScheduleById(localStorage.getItem('hris-token'), id)
                setName(response.data[0].fullname)
                setHomeSchedule(response.data[0].home_schedule)
                setScheduleTime(response.data[0].schedule_time)
                setWorkSchedule(response.data[0].work_schedule)
                console.log(response, 'byDay')
            } else {
                const response = await Api.GetScheduleById(localStorage.getItem('hris-token'), id)
                setName(response.data[0].fullname)
                setSelectDate(response.data[0].select_date)
                setScheduleTime(response.data[0].schedule_time)
                setScheduleType(response.data[0].scheduleType)
                console.log(response, 'byDate')
            }
        } catch (error) {
            console.log(error)
        }
    }


    const editSchedule = async () => {
        try {
            if (scheduleTime === 'By Day') {
                const data = {
                    fullname: localStorage.getItem('hris-role') === 'ADMIN' ? name : userName,
                    home_schedule: selectedDaysHomeSchedule,
                    schedule_time: scheduleTime,
                    work_schedule: selectedDaysWorkSchedule,
                }
                setIsLoading(true)
                await Api.UpdateSchedule(localStorage.getItem('hris-token'), data, idSchedule).then((res) => {
                    setIsLoading(false)
                    resetForm()
                    setShowEditSchedule(!showEditSchedule)
                    setRefresh(true)
                    toast.success('Success Edit Schedule!')
                }).catch((err) => {
                    console.log(err)
                })
            } else {
                const data = {
                    fullname: localStorage.getItem('hris-role') === 'ADMIN' ? name : userName,
                    select_date: selectDate,
                    schedule_time: scheduleTime,
                    schedule_type: scheduleType
                }
                setIsLoading(true)
                await Api.UpdateSchedule(localStorage.getItem('hris-token'), data, idSchedule).then((res) => {
                    setIsLoading(false)
                    resetForm()
                    setShowEditSchedule(!showEditSchedule)
                    setRefresh(true)
                    toast.success('Success Edit Schedule!')
                }).catch((err) => {
                    console.log(err)
                })
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error)
            toast.error('Failed Edit Schedule!')
        }
    }

    const resetForm = () => {
        setName('')
        setSelectedDaysHomeSchedule('')
        setScheduleTime('')
        setSelectedDaysWorkSchedule('')
        setSelectDate('')
        setScheduleTime('')
        setScheduleType('')
    }

    const postSchedule = async () => {
        try {
            if (scheduleTime === 'By Day') {
                const data = {
                    fullname: localStorage.getItem('hris-role') === 'ADMIN' ? name : userName,
                    home_schedule: selectedDaysHomeSchedule,
                    schedule_time: scheduleTime,
                    work_schedule: selectedDaysWorkSchedule,
                }
                setIsLoading(true)
                await Api.PostSchedule(localStorage.getItem('hris-token'), data).then((res) => {
                    setIsLoading(false)
                    resetForm()
                    setShowAddSchedule(!showAddSchedule)
                    setRefresh(true)
                    toast.success('Success Create Schedule!')
                }).catch((err) => {
                    console.log(err)
                })
            } else {
                const data = {
                    fullname: localStorage.getItem('hris-role') === 'ADMIN' ? name : userName,
                    select_date: selectDate,
                    schedule_time: scheduleTime,
                    schedule_type: scheduleType
                }
                setIsLoading(true)
                await Api.PostSchedule(localStorage.getItem('hris-token'), data).then((res) => {
                    setIsLoading(false)
                    resetForm()
                    setShowAddSchedule(!showAddSchedule)
                    setRefresh(true)
                    toast.success('Success Create Schedule!')
                }).catch((err) => {
                    console.log(err)
                })
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error)
            toast.error('Failed Create Schedule!')
        }
    }

    const getSchedule = async () => {
        try {
            const response = await Api.GetSchedule(localStorage.getItem('hris-token'), dateSearch, nameSearch, currentPage, limit)
            setDataSchedule(response.data.schedule)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }
    const handleSearchName = (e) => {
        const searchName = e.target.value
        debouncedSearchName(searchName)
    }
    const debouncedSearchName = debounce(async (name) => {
        try {
            const response = await Api.GetSchedule(localStorage.getItem('hris-token'), dateSearch, name, currentPage, limit)
            setDataSchedule(response.data.schedule)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleSearchDate = (e) => {
        const searchDate = e.target.value
        debouncedSearchDate(searchDate)
    }

    const debouncedSearchDate = debounce(async (date) => {
        try {
            const response = await Api.GetSchedule(localStorage.getItem('hris-token'), date, nameSearch, currentPage, limit)
            setDataSchedule(response.data.schedule)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const debouncedLimit = debounce(async (limit) => {
        try {
            const response = await Api.GetSchedule(localStorage.getItem('hris-token'), dateSearch, nameSearch, currentPage, limit)
            setDataSchedule(response.data.schedule)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleLimit = (e) => {
        const limit = e.target.value
        console.log(limit)
        debouncedLimit(limit)
    }

    const deletePermissionModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setIdSchedule(id)
        setRefresh(true)
    }

    const deleteSchedule = async (id) => {
        try {
            await Api.DeleteSchedule(localStorage.getItem('hris-token'), idSchedule).then((res) => {
                setShowModalDelete(!showModalDelete)
                setRefresh(true)
                toast.success('Success Delete Schedule!')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete Schedule!')
        }
    }

    const handleClickByDay = () => {
        setOpenByDay(!openByDay)
        setOpenByDate(false)
        setScheduleTime('By Day')
    }
    const handleClickByDate = () => {
        setOpenByDate(!openByDate)
        setOpenByDay(false)
        setScheduleTime('By Date')
    }

    const handleClickModal = async (params, id) => {
        if (params === 'By Date') {
            try {
                setShowDetailScheduleByDate(!showDetailScheduleByDate)
                const response = await Api.GetScheduleById(localStorage.getItem('hris-token'), id)
                setDataScheduleById(response.data)
            } catch (error) {
                console.log(error)
            }
        } else {
            try {
                setShowDetailScheduleByDay(!showDetailScheduleByDay)
                const response = await Api.GetScheduleById(localStorage.getItem('hris-token'), id)
                setDataScheduleById(response.data)
            } catch (error) {
                console.log(error)
            }
        }
    }

    useEffect(() => {
        getSchedule()
        getEmployee()
        FetchUser()
        setRefresh(false)
    }, [refresh, limit])


    return (
        <div>
            <ModalDelete
                activeModal={showModalDelete}
                buttonClose={() => setShowModalDelete(!showModalDelete)}
                submitButton={deleteSchedule}
            />
            <Modal
                activeModal={showDetailScheduleByDate}
                title={'Detail Schedule'}
                buttonClose={() => setShowDetailScheduleByDate(!showDetailScheduleByDate)}
                width={'553px'}
                content={
                    <div className='space-y-[40px] w-[full]'>
                        {Object.values(dataScheduleById).map((item) => (
                            <div className='space-y-[12px]'>
                                <div className="flex gap-[77px] text-dark-5  text-[12px]">
                                    <h1>Employee Name</h1>
                                    <p>: {item.fullname ? item.fullname : '-'}</p>
                                </div>
                                <div className="flex gap-[121px] text-dark-5  text-[12px]">
                                    <h1>Schedule</h1>
                                    <p>: {item.schedule_time ? item.schedule_time : '-'}</p>
                                </div>
                                <h1 className='text-[#457B9D] text-[12px] font-semibold'>{item.schedule_type}</h1>
                                <h1 className='text-[#003049] text-[12px] font-semibold'>{moment(item.select_date).format('DD-MM-YYYY')}</h1>
                            </div>
                        ))}
                    </div>
                }
            />
            <Modal
                activeModal={showDetailScheduleByDay}
                title={'Detail Schedule'}
                buttonClose={() => setShowDetailScheduleByDay(!showDetailScheduleByDay)}
                width={'553px'}
                content={
                    <div className='space-y-[40px] w-[full]'>
                        {Object.values(dataScheduleById).map((item) => (
                            <>
                                <div className='space-y-[12px]'>
                                    <div className="flex gap-[77px] text-dark-5  text-[12px]">
                                        <h1>Employee Name </h1>
                                        <p>: {item.fullname}</p>
                                    </div>
                                    <div className="flex gap-[121px] text-dark-5  text-[12px]">
                                        <h1>Schedule</h1>
                                        <p>: {item.schedule_time}</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="flex text-dark-5 text-[11px] font-[500] border rounded-md">
                                        <div className='grid grid-cols-1 w-full'>
                                            <h1 className='py-2 border-b-2 bg-[#E4E4E4] text-center h-[30px]'>Home Schedule</h1>
                                            <div className='mx-auto py-[7px] text-[10px] font-normal space-y-[7px]'>
                                                <h1>Monday</h1>
                                                <h1>Monday</h1>
                                                <h1>Monday</h1>
                                            </div>
                                        </div>
                                        <div className='grid grid-cols-1 w-full'>
                                            <h1 className='py-2 border-b-2 bg-[#E4E4E4] text-center h-[30px]'>Work Schedule</h1>
                                            <div className='mx-auto py-[7px] text-[10px] font-normal space-y-[7px]'>
                                                <h1>Monday</h1>
                                                <h1>Monday</h1>
                                                <h1>Monday</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}

                    </div>
                }
            />
            <Modal
                activeModal={showAddSchedule}
                title={'Add Schedule'}
                buttonClose={() => setShowAddSchedule(!showAddSchedule)}
                width={'553px'}
                content={
                    <div className='space-y-[20px] w-[full]'>
                        {localStorage.getItem('hris-role') === 'ADMIN' ? (
                            <div>
                                <h2 className='text-dark-5 text-[12px] font-medium mb-[5px]'>Employee Name  <span className='text-[#780000]'>*</span></h2>
                                <div className='relative'>
                                    <select onChange={(e) => setName(e.target.value)} value={name} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[473px] appearance-none'>
                                        <option selected value={''}>Select Employee...</option>
                                        {Object.values(dataEmployee).map((item) => {
                                            if (item.role === 'EMPLOYEE') {
                                                return (
                                                    <option value={item.fullname}>{item.fullname}</option>
                                                )
                                            } else {
                                                return ''
                                            }
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                </div>
                            </div>
                        ) : (

                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Employee Name<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <input value={userName} type='text' className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-full appearance-none' readOnly />
                                </div>
                            </div>
                        )}

                        <div className='text-dark-5 text-[11px]'>
                            <h1 className='mb-5 font-medium'>Schedule Time</h1>

                            <div class="flex items-center mb-5">
                                <input id="default-radio-1" type="radio" value="" name="default-radio" onClick={handleClickByDate} class="w-4 h-4 bg-gray-100 border-gray-300" />
                                <label for="default-radio-1" className={openByDate ? "ml-2 text-dark-5 font-bold text-[12px]" : "ml-2 text-dark-5 text-[12px]"}>By Date</label>
                            </div>

                            {openByDate && (
                                <>
                                    <div>
                                        <h2 className='text-dark-5 text-[12px] font-medium mb-[5px]'>Schedule</h2>
                                        <div className='relative'>
                                            <select onChange={(e) => setScheduleType(e.target.value)} value={scheduleType} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[473px] appearance-none'>
                                                <option disabled selected={''}>Select Schedule...</option>
                                                <option value={'Home Schedule'}>Home Schedule</option>
                                                <option value={'Office Schedule'}>Office Schedule</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                        </div>
                                    </div>

                                    <div className='mb-[30px]'>
                                        <h2 className='text-dark-5 text-[12px] font-medium mb-[5px]'>Select Date</h2>
                                        <input onChange={(e) => setSelectDate(e.target.value)} value={selectDate} type="date" className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] px-[21.74px] py-[10px] w-[473px] appearance-none' />
                                    </div>
                                </>
                            )}

                            <div class="flex items-center mt-5 mb-5">
                                <input onClick={handleClickByDay} id="default-radio-2" type="radio" value="" name="default-radio" class="w-4 h-4 bg-gray-100 border-gray-300" />
                                <label for="default-radio-2" class={openByDay ? "ml-2 text-dark-5 font-bold text-[12px]" : "ml-2 text-dark-5 text-[12px]"}>By Day</label>
                            </div>

                            {openByDay && (
                                <div className="flex gap-[7px]">
                                    <div>
                                        <h2 className='text-dark-5 text-[12px] font-medium mb-[5px]'>Home Schedule</h2>
                                        <div className='relative'>
                                            <select onChange={(e) => setHomeSchedule(e.target.value)} value={homeSchedule} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[233px] appearance-none'>
                                                <option disabled selected={''}>Select Day</option>
                                                <option value={'Sunday'}>Sunday</option>
                                                <option value={'Monday'}>Monday</option>
                                                <option value={'Tuesday'}>Tuesday</option>
                                                <option value={'Wednesday'}>Wednesday</option>
                                                <option value={'Thursday'}>Thursday</option>
                                                <option value={'Friday'}>Friday</option>
                                                <option value={'Saturday'}>Saturday</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />

                                            <div className='mt-2 grid grid-cols-12 gap-2'>
                                                {selectedDaysHomeSchedule.map((day) => (
                                                    <span key={day} className='col-span-6 w-full bg-dark-9 text-dark-3 text-[11px] px-[10px] py-[5px] rounded-md mr-[8px] flex justify-between'>
                                                        {day}
                                                        <button className='ml-2 text-dark-2 bg-transparent border-none outline-none ' onClick={() => handleRemoveDayHomeSchedule(day)}> x</button>
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <h2 className='text-dark-5 text-[12px] font-medium mb-[5px]'>Work Schedule</h2>
                                        <div className='relative'>
                                            <select onChange={(e) => setWorkSchedule(e.target.value)} value={workSchedule} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[233px] appearance-none'>
                                                <option disabled selected={''}>Select Day</option>
                                                <option value={'Sunday'}>Sunday</option>
                                                <option value={'Monday'}>Monday</option>
                                                <option value={'Tuesday'}>Tuesday</option>
                                                <option value={'Wednesday'}>Wednesday</option>
                                                <option value={'Thursday'}>Thursday</option>
                                                <option value={'Friday'}>Friday</option>
                                                <option value={'Saturday'}>Saturday</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />

                                            <div className='mt-2 grid grid-cols-12 gap-2'>
                                                {selectedDaysWorkSchedule.map((day) => (
                                                    <span key={day} className='col-span-6 w-full bg-dark-9 text-dark-3 text-[11px] px-[10px] py-[5px] rounded-md mr-[8px] flex justify-between'>
                                                        {day}
                                                        <button className='ml-2 text-dark-2 bg-transparent border-none outline-none' onClick={() => handleRemoveDayWorkSchedule(day)}> x</button>
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}


                        </div>

                        <div className='relative pb-[37px] pt-[40px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowAddSchedule(!showAddSchedule)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={isLoading ? null : postSchedule} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Add</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showEditSchedule}
                title={'Edit Schedule'}
                buttonClose={() => setShowEditSchedule(!showEditSchedule)}
                width={'553px'}
                content={
                    <div className='space-y-[20px] w-[full]'>

                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Employee<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <input value={localStorage.getItem('hris-role') === 'ADMIN' ? name : userName} type='text' className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-full appearance-none' readOnly />
                            </div>
                        </div>

                        <div className='text-dark-5 text-[11px]'>
                            <h1 className='mb-5 font-medium'>Schedule Time</h1>

                            <div class="flex items-center mb-5">
                                <input id="default-radio-1" type="radio" value="default-radio-1" name="default-radio" onClick={handleClickByDate} class="w-4 h-4 bg-gray-100 border-gray-300" />
                                <label for="default-radio-1" className={openByDate ? "ml-2 text-dark-5 font-bold text-[12px]" : "ml-2 text-dark-5 text-[12px]"}>By Date</label>
                            </div>

                            {openByDate && (
                                <>
                                    <div>
                                        <h2 className='text-dark-5 text-[12px] font-medium mb-[5px]'>Schedule</h2>
                                        <div className='relative'>
                                            <select onChange={(e) => setScheduleType(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[473px] appearance-none'>
                                                <option disabled selected={''}>{scheduleType}</option>
                                                <option value={'Home Schedule'}>Home Schedule</option>
                                                <option value={'Office Schedule'}>Office Schedule</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                        </div>
                                    </div>

                                    <div className='mb-[30px]'>
                                        <h2 className='text-dark-5 text-[12px] font-medium mb-[5px]'>Select Date</h2>
                                        <input value={moment(selectDate).format('YYYY-MM-DD')} onChange={(e) => setSelectDate(e.target.value)} type="date" className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] px-[21.74px] py-[10px] w-[473px] appearance-none' />
                                    </div>
                                </>
                            )}

                            <div class="flex items-center mt-5 mb-5">
                                <input onClick={handleClickByDay} id="default-radio-2" type="radio" value="default-radio-2" name="default-radio-2" class="w-4 h-4 bg-gray-100 border-gray-300" />
                                <label for="default-radio-2" class={openByDay ? "ml-2 text-dark-5 font-bold text-[12px]" : "ml-2 text-dark-5 text-[12px]"}>By Day</label>
                            </div>

                            {openByDay && (
                                <div className="flex gap-[7px]">
                                    <div>
                                        <h2 className='text-dark-5 text-[12px] font-medium mb-[5px]'>Home Schedule</h2>
                                        <div className='relative'>
                                            <select className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[233px] appearance-none' onChange={(e) => handleDayClickHomeSchedule(e.target.value)}>
                                                <option disabled selected={homeSchedule}>Select Day</option>
                                                <option value={'Sunday'}>Sunday</option>
                                                <option value={'Monday'}>Monday</option>
                                                <option value={'Tuesday'}>Tuesday</option>
                                                <option value={'Wednesday'}>Wednesday</option>
                                                <option value={'Thursday'}>Thursday</option>
                                                <option value={'Friday'}>Friday</option>
                                                <option value={'Saturday'}>Saturday</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />

                                            <div className='mt-2 grid grid-cols-12 gap-2'>
                                                {selectedDaysHomeSchedule.map((day) => (
                                                    <span key={day} className='col-span-6 w-full bg-dark-9 text-dark-3 text-[11px] px-[10px] py-[5px] rounded-md mr-[8px] flex justify-between'>
                                                        {day}
                                                        <button className='ml-2 text-dark-2 bg-transparent border-none outline-none ' onClick={() => handleRemoveDayHomeSchedule(day)}> x</button>
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <h2 className='text-dark-5 text-[12px] font-medium mb-[5px]'>Work Schedule</h2>
                                        <div className='relative'>
                                            <select className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[233px] appearance-none' onChange={(e) => handleDayClickWorkSchedule(e.target.value)}>
                                                <option disabled selected={workSchedule}>Select Day</option>
                                                <option value={'Sunday'}>Sunday</option>
                                                <option value={'Monday'}>Monday</option>
                                                <option value={'Tuesday'}>Tuesday</option>
                                                <option value={'Wednesday'}>Wednesday</option>
                                                <option value={'Thursday'}>Thursday</option>
                                                <option value={'Friday'}>Friday</option>
                                                <option value={'Saturday'}>Saturday</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />

                                            <div className='mt-2 grid grid-cols-12 gap-2'>
                                                {selectedDaysWorkSchedule.map((day) => (
                                                    <span key={day} className='col-span-6 w-full bg-dark-9 text-dark-3 text-[11px] px-[10px] py-[5px] rounded-md mr-[8px] flex justify-between'>
                                                        {day}
                                                        <button className='ml-2 text-dark-2 bg-transparent border-none outline-none' onClick={() => handleRemoveDayWorkSchedule(day)}> x</button>
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}


                        </div>

                        <div className='relative pb-[37px] pt-[40px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowEditSchedule(!showEditSchedule)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={isLoading ? null : editSchedule} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Save</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <div className='bg-[#F8F9FB] min-h-screen'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={'Schedule'} LinkBack={'/dashboard'} />
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>

                        <div>
                            <h1 className='text-[20px]'>Schedule</h1>
                            <h2 className='text-[10px] text-dark-5'>List of  Schedule</h2>
                        </div>

                        <div className="flex gap-2 mt-[31px] relative">
                            <input onChange={handleSearchDate} type="date" className='bg-[#F5F8FA] py-[11.5px] px-[10px] text-[#7E8299] text-[12px] font-medium rounded-md' />
                            {/* <button className='bg-white border border-dark-7 rounded-[6px] flex items-center gap-[12px] px-[10px] py-[11px]'>
                            <TbFileExport className='text-[#003049]'/>
                            <h1 className='text-[#003049] text-sm font-[500]'>Export</h1>
                        </button> */}
                            <select onChange={handleLimit} className='border rounded-md text-sm font-medium text-[#003049] px-[10px] py-2 appearance-none'>
                                <option value="" selected disabled>Set Limit</option>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="25">25</option>
                            </select>
                            <div className="absolute right-0">
                                <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                    <div className='relative'>
                                        <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg' />
                                        <input onChange={handleSearchName} placeholder='Search by Employee Name...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]' />
                                    </div>
                                    <button onClick={() => setShowAddSchedule(!showAddSchedule)} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                        <AiOutlinePlus className='text-xl text-white' />
                                        <h1 className='text-dark-9 text-sm font-[500]'>Add Schedule</h1>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='overflow-auto scrollbar-hide'>
                                <table className='w-full space-y-[10px]'>
                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[6px] min-w-[350px] max-w-[350px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Employee Name</h1>
                                            <MdImportExport className='text-dark-5 text-xs' />
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[350px] max-w-[350px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Schedule Time</h1>
                                            <MdImportExport className='text-dark-5 text-xs' />
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] w-full'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Action </h1>
                                            <MdImportExport className='text-dark-5 text-xs' />
                                        </div>
                                    </div>
                                    {Object.values(dataSchedule).map((item, idx) => (
                                        <div key={idx} className='flex items-center gap-2  px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='min-w-[350px] max-w-[350px]'>
                                                <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.fullname ? item.fullname : ''}</h1>
                                            </div>
                                            <div className='min-w-[350px] max-w-[350px]'>
                                                <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.schedule_time ? item.schedule_time : '-'}</h1>
                                            </div>
                                            <div className='w-full flex items-center justify-center gap-[20px]'>
                                                <button className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <AiOutlineEye onClick={() => handleClickModal(item.schedule_time, item.id)} className='text-[#003049]' />
                                                </button>
                                                <button onClick={() => openEditSchedule(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[#003049]' />
                                                </button>
                                                <button onClick={() => deletePermissionModal(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-[#003049]' />
                                                </button>

                                            </div>
                                        </div>
                                    ))}
                                </table>
                            </div>
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                                onPrevChange={handlePrevChange}
                                onNextChange={handleNextChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Schedule