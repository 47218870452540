import React from 'react'
import { AiOutlineEye } from 'react-icons/ai'
import { BiFilter } from 'react-icons/bi'
import { FiSearch } from 'react-icons/fi'
import { TbFileExport } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { Navbar } from '../../../../../components'
import languages from '../../../../../utils/languages'

const DetailPolling = () => {
    const navigate = useNavigate()
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={'Detail Polling'} LinkBack={'/dashboard/polling'} />
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div>
                            <h1 className='text-zinc-700 text-xl font-semibold'>Outbond Survey</h1>
                            <h1 className='text-neutral-500 text-[10px]'>{languages.language === 'id' ? "Detail Responden Poling" : "Detail Responden of Polling"}</h1>
                        </div>

                        <div className='space-y-[9px] rounded-xl shadow-md border py-[14px] px-[21px] w-2/12'>
                            <h1 className='text-neutral-400 text-[8px] font-medium'>{languages.language === 'id' ? "Total Responden" : "Total Respondent"}</h1>
                            <h1 className='text-zinc-600 text-[15px] font-semibold'>1800</h1>
                        </div>

                        <div className='flex justify-between'>
                            <div className='flex items-center justify-start gap-[20px]'>
                                <button className='bg-white border lg:w-fit w-full rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                    <BiFilter className='text-[#0E5073]' />
                                    <h1 className='text-[#0E5073] text-sm font-[500]'>Filter</h1>
                                </button>
                                <button className='bg-white border lg:w-fit w-full rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                    <TbFileExport className='text-[#0E5073]' />
                                    <h1 className='text-[#0E5073] text-sm font-[500]'>{languages.language === 'id' ? "Expor" : "Export"}</h1>
                                </button>
                            </div>
                            <div className='relative'>
                                <input placeholder={languages.language === 'id' ? "Cari..." : "'Search..."} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[10px] pl-10 w-full lg:w-[300px] flex items-center gap-[12px] px-[10px] py-[8px]' />
                                <FiSearch className='text-[#A8A8A8] absolute top-[8px] left-3' />
                            </div>
                        </div>

                        <div>
                            <div className='overflow-auto scrollbar-hide'>
                                <table className='w-full space-y-[10px]'>
                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[6px] min-w-[230px] max-w-[230px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Nama Employee" : "Employee Name"}</h1>
                                            {/* <MdImportExport className='text-dark-5 text-xs'/> */}
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[170px] max-w-[170px] justify-center'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Job Position</h1>
                                            {/* <MdImportExport className='text-dark-5 text-xs'/> */}
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px] justify-center'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Tanggal Pengisian" : "Submission Date"}</h1>
                                            {/* <MdImportExport className='text-dark-5 text-xs'/> */}
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[170px] max-w-[170px] justify-center'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Waktu Pengisian" : "Submission Time"}</h1>
                                            {/* <MdImportExport className='text-dark-5 text-xs'/> */}
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] w-full'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                                            {/* <MdImportExport className='text-dark-5 text-xs'/> */}
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[230px] max-w-[230px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>Muhammad Eko David Kurniawan</h1>
                                        </div>
                                        <div className='min-w-[170px] max-w-[170px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate text-center'>Finance</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate text-center'>21 November 2022</h1>
                                        </div>
                                        <div className='min-w-[170px] max-w-[170px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate text-center'>10:05</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            <button onClick={() => navigate('/dashboard/polling/detail-polling/answer')} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <AiOutlineEye className='text-[#003049]' />
                                            </button>
                                        </div>
                                    </div>
                                </table>
                            </div>
                            {/* <Pagination
                                currentPage={currentPage} 
                                totalPages={totalPages} 
                                onPageChange={handlePageChange}
                                onPrevChange={handlePrevChange}
                                onNextChange={handleNextChange}
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailPolling