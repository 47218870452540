import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { error403, error404, error419, error500 } from '../../assets/images/error'

const ErrorPage = () => {
  const { status } = useParams()
  const navigate = useNavigate()
  setTimeout(() => {
    localStorage.removeItem('hris-token')
    localStorage.removeItem('hris-role')
    navigate("/")
  }, 5000);
  return (
    <>
      {
        // status === "400" ?
        // <div className='w-screen h-screen flex flex-col justify-center items-center gap-1'>
        //   <img src={error400} className='lg:w-[65%] md:w-[75%]' />
        //   <h1 className='lg:text-5xl md:text-3xl text-xl font-semibold mb-2'>400 Bad Request</h1>
        //   <p className='lg:text-2xl md:text-md text-xs font-light text-grey-medium'>you've sent a bad request</p>
        // </div>
        // :
        status === "419" ?
          <div className='w-screen h-screen flex flex-col justify-center items-center gap-1'>
            <img src={error419} className='lg:w-[65%] md:w-[75%]' alt='' />
            <h1 className='lg:text-5xl md:text-3xl text-xl font-semibold mb-2'>419 Page Expired</h1>
            <p className='lg:text-2xl md:text-md text-xs font-light text-grey-medium'>Sorry, your session has expired.</p>
            <p className='lg:text-2xl md:text-md text-xs font-light text-grey-medium'>Please refresh and try again </p>
          </div>
          : status === "403" ?
            <div className='w-screen h-screen flex flex-col justify-center items-center gap-1'>
              <img src={error403} className='lg:w-[65%] md:w-[75%]' alt='' />
              <h1 className='lg:text-5xl md:text-3xl text-xl font-semibold mb-2'>403 Forbidden</h1>
              <p className='lg:text-2xl md:text-md text-xs font-light text-grey-medium'>The page you're trying to access has restricted access.</p>
              <p className='lg:text-2xl md:text-md text-xs font-light text-grey-medium'>Please refer to your system administrator.</p>
            </div>
            : status === "404" ?
              <div className='w-screen h-screen flex flex-col justify-center items-center gap-1'>
                <img src={error404} className='lg:h-[65%] md:h-[75%]' alt='' />
                <h1 className='lg:text-5xl md:text-3xl text-xl font-semibold mb-2'>404 Not Found</h1>
                <p className='lg:text-2xl md:text-md text-xs font-light text-grey-medium'>Double-check the URL address you entered.</p>
                <p className='lg:text-2xl md:text-md text-xs font-light text-grey-medium'>Make sure the URL address is correct and complete.</p>
              </div>
              : status === "500" ?
                <div className='w-screen h-screen flex flex-col justify-center items-center gap-1'>
                  <img src={error500} className='lg:h-[65%] md:h-[75%]' alt='' />
                  <h1 className='lg:text-5xl md:text-3xl text-xl font-semibold mb-2'>500 Internal server error</h1>
                  <p className='lg:text-2xl md:text-md text-xs font-light text-grey-medium text-center'>Double check your server configuration.</p>
                  <p className='lg:text-2xl md:text-md text-xs font-light text-grey-medium text-center'>Make sure your server configuration is correct and meets your website's needs.</p>
                </div>
                : navigate("/")
      }
    </>

  )
}

export default ErrorPage