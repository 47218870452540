import React from 'react'
import { AiOutlineUpload } from 'react-icons/ai'
import { Navbar } from '../../../../../components'

const DetailAnswerPolling = () => {
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={'Detail Answer Polling'} LinkBack={'/dashboard/polling/detail-polling'} />
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <h1 className="text-black text-xl font-semibold">Outbound Survey</h1>

                        <div className='px-[34px] py-[24px] rounded-xl shadow'>
                            <div className='grid grid-cols-6 '>
                                <div className='col-span-1 text-zinc-800 text-xs font-semibold space-y-[4px]'>
                                    <h1>Description</h1>
                                    <h1>Expired Date</h1>
                                    <h1>Who can see this post?</h1>
                                </div>
                                <div className='col-span-3 text-zinc-800 text-xs font-semibold space-y-[4px]'>
                                    <h1>: Lorem Ipsum Dolor Set Amet for dummy description </h1>
                                    <h1>: 20/10/2023</h1>
                                    <h1>: Everyone</h1>
                                </div>
                            </div>
                        </div>

                        <div className='space-y-[16px] pb-[36px]'>
                            <div className='py-[15px] px-[11px] border rounded-lg shadow-sm'>
                                <h1 className="text-zinc-800 text-xs font-medium mb-[16px]">Question 1</h1>
                                <div>
                                    <input className='py-[10px] px-[18px] text-xs border text-grey-thick placeholder:text-grey-thick rounded-md w-full' placeholder='Answer' />
                                </div>
                            </div>
                            <div className='py-[15px] px-[11px] border rounded-lg shadow-sm'>
                                <h1 className="text-zinc-800 text-xs font-medium mb-[16px]">Question 2</h1>
                                <div>
                                    <input className='py-[10px] px-[18px] text-xs border text-grey-thick placeholder:text-grey-thick rounded-md w-full' placeholder='Answer' />
                                </div>
                            </div>
                            <div className='py-[15px] px-[11px] border rounded-lg shadow-sm'>
                                <h1 className="text-zinc-800 text-xs font-medium mb-[16px]">Question 3</h1>
                                <div>
                                    <div className='space-y-[10px]'>
                                        <div className='w-full border rounded-md py-[10px] px-[18px] flex items-center gap-[14px]'>
                                            <input type="checkbox" id="option1" name="" value="" />
                                            <label className='text-gray-500 text-xs font-normal ' for="option1">Option 1</label>
                                        </div>
                                        <div className='w-full border rounded-md py-[10px] px-[18px] flex items-center gap-[14px]'>
                                            <input type="checkbox" id="option2" name="" value="" />
                                            <label className='text-gray-500 text-xs font-normal ' for="option2">Option 2</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='py-[15px] px-[11px] border rounded-lg shadow-sm'>
                                <h1 className="text-zinc-800 text-xs font-medium mb-[16px]">Question 4</h1>
                                <div>
                                    <div className='space-y-[10px]'>
                                        <div className='w-full border rounded-md py-[10px] px-[18px] flex items-center gap-[14px]'>
                                            <input type="radio" id="radio1" name="radio-option" value="" />
                                            <label className='text-gray-500 text-xs font-normal ' for="radio1">Option 1</label>
                                        </div>
                                        <div className='w-full border rounded-md py-[10px] px-[18px] flex items-center gap-[14px]'>
                                            <input type="radio" id="radio2" name="radio-option" value="" />
                                            <label className='text-gray-500 text-xs font-normal ' for="radio2">Option 2</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='py-[15px] px-[11px] border rounded-lg shadow-sm'>
                                <h1 className="text-zinc-800 text-xs font-medium mb-[16px]">Question 5</h1>
                                <div>
                                    <div className="file-upload">
                                        <input
                                            type="file"
                                            id="fileInput"
                                            style={{ display: 'none' }}
                                        />
                                        <label htmlFor="fileInput" className="flex text-zinc-700 items-center py-[17px] px-[17px] justify-between rounded-md border gap-[54px] w-[180px]">
                                            <h1 className="text-xs">Upload File</h1>
                                            <AiOutlineUpload />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailAnswerPolling