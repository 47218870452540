import React from 'react'
import { AiOutlineCalendar, AiOutlineEye, AiOutlineUser } from 'react-icons/ai'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { BestPrice, Blog1, Blog2, Blog3 } from '../../../assets'
import { HelmetReact, NavbarLandingPage } from '../../../components'

const DetailBlog3 = () => {
    return (
        <div className='scroll-smooth'>

            <HelmetReact
                title={'Humanusia sebagai Rekomendasi Sistem Manajemen HR dengan Harga Terjangkau'}
                description={''}
            />

            <NavbarLandingPage
                features={'#features'}
                pricing={'#pricing'}
            />

            {/* Begin : Section 1 */}
            <div className='px-[16px] lg:px-[150px] py-[75px] space-y-[25px]'>
                <div className='grid grid-cols-12 gap-[51px]'>
                    <div className='col-span-8 space-y-[18px]'>
                        <img src={Blog3} className='w-full h-[494px] object-cover rounded-xl' alt='' />
                        <h1 className='text-[#003049] font-bold text-[32px]'>Humanusia sebagai Rekomendasi Sistem Manajemen HR dengan Harga Terjangkau</h1>
                        <div className='flex items-center gap-[11px]'>
                            <div className='flex items-center gap-[7px]'>
                                <AiOutlineCalendar className='text-dark-5' />
                                <p className='text-dark-5 text-sm'>July, 18 2023</p>
                            </div>
                            <div className='w-[1.5px] h-[26px] bg-dark-7 rounded-full' />
                            <div className='flex items-center gap-[7px]'>
                                <AiOutlineUser className='text-dark-5' />
                                <p className='text-dark-5 text-sm'>Humanusia</p>
                            </div>
                            <div className='w-[1.5px] h-[26px] bg-dark-7 rounded-full' />
                            <div className='flex items-center gap-[7px]'>
                                <AiOutlineEye className='text-dark-5' />
                                <p className='text-dark-5 text-sm'>598</p>
                            </div>
                        </div>
                        {/* Artikel */}
                        <div className='text-dark-2 text-justify space-y-[10px]'>
                            <p>Dalam upaya untuk membantu bisnis skala menengah meningkatkan efisiensi dan produktivitas sumber daya manusia, Humanusia hadir sebagai solusi manajemen HR yang inovatif dengan harga terjangkau. Platform HRIS (Human Resource Information System) ini menawarkan fitur-fitur terbaik yang dapat membantu perusahaan mengelola sumber daya manusia secara efisien, meningkatkan produktivitas, dan mengurangi beban administrasi.</p>
                            <p>Dalam dunia bisnis yang semakin kompetitif, manajemen sumber daya manusia (HR) menjadi kunci kesuksesan bagi perusahaan. Namun, bagi bisnis skala menengah dengan anggaran terbatas, mengadopsi sistem HR yang efisien seringkali menjadi tantangan. Masuklah Humanusia, sebuah aplikasi HR inovatif yang menawarkan solusi efisien dengan harga yang terjangkau bagi bisnis skala menengah.</p>
                            <div className='w-full flex items-center justify-center'>
                                <img src={BestPrice} alt='' />
                            </div>
                            <p>Humanusia menawarkan paket berlangganan dengan harga terjangkau, yang dapat disesuaikan dengan kebutuhan bisnis skala menengah. Dengan platform HRIS yang inovatif dan hemat biaya, Humanusia menjadi pilihan ideal bagi bisnis yang ingin mengoptimalkan manajemen sumber daya manusia mereka tanpa mengorbankan anggaran. Humanusia dimulai dari paket Starter yang digunakan bisa digunakan untuk 1 - 100 karyawan sampai dengan paket Custom sesuai dengan keperluan dan kebutuhan perusahaan. Selain itu harga dari Humanusia  dimulai dari harga Rp 599.000.</p>
                            <p>Bagi bisnis skala menengah yang mencari aplikasi HR yang efisien dan terjangkau, Humanusia adalah pilihan yang menarik dengan fitur-fitur terbaik untuk mendukung pertumbuhan bisnis secara berkelanjutan. Dengan adopsi Humanusia, perusahaan dapat meningkatkan efisiensi operasional dan fokus pada pencapaian tujuan strategis dalam lingkungan bisnis yang kompetitif.</p>
                        </div>
                    </div>
                    <div className='col-span-4 space-y-2'>
                        <div>
                            <label className='text-[#780000] font-bold text-[28px]'>Postingan Terbaru</label>
                            <hr className='border-[#780000] border-2' />
                        </div>
                        <div className='py-[10px] flex gap-[20px]'>
                            <img src={Blog1} className='w-[70px] h-[70px] rounded-xl object-cover' alt='' />
                            <div className='space-y-2'>
                                <h2 className='text-[#003049] font-bold text-sm'>Humanusia: Menciptakan Pengalaman Pengelolaan SDM yang Berfokus pada Karyawan</h2>
                                <Link to={'/detail-blog/menciptakan-pengalaman-pengelolaan-sdm-yang-berfokus-pada-karyawan'} className='flex items-center gap-[15px]'>
                                    <p className='text-[#780000] text-sm font-medium'>Baca Selanjutnya</p>
                                    <HiArrowNarrowRight className='text-[#780000]' />
                                </Link>
                            </div>
                        </div>
                        <div className='py-[10px] flex gap-[20px]'>
                            <img src={Blog2} className='w-[70px] h-[70px] rounded-xl object-cover' alt='' />
                            <div className='space-y-2'>
                                <h2 className='text-[#003049] font-bold text-sm'>Maksimalkan Efisiensi SDM dengan Humansia yang unggul dengan Fitur-Fitur HRIS Terbaik</h2>
                                <Link to={'/detail-blog/maksimalkan-efisiensi-sdm-dengan-humansia-yang-unggul-dengan-fitur-fitur-hris-terbaik'} className='flex items-center gap-[15px]'>
                                    <p className='text-[#780000] text-sm font-medium'>Baca Selanjutnya</p>
                                    <HiArrowNarrowRight className='text-[#780000]' />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailBlog3