import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { MdImportExport } from 'react-icons/md'
import Api from '../../../../../Api'
import { Pagination } from '../../../../../components'

const Loan = ({ modalAddLoan, modalEditLoan, modalDeleteLoan, refresh }) => {

    const [loanData, setLoanData] = useState('')

    const limit = 5
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [refreshPage, setRefreshPage] = useState(false)

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefreshPage(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefreshPage(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefreshPage(true)
    };

    const GetData = async () => {
        try {
            const response = await Api.GetFinanceLoanSetting(localStorage.getItem('hris-token'), currentPage, limit)
            setLoanData(response.data.data)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        GetData()
        setRefreshPage(false)
    }, [refreshPage, refresh])

    return (
        <div>
            <div className='shadow-lg rounded-lg w-[full] p-[26px] mr-[17px] bg-white  border'>
                <div>
                    <div className='flex relative mb-[16px]'>
                        <div className=''>
                            <h1 className='text-[16px] font-semibold'>Loan Policy</h1>
                            <p className='text-[10px] text-dark-5'>List policy for loan</p>
                        </div>
                        <div className='absolute right-0 pt-2'>
                            <button onClick={modalAddLoan} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                <AiOutlinePlus className='text-xl text-white' />
                                <h1 className='text-dark-9 text-sm font-[500]'>Add</h1>
                            </button>
                        </div>
                    </div>
                </div>
                <br className='mb-[10px]' />
                <div className='overflow-auto scrollbar-hide px-[14px]'>
                    <table className='w-full space-y-[10px] '>
                        <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                            <div className='flex items-center gap-[15px] min-w-[170px] max-w-[170px]'>
                                <h1 className='text-dark-5 text-xs font-[500]'>Policy Name</h1>
                                <MdImportExport className='text-dark-5 text-xs' />
                            </div>
                            <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-dark-5 text-xs font-[500]'>Max Installment</h1>
                            </div>
                            <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-dark-5 text-xs font-[500]'>Interest</h1>
                            </div>
                            <div className='flex items-center justify-center gap-[15px] w-full'>
                                <h1 className='text-dark-5 text-xs font-[500]'>Action</h1>
                            </div>
                        </div>
                        {loanData.length === 0 ?
                            <div className='flex items-center gap-3 px-[14px] py-[8px] rounded-[3px]'>
                                <div className='min-w-[170px] max-w-[170px]'>
                                    <h1 className='text-dark-5 text-xs font-[500] truncate'>-</h1>
                                </div>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-xs font-[500] truncate'>-</h1>
                                </div>
                                <div className='min-w-[150px] max-w-[150px]'>
                                    <h1 className='text-dark-5 text-xs font-[500] truncate'>-</h1>
                                </div>
                                <div className='w-full flex items-center justify-center gap-[12px]'>
                                    <h1 className='text-dark-5 text-xs font-[500] truncate'>-</h1>
                                </div>
                            </div>
                            :
                            Object.values(loanData).map((data, index) => {
                                return (
                                    <div key={index} className='flex items-center gap-3 px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[170px] max-w-[170px]'>
                                            <h1 className='text-dark-5 text-xs font-[500] truncate'>{data.name}</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-xs font-[500] truncate'>{data.max_installment}</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-xs font-[500] truncate'>{data.interest}%</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            <button onClick={() => modalEditLoan(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <HiOutlinePencil className='text-[#003049]' />
                                            </button>
                                            <button onClick={() => modalDeleteLoan(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <CgTrashEmpty className='text-[#003049]' />
                                            </button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </table>
                </div>

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    onPrevChange={handlePrevChange}
                    onNextChange={handleNextChange}
                />
            </div>
        </div>
    )
}

export default Loan