import React from 'react'
import { Navbar } from '../../../../../components'
import languages from '../../../../../utils/languages'

const PreviewPerformanceSetting = () => {
  return (
    <div className='bg-[#F8F9FB] min-h-screen'>
      <div className='flex px-[30px] py-[35px] gap-[30px]'>
        <div className='w-full space-y-[24px] overflow-hidden'>
          <Navbar SubNavbar={true} NameSubNavbar={languages.language === 'id' ? "Pratinjau Pengaturan Kinerja" : "Preview Performance Setting"} LinkBack={'/performance-setting'} />
          <div>
            <h1 className="text-zinc-800 text-xl font-semibold">{languages.language === 'id' ? "Pratinjau Pengaturan Kinerja" : "Preview Performance Setting"}</h1>
            <h1 className="text-gray-500 text-sm font-normal">{languages.language === 'id' ? "Pratinjau Pengaturan Kinerja untuk Pegawai" : "Preview performance setting for employee performance"}</h1>
          </div>
          <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
            <div className='text-sm text-zinc-700 font-semibold py-[10px] px-[18px] w-full bg-base-outline rounded-lg'>
              Perilaku Kerja
            </div>
            <div className='rounded-lg border py-[16px] px-[18px] space-y-[24px]'>
              <div>
                <h1 className="text-zinc-700 text-xs font-semibold">1. Orientasi Pelayanan</h1>
                <ul class="custom-bullet-list">
                  <li>Item 1</li>
                  <li>Item 2</li>
                  <li>Item 3</li>
                </ul>
              </div>
              <div>
                <h1 className="text-zinc-700 text-xs font-semibold">2. Orientasi Pelayanan</h1>
                <ul class="custom-bullet-list">
                  <li>Item 1</li>
                  <li>Item 2</li>
                  <li>Item 3</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreviewPerformanceSetting