import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { HiOutlinePencil } from 'react-icons/hi'
import { IoMdArrowDropdown } from 'react-icons/io'
import { MultiSelect } from 'react-multi-select-component'
import Api from '../../../../../Api'
import { Modal, ModalDelete } from '../../../../../components'
import InputComponent from '../../../../../components/Input/inputComponent'
import InputOption from '../../../../../components/Input/inputOption'
import languages from '../../../../../utils/languages'

const Pemasukan = () => {
    const [data, setData] = useState([])
    const getData = async () => {

        try {
            await Api.GetIncome(localStorage.getItem('hris-token'))
                .then((response) => {
                    console.log(response.data)
                    setData(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }

    // Income
    const [showAddIncome, setShowAddIncome] = useState()
    const [showEditIncome, setShowEditIncome] = useState()
    const [showModalDeleteIncome, setShowModalDeleteIncome] = useState(false)
    const [idIncome, setIdIncome] = useState('')
    const [titleIncome, setTitleIncome] = useState('')
    const [amountIncome, setAmountIncome] = useState('')
    const [amountWorkday, setAmountWorkday] = useState('')
    const [amountHoliday, setAmountHoliday] = useState('')
    const [paymentTypeIncome, setPaymentTypeIncome] = useState('')
    const [taxIncome, setTaxIncome] = useState('')
    const [assignTo, setAssignTo] = useState('')
    const [selectedAssignTo, setSelectedAssignTo] = useState([])
    const [selected, setSelected] = useState([])

    const dataTax = [
        {
            value: "Taxable",
            label: "Kena Pajak"
        },
        {
            value: "Non Taxable",
            label: "Tidak Kena Pajak"
        }
    ]
    const getIncomeById = async (id) => {
        try {
            await Api.GetIncomeById(localStorage.getItem('hris-token'), id)
                .then((response) => {
                    const value = response.data[0]
                    setIdIncome(value?.id)
                    setTitleIncome(value?.title)
                    setAmountIncome(value?.amount)
                    setAmountWorkday(value?.amount_workday)
                    setAmountHoliday(value?.amount_holiday)
                    setPaymentTypeIncome(value?.payment_type)
                    setTaxIncome(value?.tax)
                    const assign_to = Object.keys(value.assign_to);
                    if (assign_to[0] === "ALL") {
                        setAssignTo(assign_to[0])
                    } else if (assign_to[0] === "job_category") {
                        setAssignTo(assign_to[0])
                        let options = {}
                        if (assign_to.length > 1) {
                            options = value.assign_to.job_category && value.assign_to.job_category.map(val => ({
                                "value": val.id,
                                "label": val.name
                            }))
                        } else {
                            options = [{
                                "value": value.assign_to.job_category.id,
                                "label": value.assign_to.job_category.name
                            }]
                        }
                        setSelected(options)
                    } else if (assign_to[0] === "job_position") {
                        setAssignTo(assign_to[0])
                        let options = {}
                        if (assign_to.length > 1) {
                            options = value.assign_to.job_position && value.assign_to.job_position.map(val => ({
                                "value": val.id,
                                "label": val.name
                            }))
                        } else {
                            options = [{
                                "value": value.assign_to.job_position.id,
                                "label": value.assign_to.job_position.name
                            }]
                        }
                        setSelected(options)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    // const e = error.response.data.errors.error.details
                    // for(let i=0; i<e.length; i++){
                    //     toast.error(error.response.data.errors.error.details[i].message)
                    // }
                })
        } catch (error) {
            console.log(error)
        }
    }
    // const deleteModalIncome = async (id) => {
    //     setShowModalDeleteIncome(!showModalDeleteIncome)
    //     setIdIncome(id)
    // }
    const deleteIncome = async () => {
        try {
            await Api.DeleteIncome(localStorage.getItem('hris-token'), idIncome)
            setShowModalDeleteIncome(!showModalDeleteIncome)
            // setRefresh(true)
            getData()
            toast.success('Berhasil Menghapus Data!')
        } catch (error) {
            toast.error('Gagal Menghapus Data!')
        }
    }
    const resetFormIncome = () => {
        setTitleIncome(0)
        setAmountIncome("")
        setPaymentTypeIncome("")
        setTaxIncome("")
        setAssignTo("")
        setAmountWorkday(0)
        setAmountHoliday(0)
    }
    const postIncome = async () => {
        try {
            var dataAssigTo = {}
            if (assignTo === "ALL") {
                dataAssigTo.ALL = true
            } else if (assignTo === "job_grade") {
                dataAssigTo.job_grade = selectedAssignTo
            } else if (assignTo === "job_position") {
                dataAssigTo.job_position = selectedAssignTo
            } else if (assignTo === "job_level") {
                dataAssigTo.job_level = selectedAssignTo
            }
            const data = {
                "title": titleIncome,
                "amount": amountIncome,
                "payment_type": paymentTypeIncome, // Enum ( monthly, weekly, daily, onceTime )
                "tax": taxIncome, // Enum ( Taxable, Non Taxable )
                "assign_to": dataAssigTo
            }
            // console.log(data)
            await Api.PostIncome(localStorage.getItem('hris-token'), data).then((response) => {
                setShowAddIncome(!showAddIncome)
                getData()
                resetFormIncome()
                toast.success('Berhasil Menambahkan Data!')
            }).catch((error) => {
                // console.log(error)
                const e = error.response.data.errors.error.details
                for (let i = 0; i < e.length; i++) {
                    toast.error(error.response.data.errors.error.details[i].message)
                }
            })
        } catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }
    const editIncome = async () => {
        try {
            let dataAssignTo = {}
            if (assignTo === "ALL") {
                dataAssignTo.ALL = true
            } else if (assignTo === "job_category") {
                dataAssignTo.job_category = selectedAssignTo
            } else if (assignTo === "job_position") {
                dataAssignTo.job_position = selectedAssignTo
            }
            const data = {
                "title": titleIncome,
                "payment_type": paymentTypeIncome, // Enum ( monthly, weekly, daily, onceTime )
                "amount": paymentTypeIncome !== 'daily' ? amountIncome : 0,
                "amount_workday": paymentTypeIncome === 'daily' ? amountWorkday : 0, // default 0, set jika payment_type == daily
                "amount_holiday": paymentTypeIncome === 'daily' ? amountHoliday : 0, // default 0, set jika payment_type == daily
                "tax": taxIncome, // Enum ( Taxable, Non Taxable )
                "assign_to": {
                    "ALL": true
                }
            }
            await Api.UpdateIncome(localStorage.getItem('hris-token'), data, idIncome).then((response) => {
                setShowEditIncome(!showEditIncome)
                getData()
                resetFormIncome()
                toast.success('Berhasil Mengubah Data!')
            }).catch((error) => {
                console.log(error?.response?.data?.message)
                toast.error(error?.response?.data?.message)
                // const e = error.response.data.errors.error.details
                // for (let i = 0; i < e.length; i++) {
                //     toast.error(error.response.data.errors.error.details[i].message)
                // }
            })
        } catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }
    const handleEditIncome = (id) => {
        setShowEditIncome(true)
        getIncomeById(id)
    }
    const [jobGrade, setJobGrade] = useState([])
    const [jobLevel, setJobLevel] = useState([])
    const [jobPosition, setJobPosition] = useState([])
    const [jobCategory, setJobCategory] = useState([])


    const getSelectJobGrade = async () => {
        try {
            const res = await Api.GetJobGradeDropdown(localStorage.getItem('hris-token'))
            const data = res.data.results.data;

            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.job_grade
            }))

            setJobGrade(options)
        } catch (error) {
            console.log(error)
        }
    }
    const getSelectJobCategory = async () => {
        try {
            const res = await Api.GetJobCategoryDropdown(localStorage.getItem('hris-token'))
            const data = res.data.results.data;

            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.category_name
            }))

            setJobCategory(options)
        } catch (error) {
            console.log(error)
        }
    }
    const getSelectJobLevel = async () => {
        try {
            const res = await Api.GetJobLevelDropdown(localStorage.getItem('hris-token'))
            const data = res.data.results.data;

            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.level_name
            }))

            setJobLevel(options)
        } catch (error) {
            console.log(error)
        }
    }
    const getSelectJobPosition = async () => {
        try {
            const res = await Api.GetJobPositionDropdown(localStorage.getItem('hris-token'))
            const data = res.data.results.data;

            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.position_name
            }))

            setJobPosition(options)
        } catch (error) {
            console.log(error)
        }
    }
    const handleChange = (e) => {
        const data = []
        e && e.map(val => (
            data.push(val.value)
        ))
        setSelected(e)
        setSelectedAssignTo(data)
    }
    useEffect(() => {
        getData()
        getSelectJobGrade()
        getSelectJobLevel()
        getSelectJobPosition()
        getSelectJobCategory()
    }, [])

    return (
        <div >
            <div className='relative'>

                <Modal
                    activeModal={showAddIncome}
                    title={languages.language === 'id' ? "Tambah Pemasukan" : "Add Income"}
                    buttonClose={() => { setShowAddIncome(!showAddIncome); resetFormIncome() }}
                    width={'553px'}
                    content={
                        <div className='space-y-[25px] w-[full]'>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Nama Pemasukan" : "Incomes Name"} <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setTitleIncome(e.target.value)} value={titleIncome} type="text" className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder='Nama Pemasukan...' />
                            </div>

                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Jumlah" : "Amount"}<span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setAmountIncome(e.target.value)} value={amountIncome} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder='Nominal...' />
                                {/* <input
                                type="text"
                                value={value}
                                onChange={handleChangeRupiah}
                                placeholder="Masukkan angka..."
                                className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]'
                            /> */}
                            </div>

                            <div className='flex gap-[20px] w-full items-center'>
                                <div>
                                    <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Tipe Pembayaran" : "Payment Type"}<span className='text-red-primer'>*</span></h1>
                                    <div className="mb-[12px]">
                                        <div className='relative w-[233px]'>
                                            <select name="paymentTypeIncome" onChange={(e) => setPaymentTypeIncome(e.target.value)} value={paymentTypeIncome} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                                <option selected value="">{languages.language === 'id' ? "Pilih Tipe Pembayaran" : "Select payment type"}</option>
                                                <option value="monthly">{languages.language === 'id' ? "Bulanan" : "Monthly"}</option>
                                                <option value="weekly">{languages.language === 'id' ? "Mingguan" : "Weekly"}</option>
                                                <option value="daily">{languages.language === 'id' ? "Harian" : "Daily"}</option>
                                                <option value="onceTime">{languages.language === 'id' ? "Satu Kali" : "One Time"}</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Pajak" : "Tax"}<span className='text-red-primer'>*</span></h1>
                                    <div className="mb-[12px]">
                                        <div className='relative w-[233px]'>
                                            <select name="" onChange={(e) => setTaxIncome(e.target.value)} value={taxIncome} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                                <option selected value="">{languages.language === 'id' ? "Pilih Pajak" : "Select Tax"}</option>
                                                <option value="Taxable">{languages.language === 'id' ? "Kena Pajak" : "Taxable"}</option>
                                                <option value="Non Taxable">{languages.language === 'id' ? "Tidak Kena Pajak" : "Non Taxable"}</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[8px]'>{languages.language === 'id' ? "Ditujukan Kepada" : "Assign To"}<span className='text-red-primer'>*</span></h1>
                                <div className='space-y-[16px]'>
                                    <div className='flex items-center gap-[14px] font-medium'>
                                        <input type="radio" checked={assignTo === "ALL"} id="allIncome" name="radio-option" value='ALL' onChange={(e) => setAssignTo(e.target.value)} />
                                        <label htmlFor="allIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Semua Pegawai" : "All Employee"}</label>
                                    </div>
                                    {/* <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" id="searchIncome" name="radio-option" value='' onChange={(e)=>setAssignTo(e.target.value)} />
                                    <label htmlFor="searchIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Cari Pegawai" : "Search Employee"}</label>
                                </div> */}
                                    <div className='flex items-center gap-[14px] font-medium'>
                                        <input type="radio" checked={assignTo === "job_grade"} id="jobGradeIncome" name="radio-option" value='job_grade' onChange={(e) => setAssignTo(e.target.value)} />
                                        <label htmlFor="jobGradeIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Kelas Jabatan" : "Based on Job Grade"}</label>
                                    </div>
                                    {
                                        assignTo === "job_grade" ?
                                            <MultiSelect
                                                options={jobGrade}
                                                value={selected}
                                                onChange={(e) => handleChange(e)}
                                                labelledBy="Select"
                                            />
                                            : null
                                    }
                                    <div className='flex items-center gap-[14px] font-medium'>
                                        <input type="radio" checked={assignTo === "job_level"} id="jobLevelIncome" name="radio-option" value='job_level' onChange={(e) => setAssignTo(e.target.value)} />
                                        <label htmlFor="jobLevelIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jenis Jabatan" : "Based on Job Level"}</label>
                                    </div>
                                    {
                                        assignTo === "job_level" ?
                                            <MultiSelect
                                                options={jobLevel}
                                                value={selected}
                                                onChange={(e) => handleChange(e)}
                                                labelledBy="Select"
                                            />
                                            : null
                                    }
                                    <div className='flex items-center gap-[14px] font-medium'>
                                        <input type="radio" checked={assignTo === "job_position"} id="jobPositionIncome" name="radio-option" value='job_position' onChange={(e) => setAssignTo(e.target.value)} />
                                        <label htmlFor="jobPositionIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jabatan" : "Based on Job Position"}</label>
                                    </div>
                                    {
                                        assignTo === "job_position" ?
                                            <MultiSelect
                                                options={jobPosition}
                                                value={selected}
                                                onChange={(e) => handleChange(e)}
                                                labelledBy="Select"
                                            />
                                            : null
                                    }
                                </div>
                            </div>

                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => { setShowAddIncome(!showAddIncome); resetFormIncome() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-[#003049] text-sm font-[500]'>{languages.language === 'id' ? "Batal" : "Cancel"}</h1>
                                    </button>
                                    <button onClick={postIncome} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Simpan" : "Add"}</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditIncome}
                    title={languages.language === 'id' ? "Edit Pemasukan" : "Edit Income"}
                    buttonClose={() => setShowEditIncome(!showEditIncome)}
                    width={'553px'}
                    content={
                        <div className='space-y-5 w-[full]'>
                            <div className='w-full'>
                                <InputComponent onChange={(e) => setTitleIncome(e)} value={titleIncome} type={"text"} placeholder={"Nominal..."} disabled={true} required={true} label={"Nama Pemasukan"} />
                            </div>
                            <div>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Tipe Pembayaran" : "Payment Type"}<span className='text-red-primer'>*</span></h1>
                                <div className="mb-[12px]">
                                    <div className='relative w-full'>
                                        <select name="paymentTypeIncome" onChange={(e) => setPaymentTypeIncome(e.target.value)} value={paymentTypeIncome} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                            <option selected value="">{languages.language === 'id' ? "Pilih Tipe Pembayaran" : "Select payment type"}</option>
                                            <option value="monthly">{languages.language === 'id' ? "Bulanan" : "Monthly"}</option>
                                            <option value="weekly">{languages.language === 'id' ? "Mingguan" : "Weekly"}</option>
                                            <option value="daily">{languages.language === 'id' ? "Harian" : "Daily"}</option>
                                            <option value="onceTime">{languages.language === 'id' ? "Satu Kali" : "One Time"}</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                    </div>
                                </div>
                            </div>
                            <div className='flex gap-[20px] w-full items-center'>
                                {paymentTypeIncome !== "daily" ?

                                    <div className='w-full'>
                                        <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Jumlah" : "Amount"}<span className='text-red-primer'>*</span></h1>
                                        <input onChange={(e) => setAmountIncome(e.target.value)} value={amountIncome} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder='Nominal...' />
                                    </div>
                                    :
                                    <>
                                        <div className='w-full'>
                                            <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Jumlah Pembayaran Hari Kerja " : "Amount"}<span className='text-red-primer'>*</span></h1>
                                            <input onChange={(e) => setAmountWorkday(e.target.value)} value={amountWorkday} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder='Nominal...' />
                                        </div>
                                        <div className='w-full'>
                                            <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Jumlah Pembayaran Hari Libur" : "Amount"}<span className='text-red-primer'>*</span></h1>
                                            <input onChange={(e) => setAmountHoliday(e.target.value)} value={amountHoliday} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder='Nominal...' />
                                        </div>
                                    </>
                                }
                            </div>
                            <div>
                                <InputOption label={"Pajak"} onChange={(e) => setTaxIncome(e)} value={taxIncome} data={dataTax} placeholder={"Pilih Pajak...."} required={true} disabled={true} />
                            </div>
                            {/* <div>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[8px]'>Ditujukan Untuk <span className='text-red-primer'>*</span></h1>
                                <div className='space-y-[16px]'>
                                    <div className='flex items-center gap-[14px] font-medium'>
                                        <input type="radio" checked={assignTo === "ALL"} id="allEdit" name="radio-option" value='ALL' onChange={(e) => setAssignTo(e.target.value)} />
                                        <label htmlFor="allEdit" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Semua Pegawai" : "All Employee"}</label>
                                    </div>

                                    <div className='flex items-center gap-[14px] font-medium'>
                                        <input type="radio" checked={assignTo === "job_category"} id="jenisKepegawaianEdit" name="radio-option" value='job_category' onChange={(e) => setAssignTo(e.target.value)} />
                                        <label htmlFor="jenisKepegawaianEdit" className='text-grey-thick font-medium text-xs'>Berdasarkan Jenis Kepegawaian</label>
                                    </div>
                                    {
                                        assignTo === "job_category" ?
                                            <MultiSelect
                                                options={jobCategory}
                                                value={selected}
                                                onChange={(e) => handleChange(e)}
                                                labelledBy="Select"
                                            />
                                            : null
                                    }
                                    <div className='flex items-center gap-[14px] font-medium'>
                                        <input type="radio" checked={assignTo === "job_position"} id="jabatanEdit" name="radio-option" value='job_position' onChange={(e) => setAssignTo(e.target.value)} />
                                        <label htmlFor="jabatanEdit" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jabatan" : "Based on Job Position"}</label>
                                    </div>
                                    {
                                        assignTo === "job_position" ?
                                            <MultiSelect
                                                options={jobPosition}
                                                value={selected}
                                                onChange={(e) => handleChange(e)}
                                                labelledBy="Select"
                                            />
                                            : null
                                    }
                                </div>
                            </div> */}

                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => { setShowEditIncome(!showEditIncome); resetFormIncome() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-[#003049] text-sm font-[500]'>{languages.language === 'id' ? "Batal" : "Cancel"}</h1>
                                    </button>
                                    <button onClick={editIncome} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Simpan" : "Add"}</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <ModalDelete
                    activeModal={showModalDeleteIncome}
                    buttonClose={() => setShowModalDeleteIncome(!showModalDeleteIncome)}
                    submitButton={deleteIncome}
                />
            </div>
            <div className='overflow-auto'>
                <table className='w-full space-y-[10px]'>
                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Nama</h1>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Tipe Pembayaran</h1>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Jumlah Pembayaran</h1>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Pajak</h1>
                        </div>
                        <div className='flex items-center gap-[6px]'>
                            <h1 className='text-dark-5 text-[11px] font-[500]'>Aksi</h1>
                        </div>
                    </div>
                    {data && data.map((item, index) => (
                        <>
                            <div key={index} className='flex items-center gap-2  px-[14px] py-[8px] rounded-[3px]'>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.title}</h1>
                                </div>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.payment_type}</h1>
                                </div>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    {item.payment_type !== "daily" ?
                                        <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.amount}</h1>
                                        : <div>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>Hari kerja : {item.amount_holiday}</h1>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>Hari Libur : {item.amount_workday}</h1>
                                        </div>
                                    }
                                </div>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.tax}</h1>
                                </div>
                                <div className='w-full flex items-start justify-start gap-[12px]'>
                                    <button
                                        onClick={() => handleEditIncome(item.id)}
                                        className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                        <HiOutlinePencil className='text-[#003049]' />
                                    </button>
                                </div>
                            </div>
                        </>
                    ))}
                </table>
            </div>
        </div>
    )
}

export default Pemasukan
