import React from 'react'

const IconSecondaryButton = ({ icon, text, action }) => {
    return (
        <button
            onClick={action}
            className='flex gap-1 items-center text-white bg-gray-200 hover:bg-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2'
        >
            <img src={icon} alt={text} className='h-4 w-auto' />
            {text}
        </button>
    )
}

export default IconSecondaryButton
